import React from "react";
import useReactRouter from "use-react-router";
import Nav from "react-bootstrap/Nav";
export default function UserNav({ data }) {

  const { history, location, match } = useReactRouter();
  const url = match.url;
  const courseId = location?.state?.courseId;
  const defaultURL = url.split("/")[1];
  const pageNambers = match?.params?.page;
  data = { ...data, courseId};
  return (
    <div>
      <Nav variant="tabs" style={{marginLeft: 10, marginRight: 10}} defaultActiveKey="add-grade-for-student">
        <Nav.Item>
          <Nav.Link
            onClick={() =>
              history.push(`/add-grade-for-student/${pageNambers}`, data)
            }
            active={defaultURL === "add-grade-for-student" ? true : false}
          >
            ໃຫ້ຄະແນນນັກສຶກສາໃໝ່
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            onClick={() =>
              history.push(`/up-grade-for-student/${pageNambers}`, data)
            }
            active={defaultURL === "up-grade-for-student" ? true : false}
          >
            ອັບເກຣດຄະແນນ
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </div>
  );
}
