import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useQuery, useMutation } from "@apollo/react-hooks";
/**
 *
 * @Library
 *
 */
 import Switch from "@material-ui/core/Switch";
 import * as _ from "lodash";
/**
 *
 * @Component
 *
 */
 import { Col } from "react-bootstrap";
 import { CustomContainer } from "../../common";
 import Loading from "../../common/Loading";

/**
 *
 * @Constant
 *
 */

/**
 *
 * @Apollo
 *
 */
 import {
  STD_TRIBES,
  UPDATE_REGISTER_STUDENT,
  REGISTRATION_STUDENT,
} from "../../apollo/registrationStudent/index";
import { FACULTY } from "../../common/facultyConstant";

/**
 *
 * @Function
 *
 */
 import { formatDate } from "../../common/super";

export default function RegistrationStudentDetail() {
  const { history, location, match } = useReactRouter();
  const pageNambers = match?.params?.page;
  const [checked, setChecked] = useState(0);
  const [updateRegStudent] = useMutation(UPDATE_REGISTER_STUDENT);
  const [dataUser, setDataUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dummy, setDummy] = useState(false);

  const {
    data: tribesData,
  } = useQuery(STD_TRIBES);

  const {
    loading: regStudentLoading,
    data: regStudentData,
  } = useQuery(REGISTRATION_STUDENT, {
    variables: {
      where: { stdid: location?.state?.stdid },
    },
  });

  // on first load
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
  }, []);

  // filter data by users role
  useEffect(() => {
    let userRole = dataUser?.role;
    if (userRole == "ADMIN") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [dataUser]);

  useEffect(() => {
    let _value = 0;
    let _defaultVal = parseInt(_value);
    if (regStudentData) {
      setChecked(regStudentData?.registrationStudent?.facEntre ?? _defaultVal);
    }
  }, [regStudentData]);

  useEffect(() => {
    if (checked) {
      setChecked(checked);
      setDummy(!dummy);
    }
  }, [checked]);

  // get trib_name
  const _renderTribe = (tribe) => {
    let result = tribesData?.tbtribesQueryShow?.filter(
      (item, index) => item.trib_id == tribe
    );
    if (result?.length > 0) return result[0].trib_name;
    else return "";
  };

  const _renderFac = (fac_id) => {
    let result = FACULTY?.filter((item, index) => item.vId == fac_id);
    if (result.length > 0) return result[0].name;
    else return "";
  };

  // get Department_name
  const _renderDepartment = (fac_id, Department_id) => {
    // if (!fac_id) return "";
    let deparmentData = FACULTY?.filter((item, index) => item.vId == fac_id);
    let result = deparmentData[0]?.departments?.filter((items, index) => {
      return items.vId == Department_id;
    });
    if (result?.length > 0) return result[0]?.name;
    else return "";
  };

  const _handleChangeSwitch = () => async (event) => {
    if (event.target.checked) {
      setChecked(1);
      await updateRegStudent({
        variables: {
          where: {
            stdregno: regStudentData?.registrationStudent?.stdregno ?? "",
          },
          data: { facEntre: 1 },
        },
      });
    } else {
      setChecked(0);
      await updateRegStudent({
        variables: {
          where: {
            stdregno: regStudentData?.registrationStudent?.stdregno ?? "",
          },
          data: { facEntre: 0 },
        },
      }).catch((err) => {
        alert("Update Status fail!");
      });
    }
    window.location.reload(true);
  };

  return (
    <div>
      {regStudentLoading && <Loading />}
      <Col sm={4}>
        <a
          href="#"
          onClick={() => {
            history.push(`/registration_student/${ pageNambers }`);
            window.location.reload();
          }}
        >
          ລົງທະບຽນນັກສຶກສາ
        </a>{" "}
        / {regStudentData?.registrationStudent?.stdnameL ?? ""}
      </Col>
      <CustomContainer style={{ marginTop: 10 }}>
        {/* Table list */}
        <div>
          <Col sm={12}>
            <div className="heading">
              <b>ຂໍ້ມູນທົ່ວໄປ</b>
            </div>
          </Col>
          <div className="list">
            <Col sm={3} style={{ padding: 20 }}>
              ລະຫັດນັກສຶກສາ{" "}
            </Col>
            <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
              {regStudentData?.registrationStudent?.stdid ?? ""}
            </Col>
          </div>
          <div className="list">
            <Col sm={3} style={{ padding: 20 }}>
              {" "}
              ຊື່(ລາວ)
            </Col>
            <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
              {regStudentData?.registrationStudent?.stdnameL ?? ""}
            </Col>
          </div>
          <div className="list">
            <Col sm={3} style={{ padding: 20 }}>
              {" "}
              ນາມສະກຸນ(ລາວ)
            </Col>
            <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
              {regStudentData?.registrationStudent?.stdsurL ?? ""}
            </Col>
          </div>
          <div className="list">
            <Col sm={3} style={{ padding: 20 }}>
              {" "}
              ຊື່(ອັງກິດ)
            </Col>
            <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
              {regStudentData?.registrationStudent?.stdnameE ?? ""}
            </Col>
          </div>
          <div className="list">
            <Col sm={3} style={{ padding: 20 }}>
              {" "}
              ນາມສະກຸນ(ອັງກິດ)
            </Col>
            <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
              {regStudentData?.registrationStudent?.stdsurE ?? ""}
            </Col>
          </div>
          <div className="list">
            <Col sm={3} style={{ padding: 20 }}>
              {" "}
              ເພດ
            </Col>
            <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
              {regStudentData?.registrationStudent?.stdgd ?? ""}
            </Col>
          </div>
          <div className="list">
            <Col sm={3} style={{ padding: 20 }}>
              {" "}
              ຊົນເຜົ່າ
            </Col>
            <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
              {_renderTribe(
                regStudentData?.registrationStudent?.std_tribes ?? ""
              )}
            </Col>
          </div>
          <div className="list">
            <Col sm={3} style={{ padding: 20 }}>
              {" "}
              ວັນເດືອນປີເກີດ
            </Col>
            <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
              {formatDate(regStudentData?.registrationStudent?.stddate ?? "")}
            </Col>
          </div>
          <div className="list">
            <Col sm={3} style={{ padding: 20 }}>
              {" "}
              ຈົບຈາກໂຮງຮຽນ
            </Col>
            <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
              {regStudentData?.registrationStudent?.stdschool ?? ""}
            </Col>
          </div>

          <Col sm={12}>
            <div className="heading">
              <b>ສັງກັດຢູ່</b>
            </div>
          </Col>
          <div className="list">
            <Col sm={3} style={{ padding: 20 }}>
              ຄະນະ
            </Col>
            <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
              {_renderFac(regStudentData?.registrationStudent?.fac_id ?? "")}
            </Col>
          </div>
          <div className="list">
            <Col sm={3} style={{ padding: 20 }}>
              ສາຂາວິຊາ
            </Col>
            <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
              {
                (_renderDepartment(
                  regStudentData?.registrationStudent?.fac_id,
                  regStudentData?.registrationStudent?.stdmajor ?? ""
                ))}
            </Col>
          </div>
          <Col sm={12} hidden={isAdmin}>
            <div className="heading">
              <b>ສະຖານະການລົງທະບຽນ</b>
            </div>
          </Col>
          <div className="list">
            <Col sm={3} hidden={isAdmin} style={{ padding: 20 }}>
              ສະຖານະການລົງທະບຽນ
            </Col>
            <Col
              sm={6}
              hidden={isAdmin}
              style={{ padding: 20, fontWeight: "bold" }}
            >
              <label className="c-switch c-switch-pill c-switch-label c-switch-info">
                <Switch
                  checked={checked}
                  onChange={_handleChangeSwitch()}
                  value={checked}
                  color="primary"
                  style={{
                    color: checked == 1 ? "#047CAE" : "gray",
                  }}
                />
              </label>
            </Col>
          </div>
          <div className="list">
            <Col sm={3} style={{ padding: 20 }}>
              ວັນທີລົງທະບຽນ
            </Col>
            <Col sm={6} style={{ padding: 20, fontWeight: "bold" }}>
              {regStudentData?.registrationStudent?.user_date
                ? formatDate(
                  regStudentData?.registrationStudent?.user_date ?? ""
                )
                : ""}
            </Col>
          </div>
        </div>
      </CustomContainer>
    </div>
  );
}
