import React from 'react';
import { convertGender } from '../../helpers/user';

const StudentTable = ({ data }) => {

    const filterData = data.filter(item => item !== undefined)

    return (
        <div className="container mt-4">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>ລຳດັບ</th>
                        <th>ລະຫັດລົງທະບຽນເຂົ້າຮຽນ</th>
                        <th>ຊື່ (Lao)</th>
                        <th>ຊື່ (English)</th>
                        <th>ນາມສະກຸນ (Lao)</th>
                        <th>ນາມສະກຸນ (English)</th>
                        <th>ສົກຮຽນ</th>
                        <th>ວັນເດືອນປີເກີດ</th>
                        <th>ເພດ</th>
                    </tr>
                </thead>
                <tbody>
                    {filterData.map((student, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{student.code}</td>
                            <td>{student.firstNameL}</td>
                            <td>{student.firstNameE}</td>
                            <td>{student.lastNameL}</td>
                            <td>{student.lastNameE}</td>
                            <td>{student.assessmentYear}</td>
                            <td>{student.birthday}</td>
                            <td>{convertGender(student.gender)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StudentTable;
