import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import Routers from "../../consts/router";

/**
 *
 * @Library
 *
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
/**
 *
 * @Component
 *
 */
import { Spinner } from "react-bootstrap";
import { Breadcrumb, Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CustomContainer, SearchBar, Title, CustomButton, TableHeader, TableCell } from "../../common";
import { formatDateTime, noticeStatus, bracket } from "../../common/super";
import NoticeSearch from "./NoticeSearch";
import ImageZoom from "./ImageZoom";
/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";

/**
 *
 * @Apollo
 *
 */
import { QUERY_FILE } from "../../apollo/doc";
import { NOTICS } from "../../apollo/notic";
import PaginationHelper from "../../helpers/PaginationHelper";
import consts from "../../consts";
/**
 *
 * @Function
 *
 */

function NoticeList() {
  const { history, match, location } = useReactRouter();
  const parsed = queryString?.parse(location?.state);
  /**
   *
   * @State
   *
   */

  // States
  const [showSearchView, setShowSearchView] = useState(false);
  const [showImageView, setImageView] = useState(false);
  const [imageData, setImageData] = useState("");
  const [title, setTitle] = useState("");
  const [fileCat, setFileCat] = useState({});
  // state for search
  const [typeNotice, setTypeNotice] = useState(!parsed?.typeNotice ? "" : parsed?.typeNotice);
  const [titleKeyword, setTitleKeyword] = useState();
  const [keywordSearch, setKeywordSearch] = useState("");

  const [total, setTotal] = useState(0);

  const { _limit, _skip, Pagination_helper } = PaginationHelper();


  const [notiData, setNoticData] = useState([]);
  /**
   *
   * @Apollo
   *
   */
  const [loadFiles, { loading }] = useLazyQuery(QUERY_FILE);

  const [getNotics, { loading: noticLoading, data: noticData }] = useLazyQuery(NOTICS, { fetchPolicy: "network-only" });

  /**
   *
   * @UseEffect
   *
   */

  useEffect(() => {
    setTitle("ການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ");
    setFileCat({ where: { type: "PUBLIC_FILE" } })
    loadFiles({
      variables: {
        where: { type: "PUBLIC_FILE" }
      }
    })
  }, []);

  useEffect(() => {
    let _where = { isPublish: true }
    if (titleKeyword) _where = { ..._where, title: titleKeyword }
    if (typeNotice !== "") _where = { ..._where, type: typeNotice }
    getNotics({
      variables: {
        where: _where,
        orderBy: "createdAt_DESC",
        skip: (_skip - 1) * _limit,
        first: _limit,
      },

    });
  }, [typeNotice, titleKeyword]);

  useEffect(() => {
    if (noticData?.notics) {
      setNoticData(noticData?.notics?.data);
      setTotal(noticData?.notics?.total);
    }
  }, [noticData]);
  /**
   *
   * @Function
   *
   */

  // Set states
  const _handleSearchViewClose = () => setShowSearchView(false);
  const _handleImageViewClose = () => setImageView(false);
  const _handleImageViewShow = () => setImageView(true);

  const _onSearch = (e) => {
    setTitleKeyword(e?.target?.value);
    setShowSearchView(false);
    setKeywordSearch("ຜົນການຄົ້ນຫາ");
  };

  const _showImage = (value) => {
    _handleImageViewShow();
    setImageData(value);
  };

  // set state menu item
  const _allType = (e) => {
    setTypeNotice(e);
  };
  const _notice = (e) => {
    setTypeNotice(e);
  };
  const _agreeMent = (e) => {
    setTypeNotice(e);
  };
  const _regulation = (e) => {
    setTypeNotice(e);
  };
  const _normal = (e) => {
    setTypeNotice(e);
  };
  const _news = (e) => {
    setTypeNotice(e);
  };
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <Title
          text={
            keywordSearch ? keywordSearch + " " + bracket(titleKeyword) : title
          }
        />
        <div style={{ textAlign: "right" }}>
          <CustomButton
            confirm
            addIcon
            width={200}
            title="ແຈ້ງການ"
            onClick={() => history.push("/notice-add")}
          />
        </div>

        {/* custom search button */}
        <div className="form-group row">
          <label className="col-sm-1 col-form-label"
            style={{ color: consts.PRIMARY_COLOR, fontWeight: 'bold' }}
          >ຄົ້ນຫາ</label>
          <div className="col-sm-11">
            <input
              type="text"
              className="form-control boxs"
              id="searchText"
              placeholder="ຫົວຂໍ້"
              onChange={(e) => _onSearch(e)}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: 30,
          }}
        >
          <div style={{ width: 80, color: "#057CAE", fontWeight: "bold" }}>
            ຫມວດ
          </div>
          <div className={typeNotice === "" ? "menuItem active-menu" : "menuItem"} onClick={() => _allType("")}>
            ທັງໝົດ
          </div>
          <div className={typeNotice === "NOTIC" ? "menuItem active-menu" : "menuItem"} onClick={() => _notice("NOTIC")}>
            ແຈ້ງການ
          </div>
          <div className={typeNotice === "AGREEMENT" ? "menuItem active-menu" : "menuItem"} onClick={() => _agreeMent("AGREEMENT")}>
            ຂໍ້ຕົກລົງ
          </div>
          <div className={typeNotice === "REGULATION" ? "menuItem active-menu" : "menuItem"} onClick={() => _regulation("REGULATION")}>
            ລະບຽບການ
          </div>
          {/* <div className="menuItem" onClick={() => _normal("NORMAL")}>
            ທົ່ວໄປ
          </div> */}
          <div className={typeNotice === "NEWS" ? "menuItem active-menu" : "menuItem"} onClick={() => _news("NEWS")}>
            ໜັງສືພີມ
          </div>
        </div>

        {/* ເອກະສານທັງໝົດ */}
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          ທັງໝົດ {noticData?.notics?.total}
        </div>

        {/* Table list */}
        <div>
          {loading || noticLoading ? (
            <Spinner animation="border" style={{ color: "#057CAE" }} />
          ) : (
            <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
              <thead>
                <TableHeader>
                  <th style={{ width: 70 }}> ລຳດັບ</th>
                  <th style={{ width: 100 }}>ຮູບພາບ</th>
                  <th>ຫົວຂໍ້</th>
                  <th style={{ width: 150 }}>ປະເພດ</th>
                  <th>ສ້າງ</th>
                  <th>ອັບເດດ</th>
                  <th style={{ width: 200 }}>ຈັດການ</th>
                </TableHeader>
              </thead>
              <tbody>
                {notiData?.map((x, index) => {
                  return (
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell style={{ paddingRight: 5 }}>
                        <img
                          alt=""
                          src={
                            x?.coverImage?.url ||
                            "https://www.cakemandu.com.np/public/uploads/items/thumbs/default.png"
                          }
                          className="box-image"
                          onClick={() => {
                            _showImage(
                              x?.coverImage?.url ||
                              "https://www.cakemandu.com.np/public/uploads/items/thumbs/default.png"
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "left", paddingLeft: 10 }}>
                        {x?.title}
                      </TableCell>
                      <TableCell>{noticeStatus(x.type)}</TableCell>
                      <TableCell style={{ width: 200 }}>
                        {formatDateTime(x.createdAt)}
                        <br />( ໂດຍ: {x?.createdBy?.firstname}{" "}
                        {x?.createdBy?.lastname ? x?.createdBy?.lastname : ""} )
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        {formatDateTime(x.updatedAt)}
                        <br />( ໂດຍ: {x?.updatedBy?.firstname}
                        {x?.updatedBy?.lastname ? x?.updatedBy?.lastname : ""} )
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: 200,
                          }}
                        >
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ແກ້ໄຂຂໍ້ມູນ
                              </Tooltip>
                            }
                          >
                            <div
                              onClick={() => {
                                history.push(`/notice-edit/${x.id}`, x);
                              }}
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#FFFFFF",
                                padding: 3,
                                width: 64,
                                borderRadius: 4,
                              }}
                            >
                              <i
                                className="fa fa-pen"
                                style={{ color: Consts.BORDER_COLOR }}
                              />
                            </div>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ເບິ່ງລາຍລະອຽດ
                              </Tooltip>
                            }
                          >
                            <div
                              onClick={() =>
                                history.push(
                                  "/notice-detail/" + x.id + "/" + x.type
                                )
                              }
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#FFFFFF",
                                padding: 3,
                                width: 64,
                                borderRadius: 4,
                              }}
                            >
                              <FontAwesomeIcon
                                icon={["fas", "eye"]}
                                color={Consts.BORDER_COLOR}
                              />
                            </div>
                          </OverlayTrigger>
                        </div>
                      </TableCell>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        {Pagination_helper(total, Routers.Notice_List, `typeNotice=${typeNotice}`)}
      </CustomContainer>
      {/* Search Modal */}
      <NoticeSearch
        showSearchView={showSearchView}
        _handleSearchViewClose={_handleSearchViewClose}
        onSearch={(value) => _onSearch(value)}
      />

      <ImageZoom
        showImageView={showImageView}
        _handleImageViewClose={_handleImageViewClose}
        imageData={imageData}
      />
    </div>
  );
}

export default NoticeList;
