import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import { useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from 'react-redux'
import { REGISTER_DEPARTMENT } from "../apollo/query";
import { getUserDataFromLCStorage } from "../../../helpers/user";
import { CustomButton } from '../../../common';
import SettingMajor from '../../setting/settingMajor/SettingMajor';
import { MAJORS } from '../../../apollo/major';
import { CLASSROOM_MASTERS } from '../../../apollo/classRoom';


export default function RoleAdmin({ dataFaculty, setFacutyId, setStudents,
    facutyId, setDepartmentId, setYearLevel, yearLevel, setCourseId, semester, setSemester, classRoom, setClassRoom, firstNameL,
    setFirstNameL, departmentId, register, dataCourses, _handleSearchFromStudent, majorId, setMajorId }) {
    const accessmentRedux = useSelector((state) => state.accessment.value)
    const [dataUser, setDataUser] = useState();

    const [loadDataDeparments, { data: departmentData }] = useLazyQuery(REGISTER_DEPARTMENT);
    const [loadDataMajors, { data: majorData }] = useLazyQuery(MAJORS);
    const [loadDataClassRooms, { data: classRoomData }] = useLazyQuery(CLASSROOM_MASTERS);

    useEffect(() => {
        if (getUserDataFromLCStorage()?.data) setDataUser(getUserDataFromLCStorage()?.data);
        if (facutyId && facutyId !== "") loadDataDeparments({
            variables: {
                where: {
                    isDeleted: false,
                    faculty: facutyId,
                    assessmentYear: accessmentRedux
                }
            }
        })
        if (departmentId && departmentId !== "") loadDataMajors({
            variables: {
                where: {
                    isDeleted: false,
                    department: departmentId,
                    assessmentYear: accessmentRedux
                }
            }
        })

        if (majorId && majorId !== "" || yearLevel && yearLevel !== "") {
            let _where = {}
            if (majorId && majorId !== "") _where = { ..._where, major: majorId };
            if (yearLevel && yearLevel !== "") _where = {..._where, year: yearLevel};

            loadDataClassRooms({
                variables: {
                    where: { ..._where }
                }
            })
        }
    }, [accessmentRedux, facutyId, departmentId, majorId, yearLevel]);

    /* select Faculty */
    const _selectFaculty = (e) => {
        setFacutyId(e.target.value);
        // if (e.target.value === "ທັງໝົດ") {
        //     setDepartmentId("");
        //     setYearLevel("");
        // } else {
        // if (facutyId && facutyId != "ທັງໝົດ") 
        // loadDataDeparments({ variables: { where: { isDeleted: false, faculty: e.target.value, assessmentYear: accessmentRedux } } })
        // }
    };

    /* Select Department */
    const _selectDepartment = (e) => {
        setDepartmentId(e.target.value);
        // loadDataMajors({ variables: { where: { isDeleted: false, department: e.target.value, assessmentYear: accessmentRedux } } })

    };

    const _selectMajor = (e) => {
        setMajorId(e.target.value)
    }

    const _selectCourse = (e) => {
        setCourseId(e.target.value);
    };

    const _selectYear = (e) => {
        setYearLevel(e.target.value);
        // setStudents([])
    };

    const _selectSemester = (e) => {
        setSemester(e.target.value)
    };

    const _selectClassRoom = (e) => {
        setClassRoom(e.target.value)
    };

    const _inputFirstName = (e) => {
        if (e.key === "Enter") setFirstNameL(e.target.value);
    };
    return (
        <div>
            <Row >
                <Col sm={3}>
                    <p style={{ padding: 0, marginBottom: 10 }}>ຄະນະ</p>
                    <select
                        className="form-select"
                        style={{ color: "#8D8D8D" }}
                        onChange={(e) => {
                            _selectFaculty(e);
                        }}
                        value={facutyId}
                        disabled={dataUser?.role !== "ADMIN" ? true : false}
                    >
                        <option hidden value="undefined">
                            ເລືອກຄະນະ...
                        </option>
                        <option value="">ທັງໝົດ</option>
                        {dataFaculty &&
                            dataFaculty.map((item, index) => (
                                <option key={index} value={item?.id}>
                                    {item.name}
                                </option>
                            ))}
                    </select>
                </Col>
                <Col sm={3}>
                    <p style={{ padding: 0, marginBottom: 10 }}>ພາກວິຊາ</p>
                    <select
                        className="form-select"
                        style={{ color: "#8D8D8D" }}
                        onChange={(e) => { _selectDepartment(e) }}
                        value={departmentId}
                        disabled={dataUser?.role !== "DEP_ADMIN" ? false : true}
                    >
                        <option hidden value="undefined">
                            ເລືອກພາກວິຊາ...
                        </option>
                        <option value="">ທັງໝົດ</option>
                        {departmentData?.departments &&
                            departmentData?.departments.map((item, index) => (
                                <option key={index} value={item?.id}>{item?.name}</option>
                            ))}
                    </select>
                </Col>
                <Col sm={3}>
                    <p style={{ padding: 0, marginBottom: 10 }}>ສາຂາວິຊາ</p>
                    <select
                        className="form-select"
                        style={{ color: "#8D8D8D" }}
                        onChange={(e) => { _selectMajor(e) }}
                        value={majorId}
                        disabled={dataUser?.role !== "DEP_ADMIN" ? false : true}
                    >
                        <option hidden value="undefined">
                            ເລືອກສາຂາວິຊາ...
                        </option>
                        <option value="">ທັງໝົດ</option>
                        {majorData?.majors &&
                            majorData?.majors?.data.map((item, index) => (
                                <option key={index} value={item?.id}>{item?.name}</option>
                            ))}
                    </select>
                </Col>
                <Col sm={3}>
                    <p style={{ padding: 0, marginBottom: 10 }}>ປີຮຽນ</p>
                    <select
                        className="form-select"
                        style={{ color: "#8D8D8D" }}
                        value={yearLevel}
                        onChange={(e) => _selectYear(e)}
                    >
                        <option value="">ທັງໝົດ</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                    </select>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    <p style={{ padding: 0, marginBottom: 10 }}>ຫ້ອງຮຽນ</p>
                    <select
                        className="form-select"
                        style={{ color: "#8D8D8D" }}
                        value={classRoom}
                        onChange={(e) => _selectClassRoom(e)}
                    >
                        <option value="">ທັງໝົດ</option>
                        {classRoomData?.classRooms?.data.length > 0 &&
                            classRoomData?.classRooms?.data.map((item, index) => (
                                <option key={index} value={item?.id}>
                                    {item?.name}
                                </option>
                            ))}
                    </select>
                </Col>
                {register === "REGISTER" ?
                    <Col sm={3}>
                        <p style={{ padding: 0, marginBottom: 10 }}>ລະຫັດນັກສຶກສາ</p>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <input
                                type="text"
                                className="form-control"
                                value={firstNameL}
                                onChange={(e) => setFirstNameL(e?.target?.value ?? "")}
                                placeholder="ປ້ອນລະຫັດນັກສຶກສາ..."
                                onKeyUp={(e) => _inputFirstName(e)}
                            />
                            <div style={{ width: 16 }} />
                            <CustomButton
                                confirm
                                searchIcon
                                width={50}
                                height={38}
                                onClick={() => _handleSearchFromStudent()}
                            />
                        </div>
                    </Col>
                    :
                    <Col sm={3}>
                        <p style={{ padding: 0, marginBottom: 10 }}>ວິຊາ</p>
                        <select
                            className="form-select"
                            style={{ color: "#8D8D8D" }}
                            onChange={(e) => _selectCourse(e)}
                        >
                            <option hidden value="">
                                ເລືອກວິຊາ...
                            </option>
                            <option value="">ທັງໝົດ</option>
                            {dataCourses?.length > 0 &&
                                dataCourses?.map((item, index) => (
                                    <option key={index} value={item?.id}>
                                        {item?.title}
                                    </option>
                                ))}
                        </select>
                    </Col>
                }
            </Row>
        </div>);
}
