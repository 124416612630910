import { gql } from "apollo-boost";

export const REGISTRATIONS = gql`
  query {
    grades {
      data {
        id
        student {
          id
          userId
          firstNameL
          lastNameL
          firstNameE
          lastNameE
          role
          yearLevel
          assessmentYear
          email
          phoneMobile
        }
        course {
          courseCode
          id
          title
        }
      }
      total
    }
  }
`;

export const GRADES = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        id
        numberTest
        student {
          id
          userId
          firstNameL
          lastNameL
          firstNameE
          lastNameE
          birthday
          role
          yearLevel
          assessmentYear
          email
          phoneMobile
          gender
        }
        course {
          courseCode
          id
          title
          moodleCourseId
          isDeleted
          year
        }
        faculty{
          id
          name
        }
        department{
          id
          name
        }
        createdAt
        updatedAt
        createdBy
        note
      }
      total
    }
  }
`;

export const STUDENT_TO_CHECK = gql`
  query Registrations($where: RegistrationWhereInput) {
    registrations(where: $where) {
      data {
        id
        course {
          id
          courseCode
          year
          semester
          dayTimeIndexes {
            day
            timeIndexes
          }
        }
      }
    }
  }
`;

export const CREATE_REGISTRATION = gql`
  mutation CreateGrade($data: CustomGradeCreateInput!) {
    createGrade(data: $data) {
      id
    }
  }
`;

export const CREATE_MANY_REGISTRATION = gql`
  mutation CreateManyGrade($data: CreateManyGradeInput) {
    createManyGrade(data: $data) {
      message
      all
      success
      cannotInsert{
        userId
        firstNameL
        lastNameL
      }
    }
  }
`;

export const UPDATE_REGISTRATION = gql`
  mutation UpdateRegistration(
    $data: RegistrationUpdateInput!
    $where: RegistrationWhereUniqueInput!
  ) {
    updateRegistration(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_REGISTRATION = gql`
  mutation DeleteGrade($where: GradeWhereUniqueInput!) {
    deleteGrade(where: $where) {
      id
    }
  }
`;
