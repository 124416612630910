import React, { useEffect, useState } from "react";
import { Stepper, Step, StepLabel } from "@mui/material";

const steps = ["ອາຈານ", "ພາກວິຊາ", "ຄະນະ", "ມຊ"];

const CustomizedSteppers = ({ status }) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (status === "TEACHER_SCORING" || status === "DEPARTMENT_REJECTED") {
      setActiveStep(0);
    } else if (status === "TEACHER_SUBMITED" || status === "FACULTY_REJECTED") {
      setActiveStep(1);
    } else if (
      status === "DEPARTMENT_SUBMITED" ||
      status === "ADMIN_REJECTED"
    ) {
      setActiveStep(2);
    } else if (status === "FACULTY_SUBMITED") {
      setActiveStep(3);
    }
  }, [status]);

  return (
    <div style={{ width: "100%" }}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              sx={{
                fontSize: "20px",
              }}
              style={{
                backgroundColor: "#FFF",
                color: "white",
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default CustomizedSteppers;
