import React, { useState } from "react";
import { Form, Row, Col, Modal } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { useMutation } from "@apollo/react-hooks";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { LOGIN_USER } from "../../apollo/user";
import Const, { USER_KEY } from "../../consts";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/contrant";

function Login() {
  const { history } = useReactRouter();
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [userIdStatus, setUserIdStatus] = useState(true);
  const [passwordStatus, setPasswordStatus] = useState(true);
  const [loginStatus, setLoginStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [helper, setHelper] = useState(false);
  const showHelper = () => setHelper(true);
  const [loginUser] = useMutation(LOGIN_USER, {
    variables: { data: { userId, password } },
  });

  const _onEnterKey = (e) => {
    if (e.key === "Enter") {
      _handleSubmit();
    }
  };

  const _handleSubmit = async () => {
    setIsLoading(true);
    if (
      (userId === "" || userId.trim() === "") &&
      (password === "" || password.trim() === "")
    ) {
      setPasswordStatus(false);
      setUserIdStatus(false);
      setIsLoading(false);
    } else if (userId === "" || userId.trim() === "") {
      setUserIdStatus(false);
      setIsLoading(false);
    } else if (password === "" || password.trim() === "") {
      setPasswordStatus(false);
      setIsLoading(false);
    } else {
      if (userId === "" || userId === " " || userId === null) {
        setUserIdStatus(false);
        setIsLoading(false);
      } else {
        if (password === "" || password === " " || password === null) {
          setPasswordStatus(false);
          setIsLoading(false);
        } else {
          setPasswordStatus(true);
          setUserIdStatus(true);
          try {
            //  const response = await loginUser();
            const response = await axios.post(
              NEW_API_URL + `auth/login?userId=${userId}&password=${password}`,
              {
                headers: {
                  api_key: API_KEY,
                },
              }
            );
            const user = await response?.data;

            if (user) {
              if (user.data) {
                user.data.id = user.data?._id;

                if (user.data?.role === "FAC_ADMIN_TEACHER") {
                  user.data.role = "FAC_ADMIN";
                }
                if (user.data?.role === "DEP_ADMIN_TEACHER") {
                  user.data.role = "DEP_ADMIN";
                }
                if (user.data?.role === "ADMIN_TEACHER") {
                  user.data.role = "ADMIN";
                }

                if (user.data.department) {
                  user.data.department.id = user.data.department?._id;
                }

                if (user.data.faculty) {
                  user.data.faculty.id = user.data.faculty?._id;
                }
              }
            }

            if (user) {
              if (
                user?.data?.role === "ADMIN" ||
                user?.data?.role === "FAC_ADMIN" ||
                user?.data?.role === "DEP_ADMIN"
              ) {
                localStorage.setItem(USER_KEY, JSON.stringify(user));
                setIsLoading(false);
                history.push("students-prere");
                window.location.reload();
              }
              if (user?.data?.role === "DEAN") {
                localStorage.setItem(USER_KEY, JSON.stringify(user));
                setIsLoading(false);
                history.push("/dashboard-page");
                window.location.reload();
              } else {
                setIsLoading(false);
              }
            }
          } catch (err) {
            setIsLoading(false);
          }
        }
      }
    }
  };

  const _handleChangeUserId = (e) => {
    setUserId(e.target.value);
    setUserIdStatus(true);
    setLoginStatus(true);
  };

  const _handleChangePassword = (e) => {
    setPassword(e.target.value);
    setPasswordStatus(true);
    setLoginStatus(true);
  };

  return (
    <div className="bg">
      <div className="row">
        <div className="login">
          <div>
            <img
              style={{ marginTop: 20, width: 150, height: "150px" }}
              src="/assets/new_logo_nuol.png"
            />
            <h1>
              <b>ມະຫາວິທະຍາໄລແຫ່ງຊາດ</b>
            </h1>
          </div>
          <Form noValidate style={{ width: "100%", paddingTop: 10 }}>
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <div className="text-center">
                <input
                  className="newinput"
                  type="text"
                  value={userId}
                  onChange={_handleChangeUserId}
                  placeholder="ໄອດີ"
                />
                {!userIdStatus && (
                  <p style={{ color: "red", fontSize: 14 }}>ກະລຸນາປ້ອນໄອດີ</p>
                )}
              </div>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextPassword">
              <div className="text-center m-0 o-0">
                <input
                  className="newinput mt-3"
                  type="password"
                  onChange={_handleChangePassword}
                  value={password}
                  placeholder="ລະຫັດຜ່ານ"
                  onKeyDown={_onEnterKey}
                  tabIndex="0"
                />
                {!passwordStatus && (
                  <p style={{ color: "red", fontSize: 14 }}>
                    ກະລຸນາປ້ອນລະຫັດຜ່ານ
                  </p>
                )}
              </div>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="2" />
              <Form.Label column sm="10">
                {!loginStatus && (
                  <p style={{ color: "red", fontSize: 14, marginLeft: 24 }}>
                    * ໄອດີ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ, ກະລຸນາລອງໃໝ່ອີກຄັ້ງ
                  </p>
                )}
              </Form.Label>
            </Form.Group>
          </Form>
          <Form.Group
            className="w-100 d-flex justify-content-center m-0"
            as={Row}
            controlId="formPlaintextPassword"
          >
            <Button
              sx={{
                paddingBottom: "10px",
                paddingTop: "10px",
                width: "90%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => _handleSubmit()}
              variant="contained"
              // disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                "ເຂົ້າສູ່ລະບົບ"
              )}
            </Button>
          </Form.Group>

          <div className="text-dark text-center mt-3">
            ມີ​ບັນ​ຫາ​ເຂົ້າ​ສູ່​ລະ​ບົບ?
            <span
              style={{
                color: "#057CAE",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => showHelper(true)}
            >
              {" "}
              ຊ່ວຍ​ເຫຼືອ
            </span>
            <br></br>
            <span style={{ fontSize: 12 }}>{Const.VERSION_LOGIN_PAGE}</span>
          </div>
        </div>
      </div>
      <Modal show={helper} onHide={() => setHelper(false)} animation={false}>
        <Modal.Header
          style={{
            backgroundColor: "#057CAE",
            color: "white",
            justifyContent: "center",
            fontSize: 40,
            fontWeight: "bold",
          }}
        >
          ບັນຫາການເຂົ້າສູ່ລະບົບ
        </Modal.Header>
        <Modal.Body style={{ padding: "10px 40px" }}>
          <p>
            1. ກວດສອບ ໄອດີ ແລະ ລະຫັດຜ່ານຂອງທ່ານ ໃຫ້ຖືກຕ້ອງ. ຖ້າທ່ານລືມລະຫັດຜ່ານ,
            ກະລຸນາກົດປຸ່ມ “ລືມລະຫັດຜ່ານ” ທີ່ໜ້າລັອກອິນ.
          </p>
          <p>
            2. ຖ້າທ່ານຫຼືມຄົບໍ່ຖືກແກ້ໄຂບໍ່ໄດ້, ກະລຸນາຕິດຕໍ່ທີມຊ່ວຍເຫຼືອຂອງທ່ານ.
          </p>
          <p>3. ກວດສອບອີເມວຂອງທ່ານເພື່ອດູຄຳແນະນຳຂອງລະບົບ.</p>
          <div
            className="text-right"
            style={{ cursor: "pointer", color: "blue", fontWeight: "bold" }}
            onClick={() => setHelper(false)}
          >
            ປິດ
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Login;
