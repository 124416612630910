import React from "react";
import useReactRouter from "use-react-router";
import { Col, Row } from "react-bootstrap";
import Consts from "../../consts";
import Routers from "../../consts/router";
import { CustomContainer } from "../../common";
import { formatDate } from "../../common/super";
import {
  onConvertStudentInfo,
  convertGenderNam,
  onConvertEducationLevel,
  convertGender,
  convertGenderNamEN,
  convertStatus,
} from "../../helpers/user";

export default function PreregistrationStudentDetailFacultyList() {
  const { history } = useReactRouter();
  const dataStudent = history.location.state;

  const styleHide = {
    overflowX: "hidden",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  };

  return (
    <div>
      {/* {dataStudent && <Loading />} */}
      <Col sm={12} style={{ paddingBottom: 20, paddingTop: 20 }}>
        <div
          href="#"
          rel="noopener noreferrer"
          onClick={() => {
            history.push(Routers.PRERE_STUDENT_FAC_LIST+Routers.PAGE_GINATION);
            window.location.reload();
          }}
        >
          ລົງທະບຽນນັກສຶກສາ
        </div>{" "}
        / {dataStudent && dataStudent ? dataStudent?.firstNameL : "-"}{" "}
        {dataStudent?.lastNameL}
      </Col>
      <Row style={{ padding: 10 }}>
        {/* START LAYOUT 1 */}
        <Col sm={12}>
          <CustomContainer>
            <Row>
              <Col style={{ borderRight: "1px solid #DFDFDF" }}>
                <p style={{ fontSize: "18px", color: "#057CAE" }}>
                  ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ
                </p>
                <img alt=""
                  style={{ margin: "0 auto" }}
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
                  }
                  className="browserImage"
                />

                <Row className="p-0" style={{ fontSize: 14, paddingTop: 20 }}>
                  <Col sm="6">ລະຫັດນັກສຶກສາ</Col>
                  <Col sm="6">{dataStudent?.studentNumber ?? "-"}</Col>

                  {/* <Col sm='6'>ລະຫັດສອບເສັງນັກສຶກສາ</Col>
                  <Col sm='6'>{dataStudent?.examCode ?? '-'}</Col> */}

                  <Col sm="6">ຄະນະ</Col>
                  <Col sm="6">{dataStudent?.faculty?.name ?? "-"}</Col>
                  <Col sm="6">ພາກວິຊາ</Col>
                  <Col sm="6">{dataStudent?.department?.name ?? "-"}</Col>
                  <Col sm="6">ສາຂາວິຊາ</Col>
                  <Col sm="6">{dataStudent?.major?.name ?? "-"}</Col>

                  <Col sm="6">ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ</Col>
                  <Col sm="6">
                    {onConvertStudentInfo(dataStudent?.studentInfo) ?? "-"}
                  </Col>

                  <Col sm="6">ລະດັບ</Col>
                  <Col sm="6">
                    {onConvertEducationLevel(dataStudent?.educationLevel) ??
                      "-"}
                  </Col>
                </Row>
              </Col>

              {/*--------------------------------------  */}
              <Col>
                <div style={{ fontSize: "18px", color: "#057CAE" }}>
                  ຂໍ້ມູນ​ທົ່ວໄປ
                </div>

                <Row style={{ fontSize: 14 }}>
                  <Col sm="6">ຊື່</Col>
                  <Col sm="6">
                    {convertGenderNam(dataStudent?.gender)}{" "}
                    {dataStudent?.firstNameL ?? "-"}
                  </Col>

                  <Col sm="6">ນາມສະກຸນ</Col>
                  <Col sm="6">{dataStudent?.lastNameL ?? "-"}</Col>

                  <Col sm="6">ຊື່ (ພາສາອັງກິດ)</Col>
                  <Col sm="6">
                    {convertGenderNamEN(dataStudent?.gender)}{" "}
                    {dataStudent?.firstNameE ?? "-"}
                  </Col>

                  <Col sm="6">ນາມສະກຸນ (ພາສາອັງກິດ)</Col>
                  <Col sm="6">{dataStudent?.lastNameE ?? "-"}</Col>

                  <Col sm="6">ວັນເດືອນປີເກີດ</Col>
                  <Col sm="6">{formatDate(dataStudent?.birthday) ?? "-"}</Col>

                  <Col sm="6">ເພດ</Col>
                  <Col sm="6">{convertGender(dataStudent?.gender) ?? "-"}</Col>

                  <Col sm="6">ສະຖານະ</Col>
                  <Col sm="6">
                    {convertStatus(dataStudent?.maritualStatus) ?? "-"}
                  </Col>

                  <Col sm="6">ສັນຊາດ</Col>
                  <Col sm="6">{dataStudent?.nationality ?? "-"}</Col>
                  <Col sm="6">ຊົນເຜົ່າ</Col>
                  <Col sm="6">{dataStudent?.tribe?.trib_name ?? "-"}</Col>

                  <Col sm="6">ສາສະໜາ</Col>
                  <Col sm="6">{dataStudent?.religion ?? "-"}</Col>

                  <Col sm="6">ເບີໂທເຮືອນ</Col>
                  <Col sm="6">{dataStudent?.phoneHome ?? "-"}</Col>

                  <Col sm="6">ເບີໂທລະສັບມືຖື</Col>
                  <Col sm="6">{dataStudent?.phoneMobile ?? "-"}</Col>
                </Row>

                <div
                  style={{ fontSize: "18px", color: "#057CAE", marginTop: 20 }}
                >
                  ທີ່​ຢູ່​ປະ​ຈຸ​ບັນ
                </div>
                <Row style={{ fontSize: 14 }}>
                  <Col sm="6">ແຂວງ</Col>
                  <Col sm="6">{dataStudent?.address?.province ?? "-"}</Col>

                  <Col sm="6">ເມືອງ</Col>
                  <Col sm="6">{dataStudent?.address?.district ?? "-"}</Col>

                  <Col sm="6">ບ້ານ</Col>
                  <Col sm="6">{dataStudent?.address?.village ?? "-"}</Col>
                </Row>
              </Col>
              {/* --------------------------------------------------- */}
              <Col style={{ borderLeft: "1px solid #DFDFDF" }}>
                <div style={{ fontSize: "18px", color: "#057CAE" }}>
                  ສະຖານທີ່ເກີດ
                </div>

                <Row style={{ fontSize: 14 }}>
                  <Col sm="6">ແຂວງ</Col>
                  <Col sm="6">{dataStudent?.birthAddress?.province ?? "-"}</Col>

                  <Col sm="6">ເມືອງ</Col>
                  <Col sm="6">{dataStudent?.birthAddress?.district ?? "-"}</Col>

                  <Col sm="6">ບ້ານ</Col>
                  <Col sm="6">{dataStudent?.birthAddress?.village ?? "-"}</Col>
                </Row>

                <div
                  style={{ fontSize: "18px", color: "#057CAE", marginTop: 20 }}
                >
                  ກໍລະນີສຸກເສີນຕິດຕໍ່
                </div>

                <Row style={{ fontSize: 14 }}>
                  <Col sm="6">ພົວພັນທ່ານ</Col>
                  <Col sm="6">{dataStudent?.contactName ?? "-"}</Col>

                  <Col sm="6">ເບີໂທຕິດຕໍ່</Col>
                  <Col sm="6">{dataStudent?.emergencyPhone ?? "-"}</Col>

                  <Col sm="6">ອາໄສຢູ່ຮ່ວມກັນ</Col>
                  <Col sm="6">
                    {dataStudent?.stayTogether === true ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                  </Col>
                  <Col sm="6">ຫໍ​ພັກ​ນັກ​ສຶກ​ສາ</Col>
                  <Col sm="6">
                    {dataStudent?.dormitory === "YES"
                      ? "ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"
                      : "ບໍ່ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"}
                  </Col>
                </Row>

                <div
                  style={{ fontSize: "18px", color: "#057CAE", marginTop: 20 }}
                >
                  ຂໍ້​ມູນ​ຜູ້​ປົກ​ຄອງ
                </div>

                <Row style={{ fontSize: 14 }}>
                  <Col sm="6">ຊື ແລະ ນາມສະກຸນພໍ່</Col>
                  <Col sm="6">{dataStudent?.fatherNameAndSurname ?? "-"}</Col>

                  <Col sm="6">ຊື່ ແລະ ນາມສະກຸນແມ່</Col>
                  <Col sm="6">{dataStudent?.motherNameAndSurname ?? "-"}</Col>

                  <Col sm="6">ເບີໂທລະສັບພໍ່/ແມ່</Col>
                  <Col sm="6">{dataStudent?.parentTelephone ?? "-"}</Col>

                  <Col sm="6">ແຂວງ</Col>
                  <Col sm="6">
                    {dataStudent?.parentAddress?.province ?? "-"}
                  </Col>

                  <Col sm="6">ເມືອງ</Col>
                  <Col sm="6">
                    {dataStudent?.parentAddress?.district ?? "-"}
                  </Col>

                  <Col sm="6">ບ້ານ</Col>
                  <Col sm="6">{dataStudent?.parentAddress?.village ?? "-"}</Col>
                </Row>
              </Col>
            </Row>

            {/* ---------------------------------------- */}
            <Row
              style={{
                // width: "900px",
                borderTop: "3px solid #DFDFDF",
                paddingTop: "10px",
              }}
            >
              {" "}
              {dataStudent?.studentType !== "OUT_PLAN" ? (
                <Col style={{ borderRight: "1px solid #DFDFDF" }}>
                  <div style={{ fontSize: "18px", color: "#057CAE" }}>
                    ສຳຫຼັບນັກ​ສຶກ​ສາ/ພະ​ນັກ​ງານທຶນ​ແບ່ງ​ປັນ​
                  </div>

                  <Row className="p-0" style={{ fontSize: 14 }}>
                    <Col sm="6">ທຶນແບ່ງປັນ</Col>
                    <Col sm="6">{dataStudent?.scholarship ?? "-"}</Col>

                    <Col sm="6">ຈົບຈາກແຂວງ</Col>
                    <Col sm="6">{dataStudent?.graduateFromProvince ?? "-"}</Col>

                    <Col sm="6">ຈົບຈາກໂຮງຮຽນ</Col>
                    <Col sm="6">{dataStudent?.graduateFromSchool ?? "-"}</Col>

                    <Col sm="6">ສົກສຶກສາ</Col>
                    <Col sm="6">{dataStudent?.semesterYear ?? "-"}</Col>

                    <Col sm="6">ເລກທີ່ຂໍ້ຕົກລົງເສັງໄດ້ທຶນ ມຊ</Col>
                    <Col sm="6">
                      {dataStudent?.scholarshipAgreementNo ?? "-"}
                    </Col>

                    <Col sm="6">ເລກທີໃບນຳສົ່ງ</Col>
                    <Col sm="6">{dataStudent?.transferLetterNo ?? "-"}</Col>
                  </Row>
                </Col>
              ) : (
                <Col style={{ borderRight: "1px solid #DFDFDF" }}>
                  <p style={{ fontSize: "18px", color: "#057CAE" }}>
                    ສຳຫຼັບນັກ​ສຶກ​ສາ​ຈ່າຍ​ຄ່າ​ຮຽນ
                  </p>

                  <Row style={{ fontSize: 14 }}>
                    <Col sm="6">ຈົບຈາກແຂວງ</Col>
                    <Col sm="6">{dataStudent?.graduateFromProvince ?? "-"}</Col>

                    <Col sm="6">ຈົບຈາກໂຮງຮຽນ</Col>
                    <Col sm="6"> {dataStudent?.graduateFromSchool ?? "-"}</Col>

                    <Col sm="6">ສົກສຶກສາ</Col>
                    <Col sm="6">{dataStudent?.semesterYear ?? "-"}</Col>
                  </Row>
                </Col>
              )}
              {/*--------------------------------------  */}
              <Col>
                <div style={{ fontSize: "18px", color: "#057CAE" }}>
                  ເອກະສານຄັດຕິດ
                </div>

                <Row style={{ fontSize: 14 }}>
                  <Col sm="6">
                    ບັດ​ປະ​ຈຳ​ຕົວ ຫຼື ສຳ​ມ​ະ​ໂນ​ຄົວ (ພຣະ: ໜັງສືສຸທິ)
                  </Col>
                  {dataStudent?.idCardOrFamilyBookUrl && dataStudent?.idCardOrFamilyBookUrl?.url?.split('.').pop() === "pdf" ?
                    <Col sm="6" style={styleHide}>
                      <a target="_blank" rel="noopener noreferrer"  href={Consts.URL_IMAGE + dataStudent?.idCardOrFamilyBookUrl?.url}> ເປີດໄຟລ PDF</a>
                    </Col> : dataStudent?.idCardOrFamilyBookUrl?.url ?
                      <Col sm="6" style={styleHide}>
                        ຮູບນ້ອຍ:
                        <a target="_blank" rel="noopener noreferrer" href={Consts.URL_IMAGE_MEDIUM + dataStudent?.idCardOrFamilyBookUrl?.url}>
                          <img alt=""src={Consts.URL_IMAGE_SMALL + dataStudent?.idCardOrFamilyBookUrl?.url} width="40" height="40" />
                        </a>
                        <div style={{ width: 8 }} />
                        ຕົ້ນສະບັບ:
                        <a target="_blank" rel="noopener noreferrer" href={Consts.URL_IMAGE + dataStudent?.idCardOrFamilyBookUrl?.url}>
                          <img alt=""src={Consts.URL_IMAGE + dataStudent?.idCardOrFamilyBookUrl?.url} width="40" height="40" />
                        </a>
                      </Col> : <Col sm="6" style={styleHide}>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </Col>
                  }
                  <div style={{ height: 8 }} />

                  {/* <Col sm='6'>ໄຟລຮູບ ໃບ​ຄະ​ແນນ</Col>
                  <Col sm='6' style={styleHide}>
                    <u>{dataStudent?.scorecardUrl?.url}</u>
                  </Col> */}

                  <Col sm="6">ໄຟລຮູບ ໃບ​ປະ​ກາດ</Col>
                  {dataStudent?.certificateUrl && dataStudent?.certificateUrl?.url?.split('.').pop() === "pdf" ?
                    <Col sm="6" style={styleHide}>
                      <a target="_blank" rel="noopener noreferrer"  href={Consts.URL_IMAGE + dataStudent?.certificateUrl?.url}> ເປີດໄຟລ PDF</a>
                    </Col> : dataStudent?.certificateUrl?.url ?
                      <Col sm="6" style={styleHide}>
                        ຮູບນ້ອຍ:
                        <a target="_blank" rel="noopener noreferrer" href={Consts.URL_IMAGE_MEDIUM + dataStudent?.certificateUrl?.url}>
                          <img alt="" src={Consts.URL_IMAGE_SMALL + dataStudent?.certificateUrl?.url} width="40" height="40" />
                        </a>
                        <div style={{ width: 8 }} />
                        ຕົ້ນສະບັບ:
                        <a target="_blank" rel="noopener noreferrer" href={Consts.URL_IMAGE + dataStudent?.certificateUrl?.url}>
                          <img alt="" src={Consts.URL_IMAGE + dataStudent?.certificateUrl?.url} width="40" height="40" />
                        </a>
                      </Col> : <Col sm="6" style={styleHide}>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </Col>
                  }
                  <div style={{ height: 8 }} />

                  <Col sm="6">ໄຟລຮູບ ບັດ​ເຂົ້າ​ຫ້ອງ​ເສັງ</Col>
                  {dataStudent?.testCardUrl && dataStudent?.testCardUrl?.url?.split('.').pop() === "pdf" ?
                    <Col sm="6" style={styleHide}>
                      <a target="_blank" rel="noopener noreferrer" href={Consts.URL_IMAGE + dataStudent?.testCardUrl?.url}> ເປີດໄຟລ PDF</a>
                    </Col> : dataStudent?.testCardUrl?.url ?
                      <Col sm="6" style={styleHide}>
                        ຮູບນ້ອຍ: <a target="_blank" rel="noopener noreferrer" href={Consts.URL_IMAGE_MEDIUM + dataStudent?.testCardUrl?.url}>
                          <img alt="" src={Consts.URL_IMAGE_SMALL + dataStudent?.testCardUrl?.url} width="40" height="40" />
                        </a>
                        <div style={{ width: 8 }} />
                        ຕົ້ນສະບັບ:
                        <a target="_blank" rel="noopener noreferrer"  href={Consts.URL_IMAGE + dataStudent?.testCardUrl?.url}>
                          <img alt="" src={Consts.URL_IMAGE + dataStudent?.testCardUrl?.url} width="40" height="40" />
                        </a>
                      </Col> : <Col sm="6" style={styleHide}>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </Col>
                  }
                  
                </Row>
              </Col>
            </Row>
          </CustomContainer>
        </Col>
        {/* END LAYOUT 1 */}
        {/**
         *
         * show image
         *
         */}
      </Row>
    </div>
  );
}
