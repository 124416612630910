import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Modal, Button } from "react-bootstrap";
import Consts from "../../../consts";
import { UPDATE_DEPARTMENT_REGISTER } from "../../../apollo/deparment";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

export default function EditDepartmentTimeRegister({
  showUploadView,
  _handleUploadViewClose,
  data,
}) {
  // State
  const [startDate, setStartDate] = useState(
    moment(data?.startDateRegister).format("YYYY-MM-DD") ??
      moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(data?.endDateRegister).format("YYYY-MM-DD") ??
      moment(new Date()).format("YYYY-MM-DD")
  );

  // Apollo
  const [updateDepartmentDateRegister] = useMutation(
    UPDATE_DEPARTMENT_REGISTER
  );

  // Function
  const _handleChangeStartDate = (e) => {
    setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
  };
  const _handleChangeEndDate = (e) => {
    setEndDate(moment(e.target.value).format("YYYY-MM-DD"));
  };
  const _onEdit = async () => {
    await updateDepartmentDateRegister({
      variables: {
        data: {
          startDateRegister: startDate,
          endDateRegister: endDate,
        },
        where: {id: data.id},
      },
    });
    _handleUploadViewClose();
    window.location.reload(true);
  };

  return (
    <div>
      <Modal show={showUploadView} onHide={_handleUploadViewClose} size="lg">
        <Modal.Title
          style={{ textAlign: "center", paddingTop: 20, fontWeight: "bold" }}
        >
          ແກ້ໄຂເວລາລົງທະບຽນ
        </Modal.Title>
        <Modal.Body
          style={{
            marginRight: 50,
            padding: 50,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
            }}
          >
            <div>ວັນທີເປີດໃຫ້ລົງທະບຽນ</div>
            <div style={{ paddingLeft: 24 }}>
              <TextField
                id="date"
                type="date"
                name="startDate"
                defaultValue={moment(startDate).format("YYYY-MM-DD")}
                onChange={(e) => _handleChangeStartDate(e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div style={{ paddingLeft: 24 }}>ວັນທີສິ້ນສຸດໃຫ້ລົງທະບຽນ</div>
            <div style={{ paddingLeft: 24 }}>
              <TextField
                id="date"
                type="date"
                name="startDate"
                defaultValue={moment(endDate).format("YYYY-MM-DD")}
                onChange={(e) => _handleChangeEndDate(e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div className="row" style={{ paddingBottom: 24 }}>
            <div style={{ padding: 15 }} className="col">
              <Button
                onClick={_handleUploadViewClose}
                style={{
                  width: 200,
                  backgroundColor: "#fff",
                  color: Consts.SECONDARY_COLOR,
                  borderColor: Consts.SECONDARY_COLOR,
                }}
              >
                ຍົກເລີກ
              </Button>
            </div>
            <div style={{ padding: 15 }} className="col">
              <Button
                style={{
                  width: 200,
                  backgroundColor: Consts.SECONDARY_COLOR,
                  color: "#fff",
                  borderColor: Consts.SECONDARY_COLOR,
                }}
                onClick={() => _onEdit()}
              >
                ແກ້ໄຂ
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
