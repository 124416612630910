import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
  FormHelperText,
  Box,
  CircularProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Consts, { USER_KEY } from "../../../consts";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";
import CustomMessage from "../../../common/CustomStatusMessage";
import { SUBJECT_GROUP } from "../../../consts/SubjectGroup";
import { useSelector } from "react-redux";

const CourseAddModal = ({
  location,
  selected,
  setSelected,
  showComnfirm,
  setShowConfirm,
  setOnSuccess,
  isLoading,
  setIsLoading,
}) => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const [year, setYear] = useState("");
  const [semester, setSemester] = useState("");
  const [yearError, setYearError] = useState(false);
  const [semesterError, setSemesterError] = useState(false);
  const [subjectList, setSubjectList] = useState([]);

  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const prevSelected = useRef([]);

  const accessmentRedux = useSelector((state) => state.accessment.value);

  useEffect(() => {
    const filterSelectId = selected.map((item) => ({ subjectId: item._id }));
    const newIds = filterSelectId.filter(
      (newId) =>
        !subjectList.some((oldId) => oldId.subjectId === newId.subjectId)
    );

    // Check if the new selected items are different from the previous ones
    const selectedChanged =
      JSON.stringify(selected) !== JSON.stringify(prevSelected.current);

    if (newIds.length > 0 && selectedChanged) {
      setSubjectList((prevList) => [...prevList, ...newIds]);
    }

    // Find and remove old IDs that are not in the new filterSelectId
    const updatedSubjectList = subjectList.filter((oldId) =>
      filterSelectId.some((newId) => newId.subjectId === oldId.subjectId)
    );

    // If there's any change in the subjectList, update the state
    if (updatedSubjectList.length !== subjectList.length) {
      setSubjectList(updatedSubjectList);
    }

    // Update the previous selected items
    prevSelected.current = selected;
  }, [selected, subjectList]);

  useEffect(() => {
    if (snackbarOpen) {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }
  }, [snackbarOpen]);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "year") {
      setYear(value);
      setYearError(value === "");
    } else if (name === "semester") {
      setSemester(value);
      setSemesterError(value === "");
    }
  };

  const handleSubjectGroup = (e, id) => {
    const newItem = {
      subjectId: id,
      subjectGroup: e,
    };
    const updatedList = subjectList.filter((item) => item.subjectId !== id);
    updatedList.push(newItem);
    setSubjectList(updatedList);
  };

  const handleSumit = async (event) => {
    event.preventDefault();

    if (year === "") {
      setYearError(true);
    } else if (semester === "") {
      setSemesterError(true);
    } else {
      const body = {
        faculty: location.faculty,
        department: location.department,
        major: location._id,
        subjectList: subjectList,
        year: year,
        semester: semester,
        assessmentYear: accessmentRedux,
      };

      setIsLoading(true);

      try {
        const response = await axios.post(
          NEW_API_URL + "subject/course",
          body,
          {
            headers: {
              api_key: API_KEY,
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          setYear("");
          setSemester("");
          setSelected([]);
          setSubjectList([]);
          setShowConfirm(false);
          setOnSuccess(true);
          showSnackbar("ເພີ່ມຫລັກສູດສຳເລັດ", "success");
        }
      } catch (err) {
        showSnackbar("ເພີ່ມຫລັກສູກບໍ່ສຳເລັດ", "error");
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <div>
      <Dialog
        fullWidth
        open={showComnfirm}
        onClose={() => setShowConfirm(false)}
      >
        <DialogTitle sx={{ color: Consts.PRIMARY_COLOR }}>
          <b>ຈັດລາຍວິຊາເຂົ້າປີ ແລະ ພາກຮຽນ</b>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setShowConfirm(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth size="small" error={yearError}>
                <InputLabel htmlFor="year">ເລືອກປີຮຽນ</InputLabel>
                <Select
                  label="ເລືອກປີຮຽນ"
                  name="year"
                  value={year}
                  onChange={handleChange}
                >
                  <MenuItem value={""}>ເລືອກປີຮຽນ</MenuItem>
                  <MenuItem value={1}>ປີ 1</MenuItem>
                  <MenuItem value={2}>ປີ 2</MenuItem>
                  <MenuItem value={3}>ປີ 3</MenuItem>
                  <MenuItem value={4}>ປີ 4</MenuItem>
                  <MenuItem value={5}>ປີ 5</MenuItem>
                  <MenuItem value={6}>ປີ 6</MenuItem>
                </Select>
                {yearError && <FormHelperText>ກະລຸນາເລືອກປີຮຽນ</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small" error={semesterError}>
                <InputLabel htmlFor="semester">ເລືອກພາກຮຽນ</InputLabel>
                <Select
                  label="ເລືອກພາກຮຽນ"
                  name="semester"
                  value={semester}
                  onChange={handleChange}
                >
                  <MenuItem value={""}>ເລືອກພາກຮຽນ</MenuItem>
                  <MenuItem value={1}>ພາກຮຽນ I</MenuItem>
                  <MenuItem value={2}>ພາກຮຽນ II</MenuItem>
                </Select>
                {semesterError && (
                  <FormHelperText>ກະລຸນາເລືອກພາກຮຽນ</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <p
            style={{
              marginTop: 10,
              marginBottom: 10,
              color: Consts.SECONDARY_COLOR,
            }}
          >
            ຈັດໝວດໝູ່
          </p>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow color="info">
                  <TableCell
                    align="center"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ລະຫັດ
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ຊື່ວິຊາຮຽນ (ພາສາລາວ)
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ໝວດວິຊາ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selected.map((subject, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{subject?.code}</TableCell>
                    <TableCell align="center" sx={{ whiteSpace: "wrap" }}>
                      {subject?.title} ຄວາມຮູ້ພື້ນຖານຂອງຖານຂໍ້ມູນ
                    </TableCell>
                    <TableCell align="center">
                      <Select
                        name="subjectGroup"
                        fullWidth
                        size="small"
                        displayEmpty
                        value={
                          subjectList.find(
                            (item) => item.subjectId === subject._id
                          )?.subjectGroup
                        }
                        onChange={(e) =>
                          handleSubjectGroup(e.target.value, subject._id)
                        }
                        error={
                          subjectList.find(
                            (item) => item.subjectId === subject._id
                          )?.subjectGroup === undefined
                        }
                      >
                        <MenuItem disabled>
                          <em>ເລືອກໝວດວິຊາ</em>
                        </MenuItem>
                        {SUBJECT_GROUP.map((item, index) => (
                          <MenuItem key={index} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {subjectList.find(
                        (item) => item.subjectId === subject._id
                      )?.subjectGroup === undefined && (
                        <FormHelperText sx={{ color: "red" }}>
                          ກະລຸນາເລືອກໝວດວິຊາ
                        </FormHelperText>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-secondary"
            onClick={() => setShowConfirm(false)}
          >
            ຍົກເລີກ
          </button>
          <button
            onClick={handleSumit}
            className="btn btn-primary"
            disabled={
              isLoading ||
              !subjectList.every((item) => item.subjectGroup !== undefined)
            }
          >
            ຢືນຢັນ
          </button>
        </DialogActions>
      </Dialog>
      <CustomMessage
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default CourseAddModal;
