import React from "react";
import { Modal } from "react-bootstrap";
import "../util/index.css";
import recieve from "../../../images/Step_to_recieve.png";
import cancelimg from "../../../images/Step_to_cancel.png";
export default function HelpModal({ show, onHide }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header className="closeModal" closeButton style={{ border: 0 }}>
        <p>ຄູ່ມືແນະນຳສຳລັບການຢືນຢັນການຍອມຮັບ</p>
      </Modal.Header>
      <Modal.Body>
        ສຳລັບການເຮັດວຽກຂອງໜ້າວຽກນີ້ແມ່ນຈະອະທິບາຍລວມໆດັ່ງລຸ່ມນີ້:
        <p>
          1. ສຳລັບການຍອມຮັບ: ເຂົ້າມາທຳອິດແມ່ນສະຖານະຈະຢູ່ທີ່ອາຈານ,
          ກ່ອນຈະທຳການຍອມຮັບຂັ້ນຕອນຕໍ່ຈາກອາຈານແມ່ນ
          ໃຫ້ເລືອກສະຖານະການຍອມເປັນພາກວິຊາກ່ອນ ຈື່ງທຳການຍອມຮັບ.
          ສຳລັບຂັ້ນຕອນຕໍ່ໆໄປກໍ່ຈະຕ້ອງໄດ້ເຮັດຄືກັນຈົນຮອດຂັ້ນຕອນສຸດ
          ທ້າຍກໍຄືວິຊາການ
          <img
            src={recieve}
            alt=""
            style={{
              width: "100%",
              height: "auto",
              border: "1px solid green",
              borderRadius: "30px",
            }}
            className="center"
          />
        </p>
        <p>
          2. ສຳລັບການປະຕິເສດ: ການປະຕິເສດແມ່ນຈະໄດ້ເບີ່ງຕາມສະຖານະ ຕົວຢ່າງ:
          ຖ້າຕ້ອງການປະຕິເສດພາກວິຊາແມ່ນຕ້ອງ
          ໄດ້ເລືອກສະຖານະເປັນພາກວິຊາກ່ອນຈື່ງທຳການປະຕິເສດ.
          ສຳລັບການປະຕິເສດຂັ້ນຕອນອື່ນໆກໍ່ເຮັດຄືກັນຕ້ອງການປະຕິເສດພາກສ່ວນໃດແມ່ນໃຫ້
          ເລືອກສະຖານະເປັນພາກສ່ວນນັ້ນກ່ອນ
          <img
            alt=""
            src={cancelimg}
            style={{
              width: "100%",
              height: "auto",
              border: "1px solid green",
              borderRadius: "30px",
            }}
            className="center"
          />
        </p>
      </Modal.Body>
    </Modal>
  );
}
