import { gql } from "apollo-boost";

export const CLASSROOM_MASTERS = gql`
  query ClassRooms(
    $where: ClassroomInput
    $orderBy: OrderDataInput
    $skip: Int
    $first: Int
  ) {
    classRooms(
      where: $where
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
    total
      data {
        id
        name
        faculty{
          id
          name
        }
        department{
          id
          name
        }
        major{
          id
          name
        }
        year
        note
        createdAt
        updatedAt
      }
    }
  }
`;
export const CLASSROOM_AND_COURSE = gql`
  query ClassRoomAndCourses(
    $where: ClassroomAndCourseInput
    $orderBy: OrderDataInput
    $skip: Int
    $first: Int
  ) {
    classRoomAndCourses(
      where: $where
      orderBy: $orderBy
      skip: $skip
      first: $first
      ){
    data{
      id
      classRoom{
        id
        name
      }
      course{
        id
        title
        courseCode
      }
      createdAt
      faculty {
        id
        name
      }
      department{
        id
        name
      }
      major{
        id
        name
      }
      semester
      year
    }
  }
  }
`;

export const CREATE_CLASSROOM = gql`
  mutation CreateClassRoom($data: ClassroomInput!) {
    createClassRoom(data: $data) {
      id
    }
  }
`
export const CREATE_ASSIGN_STUDENT_TO_CLASSROOM = gql`
  mutation CreateClassroomAndConnectCourse($data: CreateClassroomAndConnectCourseInput!) {
    createClassroomAndConnectCourse(data: $data) {
      id
    }
  }
`

export const CREATE_CLASSROOM_AND_CONNECT_COURSE = gql`
  mutation CreateClassRoomAndCourse($data: ClassroomAndCourseInput!) {
    createClassRoomAndCourse(data: $data) {
      id
    }
  }
`

export const UPDATE_CLASSROOM = gql`
  mutation UpdateClassRoom(
    $data: ClassroomInput!
    $where: ClassroomWhereUniqueInput!
  ) {
    updateClassRoom ( data: $data, where: $where ) {
      id
    }
  }
`

export const DELETE_CLASSROOM = gql`
 mutation DeleteClassRoom(
    $where: ClassroomWhereUniqueInput!
  ) {
    deleteClassRoom (where: $where ) {
      id
    }
  }
`

export const UPDATE_CLASSROOM_AND_COURSE = gql`
 mutation UpdateClassRoomAndCourse(
  $data: ClassroomAndCourseInput!
    $where: ClassroomAndCourseWhereUniqueInput!
  ) {
    updateClassRoomAndCourse ( data: $data, where: $where ) {
      id
    }
  }
`
export const DELETE_CLASSROOM_AND_COURSE = gql`
 mutation DeleteClassRoomAndCourse(
    $where: ClassroomAndCourseWhereUniqueInput!
  ) {
    deleteClassRoomAndCourse (where: $where ) {
      id
    }
  }
`