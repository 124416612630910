import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { CustomContainer } from "../../../common";
import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import { Autorenew } from "@mui/icons-material";
import TeacherBySpecialSubject from "./TeacherBySpecialSubject";
import TeacherByTeachLevel from "./TeacherByTeachLevel";
import { setFilter } from "../../../redux/filtrSlice";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { USER_KEY } from "../../../consts";

const exampleData = [
  {
    value: 1,
    label: "ຈຳນວນຄູ-ອາຈານສອນ ແລະ ພະນັກງານບໍລິຫານ ຕາມຊັ້ນວິຊາສະເພາະ",
  },
  {
    value: 2,
    label: "ຈຳນວນຄູສອນ ແລະ ພະນັກງານບໍລິຫານທີ່ໄດ້ສອນຕາມລະບົບຊັ້ນສອນ",
  },
];

export const TeacherTab = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const filterData = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [formData, setFormData] = useState({
    faculty: filterData.faculty || "",
    department: filterData.department || "",
  });

  const ModernAutocomplete = styled(Autocomplete)({
    ".MuiOutlinedInput-root": {
      backgroundColor: "white",
      "& fieldset": {
        borderColor: "#2196F3", // Set your preferred border color
      },
      "&:hover fieldset": {
        borderColor: "#1565C0", // Set your preferred border color on hover
      },
    },
  });

  const [selectedValue, setSelectedValue] = useState(exampleData[0]);
  const [content, setContent] = useState(null);

  useEffect(() => {
    switch (selectedValue.value) {
      case 1:
        setContent(
          <TeacherBySpecialSubject
            department={formData?.department}
            faculty={formData?.faculty}
          />
        );
        break;
      case 2:
        setContent(
          <TeacherByTeachLevel
            department={formData?.department}
            faculty={formData?.faculty}
          />
        );
        break;
      default:
        setContent(<></>);
        break;
    }
  }, [selectedValue, formData.faculty, formData.department]);

  useEffect(() => {
    let _filterData = {};
    if (formData.faculty !== "") {
      _filterData.faculty = formData.faculty;
    }

    // Check and set department if not null
    if (formData.department !== "") {
      _filterData.department = formData.department;
    }

    // Check and set major if not null
    if (formData.major !== "") {
      _filterData.major = formData.major;
    }
    // Dispatch the setFilter action with the filterData payload
    dispatch(setFilter(_filterData));
    // Fetch faculties
    axios
      .get(NEW_API_URL + "faculty", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setFaculties(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch departments
    axios
      .get(NEW_API_URL + "department/" + formData.faculty, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDepartments(response.data?.data || []))
      .catch((error) => console.error(error));
  }, [formData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "faculty") {
      // If the faculty is changed, reset department to empty string
      setFormData({
        ...formData,
        [name]: value,
        department: "",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleOnChange = (event, newValue) => {
    if (newValue) setSelectedValue(newValue);
    else setSelectedValue("");
  };

  return (
    <div>
      <CustomContainer className="fix-margin-top">
        <Grid container gap={1} xs={12}>
          <Grid item xs={2}>
            <label>ຄະນະ</label>
            <FormControl fullWidth size="small">
              <Select
                disabled={
                  userObject?.data?.role === "FAC_ADMIN" ||
                  userObject?.data?.role === "DEP_ADMIN"
                }
                name="faculty"
                value={formData.faculty}
                displayEmpty
                onChange={handleChange}
              >
                <MenuItem value={""}>ຄະນະທັງຫມົດ</MenuItem>
                {faculties.map((item, index) => (
                  <MenuItem key={index} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <label>ພາກວິຊາ</label>
            <FormControl fullWidth size="small">
              <Select
                disabled={userObject?.data?.role === "DEP_ADMIN"}
                name="department"
                value={formData.department}
                displayEmpty
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ພາກວິຊາທັງຫມົດ
                </MenuItem>
                {departments.map((item, index) => (
                  <MenuItem key={index} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <label>ຄົ້ນຫາຫົວຂໍ້ສະຖິຕິ</label>
            <ModernAutocomplete
              value={selectedValue || 1}
              size="small"
              onChange={handleOnChange}
              options={exampleData}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontFamily: "YourPreferredFont",
                      fontSize: "16px",
                      color: "#333",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} display={"flex"} alignItems={"end"}>
            <FormControl fullWidth size="small">
              <Button
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: "darkgrey",
                  "&:hover": {
                    backgroundColor: "grey",
                  },
                }}
                style={{ textTransform: "none" }}
              >
                <Autorenew />
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </CustomContainer>
      <CustomContainer>
        <div className="row">{content}</div>
      </CustomContainer>
    </div>
  );
};

export default TeacherTab;
