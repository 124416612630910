import { gql } from "apollo-boost";

export const COURSES = gql`
  query Courses(
    $where: CustomCourseWhereInput
    $orderBy: CourseOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    courses(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        id
        title
        enTitle
        unit
        year
        semester
        courseCode
        isRegistration
        isUpgrade
        moodleCourseId
        assessmentYear
        teacher{
          id
          firstname
          lastname
          isDeleted
        }
        faculty{
          id
          name
        }
        department{ 
          id
          name
        }
        major{ 
          id
          name
        }
        classRoom{ 
          id
          name
        }
      }
      total
    }
  }
`;

export const COURSE_ADD = gql`
  query Courses(
    $where: CustomCourseWhereInput
    $orderBy: CourseOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    courses(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      total
      data {
        id
        title
        assessmentYear    
      }  
    }
  }
`;

export const QUERY_COURSE = gql`
  query Courses(
    $searchText: String
    $faculty: String
    $department: String
    $year: Int
    $limit: Int
    $skip: Int
  ) {
    courses(
      searchText: $searchText
      faculty: $faculty
      department: $department
      year: $year
      limit: $limit
      skip: $skip
    ) {
      total
      data {
        id
        courseCode
        title
        isRegistration
        assessmentYear
        gradeType{
          name
          detail
          score
          maxScore
        }
        department {
          id
          name
        }
        teacher {
          id
          userId
          firstname
          lastname
        }
        dayTimeIndexes {
          day
          timeIndexes
        }
        year
        semester
        unit
        description
        note
        createdAt
        updatedAt
      }
    }
  }
`;

export const IMPORT_COURSES = gql`
  mutation ImportCourseFromExcel($data: [CourseCreateInput!]!) {
    importCourseFromExcel(data: $data) {
      data {
        courseCode
        title
      }
      message
      location
    }
  }
`;

export const REGISTER_COURSES = gql`
  query Courses(
    $where: CustomCourseWhereInput
    $orderBy: CourseOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    courses(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      total
      data {
        id
        courseCode
        title
        isRegistration
        assessmentYear
      }
    }
  }
`;
export const FINE_COURSES = gql`
query Course(
  $keyword: String
  $limit: Int
  ){
    course(
      keyword: $keyword
      limit: $limit){
        id
      }
  }
`;
export const COURSE = gql`
  query Course(
    $where: CourseWhereUniqueInput!
    ) {
    course(where: $where) {
      id
      courseCode
      title
      isRegistration
      department {
        id
        name
      }
      faculty {
        id
        name
      }
      syllabusFile {
        file
        title
      }
      files {
        id
        description
        title
        file
        createdAt
      }
      dayTimeIndexes {
        day
        timeIndexes
      }
      teacher {
        id
        userId
        firstname
        lastname
      }
      gradeType{
        name
        detail
        score
        maxScore
      }
      moodleCourseId
      assessmentYear
      year
      semester
      unit
      description
      note
      createdAt
      updatedAt
    }
  }
`;

export const COURSE_FACULTY_DEPARTMENT = gql`
  query CourseAndFacultyAndDepartmentAndMajor(
    $where: CourseWhereUniqueInput!
    ) {
      courseAndFacultyAndDepartmentAndMajor(where: $where) {
        dataCourse {
        id
        addressLearn
        courseCode
        title
        enTitle
        syllabusFile {
          file
          title
        }
        files {
          id
          description
          title
          file
          createdAt
        }
        dayTimeIndexes {
          day
          timeIndexes
        }
        week
        teacher {
          id
          userId
          firstname
          lastname
        }
        gradeType{
          name
          detail
          score
          maxScore
        }
        moodleCourseId
        assessmentYear
        year
        semester
        unit
        description
        note
        createdAt
        updatedAt
      }
    dataDepartment{
        id
        department{
          id
          name
        }
        faculty{
          id
          name
        }
    }
    dataFaculty{
        id
        faculty{
          id
          name
        }
    }
    dataMajor{
      id
      major{
        id
        name
      }
      department{
          id
          name
        }
        faculty{
          id
          name
        }
    }
    dataClassroom{
      id
      major{
        id
        name
      }
      department{
        id
        name
      }
      faculty{
        id
        name
      }
      classRoom{
        id
        name
      }
    }
    }
  }
`;
export const COURSE_CHECK = gql`
  query Query($where: CustomCourseWhereInput) {
  courses(where: $where) {

    id
    title
    dayTimeIndexes {
      day
      timeIndexes
    }
    assessmentYear
    teacher {
      id
      userAuthId
      moodleUserId
    }
  }
}
`;
export const COURSE_TO_CHECK = gql`
  query Course($where: CourseWhereUniqueInput!) {
    course(where: $where) {
      id
    }
  }
`;
export const COURSE_EDIT = gql`
  query CourseEdit(
    $where: CourseWhereUniqueInput!
    $whereFaculty: CustomFacultyWhereInput
    $FacultyOrderBy: OrderDataInput

  ) {
    course(where: $where) {
      id
      courseCode
      moodleCourseId
      title
      department {
        id
        name
      }
      assessmentYear
      gradeType{
        name
        score
        detail
        maxScore
        note
      }
      faculty {
        id
        name
      }
      syllabusFile {
        file
        title
      }
      dayTimeIndexes {
        day
        timeIndexes
      }
      teacher {
        id
        userId
        firstname
        lastname
        moodleUserId
      }
      year
      semester
      unit
      description
      isRegistration
      isUpgrade
    }

    faculties(
      where: $whereFaculty
      orderBy: $FacultyOrderBy
    ) {
      id
      name
      departments {
        id
        name
      }
    }
  }
`;
export const CREATE_COURSE = gql`
  mutation CreateCourse($data: CustomCourseCreateInput!) {
    createCourse(data: $data) {
      id
    }
  }
`;
export const UPDATE_COURSE = gql`
  mutation UpdateCourse(
    $data: CustomCourseCreateInput!
    $where: CourseWhereUniqueInput!
  ) {
    updateCourse(data: $data, where: $where) {
      id
    }
  }
`;
export const UPDATE_COURSE_STATUS = gql`
  mutation UpdateCourseStatus(
    $data: CustomCourseCreateInput!
    $where: CourseWhereUniqueInput!
  ) {
    updateCourseStatus(data: $data, where: $where) {
      id
    }
  }
`;
export const UPDATE_MANY_COURSE_STATUS = gql`
  mutation UpdateManyCourseStatus(
    $data: CustomCourseCreateInput!
    $where: CourseWhereManyInput!
  ) {
    updateManyCourseStatus(data: $data, where: $where) {
      id
    }
  }
`;
export const DELETE_COURSE = gql`
  mutation DeleteCourse($where: CourseWhereUniqueInput!) {
    deleteCourse(where: $where) {
      id
    }
  }
`;
export const CALL_COURSE_DATA = gql`
  query Courses(
    $where: CustomCourseWhereInput
    $orderBy: CourseOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ){
    courses(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      total
      data {
        id
        title
        detail
        assessmentYear
        gradeType {
          name
          score
          maxScore
        }
      }
    }
  }

`;