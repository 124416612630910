import React, { useEffect, useState } from "react";
import {
  Input,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
} from "@mui/material";
import axios from "axios";
import { CustomContainer, Title } from "../../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CourseAddModal from "./CourseAddModal";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";
import CourseAddTable from "./CourseAddTable";

const CourseAddForm = ({ location, subjectType, onSuccess, setOnSuccess, isLoading, setIsLoading }) => {
  const [formData, setFormData] = useState({
    title: "",
    department: location.department || "",
    educationLevel: location.educationLevel || "",
  });
  const [selected, setSelected] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [showComnfirm, setShowConfirm] = useState(false);
  const [educationLevels, setEducationLevels] = useState([]);

  useEffect(() => {
    // Fetch departments
    axios
      .get(NEW_API_URL + "department/" + location.faculty, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDepartments(response.data?.data || []))
      .catch((error) => console.error(error));
    // Fetch education levels
    axios
      .get(NEW_API_URL + "educationlevel", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setEducationLevels(response.data))
      .catch((error) => console.error(error));
  }, []);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCourseAdd = () => {
    setShowConfirm(true);
  };
  return (
    <div>
      <CustomContainer>
        <Title text={`ເລືອກວິຊາໃນຄະນະ`} />
        <Grid container className="d-flex" spacing={2}>
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel htmlFor="department">ລະດັບການສຶກສາ</InputLabel>
              <Select
                // disabled={userObject?.data?.role === "DEP_ADMIN"}
                label="ລະດັບການສຶກສາ"
                name="educationLevel"
                value={formData.educationLevel || ""}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ທັງຫມົດ
                </MenuItem>
                {educationLevels.map((educationLevel) => (
                  <MenuItem
                    key={educationLevel?._id}
                    value={educationLevel?._id}
                  >
                    {educationLevel?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth size="small">
              <InputLabel htmlFor="department">ພາກວິຊາ</InputLabel>
              <Select
                // disabled={userObject?.data?.role === "DEP_ADMIN"}
                label="ພາກວິຊາ"
                name="department"
                value={formData.department || ""}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ທັງຫມົດ
                </MenuItem>
                {departments.map((department) => (
                  <MenuItem key={department._id} value={department._id}>
                    {department.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                label="ຄົ້ນຫາດ້ວຍຊື່ວິຊາ"
                id="outlined-size-small"
                size="small"
                name="title"
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
              <Button
                disabled={selected.length <= 0}
                variant="contained"
                size="large"
                color="success"
                endIcon={<FontAwesomeIcon icon="fa-solid fa-share" />}
                onClick={handleCourseAdd}
              >
                ເພິ່ມຫຼັກສູດ
              </Button>
          </Grid>
        </Grid>
        <CourseAddTable
          formData={formData}
          subjectType={subjectType}
          location={location}
          selected={selected}
          setSelected={setSelected}
          onSuccess={onSuccess}
          setOnSuccess={setOnSuccess}
        />
      </CustomContainer>
      <CourseAddModal
        location={location}
        selected={selected}
        setSelected={setSelected}
        showComnfirm={showComnfirm}
        setShowConfirm={setShowConfirm}
        setOnSuccess={setOnSuccess}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  );
};

export default CourseAddForm;
