import React, { useState, useEffect } from 'react';
import { Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getUserDataFromLCStorage } from "../../../helpers/user";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import consts from '../../../consts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function RoleAdmin({ 
  dataClassroom, 
  setFacultyId, 
  facultyId,
  dataFaculties, 
  setDepartmentId, 
  setYear, 
  year, 
  departmentId,
  setClassroomId,
  classroomId,
  dataDepartments,
  majorId,
  setMajorId,
  dataMajors
 }) {
    const [dataUser, setDataUser] = useState();

    useEffect(() => {
        if (getUserDataFromLCStorage()?.data) setDataUser(getUserDataFromLCStorage()?.data)
    }, []);

    /* select Faculty */
    const _selectFaculty = (e) => {
      setFacultyId(e.target.value);
    }
    const _onSelectedDepartment = (e) => {
      setDepartmentId(e.target.value);
    }
    const _onSelectedMajor = (e) => {
      setMajorId(e.target.value);
    }
    const _onSelectedYear = (e) => {
      setYear(e.target.value);
    }
    const _selectClassroom = (e) => {
      setClassroomId(e.target.value);
    };
    return (
        <div>
            <Row>
          <Col>
            <p>ຄະນະ</p>
            <Form.Control
              as="select"
              className="form-select"
              style={{ cursor: "pointer" }}
              disabled={dataUser?.role !== "ADMIN" && dataUser?.role !== "DEAN" ? true : false}
              value={facultyId}
              onChange={(e) => { _selectFaculty(e) }}
            >
              <option value="">ທັງໝົດ</option>
              {dataFaculties?.map((item, index) => {
                return (
                  <option key={index} value={item?.id}>{item?.name}</option>
                );
              })}
            </Form.Control>
          </Col>
          <Col>
            <p>ພາກວິຊາ</p>
            <Form.Control
              as="select"
              className="form-select"
              style={{ cursor: "pointer" }}
              value={departmentId}
              onChange={(e) => _onSelectedDepartment(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataDepartments?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>{item.name}</option>
                );
              })}
            </Form.Control>
          </Col>
          <Col>
            <p>ສາຂາວິຊາ</p>
            <Form.Control
              as="select"
              className="form-select"
              style={{ cursor: "pointer" }}
              value={majorId}
              onChange={(e) => _onSelectedMajor(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataMajors?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>{item.name}</option>
                );
              })}
            </Form.Control>
          </Col>
          <Col sm={2}>
            <p>ປີຮຽນ</p>
            <Form.Control
              as="select"
              className="form-select"
              style={{ cursor: "pointer" }}
              value={year}
              onChange={(e) => _onSelectedYear(e)}
            >
              <option value={""}>ທັງໝົດ</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </Form.Control>
          </Col>
          <Col sm={2}>
          <p>ຫ້ອງຮຽນ {" "}
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">ເລືອກຫ້ອງເພື່ອຄົ້ນຫານັກສຶກສາຕາມຫ້ອງຮຽນ</Tooltip>}>
                <FontAwesomeIcon icon={faInfoCircle} color={consts.PRIMARY_COLOR} />
              </OverlayTrigger>
            </p>

            <Form.Control
              as="select"
              className="form-select"
              style={{ cursor: "pointer" }}
              value={classroomId}
              onChange={(e) => _selectClassroom(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataClassroom?.map((x, index) => (
                <option key={index} value={x?.id}>{x?.name}</option>
              ))}
            </Form.Control>
          </Col>
        </Row>
        </div>);
}
