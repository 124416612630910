import React, { useEffect } from "react";
import useReactRouter from "use-react-router";
import { Breadcrumb, Table } from "react-bootstrap";
import { useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from 'react-redux'
import Consts from "../../../consts";
import { CustomContainer, Title } from "../../../common";
import { formatDate } from "../../../common/super";
import Loading from "../../../common/Loading";
import { FACULTIES } from "../apollo/query";
import { getUserFacultyId, getUserRole } from "../../../helpers/user";

export default function SettingFaculty() {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  // Route
  const { history } = useReactRouter();
  // Apollo
  let [loadFaculties, { loading, data }] = useLazyQuery(FACULTIES);

  /**
   * use effect
   */
  useEffect(() => {
    const userRole = getUserRole();
    if (userRole === "ADMIN") {
      loadFaculties({
        variables:{
          where:{
            assessmentYear: accessmentRedux,
            isDeleted: false,
          }
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } else if (userRole === "FAC_ADMIN") {
      const faclutyId = getUserFacultyId();
      loadFaculties({
        variables: {
          where: {
            assessmentYear: accessmentRedux,
            id: faclutyId,
            isDeleted: false,
          },
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
      });
    } else if (userRole === "DEP_ADMIN") {
      const faclutyId = getUserFacultyId();
      _toSettingFacultyDetail(faclutyId);
    }
  }, [accessmentRedux]);
  // function
  const _toSettingFacultyDetail = (id) => {
    history.push(`/setting-faculty-detail/${id}`);
  };

  // loadding
  if (loading) return <Loading />;
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ເລືອກການຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ການລົງທະບຽນ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        {/* title */}
        <Title text={"ການລົງທະບຽນ"} />
        {/* body list */}
        <div
          style={{
            width: "100%",
            minHeight: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 32,
          }}
        >
          <Table hover>
              <tr
                style={{
                  backgroundColor: Consts.SECONDARY_COLOR,
                  color: "white",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                <th style={{ width: 70 }}>#</th>
                <th>ຊື່ຄະນະ</th>
                <th>ວັນທີເປີດໃຫ້ລົງທະບຽນ</th>
                <th>ວັນທີສິ້ນສຸດໃຫ້ລົງທະບຽນ</th>
              </tr>
            <tbody>
              {data?.faculties?.map((faculty, index) => (
                <tr
                  key={index}
                  onClick={() =>
                    _toSettingFacultyDetail(faculty.id, faculty.name)
                  }
                  style={{
                    backgroundColor: "#f1f1f1",
                    borderBottomWidth: 2,
                    borderBottomColor: "white",
                    borderBottomStyle: "solid",
                  }}
                >
                  <td style={{ textAlign: "center" }}>{index + 1}</td>
                  <td>{faculty?.name}</td>
                  <td style={{ textAlign: "center" }}>
                    {formatDate(faculty?.startDateRegister) ?? "-"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {formatDate(faculty?.endDateRegister) ?? "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </CustomContainer>
    </div>
  );
}
