import React from 'react';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
    Button
} from '@mui/material'
import { formatDate } from '../../../common/super';
import { convertGenderNam } from '../../../helpers/user';

const ExportPaymentList = ({ data }) => {
    const onExport = (data) => {
        let queue = 0;
        const PaymentList = data.map((item) => {
            queue++;
            return {
                ລຳດັບ: queue,
                ລະຫັດນັກສຶກສາ: item.student.studentNumber,
                "ຊື່ ແລະ ນາມສະກຸນ": convertGenderNam(item?.student?.gender)
                    + " " + item?.student?.firstNameL + " " + item.student.lastNameL,
                ວັນທີ່ຊຳລະ: formatDate(item.createdAt),
                ຄ່າຮຽນ: item.totalPaid,
                ປີຮຽນ: item.year,
                ສະຖານະຊຳລະ: item.isPaid === false ? "ຍັງບໍ່ທັນຊໍາລະ" : "ຊໍາລະສໍາເລັດ",
            };
        });

        const ws = XLSX.utils.json_to_sheet(PaymentList);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "PaymentList");

        // Write the XLSX file
        const xlsxBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
            mimeType:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const blob = new Blob([new Uint8Array(xlsxBuffer)], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "paymentlist.xlsx");
    };
    return (
        <div>
            <Button
                color="success"
                variant='contained'
                sx={{ textTransform: "none", marginBottom: 3 }}
                onClick={() => onExport(data)}
            >
                Export
            </Button>
        </div>
    )
}

export default ExportPaymentList