import React, { useState } from 'react'
import {
    Input,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Button,
    IconButton
} from '@mui/material'
import { SUBJECT_GROUP } from '../../../consts/SubjectGroup'
import { Autorenew, Search } from '@mui/icons-material';
// import ExportCourseDetail from './ExportCourseDetail';
const PaymentListlFilter = ({ handleChange, formData, setFormData, educationLevels, faculties, departments, majors, classRooms, studentData }) => {
    const [firstName, setFirstName] = useState();

    const handleSearch = (name) => {
        setFormData(prevState => ({
            ...prevState,
            firstName: name
        }));
    };
    return (
        <div>
            <Grid container justify="space-between">
                <Grid item container spacing={2}>
                    <Grid item xs={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor="status">ຄົ້ນຫາຕາມສະຖານະ</InputLabel>
                            <Select
                                // disabled={userObject?.data?.role === "DEP_ADMIN"}
                                label="ຄົ້ນຫາຕາມສະຖານະ"
                                name="status"
                                value={formData.status || ""}
                                onChange={handleChange}
                            >
                                <MenuItem key={""} value={""}>
                                    ທັງຫມົດ
                                </MenuItem>
                                <MenuItem value="notPaid">
                                    ຍັງບໍ່ທັນຊໍາລະ
                                </MenuItem>
                                <MenuItem value="paid">
                                    ຊໍາລະສໍາເລັດ
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth size='small'>
                            <input type="date" name='date' value={formData.date} onChange={handleChange} className='form-control' />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth size='small'>
                            <TextField
                                size='small'
                                name='firstName'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder='ຄົ້ນຫາຕາມລະຫັດນັກສຶກສາ / ຊື່ນັກສຶກສາ...'
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        <FormControl fullWidth size='small'>
                            <Button
                                variant="contained"
                                size='large'
                                color='info'
                                style={{ textTransform: 'none' }}
                                onClick={() => handleSearch(firstName)}
                            >
                                <Search />
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth size='small'>
                            <Button
                                variant="contained"
                                size='large'
                                sx={{
                                    backgroundColor: 'darkgrey', '&:hover': {
                                        backgroundColor: 'grey',
                                    },
                                }}
                                style={{ textTransform: 'none' }}
                                onClick={() => {
                                    setFirstName("");
                                    setFormData(prevState => ({
                                        ...prevState,
                                        firstName: ""
                                    }));
                                }}
                            >
                                <Autorenew />
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor="educationLevel">ລະດັບ</InputLabel>
                            <Select
                                // disabled={userObject?.data?.role === "DEP_ADMIN"}
                                label="ລະດັບ"
                                name="educationLevel"
                                value={formData.educationLevel || ""}
                                onChange={handleChange}
                            >
                                <MenuItem key={""} value={""}>
                                    ທັງຫມົດ
                                </MenuItem>
                                {educationLevels.map((item, index) => (
                                    <MenuItem key={index} value={item._id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor="faculty">ຄະນະວິຊາ</InputLabel>
                            <Select
                                // disabled={userObject?.data?.role === "DEP_ADMIN"}
                                label="ຄະນະວິຊາ"
                                name="faculty"
                                value={formData.faculty || ""}
                                onChange={handleChange}
                            >
                                <MenuItem key={""} value={""}>
                                    ທັງຫມົດ
                                </MenuItem>
                                {faculties.map((item, index) => (
                                    <MenuItem key={index} value={item._id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor="department">ພາກວິຊາ</InputLabel>
                            <Select
                                // disabled={userObject?.data?.role === "DEP_ADMIN"}
                                label="ພາກວິຊາ"
                                name="department"
                                value={formData.department || ""}
                                onChange={handleChange}
                            >
                                <MenuItem key={""} value={""}>
                                    ທັງຫມົດ
                                </MenuItem>
                                {departments.map((item, index) => (
                                    <MenuItem key={index} value={item._id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor="major">ສາຂາວິຊາ</InputLabel>
                            <Select
                                // disabled={userObject?.data?.role === "DEP_ADMIN"}
                                label="ສາຂາວິຊາ"
                                name="major"
                                value={formData.major || ""}
                                onChange={handleChange}
                            >
                                <MenuItem key={""} value={""}>
                                    ທັງຫມົດ
                                </MenuItem>
                                {majors.map((item, index) => (
                                    <MenuItem key={index} value={item._id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor="year">ປີຮຽນ</InputLabel>
                            <Select
                                label="ປີຮຽນ"
                                name="year"
                                value={formData.year || ""}
                                onChange={handleChange}
                            >
                                <MenuItem key={""} value={""}>
                                    ທັງຫມົດ
                                </MenuItem>
                                <MenuItem value={1}>
                                    ປີ 1
                                </MenuItem>
                                <MenuItem value={2}>
                                    ປີ 2
                                </MenuItem>
                                <MenuItem value={3}>
                                    ປີ 3
                                </MenuItem>
                                <MenuItem value={4}>
                                    ປີ 4
                                </MenuItem>
                                <MenuItem value={5}>
                                    ປີ 5
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth size='small'>
                            <InputLabel htmlFor="classRoom">ຫ້ອງ</InputLabel>
                            <Select
                                label="ຫ້ອງ"
                                name="classRoom"
                                value={formData.classRoom || ""}
                                onChange={handleChange}
                            >
                                <MenuItem key={""} value={""}>
                                    ເລືອກຫ້ອງ
                                </MenuItem>
                                {classRooms.map((item, index) => (
                                    <MenuItem key={index} value={item._id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: "flex-end" }}>
                    {/* <ExportCourseDetail data={studentData} /> */}
                </Grid>

            </Grid>

        </div>
    )
}

export default PaymentListlFilter