import React, { useEffect, useState } from "react";
import { NEW_API_URL, API_KEY } from "../../../common/contrant";
import axios from "axios";
import consts, { USER_KEY } from "../../../consts";
import Consts from "../../../consts";
import Routers from "../../../consts/router";
import { useSelector } from "react-redux";
import queryString from "query-string";
import useReactRouter from "use-react-router";
import UserNav from "../../grades/common/GradeNav";
import { CustomContainer } from "../../../common";
import PaginationHelper from "../../../helpers/PaginationHelper";
import FilterGradeSendingClass from "../common/FilterGradeSendingClass";

export const GradeClassList = () => {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const { history, location } = useReactRouter();
  const parsed = queryString?.parse(location?.state);

  const [selectedFaculty, setSelectedFaculty] = useState(!parsed?.faculty ? "" : parsed?.faculty);
  const [selectedDepartment, setSelectedDepartment] = useState(!parsed?.department ? "" : parsed?.department);
  const [selectedMajor, setSelectedMajor] = useState(!parsed?.major ? "" : parsed?.major);
  const [selectedClass, setSelectedClass] = useState(!parsed?.class ? "" : parsed?.class);
  const [selectedTeacher, setSelectedTeacher] = useState(!parsed?.teacher ? "" : parsed?.teacher);
  const [gradeSendingStatus, setGradeSendingStatus] = useState(!parsed?.gradeSendingStatus ? "" : parsed?.gradeSendingStatus);
  const [semester, setSemester] = useState(!parsed?.semester ? "" : parsed?.semester);
  const [year, setYear] = useState(!parsed?.year ? "" : parsed?.year);
  const [numberTest, setNumberTest] = useState(1);
  const [courseTitle, setCourseTitle] = useState(!parsed?.courseTitle ? "" : parsed?.courseTitle);

  const [courseList, setCourseList] = useState();
  const [classAndCourseData, setClassAndCourseData] = useState();
  const [facId, setFacId] = useState(!parsed?.faculty ? "" : parsed?.faculty);
  const [role, setRole] = useState("");
  const [depId, setDepId] = useState("");
  const [classRoom, setClassRoom] = useState("");
  const [disableDep, setDisableDep] = useState(false);
  const [disableFact, setDisableFact] = useState(false);
  //   console.log("data::", courseList?.data)
  // console.log("cours::",courseList?.data.map(item => item.course))
  const getGradeStatus = (status) => {
    switch (status) {
      case "TEACHER_SCORING":
        return (<p style={{ color: Consts.PRIMARY_COLOR }}>ລໍຖ້າອາຈານໃຫ້ຄະເເນນ</p>)
      case "TEACHER_SUBMITED":
        return (<p className="text-success">ອາຈານສົ່ງຄະເເນນເເລ້ວ</p>);
      case "TEACHER_REJECTED":
        return (<p className="text-danger">ອາຈານຖືກປະຕິເສດ</p>);
      case "DEPARTMENT_SUBMITED":
        return (<p className="text-success">ພາກວິຊາກວດຄະເເນນ</p>);
      case "DEPARTMENT_REJECTED":
        return (<p className="text-danger">ພາກວິຊາປະຕິເສດຄະເເນນ</p>);
      case "FACULTY_SUBMITED":
        return (<p className="text-success">ຄະນະຢືນຢັນຄະເເນນແລ້ວ</p>);
      case "FACULTY_REJECTED":
        return (<p className="text-danger">ຄະນະປະຕິເສດຄະເເນນ</p>);
      case "ADMIN_REJECTED":
        return (<p className="text-success">ວິຊາການ ມຊ ປະຕິເສດຄະເເນນ</p>);
    }
  };

  const { _limit, _skip, set_limit, set_skip, Pagination_helper } = PaginationHelper();

  useEffect(() => {
    const user = localStorage.getItem(USER_KEY);
    // const facId = user.data.faculty.id
    const userObject = JSON.parse(user);

    if (userObject) {
      if (userObject.data.role === "ADMIN") {
        setDepId("");
        setFacId("");
        setDisableDep(false);
        setDisableFact(false);
      } else if (userObject.data.role === "DEP_ADMIN") {
        setDisableDep(true);
        setDisableFact(true);
        setDepId(userObject.data.department.id);
        setFacId(userObject.data.faculty.id);
      } else if (userObject.data.role === "FAC_ADMIN") {
        setDisableDep(false);
        setDisableFact(false);
        setFacId(userObject.data.faculty.id);
      }
      setRole(userObject.data.role);
    }
  }, []);


  const onSelected = async (data) => {
    if (
      // true
      // data.faculty &&
      // data.department &&
      // data.major &&
      // data.class &&
      // data.year &&
      data.semester !== ""
      // data.status &&
      // data.teacherId &&
      // data.title
    ) {
      fetchClassAndCourse(data);
      setClassRoom(data.class);
      await axios
        .get(
          NEW_API_URL +
          `/gradebycourse/${data.faculty || "undefined"}/${data.department || "undefined"
          }/${data.major || "undefined"}/${data.class || "undefined"}/${data.year || "undefined"
          }/${accessmentRedux || "undefined"}/${data.semester || "undefined"}/${data.numberTest || "undefined"
          }?skip=${_skip}&limit=${_limit}`,
          {
            headers: {
              api_key: API_KEY,
            },
          }
        )
        .then((res) => {

          setCourseList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchClassAndCourse = async (data) => {
    console.log("data::",data)
    await axios
        .get(
            NEW_API_URL +
            `/classandcourse/${data.faculty || "undefined"}/${data.department || "undefined"
            }/${data.major || "undefined"}/${data.class || "undefined"}/${data.year || "undefined"
            }/${accessmentRedux || "undefined"}/${data.semester || "undefined"
            }`,
            {
                headers: {
                    api_key: API_KEY,
                },
            }
        )
        .then((res) => {
            setClassAndCourseData(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
};

  return (
    <div>
      <UserNav />
      <CustomContainer style={{ marginTop: 0 }}>
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: consts.FONT_COLOR_SECONDARY,
          }}
        >
          <FilterGradeSendingClass
            depId={depId}
            disableDep={disableDep}
            factId={facId}
            disableFact={disableFact}
            setSelectedFaculty={setSelectedFaculty}
            selectedFaculty={selectedFaculty}
            setSelectedDepartment={setSelectedDepartment}
            selectedDepartment={selectedDepartment}
            setSelectedMajor={setSelectedMajor}
            selectedMajor={selectedMajor}
            setSelectedClass={setSelectedClass}
            selectedClass={selectedClass}
            setSelectedTeacher={setSelectedTeacher}
            selectedTeacher={selectedTeacher}
            setGradeSendingStatus={setGradeSendingStatus}
            gradeSendingStatus={gradeSendingStatus}
            setSemester={setSemester}
            semester={semester}
            setYear={setYear}
            year={year}
            setNumberTest={setNumberTest}
            numberTest={numberTest}
            setCourseTitle={setCourseTitle}
            courseTitle={courseTitle}
            set_skip={set_skip}
            set_limit={set_limit}
            onSelected={onSelected}
          />
          <div>
            <div style={{ marginLeft: "10px", marginRight: "10px", padding: "24px 10px", backgroundColor: "white" }}>
              <div style={{ display: "flex", justifyContent: "center", Font: 20 }}>
                ຜົນສອບເສັງຂອງ ນັກສຶກສາ ຄະນະ {classAndCourseData?.data[0]?.faculty?.name} (ມຊ) ຊຸດທີ່ xx ພາກຮຽນ {classAndCourseData?.data[0]?.semester} ສົກຮຽນປີ {classAndCourseData?.data[0]?.assessmentYear} (ພາກວິຊາ {classAndCourseData?.data[0]?.department?.name} ສາຂາວິຊາ {classAndCourseData?.data[0]?.major?.name})</div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>ນັກສຶກສາທັງໝົດ ( {courseList?.length} )</div>
                {/* <ExportGradeClass courseListData={data} /> */}
                {/* <button className="btn btn-outline-success" style="float: right;">
                        <svg className="svg-inline--fa fa-file-excel" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-excel" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path>
                        </svg> Export Excel</button> */}
              </div>
              <div style={{ marginLeft: "auto", marginRight: "auto", marginTop: "10px", overflowX: "auto" }}>
                <table className="table-hover" border="1" bordercolor="#fff" style={{ minWidth: "100%" }}>
                  <thead>
                    <tr>
                      <th className="text-center" rowSpan="3" style={{ width: "70px" }}>ລຳດັບ</th>
                      <th className="text-center" rowSpan="3">ຊື່ ແລະ ນາມສະກຸນ</th>
                      <th className="text-center" rowSpan="3">ຫ້ອງຮຽນ</th>
                      <th classsName="text-center" rowSpan="3">ລະຫັດນັກສຶກສາ</th>
                      {classAndCourseData?.data?.map((item, index) => (
                        <th className="text-center" colSpan="4" >{item.course.title ? item.course.title : "ບໍ່ມີຂໍ້ມູນ"}</th>

                      ))
                      }
                      <th className="text-center" rowSpan="3">GPA</th>
                      <th className="text-center" rowSpan="3">ອັນດັບທີ່</th>
                      <th className="text-center" rowSpan="3">ໝາຍເຫດ</th>
                    </tr>
                    <tr>
                      {classAndCourseData?.data?.map((item, index) => (
                        <th className="text-center" colSpan="4">3 ໝ.ກ</th>
                      ))
                      }
                    </tr>
                    <tr>
                      {classAndCourseData?.data?.map((item, index) => (
                        <React.Fragment key={index}>
                          <th>60</th>
                          <th>40</th>
                          <th>ຄະແນນ</th>
                          <th>ເກຣດ</th>
                        </React.Fragment>
                      ))
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {courseList?.data.map((item, index) =>
                    (
                      <tr key={index} style={{ height: "40px", cursor: "pointer", textAlign: "center" }}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{item.student.firstNameL + " " + item.student.lastNameL}</td>
                        <td className="text-center">{item.classRoom.name}</td>
                        <td className="text-center">{item.student.studentNumber}</td>
                        <td className="text-center">{1}</td>
                        <td className="text-center">{1}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                        <td className="text-center">{'-'}</td>
                      </tr>
                    )
                    )}
                  </tbody>
                  {/* <tbody>
                            <tr>
                                <td colSpan="10">
                                    <div>
                                        <div className="container" style={{textAlign:'center',marginTop: "20%", marginBottom: "20%"}}>
                                            <img src="https://cdn.dribbble.com/users/598368/screenshots/3890110/dribble_no_data.jpg" width="220px" height="140px" style="text-align: center; opacity: 0.2;" />

                                            <h4>
                                                <strong style="color: rgb(204, 204, 204);">ຍັງບໍ່ມີຂໍ້ມູນທີ່ຈະສະແດງເທື່ອ !</strong>
                                            </h4>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody> */}
                </table>
              </div>
              <div style={{ paddingLeft: "20px" }}>
                <strong>ໝາຍເຫດ : </strong>
                <div style={{ paddingLeft: "20px" }}>
                  <div>ຄະແນນ 91-100 ຄ່າລະດັບ=4.0 ເກຣດ A; ຄະແນນ 81-90 ຄ່າລະດັບ=3.5 ເກຣດ B+; ຄະແນນ 70-80 ຄ່າລະດັບ=3.0 ເກຣດ B;</div>
                  <div>ຄະແນນ 65-69 ຄ່າລະດັບ=2.5 ເກຣດ C+; ຄະແນນ 60-64 ຄ່າລະດັບ=2.0 ເກຣດ C; ຄະແນນ 55-59 ຄ່າລະດັບ=1.5 ເກຣດ D+;</div>
                  <div>ຄະແນນ 50-54 ຄ່າລະດັບ=1.0 ເກຣດ D; ຄະແນນ 49 ລົງມາ ມີຄ່າລະດັບ=0 ເກຣດ F, I=ໂຈະເສັງ.</div>
                  <div>ໃຫ້ນັກສຶກສາຜູ້ທີ່ເສັງຕົກ(F), ເກດ D,D+ ລົງທະບຽນ ແກ້ເກດ ແຕ່ວັນທີ .. -.. / .. / .... ຖ້າກາຍກຳນົດເວລາດັ່ງກ່າວ ແມ່ນບໍ່ມີສິດ ເຂົ້າຮ່ວມສອບເສັງ.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomContainer>
    </div>
  );
};

export default GradeClassList;

