import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
/**
 *
 * @Library
 *
 */
import { useLazyQuery } from "@apollo/react-hooks";
import queryString from "query-string";

/**
 *
 * @Component
 *
 */
import { Breadcrumb } from "react-bootstrap";
import { useSelector } from 'react-redux'
import { CustomContainer, TableHeader } from "../../../common";
import Loading from '../../../common/Loading'
/**
 *
 * @Constant
 *
 */
import Routers from "../../../consts/router";
/**
 *
 * @Apollo
 *
 */
import { FACULTY_NAME } from "../../../apollo/faculty";
import { DEPARTMENT_NAME } from "../../../apollo/deparment/index";
import { COURSES } from "../../../apollo/course/index";
import { TEACHERS } from "../../../apollo/user/index";
/**
 *
 * @Function
 *
 */
import PaginationHelper from "../../../helpers/PaginationHelper";
import "../util/index.css";
import { MAJORS } from "../../../apollo/major";
import { CLASSROOM_MASTERS } from "../../../apollo/classRoom";

function GradeCourseList() {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const { history, location } = useReactRouter();
  const user = JSON.parse(window.localStorage.getItem("user")).data;
  const ROLE = user.role;
  const parsed = queryString?.parse(location?.state);
  /**
   *
   * @State
   *
   */
  const [factId, setFactId] = useState(ROLE !== "ADMIN" && ROLE !== "DEAN" ? user.faculty.id : !parsed?.faculty ? "" : parsed?.faculty);
  const [departmentId, setDepartmentId] = useState(ROLE === "DEP_ADMIN" ? user.department.id : !parsed?.department ? "" : parsed?.department);
  const [courseTitle, setCourseTitle] = useState(!parsed?.title ? "" : parsed?.title);
  const [selectedYear, setSelectedYear] = useState(!parsed?.year ? "" : parsed?.year);
  const [selectSemester, setSelectSemester] = useState(!parsed?.semester ? "" : parsed?.semester);
  const [selectTeacher, setSelectTeacher] = useState(!parsed?.teacher ? "" : parsed?.teacher);
  const [dataDepartment, setDataDepartment] = useState([]);
  const [dataCourses, setDataCourses] = useState([]);
  const [coursesTotal, setCoursesTotal] = useState(0);

  const [dataMajors, setDataMajors] = useState([])
  const [majorId, setMajorId] = useState("")

  const [dataClassRooms, setDataClassRooms] = useState([]);
  const [classroomId, setClassroomId] = useState(!parsed?.classRoom ? "" : parsed?.classRoom);

  /**
   *
   * @Apollo
   *
   */
  const { _limit, _skip, Pagination_helper } = PaginationHelper();
  // Query faculties
  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTY_NAME, {
    variables: {
      where: ROLE !== "ADMIN" && ROLE !== "DEAN"
        ? { id: user.faculty.id, isDeleted: false, assessmentYear: accessmentRedux }
        : { isDeleted: false, assessmentYear: accessmentRedux },
    },
  });
  const [loadDepartment, { data: departmentData }] = useLazyQuery(DEPARTMENT_NAME);
  const [loadMajors, { data: majorData }] = useLazyQuery(MAJORS)
  const [loadClassRooms, { data: classroomData }] = useLazyQuery(CLASSROOM_MASTERS);
  const [loadTeacher, { data: teacherData }] = useLazyQuery(TEACHERS, { fetchPolicy: "network-only" });
  const [loadCourse, { loading: courseLoading, data: courseData }] = useLazyQuery(COURSES, {
    variables: {
      where: {
        assessmentYear: accessmentRedux,
        isDeleted: false
      },
      skip: (_skip - 1) * _limit,
      first: _limit,
    },
  });

  /**
   *
   * @UseEffect
   *
   */
  useEffect(() => {
    loadCourse();
    loadFaculties();
    let _where = { isDeleted: false, role: "TEACHER"}
    if (factId !== "") _where = { ..._where, faculty: factId }
    if (departmentId !== "") _where = { ..._where, department: departmentId }

    loadTeacher({
      variables: {
        where: _where,
      },
    });
    

    let today = new Date();
    let y2 = today.getFullYear();
    var accessment = [];
    for (var i = y2; i >= 2010; i--) {
      accessment.push(i);
    }
  }, []);

  useEffect(() => {
    let _where = {isDeleted: false, role: "TEACHER"}
    if (factId !== "") _where = { ..._where, faculty: factId }
    if (departmentId !== "") _where = { ..._where, department: departmentId }

    loadTeacher({ variables: {  where: _where}});
    
  }, [factId, departmentId])
  

  // load assessmentyear
  useEffect(() => {
    if (!courseData) return;
    setDataCourses(courseData?.courses?.data);
    setCoursesTotal(courseData?.courses?.total);
  }, [courseData]);

  useEffect(() => {
    if (departmentData?.departments)
      setDataDepartment(departmentData?.departments)
  }, [departmentData]);

  useEffect(() => {
    if(majorData) setDataMajors(majorData?.majors?.data)
  }, [majorData])

  useEffect(() => {
    if(classroomData) setDataClassRooms(classroomData?.classRooms?.data);
  }, [classroomData]);

  useEffect(() => {
    if (factId) {
      loadDepartment({
        variables: {
          where: { faculty: factId },
        },
      });
    }
  }, [factId])

  useEffect(() => {
    if(departmentId) {
      loadMajors({
        variables:{
          where: {
            isDeleted: false, 
            department: departmentId, 
            assessmentYear: accessmentRedux
          }
        }
      })
    }
  }, [departmentId])

  useEffect(() => {
    if(!majorId) return;
    let _where = {}
    if (selectedYear !== "") _where = { ..._where, year: selectedYear }
    if(majorId !== "") _where = { ..._where, major: majorId }
    loadClassRooms({
      variables: { where: _where },
    });
  }, [majorId, selectedYear]);

  // load course
  useEffect(() => {
    let allWhere = { assessmentYear: accessmentRedux };
    if (courseTitle !== "") allWhere = { ...allWhere, title: courseTitle };
    if (factId !== "") allWhere = { ...allWhere, faculty: factId };
    if (departmentId !== "") allWhere = { ...allWhere, department: departmentId };
    if (majorId !== "") allWhere = { ...allWhere, major: majorId };
    if (classroomId !== "") allWhere = { ...allWhere, classRoom: classroomId };
    if (selectedYear !== "") allWhere = { ...allWhere, year: parseInt(selectedYear) };
    if (selectSemester !== "") allWhere = { ...allWhere, semester: parseInt(selectSemester) };
    if (selectTeacher !== "") allWhere = { ...allWhere, teacher_some: [selectTeacher] };

    loadCourse({
      variables: {
        where: { ...allWhere, isDeleted: false },
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });
  }, [courseTitle, factId, departmentId, majorId, classroomId, selectedYear, selectSemester, selectTeacher, accessmentRedux]);
  /**
   *
   * @Function
   *
   */
  // select faculty ad get value
  const _selectFaculty = (e) => {
    setDataDepartment([])
    setFactId(e.target.value);
    setDepartmentId("")
    setDataMajors([])
    setMajorId("")
    setDataClassRooms([])
    setClassroomId("")
  };
  // select department
  const _onSelectedDepartment = (e) => {
    setDepartmentId(e.target.value);
    setDataMajors([])
    setMajorId("")
    setDataClassRooms([])
    setClassroomId("")
  };
  const _onSelectedMajor = (e) => {
    setMajorId(e.target.value);
    setDataClassRooms([])
    setClassroomId("")
  };
  // select yaer
  const _onSelectedYear = (e) => {
    setSelectedYear(e.target.value);
  };
  // select unit
  const _onSelectedSemester = (e) => {
    setSelectSemester(e.target.value);
  };
  const _onSelectedClassroom = (e) => {
    setClassroomId(e.target.value);
  }
  // select Teacher
  const _onSelectedTeacher = (e) => {
    setSelectTeacher(e.target.value);
  };

  const _courseDetail = (dataCourse) => {
    const _sendData = {
      title: dataCourse?.title,
      factId: factId,
      departmentId: departmentId,
      majorId: majorId,
      classroomId: classroomId,
      yearLevel: dataCourse?.year,
      semester: dataCourse?.semester,
      courseId: dataCourse?.id,
      teacherId: selectTeacher,
    };
    history.push(Routers.GRADE_TAPS +"/"+ dataCourse?.id, _sendData);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
          <strong>ຄະແນນ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
          <strong>ເລືອກໝວດໝູ່</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ວິຊາທັງໝົດ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer style={{ marginTop: 0 }}>
        <span
          style={{
            display: "inline-block",
            fontSize: 24,
            marginTop: 20,
            color: "#057CAE",
            fontWeight: "bold",
          }}
        >
          ວິຊາທັງໝົດ
        </span>

        <div className="form-group row">
          <label htmlFor="searchtext" className="col-sm-1 col-form-label">
            ຄົ້ນຫາ
          </label>
          <div className="col-sm-11">
            <input
              type="text"
              className="form-control boxs"
              id="searchtext"
              placeholder="ປ້ອນລະຫັດວິຊາ"
              onChange={(e) => setCourseTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ຄະນະ</label>
            <select
              className="form-control form-select"
              disabled={ROLE !== "ADMIN" && ROLE !== "DEAN" ? true : false}
              value={
                ROLE !== "ADMIN" && ROLE !== "DEAN" ? user.faculty.id : factId
              }
              onChange={(e) => _selectFaculty(e)}
            >
              <option value="">ທັງໝົດ</option>
              {facultyData?.faculties?.map((item, index) => {
                return (
                  <option key={"faculty" + index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ພາກວິຊາ</label>
            <select
              className="form-control form-select"
              disabled={ROLE === "DEP_ADMIN" ? true : false}
              value={ROLE === "DEP_ADMIN" ? user.department.id : departmentId}
              onChange={(e) => _onSelectedDepartment(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataDepartment?.map((x, index) => (
                <option key={"department" + index} value={x.id}>
                  {x.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ສາຂາວິຊາ</label>
            <select
              className="form-control form-select"
              onChange={(e) => _onSelectedMajor(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataMajors?.map((x, index) => (
                <option key={index} value={x.id}>
                  {x.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ປີ</label>
            <select
              className="form-control form-select"
              onChange={(e) => _onSelectedYear(e)}
            >
              <option value="">ທັງໝົດ</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ພາກຮຽນ</label>
            <select
              className="form-control form-select"
              onChange={(e) => _onSelectedSemester(e)}
            >
              <option value="">ທັງໝົດ</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ຫ້ອງນັກສຶກສາ</label>
            <select
              className="form-control form-select"
              onChange={(e) => _onSelectedClassroom(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataClassRooms?.map((item, index) => (
                <option key={index} value={item?.id}>{item?.name}</option>
              ))}
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ອາຈານ</label>
            <select
              className="form-control form-select"
              onChange={(e) => _onSelectedTeacher(e)}
            >
              <option value="">ທັງໝົດ</option>
              {teacherData?.users?.data?.map((item, index) => (
                <option key={"teacher" + index} value={item?.id}>
                  {item?.firstname}
                </option>
              ))}
            </select>
          </div>
        </div>
      </CustomContainer>
      <CustomContainer>
        {courseLoading ? (
          <Loading />
        ) : (
          <div>
            <table style={{ width: "100%", height: 50, marginBottom: 20 }} border="0">
              <thead>
                <TableHeader>
                  <th style={{ width: 70 }}>ລຳດັບ</th>
                  <th>ວິຊາທັງໝົດຂອງອາຈານ</th>
                  <th style={{ width: 300 }}>ຈັດການ</th>
                </TableHeader>
              </thead>
              <tbody>
                {dataCourses?.map((item, index) => {
                  return (
                    <tr key={index} style={{ height: 70 }}>
                      <td className="center">
                        {index + 1 + _limit * (_skip - 1)}
                      </td>
                      <td>{item?.title}</td>
                      <td className="center">
                        <button
                          className="btn btn-outline-success"
                          onClick={(e) => _courseDetail(item)}
                        >
                          <i className="fa fa-external-link-alt"></i>{" "}
                          ເບີ່ງລາຍລະອຽດ
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {Pagination_helper(
              coursesTotal,
              Routers.GRADE_COURSE_LIST,
              `faculty=${factId}&&department=${departmentId}&&year=${selectedYear}&&semester=${selectSemester}&&teacher=${selectTeacher}&&title=${courseTitle}`
            )}
          </div>
        )}
      </CustomContainer>
    </div>
  );
}

export default GradeCourseList;
