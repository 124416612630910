import { gql } from 'apollo-boost'
export const PERSONALTYPE = gql`
  query PersonalTypeMasters(
    $where: PersonalTypeMasterWhereInput
    $orderBy: PersonalTypeMasterOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    personalTypeMasters(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {data{
        id
        name
        }
    }
  }
`;