import React, { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import Loading from "../../../common/Loading";
import EditeReligional from "./EditeReligional";
import { Modal, Form} from "react-bootstrap";
import { Formik } from "formik";
import { CustomContainer, TableHeader, CustomButton } from "../../../common";
import { valiDate, alertSuccess } from "../../../common/super";
import {
  RELIGIONAL_MASTERS,
  DELETE_RELIGINAL,
  CREATE_RELIGINAL,
} from "../../../apollo/religionalMasters";
//
function SettingReligional() {
  const [showSearchView, setShowSearchView] = useState(false);
  const [newData, setnewData] = useState();
  const _handleSearchViewClose = () => setShowSearchView(false);
  const _handleSearchViewShow = (e) => {
    setnewData(e);
    setShowSearchView(true);
  };
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const _showAdd = () => setShowAdd(true);
  const _closeAdd = () => setShowAdd(false);
  const _closeDelete = () => setShowDelete(false);
  const [deleteReligional] = useMutation(DELETE_RELIGINAL);
  const [confrimDelete, setShowConfirmDelete] = useState();

  const _delete = async (i) => {
    setShowDelete(true);
    setShowConfirmDelete(i);
  };
  const _comfrimeDelete = async (confrimDelete) => {
    await deleteReligional({ variables: { where: { id: confrimDelete.id } } })
      .then(async () => {
        alertSuccess("ການບັນທຶກຂໍ້ມູນສຳເລັດ");
      })
      .catch((err) => {});
  };
  const [
    loadReligionalMasters,
    { loading: religionalMastersLoading, data: religionalMastersData },
  ] = useLazyQuery(RELIGIONAL_MASTERS);

  // on first load
  useEffect(() => {
    loadReligionalMasters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // create CREATE_RELIGINAL
  const [createReligional] = useMutation(CREATE_RELIGINAL);
  const _saveReligional = (data) => {
    createReligional({
      variables: {
        data: {
          name: data.name,
          note: data.note,
        },
      },
    })
      .then(async () => {
        // window.location.reload(true)
        setShowAdd(false)
        alertSuccess("ການບັນທຶກຂໍ້ມູນສຳເລັດ");
      })
      .catch((err) => {});
  };

  return (
    <div>
      <CustomContainer>
        <div className="row" style={{ marginTop: 0, marginBottom: 0 }}>
          <div className="col-md-10">
            <strong>ລາຍການສາສະໜາ</strong>
          </div>
          <div className="col-md-2">
            <button className="btn btn-info right" onClick={_showAdd}>
              <i className="fa fa-plus"></i> ເພີ່ມ
            </button>
          </div>
          {religionalMastersLoading && <Loading />}
          <table
            className="table-hover"
            bordercolor="#fff"
            style={{ width: "100%" }}
          >
            <thead>
              <TableHeader>
                <th style={{ width: 100 }}>ລຳດັບ</th>
                <th>ຊື່ສາສະໜາ</th>
                <th>ໝາຍເຫດ</th>
                <th style={{ width: 200 }}>ຈັດການ</th>
              </TableHeader>
            </thead>
            <tbody>
              {religionalMastersData?.religionalMasters?.data?.map(
                (item, index) => (
                  <tr
                    key={index}
                    style={{
                      border: "2px solid #ffffff",
                      textAlign: "center",
                      background: "#f0f0f0",
                    }}
                  >
                    <td>{index + 1}</td>
                    <td className="left">{item.name}</td>
                    <td className="left">{item.note}</td>
                    <td>
                      <button
                        className="btn btn-control"
                        onClick={(e) => _handleSearchViewShow(item)}
                      >
                        <i
                          className="fa fa-pen"
                          style={{ color: "#28a745" }}
                        ></i>
                      </button>
                      &nbsp;&nbsp;
                      <button
                        className="btn btn-control"
                        data-toggle="modal"
                        data-target="#deleteTribe"
                        onClick={() => _delete(item)}
                      >
                        <i
                          className="fa fa-trash"
                          style={{ color: "#B80000" }}
                        ></i>
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </CustomContainer>
      {/* add data */}
      <Formik
        initialValues={{
          tribeName: "",
          note: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.tribeName) {
            errors.tribeName = "Required";
          }

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          let data = {
            name: values.tribeName,
            note: values.note,
          };
          _saveReligional(data);
          setShowSearchView(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Modal animation={false} size="lg" show={showAdd} onHide={_closeAdd}>
            <Modal.Header style={{ padding: 10, border: 0 }}>
              <Modal.Title
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: 0,
                  marginTop: 20,
                  fontWeight: "bolder",
                }}
              >
                <strong>ເພີ່ມຂໍ້ມູນສາສະໜາ</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0 100px 20px 100px",
                justifyContent: "center",
              }}
            >
              <label>ຊື່ສາສະໜາ {valiDate()}</label>
              <Form.Control
                type="text"
                className="form-control"
                name="tribeName"
                isInvalid={!!errors.tribeName}
                onChange={handleChange}
                value={values.tribeName}
                placeholder="ກະລຸນາປ້ອນຊື່ສາສະໜາ"
              />
              <label>ໝາຍເຫດ</label>
              <textarea
                className="form-control"
                name="note"
                onChange={handleChange}
                value={values.note}
                placeholder="ກະລຸນາປ້ອນໝາຍເຫດ(ຖ້າມີ)"
              />
            </Modal.Body>
            <Modal.Footer>
              <CustomButton onClick={_closeAdd} title="ຍົກເລີກ" />
              <CustomButton onClick={handleSubmit} title="ບັນທຶກ" confirm />
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
      {/* edit data */}
      <Modal
        animation={false}
        size="md"
        show={showDelete}
        onHide={_closeDelete}
      >
        <Modal.Header style={{ padding: 10, border: 0 }}>
          <Modal.Title
            style={{
              textAlign: "center",
              width: "100%",
              padding: 0,
              marginTop: 20,
              fontWeight: "bolder",
            }}
          >
            <strong>ລຶບຂໍ້ມູນສາສະໜາ</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0 100px 20px 100px",
            justifyContent: "center",
          }}
        >
          <span>ທ່ານຕ້ອງການລຶບສາສະໜາ {confrimDelete?.name} ແທ້ ຫຼື ບໍ ?</span>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            style={{
              background: "#fff",
              color: "#c7c7c7",
              border: "1px solid #c7c7c7",
            }}
            onClick={_closeDelete}
            title="ຍົກເລີກ"
          />
          <CustomButton
            style={{ background: "#c7c7c7", color: "#fff", border: "none" }}
            title="ຢືນຢັນການລົບ"
            onClick={() => _comfrimeDelete(confrimDelete)}
          />
        </Modal.Footer>
      </Modal>
      <EditeReligional
        showSearchView={showSearchView}
        _handleSearchViewClose={_handleSearchViewClose}
        let
        allData={newData}
      />
    </div>
  );
}
export default SettingReligional;
