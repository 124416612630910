import axios from "axios";
import { NEW_API_URL, API_KEY } from "../../common/contrant";
import { USER_KEY } from "../../consts";

export const fetStatistichData = async (url) => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;

  try {
    const response = await axios.get(`${NEW_API_URL}/statistic/${url}`, {
      headers: {
        api_key: API_KEY,
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
