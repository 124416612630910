import React, { Component } from "react";
import { Spinner } from "react-bootstrap";

export default class LoadingFullScreen extends Component {
  render() {
    return (
      <div
        className="fixed-top"
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            height: "100vh",
            width: "120%",
            opacity: 0.5,
          }}
        ></div>
        <Spinner animation="border" style={{ color: "#057CAE", zIndex: 2, position: "absolute", marginTop: -120 }} />
      </div>
    );
  }
}
