import { gql } from "apollo-boost"

export const MAJORS = gql`
    query MAJORS(
        $where: CustomMajorWhereInput
        $orderBy: MajorOrderByInput
        $skip: Int
        $after: String
        $before: String
        $first: Int
        $last: Int
    ) {
        majors(
            where: $where
            orderBy: $orderBy
            skip: $skip
            after: $after
            before: $before
            first: $first
            last: $last

        ){
            total
            data{
                id
                name
                enName
                description
                status
                enDescription
                startDateRegister
                endDateRegister
                note
                faculty{
                id
                name
                note
                }
                department{
                  id
                  name
                }
                assessmentYear
                startDateRegister
                endDateRegister
            }
        }
    }

`
export const MAJOR = gql`
  query Major(
    $where: CustomMajorWhereUniqueInput!
  ) {
    major(
      where: $where
    ) {
        id
        name
        description
        note
        faculty{
            id
            name
        }
        department{
                  id
                  name
                }
        createdBy{
          id
          firstname
          lastname
        }
        # courseList{
        #   id
        #   title
        #   courseCode
        # }
        status
        startDateRegister
        endDateRegister
        # files{
        #   id
        #   title
        #   description
        #   file
        #   keyword
        #   type
        #   cate
        # }
        createdAt
        updatedAt
        updatedBy{
          firstname
          lastname
        }
    }
  }
`

export const CREATE_MAJOR = gql`
  mutation createMajor(
    $data: CustomMajorCreateInput!
  ) {
    createMajor(
      data: $data
    ) {
        id
    }
  }
`

export const UPDATE_MAJOR = gql`
  mutation UpdateMajor(
    $data: CustomMajorUpdateInput!
    $where: CustomMajorWhereUniqueInput!
  ) {
    updateMajor(
      data: $data
      where: $where
    ) {
        id
        status
    }
  }
`
export const UPDATE_MAJOR_REGISTER = gql`
  mutation UpdateMajorDateRegister(
    $data: UpdateMajorDateRegisterInput!
    $where: CustomMajorWhereUniqueInput!
  ) {
    updateMajorDateRegister(data: $data, where: $where) {
      id
    }
  }
`

export const DELETE_MAJOR = gql`
  mutation DeleteMajor(
    $where: CustomMajorWhereUniqueInput!
  ) {
    deleteMajor(
      where: $where
    ) {
        id
    }
  }
`