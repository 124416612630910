import React, { useEffect, useState } from "react";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";
import axios from "axios";
import { useLazyQuery } from "@apollo/react-hooks";
import { Translater } from "../../../common/super";
import { TEACHERS } from "../../../apollo/user";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const FilterGradeSendingClass = (
    {
        onSelected, disableFact, factId, disableDep, depId, setSelectedFaculty, selectedFaculty,
        setSelectedDepartment, selectedDepartment, setSelectedMajor, selectedMajor, setSelectedClass, selectedClass,
        setSelectedTeacher, selectedTeacher, setGradeSendingStatus, gradeSendingStatus, setSemester, semester,
        setYear, year, numberTest, setCourseTitle, courseTitle, set_skip, set_limit
    }
) => {
    const [facultyList, setFacultyList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [majorList, setMajorList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [approveStatus, setApproveStatus] = useState([]);
    const [loadTeacher, { data: teacherData }] = useLazyQuery(TEACHERS, {
        variables: {
            where: { role: "TEACHER" },
        },
    });
    useEffect(() => {
        setApproveStatus([
            "ADMIN_CHECKING",
            "ADMIN_APPROVED",
            "FACULTY_CHECKING",
            "FACULTY_SUBMITED",
            "FACULTY_REJECTED",
            "DEPARTMENT_CHECKING",
            "DEPARTMENT_SUBMITED",
            "DEPARTMENT_REJECTED",
            "TEACHER_SCORING",
            "TEACHER_SUBMITED",
            "TEACHER_REJECTED",
        ]);
        loadTeacher()
        // setSelectedDepartment(depId);
        // setSelectedFaculty(factId);
    }, []);


    const handleFacultyChange = (event) => {
        setSelectedFaculty(event.target.value);
    };
    const handleDepartmentChange = (event) => {
        setSelectedDepartment(event.target.value);
    };
    const handleMajorChange = (event) => {
        setSelectedMajor(event.target.value);
    };
    const handleClassChange = (event) => {
        setSelectedClass(event.target.value);
    };
    const handleYearChange = (event) => {
        setYear(event.target.value);
    };
    const handleSemesterChange = (event) => {
        setSemester(event.target.value);
    };
    const handleTeacherChange = (event) => {
        setSelectedTeacher(event.target.value);
    };
    const handleGradeSendingStatusChange = (event) => {
        setGradeSendingStatus(event.target.value);
    };

    const fetchFaculty = async () => {
        try {
            const response = await axios.get(NEW_API_URL + "/faculty", {
                headers: {
                    api_key: API_KEY,
                },
            });
            if (response.data.success) {
                setFacultyList(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching faculty:", error);
        }
    };

    // Similar changes for fetchDepartment, fetchMajor, and fetchClass functions

    // ...

    const fetchDepartment = async () => {
        if (selectedFaculty) {
            try {
                const response = await axios.get(
                    NEW_API_URL + "/department/" + selectedFaculty,
                    {
                        headers: {
                            api_key: API_KEY,
                        },
                    }
                );
                if (response.data.success) {
                    setDepartmentList(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching department:", error);
            }
        }
    };

    // Similar changes for fetchMajor and fetchClass functions

    // ...

    const fetchMajor = async () => {
        if (selectedDepartment) {
            try {
                const response = await axios.get(
                    NEW_API_URL + "/getmajo/" + selectedDepartment,
                    {
                        headers: {
                            api_key: API_KEY,
                        },
                    }
                );
                if (response.data.success) {
                    setMajorList(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching major:", error);
            }
        }
    };

    // ...

    const fetchClass = async () => {
        if (selectedMajor) {
            try {
                const response = await axios.get(
                    NEW_API_URL + "/class/" + selectedMajor + "/" + year,
                    {
                        headers: {
                            api_key: API_KEY,
                        },
                    }
                );
                if (response.data.success) {
                    setClassList(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching class:", error);
            }
        }
    };

    useEffect(() => {
        // if (!disableDep) setSelectedDepartment("");
        fetchDepartment();
    }, [selectedFaculty]);

    useEffect(() => {
        // setSelectedMajor("");
        fetchMajor();
    }, [selectedDepartment]);

    useEffect(() => {
        // setSelectedClass("");
        fetchClass();
    }, [selectedMajor, year]);

    useEffect(() => {
        fetchFaculty();
    }, []);

    useEffect(() => {
        onSelected({
            faculty: selectedFaculty,
            department: selectedDepartment,
            major: selectedMajor,
            class: selectedClass,
            year: year,
            semester: semester,
            numberTest: numberTest,
            status: gradeSendingStatus,
            teacherId: selectedTeacher,
            title: courseTitle || "",
            set_skip: set_skip,
            set_limit: set_limit
        });
    }, [selectedFaculty, selectedClass, selectedDepartment, selectedMajor, year, semester, numberTest, selectedTeacher, gradeSendingStatus, courseTitle]);


    return (
        <div>
            <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group">
                        <label>ຄະນະວິຊາ</label>
                        <select
                            disabled={disableFact}
                            className="form-select form-select-md mb-3 "
                            aria-label=".form-select-lg example"
                            value={selectedFaculty}
                            onChange={handleFacultyChange}
                        >
                            <option value="">ເລືອກຄະນະວິຊາ</option>
                            {facultyList.map((faculty) => (
                                <option key={faculty._id} value={faculty._id}>
                                    {faculty.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group">
                        <label>ພາກວິຊາ</label>
                        <select
                            disabled={disableDep}
                            className="form-select form-select-md mb-3 "
                            aria-label=".form-select-lg example"
                            value={selectedDepartment}
                            onChange={handleDepartmentChange}
                        >
                            <option value="">ເລືອກພາກວິຊາ</option>
                            {departmentList.map((department) => (
                                <option
                                    key={department._id}
                                    value={department._id}
                                    disabled={department._id === selectedDepartment}
                                >
                                    {department.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group">
                        <label>ສາຂາວິຊາ</label>
                        <select
                            className="form-select form-select-md mb-3 "
                            aria-label=".form-select-lg example"
                            value={selectedMajor}
                            onChange={handleMajorChange}
                        >
                            <option value="">ເລືອກສາຂາວິຊາ</option>
                            {majorList.map((major) => (
                                <option key={major._id} value={major._id}>
                                    {major.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group">
                        <label>ປີ</label>
                        <select
                            className="form-select form-select-md mb-3 "
                            aria-label=".form-select-lg example"
                            id="year"
                            value={year}
                            onChange={handleYearChange}
                        >
                            <option value="">ເລືຶອກປີຮຽນ</option>
                            {[1, 2, 3, 4, 5, 6].map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group">
                        <label>ພາກຮຽນ</label>
                        <select
                            className="form-select form-select-md mb-3 "
                            aria-label=".form-select-lg example"
                            id="semester"
                            value={semester}
                            onChange={handleSemesterChange}
                        >
                            <option value="">ເລືຶອກພາກຮຽນ</option>
                            {[1, 2].map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="form-group">
                        <label>ຫ້ອງຮຽນ</label>
                        <select
                            className="form-select form-select-md mb-3 "
                            aria-label=".form-select-lg example"
                            id="class"
                            value={selectedClass}
                            onChange={handleClassChange}
                        >
                            <option value="">ເລືຶອກຫ້ອງຮຽນ</option>
                            {classList.map((item) => (
                                <option key={item._id} value={item._id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default FilterGradeSendingClass;
