import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { Breadcrumb, Button, Table, Accordion, Card } from "react-bootstrap";
import { useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from 'react-redux'
import Consts from "../../../consts";
import { CustomContainer, Title } from "../../../common";
import { formatDate, dateTime, formatDateTime } from "../../../common/super";
import Loading from "../../../common/Loading";
import { FACULTY } from "../apollo/query";
import { REGIS_FACULTY_DATE_HISTORIES } from "../../../apollo/regisFacultyDateHistory";
import { DEPARTMENTS } from "../apollo/query";
import EditDateTimeRegister from "./EditDateTimeRegister";

import "./style.css";

export default function SettingFacultyDetail() {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  // Route
  const { history, match } = useReactRouter();
  // Varible
  const facultyId = match.params.id;
  // State
  const [showUploadView, setShowUploadView] = useState(false);
  const [dataRegisFacultyDateHistories, setDataRegisFacultyDateHistories] = useState([]);

  // Apollo
  // const [updateDepartment] = useMutation(UPDATE_DEPARTMENT)
  let [loadFacultyData, { loading, data }] = useLazyQuery(FACULTY, { variables: { where: { id: facultyId } }, });

  let [loadRegisFacultyDateHistoryData, { loading: regisFacultyDateHistoryLoading, data: regisFacultyDateHistoryData }] = useLazyQuery(REGIS_FACULTY_DATE_HISTORIES, {
    variables: {
      where: {
        faculty: facultyId
      },
      orderBy: "createdAt_DESC",
    },
  });

  let [loadDepartment, { loading: loadingDepartment, data: dataDepartment }] = useLazyQuery(DEPARTMENTS);

  // Use
  useEffect(() => {
    loadDepartment({
      variables: {
        where: {
          isDeleted: false,
          assessmentYear: accessmentRedux,
          faculty: facultyId,
        },
      },
    });
    loadFacultyData();
    loadRegisFacultyDateHistoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setDataRegisFacultyDateHistories(regisFacultyDateHistoryData?.regisFacultyDateHistories);
  }, [regisFacultyDateHistoryData]);

  // Function
  const _handleUploadViewClose = () => setShowUploadView(false);
  const _handleUploadViewShow = () => setShowUploadView(true);

  const _toSettingDepartmentDetail = (id, name) => {
    history.push(`/setting-faculty-detail/${facultyId}/department/${id}`, {
      faculty: data?.faculty,
      department: name,
    });
  };

  // Loadding
  if (loading || regisFacultyDateHistoryLoading || loadingDepartment)
    return <Loading />;

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ເລືອກການຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push("/setting-faculty")}
        >
          ການລົງທະບຽນ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{data?.faculty?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        {/* title */}
        <Title text={data?.faculty?.name} />
        {/* body list */}
        <div
          style={{
            width: "100%",
            minHeight: 250,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 32,
            paddingBottom: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid " + Consts.SECONDARY_COLOR,
              width: "65%",
              minHeight: 150,
              justifyContent: "center",
              paddingTop: 32,
              paddingBottom: 16,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                paddingLeft: 32,
              }}
            >
              <div style={{ width: "70%" }}>
                <h6>
                  <span style={{ fontWeight: "bold" }}>ຄະນະ: {"\t"}</span>
                  <span>{data?.faculty?.name}</span>
                </h6>
                <h6>
                  <span style={{ fontWeight: "bold" }}>
                    ໄລຍະລົງທະບຽນ: {"\t"}
                  </span>
                  <span>
                    {formatDate(data?.faculty?.startDateRegister) ??
                      "ຍັງບໍ່ທັນຕັ້ງຄ່າ"}
                  </span>
                  {"\t"} ~ {"\t"}
                  <span>
                    {formatDate(data?.faculty?.endDateRegister) ??
                      "ຍັງບໍ່ທັນຕັ້ງຄ່າ"}
                  </span>
                </h6>
                <h6>
                  <span style={{ fontWeight: "bold" }}>
                    ອັບເດດລ່າສຸດ: {"\t"}
                  </span>
                  <span>
                    {formatDate(data?.faculty?.updatedAt)}{" "}
                    {dateTime(data?.faculty?.updatedAt) ?? "ຍັງບໍ່ທັນຕັ້ງຄ່າ"}{" "}
                    {"\t"} (ໂດຍ: {"\t"}
                    {data?.faculty?.updatedBy?.firstname
                      ? data?.faculty?.updatedBy?.firstname +
                      "  " +
                      (data?.faculty?.updatedBy?.lastname ?? "")
                      : data?.faculty?.createdBy?.firstname
                        ? data?.faculty?.createdBy?.firstname +
                        "  " +
                        (data?.faculty?.createdBy?.lastname ?? "")
                        : "ຍັງບໍ່ທັນຕັ້ງຄ່າ"}
                    )
                  </span>
                </h6>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "30%",
                  justifyContent: "flex-end",
                  paddingRight: 16,
                }}
              >
                <Button
                  style={{
                    border: "1px solid " + Consts.SECONDARY_COLOR,
                    backgroundColor: Consts.SECONDARY_COLOR,
                    color: "white",
                    width: "100%",
                    height: 40,
                  }}
                  onClick={() => _handleUploadViewShow()}
                >
                  ແກ້ໄຂ 
                </Button> 
              </div>
            </div>
            <div style={{ width: "100%", marginBottom: 0, marginTop: 32 }}>
              <Accordion>
                <Card style={{ border: 0 }}>
                  <Card.Header style={{ backgroundColor: "white" }}>
                    <Accordion.Toggle
                      as={Card}
                      style={{
                        color: Consts.SECONDARY_COLOR,
                        cursor: "pointer",
                        border: 0,
                        textDecoration: "underline",
                      }}
                      eventKey="0"
                    >
                      ເບິ່ງປະຫວັດການອັບເດດ
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {dataRegisFacultyDateHistories?.data?.length > 0 ? (
                        <Table striped bordered>
                          <tr
                            style={{
                              backgroundColor: Consts.SECONDARY_COLOR,
                              color: "white",
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            <th style={{ width: 70, textAlign: "center" }}>
                              #
                            </th>
                            <th>ວັນທີເປີດໃຫ້ລົງທະບຽນ</th>
                            <th>ວັນທີສິ້ນສຸດໃຫ້ລົງທະບຽນ</th>
                            <th>ອັບເດດໂດຍ</th>
                            <th>ອັບເດດວັນທີ</th>
                          </tr>
                          <tbody>
                            {dataRegisFacultyDateHistories?.data.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td style={{ textAlign: "center" }}>
                                    {formatDate(item?.startDateRegister)}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {formatDate(item?.endDateRegister)}
                                  </td>
                                  <td>
                                    {item?.createdBy?.firstname
                                      ? item?.createdBy?.firstname +
                                      "  " +
                                      (item?.createdBy?.lastname ?? "")
                                      : "-"}
                                  </td>
                                  <td>
                                    {formatDateTime(item?.createdAt)}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <p
                          style={{
                            color: "#bfbfbf",
                            fontSize: 18,
                            fontWeight: "bold",
                          }}
                        >
                          ຍັງບໍ່ເຄີຍມີການຕັ້ງຄ່າ
                        </p>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div>
        <br />
        {/* Department */}
        <h4 style={{ fontWeight: "bold" }}>ພາກວິຊາ</h4>
        <h6>ພາກວິຊາທັງໝົດ ({dataDepartment?.departments?.length})</h6>
        <Table hover>
          <tr
            style={{
              backgroundColor: Consts.SECONDARY_COLOR,
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <th style={{ width: 70, textAlign: "center" }}>#</th>
            <th>ຊື່ພາກວິຊາ</th>
            <th>ວັນທີເປີດໃຫ້ລົງທະບຽນ</th>
            <th>ວັນທີສິ້ນສຸດໃຫ້ລົງທະບຽນ</th>
          </tr>
          <tbody>
            {dataDepartment?.departments?.map((item, index) => (
              <tr
                key={index}
                onClick={() => _toSettingDepartmentDetail(item.id, item.name)}
                style={{
                  backgroundColor: "#f1f1f1",
                  borderBottomWidth: 2,
                  borderBottomColor: "white",
                  borderBottomStyle: "solid",
                }}
              >
                <td style={{ textAlign: "center" }}>{index + 1}</td>
                <td>{item?.name}</td>
                <td style={{ textAlign: "center" }}>
                  {formatDate(item?.startDateRegister) ?? "-"}
                </td>
                <td style={{ textAlign: "center" }}>
                  {formatDate(item?.endDateRegister) ?? "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CustomContainer>

      <EditDateTimeRegister
        data={data?.faculty ?? ""}
        showUploadView={showUploadView}
        _handleUploadViewClose={_handleUploadViewClose}
        loadFacultyData={loadFacultyData}
      />
    </div>
  );
}
