import React from 'react'
import Consts from '../consts'

/**
 *
 * @param {confirm} : show confirm
 * @param {addIcon} : show addIcon
 * @param {angleDoubleUp} : show angleDoubleUp
 * @param {downloadIcon} : show downloadIcon
 */
function CustomButton({ title, onClick, confirm, addIcon, angleDoubleUp, angleDoubleRight, angleDoubleLeft, downloadIcon, editIcon, searchIcon, width, height, style, disabled}) {
  return (
    <button
      style={{
        backgroundColor: confirm && !disabled ? Consts.SECONDARY_COLOR : '#fff',
        color: confirm && !disabled ? '#fff' : Consts.SECONDARY_COLOR,
        width: width || 140,
        height: height || 40,
        border: '1px solid ' + Consts.SECONDARY_COLOR,
        outline: 'none',
        borderRadius: 5,
        ...style
      }}
      disabled={disabled ?? false}
      onClick={() => onClick()}
    >
      {addIcon && <i className='fa fa-plus' />}{' '}
      {angleDoubleUp && <i className='fa fa-angle-double-up' />}{' '}
      {angleDoubleRight && <i className='fa fa-angles-right' />}{' '}
      {angleDoubleLeft && <i className='fa fa-angles-left' />}{' '}
      {downloadIcon && <i className='fa fa-download' />} {title}
      {editIcon && <i className='fa fa-edit' />}
      {searchIcon && <i className='fa fa-search' />}
    </button>
  )
}

export default CustomButton
