import React, { useState, useEffect, useRef, forwardRef } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import ReactToPrint from "react-to-print";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import * as _ from "lodash";

/**
 *
 * @Component
 *
 */
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import { CustomContainer, TableHeader, Title } from "../../../common";
import PopUpLoading from "../../../common/PopUpLoading";

/**
 *
 * @Constant
 *
 */
import Consts from "../../../consts";

/**
 *
 * @Apollo
 *
 */
import { GRADE_All_STUDENT_COURSE, GRADES } from "../../../apollo/grade/index";

/**
 *
 * @Function
 *
 */
import { convertGenderNamEN, getUserDataFromLCStorage } from "../../../helpers/user";
import { setGenders } from "../../../common/super";
import Empty from "../../../common/Empty";
import "../util/index.css";
import { useSelector } from "react-redux";
import { FACULTIES } from "../../../apollo/faculty";
import { DEPARTMENTS } from "../../../apollo/deparment";
import { MAJORS } from "../../../apollo/major";
import { CLASSROOM_MASTERS } from "../../../apollo/classRoom";

let gradeDatasData;
const ComponentToPrint = forwardRef((props, ref) => (
  <div ref={ref}>
    <table width="100%" style={{ color: "#fff" }}>
      <TableHeader>
        <th style={{ width: 70 }}>ລຳດັບ</th>
        <th style={{ textAlign: "left", width: "20%" }}>ຊື່ ແລະ ນາມສະກຸນ</th>
        {gradeDatasData?.grades?.data[0]?.gradeType?.map((x, index) => {
          return <th key={index}>{x.name}</th>;
        })}
        <th width="100">ລວມ</th>
        <th width="100">ເກຣດ</th>
      </TableHeader>
      <tbody>
        {gradeDatasData?.grades?.data?.map((x, index) => {
          return (
            <tr
              style={{
                borderBottom: "2px solid #ffff",
                textAlign: "center",
              }}
              key={index}
            >
              <td>{index + 1}</td>
              <td style={{ textAlign: "left" }}>
                {!x?.student?.firstNameL || x?.student?.firstNameL === "" ? 
                  convertGenderNamEN(x?.student?.gender) + " " + x?.student?.firstNameE + " " + x?.student?.lastNameE
                  :
                  setGenders(x?.student?.gender) + " " + x?.student?.firstNameL + " " + x?.student?.lastNameL
                }
                {/* {setGenders(x.student?.gender) + " "}
                {x.student?.firstNameL
                  ? x.student.firstNameL + " " + x.student?.lastNameL ?? "-"
                  : "-"} */}
              </td>
              {x?.gradeType?.map((_data, index) => {
                return <td key={index}>{_data?.score}</td>;
              })}
              <td>{x?.numbericGrade}</td>
              <td>{x?.letterGrade}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
));

export default function SentGradesDetail() {
  const componentRef = useRef();
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const { history, location, match } = useReactRouter();
  const courseID = match?.params?.id;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [gradeDatas, setGradeDatas] = useState([]);
  const [gradeType, setGradeType] = useState([]);

  const [dataFaculties, setDataFaculties] = useState([]);
  const [facultyId, setFacultyId] = useState("");

  const [dataDepartments, setDataDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState("");

  const [dataMajors, setDataMajors] = useState([]);
  const [majorId, setMajorId] = useState("");
  const [dataClassRooms, setDataClassRooms] = useState([]);
  const [classroomId, setClassroomId] = useState("");
  const [year, setYear] = useState("");
  const [semester, setSemester] = useState("");
  const [dataUser, setDataUser] = useState({});

  // query grade
  const [loadGradeData, { data: gradeDataLoad }] = useLazyQuery(GRADES, {fetchPolicy:"network-only"});
  const [loadAllStudentGrade,{loading: gradeAllStudentLoading},] = useLazyQuery(GRADE_All_STUDENT_COURSE);
  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTIES);
  const [loadDepartments, { data: departmentData }] = useLazyQuery(DEPARTMENTS);
  const [loadMajor, { data: majorData }] = useLazyQuery(MAJORS);
  const [loadClassRooms, { data: classroomData }] = useLazyQuery(CLASSROOM_MASTERS);
  
  const _handleShowConfirmModalClose = () => setShowConfirmModal(false);

  useEffect(() => {
    if (getUserDataFromLCStorage()?.data) setDataUser(getUserDataFromLCStorage()?.data)
    loadGradeData({
      variables: {
        where: {
          assessmentYear: accessmentRedux,
          numberTest: 1,
          course: courseID,
        },
      },
    });
    loadFaculties({
      variables: {
        where: { assessmentYear: accessmentRedux, isDeleted: false },
      },
    });
    setYear(location?.state?.yearLevel)
    setSemester(location?.state?.semester)
  }, [courseID, accessmentRedux]);

  useEffect(() => {
    if (gradeDataLoad) {
      gradeDatasData = gradeDataLoad;
      setGradeDatas(gradeDataLoad?.grades?.data);
    }
  }, [gradeDataLoad]);

  useEffect(() => {
    if (dataUser?.role === "FAC_ADMIN") {
      setFacultyId(dataUser?.faculty?.id)
    }
    else if (dataUser?.role === "DEP_ADMIN") {
      setFacultyId(dataUser?.faculty?.id)
      setDepartmentId(dataUser?.department?.id)
    }
  }, [dataUser])

  useEffect(() => {
    if(facultyData) setDataFaculties(facultyData?.faculties);
  }, [facultyData]);

  useEffect(() => {
    if(departmentData) setDataDepartments(departmentData?.departments);
  }, [departmentData]);

  useEffect(() => {
    if(majorData) setDataMajors(majorData?.majors?.data);
  }, [majorData]);

  useEffect(() => {
    if(classroomData) setDataClassRooms(classroomData?.classRooms?.data);
  }, [classroomData]);

  useEffect(() => {
    let grade = [];
    gradeDatas.map((x, index) => {
      grade.push(x?.gradeType);
    });
    setGradeType(grade);
  }, [gradeDatas]);

  useEffect(() => {
    if(facultyId !== "") {
      loadDepartments({
        variables: { where: { isDeleted: false, faculty: facultyId } },
      });
    }
  }, [facultyId]);

  useEffect(() => {
    if(departmentId !== "") {
      loadMajor({
        variables: { where: { isDeleted: false, department: departmentId } },
      });
    }
  }, [departmentId]);

  useEffect(() => {
    if(!majorId) return;
    let _where = {}
    if (year !== "") _where = { ..._where, year: year.toString() }
    if(majorId !== "") _where = { ..._where, major: majorId }
    loadClassRooms({
      variables: { where: _where },
    });
  }, [majorId, year]);

  useEffect(() => {
    let _where = { assessmentYear: accessmentRedux, numberTest: 1, course: courseID }

    if (facultyId !== "") _where = { ..._where, faculty: facultyId }
    if (departmentId !== "") _where = { ..._where, department: departmentId }
    if (majorId !== "") _where = { ..._where, major: majorId }
    if (year !== "") _where = { ..._where, yearLevel: parseInt(year) }
    if (classroomId !== "") _where = { ..._where, classRoom: classroomId }
    loadGradeData({
      variables: {
        where: _where
      },
    });
  }, [facultyId, departmentId, majorId, year, classroomId])

  const _getStudentAll = () => {
    loadAllStudentGrade({
      variables: {
        where: {
          course: courseID,
        },
      },
    });
  };

  const _selectFaculty = (e) => {
    setFacultyId(e.target.value);
    setDepartmentId("")
    setDataDepartments([])
    setMajorId("")
    setDataMajors([])
    setClassroomId("")
    setDataClassRooms([])
  }
  const _onSelectedDepartment = (e) => {
    setDepartmentId(e.target.value);
    setMajorId("")
    setDataMajors([])
    setClassroomId("")
    setDataClassRooms([])
  }
  const _onSelectedMajor = (e) => {
    setMajorId(e.target.value);
    setClassroomId("")
    setDataClassRooms([])
  }
  const _onSelectedClassroom = (e) => {
    setClassroomId(e.target.value);
  }
  const _onSelectedYear = (e) => {
    setYear(e.target.value);
  }
  const _onSelectedSemester = (e) => {
    setSemester(e.target.value);
  }

  return (
    <div>
      {/* <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => history.push("/grades-index/1", location?.state)}
        >
          <strong>ຄະແນນ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => history.push("/grades-index/1", location?.state)}
        >
          <strong>ເລືອກໝວດໝູ່</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => history.push("/grades-course-list/limit/40/skip/1", location?.state)}
        >
          <strong>ວິຊາທັງໝົດ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ລາຍລະອຽດຄະແນນ</Breadcrumb.Item>
      </Breadcrumb>
      <GradeNavCourseDetail data={location?.state} goToRoute={goToRoute} /> */}

      <CustomContainer style={{ marginTop: -16 }}>
        <Title
          text={`ລາຍລະອຽດຄະແນນທັງໝົດໃນວິຊາ: ${location?.state?.title ?? ""}`}
        />
        <Row>
          <Col sm={3}>
            <p style={{padding: 0, margin: 0, marginBottom: 10}}>ຄະນະ</p>
            <Form.Control
              as="select"
              className='form-select'
              style={{ cursor: "pointer" }}
              value={facultyId}
              disabled={dataUser?.role === "FAC_ADMIN" || dataUser?.role === "DEP_ADMIN" ? true : false}
              onChange={(e) => { _selectFaculty(e) }}
            >
              <option value="">ຄະນະທັງໝົດ</option>
              {dataFaculties?.map((item, index) => {
                return (
                  <option key={index} value={item?.id}>
                    {item?.name}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
          <Col sm={3}>
            <p style={{padding: 0, margin: 0, marginBottom: 10}}>ພາກວິຊາ</p>
            <Form.Control
              as="select"
              className='form-select'
              style={{ cursor: "pointer" }}
              value={departmentId}
              disabled={dataUser?.role === "DEP_ADMIN" ? true : false}
              onChange={(e) => _onSelectedDepartment(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataDepartments?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
          <Col sm={3}>
            <p style={{padding: 0, margin: 0, marginBottom: 10}}>ສາຂາ</p>
            <Form.Control
              as="select"
              className='form-select'
              style={{ cursor: "pointer" }}
              value={majorId}
              onChange={(e) => _onSelectedMajor(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataMajors?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
          <Col sm={3}>
            <p style={{padding: 0, margin: 0, marginBottom: 10}}>ປີຮຽນ</p>
            <Form.Control
              as="select"
              className='form-select'
              style={{ cursor: "pointer" }}
              disabled
              value={year}
              onChange={(e) => _onSelectedYear(e)}
            >
              <option value="">ທັງໝົດ</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </Form.Control>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <p style={{padding: 0, margin: 0, marginBottom: 10}}>ພາກຮຽນ</p>
            <Form.Control
              as="select"
              className='form-select'
              style={{ cursor: "pointer" }}
              disabled
              value={semester}
              onChange={(e) => _onSelectedSemester(e)}
            >
              <option value="">ທັງໝົດ</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </Form.Control>
          </Col>
          <Col sm={3}>
            <p style={{padding: 0, margin: 0, marginBottom: 10}}>ຫ້ອງຮຽນ</p>
            <Form.Control
              as="select"
              className='form-select'
              style={{ cursor: "pointer" }}
              value={classroomId}
              onChange={(e) => _onSelectedClassroom(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataClassRooms?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
        </Row>
        {gradeDatas && gradeDatas?.length > 0 ? (
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 20px",
                }}
              >
                <a>ທັງໝົດ {gradeDatas?.length}</a>
                <div style={{ display: "flex", padding: "5px 0" }}>
                  <ReactToPrint
                    trigger={() => (
                      <div
                        className="btn btn-outline-success"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#FFFFFF",
                          padding: 3,
                          marginRight: 10,
                          borderRadius: 4,
                          border: `1px solid ${Consts.BORDER_COLOR}`,
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <i className="fa fa-print"></i>&nbsp; ສັ່ງພີມ
                      </div>
                    )}
                    content={() => componentRef.current}
                  />
                  <button
                    className="btn btn-outline-success"
                    onClick={() => {
                      _getStudentAll();
                      setShowConfirmModal(!showConfirmModal);
                    }}
                    style={{ float: "right" }}
                  >
                    <i className="fa fa-file-excel"></i> Export Excel
                  </button>
                </div>
              </div>
              {gradeDataLoad && <ComponentToPrint ref={componentRef} />}
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </CustomContainer>
      {/* export excel */}
      <Modal
        center
        show={showConfirmModal}
        onHide={_handleShowConfirmModalClose}
        size="lg"
      >
        {gradeAllStudentLoading && <PopUpLoading />}
        <Modal.Header style={{ textAlign: "center", paddingTop: 40 }}>
          {"ລາຍລະອຽດຂໍ້ມູນທັງໝົດໃນວິຊາ "}
          <div style={{ height: 20 }} />
          <div className="row" style={{ padding: 20 }}>
            <Button
              className="btn"
              variant="outline-dark"
              onClick={_handleShowConfirmModalClose}
            >
              ຍົກເລີກ
            </Button>
            &nbsp;&nbsp;
            <ReactHTMLTableToExcel
              style={{ backgroundColor: "#000" }}
              className="btn btn-outline-success"
              table="ExportData"
              filename={"ລາຍງານຂໍ້ມູນສະຖິຕິ"}
              sheet="Sheet"
              buttonText="Export excel"
              font={"NotoSansLao"}
              headerPaddingCellOptions={{ background: "#ff0000" }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table
              className="table"
              id="ExportData"
              style={{  fontSize: 12}}
            >
              <thead>
                <tr>
                  <th style={{ width: 70 }}>ລຳດັບ</th>
                  <th width="25%">ຊື່ ແລະ ນາມສະກຸນ</th>
                  {gradeType[0]?.map((item, index) => {
                    return <th key={index}>{item?.name}</th>;
                  })}
                  <th>ຄະະແນນລວມ</th>
                  <th>ເກຣດ</th>
                </tr>
              </thead>
              <tbody>
                {gradeDataLoad?.grades?.data?.map((x, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td style={{ textAlign: "left" }}>
                        {!x?.student?.firstNameL || x?.student?.firstNameL === "" ? 
                          convertGenderNamEN(x?.student?.gender) + " " + x?.student?.firstNameE + " " + x?.student?.lastNameE
                          :
                          setGenders(x?.student?.gender) + " " + x?.student?.firstNameL + " " + x?.student?.lastNameL
                        }
                        {/* {setGenders(x.student?.gender) + " "}
                        {x.student?.firstNameL ? x.student?.firstNameL +  " " + x.student?.lastNameL ?? "-" : "-"} */}
                      </td>
                      {x?.gradeType?.map((r_up, rIndex) => {
                          return <td key={rIndex}>{r_up?.score}</td>;
                        })}
                      <td>{x?.numbericGrade}</td>
                      <td>{x?.letterGrade}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
