import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { Breadcrumb } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import useReactRouter from "use-react-router";
import { CustomContainer, Title } from "../../../common";
import Loading from "../../../common/Loading";
import { UPDATE_LOG } from "../../../apollo/updateLog";
import { formatDateTime } from "../../../common/super";
import { PAGE_GINATION } from "../../../consts/router";

export default function UpdateLogsDetail() {
  const { history, match } = useReactRouter();
  let logId = match?.params?.id;
  const [logData, setLogData] = useState([]);
  const [
    loadUpdatelog,
    { loading: updateLogLoading, data: updateLogData },
  ] = useLazyQuery(UPDATE_LOG);

  useEffect(() => {
    loadUpdatelog({
      variables: { where: { id: logId }, orderBy: "createdAt_DESC" },
    });
    if (updateLogData?.updateLog?.diffData) {
      setLogData(JSON.parse(updateLogData?.updateLog?.diffData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLogData, logId]);

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ປະຫວັດການປ່ຽນແປງຂໍ້ມູນ</Breadcrumb.Item>
      </Breadcrumb>
      <Nav variant="tabs" style={{ height: 51 }}>
        <Nav.Item>
          <Nav.Link onClick={() => history.push(`/settings/update-log`+PAGE_GINATION)}>
            ປະຫວັດການປ່ຽນແປງຂໍ້ມູນ
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link active={"update-log"} style={{ cursor: "no-drop" }}>
            ລາຍລະອຽດປະຫວັດການປ່ຽນແປງຂໍ້ມູນ
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <CustomContainer>
        {updateLogLoading && <Loading />}
        {/* title */}
        <Title text="ລາຍລະອຽດການປ່ຽນແປງຂໍ້ມູນ"></Title>
        {/* body list */}
        <div className="row">
          <div className="col-md-12">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th style={{ width: 200 }}>ຊື່ຟິວ</th>
                  <th>ຂໍ້ມູນເກົ່າ</th>
                  <th>ຂໍ້ມູນໃໝ່</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ລະຫັດລົງທະບຽນ</td>
                  <td>{logData?.registration?.__old}</td>
                  <td>{logData?.registration?.__new}</td>
                </tr>
                <tr>
                  <td>ລະຫັດນັກຮຽນ</td>
                  <td>{logData?.student?.studentNumber?.__old}</td>
                  <td>{logData?.student?.studentNumber?.__new}</td>
                </tr>
                <tr>
                  <td>{"ພາກຮຽນ"}</td>
                  <td>{logData?.semester?.__old}</td>
                  <td>{logData?.semester?.__new}</td>
                </tr>
                <tr>
                  <td>{"ປີຮຽນ"}</td>
                  <td>{logData?.yearLevel?.__old}</td>
                  <td>{logData?.yearLevel?.__new}</td>
                </tr>
                <tr>
                  <td>{"ສົກຮຽນ"}</td>
                  <td>{logData?.assessmentYear?.__old}</td>
                  <td>{logData?.assessmentYear?.__new}</td>
                </tr>
                <tr>
                  <td>{"ຄະແນນ"}</td>
                  <td>{logData?.numericGrade?.__old}</td>
                  <td>{logData?.numericGrade?.__new}</td>
                </tr>
                <tr>
                  <td>ເກຣດ</td>
                  <td>{logData?.letterGrade?.__old}</td>
                  <td>{logData?.letterGrade?.__new}</td>
                </tr>
                <tr>
                  <td>{"ວັນທີສ້າງ"}</td>
                  <td>{formatDateTime(logData?.updatedAt?.__old)}</td>
                  <td>{formatDateTime(logData?.updatedAt?.__new)}</td>
                </tr>
                <tr>
                  <td>{"ສ້າງໂດຍ"}</td>
                  <td>{logData?.updatedBy?.__old}</td>
                  <td>{logData?.updatedBy?.__new}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CustomContainer>
    </div>
  );
}
