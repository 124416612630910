import { gql } from 'apollo-boost'

export const QUERY_DATA_REGISTRATION = gql`
query QueryRegistration(
    $studentWhere: CustomStudentWhereInput
    $studentSkip: Int
    $studentFirst: Int

    $FacultyWhere: CustomFacultyWhereInput
    $departmentWhere: CustomDepartmentWhereInput

    $courseWhere: CustomCourseWhereInput
  ) {
    students(
      where: $studentWhere
      skip: $studentSkip
      first: $studentFirst
      ) {
      data {
        id
        gender
        firstNameL
        lastNameL
        firstNameE
        lastNameE
        studentNumber
        yearLevel
        faculty {
          id
          name
        }
        department{
          id
          name
        }
      }
      total
    }

    faculties(where: $FacultyWhere) {
        id
        name
        departments{
          id
          name
      }
    }

    course_page(where: $courseWhere) {
      data{
        id
        title
      }
    }

    departments(where: $departmentWhere) {
        id
        name
        faculty{
          id
          name
        }
    }
  }
`;

export const REGISTER_DEPARTMENT = gql`
query Departments( $where: CustomDepartmentWhereInput) {
    departments(where: $where) {
        id
        name
        faculty{
          id
          name
        }
    }
  }
`;

