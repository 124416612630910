import React, { useState, useEffect, useRef } from "react";
import useReactRouter from "use-react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from 'react-redux'

/**
 *
 * @Library
 *
 */
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CKEditor from "ckeditor4-react";

/**
 *
 * @Component
 *
 */
import { Breadcrumb, Form, Button, Row, Col } from "react-bootstrap";
import { CustomContainer, Title } from "../../common";

/**
 *
 * @Constant
 *
 */
/**
 *
 * @Apollo
 *
 */
import { CREATE_NOTIC } from "../../apollo/notic";
import { FACULTIES } from "../../apollo/faculty";
import { PRE_SIGNED_URL, PRE_SIGNED_MANY_URL } from "../../apollo/doc";

/**
 *
 * @Function
 *
 */
import "./notice.css";
import { messageWarning } from "../../common/super";
import { toDayDash } from "../../common/super";
import { CREATE_FILE } from "../../apollo/file";
import { PAGE_GINATION } from "../../consts/router";
import LoadingFullScreen from "../../common/LoadingFullScreen";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function AddNotice() {
  const { history } = useReactRouter();
  // The first commit of Material-UI
  const classes = useStyles();
  /**
   *
   * @State
   *
   */
  const [isLoading, setIsLoading] = useState(false);
  const [loadFile, setLoadFile] = useState([]);
  const [coverImage, setCoverImage] = useState();
  const [previewImageURL, setPreviewImageURL] = useState();
  const accessmentRedux = useSelector((state) => state.accessment.value)

  const [checkAccessable, setCheckAccessable] = useState("");
  //  const [noticType, setNoticType] = useState();
  const [fileType, setFileType] = useState([]);
  const [fileUrl, setFileUrl] = useState([]);
  const [facultyName, setFacultyName] = useState("");
  const [facultyId, setFacultyId] = useState("");
  const [valueData, setValueData] = useState("");
  /**
   *
   * @Apollo
   *
   */

  const [loadPresignImage, { data: presignData }] = useLazyQuery(PRE_SIGNED_URL);

  const [loadFileUrl, { data: dataFile }] = useLazyQuery(PRE_SIGNED_MANY_URL);

  const [createFile] = useMutation(CREATE_FILE);
  const [createNotic] = useMutation(CREATE_NOTIC);
  const [loadFaculty, { data: facultyData }] = useLazyQuery(FACULTIES, {fetchPolicy: "network-only"});
  /**
   *
   * @UseEffect
   *
   */
  useEffect(() => {
    setCheckAccessable("ALL");
    loadFaculty({variables: {where: {isDeleted: false, assessmentYear: accessmentRedux}}})
  }, [])
  useEffect(() => {
    loadFaculty({variables: {where: {isDeleted: false, assessmentYear: accessmentRedux}}})
  }, [accessmentRedux])

  useEffect(() => {
    if (dataFile?.preSignedManyUrl?.length > 0) {
      let newUrl = fileUrl;
      for (var i = 0; i < dataFile.preSignedManyUrl.length; i++) {
        newUrl.push({ url: dataFile.preSignedManyUrl[i].url });
      }
      setFileUrl(newUrl);
    }
  }, [dataFile]);

  useEffect(() => {
    if (fileType.length > 0) {
      getPresign(fileType);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [fileType]);

  useEffect(() => {
    getPresignImage(coverImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverImage]);

  /**
   *
   * @Function
   *
   */


  const _bactoNoticeList = () => {
    history.push(`/notice-list/${1}`);
    // window.location.reload(true);
  };
  const handleReset = () => {
    history.push(`/notice-list/limit/40/skip/1`);
  };
  // function images
  const inputFile = useRef("inputFile");
  const inputImage = useRef("inputImage");
  const _addImage = () => {
    inputImage.current.click();
  };
  const _addFile = () => {
    inputFile.current.click();
  };
  const _handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setPreviewImageURL(URL.createObjectURL(event.target.files[0]));
      setCoverImage(event.target.files[0]);
    }
  };
  const getPresign = async (fileType) => {
    await loadFileUrl({
      variables: {
        data: fileType,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getPresignImage = async (file) => {
    await loadPresignImage({
      variables: {
        mimeType: file?.type,
        fileName: file?.name.split(".")[0],
      },
    });
  };

  const handleChangeFaculty = (e) => {
    setFacultyName(e.target.value.split("|+|")[0]);
    setFacultyId(e.target.value.split("|+|")[1]);
  };

  const _handleChangeFile = async (event) => {
    let type = [];
    for (var i = 0; i < event?.target?.files?.length; i++) {
      let _backType =
        event.target.files[i].name.split(".")[
        event.target.files[i].name.split(".").length - 1
        ];
      let _frontType = event.target.files[i].type.split("/")[0];
      type.push({
        mimeType: `${_frontType}/${_backType}`,
        fileName: event.target.files[i].name.split(".")[0],
      });
    }

    setFileType(type);
    let newFile = [...loadFile, ...event.target.files];

    setLoadFile(newFile);
  };

  const _removeFile = (e) => {
    let newFile = [];
    for (var i = 0; i < loadFile.length; i++) {
      if (i !== e) {
        newFile.push(loadFile[i]);
      }
    }
    setLoadFile(newFile);
  };

  const _uploadFile = async () => {
    if (presignData.preSignedUrl) {

      console.log("preurl")
      // console.log(previewImageURL)
      try {
        const response = await axios({
          method: "put",
          url: presignData.preSignedUrl.url,
          data: coverImage,
          headers: {
            "Content-Type": " file/*; image/*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        });
        return response;
      }
      catch(err) {
        console.log("add notic error")
        console.log(err)

      }
     
    }
  };

  const _uploadManyFile = async () => {
    if (fileUrl && fileUrl.length > 0) {
      let newResponse = [];
      for (var i = 0; i < fileUrl.length; i++) {
        const response = await axios({
          method: "put",
          url: fileUrl[i].url,
          data: loadFile[i],
          headers: {
            "Content-Type": " file/*; image/*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        });
        newResponse.push(response);
      }
      return newResponse;
    }
  };

  const _createNotic = async (parameter) => {
    try {
      let fileName = null;
      if (coverImage) {
        const res = await _uploadFile();
        fileName = res.request.responseURL.split("?")[0];
      }

      let newUploadUrl = [];
      if (loadFile.length > 0) {
        const res = await _uploadManyFile();
        for (var i = 0; i < res.length; i++) {
          newUploadUrl.push({
            title: res[i].request.responseURL.split("?")[0].split("/")[4],
            file: res[i].request.responseURL.split("?")[0],
          });
        }
      }
      if (newUploadUrl.length > 0) {
        let fileData = {
          title: newUploadUrl[0]?.title,
          file: newUploadUrl[0]?.file
        };
        await createFile({
          variables: {
            data: fileData
          }
        }).then(async (x) => {
          let allData = {
            ...parameter.data,
            files: [x.data.createFile.id],
            coverImage: {
              url: fileName,
            },
          };
          // TD: send data 1 server
          await createNotic({ variables: { data: allData } }).then((y) => {
            history.push("/notice-list" + PAGE_GINATION);
            window.location.reload(true);
          }
          );
        });

      } else {
        let allData = {
          ...parameter.data,
          coverImage: {
            url: fileName,
          },
        };
        // TD: send data 2 server
        const newNotic = await createNotic({ variables: { data: allData } })
        if(newNotic?.data?.createNotic?.id) {
          setIsLoading(false)
          history.push("/notice-list" + PAGE_GINATION);
          window.location.reload(true);
        }
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "white",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);


  const onEditorChange = (evt) => {
    setValueData(evt.editor.getData());
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={_bactoNoticeList} alt>
          ການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          ເພີ່ມການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ
        </Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <Title text={"ເພີ່ມການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ"} />
        <Formik
          initialValues={{
            noticeType: "NOTIC",
            facultyName: facultyName,
            accessableRole: checkAccessable || "ALL",
            startDate: toDayDash(),
            endDate: toDayDash(),
            title: "",
            content: valueData,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.title) {
              errors.title = "ກະລຸນາຕື່ມ";
            }
            return errors;
          }}
          onSubmit={async (values) => {
            let accessableRole;
            if (values.accessableRole === "ALL") {
              accessableRole = ["ADMIN", "TEACHER", "STUDENT", "DEP_ADMIN", "FAC_ADMIN", "DEAN"]
            } else {
              accessableRole = ["ADMIN", values.accessableRole]
            };
            let accessableFaculty = null;
            if (facultyName !== "") {
              accessableFaculty = { facultyId: facultyId, name: facultyName };
            }
            if (facultyName === "") {
              accessableFaculty = facultyData?.faculties?.map((item, index) => {
                return (
                  { facultyId: item.id, name: item.name }
                )
              });
            }
            let parameter = {
              data: {
                type: values.noticeType,
                startDate: values.startDate,
                endDate: values.endDate,
                accessableRole: accessableRole,
                title: values.title,
                content: valueData,
                accessableFaculty,
                isPublish: true,
              },
            };
            if (valueData === "") {
              messageWarning("ກະລຸນາ, ປ້ອນເນື້ອໃນເອກະສານກ່ອນ");
            } else {
              _createNotic(parameter);
              setIsLoading(true)
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <div>
              {isLoading ? <LoadingFullScreen /> : <></>}
              <Form.Group>
                <Form.Label>ປະເພດແຈ້ງການ</Form.Label>
                <Form.Control
                  as="select"
                  require
                  name="noticeType"
                  value={values.noticeType}
                  onChange={handleChange}
                >
                  <option value="NOTIC">ແຈ້ງການ</option>
                  <option value="AGREEMENT">ຂໍ້ຕົກລົງ</option>
                  <option value="REGULATION">ລະບຽບ</option>
                  <option value="NEWS">ໜັງສືພິມ</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>ຄະນະ</Form.Label>
                <Form.Control
                  as="select"
                  name="facultyName"
                  onChange={(e) => handleChangeFaculty(e)}
                >
                  <option value="">ທຸກຄະນະ</option>
                  {facultyData?.faculties?.map((item, index) => {
                    return (
                      <option key={index} value={item.name + "|+|" + item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Label>ເລືອກການສະແດງ</Form.Label>
              {"\n"}
              <Form.Group
                as={Row}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Form.Check
                  style={{ marginLeft: 25 }}
                  type="radio"
                  label="ທັງໝົດ"
                  name="accessableRole"
                  id="accessable"
                  value="ALL"
                  onChange={handleChange}
                  checked={values.accessableRole === "ALL" ? true : false}
                />
                <Form.Check
                  style={{ marginLeft: 30 }}
                  type="radio"
                  label="ສະເພາະອາຈານ"
                  name="accessableRole"
                  id="accessable2"
                  value="TEACHER"
                  onChange={handleChange}
                  checked={values.accessableRole === "TEACHER" ? true : false}
                />
                <Form.Check
                  style={{ marginLeft: 30 }}
                  type="radio"
                  label="ສະເພາະນັກຮຽນ"
                  name="accessableRole"
                  id="accessable3"
                  value="STUDENT"
                  onChange={handleChange}
                  checked={values.accessableRole === "STUDENT" ? true : false}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label style={{ display: "block" }}>
                  ກຳນົດເວລາແຈ້ງການ
                </Form.Label>
                <span>ວັນທີເປີດ</span>
                <TextField
                  name="startDate"
                  type="date"
                  defaultValue={values.startDate}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />{" "}
                <span>ວັນທີປິດ</span>
                <TextField
                  name="endDate"
                  type="date"
                  defaultValue={values.endDate}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }} Col={9}>
                <Form.Label>ປ້ອນຫົວຂໍ້ເອກະສານ</Form.Label>
                <Row>
                  <Col
                    md={1}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 12,
                      width: 110,
                      height: 50,
                      cursor: "pointer",
                      marginLeft: 15,
                      border: "1px solid #057CAE",
                      borderStyle: "dashed",
                    }}
                  >
                    {/* <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1,
                      }}
                    >
                      <FontAwesomeIcon icon={["fa", "plus"]} />
                      &nbsp;&nbsp; ເພີ່ມຮູບພາບ
                    </div> */}

                    <img
                      src={previewImageURL}
                      style={{ height: 50, position: "absolute" }}
                      alt=""
                    />
                    <button onClick={_addImage}>ເລືອກຮູບ</button>
                    <input
                      type="file"
                      id="inputImage"
                      multiple
                      name="image"
                      ref={inputImage}
                      style={{ display: "none" }}
                      onChange={_handleChangeImage}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      style={{ height: 50 }}
                      name="title"
                      value={values.title}
                      isInvalid={!!errors.title}
                      onChange={handleChange}
                      placeholder="ກະລຸນາປ້ອນ..."
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Label>ປ້ອນເນື້ອໃນເອກະສານ</Form.Label>

              <div>
                <CKEditor data={valueData} onChange={onEditorChange} />
              </div>

              <Form.Label style={{ marginTop: 10, display: "block" }}>
                ອັບໂຫລດ File ເອກະສານ
              </Form.Label>
              <div className={loadFile.length > 0 ? "box-dashed" : ""}>
                {loadFile?.map((item, index) => {
                  return (
                    <div className="file-item">
                      <div className="tagfile">{item?.name}</div>
                      <button
                        onClick={() => _removeFile(index)}
                        className="btn btn-sm"
                        style={{ float: "right", width: "20%" }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  );
                })}
              </div>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      <b style={{ color: "red" }}>ຄຳແນະນຳ</b>
                    </Typography>
                    <hr />
                    <p>{"ຂະໜາດບໍ່ເກີນ 5MB "}</p>
                    <p style={{ color: "red" }}>{"ອັບໂຫລດໄດ້ສະເພາະໄຟລ໌"}</p>
                    <p>{".PDF, .XLSX, .DOCX, .PPT, .JPEG, .PNG, .JPG"}</p>
                  </React.Fragment>
                }
              >
                <div
                  onClick={_addFile}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: 50,
                    border: "1px solid #057CAE",
                    borderStyle: "dashed",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={["fa", "download"]} />
                  &nbsp;&nbsp;&nbsp;&nbsp; ອັບໂຫຼດ File ເອກະສານ
                  <input
                    type="file"
                    id="inputfile"
                    multiple
                    name="image"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={_handleChangeFile}
                  />
                </div>
              </HtmlTooltip>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: 30,
                }}
              >
                <Button
                  onClick={handleReset}
                  variant={"none"}
                  style={{
                    border: "1px solid #057CAE",
                    borderRadius: 0,
                    width: 200,
                  }}
                >
                  ຍົກເລີກ
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  onClick={handleSubmit}
                  style={{
                    border: "1px solid #057CAE",
                    borderRadius: 0,
                    width: 200,
                  }}
                >
                  ຕົກລົງ
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </CustomContainer>
    </div>
  );
}
