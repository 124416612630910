import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import "./index.css"; // Import your custom CSS
import { NEW_API_URL, API_KEY } from "../../common/contrant";
function Popup() {
  const [show, setShow] = useState(false);
  const [scholarships, setScholarships] = useState([]);

  const [newTitle, setNewTitle] = useState("");
  const [newImage, setNewImage] = useState("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    fetchScholarships();
  }, []);

  const fetchScholarships = async () => {
    try {
      const response = await axios.get(NEW_API_URL + "popup/all");
      setScholarships(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddNew = () => {
    setShow(true);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
  };

  const handleSave = async () => {
    const formData = new FormData();
    // console.log(formData)
    formData.append("title", newTitle);
    formData.append("file", newImage);

    try {
      const response = await axios.post(NEW_API_URL + "popup", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setScholarships([...scholarships, response.data]);
      setShow(false);
      console.log("Scholarship added successfully:", response.data);
    } catch (error) {
      console.error("Error adding scholarship:", error);
    }
  };

  const handleDeleteClick = (item) => {
    // console.log("Delete icon clicked, item:", item);
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(NEW_API_URL + "/popup/" + itemToDelete._id);
      setScholarships(scholarships.filter((s) => s._id !== itemToDelete._id));
      setShowDeleteModal(false);
      setItemToDelete(null);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <div className="body px-5 py-3">
      <div className="d-flex justify-content-end">
        <Button
          variant="success"
          className="d-flex align-items-center"
          onClick={handleAddNew}
        >
          <i className="fas fa-plus-circle me-2"></i> ເພີ່ມໃຫມ່
        </Button>
      </div>
      <div className="">
        <Row>
          {scholarships.map((item, index) => (
            <Col key={index} xs={12} md={6} lg={3} xl={3}>
              <div className="card card-body p-3 mt-3 position-relative custom-card">
                <div className="rounded border">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="card-img-top custom-img"
                  />
                  <button
                    className="position-absolute top-0 end-0 border-d"
                    onClick={() => handleDeleteClick(item)}
                  >
                    <i className="fas fa-trash"></i>
                  </button>
                </div>
                <div className="card-body p-2">
                  <h5 className="card-title text-center">{item.title}</h5>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ເພີ່ມຫົວຂໍ້ ແລະ ຮູບ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>ຫົວຂໍ້</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formImage">
              <Form.Label>ຮູບ</Form.Label>
              <Form.Control
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={handleImageChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            ປິດ
          </Button>
          <Button variant="primary" onClick={handleSave}>
            ບັນທຶກ
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ທ່ານແນ່ໃຈບໍ່ວ່າຢາກລົບ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>ຫົວຂໍ້: {itemToDelete?.title}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            ຍົກເລີກ
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            ລຶບ
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Popup;
