import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import Tabs from "react-bootstrap/Tabs";
import "./utils/index.css";
import {Tab } from "react-bootstrap";
import RegistrationStudentList from "./RegistrationStudentList";
import RegistrationStudentFacultyList from "./RegistrationStudentFacultyList";
import RegistrationStudentStatistic from "./RegistrationStudentStatistic";
import RegistrationStudentStatisticTribe from "./RegistrationStudentStatisticTribe";

//
function RegistrationStudent() {
  /**
   * routes
   */
  const { history } = useReactRouter();
  /**
   * use states
   */
  const [titles, setTitles] = useState(["ລົງທະບຽນນັກສຶກສາ"]);
  const [callStdId, setCallStdId] = useState([]);
  const [selectStdId, setSelectStdId] = useState("2020");
  const [dataUser, setDataUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState();
  /**
   * use effect
   */
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    }
      
  }, []);// eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    let userRole = dataUser?.role;
    // check role
    if (userRole !== "ADMIN") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [dataUser]);

  useEffect(() => {
    let today = new Date();
    let y2 = today.getFullYear() - 1;
    var stdId = [];
    for (var i = y2; i >= 2000; i--) {
      stdId.push(i);
    }

    setCallStdId(stdId);
  }, []);

  const _onChangeStdId = (e) => {
    setSelectStdId(e.target.value);
  };

  const SEARCH = () => {
    return (
      <select
        id="search"
        className="form-control"
        style={{ border: "none" }}
        defaultValue={selectStdId}
        onChange={(e) => _onChangeStdId(e)}
      >
        <option value={"2021"}>{"2021"}</option>
        {callStdId?.map((item, index) => {
          return (
            <option key={index} value={item}>
              {item}
            </option>
          );
        })}
      </select>
    );
  };

  return (
    <div>
      {/* Breadcrumb */}
      <div className="h-title">
        <h3>{titles[0]}</h3>
      </div>
      {/* )})} */}
      
      <div>
        <SEARCH />
        <Tabs
          defaultActiveKey={isAdmin ? "alls" : "alls"}
          transition={false}
          style={{ borderColor: "none" }}
          className={".nav-tabs .nav-link.active"}
        >
          {!isAdmin ? (
            <Tab
              eventKey="alls"
              title="ຂໍ້ມູນທີ່ລົງທະບຽນຈາກ ວິຊາການ ມຊ"
              style={{ border: "none", cursor: "pointer" }}
            >
              <RegistrationStudentList stdIdFilter={selectStdId} />
            </Tab>
          ) : (
            ""
          )}

          <Tab
            eventKey={isAdmin ? "alls" : "fac"}
            title="ຂໍ້ມູນທີ່ລົງທະບຽນຈາກ ຄະນະ ມຊ"
            style={{ border: "none", cursor: "pointer" }}
          >
            <RegistrationStudentFacultyList stdIdFilter={selectStdId} />
          </Tab>

          <Tab
            eventKey="statistics"
            onClick={(k) => setTitles(k)}
            title="ຂໍ້ມູນສະຖິຕິ"
          >
            <RegistrationStudentStatistic stdIdFilter={selectStdId} />
          </Tab>
          <Tab
            eventKey="statistic-tribe"
            onClick={(k) => setTitles(k)}
            title="ສະຖິຕິຊົນເຜົ່າ"
            style={{ cursor: "pointer" }}
          >
            <RegistrationStudentStatisticTribe stdIdFilter={selectStdId} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
export default RegistrationStudent;
