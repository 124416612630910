import React, { useEffect, useRef, useState } from "react";
import useReactRouter from "use-react-router";
import ReactToPrint from "react-to-print";
import "../util/index.css";
import { CustomContainer } from "../../../common";
import { useLazyQuery } from "@apollo/react-hooks";
import { GRADES_TO_PRINT } from "../../../apollo/grade";
import ComponentToPrint from "./ComponentToPrint";
import ComponentToPrintE from "./ComponentToPrintE";
import { COURSES } from "../../../apollo/course";

let Genders;
let genders;
let maxCount;
let mapCount;

// English version
// const ComponentToPrintE = forwardRef((props, ref) => (
//     <div ref={ref}><br />
//         <div className="center">
//             <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Emblem_of_Laos.svg/1200px-Emblem_of_Laos.svg.png'} className="imgSize" alt="" />
//         </div>
//         <p className="center">Lao People's Democratic Republic<br />
//         Peace Independence Democracy Unity Properity</p>
//         <table className="table-score">
//             <tr style={{ borderStyle: "hidden" }}>
//                 <td style={{ borderStyle: "hidden" }}>University of Laos<br />
//                 Faculty of {studentDatas?.students?.data[0]?.faculty?.enName}</td>
//                 <td style={{ borderStyle: "hidden", textAlign: "right" }}>Ref.no:................................../........<br />
//                 Vientiane Capital, Date:...........................</td>
//             </tr>
//             <tr>
//                 <td colSpan="2" style={{ textAlign: "center", fontSize: "26px", fontWeight: "bold" }}>
//                     <p>OFFICIAL TRANSCRIPT OF ACADEMIC RECORDS</p></td>
//             </tr>
//             <tr style={{ borderStyle: "hidden" }}>
//                 <td style={{ borderStyle: "hidden" }}>Program of Study: Bachelor of {studentDatas?.students?.data[0]?.department?.enName}<br />
//                 Name and SurName: {Genders}{""} {studentDatas?.students?.data[0]?.firstNameE}&nbsp;
//                     {studentDatas?.students?.data[0]?.lastNameE}</td>
//                 <td style={{ borderStyle: "hidden", textAlign: "right" }}>DATE: {toDayDash()}<br />
//                 Student ID: {studentDatas?.students?.data[0]?.userId}</td>
//             </tr>
//         </table>
//         <br />

//         <div style={{ width: "100%" }}>
//             <div style={{ float: "left", width: "50%", marginRight: -1 }}>
//                 <table style={{ width: "100%", marginRight: -1 }}>
//                     <thead>
//                         <TableHeader>
//                             {/* <tr style={{ backgroundColor: "#057CAE", paddingTop:10, paddingBottom:10}}> */}
//                             <th>Course</th>
//                             <th>Course Name</th>
//                             <th className="center">Credit</th>
//                             <th className="center">Grade</th>
//                             {/* </tr> */}
//                         </TableHeader>
//                     </thead>
//                     <tbody>
//                         {gradeDatas?.map((item, index) => {
//                             return (
//                                 <tr key={index}>
//                                     <td className="center">{item?.course?.courseCode}</td>
//                                     <td>{item?.course?.enTitle}</td>
//                                     <td className="center">{item?.course?.unit}</td>
//                                     <td className="center">{item?.letterGrade}</td>
//                                 </tr>
//                             );
//                         })}
//                         {
//                            Array.from(Array(mapCount), ((e, i) =>{
//                                 return(
//                                 <tr>
//                                     <td className="center">-</td>
//                                     <td></td>
//                                     <td className="center"></td>
//                                     <td className="center"></td>
//                                 </tr> 
//                                 );
//                             })
//                            )}
//                         <tr>
//                             <td rowSpan="2">ReMark</td>
//                             <td colSpan="3" className="center">-</td>
//                         </tr>
//                         <tr>
//                         <td colSpan="3" className="center">-</td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//             <div style={{ float: "right", width: "50%", marginLeft: -1 }}>
//                 <table style={{ width: "100%", marginLeft: -1 }}>
//                     <thead>
//                         <TableHeader>
//                             {/* <tr style={{ backgroundColor: "#057CAE" }}> */}
//                             <th>Course</th>
//                             <th>Course Name</th>
//                             <th className="center">Credit</th>
//                             <th className="center">Grade</th>
//                             {/* </tr> */}
//                         </TableHeader>
//                     </thead>
//                     <tbody>
//                         {gradeDatass?.map((item, index) => {
//                             return (
//                                 <tr key={index}>
//                                     <td className="center">{item?.course?.courseCode}</td>
//                                     <td>{item?.course?.enTitle}</td>
//                                     <td className="center">{item?.course?.unit}</td>
//                                     <td className="center">{item?.letterGrade}</td>
//                                 </tr>
//                             );
//                         })}
//                         {
//                            Array.from(Array(maxCount), ((e, i) =>{
//                                 return(
//                                 <tr>
//                                     <td className="center">-</td>
//                                     <td></td>
//                                     <td className="center"></td>
//                                     <td className="center"></td>
//                                 </tr> 
//                                 );
//                             })
//                            )}
//                         <tr>
//                             <td colSpan="2">Total of Credit</td>
//                             <td colSpan="2"></td>
//                         </tr>
//                         <tr>
//                             <td colSpan="2">CGPA</td>
//                             <td colSpan="2"></td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//         </div>

//         {/* Asign */}
//         <br /><br />
//         <table className="table-score">
//             <tr>
//                 <td className="center" style={{ borderStyle: "hidden"}}></td>
//                 <td className="center" style={{ borderStyle: "hidden", width:"50%"}}>
//                     A(Excellent)=4; B+(Very Good)=3.5; B(Good)=3; C+(Fairly Good)=2.5;<br />
//             C(Fair)=2; D+(Poor)=1.5; D(Very Poor)=1; F(Fall)=0.</td>
//                 <td className="center" style={{ borderStyle: "hidden"}}></td>
//             </tr>
//         </table><br />
//         <table className="table-score">
//             <tr>
//                 <td className="center" style={{ borderStyle: "hidden", paddingRight: 200 }}>DEAN</td>
//                 <td style={{ textAlign: "right", paddingRight: "80px", borderStyle: "hidden" }}>Head of Department</td>
//             </tr>
//         </table>
//     </div>
// ));

export default function GradesToPrint() {
    const componentRef = useRef();
    const componentRefE = useRef();
    const { history, location } = useReactRouter();
    const [dataFromLocation, setDataFromLocation] = useState()
    const [gradeDatas, setGradeDatas] = useState()
    const [gradeDatass, setGradeDatass] = useState()
    const [totalCourse, setTotalCourse] = useState()
    const PAGE_LIMIT = 20;

    
    useEffect(() => {

        console.log("here is data location")
        console.log(location.state)

    },[location.state])



    let verSions = location?.state?.version;
    let length = location?.state?.Length;
    let maxcount = location?.state?.maxCount;
    // load grades
    const [loadGrades, {  data: gradeData }] = useLazyQuery(GRADES_TO_PRINT, {fetchPolicy: 'network-only'})
    const [loadGradess, {data: gradeDataa }] = useLazyQuery(GRADES_TO_PRINT, {fetchPolicy: 'network-only'})
    const [loadTotalCourse, {data: totalCourseData }] = useLazyQuery(COURSES, {fetchPolicy: 'network-only'})
    useEffect(() => {
        setDataFromLocation(location?.state)

        loadGrades({
            variables: {
                first: PAGE_LIMIT,
                where: { 
                    student: location?.state?.students?.students?.data[0]?.id,
                    sendingGradeStatus: "ADMIN_APPROVED"
                }
            }
        });
        loadGradess({
            variables: {
                skip: PAGE_LIMIT,
                where: { 
                    student: location?.state?.students?.students?.data[0]?.id,
                    sendingGradeStatus: "ADMIN_APPROVED"
                }
            }
        });
        loadTotalCourse({
            variables: {
                where: { 
                    faculty: location?.state?.students?.students?.data[0]?.faculty?.id,
                    department: location?.state?.students?.students?.data[0]?.department?.id,
                    major: location?.state?.students?.students?.data[0]?.major?.id,
                    classRoom: location?.state?.students?.students?.data[0]?.classRoom?.id,
                }
            }
        })
    }, [location?.state])

    useEffect(() => {
        if(gradeData) setGradeDatas(gradeData?.gradesToPrint?.data);
    }, [gradeData])

    useEffect(() =>{
        if(gradeDataa) setGradeDatass(gradeDataa?.gradesToPrint?.data);
    },[gradeDataa])

    useEffect(() => {
        if(totalCourseData) setTotalCourse(totalCourseData?.courses?.total)
    }, [totalCourseData])
    useEffect(() => {
        mapCount = 20 - parseInt(length);
    }, [length])
    
    return (
        <div>
            <CustomContainer style={{ marginTop: 0 }}>
                {verSions === "lao" ? <div>
                    {/* <ComponentToPrint ref={componentRef} /> */}
                    {dataFromLocation && gradeDatas && gradeDatass ?
                        <ComponentToPrint 
                            studentDatas={dataFromLocation?.students?.students?.data[0]} 
                            gradeDatas={gradeDatas} 
                            gradeDatass={gradeDatass} 
                            totalCourse={totalCourse}
                            length={gradeDatas?.length} 
                            maxCount={dataFromLocation?.maxCount} />
                        : <></>
                    }
                    <div style={{ display: "flex", padding: "5px 0" }}>
                        <ReactToPrint
                            trigger={() => (
                                <div style={{ textAlign: "right" }}>
                                    <button className="btn btn-info">ສັ່ງພີມ</button>
                                </div>
                            )}
                            content={() => componentRef.current}
                        />
                        <button className="btn btn-info btncancel"
                            onClick={() => history.goBack()}
                        >ຍົກເລີກ</button>
                    </div>

                </div> : <div>
                        {/* <ComponentToPrintE ref={componentRefE} /> */}
                        {dataFromLocation && gradeDatas && gradeDatass ?
                            <ComponentToPrintE 
                                studentDatas={dataFromLocation?.students?.students?.data[0]} 
                                gradeDatas={gradeDatas} 
                                gradeDatass={gradeDatass} 
                                totalCourse={totalCourse}
                                length={gradeDatas?.length} 
                                maxCount={dataFromLocation?.maxCount} />
                            : <></>
                        }
                        <div style={{ display: "flex", padding: "5px 0" }}>
                            <ReactToPrint
                                trigger={() => (
                                    <div style={{ textAlign: "right" }}>
                                        <button className="btn btn-info">ສັ່ງພີມ</button>
                                    </div>
                                )}
                                content={() => componentRefE.current}
                            />
                            <button className="btn btn-info btncancel"
                                onClick={() => history.goBack()}
                            >ຍົກເລີກ</button>
                        </div>
                    </div>}

            </CustomContainer>
        </div>
    )
}
