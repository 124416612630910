import { gql } from "apollo-boost";

export const GRADES = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data{
        id
        semester
        assessmentYear
        letterGrade
        yearLevel
        letterGrade
        gredeStatus
        course {
          courseCode
          title
          isUpgrade
          teacher{
            id
            firstname
            lastname
          }
        }
        student{
          id
          studentNumber
          firstNameL
          lastNameL
          assessmentYear
          gender
        }
       }
      total
    }
  }
`;

export const GRADES_FOR_UPGRADE = gql`
  query GradesForUpgrade(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    gradesForUpgrade(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data{
        id
        semester
        assessmentYear
        letterGrade
        yearLevel
        letterGrade
        gredeStatus
        course {
          courseCode
          title
          isUpgrade
          teacher{
            id
            firstname
            lastname
          }
        }
        student{
          id
          studentNumber
          firstNameL
          lastNameL
          assessmentYear
          gender
        }
       }
      total
    }
  }
`;