import { gql } from "apollo-boost";
export const USER_LOGIN = gql`
query UserLogin(
    $where: CustomUserLoginWhereInput
    $orderBy: UserLoginOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    ){
        userLogins(
            where: $where
            orderBy: $orderBy
            skip: $skip
            after: $after
            before: $before
            first: $first
            last: $last
            ){
        total
        data{
          id
          userId
          firstName
          lastName
          role
          createdAt
          createdBy
        }
      }
    }
`;
