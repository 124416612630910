import React, { useState } from "react";
import useReactRouter from "use-react-router";
import { Col, Row, Modal, Button, Spinner } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { UPDATE_STUDENT } from "./apollo/mutation";
import {
  convertGender,
  convertStatus,
  onConvertEducationLevel,
  onConvertStudentInfo,
  convertGenderNamEN,
  convertGenderNam,
  convertScholarship,
} from "../../helpers/user";
import "bootstrap/dist/css/bootstrap.min.css";
import addLogs from "../../helpers/AddLogs";
import axios from "axios";
import { USER_KEY } from "../../consts";
import { NEW_API_URL, API_KEY } from "../../common/contrant";
import Routers from "../../consts/router";
// CourseAddConfirm
function RegistrationConfirm({
  data,
  showAddConfirmModal,
  _handleShowAddConfirmModalClose,
}) {
  const { history } = useReactRouter();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateStudent] = useMutation(UPDATE_STUDENT);

  const _handleSubmit = async () => {
    try {
      setIsLoading(true);
      delete data.outPlanGraduateFromCountry;
      delete data.birthCountry;
      delete data.birthProvince;
      delete data.country;
      delete data.other;
      delete data.parentCountry;
      delete data.passport;
      delete data.province;
      delete data.qualification;
      delete data.departmentname;
      delete data.majorname;
      delete data.facultyname;
      // delete data.scorecardUrl
      delete data.idCardOrFamilyBook;
      delete data.certificate;
      delete data.testCard;
      delete data.tribeName;
      delete data.day;
      delete data.month;
      delete data.year;

      let _id = data.id;
      delete data.id;
      data = {...data, studentNumber: data.preStudentNumber + data.studentNumber}
      delete data.preStudentNumber
      const updateStudented = await updateStudent({
        variables: {
          where: { id: _id },
          data,
        },
      });

      setIsLoading(false);
      setShow(true);
      _handleShowAddConfirmModalClose();
      await addLogs({
        type: "PRE_STUDENT",
        action: "UPDATE",
        student: _id,
        status: "SUCCESS",
        data: data,
      });

      const user = localStorage.getItem(USER_KEY);
      const userObject = JSON.parse(user);
      const token = userObject?.accessToken;

      await axios.put(NEW_API_URL + "student", data, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      if (updateStudented) {
        setTimeout(() => {
          history.push(Routers.PRERE_STUDENT_LIST)
          // history.push("/preregistration_student-detail", data);
          window.location.reload();
        }, 100);
      }
      setShow(false);
    } catch (err) {
      console.error("err:", err.message);
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Modal
        style={{ marginTop: 50 }}
        show={showAddConfirmModal}
        onHide={() =>
          isLoading ? console.log("waiting") : _handleShowAddConfirmModalClose()
        }
        size="lg"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={!isLoading}>
          <Col
            style={{
              marginTop: 20,
              fontSize: 30,
              fontWeight: "bold",
              color: "#057CAE",
            }}
          >
            ກວດ​ສອບ​ຊື່​ລົງ​ທະ​ບຽນ
          </Col>
        </Modal.Header>
        {isLoading ? (
          <Modal.Body>
            <div
              style={{
                width: "100%",
                minHeight: 350,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" style={{ color: "#057CAE" }} />
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <Row>
              <Col sm="2"></Col>
              <Col sm="8">
                <Col style={{ fontSize: 24 }}>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{ color: "#057CAE" }}
                  />{" "}
                  ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ
                </Col>
                <Row style={{ marginLeft: 50 }}>
                  <Col sm="6">ລະຫັດນັກສຶກສາ</Col>
                  <Col sm="6">{data?.preStudentNumber + data?.studentNumber}</Col>
                  <Col sm="6">ຄະ​ນ​ະ</Col>
                  <Col sm="6">{data?.facultyname}</Col>
                  <Col sm="6">ພາກວິຊາ</Col>
                  <Col sm="6">{data?.departmentname}</Col>
                  <Col sm="6">ສາ​ຂາ​ວິ​ຊາ</Col>
                  <Col sm="6">{data?.majorname}</Col>
                  <Col sm="6">ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ</Col>
                  <Col sm="6">
                    {data &&
                      (data?.studentInfo
                        ? onConvertStudentInfo(data?.studentInfo)
                        : "-")}
                  </Col>
                  <Col sm="6">​ລະ​ດັບ</Col>
                  <Col sm="6">
                    {data &&
                      (data?.educationLevel
                        ? onConvertEducationLevel(data?.educationLevel)
                        : "-")}
                  </Col>
                </Row>
                <Col style={{ fontSize: 24 }}>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{ color: "#057CAE" }}
                  />{" "}
                  ຂໍ້ມູນ​ທົ່ວໄປ
                </Col>
                <Col className="row" style={{ marginLeft: 38 }}>
                  <Col sm="6"> ຊື່</Col>
                  <Col sm="6">
                    {convertGenderNam(data?.gender)} {data?.firstNameL}
                  </Col>
                  <Col sm="6">ນາມ​ສະ​ກຸນ</Col>
                  <Col sm="6">{data?.lastNameL}</Col>
                  <Col sm="6">ຊື່ (​ພາ​ສາ​ອັງ​ກິດ)</Col>
                  <Col sm="6">
                    {convertGenderNamEN(data?.gender)} {data?.firstNameE}
                  </Col>
                  <Col sm="6">ນາມ​ສະ​ກຸນ (​ພາ​ສາ​ອັງ​ກິດ)</Col>
                  <Col sm="6">{data?.lastNameE}</Col>
                  <Col sm="6">ວັນ​ເດືອນ​ປີ​ເກີດ</Col>
                  <Col sm="6">{data?.birthday}</Col>
                  <Col sm="6">ເພດ</Col>
                  <Col sm="6">
                    {data && (data?.gender ? convertGender(data?.gender) : "-")}
                  </Col>
                  <Col sm="6">ສະ​ຖາ​ນະ</Col>
                  <Col sm="6">
                    {data &&
                      (data?.maritualStatus
                        ? convertStatus(data?.maritualStatus)
                        : "-")}
                  </Col>
                  <Col sm="6"> ສັນ​ຊາດ</Col>
                  <Col sm="6">{data?.nationality}</Col>
                  <Col sm="6"> ຊົນ​ເຜົ່າ</Col>
                  <Col sm="6">{data?.tribeName}</Col>
                  <Col sm="6">ສາ​ສະ​ໜາ</Col>
                  <Col sm="6">{data?.religion}</Col>
                  <Col sm="6">ເບີໂທເຮືອນ</Col>
                  <Col sm="6">{data?.phoneHome}</Col>
                  <Col sm="6">ເບີໂທລະສັບມືຖື</Col>
                  <Col sm="6">{data?.phoneMobile}</Col>
                  <Col sm="6">ອີ່ເມວ</Col>
                  <Col sm="6">{data?.email}</Col>
                </Col>
                <Col style={{ fontSize: 24 }}>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{ color: "#057CAE" }}
                  />{" "}
                  ທີ່ຢູ່ປະຈຸບັນ
                </Col>
                <Row style={{ marginLeft: 50 }}>
                  <Col sm="6">ແຂວງ</Col>
                  <Col sm="6">{data?.address?.province}</Col>
                  <Col sm="6">ເມືອງ</Col>
                  <Col sm="6">{data?.address?.district}</Col>
                  <Col sm="6">ບ້ານ</Col>
                  <Col sm="6">{data?.address?.village}</Col>
                </Row>
                <Col style={{ fontSize: 24 }}>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{ color: "#057CAE" }}
                  />{" "}
                  ສະຖານທີເກີດ
                </Col>
                <Col className="row" style={{ marginLeft: 38 }}>
                  <Col sm="6">ແຂວງ</Col>
                  <Col sm="6">{data?.birthAddress?.province}</Col>
                  <Col sm="6">ເມືອງ</Col>
                  <Col sm="6">{data?.birthAddress?.district}</Col>
                  <Col sm="6">ບ້ານ</Col>
                  <Col sm="6">{data?.birthAddress?.village}</Col>
                </Col>
                {data?.studentType !== "IN_PLAN" ? (
                  <div>
                    <Col style={{ fontSize: 24 }}>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ color: "#057CAE" }}
                      />{" "}
                      ສຳຫຼັບນັກ​ສຶກ​ສາ​ຈ່າຍ​ຄ່າ​ຮຽນ
                    </Col>
                    <Row style={{ marginLeft: 50 }}>
                      <Col sm="6">ຈົບ​ຈາກແຂວງ</Col>
                      <Col sm="6">{data?.graduateFromProvince}</Col>
                      <Col sm="6">ຈົບຈາກໂຮງຮຽນ</Col>
                      <Col sm="6">{data?.graduateFromSchool}</Col>
                      <Col sm="6">ສົກສຶກສາ</Col>
                      <Col sm="6">{data?.semesterYear}</Col>
                    </Row>
                  </div>
                ) : (
                  <div>
                    <Col style={{ fontSize: 24 }}>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ color: "#057CAE" }}
                      />{" "}
                      ສໍາຫຼັບນັກສຶກສາ/ພະນັກງານ ທືນແບ່ງປັນ
                    </Col>
                    <Row style={{ marginLeft: 50 }}>
                      <Col sm="6"> ທຶນແບ່ງປັນ</Col>
                      <Col sm="6">{convertScholarship(data?.scholarship)}</Col>
                      <Col sm="6"> ເລກທີໃບນຳສົ່ງ</Col>
                      <Col sm="6">{data?.transferLetterNo}</Col>
                      <Col sm="6">ລົງ​ວັນ​ທີ່</Col>
                      <Col sm="6">{data?.dateTransferLetterNo}</Col>
                      <Col sm="6">ເລກທີຂໍ້ຕົກລົງເສັງ​ໄດ້​ທຶນ ມ​ຊ</Col>
                      <Col sm="6">{data?.scholarshipAgreementNo}</Col>
                      <Col sm="6">ລົງ​ວັນ​ທີ່</Col>
                      <Col sm="6">{data?.dateScholarshipAgreementNo}</Col>
                      <Col sm="6">ຈົບຈາກແຂວງ</Col>
                      <Col sm="6">{data?.graduateFromProvince}</Col>
                      <Col sm="6">ຈົບຈາກໂຮງຮຽນ</Col>
                      <Col sm="6">{data?.graduateFromSchool}</Col>
                      <Col sm="6">ສົກສຶກສາ</Col>
                      <Col sm="6">{data?.semesterYear}</Col>
                    </Row>
                  </div>
                )}
                {data?.educationLevel === "BACHELOR_CONTINUING" ? (
                  <Row style={{ marginLeft: 50 }}>
                    <Col sm="6">ຈົບສາຂາວິຊາ</Col>
                    <Col sm="6">{data?.graduatedMajor}</Col>
                  </Row>
                ) : null}
                <Col style={{ fontSize: 24 }}>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{ color: "#057CAE" }}
                  />{" "}
                  ຂໍ້​ມູນ​ຜູ້​ປົກ​ຄອງ
                </Col>
                <Row style={{ marginLeft: 50 }}>
                  <Col sm="6">ຊື ແລະ ນາມສະກຸນພໍ່</Col>
                  <Col sm="6">{data?.fatherNameAndSurname}</Col>
                  <Col sm="6">ຊື່ ແລະ ນາມສະກຸນແມ່</Col>
                  <Col sm="6">{data?.motherNameAndSurname}</Col>
                  <Col sm="6">ເບີໂທລະສັບພໍ່/ແມ່</Col>
                  <Col sm="6">{data?.parentTelephone}</Col>
                  <Col sm="6">ແຂວງ</Col>
                  <Col sm="6">{data?.parentAddress?.province}</Col>
                  <Col sm="6">ເມືອງ</Col>
                  <Col sm="6">{data?.parentAddress?.district}</Col>
                  <Col sm="6">ບ້ານ</Col>
                  <Col sm="6">{data?.parentAddress?.village}</Col>
                </Row>
                <Col style={{ fontSize: 24 }}>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{ color: "#057CAE" }}
                  />{" "}
                  ກໍ​ລະ​ນີ​ສຸກ​ເສີນ​ຕິດ​ຕໍ່
                </Col>
                <Row style={{ marginLeft: 50 }}>
                  <Col sm="6">ພົວ​ພັນ​ທ່ານ</Col>
                  <Col sm="6">{data?.contactName}</Col>
                  <Col sm="6">​ເບີ​ໂທ​ຕິດ​ຕໍ່</Col>
                  <Col sm="6">{data?.emergencyPhone}</Col>
                  <Col sm="6">ອ​າ​ໄສ​ຢູ່​ຮ່ວມ​ກັນ</Col>
                  <Col sm="6">
                    {data?.stayTogether === true ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                  </Col>
                </Row>
                {data?.stayTogether === false ? (
                  <Row style={{ marginLeft: 50 }}>
                    <Col sm="6">ແຂວງ</Col>
                    <Col sm="6">{data?.emergencyAddress?.province}</Col>
                    <Col sm="6">ເມືອງ</Col>
                    <Col sm="6">{data?.emergencyAddress?.district}</Col>
                    <Col sm="6">ບ້ານ</Col>
                    <Col sm="6">{data?.emergencyAddress?.village}</Col>
                  </Row>
                ) : null}
                <Col style={{ fontSize: 24 }}>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{ color: "#057CAE" }}
                  />{" "}
                  ອັບໂຫຼດເອກະສານຄັດຕິດ
                </Col>
                <Row style={{ marginLeft: 50 }}>
                  <Col sm="6">ບັດປະຈໍາຕົວ ຫຼື ສໍາມະໂນຄົວ (ພຣະ: ໜັງສືສຸທິ)</Col>
                  <Col
                    sm="6"
                    style={{
                      overflowX: "hidden",
                      overflowY: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data?.idCardOrFamilyBookUrl?.url ?? "-"}
                  </Col>
                  <Col sm="6">ໃບປະກາດ</Col>
                  <Col
                    sm="6"
                    style={{
                      overflowX: "hidden",
                      overflowY: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data?.certificateUrl?.url ?? "-"}
                  </Col>
                  {/* <Col sm='6'>ໃບປະກາດ</Col>
                <Col
                  sm='6'
                  style={{
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {data?.testCard}
                </Col> */}
                  <Col sm="6">ບັດເຂົ້າຫ້ອງເສັ່ງ</Col>
                  <Col
                    sm="6"
                    style={{
                      overflowX: "hidden",
                      overflowY: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data?.testCardUrl?.url ?? "-"}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Col
              className="d-flex justify-content-center"
              style={{ marginTop: 50, marginBottom: 30 }}
            >
              <Col sm="3">
                <button
                  type="submit"
                  style={{
                    width: 122,
                    height: 48,
                    color: "#057CAE",
                    border: "2px solid #057CAE",
                    backgroundColor: "#fff",
                    borderRadius: 6,
                  }}
                  onClick={() => _handleShowAddConfirmModalClose()}
                >
                  ຍົກເລີກ
                </button>
              </Col>
              <Col sm="1" />
              <Col sm="3">
                <Button
                  // type='submit'
                  onClick={() => {
                    _handleSubmit();
                  }}
                  style={{
                    width: 122,
                    height: 48,
                    color: "#fff",
                    backgroundColor: "#057CAE",
                    borderRadius: 6,
                  }}
                >
                  ຢືນຢັນ
                </Button>
              </Col>
            </Col>
          </Modal.Body>
        )}
      </Modal>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Body>
          <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div
              className="text-bold"
              style={{ color: "#00A1DE", fontSize: 32 }}
            >
              ສໍາເລັດ
            </div>
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="9x"
              style={{ color: "#00A1DE" }}
            />
            <div
              className="textfont-size19 text-bold"
              style={{ marginTop: 40 }}
            >
              ປັນທືກເປັນທີ່ຮຽບຮ້ອຍ
            </div>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RegistrationConfirm;
