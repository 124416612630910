import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import { Typography } from "@material-ui/core";

const LoadingDialog = ({ open, message }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh", // Adjust the height as needed
        }}
      >
        <div className="bg-white px-5 py-4 d-flex flex-column align-items-center">
          <CircularProgress />
          <Typography className="mt-3">{message || "ກຳລັງໂຫລດ..."}</Typography>
        </div>
      </div>
    </Modal>
  );
};

export default LoadingDialog;
