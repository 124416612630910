import { gql } from 'apollo-boost'
export const EDUCATIONLEVEL = gql`
  query EducationLevelMasters(
    $where: EducationLevelMasterWhereInput
    $orderBy: EducationLevelMasterOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    educationLevelMasters(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {data{
        id
        name
        }
    }
  }
`;