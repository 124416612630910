import { gql } from "apollo-boost";
export const REGISTER_STUDENT = gql`
  query registrationStudents(
    $where: RegistrationStudentInput
    $skip: Int
    $limit: Int
  ) {
    registrationStudents(where: $where, skip: $skip, limit: $limit) {
      data {
        stdid
        stdgd
        stdnameE
        stdnameL
        stdsurL
        stdsurE
        stddate
        std_tribes
        stdschool
        std_status
        user_date
        std_tribes
        stddate
        stdschool
        major_name
        std_seem
        stdmajor
        fac_id
        stdregno
        facEntre
      }
      success
      total
    }
  }
`;
export const STD_TRIBES = gql`
  query TbtribesQueryShow {
    tbtribesQueryShow {
      trib_id
      tribNo
      trib_name
    }
  }
`;
export const COURSE_FAC = gql`
  query DepartmentQueryShow($where: DepartmentQueryInput) {
    departmentQueryShow(where: $where) {
      maj_id
      maj_name
    }
  }
`;
export const STATISTICS_COUNT = gql`
  query StatisticsAll($where: StatisticsAllInput) {
    statisticsAll(where: $where) {
      success
      noSuccess
      total
    }
  }
`;
export const COURSE_STAT = gql`
  query DepartmentQueryShow($where: DepartmentQueryInput) {
    departmentQueryShow(where: $where) {
      maj_id
      maj_name
    }
  }
`;

export const UPDATE_REGISTRATION_STUDENT_BULK = gql`
  mutation updateRegistrationStudentBulk(
    $where: [UpdateRegistrationStudentBulkWhere]
  ) {
    updateRegistrationStudentBulk(where: $where)
  }
`;

export const TRIBES_COUNT = gql`
  query TribesCount($where: tribesCountInput) {
    tribesCount(where: $where) {
      name
      total
      female
    }
  }
`;
export const GENDER = gql`
  query Gender {
    gender {
      stdgd
      trib_name
    }
  }
`;
export const TRIBESINCHART = gql`
  query TribesInchart {
    tribesInchart {
      man
      woman
      total
    }
  }
`;

export const UPDATE_REGISTER_STUDENT = gql`
  mutation UpdateRegistrationStudent(
    $data: UpdateRegistrationStudentInput!
    $where: UpdateRegistrationStudentWhere!
  ) {
    updateRegistrationStudent(data: $data, where: $where)
  }
`;

export const FAC_QUERY = gql`
  query FacultyQueryShow {
    facultyQueryShow {
      fac_id
      fac_name
    }
  }
`;
export const STATISTICES = gql`
  query Statistics($where: StatisticsInput) {
    statistics(where: $where) {
      major
      total
      success
    }
  }
`;
export const STATISTIC = gql`
  query Statistics {
    statistics {
      major
      total
      success
    }
  }
`;
export const STATISTICES_ALL = gql`
  query StatisticsAll($where: StatisticsAllInput) {
    statisticsAll(where: $where) {
      total
      success
      noSuccess
    }
  }
`;
export const REGISTRATION_STUDENT = gql`
  query RegistrationStudent($where: registrationStudentInput) {
    registrationStudent(where: $where) {
      facEntre
      stdid
      fac_id
      major_name
      std_status
      std_tribes
      stddate
      stdgd
      stdmajor
      stdnameE
      stdnameL
      stdsurE
      stdregno
      stdschool
      dis_idB
      villageB
      stdliving
      stdsurL
      user_date
    }
  }
`;
