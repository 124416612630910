import { gql } from "apollo-boost";

export const LOGIN_USER = gql`
  mutation LoginUser($data: loginUserInput!) {
    loginUser(data: $data) {
      accessToken
      data {
        id
        userId
        firstname
        lastname
        role
        department {
          id
          name
        }
        faculty {
          id
          name
        }
     }
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($data: CustomUserCreateInputWithPassword!) {
    createUser(data: $data) {
      id
      firstname
      userId
    }
  }
`;

export const IMPORT_STUDENT = gql`
  mutation ImportStudentFromExcel($data: [UserCreateInputWithPassword!]!) {
    importStudentFromExcel(data: $data) {
      data {
        userId
        firstname
        lastname
      }
      message
      location
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $data: CustomUserUpdateInputWithPassword!
    $where: UserWhereUniqueInput!
  ) {
    updateUser(data: $data, where: $where) {
      id
      firstname
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($where: UserWhereUniqueInput!) {
    deleteUser(where: $where) {
      id
    }
  }
`;

export const USER = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      userId
      firstname
      lastname
      email
      phone
      role
      yearLevel
      description
      note
      birthday
      gender
      maritualStatus
      religion
      educationBackGround
      highSchool
      college
      passportNo
      idNumber
      introduction
      address {
        country
        province
        district
        village
        postCode
        lat
        lng
        detail
        note
      }
      department {
        name
      }
      faculty {
        name
      }
      createdAt
      updatedAt
    }
  }
`;


export const USERS = gql`
  query Users(
    $where: UserCustomWhereInput
    $orderBy: UserOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    user_page(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        id
        userId
        firstname
        lastname
        email
        phone
        role
        birthday
        description
        note
        yearLevel
        gender
        maritualStatus
        religion
        educationBackGround
        highSchool
        college
        passportNo
        idNumber
        introduction
        address {
          country
          province
          district
          village
          postCode
          lat
          lng
          detail
          note
        }
        department {
          name
          faculty {
            name
          }
        }
        faculty {
          name
        }
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const FINE_USERS = gql`
  query FineUser($keyword: String, $limit: Int, $role: String) {
    fineUser(keyword: $keyword, limit: $limit, role: $role) {
      id
      userId
      firstname
      lastname
      role
    }
  }
`;

export const QUERY_STUDENT = gql`
  query Student($where: StudentWhereUniqueInput!) {
    student(where: $where) {
      id
      userId
      lastNameL
      firstNameL
      faculty {
        id
        name
      }
      department {
        id
        name
      }
      yearLevel
      assessmentYear
    }
  }
`;

export const QUERY_STUDENTS = gql`
  query Students(
    $where: CustomStudentWhereInput
    $orderBy: StudentOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
    ) {
    students(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
      ) {
        data{
          id
          userId
          lastNameL
          firstNameL
          faculty{
              id
              name
            }
          department{
              id
              name
            }
        yearLevel
        assessmentYear
        email
        role
        phoneMobile
        userId
        }
    }
  }
`;

export const USERS_REGISTER = gql`
  query Users(
    $where: UserWhereInput
    $orderBy: UserOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    users(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      id
      userId
      firstname
      lastname
    }
  }
`;
export const TEACHERS = gql`
  query Users(
    $where:  CustomUserWhereInput
    $orderBy: UserOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    users(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        id
        userId
        moodleUserId
        firstname
        lastname
        userId
        email
        phone
        role
        yearLevel
        description
        note
        birthday
        gender
        maritualStatus
        religion
        educationBackGround
        highSchool
        college
        passportNo
        idNumber
        introduction
        address{
          country
          province
          district
          village
          postCode
          lat
          lng
          detail
          note
        }
        department{
          id
          name
        }
        faculty{
          id
          name
        }
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const TEACHERS_BOX = gql`
  query Users_page($where: UserWhereInput) {
    user_page(where:$where) {
      data {
        id
        firstname
        lastname
      }
      total
    }
  }
`;

export const LOAD_TEACHERS_BOX = gql`
  query UserTeachers($where: CustomUserWhereInput) {
    userTeachers(where: $where) {
      data {
        id
        firstname
        lastname
      }
      total
    }
  }
`;

export const COURSES_TIMES = gql`
  query Courses($where: CustomCourseWhereInput) {
    courses(where: $where) {
      data {
        dayTimeIndexes {
          day
          timeIndexes
        }
      }
    }
  }
`;
