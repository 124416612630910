import { gql } from 'apollo-boost'

export const DEPARTMENTS = gql`
  query Departments(
    $where: CustomDepartmentWhereInput
    $orderBy: DepartmentOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    departments(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
        id
        name
        enName
        description
        status
        enDescription
        startDateRegister
        endDateRegister
        note
        faculty{
          id
          name
          note
        }
        assessmentYear
        startDateRegister
        endDateRegister
    }
  }
`

export const DEPARTMENT = gql`
  query Department(
    $where: DepartmentWhereUniqueInput!
  ) {
    department(
      where: $where
    ) {
        id
        name
        description
        note
        faculty{
            id
            name
        }
        createdBy{
          id
          firstname
          lastname
        }
        courseList{
          id
          title
          courseCode
        }
        status
        startDateRegister
        endDateRegister
        files{
          id
          title
          description
          file
          keyword
          type
          cate
        }
        createdAt
        updatedAt
        updatedBy{
          firstname
          lastname
        }
    }
  }
`

export const CREATE_DEPARTMENT = gql`
  mutation CreateDepartment(
    $data: CustomDepartmentCreateInput!
  ) {
    createDepartment(
      data: $data
    ) {
        id
    }
  }
`

export const UPDATE_DEPARTMENT = gql`
  mutation UpdateDepartment(
    $data: CustomDepartmentUpdateDataInput!
    $where: DepartmentWhereUniqueInput!
  ) {
    updateDepartment(
      data: $data
      where: $where
    ) {
        id
        status
    }
  }
`

export const DELETE_DEPARTMENT = gql`
  mutation DeleteDepartment(
    $where: DepartmentWhereUniqueInput!
  ) {
    deleteDepartment(
      where: $where
    ) {
        id
    }
  }
`
export const UPDATE_DEPARTMENT_REGISTER = gql`
  mutation UpdateDepartmentDateRegister(
    $data: UpdateDepartmentDateRegisterInput!
    $where: DepartmentWhereUniqueInput!
  ) {
    updateDepartmentDateRegister(data: $data, where: $where) {
      id
    }
  }
`

export const DEPARTMENT_NAME = gql`
  query Departments($where: CustomDepartmentWhereInput) {
    departments(where: $where) {
      id
      name
      enName
      description
    }
  }
`;

export const APPROVE_GRADE = gql`
  mutation ApproveGrade(
    $where: GradeWhereUniqueInput!
  ) {
    approveGrade(where: $where) {
      id
    }
  }
`;
