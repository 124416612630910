import { gql } from "apollo-boost";

export const GRADEIMPROVESHISTORY = gql`
query GradeImproves(
  $where: GradeImproveWhereInput
  $orderBy: GradeImproveOrderByInput
  $skip: Int
  $after: String
  $before: String
  $first: Int
  $last: Int
){
  gradeImproves(
    where: $where
    orderBy: $orderBy
    skip: $skip
    after: $after
    before: $before
    first: $first
    last: $last
  ){
    total
    data{
      id
      	status
      student{
        id
        firstNameL
        lastNameL
        studentNumber
        yearLevel
      }
       teacher{
        id
        firstname
        lastname
      }
  		oldGrade{
        detail
        name
        score
        maxScore
      }
      newGrade{
        letterGrade
        gradeType{
          name
          score
          maxScore
        }
      }
    	course{
        id
        semester
        year
        title
      }
      assessmentYear
    }  
  }
}
`;