import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import * as _ from "lodash";
import queryString from "query-string";
/**
 *
 * @Component
 *
 */
import { Breadcrumb } from "react-bootstrap";
import { useSelector } from 'react-redux'
import { CustomContainer, CustomButton, Title, TableHeader, TableCell } from "../../common";
import Loading from "../../common/Loading";
/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";
import Routers from "../../consts/router";
/**
 *
 * @Apollo
 *
 */

import { FACULTIES } from "../../apollo/faculty";
import { DEPARTMENTS } from "../../apollo/deparment";
import { STUDENTS } from "./../../apollo/student";
import { CLASSROOM_MASTERS } from "../../apollo/classRoom";
/**
 *
 * @Function
 *
 */
import { currency, setGenders } from "../../common/super";
import PaginationHelper from "../../helpers/PaginationHelper";
import { convertGenderNamEN, getUserDataFromLCStorage } from "../../helpers/user";
import RoleAdmin from "./filter/RoleAdmin";
import RoleDepartment from "./filter/RoleDepartment";
import RoleFaculty from "./filter/RoleFaculty";
import { MAJORS } from "../../apollo/major";

function AssignStudentList() {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const { history, location } = useReactRouter();
  const parsed = queryString?.parse(location?.state);
  /**
   *
   * @State
   *
   */
  const [dataFaculties, setDataFaculties] = useState([]);
  const [facultyId, setFacultyId] = useState(!parsed?.facultyId ? "" : parsed?.facultyId);

  const [dataDepartments, setDataDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState(!parsed?.department ? "" : parsed?.department);
  const [dataMajors, setDataMajors] = useState([]);
  const [majorId, setMajorId] = useState(!parsed?.major ? "" : parsed?.major);
  const [year, setYear] = useState(!parsed?.year ? "" : parsed?.year);
  const [classroomId, setClassroomId] = useState(!parsed?.classroomId ? "" : parsed?.classroomId);
  const [dataUser, setDataUser] = useState({});

  const [dataStudents, setDataStudents] = useState([]);
  const [totals, setTotals] = useState(0);
  const [dataClassroom, setDataClassroom] = useState([]);
  const { _limit, _skip, Pagination_helper } = PaginationHelper();
  /**
   *
   * @Query
   *
   */
  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTIES);
  const [loadDepartments, { data: departmentData }] = useLazyQuery(DEPARTMENTS);
  const [loadMajors, { data: majorData }] = useLazyQuery(MAJORS);
  const [loadStudents, {loading: studentLoading, data: studentData }] = useLazyQuery(STUDENTS, { fetchPolicy: "network-only" });
  const [loadClassRooms, { data: classroomData }] = useLazyQuery(CLASSROOM_MASTERS)
  /**
   *
   * @UseEffect
   *
   */
  useEffect(() => {
    if (getUserDataFromLCStorage()?.data) setDataUser(getUserDataFromLCStorage()?.data)
    loadFaculties({
      variables: {
        where: { isDeleted: false, assessmentYear: accessmentRedux },
      },
    });

    loadStudents({
      variables: {
        where: { isDeleted: false, assessmentYear: accessmentRedux, studyStatus: ["STUDYING", "DROP", "DONT_STUDY"], classRoom_not_null: true },
        skip: (_skip - 1) * _limit,
        first: _limit,
        orderBy: "createdAt_DESC"
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessmentRedux]);

  useEffect(() => {
    if(dataUser?.role === "FAC_ADMIN") setFacultyId(dataUser?.faculty?.id)
    if(dataUser?.role === "FAC_ADMIN" || dataUser?.role === "DEP_ADMIN"){
      setFacultyId(dataUser?.faculty?.id)
      setDepartmentId(dataUser?.department?.id)
    }
  },[dataUser])

  useEffect(() => {
    let qlWhere = { 
      isDeleted: false, 
      assessmentYear: accessmentRedux, 
      studyStatus: ["STUDYING", "DROP", "DONT_STUDY"],
      classRoom_not_null: true
    };
    if (facultyId !== "") qlWhere = { ...qlWhere, faculty: facultyId };
    if (departmentId !== "") qlWhere = { ...qlWhere, department: departmentId };
    if (majorId !== "") qlWhere = { ...qlWhere, major: majorId };
    if (year !== "" && year !== "ທັງໝົດ") qlWhere = { ...qlWhere, yearLevel: year };
    if (classroomId !== "") {
      qlWhere = { ...qlWhere, classRoom: classroomId };
      delete qlWhere.classRoom_not_null
    }

    loadStudents({
      variables: {
        where: qlWhere,
        skip: (_skip - 1) * _limit,
        first: _limit,
        orderBy: "createdAt_DESC"
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facultyId, departmentId, majorId, year, accessmentRedux, classroomId]);
  /* set data student */
  useEffect(() => {
    if (studentData?.students) {
      setDataStudents(studentData?.students?.data);
      setTotals(studentData?.students?.total);
    }
  }, [studentData]);
  useEffect(() => {
    setDataFaculties(facultyData?.faculties);
  }, [facultyData]);

  useEffect(() => {
    if(facultyId === ""){
      setDataDepartments([]);
      setDepartmentId("");
      return
    }

    loadDepartments({
      variables: { where: { isDeleted: false, faculty: facultyId, assessmentYear: accessmentRedux } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facultyId]);
  
  useEffect(() => {
    if(departmentId === ""){
      setDataMajors([]);
      setMajorId("");
      return
    }
    loadMajors({
      variables: { where: { isDeleted: false, department: departmentId, assessmentYear: accessmentRedux } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);
  useEffect(() => {
    if(majorId === ""){
      setDataClassroom([]);
      setClassroomId("");
      return
    }
    let _where = { major: majorId }
    if(year !== "") _where = {..._where, year: year}
    loadClassRooms({
      variables: { where: _where },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [majorId, year]);

  useEffect(() => {
    if(departmentData) setDataDepartments(departmentData?.departments);
  }, [departmentData]);

  useEffect(() => {
    if(majorData) setDataMajors(majorData?.majors?.data)
  }, [majorData])
  useEffect(() => {
    if(classroomData) setDataClassroom(classroomData?.classRooms?.data)
  }, [classroomData])
  
  const _assignStudentMore = (event) => {
    history.push(Routers.ASSIGN_STUDENT_ADD + "/limit/50/skip/1", event);
  };
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item active>ຈັດການຫ້ອງຮຽນ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <Title text={"ລາຍການຫ້ອງຮຽນ"} />
        {dataUser?.role == 'ADMIN' ?
        <RoleAdmin
        facultyId={facultyId}
        setFacultyId={setFacultyId}
        dataFaculties={dataFaculties}
        departmentId={departmentId}
        setDepartmentId={setDepartmentId}
        dataDepartments={dataDepartments}
        majorId={majorId}
        setMajorId={setMajorId}
        dataMajors={dataMajors}
        classroomId={classroomId}
        setClassroomId={setClassroomId}
        dataClassroom={dataClassroom}
        year={year}
        setYear={setYear}
        />: dataUser?.role === "FAC_ADMIN" ?
        <RoleFaculty
        facultyId={facultyId}
        setFacultyId={setFacultyId}
        dataFaculties={dataFaculties}
        departmentId={departmentId}
        setDepartmentId={setDepartmentId}
        dataDepartments={dataDepartments}
        majorId={majorId}
        setMajorId={setMajorId}
        dataMajors={dataMajors}
        classroomId={classroomId}
        setClassroomId={setClassroomId}
        dataClassroom={dataClassroom}
        year={year}
        setYear={setYear}
        /> : dataUser?.role === "DEP_ADMIN" ?
          <RoleDepartment
          facultyId={facultyId}
          setFacultyId={setFacultyId}
          dataFaculties={dataFaculties}
          departmentId={departmentId}
          setDepartmentId={setDepartmentId}
          dataDepartments={dataDepartments}
          majorId={majorId}
          setMajorId={setMajorId}
          dataMajors={dataMajors}
          classroomId={classroomId}
          setClassroomId={setClassroomId}
          dataClassroom={dataClassroom}
          year={year}
          setYear={setYear}
          /> : ""
        }
      </CustomContainer>
      <CustomContainer>
        <div>
          <CustomButton
            style={{ float: "right" }}
            confirm
            addIcon
            title="ຈັດການຫ້ອງຮຽນ"
            onClick={() => _assignStudentMore()}
          />
        </div>
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          ທັງໝົດ {currency(totals)} ລາຍການ
        </div>

        <div style={{marginBottom: 20}}>
          {studentLoading ? <Loading /> : <></>}
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader>
                <th style={{ width: 70 }}>ລຳດັບ</th>
                <th>ລະຫັດສຶກສາ</th>
                <th>ຊື່ນັກສຶກສາ</th>
                <th>ຄະນະ</th>
                <th>ພາກວິຊາ</th>
                <th>ສາຂາວິຊາ</th>
                <th>ປີຮຽນ</th>
                <th>ຫ້ອງຮຽນ</th>
              </TableHeader>
            </thead>
            {dataStudents && dataStudents?.map((x, index) => {
                return (
                  <tbody key={index}>
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                    >
                      <TableCell style={{ width: 70 }}>{index + 1 + _limit * (_skip - 1)}</TableCell>
                      <TableCell>{x?.studentNumber}</TableCell>
                      <TableCell>
                        {!x?.firstNameL || x?.firstNameL === "" ? 
                          convertGenderNamEN(x?.gender) + " " + x?.firstNameE + " " + x?.lastNameE
                          :
                          setGenders(x?.gender) + " " + x?.firstNameL + " " + x?.lastNameL
                        }
                        {/* {setGenders(x?.gender) + " " + x?.firstNameL + " " + x?.lastNameL} */}
                      </TableCell>
                      <TableCell>{x?.faculty?.name}</TableCell>
                      <TableCell>{x?.department?.name}</TableCell>
                      <TableCell>{x?.major?.name}</TableCell>
                      <TableCell>{x?.yearLevel}</TableCell>
                      <TableCell>{x?.classRoom?.name}</TableCell>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        </div>
        {Pagination_helper(
          totals,
          Routers.ASSIGN_STUDENT_LIST,
          `facultyId=${facultyId}&&department=${departmentId}&&major=${majorId}&&year=${year}&&classroomId=${classroomId}`
        )}
      </CustomContainer>
    </div>
  );
}

export default AssignStudentList;
