import { useMutation } from "@apollo/react-hooks";
import { Switch } from "@material-ui/core";
import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { UPDATE_MAJOR } from "../../../apollo/major";
import { CustomButton } from "../../../common";
import { alertSuccess, alertWarning, valiDate } from "../../../common/super";
import { fetchPut } from "../../../common/fetchAPI";

const EditMajor = ({
  showSearchView,
  _handleSearchViewClose,
  allData,
  getData,
}) => {
  const [updateMajor] = useMutation(UPDATE_MAJOR);
  const [checkStatus, setCheckStatus] = useState();
  const [assessmentYear, setAssessmentYear] = useState([]);

  useEffect(() => {
    let year = [];
    const NOW = new Date().getFullYear();
    for (var i = NOW - 0; i >= 2014; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    setAssessmentYear(year);

    if (allData?.status === "ENABLE") {
      setCheckStatus(true);
    } else {
      setCheckStatus(false);
    }
  }, [allData]);

  const handleChangeStatus = async () => {
    setCheckStatus(!checkStatus);
  };

  const _saveUpdateMajor = async (data) => {
    fetchPut("major/" + allData._id, data);
    _handleSearchViewClose();
    // updateMajor({
    //   variables: {
    //     data: { ...data },
    //     where: {
    //       id: allData?.id
    //     }
    //   }
    // })
    //   .then(async () => {
    //     _handleSearchViewClose()
    //     alertSuccess('ສຳເລັດ ການແກ້ໄຂສາຂາວິຊາ')
    //   })
    //   .catch(err => {
    //     _handleSearchViewClose()
    //     alertWarning('ການແກ້ໄຂລົ້ມແຫຼວ')
    //   })
  };
  return (
    <div>
      {allData && (
        <Formik
          initialValues={{
            name: allData?.name,
            description: allData?.description,
            enName: allData?.enName,
            enDescription: allData?.enDescription,
            assessmentYear: allData?.assessmentYear,
            note: allData?.note,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "ກະລຸນາປ້ອນຊື່ສາຂາວິຊາ";
            }
            // let arr = allData?.assessmentYear
            // if (arr.indexOf(values.assessmentYear) !== -1) {
            //   errors.assessmentYear = 'ສົກສຶກສານີ້ມີຢູ່ແລ້ວ'
            // }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            let arr = allData?.assessmentYear;
            let newArr = [];
            if (values.assessmentYear === arr) {
              newArr.push(...arr);
            } else {
              // check year is exist
              let checkAssessmentYear = arr.filter(
                (item, index) => item == values.assessmentYear
              );
              if (checkAssessmentYear.length >= 1) {
                newArr.push(...arr);
              } else {
                newArr.push(...arr, values.assessmentYear);
              }
            }
            let data = {
              name: values.name,
              description: values.description,
              enName: values.enName,
              enDescription: values.enDescription,
              assessmentYear: newArr,
              note: values.note,
            };
            _saveUpdateMajor(data);
          }}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Modal
              show={showSearchView}
              onHide={_handleSearchViewClose}
              size="lg"
            >
              <Modal.Title
                style={{ textAlign: "center", paddingTop: 20, fontWeight: 700 }}
              >
                ແກ້ໄຂສາຂາວິຊາ
              </Modal.Title>
              <Modal.Body
                style={{ marginLeft: 50, marginRight: 50, padding: 50 }}
              >
                <div className="model-contents">
                  <label>ຊື່ສາຂາວິຊາ(ລາວ) {valiDate()}:</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    placeholder="ກະລຸນາປ້ອນຊື່ສາຂາວິຊາ(ລາວ)"
                  />
                  <label>ຕົວຫຍໍ້(ລາວ)</label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="description"
                    onChange={handleChange}
                    value={values.description}
                    placeholder="ກະລຸນາປ້ອນຕົວຫຍໍ້(ຖ້າມີ)"
                  />
                  <label>ຊື່ສາຂາວິຊາ(ອັງກິດ):</label>
                  <input
                    type="text"
                    className="form-control"
                    name="enName"
                    onChange={handleChange}
                    value={values.enName}
                    placeholder="ກະລຸນາປ້ອນຊື່ສາຂາວິຊາ(ອັງກິດ)"
                  />
                  <label>ຕົວຫຍໍ້(ອັງກິດ):</label>
                  <textarea
                    type="text"
                    rows="3"
                    className="form-control"
                    name="enDescription"
                    onChange={handleChange}
                    value={values.enDescription}
                    placeholder="ກະລຸນາປ້ອນຕົວຫຍໍ້ຂອງສາຂາວິຊານີ້(ອັງກິດ)"
                  ></textarea>
                  <label>ສົກສຶກສາ {valiDate()}:</label>
                  <Form.Control
                    as="select"
                    name="assessmentYear"
                    value={values.assessmentYear}
                    className="form-select"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    isInvalid={!!errors.assessmentYear}
                  >
                    <option disabled={true} value="">
                      ---ກະລຸນາເລືອກສົກສຶກສາ---
                    </option>
                    {assessmentYear.map((x, assesIndex) => {
                      return (
                        <option key={assesIndex} value={x}>
                          {x}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.assessmentYear}
                  </Form.Control.Feedback>
                  <label>ໝາຍເຫດ</label>
                  <textarea
                    type="text"
                    className="form-control"
                    name="note"
                    onChange={handleChange}
                    value={values.note}
                    placeholder="ກະລຸນາປ້ອນໝາຍເຫດຖ້າມີ"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <CustomButton
                  title="ຍົກເລີກ"
                  onClick={_handleSearchViewClose}
                />
                <CustomButton onClick={handleSubmit} title="ບັນທຶກ" confirm />
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditMajor;
