import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { Breadcrumb, Tab } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Routers from "../../consts/router";
import { CustomContainer, Title } from "../../common";
import { getUserDataFromLCStorage } from "../../helpers/user";

export default function SettingList() {
  const { history } = useReactRouter();
  const [ROLE, setROLE] = useState("");
  useEffect(() => {
    setROLE(getUserDataFromLCStorage()?.data?.role);
  }, []);

  const [isAdmin, setIsAdmin] = useState();
  const _toSettingFaculty = () => {
    history.push(`/setting-faculty`);
  };

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ຕັ້ງຄ່າ
        </Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer
        style={{
          height: "100vh",
        }}
      >
        {/* title */}
        <Title text={"ເລືອກການຕັ້ງຄ່າ"} />
        {/* body list */}
        <div className="row">
          <div
            className="col-md-6 col-lg-4"
            onClick={() => _toSettingFaculty()}
          >
            <div className="menu-setting">
              <div
                className="div-icon"
                style={{ paddingRight: "20px", color: "#057CAE" }}
              >
                <i className="fa fa-user-plus fa-2x icon-setting"></i>
              </div>
              <div>
                <h4>
                  <strong>ຕັ້ງຄ່າການລົງທະບຽນ</strong>
                </h4>
                <p style={{ color: "#ccc", fontSize: 11 }}>
                  ໃຊ້ໃນກໍລະນີ ຂໍ້ມູນມີການເພີ່ມ ຫຼື ປ່ຽນແປງ
                </p>
              </div>
            </div>
          </div>
          {ROLE == "DEP_ADMIN" ? (
            ""
          ) : (
            <div
              className="col-md-6 col-lg-4"
              onClick={() => {
                history.push(`/setting-master-data/${"tribe/limit/50/skip/1"}`);
              }}
            >
              <div className="menu-setting">
                <div className="div-icon" style={{ color: "#057CAE" }}>
                  <i className="fa fa-server fa-2x"></i>
                </div>
                <div>
                  <h4>
                    <strong>ຈັດການຂໍ້ມູນຄົງທີ່</strong>
                  </h4>
                  <p style={{ color: "#ccc", fontSize: 11 }}>
                    ໃຊ້ໃນກໍລະນີ ຂໍ້ມູນມີການເພີ່ມ ຫຼື ປ່ຽນແປງ
                  </p>
                </div>
              </div>
            </div>
          )}
          <div
            className="col-md-6 col-lg-4"
            onClick={() => {
              history.push(Routers.SETTINGS_UPDATE_LOG + Routers.PAGE_GINATION);
            }}
          >
            <div className="menu-setting">
              <div className="div-icon" style={{ color: "#057CAE" }}>
                <i className="fa fa-history fa-2x"></i>
              </div>
              <div>
                <h4>
                  <strong>ປະຫວັດການປ່ຽນແປງຂໍ້ມູນ</strong>
                </h4>
                <p style={{ color: "#ccc", fontSize: 11 }}>
                  ໃຊ້ໃນກໍລະນີ ຢາກຮູ້ວ່າຂໍ້ມູນຖືກປ່ຽນແປງແນວໃດ
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-4"
            onClick={() => {
              history.push(Routers.LOGIN_HISTORY + Routers.PAGE_GINATION);
            }}
          >
            <div className="menu-setting">
              <div className="div-icon" style={{ color: "#057CAE" }}>
                <i className="fa fa-clipboard-list fa-2x"></i>
              </div>
              <div>
                <h4>
                  <strong>ປະຫວັດການເຂົ້າໃຊ້ລະບົບ</strong>
                </h4>
                <p style={{ color: "#ccc", fontSize: 11 }}>
                  ໃຊ້ໃນກໍລະນີ ຢາກຮູ້ວ່າຜູ້ໃຊ້ເຂົ້າ-ອອກລະບົບເວລາໃດ
                </p>
              </div>
            </div>
          </div>

          {ROLE == "DEP_ADMIN" ? (
            ""
          ) : (
            <div
              className="col-md-6 col-lg-4"
              onClick={() => {
                history.push(
                  Routers.PROMOTE_TO_NEXT_GRADE + Routers.PAGE_GINATION
                );
              }}
            >
              <div className="menu-setting">
                <div
                  className="div-icon"
                  style={{ color: "#057CAE", width: 103 }}
                >
                  <i className="fa fa-angle-double-up fa-2x"></i>
                </div>
                <div>
                  <h4>
                    <strong>ເລື່ອນຊັ້ນນັກສຶກສາ</strong>
                  </h4>
                  <p style={{ color: "#ccc", fontSize: 11 }}>
                    ໃຊ້ໃນກໍລະນີ ຢາກເລື່ອນຊັ້ນນັກສຶກສາເປັນປີໃໝ່
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* //////////////////===================New Code ========================///////////////////////////// */}
          <div
            className="col-md-6 col-lg-4"
            onClick={() => {
              history.push(Routers.PRICE_MANAGEMENT);
            }}
          >
            <div className="menu-setting">
              <div className="div-icon" style={{ color: "#057CAE" }}>
                <i className="fa fa-clipboard-list fa-2x"></i>
              </div>
              <div>
                <h4>
                  <strong>ຈັດການລາຍການຈ່າຍຄ່າຮຽນ</strong>
                </h4>
                <p style={{ color: "#ccc", fontSize: 11 }}>
                  ຈັດການລາຍການຈ່າຍຄ່າຮຽນຂອງນັກສຶກສາ
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-4"
            onClick={() => {
              history.push(Routers.TIME_STUDY);
            }}
          >
            <div className="menu-setting">
              <div className="div-icon" style={{ color: "#057CAE" }}>
                <i className="fa fa-clipboard-list fa-2x"></i>
              </div>
              <div>
                <h4>
                  <strong>ຈັດການເວລາຮຽນ</strong>
                </h4>
                <p style={{ color: "#ccc", fontSize: 11 }}>
                  ຈັດການເວລາຮຽນຂອງແຕ່ລະຄະນະ
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-4"
            onClick={() => {
              history.push(Routers.ASSEMENT_YEAR_SETTING);
            }}
          >
            <div className="menu-setting">
              <div className="div-icon" style={{ color: "#057CAE" }}>
                <i className="fa fa-clipboard-list fa-2x"></i>
              </div>
              <div>
                <h4>
                  <strong>ຈັດການການເປີດສົກ</strong>
                </h4>
              </div>
            </div>
          </div>

          {/* //////////////////===================New Code ========================///////////////////////////// */}

          {/* {ROLE == "DEP_ADMIN" ? "" :  */}
          {/* <div
              className="col-md-6 col-lg-4"
              onClick={() => {
                history.push(Routers.CLASS_LIST + Routers.PAGE_GINATION);
              }}
            >
              <div className="menu-setting">
                <div className="div-icon" style={{ color: "#057CAE", width: 103}}>
                  <i className="fa fa-border-all fa-2x"></i>
                </div>
                <div>
                  <h4>
                    <strong>ໝວດໝູ່ຫ້ອງຮຽນ</strong>
                  </h4>
                  <p style={{ color: "#ccc", fontSize: 11 }}>
                    ໃຊ້ໃນກໍລະນີ ຂໍ້ມູນມີການເພີ່ມ ຫຼື ປ່ຽນແປງ
                  </p>
                </div>
              </div>
            </div> */}
          {/* } */}
        </div>
      </CustomContainer>
    </div>
  );
}
