import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from 'react-redux'
/**
 *
 * @Library
 *
 */
import { Formik } from "formik";
import * as _ from "lodash";

/**
 *
 * @Component
 *
 */
import { Modal, Button, Form, Breadcrumb, OverlayTrigger, Tooltip, Row, Col} from "react-bootstrap";
import { CustomContainer, TableHeader } from "../../../common";
import Empty from "../../../common/Empty";
import GradeNavAddforStudent from "../common/GradeNavAddforStudent";
import Loading from "../../../common/Loading";
/**
 *
 * @Constant
 *
 */
/**
 *
 * @Apollo
 *
 */
import { UPDATE_GRADE, GRADE_COURSES, SEND_GRADE_COURSE } from "../../../apollo/grade/index";
import { CALL_COURSE_DATA, COURSE_FACULTY_DEPARTMENT } from "../../../apollo/course/index";
/**
 *
 * @Function
 *
 */
import "../util/index.css";
import { setGenders, convertLetterGrade, gradeTypeName, alertSuccess, alertWarning, messageSuccess } from "../../../common/super";
import { FACULTIES } from "../../../apollo/faculty";
import { DEPARTMENTS } from "../../../apollo/deparment";

function AddGradeForStudent() {
  const { history, location } = useReactRouter();
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const data = location.state;


  /**
   *
   * @State
   *
   */
  const [isLoading, setIsLoading] = useState(false)

  // state to set grade
  const [gradeType, setGradeType] = useState([]);
  const [confirmGrade, setConfirmGrade] = useState(false);
  const [confirmAllGrade, setConfirmAllGrade] = useState(false);
  const [confirmSentGrade, setConfirmSentGrade] = useState(false);

  const [gradeData, setGradeData] = useState([]);

  const [checkStatus, setCheckStatus] = useState([]);
  const [whenClick, setWhenClick] = useState([]);

  const [dataUser, setDataUser] = useState(null);

  const [factId, setFactId] = useState("");
  const [factIdNull, setFacIdNull] = useState();
  const [departmentIdNull, setDepartmentIdNull] = useState();
  const [selectSemesterNull, setSelectSemesterNull] = useState();
  const [selectedYearNull, setSelectedYearNull] = useState();
  const [departmentId, setDepartmentId] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectSemester, setSelectSemester] = useState("");
  const [dataFaculties, setDataFaculties] = useState([]);
  const [dataDepartments, setDataDepartments] = useState([]);

  const [facultyArray, setFacultyArray] = useState([]);
  const [departmentArray, setDepartmentArray] = useState([]);

  /**
   *
   * @Apollo
   *
   */
   const [ loadFaculties, { data: facultyData }] = useLazyQuery(FACULTIES);

  const [loadDataDepartment, { data: departmentsData }] = useLazyQuery(DEPARTMENTS, { fetchPolicy: "network-only" });

  const [loadCourseFacultyDepartment, { data: courseAndFacultyAndDepartmentData }] = useLazyQuery(COURSE_FACULTY_DEPARTMENT);

  const [updateGrade] = useMutation(UPDATE_GRADE);
  const [loadGradeCourseData, { loading, data: gradeCourseData }] =
    useLazyQuery(GRADE_COURSES);

  const [sendGradeCourse] = useMutation(SEND_GRADE_COURSE);
  // query course
  const [loadCourse, { data: courseData }] = useLazyQuery(CALL_COURSE_DATA);
  /**
   *
   * @UseEffect
   *
   */
   // use effect
   useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
    loadCourse();
    loadFaculties({
      variables: {
        where: { isDeleted: false}
      }
    })
    loadCourseFacultyDepartment({ variables: { where: { id: data?.courseId } } });
  }, []);

   useEffect(() => {

    if (courseAndFacultyAndDepartmentData) {
      var arrayFaculty = []
      var arrayDepartment = []
      for (var i = 0; i < courseAndFacultyAndDepartmentData?.courseAndFacultyAndDepartment?.dataFaculty?.length; i++) {
        arrayFaculty.push({ id: courseAndFacultyAndDepartmentData?.courseAndFacultyAndDepartment?.dataFaculty[i]?.faculty?.id });
      }
      for (var i = 0; i < courseAndFacultyAndDepartmentData?.courseAndFacultyAndDepartment?.dataDepartment?.length; i++) {
        arrayDepartment.push({ id: courseAndFacultyAndDepartmentData?.courseAndFacultyAndDepartment?.dataDepartment[i]?.department?.id });
      }
      setFacultyArray(arrayFaculty)
      setDepartmentArray(arrayDepartment)
    }
  }, [courseAndFacultyAndDepartmentData]);

  useEffect(() => {
    loadGradeCourseData({
      variables: {
        where: {
          assessmentYear: accessmentRedux,
          course: data?.courseId,
          numberTest: 1,
          gredeStatus_in: ['APROVED'],
          sendingGradeStatus_in: ["TEACHER_SCORING", "TEACHER_REJECTED"],
          // sendingGradeStatus:"TEACHER_SCORING"
        },
      },
    });
    if (gradeCourseData?.grades?.data) {
      setGradeData(gradeCourseData?.grades?.data);
    }
  }, [gradeCourseData]);




  useEffect(() => {
    let grade = [];
    let sendingGradeStatus = [];
    gradeData.map((x) => {
      grade.push(x.gradeType);
      sendingGradeStatus.push(x.sendingGradeStatus);
    });
    setGradeType(grade);
  }, [gradeData]);

  // pagegination
  useEffect(() => {
    if (facultyData)  setDataFaculties(facultyData?.faculties)
  }, [facultyData]);
  useEffect(() => {
    if (departmentsData)  setDataDepartments(departmentsData?.departments) 
  }, [departmentsData]);
  /**
   *
   * @Function
   *
   */

  const _grade = (index, id) => {
    setConfirmGrade(!confirmGrade);
  };


  // get depName
  const _renderCourseName = (e) => {
    let result = courseData?.courses?.data.filter((item) => item.id === e);
    if (result?.length > 0) {
      return result[0].title;
    }
    else return "";
  };

  const _saveOneGrade = async (grade) => {
    updateGrade({
      variables: {
        data: {
          gredeStatus: "APROVED",
          gradeType:  grade.gradeType,
        },
        where: { id: grade.id },
      },
    }).then(() => {
      messageSuccess("ຢືນຢັນແລ້ວ");
    });
  };

  async function _updateAllGrade() {
    try {
      setConfirmAllGrade(false);
      setIsLoading(true);
      gradeData.map(async (grade, index) => {
        await _grade(index, grade.id);
        await _saveOneGrade(grade);
      });
      setCheckStatus([]);
      setWhenClick([]);
      await loadGradeCourseData({
        variables: {
          where: {
            assessmentYear: accessmentRedux,
            course: data?.courseId,
            numberTest: 1,
            gredeStatus_in: ["APROVED"],
            // sendingGradeStatus_in: ["TEACHER_SCORING", "TEACHER_REJECTED"],
            sendingGradeStatus: "TEACHER_SCORING"
          },
        },
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  const _sendGrade = async () => {

    if(factId === ""){
      setFacIdNull(true);
      return
    }else setFacIdNull(false);
    if(departmentId === ""){
      setDepartmentIdNull(true);
      return
    }else setDepartmentIdNull(false);
    if(selectedYear === ""){
      setSelectedYearNull(true);
      return
    }else setSelectedYearNull(false);
    if(selectSemester === ""){
      setSelectSemesterNull(true);
      return
    }else setSelectSemesterNull(false);

    await sendGradeCourse({
      variables: {
        where: {
          course: data?.courseId,
          faculty: factId,
          department: departmentId,
          assessmentYear: accessmentRedux,
          semester: selectSemester,
          yearLevel: parseInt(selectedYear)
        },
        data: { sendingGradeStatus: "TEACHER_SUBMITED" },
      },
    })
      .then(() => {
        alertSuccess("ການສົ່ງຄະແນນຂອງທ່ານສຳເລັດແລ້ວ");
      })
      .catch((err) => {
        alertWarning("ການສົ່ງຄະແນນຂອງທ່ານລົ້ມແຫຼວ");
      });
  };

  const _totalGrade = (grades) => {
    let result = 0;
    grades.map((_type, _index) => {
      result += parseInt(_type?.score ?? 0);
    });
    return result;
  };

   // select faculty ad get value
   const _selectFaculty = async (e) => {
    let _facultyId = e?.target?.value
    setFactId(e.target.value);
    if (_facultyId === "") {
      setDataDepartments([])
    }
    else {
      await loadDataDepartment({ variables: { where: { faculty: _facultyId } } })
    }
  };
  const _showTheFacultyAccordingToTheTeacher = (id) => {
    const _showThe = facultyArray.filter(fac => fac?.id == id)
    if (_showThe.length > 0) return false
    else return true
  }
  // select department
  const _onSelectedDepartment = async (e) => {
    let _departmentId = e?.target?.value
    setDepartmentId(_departmentId);
  };
  const _showTheDepartmentAccordingToTheTeacher = (id) => {
    const _showTheDepartment = departmentArray.filter(dep => dep?.id == id)
    if (_showTheDepartment.length > 0) return false
    else return true
  }
  // select yaer
  const _onSelectedYear = (e) => {
    setSelectedYear(e.target.value);
  };
  // select unit
  const _onSelectedSemester = (e) => {
    setSelectSemester(e.target.value);
  };

  if (isLoading) return <Loading />;
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => history.push("/grades-index/1", location?.state)}
        >
          <strong>ຄະແນນ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() =>
            history.push("/grades-index/1", location?.state?.title)
          }
        >
          <strong>ເລືອກໝວດໝູ່</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() =>
            history.push("/grades-course-list/limit/40/skip/1", location?.state?.title)
          }
        >
          <strong>ວິຊາທັງໝົດ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.goBack()}>
          ລາຍລະອຽດວິຊາ
        </Breadcrumb.Item>
        <Breadcrumb.Item>ໃຫ້ຄະແນນນັກສຶກສາໃໝ່</Breadcrumb.Item>
      </Breadcrumb>
      <GradeNavAddforStudent />
      {gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "TEACHER_SCORING" ||
        gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "TEACHER_SUBMITED" ||
        gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "TEACHER_REJECTED" ||
        gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "DEPARTMENT_CHECKING" ||
        gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "DEPARTMENT_SUBMITED" ||
        gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "DEPARTMENT_REJECTED" ||
        gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "FACULTY_CHECKING" ||
        gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "FACULTY_SUBMITED" ||
        gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "FACULTY_REJECTED" ||
        gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "ADMIN_CHECKING" ||
        gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "ADMIN_APPROVED" ? (
        <div>
          {loading ? (
            <Loading />
          ) : _renderCourseName(data?.courseId) === "" ? (
            <CustomContainer>
              <Empty style={{ marginTop: -16 }} />
            </CustomContainer>
          ) : (
            <CustomContainer style={{ marginTop: 0 }} >
              <div
                className="col-sm-4"
                style={{ display: "block", marginTop: 5 }}
              >
                <span
                  style={{ display: "inline-block", fontSize: 24, color: "#057CAE", fontWeight: "bold"}}
                >
                  {_renderCourseName(data?.courseId)}
                </span>
              </div>
              <Row style={{ marginBottom: 30 }}>
                <Col sm={3}>
                  <label>ຄະນະວິຊາ</label>
                  <Form.Control
                    as="select"
                    style={{ border: "none", backgroundColor: "#f1f1f1f1", cursor: "pointer" }}
                    value={factId}
                    onChange={(e) => { _selectFaculty(e) }}
                  >
                    <option value="">ຄະນະທັງໝົດ</option>
                    {dataFaculties?.map((item, index) => {
                      return (
                        <option
                          disabled={_showTheFacultyAccordingToTheTeacher(item?.id)}
                          key={index}
                          value={item.id}>
                          {item.name}
                        </option>);
                    })}
                  </Form.Control>
                  <p>{factIdNull && <span style={{color:"red"}}>ກະລຸນາເລືອກຄະນະ!</span>}</p>
                </Col>
                <Col sm={3}>
                  <label>ສາຂາວິຊາ</label>
                  <Form.Control
                    as="select"
                    style={{
                      border: "none",
                      backgroundColor: "#f1f1f1f1",
                      cursor: "pointer",
                    }}
                    value={departmentId}
                    onChange={(e) => _onSelectedDepartment(e)}
                  >
                    <option value="">ທັງໝົດ</option>
                    {dataDepartments?.map((item, index) => {
                      return (
                        <option disabled={_showTheDepartmentAccordingToTheTeacher(item?.id)} key={index} value={item.id}>{item.name}</option>
                      );
                    })}
                  </Form.Control>
                  <p>{departmentIdNull && <span style={{color:"red"}}>ກະລຸນາເລືອກສາຂາວິຊາ!</span>}</p>
                </Col>
                <Col sm={3}>
                  <label>ປີຮຽນ</label>
                  <Form.Control
                    as="select"
                    style={{
                      border: "none",
                      backgroundColor: "#f1f1f1f1",
                      cursor: "pointer",
                    }}
                    value={selectedYear}
                    onChange={(e) => _onSelectedYear(e)}
                  >
                    <option value="">ທັງໝົດ</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </Form.Control>
                  <p>{selectedYearNull && <span style={{color:"red"}}>ກະລຸນາເລືອກປີຮຽນ!</span>}</p>
                </Col>

                <Col sm={3}>
                  <label>ພາກຮຽນ</label>
                  <Form.Control
                    as="select"
                    style={{
                      border: "none",
                      backgroundColor: "#f1f1f1f1",
                      cursor: "pointer",
                    }}
                    value={selectSemester}
                    onChange={(e) => _onSelectedSemester(e)}
                  >
                    <option value="">ທັງໝົດ</option>
                    <option value="1">ພາກຮຽນ 1</option>
                    <option value="2">ພາກຮຽນ 2</option>
                  </Form.Control>
                  <p>{selectSemesterNull && <span style={{color:"red"}}>ກະລຸນາເລືອກພາກຮຽນ!</span>}</p>
                </Col>
              </Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                ທັງໝົດ {gradeData?.length} ຄົນ
                {dataUser?.role === "ADMIN" ? (
                  <div>
                    <Button
                      onClick={() => {setConfirmAllGrade(!confirmAllGrade); }}
                      className="btn btn-info"
                      style={{ width: 200 }}
                    >
                      ຢືນຢັນທັງໝົດ
                    </Button>
                    <Button
                      onClick={() => {setConfirmSentGrade(!confirmSentGrade)}}
                      className="btn btn-success"
                      style={{ width: 200 }}
                    >
                      ສົ່ງຄະແນນ
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* Table list */}
              {gradeData && (
                <Formik
                  onSubmit={() => { }}
                >
                  {({ resetForm }) => (
                    <div>
                      <table
                        className="table table-bordered "
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <TableHeader>
                            <th style={{ width: "5%" }}>ລຳດັບ</th>
                            <th style={{ width: "18%" }}>ຊື່ ແລະ ນາມສະກຸນ</th>
                            {gradeType[0]?.map((item, index) => {
                              return <th key={index}>{item?.name}</th>;
                            })}
                            <th width="8%">ຄະແນນລວມ</th>
                            <th style={{ width: "5%" }}>ເກຣດ</th>
                            <th style={{ width: "19%" }}>ຈັດການ</th>
                          </TableHeader>
                        </thead>
                        <tbody>
                          {loading ? (
                            <Loading />
                          ) : (
                            gradeData?.map((grade, index) => {
                              return (
                                <tr
                                  key={index}
                                  style={{ backgroundColor: "#f0f0ff" }}
                                >
                                  <td className="center">
                                    {index + 1}
                                  </td>
                                  <td>
                                    {setGenders(grade?.student?.gender) ?? "-"}{" "}
                                    &nbsp;
                                    {grade?.student?.firstNameL ??
                                      "-"} &nbsp;{" "}
                                    {grade?.student?.lastNameL ?? "-"}
                                  </td>
                                  {grade?.gradeType.map((_type, _index) => {
                                    return (
                                      <td key={_index} >
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="tooltip-disabled">
                                              ຄະແນນສຸງສຸດ{_type?.maxScore}
                                            </Tooltip>
                                          }
                                        >
                                          <Form.Control
                                            max={_type?.maxScore}
                                            min={0}
                                            className="center"
                                            type="number"
                                            name={gradeTypeName(_type?.name)}
                                            disabled={
                                              dataUser?.role !== "ADMIN"
                                                ? true
                                                : whenClick[index] === index - 1
                                                  ? true
                                                  : checkStatus[index] === index
                                                    ? false
                                                    : true
                                            }
                                            value={_type?.score ?? ""}
                                            placeholder={_type?.maxScore}
                                            onChange={async (e) => {
                                              let _total = 0;
                                              for (
                                                var i = 0;
                                                i <
                                                gradeData[index].gradeType
                                                  .length;
                                                i++
                                              ) {
                                                if (
                                                  gradeData[index].gradeType[i]
                                                    .name !==
                                                  gradeData[index].gradeType[
                                                    _index
                                                  ].name
                                                ) {
                                                  _total +=
                                                    gradeData[index].gradeType[
                                                      i
                                                    ].score;
                                                }
                                              }
                                              _total =
                                                _total +
                                                parseInt(e.target.value);
                                              gradeData[index].gradeType[
                                                _index
                                              ].score = parseInt(
                                                e.target.value
                                              );
                                              let conn = [...checkStatus];
                                              conn[index] = index;
                                              setCheckStatus(conn);
                                              let setwhen = [...whenClick];
                                              setwhen[index] = index;
                                              setWhenClick(setwhen);
                                            }}
                                          />
                                        </OverlayTrigger>
                                      </td>
                                    );
                                  })}
                                  <td>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          ຄະແນນສູງສຸດ 100
                                        </Tooltip>
                                      }
                                    >
                                      <Form.Control
                                        disabled={true}
                                        className="center"
                                        type="text"
                                        placeholder="100"
                                        value={_totalGrade(
                                          grade?.gradeType ?? []
                                        )}
                                      />
                                    </OverlayTrigger>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          ສູງສຸດເກຣດ A
                                        </Tooltip>
                                      }
                                    >
                                      <input
                                        type="text"
                                        disabled={true}
                                        className="w-100 grade-type"
                                        style={{ textAlign: "center" }}
                                        name={gradeData?.letterGrade}
                                        value={convertLetterGrade(
                                          _totalGrade(grade?.gradeType ?? [])
                                        )}
                                      />
                                    </OverlayTrigger>
                                  </td>
                                  {dataUser?.role === "ADMIN" ? (
                                    <td style={{ textAlign: "center" }}>
                                      {whenClick[index] === index ? (
                                        <button
                                          className="btn btn-outline-success"
                                          onClick={async (e) => {
                                            let conn = [...checkStatus];
                                            conn[index] = index - 1;
                                            setCheckStatus(conn);
                                            let setwhen = [...whenClick];
                                            setwhen[index] = index - 1;
                                            setWhenClick(setwhen);
                                            await _grade(index, grade.id);
                                            await _saveOneGrade(grade);
                                            resetForm({});
                                          }}
                                          style={{ fontWeight: "bold" }}
                                        >
                                          <i className="fa fa-check-circle"></i>
                                          &nbsp;&nbsp; ຢືນຢັນ
                                        </button>
                                      ) : grade?.numbericGrade !== null &&
                                        checkStatus[index] === index ? (
                                        <button
                                          className="btn btn-outline-success"
                                          onClick={async (e) => {
                                            let conn = [...checkStatus];
                                            conn[index] = index - 1;
                                            setCheckStatus(conn);
                                            let setwhen = [...whenClick];
                                            setwhen[index] = index - 1;
                                            setWhenClick(setwhen);
                                            await _grade(index, grade.id);
                                            await _saveOneGrade();
                                            resetForm({});
                                          }}
                                          style={{ fontWeight: "bold" }}
                                        >
                                          <i className="fa fa-check-circle"></i>
                                          &nbsp;&nbsp; ຢືນຢັນ
                                        </button>
                                      ) : (
                                        ""
                                      )}

                                      {whenClick[index] === index ||
                                        grade?.sendingGradeStatus ===
                                        "TEACHER_SUBMITED" ||
                                        grade?.sendingGradeStatus ===
                                        "DEPARTMENT_CHECKING" ||
                                        grade?.sendingGradeStatus ===
                                        "DEPARTMENT_SUBMITED" ||
                                        grade?.sendingGradeStatus ===
                                        "DEPARTMENT_REJECTED" ||
                                        grade?.sendingGradeStatus ===
                                        "FACULTY_CHECKING" ||
                                        grade?.sendingGradeStatus ===
                                        "FACULTY_SUBMITED" ||
                                        grade?.sendingGradeStatus ===
                                        "FACULTY_REJECTED" ||
                                        grade?.sendingGradeStatus ===
                                        "ADMIN_CHECKING" ||
                                        grade?.sendingGradeStatus ===
                                        "ADMIN_APPROVED" ? (
                                        ""
                                      ) : (
                                        <button
                                          className="btn btn-outline-info"
                                          onClick={() => {
                                            let conn = [...checkStatus];
                                            conn[index] = index;
                                            setCheckStatus(conn);
                                            let setwhen = [...whenClick];
                                            setwhen[index] = index;
                                            setWhenClick(setwhen);
                                          }}
                                        >
                                          ແກ້ໄຂ
                                        </button>
                                      )}
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        color: "#FF8819",
                                        textAlign: "center",
                                        marginTop: "4px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <i className="fa fa-exclamation-triangle"></i>
                                      &nbsp;&nbsp;ບໍ່ມີສິດໃຫ້ ຫຼື ແກ້ໄຂຄະແນນ
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          )}

                          {/* })} */}
                        </tbody>
                      </table>
                      <div
                        className="col-md-12"
                        style={{ textAlign: "center" }}
                      >
                        {dataUser?.role === "ADMIN" ? (
                          <Button
                            type="submit"
                            onClick={() => {
                              setConfirmAllGrade(!confirmAllGrade);
                            }}
                            className="btn btn-info"
                            style={{ width: "200px" }}
                          >
                            ຢືນຢັນທັງໝົດ
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </Formik>
              )}
            </CustomContainer>
          )}
        </div>
      ) : (
        <CustomContainer>
          <Empty style={{ marginTop: -16 }} />
        </CustomContainer>
      )}

      <Modal
        show={confirmAllGrade}
        onHide={() => setConfirmAllGrade(!confirmAllGrade)}
      >
        <Modal.Header closeButton>
          <p>ຢືນຢັນການບັນທືກຄະແນນທັງໝົດ</p>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>ທ່ານແນ່ໃຈແລ້ວບໍ່ ?</p>
          <div className="col-md-12" style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="btn btn-outline-secondary"
              onClick={() => setConfirmAllGrade(!confirmAllGrade)}
            >
              ຍົກເລີກ
            </button>
            &nbsp;&nbsp; &nbsp;
            <button
              type="submit"
              onClick={async () => {
                await _updateAllGrade();
              }}
              className="btn btn-info"
            >
              ຢືນຢັນ
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={confirmSentGrade}
        onHide={() => setConfirmSentGrade(!confirmSentGrade)}
      >
        <Modal.Header closeButton>
          <p>ຢືນຢັນການສົ່ງຄະແນນ</p>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>ທ່ານແນ່ໃຈແລ້ວບໍ່ວ່າຕ້ອງການສົ່ງຄະແນນວິຊານີ້ ?</p>
          <div className="col-md-12" style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="btn btn-outline-secondary"
              onClick={() => setConfirmSentGrade(!confirmSentGrade)}
            >
              ຍົກເລີກ
            </button>
            &nbsp;&nbsp; &nbsp;
            <button
              type="submit"
              onClick={async () => {
                await _sendGrade();
                setConfirmSentGrade(!confirmSentGrade);
              }}
              className="btn btn-info"
            >
              ຢືນຢັນ
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddGradeForStudent;
