import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { composeComponentAs } from 'office-ui-fabric-react';
import { NEW_API_URL, API_KEY } from '../../../common/contrant';
import axios from 'axios';
import "./index.css"

const PDFGenerator = ({ student }) => {


    const [gradeData, setGradeData] = useState({})


    const fetchStudent = async () => {
        console.log("here is student number ")
        console.log(student)
        await axios.get(NEW_API_URL + "score/student/" + student, {
            headers: {
                api_key: API_KEY
            }
        }).then((res) => {
            setGradeData(res.data)

            console.log("here is response data")
            console.log(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        console.log("gradeData")
        console.log(gradeData)
    }, [student, gradeData])



    const data = [
        {
            code: "123",
            name: "English",
            credit: "1",
            grade: "A"
        },
        {
            code: "122",
            name: "Franch",
            credit: "1",
            grade: "A"
        },
        {
            code: "121",
            name: "Chemistry",
            credit: "1",
            grade: "A"
        },
        {
            code: "133",
            name: "Physics",
            credit: "1",
            grade: "A"
        }
    ]



    // console.log("dataTransform");

    // console.log(dataTransform);











    const showData = () => {
        fetchStudent()
        setShow(true)
    }

    const handleGeneratePDF = () => {


        const input = document.getElementById('pdf-content');

        // Increase the DPI for higher quality (e.g., 300)
        const dpi = 300;
        const scale = dpi / 96; // 96 DPI is the standard screen resolution

        html2canvas(input, { scale: scale }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png', 1.0); // Set quality to 1.0 (highest)
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
            });

            // Calculate the width and height of the PDF to match the content
            const imgWidth = 210; // A4 width in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
            pdf.save('grade.pdf');
        });


        setShow(false)

    };



    function formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-GB', options);
        return formattedDate;
    }





    const [show, setShow] = useState(false)


    useEffect(() => {
        fetchStudent()
    }, [show])

    return (
        <div >
            {show && <div className='my-modal-regis'>
                <div className='modal-content-regis'>
                    <div className='px-5' id='pdf-content'>
                        <div className='d-flex justify-content-center'>
                            <div className='w-75'>
                                <img className='w-25 p-5' src="/lao.png" />
                            </div>
                        </div>
                        <div className='row'>
                            <h4>
                                ສາທາລະນະລັດ  ປະຊາທິປະໄຕ ປະຊາຊົນລາວ
                            </h4>
                        </div>
                        <div className='row text-center'>
                            <h4>
                                ສັນຕິພາບ  ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ
                            </h4>
                        </div>
                        <siv className="row">
                            <div className='col text-start'>
                                <h4>ມະຫາວິທະຍາໄລແຫ່ງຊາດ</h4>
                                <h4 className='pt-3'>ຄະນະ: {gradeData.studentInfo?.faculty?.name}</h4>
                            </div>
                            <div className='col text-end'>
                                <h4>ເລກທີ .......... </h4>
                            </div>
                        </siv>


                        <div className='row'>
                            <div className='col text-cemter'>
                                <h2>ໃບຄະເເນນ  </h2>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col text-start'>
                                <h4>ຫລັກສູດປະລິນຍາຕິ ສາຂາ: {gradeData.studentInfo?.major?.name}</h4>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col text-start'>
                                <h4>ຊື່ ແລະ ນາມສະກຸນ : {gradeData.studentInfo?.firstNameL}</h4>
                            </div>
                            <div className='col text-cemter'>
                                <h4>ວັນເດືອນປີເກີດ {formatDate(gradeData.studentInfo?.birthday)}</h4>
                            </div>
                            <div className='col text-cemter'>
                                <h4>ລະຫັດນັກສຶກສາ {gradeData.studentInfo?.studentNumber}</h4>
                            </div>
                        </div>


                        <div className='row'>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='text-dark py-2' style={{ border: '1px solid black', backgroundColor: '#fff', width: '15%' }} ><h4>ລະຫັດວິຊາ</h4></th>
                                        <th className='text-dark py-2' style={{ border: '1px solid black', backgroundColor: '#fff' }} colSpan="2"><h4>ຊື່ວິຊາ</h4></th>
                                        <th className='text-dark py-2' style={{ border: '1px solid black', backgroundColor: '#fff' }}><h4>ຫນ່ວຍກິດ</h4></th>
                                        <th className='text-dark py-2' style={{ border: '1px solid black', backgroundColor: '#fff' }}><h4>ລະດັບ</h4></th>
                                        <th className='text-dark py-2' style={{ border: '1px solid black', backgroundColor: '#fff', width: '15%' }}><h4>ລະຫັດວິຊາ</h4></th>
                                        <th className='text-dark py-2' style={{ border: '1px solid black', backgroundColor: '#fff' }} colSpan="2"><h4>ຊື່ວິຊາ</h4></th>
                                        <th className='text-dark py-2' style={{ border: '1px solid black', backgroundColor: '#fff' }}><h4>ຫນ່ວຍກິດ</h4></th>
                                        <th className='text-dark py-2' style={{ border: '1px solid black', backgroundColor: '#fff' }}><h4>ລະດັບ</h4></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        gradeData.grade && gradeData.grade.map((g) => (
                                            <tr>
                                                <td className='py-2' style={{ border: '1px solid black', borderRight: 'none' }}><h4 style={{ whiteSpace: 'normal' }}>{g?.code}</h4></td>
                                                <td className='py-2' style={{ border: '1px solid black' }} colSpan="2"><h4 style={{ whiteSpace: 'normal' }}>{g?.name}</h4></td>
                                                <td className='py-2' style={{ border: '1px solid black' }}><h4 style={{ whiteSpace: 'normal' }}>{g?.credit}</h4></td>
                                                <td className='py-2' style={{ border: '1px solid black' }}><h4 style={{ whiteSpace: 'normal' }}>{g?.grade}</h4></td>
                                                <td className='py-2' style={{ border: '1px solid black', borderRight: 'none' }}><h4 style={{ whiteSpace: 'normal' }}>{g?.code2}</h4></td>
                                                <td className='py-2' style={{ border: '1px solid black' }} colSpan="2"><h4 style={{ whiteSpace: 'normal' }}>{g?.name2}</h4></td>
                                                <td className='py-2' style={{ border: '1px solid black' }}><h4 style={{ whiteSpace: 'normal' }}>{g?.credit2}</h4></td>
                                                <td className='py-2' style={{ border: '1px solid black' }}><h4 style={{ whiteSpace: 'normal' }}>{g?.grade2}</h4></td>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <td className='py-2' style={{ border: '1px solid black' }} rowSpan="2"><h4>ຫມາຍເຫດ</h4></td>
                                        <td className='py-2' style={{ border: '1px solid black' }} colSpan="5"><h4>A=4 (ດີເລີດ) B+=3.5 (ດີຫລາຍ) B=3 (ດີ)  C=2.5 (ໃຊ່ໄດ້)</h4></td>
                                        <td className='py-2' style={{ border: '1px solid black' }} colSpan="3"><h4>ລວມຈຳນວນຫນ່ວຍກິດທັງຫມົດ</h4></td>
                                        <td className='py-2' style={{ border: '1px solid black' }}>{gradeData?.totalCredit}</td>
                                    </tr>
                                    <tr>
                                        <td className='py-2' style={{ border: '1px solid black' }} colSpan="5"><h4>C=2 (ພໍໃຊ້) B+=1.5 (ອ່ອນ) D=3 (ອ່ອນຫລາຍ)  F=0 (ຕົກ)</h4></td>
                                        <td className='py-2' style={{ border: '1px solid black' }} colSpan="3"><h4>ຄະເເນນສະເລ່ຍສະສົມ</h4></td>
                                        <td className='py-2' style={{ border: '1px solid black' }}>{gradeData?.gpa}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                        <div className='row mt-5'>
                            <div className='col text-start text-center'>
                                <h4>ຄະນະບໍດີ</h4>
                            </div>
                            <div className='col text-start text-center'>
                                <h4>ຫົວຫນ້າພາກວິຊາ</h4>
                            </div>
                        </div>

                    </div>
                    <div>
                        <button onClick={handleGeneratePDF} type="button" className="btn btn-primary" style={{ marginRight: '10px' }}>ຕົກລົງ</button>
                        <button onClick={() => setShow(false)} type="button" className="btn btn-danger">ຍົກເລີກ</button>
                    </div>

                </div>
            </div>}
            <button onClick={setShow} type="button" class="btn btn-primary">ອອກໃບຄະແນນ</button>
        </div>
    );
};

export default PDFGenerator;
