import React from 'react'
import { CustomButton } from '../../../common'
import { Modal } from 'react-bootstrap'

import {  useMutation } from '@apollo/react-hooks'
import { DELETE_CLASSROOM } from '../../../apollo/classRoom/index'
import { alertSuccess, alertWarningClassRoom } from '../../../common/super'

// DeleteFacultyConfirm
function DeleteClassRoomConfirm ({
    deleteClassRoomConfirmModal,
  _handleDeleteClassRoomConfirmModalClose,
  classRoomData
}) {
  const [deleteClassRoom] = useMutation(DELETE_CLASSROOM)
  const _deleteClassRoom = async () => {
    deleteClassRoom({ variables: { 
      data: {isDeleted: true},
      where: { id: classRoomData.id } 
    } })
      .then(() => {
        _handleDeleteClassRoomConfirmModalClose()
        alertSuccess('ການລຶບ')
      })
      .catch(err => {
        _handleDeleteClassRoomConfirmModalClose()
        if(err?.message === 'GraphQL error: THERE_ARE_STUDENTS_TIES_UP_IN_THE_ROOM'){
        alertWarningClassRoom('ຫ້ອງນີ້ມີນັກສຶກສາຜູກຢູ່ແລ້ວ ບໍ່ສາມາດລຶບໄດ້!')
        }else{
        alertWarningClassRoom('ການລຶບລົ້ມແຫຼວ')
        }
      })
  }

  return (
    <Modal
      show={deleteClassRoomConfirmModal}
      onHide={_handleDeleteClassRoomConfirmModalClose}
      size='md'
    >
      <Modal.Body>
        <h4>ຕ້ອງການລຶບຫ້ອງ {classRoomData?.name} ນີ້ແທ້ບໍ?</h4>
      </Modal.Body>
      <Modal.Footer>
      <CustomButton
          style={{background:"#fff", color: "#c7c7c7",border: "1px solid #c7c7c7"}}
          title='ຍົກເລີກ'
          onClick={_handleDeleteClassRoomConfirmModalClose}
        />
        <CustomButton
          style={{background:"#c7c7c7", color: "#fff",border: "none"}}
          onClick={_deleteClassRoom}
          title='ຢືນຢັນການລົບ'
        />
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteClassRoomConfirm
