export const getDayAsLao = (day) => {
  switch (day) {
    case "MONDAY":
      return "ຈັນ";
    case "TUESDAY":
      return "ອັງຄານ";
    case "WEDNESDAY":
      return "ພຸດ";
    case "THURSDAY":
      return "ພະຫັດ";
    case "FRIDAY":
      return "ສຸກ";
    case "SATURDAY":
      return "ເສົາ";
    case "SUNDAY":
      return "ອາທິດ";
    default:
      return "Invalid day"; // Handle invalid input
  }
};

export default getDayAsLao;
