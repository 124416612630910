import React, { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import useReactRouter from "use-react-router";
/**
 *
 * @Library
 *
 */
import Switch from "@material-ui/core/Switch";
import { Formik } from "formik";
import { __create } from "draft-js/lib/DraftEntity";

/**
 *
 * @Component
 *
 */
import { CustomContainer, TableHeader, CustomButton } from "../../../common";
import { Modal, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loading from "../../../common/Loading";
import DeleteFacultyConfirm from "./DeleteFacultyConfirm";
import EditFaculty from "./EditFaculty";

/**
 *
 * @Constant
 *
 */
/**
 *
 * @Apollo
 *
 */
import { CREATE_FACULTY } from "../../../apollo/faculty/index";
import { FACULTIES } from "../apollo/query";
/**
 *
 * @Function
 *
 */
import { toDayDash, formatDateDash, valiDate } from "../../../common/super";

function SettingFaculty() {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const { history } = useReactRouter();
  const [dataUser, setDataUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState();
  const [showAdd, setShowAdd] = useState(false);
  const _closeAdd = () => setShowAdd(false);
  const [deleteFacultyConfirmModal, setDeleteFacultyConfirmModal] =
    useState(false);
  const [selectedFacultyIndex, setSelectedFacultyIndex] = useState(0);
  const [assessmentYear, setAssessmentYear] = useState([]);

  const _handleDeleteFacultyModalClose = () =>
    setDeleteFacultyConfirmModal(false);
  const _handleDeleteFacultyModalShow = () =>
    setDeleteFacultyConfirmModal(true);

  const [
    loadFacultyMasters,
    { loading: facultyMastersLoading, data: facultyMastersData },
  ] = useLazyQuery(FACULTIES, {
    variables: {
      where: { assessmentYear: accessmentRedux, isDeleted: false },
    },
  });
  // on first load
  useEffect(() => {
    let year = [];
    const NOW = new Date().getFullYear();
    for (var i = NOW - 0; i >= 2014; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    setAssessmentYear(year);
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user?.data);
      if (user?.data?.role === "ADMIN") {
        loadFacultyMasters({
          variables: {
            where: { assessmentYear: accessmentRedux, isDeleted: false },
          },
        });
      }
      if (user?.data?.role === "FAC_ADMIN") {
        loadFacultyMasters({
          variables: {
            where: {
              assessmentYear: accessmentRedux,
              isDeleted: false,
              id: user?.data?.faculty?.id,
            },
          },
        });
      }
    } else {
      history.push(`/`);
    }

  }, [accessmentRedux]);

  useEffect(() => {
    let userRole = dataUser?.role;
    // check role
    if (userRole !== "ADMIN") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    console.log("dataUser -> ", dataUser);
  }, [dataUser]);


 
  // Edit data
  const [showSearchView, setShowSearchView] = useState(false);
  const [newData, setnewData] = useState();
  const _handleSearchViewClose = () => setShowSearchView(false);
  const _handleSearchViewShow = (e) => {
    setnewData(e);
    setShowSearchView(true);
  };
  const [createFaculty] = useMutation(CREATE_FACULTY);
  // add faculty
  const _savefaculty = (data) => {
    createFaculty({
      variables: {
        data: {
          name: data?.name,
          enDescription: data?.enDescription,
          note: data?.note,
          assessmentYear: data?.assessmentYear,
        },
      },
    })
      .then(async () => {
        window.location.reload(true);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <CustomContainer>
        <div className="row" style={{ marginTop: 0, marginBottom: 0 }}>
          <div className="col-md-10">
            <strong>ລາຍການຄະນະວິຊາ</strong>
          </div>
          {isAdmin ? (
            <div className="col-md-2">
              <button className="btn btn-info right" onClick={setShowAdd}>
                <i className="fa fa-plus"></i> ເພີ່ມຄະນະ
              </button>
            </div>
          ) : (
            <></>
          )}
          {facultyMastersLoading && <Loading />}
          <table
            className="table-hover"
            bordercolor="#fff"
            style={{ width: "100%" }}
          >
            <thead>
              <TableHeader>
                <th style={{ width: 100 }}>ລຳດັບ</th>
                <th>ຊື່ຄະນະ(ລາວ)</th>
                <th>ລາຍລະອຽດ(ລາວ)</th>
                {isAdmin ? <th style={{ width: 200 }}>ຈັດການ</th> : <></>}
              </TableHeader>
            </thead>
            <tbody>
              {facultyMastersData?.faculties?.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    setSelectedFacultyIndex(index);
                  }}
                >
                  <td className="text-center">{index + 1}</td>
                  <td
                    className="left"
                    onClick={() => history.push(`/setting-department`, item)}
                  >
                    {item?.name}
                  </td>
                  <td
                    className="left"
                    onClick={() => history.push(`/setting-department`, item)}
                  >
                    {item?.description}
                  </td>
                  {isAdmin ? (
                    <td>
                      <button
                        className="btn btn-control"
                        onClick={(e) => _handleSearchViewShow(item)}
                      >
                        <i
                          className="fa fa-pen"
                          style={{ color: "#28a745" }}
                        ></i>
                      </button>
                      <button
                        className="btn btn-control"
                        onClick={_handleDeleteFacultyModalShow}
                      >
                        <i
                          className="fa fa-trash"
                          style={{ color: "#B80000" }}
                        ></i>
                      </button>
                    </td>
                  ) : (
                    <></>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CustomContainer>

      {/* add data  */}
      <Formik
        initialValues={{
          assessmentYear: "",
          name: "",
          description: "",
          enName: "",
          enDescription: "",
          note: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = "ກະລຸນາປ້ອນຊື່ຄະນະພາສາລາວ";
          }
          if (!values.enName) {
            errors.enName = "ກະລຸນາປ້ອນຊື່ຄະນະພາສາອັງກິດ";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          let status = values.status;
          if (status == "") {
            status = "DISABLE";
          } else {
            status = "ENABLE";
          }
          let data = {
            name: values.name,
            description: values.description,
            enName: values.enName,
            enDescription: values.enDescription,
            note: values.note,
            assessmentYear: values.assessmentYear,
          };
          _savefaculty(data);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Modal
            animation={false}
            size="lg"
            centered
            show={showAdd}
            onHide={_closeAdd}
            style={{ padding: 0 }}
          >
            <Modal.Header style={{ padding: 10, border: 0 }}>
              <Modal.Title
                style={{
                  textAlign: "center",
                  width: "100%",
                  padding: 0,
                  marginTop: 20,
                  fontWeight: "bolder",
                }}
              >
                <strong>ເພີ່ມຂໍ້ມູນຄະນະ</strong>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0 100px 20px 100px",
                justifyContent: "center",
              }}
            >
              <label>ຊື່ຄະນະ(ລາວ) :{valiDate()}</label>
              <Form.Control
                type="text"
                name="name"
                onChange={handleChange}
                value={values.name}
                isInvalid={!!errors.name}
                placeholder="ກະລຸນາປ້ອນຊື່ຄະນະ(ລາວ)"
              />
              <label>ຕົວຫຍໍ້(ລາວ)</label>
              <Form.Control
                className="form-control"
                type="text"
                name="description"
                onChange={handleChange}
                value={values.description}
                placeholder="ກະລຸນາປ້ອນຕົວຫຍໍ້(ຖ້າມີ)"
              />
              <label>ຊື່ຄະນະ(ອັງກິດ) {valiDate()}</label>
              <Form.Control
                type="text"
                name="enName"
                onChange={handleChange}
                value={values.enName}
                isInvalid={!!errors.enName}
                placeholder="ກະລຸນາປ້ອນຊື່ຄະນະ(ອັງກິດ)"
              />
              <label>ຕົວຫຍໍ້(ອັງກິດ)</label>
              <Form.Control
                className="form-control"
                type="text"
                name="enDescription"
                onChange={handleChange}
                value={values.enDescription}
                placeholder="ກະລຸນາປ້ອນຕົວຫຍໍ້(ຖ້າມີ)"
              />
              <label>ສົກສຶກສາ {valiDate()}</label>
              <Form.Control
                as="select"
                name="assessmentYear"
                value={values.assessmentYear}
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <option value="">---ກະລຸນາປ້ອນສົກຮຽນ---</option>
                {assessmentYear.map((x, assesIndex) => {
                  return (
                    <option key={assesIndex} value={x}>
                      {x}
                    </option>
                  );
                })}
              </Form.Control>
              <label>ໝາຍເຫດ</label>
              <textarea
                className="form-control"
                name="note"
                onChange={handleChange}
                value={values.note}
                placeholder="ກະລຸນາປ້ອນໝາຍເຫດ(ຖ້າມີ)"
              />
            </Modal.Body>
            <Modal.Footer>
              <CustomButton title="ຍົກເລີກ" onClick={_closeAdd} />
              <CustomButton
                onClick={handleSubmit}
                title="ບັນທຶກ"
                type="submit"
                confirm
              />
            </Modal.Footer>
          </Modal>
        )}
      </Formik>

      {/* ------- DeleteFacultyConfirm Modal ------ */}
      <DeleteFacultyConfirm
        deleteFacultyConfirmModal={deleteFacultyConfirmModal}
        _handleDeleteFacultyConfirmModalClose={_handleDeleteFacultyModalClose}
        facultyData={facultyMastersData?.faculties[selectedFacultyIndex]}
      />

      {/* update faculty */}
      <EditFaculty
        showSearchView={showSearchView}
        _handleSearchViewClose={_handleSearchViewClose}
        let
        allData={newData}
      />
    </div>
  );
}
export default SettingFaculty;
