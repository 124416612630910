import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import useReactRouter from "use-react-router";
/**
 *
 * @Library
 *
 */
 import queryString from "query-string";
/**
 *
 * @Component
 *
 */
import { Form } from "react-bootstrap";
import { useSelector } from 'react-redux'
import { CustomContainer } from "../../../common";
import Loading from "../../../common/Loading";
import GradeNav from "../common/GradeNav";

/**
 *
 * @Constant
 *
 */
import Consts from "../../../consts";

/**
 *
 * @Apollo
 *
 */
import { FACULTY_NAME } from "../../../apollo/faculty";
import { DEPARTMENT_NAME } from "../../../apollo/deparment/index";
import { GRADES } from "../../../apollo/grade/index";
import { CALL_COURSE_DATA } from "../../../apollo/course/index";
import { LOAD_TEACHERS_BOX, USERS } from "../../../apollo/user/index";
/**
 *
 * @Function
 *
 */
import { valiDate, setGenders, formatDate } from "../../../common/super";
import PaginationHelper from "../../../helpers/PaginationHelper";
import "../util/index.css";
import { MAJORS } from "../../../apollo/major";
import { CLASSROOM_MASTERS } from "../../../apollo/classRoom";
import { convertGenderNamEN } from "../../../helpers/user";

function GradesList() {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const { location } = useReactRouter();
  const parsed = queryString?.parse(location?.state);
  const user = JSON.parse(window.localStorage.getItem("user")).data;
  const ROLE = user.role;

  // filter data
  const [factId, setFactId] = useState(!parsed?.faculty ? "" : parsed?.faculty);
  const [departmentId, setDepartmentId] = useState(!parsed?.department ? "" : parsed?.department);
  const [dataDepartments, setDataDepartments] = useState([])
  const [selectedYear, setSelectedYear] = useState(!parsed?.year ? "" : parsed?.year);
  const [selectAccessMent, setSelectAccessMent] = useState("");
  const [selectSemester, setSelectSemester] = useState(!parsed?.semester ? "" : parsed?.semester);
  const [selectCourse, setSelectCourse] = useState(!parsed?.course ? "" : parsed?.course);
  const [selectTeacher, setSelectTeacher] = useState(!parsed?.teacher ? "" : parsed?.teacher);
  const [studentName, setStudentName] = useState("");
  const [courses, setCourses] = useState([]);
  const [grades, setGrades] = useState([]);
  const [userTeachers, setUserTeachers] = useState([]);

  const [dataMajors, setDataMajors] = useState([])
  const [majorId, setMajorId] = useState(!parsed?.major ? "" : parsed?.major)

  const [dataClassRooms, setDataClassRooms] = useState([]);
  const [classroomId, setClassroomId] = useState(!parsed?.classroom ? "" : parsed?.classroom);

  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTY_NAME);
  const [loadGrades, { loading: gradeLoading, data: gradeData }] = useLazyQuery(GRADES, { fetchPolicy: "network-only" });
  const [loadCourse, { data: courseData }] = useLazyQuery(CALL_COURSE_DATA);
  const [loadTeacher, { data: teacherData }] = useLazyQuery(USERS);
  const [loadDepartment, { data: departmentData }] = useLazyQuery(DEPARTMENT_NAME);
  const [loadMajors, { data: majorData }] = useLazyQuery(MAJORS)
  const [loadClassRooms, { data: classroomData }] = useLazyQuery(CLASSROOM_MASTERS);
  /**
   *
   * Pagination
   *
   */
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  useEffect(() => {
    if(user?.role === "FAC_ADMIN"){
      setFactId(user?.faculty?.id)
    }
    if(user?.role === "DEP_ADMIN"){
      setFactId(user?.faculty?.id)
      setDepartmentId(user?.department?.id)
    }
  }, [user])

  useEffect(() => {
    loadFaculties({
      variables: {
        where: { isDeleted: false, assessmentYear: accessmentRedux }
      },
    });
    loadGrades({
      variables: {
        where: {
          sendingGradeStatus_not: "TEACHER_SCORING",
          assessmentYear: accessmentRedux,
          isDeleted: false
        },
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });
  }, [accessmentRedux]);

  useEffect(() => {
    if (courseData?.courses?.data?.length > 0) {
      setCourses(courseData?.courses?.data)
    }
  }, [courseData])

  useEffect(() => {
    if (gradeData?.grades?.data) {
      setGrades(gradeData?.grades?.data)
    }
  }, [gradeData])

  useEffect(() => {
    if(departmentId) {
      loadMajors({
        variables:{
          where: {
            isDeleted: false, 
            department: departmentId, 
            assessmentYear: accessmentRedux
          }
        }
      })
    }
  }, [departmentId])

  useEffect(() => {
    if(!majorId) return;
    let _where = {}
    if (selectedYear !== "") _where = { ..._where, year: selectedYear }
    if(majorId !== "") _where = { ..._where, major: majorId }
    loadClassRooms({
      variables: { where: _where },
    });
  }, [majorId, selectedYear]);

  useEffect(() => {
    if (teacherData?.user_page?.data?.length > 0) {
      setUserTeachers(teacherData?.user_page?.data)
    }
  }, [teacherData])

  useEffect(() => {
    if (departmentData) {
      setDataDepartments(departmentData?.departments)
    }
  }, [departmentData])

  useEffect(() => {
    if(majorData) setDataMajors(majorData?.majors?.data)
  }, [majorData])

  useEffect(() => {
    if(classroomData) setDataClassRooms(classroomData?.classRooms?.data);
  }, [classroomData]);

  // useEfect first loading
  useEffect(() => {
    let _whereTeachers = { isDeleted: false }
    if (factId !== "") _whereTeachers = { ..._whereTeachers, faculty: factId }
    if (departmentId !== "") _whereTeachers = { ..._whereTeachers, department: departmentId }
    loadTeacher({
      variables: {
        where: _whereTeachers,
      },
    });

    let _whereCourse = { isDeleted: false, assessmentYear: accessmentRedux }
    if (factId !== "") _whereCourse = { ..._whereCourse, faculty: factId }
    if (departmentId !== "") _whereCourse = { ..._whereCourse, department: departmentId }
    if (majorId != "") _whereCourse = { ..._whereCourse, major: majorId }
    if (classroomId != "") _whereCourse = { ..._whereCourse, classRoom: classroomId }

    loadCourse({
      variables: {
        where: _whereCourse,
        first: 300,
        orderBy: "createdAt_DESC"
      },
    });

    // call all grade data
    let allWhere = { sendingGradeStatus_not: "TEACHER_SCORING", assessmentYear: accessmentRedux, isDeleted: false };
    if (factId != "") allWhere = { ...allWhere, faculty: factId }
    if (departmentId != "") allWhere = { ...allWhere, department: departmentId }
    if (majorId != "") allWhere = { ...allWhere, major: majorId }
    if (classroomId != "") allWhere = { ...allWhere, classRoom: classroomId }
    if (selectedYear) allWhere = { ...allWhere, yearLevel: parseInt(selectedYear) };
    if (selectSemester) allWhere = { ...allWhere, semester: parseInt(selectSemester) };
    if (studentName) allWhere = { ...allWhere, student: studentName };
    if (selectCourse) allWhere = { ...allWhere, course: selectCourse };
    if (selectTeacher) allWhere = { ...allWhere, teacher: selectTeacher };

    loadGrades({
      variables: {
        where: allWhere,
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });
  }, [factId, departmentId, majorId, classroomId, selectedYear, selectSemester, selectAccessMent, selectCourse, selectTeacher, studentName]);

  // select faculty ad get value
  const _selectFaculty = (e) => {
    setFactId(e.target.value);
    setDataMajors([])
    setMajorId("")
    setDataClassRooms([])
    setClassroomId("")
    if (e.target.value) {
      loadDepartment({
        variables: {
          where: { faculty: e.target.value },
        },
      });
    } else {
      setDepartmentId("")
      setDataDepartments([])
    }
  };
  // select department
  const _onSelectedDepartment = (e) => {
    setDepartmentId(e.target.value);
    setDataMajors([])
    setMajorId("")
    setDataClassRooms([])
    setClassroomId("")
  };
  const _onSelectedMajor = (e) => {
    setMajorId(e.target.value);
    setDataClassRooms([])
    setClassroomId("")
  };
  const _onSelectedClassroom = (e) => {
    setClassroomId(e.target.value);
  }
  // select year
  const _onSelectedYear = (e) => {
    setSelectedYear(e.target.value);
  };
  // select unit
  const _onSelectedSemester = (e) => {
    setSelectSemester(e.target.value);
  };
  // select Course
  const _onSelectedCourse = (e) => {
    setSelectCourse(e.target.value);
  };
  // select Teacher
  const _onSelectedTeacher = (e) => {
    setSelectTeacher(e.target.value);
  };

  // search data
  const _onKeyUp = (e) => {
    if (e.key == "Enter") {
      setStudentName(e.target.value);
    }
  };

  return (
    <div>
      <GradeNav />
      <CustomContainer style={{ marginTop: 0 }}>
        <div style={{ display: "block", marginTop: 20 }}>
          <span
            style={{
              display: "inline-block",
              fontSize: 24,
              marginTop: 20,
              color: "#057CAE",
              fontWeight: "bold",
            }}
          >
            ຄະແນນທັງໝົດ
          </span>
          {/* <Button
            variant="outline-success"
            style={{ float: "right", marginTop: 20 }}
          >
            <i className="fa fa-file-excel"></i> ເພີ່ມຈາກ Excel
          </Button> */}
        </div>
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <input
              type="text"
              className="form-control box-search"
              placeholder="ປ້ອນລະຫັດນັກສຶກສາແລ້ວກົດ Enter"
              // defaultValue={studentName}
              name="studentName"
              onKeyDown={(e) => _onKeyUp(e)}
            />
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span>ຄະນະ</span>
              <Form.Control
                disabled={ROLE !== "ADMIN" ? true : false}
                value={ROLE !== "ADMIN" ? user.faculty.id : factId}
                as="select"
                className="form-select"
                onChange={(e) => _selectFaculty(e)}
              // defaultValue={ROLE !="ADMIN"?user.faculty.id:""}
              >
                <option value="">ທັງໝົດ</option>
                {facultyData?.faculties?.map((item, index) => {
                  return (
                    <option key={"faculty" + index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span>ພາກວິຊາ</span>
              <Form.Control
                disabled={ROLE === "DEP_ADMIN" ? true : false}
                value={ROLE === "DEP_ADMIN" ? user.department.id : departmentId}
                as="select"
                className="form-select"
                onChange={(e) => _onSelectedDepartment(e)}
              // defaultValue={ROLE === "DEP_ADMIN"?user.department.id:""}
              >
                <option value="">ທັງໝົດ</option>
                {dataDepartments?.map((x, index) => (
                  <option key={"department" + index} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span>ສາຂາວິຊາ</span>
              <Form.Control
                value={majorId}
                as="select"
                className="form-select"
                onChange={(e) => _onSelectedMajor(e)}
              >
                <option value="">ທັງໝົດ</option>
                {dataMajors?.map((x, index) => (
                  <option key={index} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span>ປີ</span>
              <Form.Control
                as="select"
                className="form-select"
                onChange={(e) => _onSelectedYear(e)}
              >
                <option value="">ທັງໝົດ</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
              </Form.Control>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span>ພາກຮຽນ</span>
              <Form.Control
                as="select"
                className="form-select"
                onChange={(e) => _onSelectedSemester(e)}
              >
                <option value="">ທັງໝົດ</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
              </Form.Control>
            </div>
            {/* <div className="col-lg-3 col-md-4 col-sm-6">
              <p>ສົກຮຽນ</p>
              <select
                className="form-control"
                defaultValue={selectAccessMent}
                onChange={(e) => _onChangeAccessMent(e)}
              >
                <option value="">ທັງໝົດ</option>
                {callAccessMent?.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div> */}
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span>ຫ້ອງນັກສຶກສາ</span>
              <Form.Control
                as="select"
                className="form-select"
                onChange={(e) => _onSelectedClassroom(e)}
                values={classroomId}
              >
                <option value="">ທັງໝົດ</option>
                {dataClassRooms?.map((item, index) => (
                  <option key={index} value={item.id}>{item.name}</option>
                ))}
              </Form.Control>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span>ວິຊາ</span>
              <Form.Control
                as="select"
                className="form-select"
                onChange={(e) => _onSelectedCourse(e)}
                values={selectCourse}
              >
                <option value="">ທັງໝົດ</option>
                {courses?.map((_course, index) => (
                  <option key={"coures" + index} value={_course.id}>
                    {_course.title}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6">
              <span>ອາຈານ</span>
              <Form.Control
                as="select"
                className="form-select"
                onChange={(e) => _onSelectedTeacher(e)}
                values={selectTeacher}
              >
                <option value="">ທັງໝົດ</option>
                {userTeachers?.map((item, index) => (
                  <option key={"teacher" + index} value={item?.id}>
                    {item?.firstname} {item?.lastname}
                  </option>
                ))}
              </Form.Control>
            </div>
          </div>


        </div>
      </CustomContainer>
      <CustomContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          ທັງໝົດ {gradeData?.grades?.total ?? 0} ຄົນ
        </div>
        {/* Table list */}
        <div>
          {gradeLoading ? (
            <Loading />
          ) : (
            <table className="table table-hover">
              <tr
                style={{
                  color: "white",
                  backgroundColor: Consts.PRIMARY_COLOR,
                  textAlign: "center",
                }}
              >
                <th style={{ width: 70 }}>ລຳດັບ</th>
                <th>ລະຫັດນັກຮຽນ</th>
                <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                <th>ຄະແນນ</th>
                <th>ເກຣດ</th>
                <th>ໃຫ້ຄະແນນໂດຍ</th>
                <th>ເວລາອັບເດດ</th>
                {/* <th style={{ width: 180 }}>ແກ້ໄຂ</th> */}
              </tr>
              <tbody>
                {grades?.map((grade, index) => {
                  return (
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      <td>{index + 1 + _limit * (_skip - 1)}</td>
                      <td>{grade?.student?.studentNumber}</td>
                      <td style={{ textAlign: "left" }}>
                        {!grade?.student?.firstNameL || grade?.student?.firstNameL === "" ? 
                          convertGenderNamEN(grade?.student?.gender) + " " + grade?.student?.firstNameE + " " + grade?.student?.lastNameE
                          :
                          setGenders(grade?.student?.gender) + " " + grade?.student?.firstNameL + " " + grade?.student?.lastNameL
                        }
                        {/* {setGenders(grade?.student?.gender)}{" "}
                        {grade?.student?.firstNameL}&nbsp;{" "}
                        {grade?.student?.lastNameL} */}
                      </td>
                      <td>{grade?.numbericGrade}</td>
                      <td>{grade?.letterGrade}</td>
                      <td>{grade?.updatedBy ?? grade?.note}</td>
                      <td>{formatDate(grade?.createdAt)}</td>
                      {/* <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <div
                            onClick={() => {
                              setShowEdit(!showEdit);
                              setEditData(grade);
                            }}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#FFFFFF",
                              padding: 3,
                              width: 64,
                              borderRadius: 4,
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fas", "edit"]}
                              color={Consts.BORDER_COLOR}
                            />{" "}
                          </div>
                        </div>
                      </td> */}
                    </tr>
                  );
                })}
                {/* })} */}
              </tbody>
            </table>
          )}
        </div>
        {Pagination_helper(gradeData?.grades?.total, "/grades-list", `faculty=${factId}&&department=${departmentId}&&major=${majorId}&&year=${selectedYear}&&semester=${selectSemester}&&classroom=${classroomId}&&course=${selectCourse}&&teacher=${selectTeacher}`)}
      </CustomContainer>
      {/* <EditGrade
        show={showEdit}
        hide={() => setShowEdit(!showEdit)}
        data={editData}
      /> */}

      {/* search madal */}
      <div className="modal fade" id="editData" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <strong>ຄົ້ນຫາ</strong>
              <button
                className="btn right btn-sm close-icon"
                data-dismiss="modal"
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <label>ຊື່ຊົນເຜົ່າ {valiDate()}</label>
              <input
                type="text"
                className="form-control"
                name="tribeName"
                placeholder="ກະລຸນາປ້ອນຊື່ຊົນເຜົ່າ"
              />
              <label>ໝາຍເຫດ</label>
              <textarea
                className="form-control"
                name="node"
                placeholder="ກະລຸນາປ້ອນໝາຍເຫດ(ຖ້າມີ)"
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-info">
                ຄົ້ນຫາ
              </button>
              <button
                type="button"
                className="btn btn-control"
                data-dismiss="modal"
              >
                ຍົກເລີກ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GradesList;
