import React, { useState, useEffect, useRef } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import { Formik } from "formik";
import * as _ from "lodash";
import axios from "axios";
import { Progress } from "reactstrap";
import { useSelector } from "react-redux";

/**
 *
 * @Component
 *
 */
import { Breadcrumb, Button, Form, Toast, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import StudentEditConfirm from "./StudentEditConfirm";

/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";
import Routers from "../../consts/router";
import { LAO_ADDRESS } from "../../consts/Address";
import { COUNTRIES_LIST } from "../../consts/Countries";

/**
 *
 * @Apollo
 *
 */
import { FACULTIES } from "../../apollo/faculty";
import { DEPARTMENTS } from "../../apollo/deparment";
import { MAJORS } from "../../apollo/major";
import { TRIBE_MASTER } from "../../apollo/tribeMaster/index";
import { RELIGIONAL_MASTERS } from "../../apollo/religionalMasters";
import { EDUCATIONLEVEL } from "../../apollo/educationLevel";
import { PERSONALTYPE } from "../../apollo/ personalTypeMaster";
import { PRE_SIGNED_URL, STUDENT } from "../../apollo/student"
import { CLASSROOM_MASTERS } from "../../apollo/classRoom";

/**
 *
 * @Function
 *
 */
import { CustomContainer } from "../../common";
import { valiDate, formatDateDash } from "../../common/super";

// --------- function component -----------------
function StudentEdit() {
  const { history, match } = useReactRouter();
  const studentId = match?.params?.id;
  /**
 *
 * @State
 *
 */
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const [studentData, setStudentData] = useState();
  const [coverImage, setCoverImage] = useState();
  const [previewImageURL, setPreviewImageURL] = useState("");
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
  const [selectComeFormProvince, setSelectComeFormProvince] = useState("");
  const [selectCurrentProvinceIndex, setSelectCurrentProvinceIndex] = useState(-1);
  const [isLiveTogether, setIsLiveTogether] = useState("");
  const [isBornAbroad, setIsBornAbroad] = useState(false)
  const [scolarship, setScolarship] = useState();
  const [studentType, setStudentType] = useState("");
  const [graduatedYear, setGraduatedYear] = useState([]);
  const [assessmentYear, setAssessmentYear] = useState([]);
  const [selectCurrentProvince, setSelectCurrentProvince] = useState("");
  const [selectCurrentDistrict, setSelectCurrentDistrict] = useState("");
  const [selectBirthProvinceIndex, setSelectBirthProvinceIndex] = useState(-1);
  const [selectBirthProvince, setSelectBirthProvince] = useState("");
  const [selectBirthDistrict, setSelectBirthDistrict] = useState("");
  const [strengthPass, setStrengthPass] = useState(0);
  const [checkPasswordLength, setCheckPasswordLength] = useState("");
  const [formParam, setFormParam] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const [clickCheckbox, setClickCheckbox] = useState();
  const [scholarship, setScholarship] = useState();

  const [dataFaculties, setDataFaculties] = useState([]);
  const [facultyId, setFacultyId] = useState("");
  const [facultyName, setFacultyName] = useState("");

  const [dataDepartment, setDataDepartment] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [departmentName, setDepartmentName] = useState("");

  const [dataMajors, setDataMajors] = useState([]);
  const [majorId, setMajorId] = useState("");
  const [majorName, setMajorName] = useState("");

  const [dataClassroom, setDataClassroom] = useState([]);
  const [classroomId, setClassroomId] = useState("");
  const [classroomName, setClassroomName] = useState("");

  const [dataUser, setDataUser] = useState({})
  const [role, setRole] = useState("");

  const [year, setYear] = useState("");

  /**
   *
   * @Apollo
   *
   */

  const [dataStudentLoader, { data: apolloData }] = useLazyQuery(STUDENT);

  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTIES);
  const [loadDepartments, { data: departmentData }] = useLazyQuery(DEPARTMENTS);
  const [loadMajors, { data: majorApollo }] = useLazyQuery(MAJORS)

  const [loadReligionalMaster, { data: religionalMasterData }] = useLazyQuery(RELIGIONAL_MASTERS);
  const [loadPresignImage, { data: presignData }] = useLazyQuery(PRE_SIGNED_URL);
  const [loadTribeMaster, { data: tribeMasterData }] = useLazyQuery(TRIBE_MASTER);
  const [loadEducationMaster, { data: EducationData }] = useLazyQuery(EDUCATIONLEVEL);
  const [loadPersonalTypeMaster, { data: PersonalTypeData }] = useLazyQuery(PERSONALTYPE);
  const [loadClassRooms, { data: classroomData }] = useLazyQuery(CLASSROOM_MASTERS)
  /**
   *
   * @UseEffect
   *
   */
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
    let year = [];
    const NOW = new Date().getFullYear();
    for (var i = NOW; i >= 2014; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    setAssessmentYear(year);
    setGraduatedYear(year);
    loadPersonalTypeMaster();
    loadEducationMaster();
    loadTribeMaster();
    loadReligionalMaster();
    setIsChecked(false);
    loadFaculties({
      variables: {
        where: { isDeleted: false, assessmentYear: accessmentRedux },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessmentRedux]);

  useEffect(() => {
    dataStudentLoader({
      fetchPolicy: "network-only",
      variables: {
        where: { id: studentId },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId]);

  useEffect(() => {
    if (!facultyData?.faculties) return;
    setDataFaculties(facultyData?.faculties)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facultyData]);

  useEffect(() => {
    if (!apolloData?.student) return;

    setStudentData(apolloData?.student);
    setPreviewImageURL(Consts.URL_IMAGE_STUDENT + apolloData?.student?.image?.url);

    setStudentType(apolloData?.student?.personalType?.name);
    setFacultyName(apolloData?.student?.faculty?.name);

    loadDepartments({
      variables: {
        where: {
          isDeleted: false,
          assessmentYear: accessmentRedux,
          faculty: apolloData?.student?.faculty?.id
        }
      }
    });
    setDepartmentName(apolloData?.student?.department?.name);

    loadMajors({
      variables: {
        where: {
          isDeleted: false,
          assessmentYear: accessmentRedux,
          department: apolloData?.student?.department?.id
        }
      }
    });
    setMajorName(apolloData?.student?.major?.name);

    loadClassRooms({ variables: { where: { major: apolloData?.student?.major?.id, year: apolloData?.student?.year } }, });
    setMajorId(apolloData?.student?.major?.id);
    setYear(apolloData?.student?.year);
    setClassroomName(apolloData?.student?.classRoom?.name);

    _selectBirthProvinceFirst(apolloData?.student?.birthAddress?.province);
    setSelectBirthDistrict(apolloData?.student?.birthAddress?.district);

    setIsLiveTogether(apolloData?.student?.emergencyContact?.isLiveTogether === "true" ? true : false);
    setIsBornAbroad(apolloData?.student?.bornAbroad === "" ? false : true)
    setClickCheckbox(false);
    // _selectComeFormProvinceFirst(
    //   scolarship !== "NO"
    //     ? apolloData?.student?.comingFrom?.graduatedProvince
    //     : apolloData?.student?.payTuition?.graduatedProvince
    // );
    _selectComeFormProvinceFirst(apolloData?.student?.payTuition?.graduatedProvince)
    if (apolloData?.student?.scholarship === "NO") {
      setSelectComeFormProvince(apolloData?.student?.payTuition?.graduatedProvince)
    }
    else if (
      apolloData?.student?.scholarship === "ORGANIZATION_IN_LAOPDR_MINISTRIES"
      || apolloData?.student?.scholarship === "ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION"
      || apolloData?.student?.scholarship === "HIGHER_EDUCATION_INSTITUTION_FORM_ABROAD"
      || apolloData?.student?.scholarship === "PERSONAL_FINANCIAL"
    ) {
      setClickCheckbox(false);
    }
    else {
      setClickCheckbox(true);
      setSelectComeFormProvince(apolloData?.student?.comingFrom?.graduatedProvince)
    }

    _selectCurrentProvinceFirst(apolloData?.student?.address?.province);
    setSelectCurrentDistrict(apolloData?.student?.address?.district)

    setScolarship(apolloData?.student?.scholarship);

    setClassroomName(apolloData?.student?.classRoom?.name)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apolloData]);

  useEffect(() => {
    getPresignImage(coverImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverImage, presignData]);

  useEffect(() => {
    loadDepartments({
      variables: { where: { faculty: facultyId } },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facultyId]);

  useEffect(() => {
    setDataDepartment(departmentData?.departments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentData]);

  useEffect(() => {
    if (departmentId) {
      loadMajors({
        variables: { where: { isDeleted: false, department: departmentId } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  useEffect(() => {
    setClassroomId("")
    let _where = {}
    if (majorId !== "") _where = { ..._where, major: majorId }
    if (year !== "") _where = { ..._where, year: year }
    loadClassRooms({
      variables: { where: _where },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [majorId, year]);

  useEffect(() => {
    setDataMajors(majorApollo?.majors?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [majorApollo]);

  useEffect(() => {
    setDataClassroom(classroomData?.classRooms?.data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classroomData])

  useEffect(() => {
    if (dataUser?.role === 'ADMIN' || dataUser?.role === 'DEAN') {
      setFacultyId("");
      setDepartmentId("");
    } else {
      setFacultyId(dataUser?.faculty?.id)
      setFacultyName(dataUser?.faculty?.name);
      setDepartmentId(dataUser?.department?.id)
      setDepartmentName(dataUser?.department?.name);
      setMajorId(dataUser?.major?.id);
      setMajorName(dataUser?.major?.name);
    }
    setRole(dataUser?.role)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser]);

  /**
   *
   * @Function
   *
   */

  const inputImage = useRef("inputImage");
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
  const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);

  const _addImage = () => { inputImage.current.click() };

  const _uploadFile = async () => {
    if (presignData.preSignedUrl) {
      const response = await axios({
        method: "put",
        url: presignData.preSignedUrl.url,
        data: coverImage,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      return response;
    }
  };

  const getPresignImage = async (file) => {
    await loadPresignImage({
      variables: {
        mimeType: file?.type,
        fileName: file?.name?.split(".")[0],
      },
    });
  };

  const _handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setPreviewImageURL(URL.createObjectURL(event.target.files[0]));
      setCoverImage(event.target.files[0]);
    }
  };

  const _selectPersonalType = (e) => {
    const personIndex = _.findIndex(PersonalTypeData?.personalTypeMasters?.data, { id: e.target.value });
    setStudentType(PersonalTypeData?.personalTypeMasters?.data[personIndex]?.name);
  }

  const _selectFacalty = (e) => {
    setDepartmentId("")
    setDataDepartment([])
    setMajorId("")
    setDataMajors([])
    setClassroomId("")
    setDataClassroom([])
    setFacultyId(e.target.value);
    const facIndex = _.findIndex(dataFaculties, { id: e.target.value });
    setFacultyName(dataFaculties[facIndex]?.name);
  };

  const _selectDepartment = (e) => {
    setMajorId("")
    setDataMajors([])
    setClassroomId("")
    setDataClassroom([])
    setDepartmentId(e.target.value);
    const depIndex = _.findIndex(dataDepartment, { id: e.target.value });
    setDepartmentName(dataDepartment[depIndex]?.name);
  };
  const _selectMajor = (e) => {
    setClassroomId("")
    setDataClassroom([])
    setMajorId(e.target.value);
    const majorIndex = _.findIndex(dataMajors, { id: e.target.value })
    setMajorName(dataMajors[majorIndex]?.name);
  };

  const _selectClassroom = (e) => {

    setClassroomId(e.target.value);
    const classIndex = _.findIndex(dataClassroom, {
      id: e.target.value
    })
    setClassroomName(dataClassroom[classIndex]?.name);
  };

  function checkpassword(password) {
    var strength = 0;
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[$@#&!%_?]+/)) {
      strength += 1;
    }

    switch (strength) {
      case 0:
        setStrengthPass(0);
        break;

      case 1:
        setStrengthPass(25);
        break;

      case 2:
        setStrengthPass(50);
        break;

      case 3:
        setStrengthPass(75);
        break;

      case 4:
        setStrengthPass(100);
        break;
      default:
        setStrengthPass(0);
        break;
    }
  }
  const checkLength = (password) => {
    if (password && password.length < 6) {
      setCheckPasswordLength("ກະລຸນາປ້ອນໜ້ອຍສຸດຢ່າງໜ້ອຍ 6 ໂຕຂຶ້ນໄປ");
    } else {
      setCheckPasswordLength("");
    }
  };

  const _selectCurrentProvinceFirst = (e) => {
    // setSelectCurrentDistrict("");
    const _provinceIndex = _.findIndex(LAO_ADDRESS, { province_name: e, });

    setSelectCurrentProvinceIndex(_provinceIndex);
    setSelectCurrentProvince(e);
    setSelectCurrentDistrict(studentData?.address?.district);
  };

  const _selectCurrentProvince = (e) => {
    setSelectCurrentDistrict("");
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectCurrentProvinceIndex(_provinceIndex);
    setSelectCurrentProvince(e.target.value);
  };
  const _selectCurrentDistrict = (e) => {
    setSelectCurrentDistrict(e.target.value);
  };

  const _selectBirthProvinceFirst = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e,
    });
    setSelectBirthProvinceIndex(_provinceIndex);
    setSelectBirthProvince(e);
    setSelectBirthDistrict(studentData?.birthAddress?.district);
  };
  const _selectBirthProvince = (e) => {
    setSelectBirthDistrict("");
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value,
    });
    setSelectBirthProvinceIndex(_provinceIndex);
    setSelectBirthProvince(e.target.value);
  };

  const _selectBirthDistrict = (e) => {
    setSelectBirthDistrict(e.target.value);
  };

  const _selectComeFormProvinceFirst = (e) => {
    setSelectComeFormProvince(e);
  };
  const _selectComeFormProvince = (e) => {
    setSelectComeFormProvince(e.target.value);
  };


  const _selectScholarship = (e) => {
    // setClickCheckbox(false);
    setClickCheckbox(!clickCheckbox);
  };
  const selectScholarship = (e) => {
    setScholarship(e.target.value);
    setScolarship(!scolarship);
  };

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push(Routers.STUDENT_LIST + Routers.PAGE_GINATION)}
        >
          ຈັດການນັກສຶກສາ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push(Routers.STUDENT_LIST + Routers.PAGE_GINATION)}
        >
          ລາຍຊື່ນັກສຶກສາທັງໝົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ແກ້ໄຂນັກຮຽນ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer style={{ marginTop: 0 }}>
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          <div className="container">
            {studentData &&
              <Formik
                enableReinitialize
                initialValues={{
                  studentNumber: studentData?.studentNumber ?? "",
                  firstNameL: studentData?.firstNameL ?? "",
                  lastNameL: studentData?.lastNameL ?? "",
                  firstNameE: studentData?.firstNameE ?? "",
                  lastNameE: studentData?.lastNameE ?? "",
                  faculty: studentData?.faculty?.id ?? "",
                  department: studentData?.department?.id ?? "",
                  major: studentData?.major?.id ?? "",
                  personalType: studentData?.personalType?.id ?? "",
                  education: studentData?.educationLevel?.id ?? "",
                  assessmentYear: studentData?.assessmentYear ?? assessmentYear,
                  birthday: formatDateDash(studentData?.birthday),
                  phoneHome: studentData?.phoneHome ?? "",
                  phoneMobile: studentData?.phoneMobile ?? "",
                  email: studentData?.email ?? "",
                  description: studentData?.description ?? "",
                  userId: studentData?.userId ?? "",
                  password: "",
                  confirmPassword: "",
                  note: studentData?.note ?? "",
                  yearLevel: studentData?.yearLevel ?? "",
                  tribeMasters: studentData?.tribe ?? "",
                  religional: studentData?.religion ?? "",
                  day: 0,
                  month: 0,
                  year: 0,
                  gender: studentData?.gender ?? "",
                  maritualStatus: studentData?.maritualStatus,
                  scholarship: studentData?.scholarship ?? "NO",
                  outPlanScholarship: studentData?.scholarship ?? "",
                  scholarshipFrom: studentData?.scholarshipFrom ?? "",
                  nationality: studentData?.nationality ?? "",
                  currentCountry: studentData?.address?.country ?? "",
                  currentProvince: studentData?.address?.province ?? "",
                  currentDistrict: studentData?.address?.district ?? "",
                  village: studentData?.address?.village ?? "",
                  bornAbroad: studentData?.bornAbroad ?? "",
                  birthCountry: studentData?.birthAddress?.country ?? "",
                  birthProvince: studentData?.birthAddress?.province ?? "",
                  birthDistrict: studentData?.birthAddress?.district ?? "",
                  villages: studentData?.birthAddress?.village ?? "",

                  classRoom: studentData?.classRoom?.id ?? "",
                  studyStatus: studentData?.studyStatus ?? "",
                  relationship: studentData?.emergencyContact?.relationship ?? "",
                  parentPhone: studentData?.parentPhone ?? "",
                  isLiveTogether: isLiveTogether ?? "",

                  graduatedCountry: studentData?.comingFrom?.graduatedCountry ?? "",
                  graduatedFromProvince: studentData?.comingFrom?.graduatedProvince ?? "",
                  graduatedAccessMentYear: studentData?.comingFrom?.graduatedAccessMentYear ?? "",
                  endStudy: studentData?.comingFrom?.graduatedSchool ?? "",

                  graduatedCountryPay: studentData?.payTuition?.graduatedCountry ?? "",
                  graduatedFromProvincePay: studentData?.payTuition?.graduatedProvince ?? "",
                  graduatedAccessMentYearPay: studentData?.payTuition?.graduatedAccessMentYear ?? "",
                  endStudyPay: studentData?.payTuition?.graduatedSchool ?? "",

                  scholarshipAgreementNo: studentData?.scholarshipAgreementNo ?? "",
                  dateScholarshipAgreementNo:
                    formatDateDash(studentData?.dateScholarshipAgreementNo) ??
                    null,
                  transferLetterNo: studentData?.transferLetterNo ?? "",
                  dateTransferLetterNo:
                    formatDateDash(studentData?.dateTransferLetterNo) ?? null,
                }}
                validate={(values) => {
                  checkpassword(values.password);
                  checkLength(values.password);
                }}
                onSubmit={async (values) => {
                  if (!values.yearLevel) delete values.yearLevel;
                  if (!values.email) delete values.email;
                  if (!values.phone) delete values.phone;
                  if (!values.password) delete values.password;
                  if (values.faculty) {

                    values = {
                      ...values,
                      faculty: values.faculty,
                    };
                    // delete values.faculty;
                  } else {
                    values = {
                      ...values,
                      faculty: facultyId
                    };
                    // delete values.faculty;
                  }
                  if (values.department) {
                    values = {
                      ...values,
                      department: values.department,
                    };
                  } else {
                    values = {
                      ...values,
                      department: departmentId,
                    };
                  }
                  if (values.classRoom && classroomId) {

                    values = {
                      ...values,
                      classRoom: classroomId
                    }
                  } else {
                    delete values.classRoom
                  }

                  if (values.education) {

                    values = {
                      ...values,
                      educationLevel: values.education,
                    };
                    delete values.education;
                  } else {
                    delete values.education;
                  }
                  if (values.personalType) {
                    values = { ...values, personalType: values.personalType };
                  } else {
                    delete values.personalType;
                  }
                  if (values.religional) {
                    // let religion =
                    values = {
                      ...values,
                      religion: values.religional,
                    };
                    delete values.religional;
                  } else {
                    delete values.religional;
                  }
                  if (values.tribeMasters) {
                    let tribe = values.tribeMasters;
                    values = {
                      ...values,
                      tribe: tribe,
                    };
                    delete values.tribeMasters;
                  } else {
                    delete values.tribeMasters;
                  }
                  if (isChecked === false) {
                    delete values.scolarship;
                  }
                  if (values.relationship || values.isLiveTogether) {
                    let emergencyContact = {
                      relationship: values.relationship,
                      isLiveTogether: isLiveTogether
                        ? String(isLiveTogether)
                        : "false"
                    };
                    values = { ...values, emergencyContact: emergencyContact, };
                    delete values.relationship;
                    delete values.isLiveTogether;
                  } else {
                    delete values.relationship;
                    delete values.isLiveTogether;
                  }
                  if (selectCurrentProvince) {
                    if (studentType === "ນັກຮຽນຕ່າງປະເທດ") {
                      let address = {
                        country: values.currentCountry,
                        province: values.currentProvince,
                        district: values.currentDistrict,
                        village: values.village,
                      };

                      values = {
                        ...values,
                        yearLevel: values.yearLevel.toString(),
                        address: address,
                      };
                      delete values.currentCountry;
                      delete values.currentProvince;
                      delete values.currentDistrict;
                      delete values.village;
                    } else {
                      let address = {
                        province: selectCurrentProvince,
                        district: selectCurrentDistrict,
                        village: values.village,
                      };

                      values = {
                        ...values,
                        yearLevel: values.yearLevel.toString(),
                        address: address,
                      };
                      delete values.currentCountry;
                      delete values.currentProvince;
                      delete values.currentDistrict;
                      delete values.village;
                    }
                  }

                  if (studentType === "ນັກຮຽນຕ່າງປະເທດ") {
                    let birthAddress = {
                      country: values.birthCountry,
                      province: values.birthProvince,
                      district: values.birthDistrict,
                      village: values.villages,
                    };
                    values = {
                      ...values,
                      birthAddress: birthAddress,
                    };
                    delete values.birthCountry;
                    delete values.birthProvince;
                    delete values.birthDistrict;
                    delete values.villages;
                  }

                  if (selectBirthProvince || selectBirthProvince !== "") {
                    let birthAddress = {
                      province: selectBirthProvince,
                      district: selectBirthDistrict,
                      village: values.villages,
                    };
                    values = {
                      ...values,
                      birthAddress: birthAddress,
                      bornAbroad: ""
                    };
                  }
                  if (isBornAbroad === true) {
                    let birthAddress = {
                      country: values.birthCountry,
                      province: values.birthProvince,
                      district: values.birthDistrict,
                      village: values.villages,
                    };
                    values = {
                      ...values,
                      birthAddress: birthAddress,
                      bornAbroad: "true"
                    };
                  }

                  if (clickCheckbox === false) {
                    if (studentType === "ນັກຮຽນຕ່າງປະເທດ") {
                      let payTuition = {
                        graduatedCountry: values.graduatedCountryPay,
                        graduatedProvince: values.graduatedFromProvincePay,
                        graduatedSchool: values.endStudyPay,
                        graduatedAccessMentYear: values.graduatedAccessMentYearPay
                      };
                      values = {
                        ...values,
                        studentType: "OUT_PLAN_EN",
                        payTuition: payTuition,
                        comingFrom: {
                          graduatedAccessMentYear: "",
                          graduatedCountry: "",
                          graduatedProvince: "",
                          graduatedSchool: "",
                        },
                        assessmentYear: values.assessmentYear,
                      };
                      // delete values.assessmentYear;
                      delete values.graduatedCountry;
                      delete values.graduatedFromProvince;
                      delete values.graduatedCountryPay;
                      delete values.graduatedFromProvincePay;
                      delete values.graduatedAccessMentYearPay;
                      delete values.endStudyPay;
                      delete values.graduatedAccessMentYear;
                      delete values.endStudy;
                    } else {
                      let payTuition = {
                        graduatedCountry: "",
                        graduatedProvince: selectComeFormProvince,
                        graduatedSchool: values.endStudyPay,
                        graduatedAccessMentYear: values.graduatedAccessMentYearPay,
                      };
                      values = {
                        ...values,
                        studentType: "OUT_PLAN",
                        payTuition: payTuition,
                        comingFrom: {
                          graduatedAccessMentYear: "",
                          graduatedCountry: "",
                          graduatedProvince: "",
                          graduatedSchool: "",
                        },
                        assessmentYear: values.assessmentYear,
                      };
                      // delete values.assessmentYear;
                      delete values.graduatedCountry;
                      delete values.graduatedFromProvince;
                      delete values.graduatedCountryPay;
                      delete values.graduatedFromProvincePay;
                      delete values.graduatedAccessMentYearPay;
                      delete values.endStudyPay;
                      delete values.graduatedAccessMentYear;
                      delete values.endStudy;
                    }
                  } else {
                    if (studentType === "ນັກຮຽນຕ່າງປະເທດ") {
                      let comingFrom = {
                        graduatedCountry: values.graduatedCountry,
                        graduatedProvince: values.graduatedFromProvince,
                        graduatedSchool: values.endStudy,
                        graduatedAccessMentYear: values.graduatedAccessMentYear,
                      };
                      values = {
                        ...values,
                        studentType: "IN_PLAN_EN",
                        comingFrom: comingFrom,
                        payTuition: {
                          graduatedAccessMentYear: "",
                          graduatedCountry: "",
                          graduatedProvince: "",
                          graduatedSchool: "",
                        },
                        assessmentYear: values.assessmentYear,
                      };
                      // delete values.assessmentYear;
                      delete values.graduatedCountry;
                      delete values.graduatedFromProvince;
                      delete values.graduatedCountryPay;
                      delete values.graduatedFromProvincePay;
                      delete values.graduatedAccessMentYear;
                      delete values.endStudy;
                      delete values.graduatedAccessMentYearPay;
                      delete values.endStudyPay;
                    } else {
                      let comingFrom = {
                        graduatedCountry: "",
                        graduatedProvince: selectComeFormProvince,
                        graduatedSchool: values.endStudy,
                        graduatedAccessMentYear: values.graduatedAccessMentYear,
                      };
                      values = {
                        ...values,
                        studentType: "IN_PLAN",
                        comingFrom: comingFrom,
                        payTuition: {
                          graduatedCountry: "",
                          graduatedAccessMentYear: "",
                          graduatedProvince: "",
                          graduatedSchool: "",
                        },
                        assessmentYear: values.assessmentYear,
                      };
                      // delete values.assessmentYear;
                      delete values.graduatedCountry;
                      delete values.graduatedFromProvince;
                      delete values.graduatedCountryPay;
                      delete values.graduatedFromProvincePay;
                      delete values.graduatedAccessMentYear;
                      delete values.endStudy;
                      delete values.graduatedAccessMentYearPay;
                      delete values.endStudyPay;
                    }
                  }
                  if (clickCheckbox !== true) {
                    values = {
                      ...values,
                      scholarship: "NO",
                    };
                  }
                  delete values.currentCountry;
                  delete values.currentProvince;
                  delete values.currentDistrict;
                  delete values.village;

                  delete values.birthCountry;
                  delete values.birthProvince;
                  delete values.birthDistrict;
                  delete values.villages;

                  delete values.graduatedCountry;
                  delete values.graduatedFromProvince;
                  delete values.graduatedCountryPay;
                  delete values.graduatedFromProvincePay;

                  delete values.graduatedAccessMentYearPay;
                  delete values.graduatedAccessMentYear;
                  delete values.endStudyPay;
                  delete values.endStudy;

                  let fileName = null;
                  if (coverImage) {
                    const res = await _uploadFile();
                    fileName = res?.request?.responseURL
                      ?.split("?")[0]
                      .split("/")[4];
                    values = {
                      ...values,
                      image: {
                        url: fileName,
                      },
                    };
                  }

                  let data = {
                    ...values,
                    scholarship: studentType === "ນັກຮຽນຕ່າງປະເທດ" && clickCheckbox === false
                      ? values.outPlanScholarship
                      : values.scholarship,
                    scholarshipAgreementNo: values.scholarshipAgreementNo,
                    dateScholarshipAgreementNo:
                      values.dateScholarshipAgreementNo ?? null,
                    transferLetterNo: values.transferLetterNo,
                    dateTransferLetterNo: values.dateTransferLetterNo ?? null,
                    _assessmentYear: values.assessmentYear
                  };
                  delete data.outPlanScholarship;
                  let paramQL = { data };
                  setFormParam(paramQL);
                  _handleShowAddConfirmModalShow();
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form>
                    <Row>
                      <Col sm={12}>
                        <h5>
                          <strong>ແກ້ໄຂນັກສຶກສາ</strong>
                        </h5>
                        <hr />
                      </Col>
                      <Col sm={12}>
                        <img
                          alt=""
                          src={
                            previewImageURL ===
                              "https://nuol.s3.ap-southeast-1.amazonaws.com/student-image/undefined"
                              ? "https://www.seekpng.com/png/detail/414-4140251_you-profile-picture-question-mark.png"
                              : previewImageURL
                                ? previewImageURL
                                : "https://www.seekpng.com/png/detail/414-4140251_you-profile-picture-question-mark.png"
                          }
                          className="browserImage"
                        />
                        <input
                          type="file"
                          id="inputImage"
                          multiple
                          name="image"
                          ref={inputImage}
                          style={{ display: "none" }}
                          onChange={_handleChangeImage}
                        />
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ອັບໂຫຼດຮູບຈາກເຄື່ອງຂອງທ່ານ
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="outline-secondary"
                            onClick={_addImage}
                            style={{ marginLeft: 20, width: 140 }}
                          >
                            <i className="fa fa-download"></i> ອັບໂຫຼດຮູບ
                          </Button>
                        </OverlayTrigger>

                        <Row>
                          <Col sm={12}>
                            <div className="heading">
                              <b>ກະລຸນາປ້ອນລະຫັດນັກສຶກສາ</b>
                            </div>
                          </Col>
                        </Row>
                        <div className="form-group">
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນລະຫັດນັກສຶກສາ"
                            name="studentNumber"
                            value={values?.studentNumber}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div className="heading">
                          <b>ຂໍ້ມູນນັກສຶກສາ</b>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>ຄະນະ {valiDate()}</label>
                        {dataFaculties && (
                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              _selectFacalty(e);
                              handleChange(e);
                            }}
                            name="faculty"
                            value={values.faculty}
                            disabled={role !== "ADMIN" && role !== "DEAN" ? true : false}
                          >
                            <option disabled={true} value="">
                              ---ກະລຸນາເລືອກຄະນະ---
                            </option>
                            {dataFaculties?.map((x, facIndex) => (
                              <option key={facIndex} value={x?.id}>
                                {x.name}
                              </option>
                            ))}
                          </Form.Control>
                        )}
                      </Col>
                      <Col>
                        <label>ພາກວິຊາ {valiDate()}</label>
                        <Form.Control
                          as="select"
                          name="department"
                          value={values.department}
                          onChange={(e) => {
                            handleChange(e);
                            _selectDepartment(e);
                          }}
                        >
                          <option value="">---ກະລຸນາເລືອກສາຂາວິຊາ---</option>
                          {dataDepartment?.map(
                            (x, depIndex) => (
                              <option key={depIndex} value={x?.id}>
                                {x.name}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </Col>
                      <Col>
                        <label>ສາຂາວິຊາ {valiDate()}</label>
                        <Form.Control
                          as="select"
                          name="major"
                          value={values.major}
                          onChange={(e) => {
                            handleChange(e);
                            _selectMajor(e);
                          }}
                        >
                          <option value="">---ກະລຸນາເລືອກສາຂາວິຊາ---</option>
                          {dataMajors?.map((x, index) => (
                            <option key={index} value={x?.id}>{x.name}</option>
                          ))}
                        </Form.Control>
                      </Col>
                      <Col sm={2}>
                        <div className="form-group">
                          <label>ສົກສຶກສາ {valiDate()}</label>

                          <Form.Control
                            as="select"
                            name="assessmentYear"
                            value={values.assessmentYear}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            <option disabled={true} value="">
                              ---ກະລຸນາສົກຮຽນ---
                            </option>
                            {assessmentYear.map((x, asseIndex) => {
                              return <option key={asseIndex} value={x}>{x}</option>;
                            })}
                          </Form.Control>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-group">
                          <label>ປີຮຽນ {valiDate()}</label>

                          <Form.Control
                            as="select"
                            name="yearLevel"
                            value={values.yearLevel}
                            onChange={(e) => {
                              handleChange(e)
                              setYear(e?.target?.value)
                            }}
                          >
                            <option disabled={true} value="">
                              ---ກະລຸນາປີຮຽນ---
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                          </Form.Control>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2}>
                        <div className="form-group">
                          <strong>ຂໍ້ມູນນັກສຶກສາ {valiDate()}</strong>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={(e) => {
                              handleChange(e)
                              _selectPersonalType(e)
                            }}
                            name="personalType"
                            value={values.personalType}
                          >
                            <option value="">ເລືອກຂໍ້ມູນນັກສຶກສາ</option>
                            {PersonalTypeData?.personalTypeMasters?.data?.map(
                              (PersonalType, index) => (
                                <option
                                  key={"PersonalType" + index}
                                  value={PersonalType?.id}
                                >
                                  {PersonalType?.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2}>
                        <div className="form-group">
                          <strong>ລະດັບສຶກສາ {valiDate()}</strong>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name="education"
                            value={values.education}
                          >
                            <option value="">ເລືອກລະດັບສຶກສາ</option>
                            {EducationData?.educationLevelMasters?.data?.map(
                              (Education, index) => (
                                <option
                                  key={"Education" + index}
                                  value={Education?.id}
                                >
                                  {Education?.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2}>
                        <div className="form-group">
                          <strong>ຫ້ອງຮຽນ {valiDate()}</strong>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={(e) => {
                              handleChange(e);
                              _selectClassroom(e);
                            }}
                            name="classRoom"
                            value={values.classRoom}
                          >
                            <option value="">ເລືອກຫ້ອງຮຽນ</option>
                            {dataClassroom?.map(
                              (x, index) => (
                                <option key={index} value={x?.id}>
                                  {x.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2}>
                        <div className="form-group">
                          <strong>ສະຖານະການຮຽນ {valiDate()}</strong>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <select
                            className="form-control"
                            onChange={handleChange}
                            name="studyStatus"
                            value={values.studyStatus}
                          >
                            <option value="">ເລືອກສະຖານະການຮຽນ</option>
                            <option value="STUDYING">ກຳລັງສຶກສາ</option>
                            <option value="DROP">ໂຈະການຮຽນ</option>
                            <option value="DONT_STUDY">ປະລະການຮຽນ</option>
                            <option value="GRADUATED">ຈົບການສຶກສາ</option>
                          </select>
                        </div>
                      </Col>
                    </Row>

                    {/* ---------- ໄອດີແລະລະຫັດຜ່ານ --------- */}
                    <Row>
                      <Col sm={12}>
                        <div className="heading">
                          <b>ໄອດີແລະລະຫັດຜ່ານ</b>
                        </div>
                      </Col>
                    </Row>
                    {/* ໄອດີ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16,
                      }}
                    >
                      <Form.Label column sm="4" className="text-left">
                        ໄອດີ
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder="ກະລຸນາປ້ອນ"
                          name="userId"
                          value={values?.userId}
                          onChange={handleChange}
                          isInvalid={!!errors.userId}
                        />
                      </Col>
                    </Form.Group>
                    {/* ລະຫັດຜ່ານ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16,
                      }}
                    >
                      <Form.Label column sm="4" className="text-left">
                        ລະຫັດຜ່ານ
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder="ກະລຸນາປ້ອນ"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                        />
                        <div>
                          {/* <ProgressBar variant="progress-bar-low" now={100} /> */}
                          <Progress multi>
                            <Progress
                              bar
                              color="danger"
                              value={strengthPass >= 25 ? 25 : 0}
                            >
                              {strengthPass < 25 ? "" : "ຕ່ຳ"}
                            </Progress>
                            <Progress
                              bar
                              color="warning"
                              value={strengthPass >= 50 ? 25 : 0}
                            >
                              {strengthPass < 50 ? "" : "ກາງ"}
                            </Progress>
                            <Progress
                              bar
                              color="info"
                              value={strengthPass >= 75 ? 25 : 0}
                            >
                              {strengthPass < 75 ? "" : "ສູງ"}
                            </Progress>
                            <Progress
                              bar
                              color="success"
                              value={strengthPass >= 100 ? 25 : 0}
                            >
                              {strengthPass < 100 ? "" : "ສຸດຍອດ"}
                            </Progress>
                          </Progress>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                        {checkPasswordLength ? (
                          <p style={{ color: "red", fontSize: 12 }}>
                            {checkPasswordLength}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Form.Group>

                    {/* ຍືນຍັນລະຫັດຜ່ານ */}
                    <Form.Group
                      as={Row}
                      style={{
                        margin: 0,
                        marginBottom: 10,
                        paddingLeft: 20,
                        fontSize: 16,
                      }}
                    >
                      <Form.Label column sm="4" className="text-left">
                        ຍືນຍັນລະຫັດຜ່ານ
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder="ກະລຸນາປ້ອນ"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          isInvalid={!!errors.confirmPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.confirmPassword}
                        </Form.Control.Feedback>
                      </Col>
                    </Form.Group>

                    <Row>
                      <Col sm={12}>
                        <br />
                        <div className="heading">
                          <b>ຂໍ້ມູນທົ່ວໄປ</b>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <div className="form-group">
                          <label>ຊື່ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນຊື່"
                            name="firstNameL"
                            value={values.firstNameL}
                            onChange={handleChange}
                            isInvalid={!!errors.firstNameL}
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="form-group">
                          <Form.Label>ນາມສະກຸນ {valiDate()}</Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນນາມສະກຸນ"
                            name="lastNameL"
                            value={values.lastNameL}
                            onChange={handleChange}
                            isInvalid={!!errors.lastNameL}
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="form-group">
                          <Form.Label>ຊື່(ອັງກິດ) {valiDate()}</Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນຊື່(ອັງກິດ)"
                            name="firstNameE"
                            value={values.firstNameE}
                            onChange={handleChange}
                            isInvalid={!!errors.firstNameE}
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="form-group">
                          <label>ນາມສະກຸນ(ອັງກິດ) {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນນາມສະກຸນ(ອັງກິດ)"
                            name="lastNameE"
                            value={values.lastNameE}
                            onChange={handleChange}
                            isInvalid={!!errors.lastNameE}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <label>ວັນເດືອນປີເກີດ {valiDate()}</label>
                        <div className="form-group">
                          <Form.Control
                            type="date"
                            className="form-control"
                            placeholder="ວັນທີ"
                            name="birthday"
                            value={values.birthday}
                            onChange={handleChange}
                            isInvalid={!!errors.birthday}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1.5} style={{ marginLeft: 13 }}>
                        <label>ເລືອກເພດ {valiDate()}</label>
                      </Col>
                      <Col sm={2}>
                        <div className="form-group">
                          <Form.Check
                            inline
                            label="ຊາຍ"
                            type="radio"
                            value="MALE"
                            id="gender-radio"
                            name="gender"
                            onChange={handleChange}
                            defaultChecked={values.gender === "MALE" ? true : false}
                          />
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="form-group">
                          <Form.Check
                            inline
                            label="ຍິງ"
                            type="radio"
                            value="FEMALE"
                            id="gender-radio"
                            name="gender"
                            onChange={handleChange}
                            defaultChecked={values.gender === "FEMALE" ? true : false}
                          />
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="form-group">
                          <Form.Check
                            inline
                            label="ພຣະ"
                            type="radio"
                            value="MONK"
                            id="gender-radio"
                            name="gender"
                            onChange={handleChange}
                            defaultChecked={values.gender === "MONK" ? true : false}
                          />
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="form-group">
                          <Form.Check
                            inline
                            label="ສຳມະເນນ"
                            type="radio"
                            value="OTHER"
                            id="gender-radio"
                            name="gender"
                            onChange={handleChange}
                            defaultChecked={values.gender === "OTHER" ? true : false}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1.5} style={{ marginLeft: 13 }}>
                        <label>ສະຖານະ {valiDate()}</label>
                      </Col>
                      <Col sm={2}>
                        <div className="form-group">
                          <Form.Check
                            inline
                            label="ໂສດ"
                            type="radio"
                            value="SINGLE"
                            id="maritualStatus-radio"
                            name="maritualStatus"
                            onChange={handleChange}
                            defaultChecked={
                              values.maritualStatus === "SINGLE" ? true : false
                            }
                          />
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="form-group">
                          <Form.Check
                            inline
                            label="ແຕ່ງງານແລ້ວ"
                            type="radio"
                            value="MARRIAGE"
                            id="maritualStatus-radio"
                            name="maritualStatus"
                            onChange={handleChange}
                            defaultChecked={
                              values.maritualStatus === "MARRIAGE" ? true : false
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    {studentType === "ນັກຮຽນຕ່າງປະເທດ" ?
                      <Row>
                        <Col sm={6}>
                          <div className="form-group">
                            <label>ສັນຊາດ {valiDate()}</label>
                            <Form.Control
                              as="select"
                              name="nationality"
                              value={values.nationality}
                              onChange={handleChange}
                            >
                              <option value="">ເລືອກ</option>
                              {COUNTRIES_LIST.map((nationalities, index) => (
                                <option
                                  key={"nationalities" + index}
                                  value={nationalities?.nationality}
                                >
                                  {nationalities?.nationality}
                                </option>
                              ))}
                            </Form.Control>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="form-group">
                            <label>ສາສະໜາ {valiDate()}</label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              name="religional"
                              value={values.religional}
                            >
                              <option value="">ເລືອກ</option>
                              {religionalMasterData?.religionalMasters?.data?.map(
                                (religional, index) => (
                                  <option
                                    key={"religional" + index}
                                    value={religional?.id}
                                  >
                                    {religional?.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </Col>
                        {/* religionalMasterData?.religionalMasters */}
                      </Row>
                      :
                      <Row>
                        <Col sm={4}>
                          <div className="form-group">
                            <label>ສັນຊາດ {valiDate()}</label>
                            <Form.Control
                              as="select"
                              name="nationality"
                              value={values.nationality}
                              onChange={handleChange}
                            >
                              <option value="">ເລືອກ</option>
                              <option value="ລາວ">ລາວ</option>
                              <option value="ໄທ">ໄທ</option>
                              <option value="ຈີນ">ຈີນ</option>
                              <option value="ຍີ່ປຸ່ນ">ຍີ່ປຸ່ນ</option>
                              <option value="ເກົາຫຼີ">ເກົາຫຼີ</option>
                              <option value="ຫວຽດ">ຫວຽດ</option>
                            </Form.Control>
                          </div>
                        </Col>
                        {/* tribeMasterData */}
                        <Col sm={4}>
                          <div className="form-group">
                            <label>ຊົນເຜົ່າ {valiDate()}</label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              name="tribeMasters"
                              value={values.tribeMasters}
                            >
                              <option value="">ເລືອກ</option>
                              {tribeMasterData?.tribeMasters?.data?.map(
                                (tribeMasters, index) => (
                                  <option
                                    key={"tribeMasters" + index}
                                    value={tribeMasters.id}
                                  >
                                    {tribeMasters.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="form-group">
                            <label>ສາສະໜາ {valiDate()}</label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              name="religional"
                              value={values.religional}
                            >
                              <option value="">ເລືອກ</option>
                              {religionalMasterData?.religionalMasters?.data?.map(
                                (religional, index) => (
                                  <option
                                    key={"religional" + index}
                                    value={religional?.id}
                                  >
                                    {religional?.name}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </Col>
                        {/* religionalMasterData?.religionalMasters */}
                      </Row>
                    }
                    <Row>
                      <Col sm={4}>
                        <div className="form-group">
                          <label>ເບີໂທເຮືອນ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນເບີເຮືອນ"
                            name="phoneHome"
                            value={values.phoneHome}
                            onChange={handleChange}
                            isInvalid={!!errors.phoneHome}
                          />
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <label>ເບີໂທລະສັບມືຖື {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນເບີໂທລະສັບມືຖື"
                            name="phoneMobile"
                            value={values.phoneMobile}
                            onChange={handleChange}
                            isInvalid={!!errors.phoneMobile}
                          />
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <label>ອີເມວ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນອີເມວ"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div className="heading">
                          <b>ທີ່ຢູ່ປັດຈຸບັນ</b>
                        </div>
                      </Col>
                    </Row>
                    {studentType === "ນັກຮຽນຕ່າງປະເທດ" ?
                      <Row>
                        <Col sm={3}>
                          <div className="form-group">
                            <label>ປະເທດ {valiDate()}</label>
                            <select
                              className="form-control"
                              name="currentCountry"
                              value={values.currentCountry}
                              onChange={(e) => {
                                // _selectCurrentProvince(e);
                                handleChange(e);
                              }}
                            >
                              <option value="">---ເລືອກປະເທດ---</option>
                              {COUNTRIES_LIST.map((countries, index) => (
                                <option
                                  key={"countries" + index}
                                  value={countries?.en_short_name}
                                >
                                  {countries?.en_short_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="form-group">
                            <label>ແຂວງ {valiDate()}</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="ກະລຸນາປ້ອນແຂວງ"
                              name="currentProvince"
                              value={values.currentProvince}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="form-group">
                            <label>ເມືອງ {valiDate()}</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="ກະລຸນາປ້ອນເມືອງ"
                              // name="selectCurrentDistrict"
                              name="currentDistrict"
                              value={values.currentDistrict}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="form-group">
                            <label>ບ້ານ {valiDate()}</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="ກະລຸນາປ້ອນບ້ານ"
                              name="village"
                              value={values.village}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      :
                      <Row>
                        <Col sm={4}>
                          <div className="form-group">
                            <label>ແຂວງ {valiDate()}</label>
                            <select
                              className="form-control"
                              value={selectCurrentProvince}
                              onChange={(e) => {
                                _selectCurrentProvince(e);
                                handleChange(e);
                              }}
                            >
                              <option value="">---ເລືອກແຂວງ---</option>
                              {LAO_ADDRESS.map((province, index) => (
                                <option
                                  key={"province" + index}
                                  value={province.province_name}
                                >
                                  {province?.province_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="form-group">
                            <label>ເມືອງ {valiDate()}</label>
                            <Form.Control
                              as="select"
                              className="form-control"
                              // name="district"
                              value={selectCurrentDistrict}
                              onChange={(e) => {
                                _selectCurrentDistrict(e);
                                handleChange(e);
                              }}
                            >
                              <option disabled={true} value="">
                                ---ເລືອກ---
                              </option>
                              {selectCurrentProvinceIndex > -1 &&
                                LAO_ADDRESS[
                                  selectCurrentProvinceIndex
                                ].district_list.map((district, index) => (
                                  <option
                                    key={"current-district" + index}
                                    value={district.district}
                                  >
                                    {district.district}
                                  </option>
                                ))}
                            </Form.Control>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="form-group">
                            <label>ບ້ານ {valiDate()}</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="ກະລຸນາປ້ອນບ້ານ"
                              name="village"
                              value={values.village}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                      </Row>
                    }
                    <Row>
                      <Col sm={12}>
                        <div className="heading">
                          <b>ສະຖານທີເກີດ</b>
                        </div>
                      </Col>
                    </Row>
                    {studentType === "ນັກຮຽນຕ່າງປະເທດ" ?
                      <Row>
                        <Col sm={3}>
                          <div className="form-group">
                            <label>ປະເທດ {valiDate()}</label>
                            <select
                              className="form-control"
                              name="birthCountry"
                              value={values.birthCountry}
                              onChange={(e) => {
                                // _selectCurrentProvince(e);
                                handleChange(e);
                              }}
                            >
                              <option value="">---ເລືອກປະເທດ---</option>
                              {COUNTRIES_LIST.map((countries, index) => (
                                <option
                                  key={"countries" + index}
                                  value={countries?.en_short_name}
                                >
                                  {countries?.en_short_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="form-group">
                            <label>ແຂວງ</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="ກະລຸນາປ້ອນແຂວງ"
                              name="birthProvince"
                              value={values.birthProvince}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="form-group">
                            <label>ເມືອງ</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="ກະລຸນາປ້ອນເມືອງ"
                              name="birthDistrict"
                              value={values.birthDistrict}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="form-group">
                            <label>ບ້ານ {valiDate()}</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="ກະລຸນາປ້ອນບ້ານ"
                              name="villages"
                              value={values.villages}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      :
                      <>
                        <Row>
                          <Col sm="12">
                            <Form.Check
                              type="checkbox"
                              label="ນັກສຶກສາເກີດໃນຕ່າງປະເທດ"
                              inline
                              value={isBornAbroad}
                              defaultChecked={isBornAbroad}
                              onClick={() => setIsBornAbroad(!isBornAbroad)}
                            />
                          </Col>
                        </Row>
                        <Row>
                          {isBornAbroad ?
                            <Row>
                              <Col sm={3}>
                                <div className="form-group">
                                  <label>ປະເທດ {valiDate()}</label>
                                  <select
                                    className="form-control"
                                    name="birthCountry"
                                    value={values.birthCountry}
                                    onChange={handleChange}
                                  >
                                    <option value="">---ເລືອກປະເທດ---</option>
                                    {COUNTRIES_LIST.map((countries, index) => (
                                      <option
                                        key={"countries" + index}
                                        value={countries?.en_short_name}
                                      >
                                        {countries?.en_short_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              <Col sm={3}>
                                <div className="form-group">
                                  <label>ແຂວງ {valiDate()}</label>
                                  <Form.Control
                                    type="text"
                                    className="form-control"
                                    placeholder="ກະລຸນາປ້ອນແຂວງ"
                                    name="birthProvince"
                                    value={values.birthProvince}
                                    onChange={handleChange}
                                  />
                                </div>
                              </Col>
                              <Col sm={3}>
                                <div className="form-group">
                                  <label>ເມືອງ {valiDate()}</label>
                                  <Form.Control
                                    type="text"
                                    className="form-control"
                                    placeholder="ກະລຸນາປ້ອນເມືອງ"
                                    name="birthDistrict"
                                    value={values.birthDistrict}
                                    onChange={handleChange}
                                  />
                                </div>
                              </Col>
                              <Col sm={3}>
                                <div className="form-group">
                                  <label>ບ້ານ {valiDate()}</label>
                                  <Form.Control
                                    type="text"
                                    className="form-control"
                                    placeholder="ກະລຸນາປ້ອນບ້ານ"
                                    name="villages"
                                    value={values.villages}
                                    onChange={handleChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                            :
                            <>
                              <Col sm={4}>
                                <div className="form-group">
                                  <label>ແຂວງ</label>
                                  <select
                                    className="form-control"
                                    onChange={(e) => {
                                      _selectBirthProvince(e);
                                      handleChange(e);
                                    }}
                                    value={selectBirthProvince}
                                  >
                                    <option value="">---ເລືອກແຂວງ---</option>
                                    {LAO_ADDRESS.map((province, index) => (
                                      <option
                                        key={"province" + index}
                                        value={province.province_name}
                                      >
                                        {province?.province_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div className="form-group">
                                  <label>ເມືອງ</label>
                                  <Form.Control
                                    as="select"
                                    // name="district"
                                    value={selectBirthDistrict}
                                    onChange={(e) => {
                                      _selectBirthDistrict(e);
                                      handleChange(e);
                                    }}
                                  >
                                    <option disabled={true} value="">
                                      ---ເລືອກ---
                                    </option>
                                    {selectBirthProvinceIndex > -1 &&
                                      LAO_ADDRESS[
                                        selectBirthProvinceIndex
                                      ].district_list.map((district, index) => (
                                        <option
                                          key={"birth-district" + index}
                                          value={district.district}
                                        >
                                          {district.district}
                                        </option>
                                      ))}
                                  </Form.Control>
                                </div>
                              </Col>
                              <Col sm={4}>
                                <div className="form-group">
                                  <label>ບ້ານ {valiDate()}</label>
                                  <Form.Control
                                    type="text"
                                    className="form-control"
                                    placeholder="ກະລຸນາປ້ອນບ້ານ"
                                    name="villages"
                                    value={values.villages}
                                    onChange={handleChange}
                                  />
                                </div>
                              </Col>
                            </>
                          }
                        </Row>
                      </>

                    }
                    <Row>
                      <Col sm={4} style={{ marginTop: 20 }}>
                        <div className="form-group">
                          <Form.Check
                            inline
                            label="ທຶນແບ່ງປັນຈາກພາກສ່ວນ"
                            type="checkbox"
                            value={false}
                            onClick={(e) => _selectScholarship()}
                            defaultChecked={clickCheckbox}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div
                          className="heading"
                          style={{
                            display: clickCheckbox === true ? "flex" : "none",
                          }}
                        >
                          <b>ນັກສຶກສາ/ພະນັກງານ ທຶນແບ່ງປັນຈາກພາກສ່ວນ</b>
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginTop: 22,
                        display: clickCheckbox === true ? "flex" : "none",
                      }}
                      disabled={true}
                    >
                      {studentType === "ນັກຮຽນຕ່າງປະເທດ" ?
                        <>
                          <Col sm="12" style={{ marginTop: 20 }}>
                            <Form.Control
                              as="select"
                              name="scholarship"
                              value={values.scholarship}
                              isInvalid={errors.scholarship}
                              onChange={(e) => {
                                selectScholarship(e);
                                handleChange(e);
                              }}
                            >
                              <option value="">---ເລືອກ---</option>
                              <option value="NUOL_SCHOLARSHIP">
                                NUOL's talent student
                              </option>
                              <option value="ETHNIC_STUDENT_OF_PROVINCIAL">
                                Provincial ethnic student
                              </option>
                              <option value="RED_DIPLOMA_SCHOLARSHIP">
                                First-class honours
                              </option>
                              <option value="ANTIONAL_STUDENT_SCHOLARSHIP">
                                Outstanding student in general subject/talent student
                              </option>
                              <option value="MINISTRY_OF_EDUCATION_AND_SPORTS">
                                Ministry of Education and Sports
                              </option>
                              <option value="OTHER_SHOLARSHIP">
                                Other scholarship
                              </option>
                            </Form.Control>
                          </Col>
                          <Col sm={3} style={{ marginTop: 20 }}>
                            <div className="form-group">
                              <label>ຈົບຈາກປະເທດ {valiDate()}</label>
                              <select
                                className="form-control"
                                name="graduatedCountry"
                                value={values.graduatedCountry}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              >
                                <option value="">---ເລືອກປະເທດ---</option>
                                {COUNTRIES_LIST.map((countries, index) => (
                                  <option
                                    key={"countries" + index}
                                    value={countries?.en_short_name}
                                  >
                                    {countries?.en_short_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </Col>
                          <Col sm="3" style={{ marginTop: 20 }}>
                            <label> ຈົບ​ຈາກແຂວງ <span className="text-danger">*</span></label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="ກະລຸນາປ້ອນແຂວງ"
                              name="graduatedFromProvince"
                              value={values.graduatedFromProvince}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col sm="3" style={{ marginTop: 20 }}>
                            <Form.Label>ຈົບ​ຈາກ​ໂຮງ​ຮຽນ</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="ກະລຸນາປ້ອນ"
                              isInvalid={errors.endStudy}
                              onChange={handleChange}
                              name="endStudy"
                              value={values.endStudy}
                            />
                          </Col>
                          <Col sm="3" style={{ marginTop: 20 }}>
                            <Form.Label>ສົກ​ສຶກ​ສາ <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                              as="select"
                              name="graduatedAccessMentYear"
                              value={values.graduatedAccessMentYear}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            >
                              <option disabled={true} value="">
                                ---ກະລຸນາສົກຮຽນ---
                              </option>
                              {assessmentYear.map((x, yearIndex) => {
                                return <option key={yearIndex} value={x}>{x}</option>;
                              })}
                            </Form.Control>
                          </Col>
                        </>
                        :
                        <>
                          <Col sm="12" style={{ marginTop: 20 }}>
                            <Form.Control
                              as="select"
                              name="scholarship"
                              value={values.scholarship}
                              isInvalid={errors.scholarship}
                              onChange={(e) => {
                                selectScholarship(e);
                                handleChange(e);
                              }}
                            >
                              <option value="">---ເລືອກ---</option>
                              <option value="ANTIONAL_STUDENT_SCHOLARSHIP">
                                ນັກ​ຮຽນ​ເກັ່ງ​ລະ​ດັບ​ຊາດ
                              </option>
                              <option value="NUOL_SCHOLARSHIP">
                                ນັກ​ຮຽນ​ເສັງ​ໄດ້​ທຶນ ມ​ຊ
                              </option>
                              <option value="RED_DIPLOMA_SCHOLARSHIP">
                                ນັກ​ຮຽນ​ເກັ່ງ​ປ​ະ​ກາ​ສະ​ນິ​ຍະ​ບັດ​ແດງ
                              </option>
                              <option value="NO_ONE_STUDENT_OF_PROVINCIAL">
                                ນັກ​ຮຽນ​ເກັ່ງ​ເສັງ​ໄດ້​ທີ່ 1 ຂອງ​ແຂວງ
                              </option>
                              <option value="ETHNIC_STUDENT_OF_PROVINCIAL">
                                ນັກ​ຮຽນ​ຊົນ​ເຜົ່າ​ຂອ​ງ​ແຂວງ
                              </option>
                              <option value="EXCHANGE_STUDENT_SCHOLARSHIP">
                                ທຶນ​ແບ່ງ​ນັກ​ຮຽນ​ສາ​ມັນ
                              </option>
                              <option value="OFFICIAL_STAFF_SCHOLARSHIP">
                                ພະ​ນັກ​ງານ
                              </option>
                              <option value="OTHER_SHOLARSHIP">
                                ທຶນ​ແບ່ງ​ປັນ​ອື່ນໆ
                              </option>
                            </Form.Control>
                          </Col>
                          <Col sm="4" style={{ marginTop: 20 }}>
                            <label> ຈົບ​ຈາກແຂວງ <span className="text-danger">*</span></label>
                            <select
                              className="form-control"
                              value={selectComeFormProvince}
                              onChange={(e) => {
                                _selectComeFormProvince(e);
                                handleChange(e);
                              }}
                            >
                              <option value="">---ເລືອກແຂວງ---</option>
                              {LAO_ADDRESS.map((province, index) => (
                                <option key={"province" + index}>
                                  {province?.province_name}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col sm="4" style={{ marginTop: 20 }}>
                            <Form.Label>ຈົບ​ຈາກ​ໂຮງ​ຮຽນ</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="ກະລຸນາປ້ອນ"
                              isInvalid={errors.endStudy}
                              onChange={handleChange}
                              name="endStudy"
                              value={values.endStudy}
                            />
                          </Col>
                          <Col sm="4" style={{ marginTop: 20 }}>
                            <Form.Label>ສົກ​ສຶກ​ສາ <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                              as="select"
                              name="graduatedAccessMentYear"
                              value={values.graduatedAccessMentYear}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            >
                              <option disabled={true} value="">
                                ---ກະລຸນາສົກຮຽນ---
                              </option>
                              {assessmentYear.map((x, yearIndex) => {
                                return <option key={yearIndex} value={x}>{x}</option>;
                              })}
                            </Form.Control>
                          </Col>
                        </>
                      }
                      <Col sm="6" style={{ marginTop: 20 }}>
                        <Form.Label>ເລກທີໃບນຳສົ່ງ </Form.Label>
                        <Form.Control
                          type="text"
                          name="transferLetterNo"
                          placeholder="ກະ​ລຸ​ນາ​ ປ້ອນເລກທີໃບນຳສົ່ງ"
                          isInvalid={errors.transferLetterNo}
                          onChange={handleChange}
                          value={values.transferLetterNo}
                        />
                      </Col>
                      <Col sm="6" style={{ marginTop: 20 }}>
                        <Form.Label>ລົງ​ວັນ​ທີ່</Form.Label>
                        <Form.Control
                          type="date"
                          value={values.dateTransferLetterNo}
                          placeholder="12/02/2000"
                          name="dateTransferLetterNo"
                          onChange={handleChange}
                          isInvalid={errors.dateTransferLetterNo}
                        />
                      </Col>
                      <Col sm="6" style={{ marginTop: 20 }}>
                        <Form.Label>ເລກທີຂໍ້ຕົກລົງເສັງ​ໄດ້​ທຶນ ມ​ຊ</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="ເລກທີຂໍ້ຕົກລົງເສັງ​ໄດ້​ທຶນ ມ​ຊ"
                          name="scholarshipAgreementNo"
                          value={values.scholarshipAgreementNo}
                          isInvalid={errors.scholarshipAgreementNo}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col sm="6" style={{ marginTop: 20 }}>
                        <Form.Label>ລົງ​ວັນ​ທີ່ </Form.Label>
                        <Form.Control
                          type="date"
                          name="dateScholarshipAgreementNo"
                          isInvalid={errors.dateScholarshipAgreementNo}
                          onChange={handleChange}
                          value={values.dateScholarshipAgreementNo}
                        />
                      </Col>
                      <Col sm="12" style={{ marginTop: 20 }}>
                        <Form.Label>ທຶນ​ແບ່ງ​ປັນອື່ນໆ <span className="text-danger">*</span> </Form.Label>
                        <Form.Control
                          type="text"
                          name="otherScholarship"
                          placeholder="ກະ​ລຸ​ນາ​ປ້ອນທຶນ​ແບ່ງ​ປັນ​ອື່​ນໆ"
                          isInvalid={errors.otherScholarship}
                          onChange={handleChange}
                          value={values.otherScholarship}
                          disabled={
                            scholarship === ""
                              ? true
                              : scholarship === "OTHER_SHOLARSHIP"
                                ? false
                                : true
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div
                          className="heading"
                          style={{
                            display: clickCheckbox === true ? "none" : "flex",
                          }}
                        >
                          <b>ສຳລັບນັກສຶກສາຈ່າຍຄ່າຮຽນ</b>
                        </div>
                      </Col>
                    </Row>

                    {studentType === "ນັກຮຽນຕ່າງປະເທດ" ?
                      <Row
                        style={{ display: clickCheckbox === true ? "none" : "flex" }}
                      >
                        <Col sm={12}>
                          <Form.Control
                            as="select"
                            id="disabledSelect"
                            name="outPlanScholarship"
                            value={values.outPlanScholarship}
                            isInvalid={errors.outPlanScholarship}
                            onChange={(e) => {
                              selectScholarship(e);
                              handleChange(e);
                            }}
                          >
                            <option value="">ເລືອກທຶນ</option>
                            <option value="ORGANIZATION_IN_LAOPDR_MINISTRIES">
                              The organization in the Lao PDR(Ministries)
                            </option>
                            <option value="ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION">
                              The organization in the Lao PDR(Provincial Education Division)
                            </option>
                            <option value="HIGHER_EDUCATION_INSTITUTION_FORM_ABROAD">
                              Higher Education institution form abroad
                            </option>
                            <option value="PERSONAL_FINANCIAL">
                              Personal financial
                            </option>
                          </Form.Control>
                        </Col>
                        <Col sm={12}>
                          <Form.Control
                            type={
                              values.outPlanScholarship === "ORGANIZATION_IN_LAOPDR_MINISTRIES"
                                || values.outPlanScholarship === "ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION"
                                ? "text" : "hidden"
                            }
                            placeholder="ກະລຸນາປ້ອນລາຍລະອຽດ"
                            value={values.scholarshipFrom}
                            name="scholarshipFrom"
                            isInvalid={errors.scholarshipFrom}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col sm={3}>
                          <div className="form-group">
                            <label>ຈົບຈາກປະເທດ {valiDate()}</label>
                            <select
                              className="form-control"
                              name="graduatedCountryPay"
                              value={values.graduatedCountryPay}
                              onChange={(e) => {
                                // _selectComeFormProvince(e);
                                handleChange(e);
                              }}
                            >
                              <option value="">---ເລືອກປະເທດ---</option>
                              {COUNTRIES_LIST.map((countries, index) => (
                                <option
                                  key={"countries" + index}
                                  value={countries?.en_short_name}
                                >
                                  {countries?.en_short_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="form-group">
                            <label>ຈົບຈາກແຂວງ {valiDate()}</label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              placeholder="ກະລຸນາປ້ອນແຂວງ"
                              name="graduatedFromProvincePay"
                              value={values.graduatedFromProvincePay}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="form-group">
                            <label>ຈົບຈາກໂຮງຮຽນ {valiDate()}</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              name="endStudyPay"
                              value={values.endStudyPay}
                              placeholder="ກະລຸນາປ້ອນ"
                            />
                          </div>
                        </Col>
                        <Col sm={3}>
                          <div className="form-group">
                            <label>ສົກສຶກສາ {valiDate()}</label>
                            <Form.Control
                              as="select"
                              name="graduatedAccessMentYearPay"
                              value={values.graduatedAccessMentYearPay}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            >
                              <option disabled={true} value="">
                                ---ກະລຸນາເລືອກສົກຮຽນ---
                              </option>
                              {graduatedYear.map((x, gradIndex) => {
                                return <option key={gradIndex} value={x}>{x}</option>;
                              })}
                            </Form.Control>
                          </div>
                        </Col>
                      </Row>
                      :
                      <Row
                        style={{ display: clickCheckbox === true ? "none" : "flex" }}
                      >
                        <Col sm={4}>
                          <div className="form-group">
                            <label>ຈົບຈາກແຂວງ</label>
                            <select
                              className="form-control"
                              value={selectComeFormProvince}
                              onChange={(e) => {
                                _selectComeFormProvince(e);
                                handleChange(e);
                              }}
                            >
                              <option value="">---ເລືອກແຂວງ---</option>
                              {LAO_ADDRESS.map((province, index) => (
                                <option
                                  key={"province" + index}
                                  value={province.province_name}
                                >
                                  {province?.province_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="form-group">
                            <label>ຈົບຈາກໂຮງຮຽນ {valiDate()}</label>
                            <input
                              type="text"
                              className="form-control"
                              onChange={handleChange}
                              name="endStudyPay"
                              value={values.endStudyPay}
                              placeholder="ກະລຸນາປ້ອນ"
                            />
                          </div>
                        </Col>
                        <Col sm={4}>
                          <div className="form-group">
                            <label>ສົກສຶກສາ {valiDate()}</label>
                            <Form.Control
                              as="select"
                              name="graduatedAccessMentYearPay"
                              value={values.graduatedAccessMentYearPay}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            >
                              <option disabled={true} value="">
                                ---ກະລຸນາເລືອກສົກຮຽນ---
                              </option>
                              {graduatedYear.map((x, gradIndex) => {
                                return <option key={gradIndex} value={x}>{x}</option>;
                              })}
                            </Form.Control>
                          </div>
                        </Col>
                      </Row>

                    }
                    <Row>
                      <Col sm={12}>
                        <div className="heading">
                          <b>ຕິດຕໍ່ສຸກເສີນ {valiDate()}</b>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <div className="form-group">
                          <label>ພົວພັນທ່ານ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນຊື່ ແລະ ນາມສະກຸນ"
                            name="relationship"
                            value={values.relationship}
                            onChange={handleChange}
                            isInvalid={!!errors.relationship}
                          />
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="form-group">
                          <label>ເບີຕິດຕໍ່ {valiDate()}</label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="ກະລຸນາປ້ອນເບີຕິດຕໍ່"
                            name="parentPhone"
                            value={values.parentPhone}
                            onChange={handleChange}
                            isInvalid={!!errors.parentPhone}
                          />
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="form-group">
                          <Form.Check
                            inline
                            label="ອາໄສຢູ່ຮ່ວມກັນ(ປັດຈຸບັນ)"
                            type="checkbox"
                            name="isLiveTogether"
                            value={values.isLiveTogether}
                            defaultChecked={isLiveTogether}
                            onChange={handleChange}
                            onClick={() => setIsLiveTogether(!isLiveTogether)}
                          />
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="form-group">
                          <label>ໝາຍເຫດ</label>
                          <Form.Control
                            as="textarea"
                            rows="3"
                            name="note"
                            value={values.note}
                            onChange={handleChange}
                            placeholder="ກະລຸນາປ້ອນໝາຍເຫດ"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                      <Col sm={12}>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ຍົກເລີກການແກ້ໄຂຂໍ້ມູນ
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="outline-secondary"
                            className="btn secondary"
                            onClick={() => history.push(Routers.STUDENT_LIST + Routers.PAGE_GINATION)}
                          >
                            {/* {" "} */}
                            ຍົກເລີກ
                          </Button>
                        </OverlayTrigger>
                        &nbsp;
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ບັນທຶກການແກ້ໄຂຂໍ້ມູນ
                            </Tooltip>
                          }
                        >
                          <Button className="btn btn-info" onClick={handleSubmit}>
                            {/* {" "} */}
                            ບັນທຶກ
                          </Button>
                        </OverlayTrigger>
                        &nbsp;&nbsp;
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            }
          </div>
        </div>
      </CustomContainer>

      {/* ------- AddConfirm Modal ------ */}
      <StudentEditConfirm
        showAddConfirmModal={showAddConfirmModal}
        _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
        param={formParam}
        faculty={facultyName}
        department={departmentName}
        major={majorName}
        classroom={classroomName}
        let
        studentId={studentData?.id}
      />
      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
      >
        <Toast.Header style={{ backgroundColor: "#c0392b" }}>
          <strong className="mr-auto" style={{ color: "#fff" }}>
            ມີຂໍ້ຜິດພາດ
          </strong>
        </Toast.Header>
        <Toast.Body>ທ່ານຕື່ມຂໍ້ມູນບໍ່ຖືກຕ້ອງຕາມທີ່ລະບົບກໍານົດ</Toast.Body>
      </Toast>
    </div>
  );
}
export default StudentEdit;
