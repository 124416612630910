import styled from 'styled-components'
import Consts from '../consts'

export const CustomContainer = styled.div`
  background-color: #fff;
  padding: ${Consts.CONTAINER_PADDING}px;
  margin-top: 16px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 18px;
  padding-right: 18px;
  border-radius: 4px;
`
