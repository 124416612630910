import React, { useState, useEffect, useRef } from "react";
import useReactRouter from "use-react-router";
import { Col, Row } from "react-bootstrap";
import "./utils/index.css";
import { Button, Form } from "react-bootstrap";
import Consts from "../../consts";
import { CustomContainer } from "../../common";
import { toDayDash, valiDate } from "../../common/super";

//
function RegistrationStudentAdd() {
  const { history } = useReactRouter();

  const [previewImageURL, setPreviewImageURL] = useState();

  const [dataUser, setDataUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState();

  const inputImage = useRef("inputImage");

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
  }, []);

  useEffect(() => {
    let userRole = dataUser?.role;
    // check role
    if (userRole !== "ADMIN") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [dataUser]);

  useEffect(() => {
    let today = new Date();
    let y2 = today.getFullYear() - 1;
    var stdId = [];
    for (var i = y2; i >= 2000; i--) {
      stdId.push(i);
    }
  }, []);

  const _addImage = () => {
    inputImage.current.click();
  };

  const _handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setPreviewImageURL(URL.createObjectURL(event.target.files[0]));
    }
  };
  return (
    <div>
      {/* Breadcrumb */}
      <div className="title">
        <strong>
          {"ຈັດການນັກຮຽນເກົ່າ "}
          <i className="fa fa-angle-right"></i>
          {" ເພີ່ມນັກຮຽນ"}
        </strong>
      </div>
      <CustomContainer style={{ marginTop: 0 }}>
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          <div className="container">
            <form>
              <Row>
                <Col sm={12}>
                  <h5>
                    <strong>ເພີ່ມນັກຮຽນ</strong>
                  </h5>
                  <hr />
                </Col>
                <Col sm={12}>
                  <img
                    src={
                      previewImageURL
                        ? previewImageURL
                        : "https://www.learning.uclg.org/sites/default/files/styles/featured_home_left/public/no-user-image-square.jpg?itok=PANMBJF-"
                    }
                    className="browserImage"
                  />
                  <input
                    type="file"
                    id="inputImage"
                    multiple
                    name="image"
                    ref={inputImage}
                    style={{ display: "none" }}
                    onChange={_handleChangeImage}
                  />
                  <Button
                    variant="outline-secondary"
                    onClick={_addImage}
                    style={{ marginLeft: 20, width: 140 }}
                  >
                    <i className="fa fa-download"></i> ໂຫຼດຮູບ
                  </Button>
                </Col>
                <Col sm={12}>
                  <br />
                  <div className="heading">
                    <b>ຂໍ້ມູນທົ່ວໄປ</b>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="form-group">
                    <label>ຊື່ {valiDate()}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ກະລຸນາປ້ອນຊື່"
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label>ນາມສະກຸນ {valiDate()}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ກະລຸນາປ້ອນນາມສະກຸນ"
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label>ຊື່(ອັງກິດ) {valiDate()}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ກະລຸນາປ້ອນຊື່(ອັງກິດ)"
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label>ນາມສະກຸນ(ອັງກິດ) {valiDate()}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ກະລຸນາປ້ອນນາມສະກຸນ(ອັງກິດ)"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <label>ວັນເດືອນປີເກີດ {valiDate()}</label>
                  <div className="form-group">
                    <input
                      type="date"
                      defaultValue={toDayDash()}
                      className="form-control"
                      placeholder="ວັນທີ"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={1.5} style={{ marginLeft: 13 }}>
                  <label>ເລືອກເພດ {valiDate()}</label>
                </Col>
                <Col sm={2}>
                  <div className="form-group">
                    <Form.Check inline label="ຊາຍ" type="radio" />
                  </div>
                </Col>
                <Col sm={2}>
                  <div className="form-group">
                    <Form.Check inline label="ຍິງ" type="radio" />
                  </div>
                </Col>
                <Col sm={2}>
                  <div className="form-group">
                    <Form.Check inline label="ພຣະ" type="radio" />
                  </div>
                </Col>
                <Col sm={2}>
                  <div className="form-group">
                    <Form.Check inline label="ສຳມະເນນ" type="radio" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ສັນຊາດ {valiDate()}</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ຊົນເຜົ່າ {valiDate()}</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ສາສະໜາ {valiDate()}</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="form-group">
                    <label>ເບີເຮືອນ {valiDate()}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ກະລຸນາປ້ອນເບີເຮືອນ"
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label>ເບີໂທລະສັບມືຖື {valiDate()}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ກະລຸນາປ້ອນເບີໂທລະສັບມືຖື"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="heading">
                    <b>ທີ່ຢູ່ປັດຈຸບັນ</b>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ແຂວງ {valiDate()}</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ເມືອງ {valiDate()}</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ບ້ານ {valiDate()}</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="heading">
                    <b>ສະຖານທີເກີດ</b>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ແຂວງ</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ເມືອງ</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ບ້ານ</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="heading">
                    <b>ຂໍ້ມູນນັກສຶກສາ</b>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ຄະນະ {valiDate()}</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ສາຂາວິຊາ {valiDate()}</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ສົກສຶກສາ {valiDate()}</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <div className="form-group">
                    <strong>ຂໍ້ມູນນັກສຶກສາ {valiDate()}</strong>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="form-group">
                    <Form.Check
                      inline
                      label="ເປັນພະນັກງານມາຮຽນຕໍ່"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="form-group">
                    <Form.Check
                      inline
                      label="ເປັນນັກຮຽນມາຮຽນຕໍ່"
                      type="checkbox"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <div className="form-group">
                    <strong>ລະດັບສຶກສາ {valiDate()}</strong>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="form-group">
                    <Form.Check inline label="ອານຸປະລິນຍາ" type="checkbox" />
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="form-group">
                    <Form.Check inline label="ປະລິນຍາຕີ" type="checkbox" />
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="form-group">
                    <Form.Check
                      inline
                      label="ປະລິນຍາຕີ(ຕໍ່ເນື່ອງ)"
                      type="checkbox"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="heading">
                    <b>ນັກສຶກສາ/ພະນັກງານ ທຶນແບ່ງປັນຈາກພາກສ່ວນ</b>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <div className="form-group">
                    <strong>ຂໍ້ມູນນັກສຶກສາ</strong>
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="form-group">
                    <Form.Check
                      inline
                      label="ນັກຮຽນເກັ່ງລະດັບຊາດ"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="form-group">
                    <Form.Check
                      inline
                      label="ນັກຮຽນເກັ່ງປາກາສະນິຍະບັດແດງ"
                      type="checkbox"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <div className="form-group"> </div>
                </Col>
                <Col sm={3}>
                  <div className="form-group">
                    <Form.Check
                      inline
                      label="ນັກຮຽນເກັ່ງເສັງໄດ້ທີ 1 ຂອງແຂວງ"
                      type="checkbox"
                    />
                  </div>
                </Col>
                <Col sm={3}>
                  <div className="form-group">
                    <Form.Check
                      inline
                      label="ນັກຮຽນຊົນເຜົ່າຂອງແຂວງ"
                      type="checkbox"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <div className="form-group"> </div>
                </Col>
                <Col sm={3}>
                  <div className="form-group">
                    <Form.Check
                      inline
                      label="ນັກຮຽນເສັງໄດ້ບໍ່ໄດ້ຈ່າຍຄ່າຮຽນ ມຊ"
                      type="checkbox"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <div className="form-group">
                    <Form.Check
                      inline
                      label="ແຂວງ / ກົມ / ກອງ"
                      type="checkbox"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="form-group">
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ຈົບຈາກແຂວງ {valiDate()}</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ຈົບຈາກໂຮງຮຽນ {valiDate()}</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ຈົບສົກສຶກສາ {valiDate()}</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <div className="form-group">
                    <Form.Check
                      inline
                      label=" ທຶນແບ່ງປັນອື່ນໆ"
                      type="checkbox"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="form-group">
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="form-group">
                    <label>ໝາຍເຫດ</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ກະລຸນາປ້ອນໝາຍເຫດ"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="heading">
                    <b>ສຳລັບນັກສຶກສາຈ່າຍຄ່າຮຽນ</b>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ຈົບຈາກແຂວງ</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ຈົບຈາກໂຮງຮຽນ</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ກະລຸນາປ້ອນ"
                    />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <label>ສົກສຶກສາ</label>
                    <select className="form-control">
                      <option>ເລືອກ</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="heading">
                    <b>ຕິດຕໍ່ສຸກເສີນ</b>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="form-group">
                    <label>ພົວພັນທ່ານ {valiDate()}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ກະລຸນາປ້ອນຊື່ ແລະ ນາມສະກຸນ"
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label>ເບີຕິດຕໍ່ {valiDate()}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ກະລຸນາປ້ອນເບີຕິດຕໍ່"
                    />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="form-group">
                    <Form.Check
                      inline
                      label="ອາໄສຢູ່ຮ່ວມກັນ(ປັດຈຸບັນ)"
                      type="checkbox"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="heading">
                    <b>ສຳລັບພະນັກງານຮັບການລົງທະບຽນນັກສຶກສາ</b>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <div className="form-group">
                    <label>ເລກທີລົງທະບຽນ {valiDate()}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ກະລຸນາປ້ອນເລກທີລົງທະບຽນ"
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="form-group">
                    <label>ຊື່ ແລະ ນາມສະກຸນ {valiDate()}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ກະລຸນາປ້ອນຊື່ ແລະ ນາມສະກຸນ"
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ textAlign: "center" }}>
                <Col sm={12}>
                  <Button className="btn btn-info"> ບັນທຶກ</Button>&nbsp;&nbsp;
                  &nbsp;
                  <Button variant="outline-secondary" className="btn secondary">
                    {" "}
                    ຍົກເລີກ
                  </Button>{" "}
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </CustomContainer>
    </div>
  );
}
export default RegistrationStudentAdd;
