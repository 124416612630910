import { gql } from "apollo-boost";

export const PRE_SIGNED_URL = gql`
query PreregistrationPreSignedUrl($name: String) {
  preregistrationPreSignedUrl(name: $name) {
    url
  }
}
`

export const UPDATE_STUDENT = gql`
mutation UpdatePreregistrationStudent($data: PreregistrationStudentInput!
  $where: PreregistrationStudentWhereInputId! ){
    updatePreregistrationStudent(data: $data where: $where){
      id
    }
  }
`