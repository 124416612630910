import React, { useState } from 'react'
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { useMutation } from "@apollo/react-hooks";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

// common
import { formatDateDashDMY, onConvertStudentType } from "../../common/super";

// constant
import Consts from "../../consts";
import Routers from "../../consts/router";

// Apollo
import { UPDATE_PRERE_CODE } from '../../apollo/codePreregistration';
import { onConvertEducationLevel } from '../../helpers/user';

function CodePreregistrationEditConfirm({
  showAddConfirmModal,
  _handleAddConfirmModalClose,
  param,
  faculty,
  department,
  major,
  codeId
}) {
  const { history } = useReactRouter();
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [updateCode] = useMutation(UPDATE_PRERE_CODE)
console.log("param::", param)
  const _onConvertGender = (gender) => {
    let result;
    switch (gender) {
      case "MALE":
        result = "ຊາຍ";
        break;
      case "FEMALE":
        result = "ຍິງ";
        break;
      case "MONK":
        result = "ພຣະ";
        break;
      case "NOVICE":
        result = "ສຳມະເນນ";
        break;
      default:
        result = "ຊາຍ";
    }
    return result;
  };

  const _confirmCodeEdit = async () => {
    try {
      
      setIsLoading(true)
      delete param.data.day
      delete param.data.month
      delete param.data.year
      let data = { ...param.data, code: param.data.preStudentNumber + param.data.code }
      delete data.preStudentNumber
      const preData = { data }
      
      preData.where = {
        id: codeId
      }
      const updateData = await updateCode({ variables: preData });
      if(!updateData) {
        setIsLoading(false)
        return;
      }
      if(updateData && updateData?.data?.updatePreregistrationCode?.id) {
        setIsLoading(false)
        setShow(true)
        _handleAddConfirmModalClose()
        setTimeout(() => {
          setShow(false)
          history.push(Routers.CODE_PRERE_LIST + Routers.PAGE_GINATION);
          window.location.reload()
        }, 1500);
      }
    } catch(error) {
      console.log("update error::: ", error.message);
    }
  }

return (
  <div>
    <Modal
      show={showAddConfirmModal}
      onHide={_handleAddConfirmModalClose}
      size="lg"
      centered
    >
      <Modal.Header 
        closeButton
        style={{color: Consts.PRIMARY_COLOR, marginTop: 20}}
      >
        <h3>ຢືນຢັນການແກ້ໄຂລະຫັດ</h3>
      </Modal.Header>
      {isLoading ? 
      <Modal.Body>
        <div style={{width: "100%", minHeight: 350, display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <Spinner animation="border" style={{ color: "#057CAE" }} />
        </div>
      </Modal.Body> : 
      <Modal.Body className="d-flex justify-content-center">
        <Col md={12}>
          <div className="mt-3 mb-3">
            <h3>
              <i
                className="fa fa-caret-down"
                aria-hidden="true"
                style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
              />
              ຂໍ້ມູນລະຫັດລົງທະບຽນເຂົ້າຮຽນ
            </h3>
          </div>
          <Row>
            <Col xs={6} className="pl-5">
              <Form.Label>ລະຫັດລົງທະບຽນເຂົ້າຮຽນ:</Form.Label>
            </Col>
            <Col xs={6}>
              <span>{param?.data?.preStudentNumber + param?.data?.code}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="pl-5">
              <Form.Label>ຄະນະ:</Form.Label>
            </Col>
            <Col xs={6}>
              <span>{faculty}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="pl-5">
              <Form.Label>ພາກວິຊາ:</Form.Label>
            </Col>
            <Col xs={6}>
              <span>{department}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="pl-5">
              <Form.Label>ສາຂາວິຊາ:</Form.Label>
            </Col>
            <Col xs={6}>
              <span>{major}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="pl-5">
              <Form.Label>ລະດັບສຶກສາ</Form.Label>
            </Col>
            <Col xs={6}>
              <span>{onConvertEducationLevel(param?.data?.educationLevel)}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="pl-5">
              <Form.Label>ປະເພດນັກສຶກສາ:</Form.Label>
            </Col>
            <Col xs={6}>
              <span>{onConvertStudentType(param?.data?.studentType)}</span>
            </Col>
          </Row>
          
          <div className="mt-5 mb-3">
            <h3>
              <i
                className="fa fa-caret-down"
                aria-hidden="true"
                style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
              />
              ຂໍ້ມູນທົ່ວໄປ
            </h3>
          </div>
          <Row>
            <Col xs={6} className="pl-5">
              <Form.Label>ຊື່ (ພາສາລາວ):</Form.Label>
            </Col>
            <Col xs={6}>
              <span>{param?.data?.firstNameL}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="pl-5">
              <Form.Label>ນາມສະກຸນ (ພາສາລາວ):</Form.Label>
            </Col>
            <Col xs={6}>
              <span>{param?.data?.lastNameL}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="pl-5">
              <Form.Label>ຊື່ (ພາສາອັງກິດ):</Form.Label>
            </Col>
            <Col xs={6}>
              <span>{param?.data?.firstNameE ?? "-"}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="pl-5">
              <Form.Label>ນາມສະກຸນ (ພາສາອັງກິດ):</Form.Label>
            </Col>
            <Col xs={6}>
              <span>{param?.data?.lastNameE ?? "-"}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="pl-5">
              <Form.Label>ເພດ:</Form.Label>
            </Col>
            <Col xs={6}>
              <span>{_onConvertGender(param?.data?.gender)}</span>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="pl-5">
              <Form.Label>ວັນເດືອນປີເກີດ:</Form.Label>
            </Col>
            <Col xs={6}>
              <span>{formatDateDashDMY(param?.data?.birthday)}</span>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={6}>
              <Button
                variant="outline-secondary"
                className="btn secondary"
                onClick={_handleAddConfirmModalClose}
                style={{width: 150}}
              >
                ຍົກເລີກ
              </Button>
            </Col>
            <Col xs={6}>
              <Button
               className="btn btn-info"
                onClick={_confirmCodeEdit}
                style={{width: 150}}
                >
                ຢືນຢັນການແກ້ໄຂ
              </Button>
            </Col>
          </Row>
        </Col>
      </Modal.Body>
      }
    </Modal>
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Body>
        <Col
          className='text-center'
          style={{ paddingTop: 40, paddingBottom: 50 }}
        >
          <div
            className='textfont-size72 text-bold'
            style={{ color: '#00A1DE' }}
          >
            ສໍາເລັດ
          </div>
          <FontAwesomeIcon
            icon={faCheckCircle}
            size='9x'
            style={{ color: '#00A1DE' }}
          />
          <div
            className='textfont-size19 text-bold'
            style={{ marginTop: 40 }}
          >
            ແກ້ໄຂຂໍ້ມູນເປັນທີ່ຮຽບຮ້ອຍ
          </div>
        </Col>
      </Modal.Body>
    </Modal>
  </div>
  )
}

export default CodePreregistrationEditConfirm