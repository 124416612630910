import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
/**
 *
 * @Libraries
 *
 */
import { Row, Col, OverlayTrigger, Form, Modal, Tooltip, Breadcrumb, FormCheck } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import * as _ from "lodash";
import queryString from "query-string";
import { useSelector } from 'react-redux'
/**
 *
 * @Component
 *
 */

/**
 *
 * @Constants
 *
 */
import Consts from "../../consts";
import { CustomButton, TableHeader, CustomContainer, TableCell } from "../../common";
import Routers from "../../consts/router";
import Loading from "../../common/Loading";
/**
 *
 * @Apollo
 *
 */
import { FACULTIES } from "../../apollo/faculty";
import { STUDENTS } from "./../../apollo/student";
import { ASSIGN_MANY_STUDENT_TO_CLASSROOM } from "./apollo/mutation";
import { CLASSROOM_MASTERS } from "../../apollo/classRoom";
import { DEPARTMENTS } from "../../apollo/deparment";
/**
 *
 * @Function
 *
 */
import { messageSuccess, messageWarning } from "../../common/super";
import PaginationHelper from "../../helpers/PaginationHelper";
import { convertGenderNamEN, getUserDataFromLCStorage } from "../../helpers/user";
import { setGenders, currency } from "../../common/super";
import { MAJORS } from "../../apollo/major";


function AssignAdd() {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const { history, location } = useReactRouter();
  const parsed = queryString?.parse(location?.state);
  const { _limit, _skip, Pagination_helper } = PaginationHelper();
  /**
   *
   * @State
   *
   */

  const [students, setStudents] = useState([]);
  const [dataFaculties, setDataFaculties] = useState([]);
  const [facultyId, setFacultyId] = useState(!parsed?.faculty ? "" : parsed?.faculty);
  const [dataDepartments, setDataDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState(!parsed?.department ? "" : parsed?.department);
  const [dataMajors, setDataMajors] = useState([]);
  const [majorId, setMajorId] = useState(!parsed?.major ? "" : parsed?.major);

  const [dataStudents, setDataStudents] = useState([]);
  const [dataClassroom, setDataClassroom] = useState([]);
  const [classroomId, setClassroomId] = useState(!parsed?.classRoom ? "" : parsed?.classRoom);
  const [totals, setTotals] = useState("");

  const [yearLevel, setYearLevel] = useState(!parsed?.year ? "" : parsed?.year);
  const [dataUser, setDataUser] = useState();
  const [ROLE, setROLE] = useState();
  /**
   *
   * @Apollo
   *
   */
  const [loadDataFaculties, { data: facultiesData }] = useLazyQuery(FACULTIES);
  const [loadDepartments, { data: dataDepartment }] = useLazyQuery(DEPARTMENTS);
  const [loadMajors, { data: majorData }] = useLazyQuery(MAJORS);
  const [loadStudents, { loading: studentLoading, data: studentData }] = useLazyQuery(STUDENTS, { fetchPolicy: "network-only" });
  const [assignManyStudentToClassroom, { loading: LoadingAssignStudentToClassroom }] = useMutation(ASSIGN_MANY_STUDENT_TO_CLASSROOM);
  const [loadClassRooms, { data: classroomData }] = useLazyQuery(CLASSROOM_MASTERS)

  /**
   *
   * @useEffect
   *
   */
  useEffect(() => {
    loadDataFaculties({
      variables: {
        where: { isDeleted: false, assessmentYear: accessmentRedux },
      }
    });
    loadStudents({
      variables: {
        where: { 
          assessmentYear: accessmentRedux, 
          isDeleted: false, 
          classRoom_not_null: false,
          studyStatus: ["STUDYING", "DROP", "DONT_STUDY"]
        },
        skip: (_skip - 1) * _limit,
        first: _limit,
        orderBy: "createdAt_DESC"
      },
    });
    if (getUserDataFromLCStorage()?.data) setDataUser(getUserDataFromLCStorage()?.data)
    setROLE(getUserDataFromLCStorage()?.data?.role);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessmentRedux]);


  useEffect(() => {
    if(dataUser?.role === "FAC_ADMIN") setFacultyId(dataUser?.faculty?.id)
    if(dataUser?.role === "FAC_ADMIN" || dataUser?.role === "DEP_ADMIN"){
      setFacultyId(dataUser?.faculty?.id)
      setDepartmentId(dataUser?.department?.id)
    }
  },[dataUser])

  useEffect(() => {
    if (facultiesData) setDataFaculties(facultiesData?.faculties);
  }, [facultiesData, dataUser, ROLE]);

  useEffect(() => {
    if(classroomData) setDataClassroom(classroomData?.classRooms?.data)
  }, [classroomData]);

  useEffect(() => {
    if(facultyId === ""){
      setDataDepartments([]);
      setDepartmentId("");
      return
    }
    loadDepartments({ variables: {where: {faculty: facultyId,isDeleted: false, assessmentYear: accessmentRedux}}});
  }, [facultyId]);

  useEffect(() => {
    if(departmentId === ""){
      setDataMajors([]);
      setMajorId("");
      return
    }
    loadMajors({
      variables: { where: { isDeleted: false, department: departmentId, assessmentYear: accessmentRedux } },
    });
  }, [departmentId]);
  
  useEffect(() => {
    if(majorId === ""){
      setDataClassroom([]);
      setClassroomId("");
      return
    }
    let _where = { major: majorId }
    if(yearLevel !== "") _where = { ..._where, year: yearLevel }
    loadClassRooms({variables: { where: _where }});
  }, [majorId, yearLevel]);

  useEffect(() => {
   setDataDepartments(dataDepartment?.departments);
  }, [dataDepartment])

  useEffect(() => {
   setDataMajors(majorData?.majors?.data);
  }, [majorData])

  /* set data student */
  useEffect(() => {
    if (studentData?.students) {
      setDataStudents(studentData?.students?.data);
      setTotals(studentData?.students?.total);
    }
  }, [studentData]);

  useEffect(() => {
    let qlWhere = { 
      assessmentYear: accessmentRedux, 
      isDeleted: false,
      classRoom_not_null: false,
      studyStatus: ["STUDYING", "DROP", "DONT_STUDY"]
     };
    if (facultyId !== "") qlWhere = { ...qlWhere, faculty: facultyId ?? "" };
    if (departmentId !== "") qlWhere = { ...qlWhere, department: departmentId ?? "" };
    if (majorId !== "") qlWhere = { ...qlWhere, major: majorId ?? "" };
    if (yearLevel !== "" && yearLevel !== "ທັງໝົດ") qlWhere = { ...qlWhere, yearLevel: yearLevel ?? "" };

    loadStudents({
      fetchPolicy: "cache-and-network",
      variables: {
        where: { ...qlWhere },
        skip: (_skip - 1) * _limit,
        first: _limit,
        orderBy: "createdAt_DESC"
      },
    });
  }, [facultyId, departmentId, majorId, yearLevel, accessmentRedux]);

  /**
   *
   * @Function
   *
   */
  const _selectCheckBox = (e) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempStudent = dataStudents?.map((student) => {
        return { ...student, isChecked: checked };
      });
      setDataStudents(tempStudent);
      if (checked === true) {
        let arr1 = [];
        for (var b = 0; b < tempStudent?.length; b++) {
          arr1.push(tempStudent[b]?.id);
        }
        setStudents(arr1);
      } else {
        setStudents([]);
      }
    } else {
      let tempStudent = dataStudents?.map((student) =>
        student?.id === name ? { ...student, isChecked: checked } : student
      );
      let arr = [...students];
      if (checked === true) {
        arr.push(name);
        setStudents(arr);
      } else {
        const arrayId = students.filter((id) => id !== name);
        setStudents(arrayId);
      }
      setDataStudents(tempStudent);
    }
  };
  const _registration = async (values) => {
    if (students.length > 0) {
       const result = await assignManyStudentToClassroom({ variables: {
          data: {
            students: students,
            classRoom: classroomId,
          },
        } })
        
        if(result?.data?.assignManyStudentToClassroom?.status == "Success"){
            messageSuccess("ເພີ່ມນັກສຶກສາເຂົ້າຫ້ອງສຳເລັດ");
            loadStudents({
              variables: {
                where: { 
                  assessmentYear: accessmentRedux, 
                  isDeleted: false, 
                  classRoom_not_null: false,
                  studyStatus: ["STUDYING", "DROP", "DONT_STUDY"]
                },
                skip: (_skip - 1) * _limit,
                first: _limit,
                orderBy: "createdAt_DESC"
              },
            });
            setFacultyId("")
        }else{
          messageWarning(
            "ເພີ່ມນັກສຶກສາເຂົ້າຫ້ອງບໍ່ສຳເລັດ!"
          );
        }
    }
  };
  const _selectFaculty = (e) => {
    setFacultyId(e.target.value);
  }
  const _onSelectedDepartment = (e) => {
    setDepartmentId(e.target.value);
  }
  const _onSelectedMajor = (e) => {
    setMajorId(e.target.value);
  }
  const _onSelectedYear = (e) => {
    setYearLevel(e.target.value);
  }
  const _selectClassroom = (e) => {
    setClassroomId(e.target.value);
  };
  if (LoadingAssignStudentToClassroom) return <Loading />;
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push("/assign-student-list/limit/40/skip/1")}
        >
          ຈັດການຫ້ອງຮຽນ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ຈັດການຫ້ອງຮຽນໃຫ້ນັກສຶກສາຈຳນວນຫຼາຍ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <Row>
          <Col>
            <p>ຄະນະ</p>
            <Form.Control
              as="select"
              className="form-select"
              style={{ border: "none", backgroundColor: "#f1f1f1f1", cursor: "pointer" }}
              disabled={dataUser?.role !== "ADMIN" && dataUser?.role !== "DEAN" ? true : false}
              value={facultyId}
              onChange={(e) => { _selectFaculty(e) }}
            >
              <option value="">ທັງໝົດ</option>
              {dataFaculties?.map((item, index) => {
                return (
                  <option key={index} value={item?.id}>{item?.name}</option>
                );
              })}
            </Form.Control>
          </Col>
          <Col>
            <p>ພາກວິຊາ</p>
            <Form.Control
              as="select"
              className="form-select"
              style={{ border: "none", backgroundColor: "#f1f1f1f1", cursor: "pointer" }}
              value={departmentId}
              disabled={dataUser?.role !== "ADMIN" && dataUser?.role !== "DEAN" && dataUser?.role !== "FAC_ADMIN" ? true : false}
              onChange={(e) => _onSelectedDepartment(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataDepartments?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>{item.name}</option>
                );
              })}
            </Form.Control>
          </Col>
          <Col>
            <p>ສາຂາວິຊາ</p>
            <Form.Control
              as="select"
              className="form-select"
              style={{ border: "none", backgroundColor: "#f1f1f1f1", cursor: "pointer" }}
              value={majorId}
              onChange={(e) => _onSelectedMajor(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataMajors?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>{item.name}</option>
                );
              })}
            </Form.Control>
          </Col>
          <Col sm={2}>
            <p>ປີຮຽນ</p>
            <Form.Control
              as="select"
              className="form-select"
              style={{ border: "none", backgroundColor: "#f1f1f1f1", cursor: "pointer" }}
              value={yearLevel}
              onChange={(e) => _onSelectedYear(e)}
            >
              <option value={""}>ທັງໝົດ</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </Form.Control>
          </Col>
          <Col sm={2}>
            <p>ຫ້ອງຮຽນ {" "}
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">ເລືອກຫ້ອງເພື່ອເອົານັກສຶກສາທີ່ເລືອກເຂົ້າຮຽນ</Tooltip>}>
                <FontAwesomeIcon icon={faInfoCircle} color={Consts.PRIMARY_COLOR} />
              </OverlayTrigger>
            </p>

            <Form.Control
              as="select"
              className="form-select"
              style={{ border: "none", backgroundColor: "#f1f1f1f1", cursor: "pointer" }}
              onChange={(e) => _selectClassroom(e)}
            >
              <option>ເລືອກຫ້ອງ</option>
              {dataClassroom?.map((x, index) => (
                <option key={index} value={x?.id}>{x?.name}</option>
              ))}
            </Form.Control>
          </Col>
        </Row>
      </CustomContainer>
      <CustomContainer>
        <Col sm={8} className="mt-1 ms-2">
          <Row>
            <div className="custom-control custom-checkbox">
              <label className="form-check-label">
                <input
                  type="checkbox"
                  style={{ color: "orange", bordercolor: "orange" }}
                  className="form-check-input"
                  onChange={(e) => _selectCheckBox(e)}
                  name="allSelect"
                  checked={
                    dataStudents?.filter(
                      (student) => student?.isChecked !== true
                    ).length < 1
                  }
                />
                ເລືອກທັງໝົດ {currency(students?.length)} ຄົນ
              </label>
            </div>
            {/* <p>ຖືກເລືອກ </p> */}
          </Row>
        </Col>
        <div>
          <Row>
            <p className="mt-1" style={{ color: "#8D8D8D", marginBottom: -50 }}>
              ທັງໝົດ {currency(totals)} ຄົນ
            </p>
            <div style={{ textAlign: "right" }}>
              <CustomButton
                confirm
                addIcon
                title="ບັນທຶກ"
                onClick={(e) => _registration(e)}
              />
            </div>
          </Row>
        </div>
        <div>
          {studentLoading ? (
            <Loading />
          ) : (
            <table border="1" bordercolor="#fff" style={{ width: "100%", marginBottom: 20 }}>
              <thead>
                <TableHeader>
                  <th style={{ width: 70 }}>ເລືອກ</th>
                  <th style={{ width: 70 }}>ລຳດັບ</th>
                  <th>ລະຫັດນັກສຶກສາ</th>
                  <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                  <th>ຄະນະ</th>
                  <th>ພາກວິຊາ</th>
                  <th>ສາຂາວິຊາ</th>
                  <th style={{ width: 70 }}>ປີຮຽນ</th>
                  <th style={{ width: "10%" }}>ຫ້ອງຮຽນ</th>
                </TableHeader>
              </thead>
              {dataStudents?.map((x, index) => {
                return (
                  <tbody key={index}>
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                    >
                      <TableCell>
                        <FormCheck
                          className="ms-4"
                          name={x?.id}
                          checked={x?.isChecked || false} onChange={(e) => _selectCheckBox(e)}
                        />
                      </TableCell>
                      <TableCell>{index + 1 + _limit * (_skip - 1)}</TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {x?.studentNumber}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {!x?.firstNameL || x?.firstNameL === "" ? 
                          convertGenderNamEN(x?.gender) + " " + x?.firstNameE + " " + x?.lastNameE
                          :
                          setGenders(x?.gender) + " " + x?.firstNameL + " " + x?.lastNameL
                        }
                        {/* {setGenders(x?.gender) + " " + x?.firstNameL + " " + x?.lastNameL} */}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {x?.faculty?.name}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {x?.department?.name}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {x?.major?.name}
                      </TableCell>
                      <TableCell>{x?.yearLevel}</TableCell>
                      <TableCell>{x?.classRoom?.name}</TableCell>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          )}
          {Pagination_helper(
            totals,
            Routers.ASSIGN_STUDENT_ADD,
            `faculty=${facultyId}&&department=${departmentId}&&major=${majorId}&&year=${yearLevel}&&classRoom=${classroomId}`
          )}
        </div>
      </CustomContainer>
    </div>
  );
}

export default AssignAdd;
