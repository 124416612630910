import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import { Formik } from "formik";
import { Input, Progress } from "reactstrap";
import * as _ from "lodash";
import { useSelector } from 'react-redux'


/**
 *
 * @Component
 *
 */
import { Breadcrumb, Form, Row, Col } from "react-bootstrap";
import { CustomContainer, Title, CustomButton } from "../../common";
import TeacherAddConfirm from "./TeacherAddConfirm";

/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";
import Routers from "../../consts/router";
/**
 *
 * @Function
 *
 */
import { convertRole } from '../../helpers/user'
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import StepperProgress from "./common/StepperProgress";
import { LAO_ADDRESS } from "../../consts/Address";
import { TRIBES } from "../preregistrationStudent/apollo/query";
import { RELIGIONAL_MASTERS } from "../../apollo/religionalMasters";


function TeacherFormPlaceOfBirth() {
  const { history, location } = useReactRouter();
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const teacherDataById = location?.state
  // States
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
  const [dataFromStored, setDataFromStored] = useState();
  const [selectProvinceBirthIndex, setSelectProvinceBirthIndex] = useState()
  const [selectProvinceNowIndex, setSelectProvinceNowIndex] = useState()
  const [isInitialCheckDone, setIsInitialCheckDone] = useState(false);

  useEffect(() => {
    const storedValues = localStorage.getItem('placeOfBirthInfo');
    if (storedValues) {
      const parsedValues = JSON.parse(storedValues);
      setDataFromStored(parsedValues);
    }
    setIsInitialCheckDone(true);
  }, [])

  useEffect(() => {
    // Only set dataFromStored to teacherDataById if the initial check is done
    // and dataFromStored is still undefined
    if (isInitialCheckDone && dataFromStored === undefined) {
      if (teacherDataById !== undefined) {
        setDataFromStored(teacherDataById);
      } else {
        setDataFromStored();
      }
    }
  }, [teacherDataById, dataFromStored, isInitialCheckDone]);

  useEffect(() => {
    if (dataFromStored?.provinceBirth) {
      setSelectProvinceBirthIndex(_.findIndex(LAO_ADDRESS, {
        province_name: dataFromStored?.provinceBirth
      }))
    }
    if (dataFromStored?.provinceNow) {
      setSelectProvinceNowIndex(_.findIndex(LAO_ADDRESS, {
        province_name: dataFromStored?.provinceNow
      }))
    }


  }, [dataFromStored]);
  // Set states
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
  const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);


  const _selectProvinceBirth = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value
    })
    setSelectProvinceBirthIndex(_provinceIndex)
  }

  const _selectProvinceNow = (e) => {
    const _provinceIndex = _.findIndex(LAO_ADDRESS, {
      province_name: e.target.value
    })
    setSelectProvinceNowIndex(_provinceIndex)
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION)}
        >
          ຈັດການອາຈານ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION)}
        >
          ອາຈານທັງໝົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ເພີ່ມອາຈານ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <div className="container">
          <StepperProgress />

          <Formik
            enableReinitialize={true}
            initialValues={{
              provinceBirth: dataFromStored?.provinceBirth || "",
              districtBirth: dataFromStored?.districtBirth || "",
              villageBirth: dataFromStored?.villageBirth || "",
              provinceNow: dataFromStored?.provinceNow || "",
              districtNow: dataFromStored?.districtNow || "",
              villageNow: dataFromStored?.villageNow || "",
            }}
            validate={(values) => {
              const errors = {};
              // if (!values.faculty) {
              //   errors.faculty = "ກະລຸນາເລືອກຄະນະ"
              // }
              return errors;
            }}
            onSubmit={(values) => {
              // const combineData = { ...values }
              // Store the serialized values in localStorage
              localStorage.setItem('placeOfBirthInfo', JSON.stringify(values));
              _handleShowAddConfirmModalShow()
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
            }) => (
              <Form noValidate>
                {/* ---------- ສະຖານທີ່ເກີດ --------- */}
                <div style={{ marginBottom: 10 }}>
                  <div className="heading"><b>ສະຖານທີ່ເກີດ</b></div>
                  {/* ພາກວິຊາ */}
                  <Row className="mb-1">
                    <Form.Group as={Col}>
                      <Form.Label>ແຂວງເກີດ</Form.Label>
                      <Form.Control
                        as="select"
                        name="provinceBirth"
                        value={values.provinceBirth}
                        placeholder="ກະລຸນາປ້ອນນາມສະກຸນ"
                        onChange={(e) => {
                          handleChange(e)
                          _selectProvinceBirth(e)
                        }}
                        isInvalid={!!errors.provinceBirth}
                      >
                        <option value="">ເລືອກແຂວງເກີດ</option>
                        {LAO_ADDRESS.map((province, index) => (
                          <option
                            key={'province' + index}
                            value={province?.province_name}
                          >
                            {province?.province_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>ເມືອງເກີດ</Form.Label>
                      <Form.Control
                        as="select"
                        name="districtBirth"
                        value={values.districtBirth}
                        placeholder="ກະລຸນາປ້ອນນາມສະກຸນ"
                        onChange={handleChange}
                        isInvalid={!!errors.districtBirth}
                      >
                        <option value="">ເລືອກເມືອງເກີດ</option>
                        {selectProvinceBirthIndex > -1 &&
                          LAO_ADDRESS[selectProvinceBirthIndex].district_list.map(
                            (district, index) => (
                              <option key={'district' + index}>
                                {district.district}
                              </option>
                            )
                          )}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>ບ້ານເກີດ</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="ກະລຸນາປ້ອນຊື່ບ້ານເກີດ"
                        name="villageBirth"
                        value={values.villageBirth}
                        onChange={handleChange}
                        isInvalid={!!errors.villageBirth}
                      />
                    </Form.Group>
                  </Row>
                </div>

                {/* ---------- ສະຖານທີ່ຢູ່ປັດຈຸບັນ --------- */}
                <div style={{ marginBottom: 10 }}>
                  <div className="heading"><b>ສະຖານທີ່ຢູ່ປັດຈຸບັນ</b></div>
                  {/* ຊື່ */}
                  <Row className="mb-1">
                    <Form.Group as={Col}>
                      <Form.Label>ແຂວງຢູ່ປັດຈຸບັນ</Form.Label>
                      <Form.Control
                        as='select'
                        name="provinceNow"
                        value={values.provinceNow}
                        onChange={(e) => {
                          handleChange(e)
                          _selectProvinceNow(e)
                        }}
                        isInvalid={!!errors.provinceNow}
                      >
                        <option value="">ເລືອກແຂວງຢູ່ປັດຈຸບັນ</option>
                        {LAO_ADDRESS.map((province, index) => (
                          <option
                            key={'province' + index}
                            value={province?.province_name}
                          >
                            {province?.province_name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>ເມືອງຢູ່ປັດຈຸບັນ</Form.Label>
                      <Form.Control
                        as='select'
                        name="districtNow"
                        value={values.districtNow}
                        onChange={handleChange}
                        isInvalid={!!errors.districtNow}
                      >
                        <option value="">ເລືອກເມືອງຢູ່ປັດຈຸບັນ</option>
                        {selectProvinceNowIndex > -1 &&
                          LAO_ADDRESS[selectProvinceNowIndex].district_list.map(
                            (district, index) => (
                              <option key={'district' + index}>
                                {district.district}
                              </option>
                            )
                          )}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>ບ້ານຢູ່ປັດຈຸບັນ</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder="ກະລຸນາປ້ອນຊື່ບ້ານເກີດ"
                        name="villageNow"
                        value={values.villageNow}
                        onChange={handleChange}
                        isInvalid={!!errors.villageNow}
                      >
                      </Form.Control>
                    </Form.Group>
                  </Row>

                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "center",
                    justifyContent: "end",
                    marginTop: 40,
                    marginBottom: 40,
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      marginRight: "30px", backgroundColor: "darkgray", '&:hover': {
                        backgroundColor: "gray"
                      }
                    }}
                    startIcon={<KeyboardDoubleArrowLeftIcon />}
                    onClick={() => history.goBack()}
                  >
                    ກັບຄືນ
                  </Button>
                  <Button
                    variant="contained"
                    endIcon={<KeyboardDoubleArrowRightIcon />}
                    onClick={handleSubmit}
                  >
                    ໜ້າຕໍ່ໄປ
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* ------- AddConfirm Modal ------ */}
        <TeacherAddConfirm
          showAddConfirmModal={showAddConfirmModal}
          _handleShowAddConfirmModalClose={
            _handleShowAddConfirmModalClose
          }
        />

      </CustomContainer>
    </div>
  );
}

export default TeacherFormPlaceOfBirth;
