import axios from "axios";
import { USER_KEY } from "../consts/";
import { NEW_API_URL, API_KEY } from "../common/contrant";

const addLogs = async ({ type, status, data, action, student, students }) => {
  try {
    const user = localStorage.getItem(USER_KEY);
    if (!user) {
      throw new Error("User data not found in localStorage");
    }
    const userObject = JSON.parse(user);
    const token = userObject?.accessToken;

    await axios.post(
      `${NEW_API_URL}/logs`,
      {
        type,
        data,
        status,
        students,
        action,
        // case edit student
        student
      },
      {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Error adding logs:", error);
    // You might want to handle or propagate this error further depending on your application's requirements
  }
};

export default addLogs;
