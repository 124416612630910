import React from 'react'
import useReactRouter from "use-react-router";
import { Breadcrumb, Tabs, Tab } from "react-bootstrap";
import GradesCourseDetial from './GradesCourseDetial'
import SentGradesDetail from '../sendGrades/sentGradedetail'
import HistoryReceiveGrade from '../sendGrades/historyReceiveGrade'

export default function TapGrade() {
    const { history } = useReactRouter();

    return (
        <div >
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push("/grades-index/1")} >
                    ຄະແນນ
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push("/grades-index/1")} >
                    ເລືອກໝວດໝູ່
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push("/grades-course-list/limit/40/skip/1")}>
                    ວິຊາທັງໝົດ
                </Breadcrumb.Item>
                <Breadcrumb.Item>ລາຍລະອຽດວິຊາ</Breadcrumb.Item>
            </Breadcrumb>
            
            <Tabs
                style={{ marginLeft: 10, marginRight: 10,marginTop: -16 }}
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
            >
                <Tab eventKey="home" title="ລາຍລະອຽດວິຊາ">
                    <GradesCourseDetial />
                </Tab>
                <Tab eventKey="profile" title="ຄະແນນ">
                    <SentGradesDetail />
                </Tab>
                <Tab eventKey="contact" title="ປະຫວັດການຢືນຢັນ">
                    <HistoryReceiveGrade />
                </Tab>
            </Tabs>
        </div>
    )
}
