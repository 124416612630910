import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_TRIBEMASTER } from '../../../apollo/tribeMaster/index'
import { CustomButton } from '../../../common'
import { Modal, Form } from 'react-bootstrap'
import { valiDate } from '../../../common/super'
import { Formik } from 'formik'
const EditeTribes = ({ showSearchView, _handleSearchViewClose, allData }) => {
  const [updateTribeMaster] = useMutation(UPDATE_TRIBEMASTER, {
    variables: {
      where: { id: allData?.id }
    }
  })
  const _editTribes = data => {
    updateTribeMaster({
      variables: {
        data: {
          name: data.name,
          note: data.note
        }
      }
    })
      .then(async () => {
        window.location.reload(true)
      })
      .catch(err => {})
  }
  return (
    <Modal show={showSearchView} onHide={_handleSearchViewClose} size='lg'>
      <Formik
        initialValues={{
          name: allData?.name,
          note: allData?.note
        }}
        validate={values => {
          const errors = {}
          if (!values.name) {
            errors.name = 'Required'
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          let data = {
            name: values.name,
            note: values.note
          }
          _editTribes(data)
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
          <div>
            <Modal.Title style={{ textAlign: 'center', paddingTop: 20 }}>
              ແກ້ໄຂຊົນເຜົ່າ
            </Modal.Title>
            <Modal.Body
              style={{ marginLeft: 50, marginRight: 50, padding: 50 }}
            >
              <Form>
                <Form.Label>
                  ຊື່ຊົນເຜົ່າ {valiDate()}
                </Form.Label>
                <Form.Control
                  type="text"
                  name='name'
                  onChange={handleChange}
                  value={values.name}
                  placeholder='ກະລຸນາປ້ອນຊື່ຊົນເຜົ່າ'
                  isInvalid={!!errors.name}
                />
                <Form.Label>ໝາຍເຫດ</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  onChange={handleChange}
                  name='note'
                  value={values.note}
                  placeholder='ກະລຸນາປ້ອນໝາຍເຫດ(ຖ້າມີ)'
                />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <CustomButton
                title='ຍົກເລີກ'
                onClick={() => _handleSearchViewClose()}
              />
              <CustomButton onClick={handleSubmit} confirm title='ບັນທຶກ' />
            </Modal.Footer>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default EditeTribes
