import React from "react";
import { Modal } from "react-bootstrap";
import { CustomContainer, Title } from "../../../common";
import { formatDateTime, convertLetterGrade } from "../../../common/super";
export default function HistoryLogDetail({
  logsData,
  oldData,
  show,
  onHide,
}) {
  return (
    <CustomContainer>
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton className="closeModal"></Modal.Header>
        <Modal.Body>
          {/* title */}
          <Title text="ລາຍລະອຽດການປ່ຽນແປງຂໍ້ມູນ"></Title>
          {/* body list */}
          <div className="row">
            <div className="col-md-6">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ width: 200 }}>ຊື່ຟິວ</th>
                    <th>ຂໍ້ມູນເກົ່າ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{"ລະຫັດລົງທະບຽນ"}</td>
                    <td>{oldData?.id}</td>
                  </tr>
                  <tr>
                    <td>{"ລະຫັດນັກຮຽນ"}</td>
                    <td>{logsData?.student?.studentNumber?.__old}</td>
                  </tr>
                  <tr>
                    <td>{"ພາກຮຽນ"}</td>
                    <td>{oldData?.semester}</td>
                  </tr>
                  <tr>
                    <td>{"ປີຮຽນ"}</td>
                    <td>{logsData?.yearLevel?.__old}</td>
                  </tr>
                  <tr>
                    <td>{"ສົກຮຽນ"}</td>
                    <td>{logsData?.assessmentYear?.__old}</td>
                  </tr>
                  <tr>
                    <td>{"ຄະແນນ"}</td>
                    <td>{logsData?.numericGrade?.__old}</td>
                  </tr>
                  <tr>
                    <td>{"ເກຣດ"}</td>
                    <td>{convertLetterGrade(logsData?.numericGrade?.__old)}</td>
                  </tr>
                  <tr>
                    <td>{"ວັນທີສ້າງ"}</td>
                    <td>{formatDateTime(logsData?.updatedAt?.__old)}</td>
                  </tr>
                  <tr>
                    <td>{"ສ້າງໂດຍ"}</td>
                    <td>{logsData?.updatedBy?.__old}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ width: 200 }}>ຊື່ຟິວ</th>
                    <th>ຂໍ້ມູນໃໝ່</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{"ລະຫັດລົງທະບຽນ"}</td>
                    <td>{logsData?.registration?.__new}</td>
                  </tr>
                  <tr>
                    <td>{"ລະຫັດນັກຮຽນ"}</td>
                    <td>{logsData?.student?.studentNumber?.__new}</td>
                  </tr>
                  <tr>
                    <td>{"ພາກຮຽນ"}</td>
                    <td>{logsData?.semester?.__new}</td>
                  </tr>
                  <tr>
                    <td>{"ປີຮຽນ"}</td>
                    <td>{logsData?.yearLevel?.__new}</td>
                  </tr>
                  <tr>
                    <td>{"ສົກຮຽນ"}</td>
                    <td>{logsData?.assessmentYear?.__new}</td>
                  </tr>
                  <tr>
                    <td>{"ຄະແນນ"}</td>
                    <td>{logsData?.numericGrade?.__new}</td>
                  </tr>
                  <tr>
                    <td>{"ເກຣດ"}</td>
                    <td>{convertLetterGrade(logsData?.numericGrade?.__new)}</td>
                  </tr>
                  <tr>
                    <td>{"ວັນທີສ້າງ"}</td>
                    <td>{formatDateTime(logsData?.updatedAt?.__new)}</td>
                  </tr>
                  <tr>
                    <td>{"ສ້າງໂດຍ"}</td>
                    <td>{logsData?.updatedBy?.__new}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </CustomContainer>
  );
}
