import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";
import queryString from "query-string";
import moment from "moment";

/**
 *
 * 
 * @Library
 *
 * 
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";

/**
 *
 * 
 * @Component
 *
 * 
 */
import DocumentUpload from "./DocumentUpload";
import {CustomContainer, TableHeader, TableCell, CustomButton, Title} from "../../common";
import { Breadcrumb, OverlayTrigger, Tooltip, Form, Row, Col } from "react-bootstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../common/Loading";

/**
 *
 * 
 * @Constant
 *
 * 
 */
import Consts from "../../consts";
import Routers from "../../consts/router";

/**
 *
 * 
 * @Apollo
 *
 * 
 */
import { QUERY_FILE } from "../../apollo/doc";
/**
 *
 * 
 * @Function
 *
 * 
 */
import { formatDate } from "../../common/super";
import PaginationHelper from "../../helpers/PaginationHelper";

function DocumentList() {
  const { history, location } = useReactRouter();
  const parsed = queryString?.parse(location?.state);

  /**
   *
   * @State
   *
   */
  const [filesData, setFilesData] = useState([]);
  const [fileCat, setFileCat] = useState("");
  const [docCount, setDocCount] = useState(0);
  const [isClicked, setIsClicked] = useState(0);
  const [total, setTotal] = useState(0);
  const [title, setTitle] = useState("");

  const [titleName, setTitleName] = useState(!parsed?.titleName ? "" : parsed?.titleName);
  const [startDate, setStartDate] = useState(!parsed?.startDate ? "" : parsed?.startDate);
  const [endDate, setEndDate] = useState(!parsed?.endDate ? "" : parsed?.endDate);

  const { _limit, _skip, set_skip, set_limit, Pagination_helper } = PaginationHelper();
  /**
   *
   * @Apollo
   *
   */

  const [loadFiles, { loading, data: normalFilesData }] = useLazyQuery(QUERY_FILE, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    console.log("normalFilesData")
    console.log(normalFilesData)
  },[normalFilesData])

  /**
   *
   * @UseEffect
   *
   */

  // Watch effect from normalFilesData
  useEffect(() => {
    if (!normalFilesData) return;
    setFilesData(normalFilesData?.files?.data);
    setTotal(normalFilesData?.files?.total);
    setDocCount(normalFilesData?.files?.total);
  }, [normalFilesData]);

  // on first load
  useEffect(() => {
    loadFiles({
      fetchPolicy: "cache-and-network",
      variables: {
        where: {
          isDeleted: false,
          type: "PUBLIC_FILE",
        },
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });
    setTitle("ເອກະສານທັງໝົດ");
  }, []);

  useEffect(() => {
    var _where = { type: "PUBLIC_FILE", isDeleted: false }
    if (fileCat !== "") { _where = { ..._where, cate: fileCat } }
    if (titleName !== "") { _where = { ..._where, title: titleName } }
    if (startDate !== "") { _where = { ..._where, createdAt_gte: startDate } }
    if (endDate !== "") { _where = { ..._where, createdAt_lt: endDate } }

    loadFiles({
      variables: {
        where: _where,
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });

  }, [fileCat, startDate, endDate, titleName]);

  // States
  const [showUploadView, setShowUploadView] = useState(false);



  useEffect(() => {
    console.log("hello")
  },[])
  const _handleUploadViewClose = () => setShowUploadView(false);
  const _handleUploadViewShow = () => setShowUploadView(true);


  const _documentDetail = (data) => { history.push(Routers.DOCUMENT_DETAIL + '/id/' + data?.id, data) };

  function showBorder(status) {
    // Status : 0 = ທັງໝົດ 1 = ບົດຄົ້ນຄວ້າ 2 = ຄວາມຮູ້ທົ່ວໄປ 3 = ເອກະສານວິຊາສະເພາະ
    setIsClicked(status);
    if (status == 0) {
      setFileCat("");
    } else if (status == 1) {
      setFileCat("RESEARCH");
    } else if (status == 2) {
      setFileCat("GENERAL");
    } else if (status == 3) {
      setFileCat("SPECIFIC");
    }
  }


  const _keyTitleName = (e) => {
    if (e.key === "Enter") {
      set_skip(1);
      set_limit(40);
      setTitleName(e.target.value)
    }
  }

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item active>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
      <Title text={title} />
        <div style={{textAlign: 'right'}}>
      <CustomButton
            confirm
            downloadIcon
            width={200}
            title="ອັບໂຫລດເອກະສານ"
            onClick={() => _handleUploadViewShow()}
          />
          </div>
          <div>
        <Row>
          <Col md="6">
            <p style={{ padding: 0, marginBottom: 10 }}>ຄົ້ນຫາ {" "}
              <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">ປ້ອນຊື່ເອກະສານ Enter ເພື່ອຄົ້ນຫາ</Tooltip>}>
                <FontAwesomeIcon icon={faInfoCircle} color={Consts.PRIMARY_COLOR} />
              </OverlayTrigger>
            </p>

            <Form.Control type="text" placeholder="ປ້ອນຊື່ເອກະສານ" name="titleName" defaultValue={titleName}
              onKeyDown={(e) => _keyTitleName(e)}
            />
          </Col>
          <Col md='3'>
            <Form.Label>ວັນທີຫາ:</Form.Label>
            <Form.Control type="date" name="startDate" defaultValue={moment(startDate).format("YYYY-MM-DD")}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Col>
          <Col md='3'>
            <Form.Label>ວັນທີ:</Form.Label>
            <Form.Control type="date" name="endDate" defaultValue={moment(endDate).format("YYYY-MM-DD")}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Col>
        </Row>
        {/* muad */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: 30,
          }}
        >
          <div style={{ width: 80 }}>ຫມວດ</div>
          <div
            onClick={() => showBorder(0)}
            style={{
              width: 80,
              marginRight: 30,
              textAlign: "center",
              cursor: "pointer",
              borderBottom: isClicked == 0 ? "5px solid #7BB500" : "none",
            }}
          >
            ທັງໝົດ
          </div>
          <div
            onClick={() => showBorder(1)}
            style={{
              width: 80,
              marginRight: 40,
              textAlign: "center",
              cursor: "pointer",
              borderBottom: isClicked == 1 ? "5px solid #7BB500" : "none",
            }}
          >
            ບົດຄົ້ນຄ້ວາ
          </div>
          <div
            onClick={() => showBorder(2)}
            style={{
              width: 100,
              marginRight: 40,
              textAlign: "center",
              cursor: "pointer",
              borderBottom: isClicked == 2 ? "5px solid #7BB500" : "none",
            }}
          >
            ຄວາມຮູ້ທົ່ວໄປ
          </div>
          <div
            onClick={() => showBorder(3)}
            style={{
              width: 140,
              cursor: "pointer",
              borderBottom: isClicked == 3 ? "5px solid #7BB500" : "none",
            }}
          >
            ເອກະສານວິຊາສະເພາະ
          </div>
        </div>

        {/* ເອກະສານທັງໝົດ */}
        <div style={{ marginTop: 24, marginBottom: 8, fontSize: 16, color: Consts.FONT_COLOR_SECONDARY }}>
          ທັງໝົດ {total}
        </div>

        {/* Table list */}
        <div>
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader>
                <th style={{ width: 70 }}> ລຳດັບ</th>
                <th>ຊື່ເອກະສານ</th>
                <th>ສ້າງໂດຍ</th>
                <th>ອັບເດດ</th>
                <th style={{ width: 200 }}>ຈັດການ</th>
              </TableHeader>
            </thead>
            {loading ? (
              <Loading />
            ) : (
              filesData?.map((x, index) => {
                return (
                  <tbody key={index}>
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                    >
                      <TableCell style={{ width: 60 }}>
                        {index + 1 + _limit * (_skip - 1)}
                      </TableCell>
                      <TableCell style={{ textAlign: "left" }}>
                        {x.title}
                      </TableCell>
                      <TableCell>
                        {x.createdBy?.firstname ?? "-"} {x.createdBy?.lastname}
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        {formatDate(x.updatedAt).toLocaleString("la-LA", {
                          hour12: false,
                        })}
                      </TableCell>
                      <TableCell style={{ width: 200 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            width: 200,
                          }}
                        >
                          <a href={x.file} download>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  ດາວໂຫລດເອກະສານ
                                </Tooltip>
                              }
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#FFFFFF",
                                  padding: 3,
                                  width: 64,
                                  borderRadius: 4,
                                }}
                              >
                                <i
                                  className="fa fa-download"
                                  style={{ color: Consts.BORDER_COLOR }}
                                />
                              </div>
                            </OverlayTrigger>
                          </a>

                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                ເບິ່ງລາຍລະອຽດ
                              </Tooltip>
                            }
                          >
                            <div
                              onClick={() => _documentDetail(x)}
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#FFFFFF",
                                padding: 3,
                                width: 64,
                                borderRadius: 4,
                              }}
                            >
                              <FontAwesomeIcon
                                icon={["fas", "eye"]}
                                color={Consts.BORDER_COLOR}
                              />
                            </div>
                          </OverlayTrigger>
                        </div>
                      </TableCell>
                    </tr>
                  </tbody>
                );
              })
            )}
          </table>
        </div>
        </div>
        {Pagination_helper(docCount, Routers.DOCUMENT_LIST, `titleName=${titleName}&&startDate=${startDate}&&endDate=${endDate}`)}
        {/* </CustomContainer> */}

        {/* Upload Modal */}
        <DocumentUpload
          showUploadView={showUploadView}
          _handleUploadViewClose={_handleUploadViewClose}
        />
      </CustomContainer>
    </div>
  );
}

export default DocumentList;
