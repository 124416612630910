import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";

/**
 * @Library
 */
import { Formik } from "formik";
import { useSelector } from 'react-redux'

/**
 *
 * @Component
 *
 */

import { Breadcrumb, Form, Row, Col } from "react-bootstrap";
import { CustomContainer, Title, CustomButton } from "../../common";
import TeacherEditConfirm from "./TeacherEditConfirm";
import Loading from "../../common/Loading";
/**
 *
 * @Constant
 *
 */
import { Progress } from "reactstrap";
import Consts from "../../consts";
import Routers from "../../consts/router";
/**
 *
 * @Apollo
 *
 */
import { QUERY_USER_EDIT, FACULTIES_EDIT_TEACHER, DEPARTMENTS_EDIT_TEACHER } from "./apollo/query";

import { convertRole } from '../../helpers/user'

function TeacherEdit() {
  const { history, location } = useReactRouter();
const accessmentRedux = useSelector((state) => state.accessment.value)

  //Constant
  const ALLOW_ROLE = ["ADMIN", "FAC_ADMIN", "DEP_ADMIN", "TEACHER", "DEAN"];

  // States
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
  const [formParam, setFormParam] = useState({});
  const [userData, setUserData] = useState(null);
  const [dataFaculty, setDataFaculty] = useState([]);
  const [dataDepartments, setDataDepartments] = useState([]);
  const [strengthPass, setStrengthPass] = useState(0);
  const [checkPasswordLength, setCheckPasswordLength] = useState("");

  const [loadDataUsers, { loading, data: usersData }] = useLazyQuery(QUERY_USER_EDIT);

  const [loadDataFacultie, { data: facultyData }] = useLazyQuery(FACULTIES_EDIT_TEACHER);
  const [loadDataDepartment, { data: departmentData }] = useLazyQuery(DEPARTMENTS_EDIT_TEACHER);


  useEffect(() => {
    loadDataUsers({ variables: { where: { id: location.state.id } },})
    loadDataFacultie({ variables:{where: {isDeleted: false, assessmentYear: accessmentRedux}}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(usersData?.user) setUserData(usersData?.user)
    loadDataDepartment({variables: {where:{faculty: usersData?.user?.faculty?.id, isDeleted: false, assessmentYear: accessmentRedux}}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData]);

  useEffect(() => {
    setDataFaculty(facultyData?.faculties)
  }, [facultyData]);

  useEffect(() => {
    setDataDepartments(departmentData?.departments)
  }, [departmentData]);

  // Set states
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
  const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);

  //Get Data from Server
  const _cancel = () => {
    history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION);
  };

  const _edit = () => {
    _handleShowAddConfirmModalShow();
  };

  function checkPassword(password) {
    var strength = 0;
    if (password.match(/[a-z]+/)) {
      strength += 1;
    }
    if (password.match(/[A-Z]+/)) {
      strength += 1;
    }
    if (password.match(/[0-9]+/)) {
      strength += 1;
    }
    if (password.match(/[$@#&!%*_?]+/)) {
      strength += 1;
    }

    switch (strength) {
      case 0:
        setStrengthPass(0);
        break;

      case 1:
        setStrengthPass(25);
        break;

      case 2:
        setStrengthPass(50);
        break;

      case 3:
        setStrengthPass(75);
        break;

      case 4:
        setStrengthPass(100);
        break;
    }
  }
  const checkLength = (password) => {
    if (password && password.length < 6) {
      setCheckPasswordLength("ກະລຸນາປ້ອນໜ້ອຍສຸດຢ່າງໜ້ອຍ 6 ໂຕຂຶ້ນໄປ");
    } else {
      setCheckPasswordLength("");
    }
  };
  const _selectFaculty = (e) => {
    if(e.target.value){
      loadDataDepartment({
        variables:{
          where:{
          assessmentYear: accessmentRedux,
          isDeleted: false,
          faculty: e.target.value
          }
        }
      })
    }
  }

  if (loading) return <Loading />;
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() => {
            history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION);
          }}
        >
          ຈັດການອາຈານ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION)}
        >
          ອາຈານທັງໝົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ແກ້ໄຂຂໍ້ມູນ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <Title text="ແກ້ໄຂຂໍ້ມູນ" />
        {userData && (
          <Formik
            initialValues={{
              department: userData?.department ? userData?.department?.id : "",
              faculty: userData?.faculty ? userData?.faculty?.id : "",
              firstname: userData?.firstname || "",
              lastname: userData?.lastname || "",
              phone: userData?.phone || "",
              email: userData?.email || "",
              description: userData?.description || "",
              role: userData?.role || "",
              userId: userData?.userId || "",
              password: "",
              confirmPassword: "",
              note: userData?.note || "",
              birthday: userData?.birthday ?? "",
              gender: userData?.gender || "MALE",
              maritualStatus: userData?.maritualStatus || "SINGLE",
            }}
            validate={(values) => {
              const errors = {};
              checkPassword(values.password);
              checkLength(values.password);
              if (!values.firstname) {
                errors.firstname = "ກະລຸນາຕື່ມຊື່";
              }
              if (!values.userId) {
                errors.userId = "ໄອດີ";
              }
              if (values.password && !values.confirmPassword) {
                errors.confirmPassword = "ກະລຸນາຕື່ມ";
              }
              if (
                values.confirmPassword &&
                values.confirmPassword !== values.password
              ) {
                errors.confirmPassword = "ລະຫັດບໍ່ຕົງກັນ";
              }
              return errors;
            }}
            onSubmit={(values) => {
              if (!values.phone || values.phone === userData.phone) {
                delete values.phone;
                values = {
                  ...values,
                  checkUpdatePhone: "Not change"
                }
              }
              if (!values.email || values.email === userData.email) {
                delete values.email;
              }
              if (!values.password) {
                delete values.password;
              }
              let data = {  ...values};
              let paramQL = {
                where: { id: userData.id},
                data,
              };
              setFormParam(paramQL);
              _edit();
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
            }) => (
              <div>
                {/* Form container */}
                <div
                  style={{
                    width: "80%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <Form noValidate>
                    {/* ---------- ຄະນະແລະພາກວິຊາ --------- */}
                    <div style={{ marginBottom: 10 }}>
                      <div>
                        <i
                          className="fa fa-caret-down"
                          aria-hidden="true"
                          style={{
                            marginRight: 5,
                            color: Consts.SECONDARY_COLOR,
                          }}
                        />
                        ຄະນະແລະພາກວິຊາ
                      </div>
                      {facultyData && (
                        <Form.Group
                          as={Row}
                          style={{
                            margin: 0,
                            marginBottom: 10,
                            paddingLeft: 20,
                            fontSize: 16,
                          }}
                        >
                          <Form.Label column sm="4" className="text-left">
                            ຄະນະ
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              as="select"
                              name="faculty"
                              onChange={(e) => {
                                handleChange(e);
                                _selectFaculty(e);
                              }}
                              value={values.faculty}
                            >
                              <option disabled={true} value="">
                                ---ກະລຸນາເລືອກຄະນະ---
                              </option>
                              {dataFaculty?.map((fac, index) => (
                                <option key={index} value={fac?.id}>{fac?.name}</option>
                              ))}
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      )}

                      {/* ຄະນະ */}
                          <Form.Group
                            as={Row}
                            style={{
                              margin: 0,
                              marginBottom: 10,
                              paddingLeft: 20,
                              fontSize: 16,
                            }}
                          >
                            <Form.Label column sm="4" className="text-left">
                              ພາກວິຊາ
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                as="select"
                                name="department"
                                value={values.department}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                
                              >
                                <option value="">
                                  ---ກະລຸນາເລືອກພາກວິຊາ---
                                </option>
                                {dataDepartments?.map((x, index) => (
                                      <option key={index} value={x.id}> {x.name}</option>
                                    )
                                  )}
                              </Form.Control>
                            </Col>
                          </Form.Group>
                    </div>

                    {/* ---------- ຂໍ້ມູນອາຈານ --------- */}
                    <div style={{ marginBottom: 10 }}>
                      <div>
                        <i
                          className="fa fa-caret-down"
                          aria-hidden="true"
                          style={{
                            marginRight: 5,
                            color: Consts.SECONDARY_COLOR,
                          }}
                        />
                        ຂໍ້ມູນອາຈານ
                      </div>
                      {/* ຊື່ */}
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ຊື່
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            placeholder="ກະລຸນາປ້ອນ"
                            name="firstname"
                            value={values.firstname}
                            onChange={handleChange}
                            isInvalid={!!errors.firstname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.firstname}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      {/* ນາມສະກຸນ */}
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ນາມສະກຸນ
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            placeholder="ກະລຸນາປ້ອນ"
                            name="lastname"
                            value={values.lastname}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>

                      {/* ວັນເດືອນປີເກີດ */}
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ວັນເດືອນປີເກີດ
                        </Form.Label>
                        <Col sm="8">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <Form.Control
                            type="date"
                            name="birthday"
                            value={values.birthday}
                            onChange={handleChange}
                          />
                          </div>
                        </Col>
                      </Form.Group>

                      {/* ເພດ */}
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                        name="gender"
                        onChange={handleChange}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ເພດ
                        </Form.Label>
                        <Col sm="8">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <Col sm={4}>
                              <input
                                id="male"
                                value="MALE"
                                name="gender"
                                checked={
                                  values.gender === "MALE" ? true : false
                                }
                                type="radio"
                              />
                              {"\t"}
                              <label htmlFor="male">ຊາຍ</label>
                            </Col>
                            <Col sm={4}>
                              <input
                                id="female"
                                value="FEMALE"
                                name="gender"
                                checked={
                                  values.gender === "FEMALE" ? true : false
                                }
                                type="radio"
                              />
                              {"\t"}
                              <label htmlFor="female">ຍິງ</label>
                            </Col>
                            {/* <Col sm={4}>
                              <input
                                id="other"
                                value="OTHER"
                                name="gender"
                                checked={values.gender == 'OTHER' ? true : false}
                                type="radio"
                              />{'\t'}
                              <label htmlFor="other">ອື່ນໆ</label>
                            </Col> */}
                          </div>
                        </Col>
                      </Form.Group>

                      {/* ສະຖານະ */}
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                        name="maritualStatus"
                        onChange={handleChange}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ສະຖານະ
                        </Form.Label>
                        <Col sm="8">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <Col sm={4}>
                              <input
                                id="single"
                                value="SINGLE"
                                name="maritualStatus"
                                checked={
                                  values.maritualStatus === "SINGLE"
                                    ? true
                                    : false
                                }
                                type="radio"
                              />
                              {"\t"}
                              <label htmlFor="single">ໂສດ</label>
                            </Col>
                            <Col sm={4}>
                              <input
                                id="marriage"
                                value="MARRIAGE"
                                name="maritualStatus"
                                checked={
                                  values.maritualStatus === "MARRIAGE"
                                    ? true
                                    : false
                                }
                                type="radio"
                              />
                              {"\t"}
                              <label htmlFor="marriage">ແຕ່ງງານ</label>
                            </Col>
                          </div>
                        </Col>
                      </Form.Group>

                      {/* ເບີໂທ */}
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ເບີໂທ
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            placeholder="ກະລຸນາປ້ອນ"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>

                      {/* ອີເມວ */}
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ອີເມວ
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            placeholder="ກະລຸນາປ້ອນ"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                    </div>

                    {/* ---------- ໄອດີ ແລະ ລະຫັດຜ່ານ --------- */}
                    <div style={{ marginBottom: 10 }}>
                      <div>
                        <i
                          className="fa fa-caret-down"
                          aria-hidden="true"
                          style={{
                            marginRight: 5,
                            color: Consts.SECONDARY_COLOR,
                          }}
                        />
                        ໄອດີ ແລະ ລະຫັດຜ່ານ
                      </div>
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                      >
                        {/* User Role */}
                        <Form.Label column sm="4" className="text-left">
                          ລະດັບຜູ້ໃຊ້
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            as="select"
                            name="role"
                            value={values.role}
                            onChange={handleChange}
                            isInvalid={!!errors.role}
                          >
                            <option disabled={true} value={""}>
                              ເລືອກລະດັບຜູ້ໃຊ້
                            </option>
                            {ALLOW_ROLE.map((role, index) => (
                              <option value={role} key={index}>
                                {convertRole(role)}
                              </option>
                            ))}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.role}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      {/* ໄອດີ */}
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ໄອດີ
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            disabled={true}
                            placeholder="ກະລຸນາປ້ອນ"
                            name="userId"
                            value={values.userId}
                            onChange={handleChange}
                            isInvalid={!!errors.userId}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.userId}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      {/* ລະຫັດຜ່ານ */}
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ລະຫັດຜ່ານ
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            placeholder="ປ້ອນເພື່ອປ່ຽນລະຫັດຜ່ານ"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                          />
                          <div>
                            {/* <ProgressBar variant="progress-bar-low" now={100} /> */}
                            <Progress multi>
                              <Progress
                                bar
                                color="danger"
                                value={strengthPass >= 25 ? 25 : 0}
                              >
                                {strengthPass < 25 ? "" : "ຕ່ຳ"}
                              </Progress>
                              <Progress
                                bar
                                color="warning"
                                value={strengthPass >= 50 ? 25 : 0}
                              >
                                {strengthPass < 50 ? "" : "ກາງ"}
                              </Progress>
                              <Progress
                                bar
                                color="info"
                                value={strengthPass >= 75 ? 25 : 0}
                              >
                                {strengthPass < 75 ? "" : "ສູງ"}
                              </Progress>
                              <Progress
                                bar
                                color="success"
                                value={strengthPass >= 100 ? 25 : 0}
                              >
                                {strengthPass < 100 ? "" : "ສຸດຍອດ"}
                              </Progress>
                            </Progress>
                          </div>
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                          {checkPasswordLength ? (
                            <p style={{ color: "red", fontSize: 12 }}>
                              {checkPasswordLength}
                            </p>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Form.Group>

                      {/* ຍືນຍັນລະຫັດຜ່ານ */}
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ຍືນຍັນລະຫັດຜ່ານ
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            placeholder="ກະລຸນາປ້ອນ"
                            name="confirmPassword"
                            value={values.confirmPassword}
                            onChange={handleChange}
                            isInvalid={!!errors.confirmPassword}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.confirmPassword}
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </div>

                    {/* ---------- ອື່ນໆ --------- */}
                    <div style={{ marginBottom: 10 }}>
                      <div>
                        <i
                          className="fa fa-caret-down"
                          aria-hidden="true"
                          style={{
                            marginRight: 5,
                            color: Consts.SECONDARY_COLOR,
                          }}
                        />
                        ອື່ນໆ
                      </div>

                      {/* ລາຍລະອຽດ */}
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ລາຍລະອຽດ
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            placeholder="ກະລຸນາປ້ອນ"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>

                      {/* ໝາຍເຫດ */}
                      <Form.Group
                        as={Row}
                        style={{
                          margin: 0,
                          marginBottom: 10,
                          paddingLeft: 20,
                          fontSize: 16,
                        }}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ໝາຍເຫດ
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            placeholder="ກະລຸນາປ້ອນ"
                            name="note"
                            value={values.note}
                            onChange={handleChange}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </Form>

                  {/* Buttons */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "center",
                      justifyContent: "center",
                      marginTop: 40,
                      marginBottom: 40,
                    }}
                  >
                    <div style={{ marginRight: 80 }}>
                      <CustomButton title="ຍົກເລີກ" onClick={() => _cancel()} />
                    </div>

                    <CustomButton
                      confirm
                      title="ບັນທຶກການແກ້ໄຂ"
                      onClick={handleSubmit}
                      type="submit"
                    />
                  </div>
                </div>

                {/* ------- AddConfirm Modal ------ */}
                <TeacherEditConfirm
                  showAddConfirmModal={showAddConfirmModal}
                  _handleShowAddConfirmModalClose={
                    _handleShowAddConfirmModalClose
                  }
                  param={formParam}
                  faculty={facultyData}
                  department = {departmentData}
                />
              </div>
            )}
          </Formik>
        )}
      </CustomContainer>
    </div>
  );
}

export default TeacherEdit;
