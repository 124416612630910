import React, { useState, useEffect } from 'react'
import useReactRouter from 'use-react-router'
/**
 *
 * @Library
 *
 */
import { Formik } from 'formik'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import * as _ from 'lodash'
import { useSelector } from 'react-redux'
/**
 *
 * @Component
 *
 */
import UpgradeNav from '../common/UpgradeNav'
import Empty from '../../../common/Empty'
import Loading from '../../../common/Loading'
import { Modal, Col, Row } from 'react-bootstrap'
import { CustomContainer, TableHeader, CustomButton } from '../../../common'

/**
 *
 * @Constant
 *
 */
/**
 *
 * @Apollo
 *
 */
import {
  GRADES,
  GRADES_FILTER,
  UPGRADE_GRADES,
} from '../../../apollo/registrations'
/**
 *
 * @Function
 *
 */
import { alertSuccess, alertWarning, setGenders } from '../../../common/super'
import './style.css'
import { GRADES_FOR_UPGRADE } from '../../../apollo/studentResisterUpgrade'


export default function RegisterGradeCorrection() {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const user = JSON.parse(window.localStorage.getItem('user')).data
  /**
 *
 * @State
 *
 */
  const [selectedStudent, setSelectedStudent] = useState()
  const [idStudent, setIdstudent] = useState('')
  const [show, setShow] = useState(false)
  const [newData, setNewData] = useState([])
  const handleClose = () => setShow(false)
  const handleShow = e => setShow(true)
  const { match } = useReactRouter()
  const pageNambers = match?.params?.page
  const PAGE_LIMIT = 50
  const ROLE = user.role
  /**
   *
   * @Apollo
   *
   */
  const [upgradeGrade] = useMutation(UPGRADE_GRADES)
  // useQuery GRADE_IMPROVES
  const [loadDataGrades, { called, loading, data: searchGrades }] = useLazyQuery(GRADES_FOR_UPGRADE)
  // const [filterDataGrades, { data: filterGrades }] = useLazyQuery(GRADES_FILTER)
  /**
   *
   * @UseEffect
   *
   */

  useEffect(() => {
    loadDataGrades({
      variables: {
        where: idStudent ? { student: idStudent, assessmentYear: accessmentRedux.replaceAll(" ", "") } : { assessmentYear: accessmentRedux.replaceAll(" ", "") },
        skip: PAGE_LIMIT * (pageNambers - 1),
        first: PAGE_LIMIT
      }
    })
    // filterDataGrades()
  }, [])

  useEffect(() => {
    if (searchGrades?.gradesForUpgrade?.data) {
      let _newData = []
      for (var i = 0; i < searchGrades?.gradesForUpgrade?.data.length; i++) {
        for (var j = 0; j < _newData.length; j++) {
          if (searchGrades?.gradesForUpgrade?.data[i]?.course?.id === _newData[j]?.course?.id) delete _newData[j]
        }
        _newData.push(searchGrades?.gradesForUpgrade?.data[i])
      }
      let _newData2 = []
      for (var i = 0; i < _newData.length; i++) {
        if (_newData[i]) _newData2.push(_newData[i])
      }
      setNewData(_newData2)
    }
  }, [searchGrades])


  /**
   *
   * @Function
   *
   */

  //  creacte GradeImproves
  const _upgradeGrade = async values => {
    if (ROLE === 'ADMIN') {
      const resUpgradeGrade = await upgradeGrade({
        variables: {
          where: {
            id: values.id
          }
        }
      })
      if (resUpgradeGrade) {
        alertSuccess('ລົງທະບຽນແກ້ເກຣດ')
      } else {
        alertWarning('ລົງທະບຽນແກ້ເກຣດ')
      }
    }
  }

  // onChange to search Id
  const _onIdstudent = () => {
    try {
      loadDataGrades({
        variables: {
          where: idStudent ? { student: idStudent, assessmentYear: accessmentRedux.replaceAll(" ", "") } : { assessmentYear: accessmentRedux.replaceAll(" ", "") },
          skip: PAGE_LIMIT * (pageNambers - 1),
          first: PAGE_LIMIT
        }
      })
    } catch (error) {
      console.log("error: ", error);
    }
  }

  return (
    <div>
      {loading && <Loading />}
      <UpgradeNav />
      <CustomContainer>
        <h2 style={{ fontWeight: 'bold', color: '#137DAE' }}>ຄົ້ນຫານັກສຶກສາ</h2>
        <div className='form-group row'>
          <label className='col-sm-1 col-form-label'>ຄົ້ນຫາ</label>
          <div className='col-sm-9'>
            <input
              type='text'
              className='form-control boxs'
              id='searchtext'
              placeholder='ປ້ອນ ID'
              value={idStudent}
              onChange={e => setIdstudent(e?.target?.value ?? "")}
            />
          </div>
          <div className="col-sm-2">
            <CustomButton
              confirm
              searchIcon
              width={50}
              height={38}
              onClick={() => _onIdstudent()}
            />
          </div>
        </div>
      </CustomContainer>
      {newData?.length > 0 ? (
        <CustomContainer>
          <table className='table table-striped'>
            <thead>
              <TableHeader>
                <th style={{ width: 60, textAlign: "center" }}>ລຳດັບ</th>
                <th>ລະຫັດນັກສຶກສາ</th>
                <th>ຊື່ນັກສຶກສາ</th>
                <th>ນັກສຶກສາປີ</th>
                <th>ພາກຮຽນ</th>
                <th>ສົກຮຽນ</th>
                <th>ຊື່ວິຊາທີ່ແກ້ເກຣດ</th>
                <th>ຊື່ອາຈານປະຈຳວິຊາ</th>
                <th>ເກຣດເກົ່າ</th>
                <th>ຈັດການ</th>
              </TableHeader>
            </thead>
            <tbody>
              {newData?.map((dataGrade, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {(parseInt(pageNambers) - 1) * PAGE_LIMIT + index + 1}
                    </td>
                    <td className='center'>
                      {dataGrade?.student?.studentNumber}
                    </td>
                    <td>
                      {setGenders(dataGrade?.student?.firstNameL) + "" + dataGrade?.student?.firstNameL}{' '}
                      {dataGrade?.student?.lastNameL}
                    </td>
                    <td className='center'>{dataGrade?.yearLevel}</td>
                    <td className='center'>ພາກຮຽນ {dataGrade?.semester}</td>
                    <td className='center'>{dataGrade?.student?.yearLevel}</td>
                    <td>{dataGrade?.course?.title}</td>
                    <td>
                      {dataGrade?.course?.teacher?.map(
                        _teacher => _teacher?.firstname
                      )}
                    </td>
                    <td className='center'>{dataGrade?.letterGrade}</td>
                    <td className='center' style={{ color: '#7BB500' }}>
                      {dataGrade?.course?.isUpgrade === false ? (
                        'ປິດລົງທະບຽນແກ້ເກຣດ'
                      ) : dataGrade?.numberTest > 1 ? (
                        'ລົງທະບຽນແລ້ວ'
                      ) : (
                        <button
                          className='btn addbtn'
                          onClick={e => {
                            setSelectedStudent(dataGrade)
                            return handleShow(e)
                          }}
                        >
                          ລົງທະບຽນ
                        </button>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </CustomContainer>
      ) : (
        <CustomContainer>
          <Empty />
        </CustomContainer>
      )}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size='lg'
        style={{ marginLeft: 60 }}
      >
        <Modal.Header>
          <Modal.Title className='text-center'>ຢືນຢັນການລົງທະບຽນ</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            id: selectedStudent?.id,
            firstNameL: selectedStudent?.student?.firstNameL,
            lastNameL: selectedStudent?.student?.lastNameL,
            studentNumber: selectedStudent?.student?.studentNumber,
            // semester: selectedStudent?.semester,
            title: selectedStudent?.course?.title,
            title_id: selectedStudent?.course?.id,
            teacher_firstname: selectedStudent?.teacher?.firstname,
            teacher_id: selectedStudent?.teacher?.id,
            assessmentYear: selectedStudent?.assessmentYear,
            letterGrade: selectedStudent?.letterGrade,
            id: selectedStudent?.id
          }}
          onSubmit={values => {
            _upgradeGrade(values)
          }}
        >
          {({ handleSubmit }) => (
            <div>
              <Modal.Body>
                <CustomContainer>
                  {/* Table list */}
                  <Row>
                    <Col sm='12' className='heading'>
                      <b>ຂໍ້ມູນທົ່ວໄປ</b>
                    </Col>
                    <Col sm='3' style={{ padding: 10 }}>
                      ລະຫັດນັກສຶກສາ
                    </Col>
                    <Col sm='9' style={{ fontWeight: 'bold', padding: 10 }}>
                      {selectedStudent?.student?.studentNumber}
                    </Col>

                    <Col sm='3' style={{ padding: 10 }}>
                      {' '}
                      ຊື່:
                    </Col>
                    <Col sm='9' style={{ fontWeight: 'bold', padding: 10 }}>
                      {selectedStudent?.student?.firstNameL}
                    </Col>

                    <Col sm='3' style={{ padding: 10 }}>
                      {' '}
                      ນາມສະກຸນ:
                    </Col>
                    <Col sm='9' style={{ fontWeight: 'bold', padding: 10 }}>
                      {selectedStudent?.student?.lastNameL}
                    </Col>

                    <Col sm='3' style={{ padding: 10 }}>
                      {' '}
                      ນັກສຶກສາປີ:
                    </Col>
                    <Col sm='9' style={{ fontWeight: 'bold', padding: 10 }}>
                      {selectedStudent?.student?.yearLevel}{' '}
                    </Col>

                    <Col sm='3' style={{ padding: 10 }}>
                      {' '}
                      ພາກຮຽນ:
                    </Col>
                    <Col sm='9' style={{ fontWeight: 'bold', padding: 10 }}>
                      {selectedStudent?.semester}
                    </Col>

                    <Col sm='12' className='heading'>
                      <b>ວິຊາ</b>
                    </Col>

                    <Col sm='3' style={{ padding: 10 }}>
                      ຊື່ວິຊາທີ່ແກ້ເກຣດ:
                    </Col>
                    <Col sm='9' style={{ fontWeight: 'bold', padding: 10 }}>
                      {selectedStudent?.course?.title}
                    </Col>

                    <Col sm='3' style={{ padding: 10 }}>
                      ຊື່ອາຈານປະຈຳວິຊາ:
                    </Col>
                    <Col sm='9' style={{ fontWeight: 'bold', padding: 10 }}>
                      {selectedStudent?.course?.teacher?.map(
                        _teacher => _teacher?.firstname
                      )}
                    </Col>

                    <Col sm='3' style={{ padding: 10 }}>
                      ສົກຮຽນ:
                    </Col>
                    <Col sm='9' style={{ fontWeight: 'bold', padding: 10 }}>
                      {selectedStudent?.semester}{' '}
                    </Col>

                    <Col sm='3' style={{ padding: 10 }}>
                      ເກຣດເດີມ:
                    </Col>
                    <Col sm='9' style={{ fontWeight: 'bold', padding: 10 }}>
                      {selectedStudent?.letterGrade}{' '}
                    </Col>
                  </Row>
                </CustomContainer>
              </Modal.Body>
              <Modal.Footer>
                <CustomButton title='ຍົກເລີກ' onClick={() => handleClose()} />
                <CustomButton
                  title='ຢືນຢັນການລົງທະບຽນ'
                  style={{ backgroundColor: '#057CAE', color: '#ffffff' }}
                  onClick={() => {
                    handleSubmit()
                    handleClose()
                  }}
                />
              </Modal.Footer>
            </div>
          )}
        </Formik>
      </Modal>
    </div>
  )
}
