import React, { useState, useEffect } from "react";
import { Breadcrumb } from "react-bootstrap";
import useReactRouter from "use-react-router";
import Routers from "../../consts/router";
import Loading from "../../common/Loading";
import { CustomContainer, TableHeader, TableCell } from "../../common";
import { useLazyQuery } from "@apollo/react-hooks";
import { GRADES } from "./apollo/query";


function StudentYearOne() {
    const { history, match } = useReactRouter();
    const studentId = match?.params?.id;
    const [dataGradesSemester1, setDataGradesSemester1] = useState([]);
    const [dataGradesSemester2, setDataGradesSemester2] = useState([]);

    const [loadGrades, { loading: gradeLoading, data: gradesData }] = useLazyQuery(GRADES, { fetchPolicy: "network-only" });

    useEffect(() => {
        loadGrades({
            variables: {
                where: { 
                    sendingGradeStatus: "ADMIN_APPROVED",
                    student: { id: studentId },
                    yearLevel: 1,
                }
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentId]);

    useEffect(() => {
        let grade1 = [...dataGradesSemester1];
        let grade2 = [...dataGradesSemester2];
        for(var i = 0; i < gradesData?.grades?.data?.length; i++){
            if(gradesData?.grades?.data[i]?.semester == 1){
                grade1.push(gradesData?.grades?.data[i])
            }else{
                grade2.push(gradesData?.grades?.data[i])
            }
        }
        setDataGradesSemester1(grade1);
        setDataGradesSemester2(grade2);
        
    }, [gradesData]);

    if (gradeLoading) return <Loading />;

    return (
        <div>
            {/* Breadcrumb */}
            <Breadcrumb>
                <Breadcrumb.Item onClick={() => history.push(Routers.STUDENT_LIST + Routers.PAGE_GINATION)}>
                    ຈັດການນັກສຶກສາ
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push(Routers.STUDENT_LIST + Routers.PAGE_GINATION)}>
                    ລາຍຊື່ນັກສຶກສາທັງໝົດ
                </Breadcrumb.Item>
                <Breadcrumb.Item active>ຄະແນນປີ 1</Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    marginLeft: 10
                }}
            >
                <div
                    className="menuItemTap"
                    onClick={() =>
                        history.push(Routers.STUDENT_DETAIL + "/id/" + studentId)
                    }
                >
                    ຂໍ້ມູນນັກສຶກສາ
                </div>
                <div
                    className="menuItemTap active-menuTap"
                    onClick={() => history.push(Routers.STUDENT_YEAR_ONE + "/id/" + studentId)}
                >
                    ຄະແນນປີ 1
                </div>
                <div
                    className="menuItemTap"
                    onClick={() => history.push(Routers.STUDENT_YEAR_TWO + "/id/" + studentId)}
                >
                    ຄະແນນປີ 2
                </div>
                <div
                    className="menuItemTap"
                    onClick={() => history.push(Routers.STUDENT_YEAR_THREE + "/id/" + studentId)}
                >
                    ຄະແນນປີ 3
                </div>
                <div
                    className="menuItemTap"
                    onClick={() => history.push(Routers.STUDENT_YEAR_FOUR + "/id/" + studentId)}
                >
                    ຄະແນນປີ 4
                </div>

            </div>
            <CustomContainer style={{ marginTop: 0 }}>
                <div style={{ marginTop: 20 }}><span style={{color: "#057CAE"}}>ສົກສຶກສາ {dataGradesSemester1[0]?.student?.assessmentYear}</span> / ເທີມ1</div>
                <div>
                    <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                        <thead>
                            <TableHeader>
                                <th style={{ width: 70 }}> ລຳດັບ</th>
                                <th>ລະຫັດວິຊາ</th>
                                <th>ຊື່ວິຊາ</th>
                                <th style={{ width: 300 }}>ອາຈານສອນ</th>
                                <th>ຄະແນນ</th>
                                <th>ເກຣດ</th>
                            </TableHeader>
                        </thead>
                        <tbody>
                            {dataGradesSemester1 && dataGradesSemester1?.map((item, index) => {
                                return (
                                    <tr key={index} style={{ borderBottom: "2px solid #ffff", textAlign: "center" }}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{item?.course?.courseCode}</TableCell>
                                        <TableCell> {item?.course?.title}</TableCell>
                                        <TableCell>{item?.course?.teacher?.map((teacher, arr) => {return <span key={arr} >{teacher?.firstname} {teacher?.lastname}, </span>})}</TableCell>
                                        <TableCell> {item?.numbericGrade ?? 0} </TableCell>
                                        <TableCell> {item?.letterGrade ?? "-"} </TableCell>
                                    </tr>
                                    
                                    // lastname
                                )
                            })}
                        </tbody>
                    </table>
                </div>


                <div style={{ marginTop: 20 }}><span style={{color: "#057CAE"}}>ສົກສຶກສາ {dataGradesSemester1[0]?.student?.assessmentYear}</span> / ເທີມ2</div>
                <div>
                    <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                        <thead>
                            <TableHeader>
                                <th style={{ width: 70 }}> ລຳດັບ</th>
                                <th>ລະຫັດວິຊາ</th>
                                <th>ຊື່ວິຊາ</th>
                                <th style={{ width: 300 }}>ອາຈານສອນ</th>
                                <th>ຄະແນນ</th>
                                <th>ເກຣດ</th>
                            </TableHeader>
                        </thead>
                        <tbody>
                        {dataGradesSemester2 && dataGradesSemester2?.map((item, index) => {
                                return (
                                    <tr key={index} style={{ borderBottom: "2px solid #ffff", textAlign: "center" }}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{item?.course?.courseCode}</TableCell>
                                        <TableCell> {item?.course?.title}</TableCell>
                                        <TableCell>{item?.course?.teacher?.map((teacher, arr2) => {return <span key={arr2}>{teacher?.firstname} {teacher?.lastname}, </span>})}</TableCell>
                                        <TableCell> {item?.numbericGrade ?? 0} </TableCell>
                                        <TableCell> {item?.letterGrade ?? "-"} </TableCell>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
              
            </CustomContainer>
        </div>
    );
}

export default StudentYearOne;
