import React from "react";
import useReactRouter from "use-react-router";
import { useMutation } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
/**
 *
 * @Component
 *
 */
import { Modal, Button } from "react-bootstrap";
/**
 *
 * @Constant
 *
 */
/**
 *
 * @Apollo
 *
 */
import { DELETE_NOTIC } from "../../apollo/notic";
import { PAGE_GINATION } from "../../consts/router";

/**
 *
 * @Function
 *
 */

function DeleteModal({ data, showDelete, _handleDeleteClose }) {
  const { history } = useReactRouter();
  const [deleteFile] = useMutation(DELETE_NOTIC);

  const _deleteFile = () => {
    deleteFile({ variables: { where: { id: data.id } } })
      .then(async () => {
        await history.push("/notice-list"+PAGE_GINATION);
        window.location.reload(true);
      })
      .catch((err) => {});
  };
  const _handleSubmit = () => {
    _deleteFile();
    _handleDeleteClose();
  };

  return (
    <div>
      <Modal show={showDelete} onHide={_handleDeleteClose} size="md">
        <Modal.Title
          style={{ textAlign: "center", paddingTop: 20, color: "#B80000" }}
        >
          <b>ຕ້ອງການລຶບແຈ້ງການບໍ່?</b>
        </Modal.Title>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: 50,
            marginRight: 50,
            padding: 50,
          }}
        >
          {data?.title}
          <div style={{ display: "flex", marginTop: 20 }}>
            <Button
              onClick={_handleDeleteClose}
              style={{ width: 100, border: "1px solid #B80000" }}
              variant={"none"}
            >
              ຍົກເລີກ
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant={"danger"}
              onClick={_handleSubmit}
              style={{ width: 100 }}
            >
              ຕົກລົງ
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DeleteModal;
