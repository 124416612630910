import { Typography } from "@mui/material";
import React from "react";

export const getScoreStatus = (actionType) => {
  if (actionType === "GIVE" || actionType === "IMPORT") {
    return (
      <Typography
        sx={{
          color: "#4361ee",
        }}
      >
        ໃຫ້ຄະເເນນ
      </Typography>
    );
  } else if (actionType === "APPROVE") {
    return (
      <Typography
        sx={{
          color: "#43aa8b",
        }}
      >
        ຢືນຢັນ
      </Typography>
    );
  } else if (actionType === "REJECT") {
    return (
      <Typography
        sx={{
          color: "#bc4749",
        }}
      >
        ປະຕິເສດ
      </Typography>
    );
  } else if (actionType === "SEND") {
    return (
      <Typography
        sx={{
          color: "#55a630",
        }}
      >
        ສົ່ງຄະເເນນ
      </Typography>
    );
  }
};

export default getScoreStatus;
