import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
/**
 *
 * @Component
 *
 */
import { Row, Col } from "react-bootstrap";
import { CustomContainer, TableHeader } from "../../common";
import Empty from "../../common/Empty";
import Loading from "../../common/Loading";
/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";

/**
 *
 * @Apollo
 *
 */
import { TRIBES_COUNT } from "../../apollo/registrationStudent/index";

/**
 *
 * @Function
 *
 */
import { currency } from "../../common/super";

function RegistrationStudentStatisticTribe() {

  const [loadTribesData, { loading: tribesLoading, data: tribesData }] =
    useLazyQuery(TRIBES_COUNT);

  useEffect(() => {
    let qlWhere = {};
    loadTribesData({ variables: { where: qlWhere } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      if (user.data.role === "ADMIN") {
        let qlWhere = {};

        loadTribesData({ variables: { where: qlWhere } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      } else {
        let qlWhere = {};
        loadTribesData({ variables: { where: qlWhere } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tribesData]);

  return (
    <div>
      {tribesLoading && <Loading />}

      <CustomContainer style={{ marginTop: 0 }}>
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          <Row>
            <Col ms={2}></Col>
            {/* <Col ms={2}>
              {chart && (
                <Chart
                  options={chart.options}
                  series={chart.series}
                  legendAlignment="center"
                  type="donut"
                  width="500"
                  height="300"
                />
              )}
            </Col> */}
            <Col sm={6}></Col>
          </Row>
        </div>

        {tribesData?.tribesCount.length > 0 ? (
          <div>
            {/* {(loading || called) && ""} */}
            <Row>
              <Col sm={3}>
                ສະແດງລາຍການທັງໜົດ {tribesData?.tribesCount.length ?? "0"}
              </Col>
            </Row>
            <table className="table table-hover" style={{ width: "100%" }}>
              <thead>
                <TableHeader>
                  <th style={{ width: 70 }}>ລຳດັບ</th>
                  <th>ຊົນເຜົ່າ</th>
                  <th>ຍິງ</th>
                  <th>ສະຖິຕິທັງໝົດ</th>
                  {/* <th>ຍິງ</th>
                <th>ຊາຍ</th> */}
                </TableHeader>
              </thead>
              <tbody>
                {tribesData?.tribesCount?.map((item, index) => {
                  if (item.name === null) {
                    return;
                  }
                  return (
                    <tr
                      key={"tribe" + index}
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                    >
                      <td>{index} </td>
                      <td>{item.name}</td>
                      <td>{currency(item.female)}</td>
                      <td>{currency(item.total)}</td>
                      {/* <td>{"NAN"}</td>
                    <td>{"NAN"}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <Empty />
        )}
        <div></div>
      </CustomContainer>
    </div>
  );
}

export default RegistrationStudentStatisticTribe;
