import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
// import { useSelector } from 'react-redux'

/**
 *
 * @Library
 *
 */
import { Formik } from "formik";
import * as _ from "lodash";

/**
 *
 * @Component
 *
 */
import { Modal, Button, Form, Breadcrumb, OverlayTrigger, Tooltip} from "react-bootstrap";
import { CustomContainer, TableHeader } from "../../../common";
import GradeNavAddforStudent from "../common/GradeNavAddforStudent";
import Empty from "../../../common/Empty";
import Loading from "../../../common/Loading";

/**
 *
 * @Apollo
 *
 */
import { UPDATE_GRADE, GRADE_COURSES, SEND_GRADE_COURSE} from "../../../apollo/grade/index";
import { CALL_COURSE_DATA } from "../../../apollo/course/index";

/**
 *
 * @Function
 *
 */
import { setGenders, convertLetterGrade, alertSuccess, alertWarning, messageSuccess, sendingLoad} from "../../../common/super";
import "../util/index.css";

function AddGradeForStudent() {
  const { history, location } = useReactRouter();
  const data = location.state;
  // const accessmentRedux = useSelector((state) => state.accessment.value)

  /**
   *
   * @State
   *
   */
  const [showEdit, setShowEdit] = useState(false);
  const [editData, setEditData] = useState();
  // state to set grade
  const [confirmAllGrade, setConfirmAllGrade] = useState(false);
  const [confirmSentGrade, setConfirmSentGrade] = useState(false);
  const [gradeData, setGradeData] = useState([]);
  const [checkStatus, setCheckStatus] = useState([]);
  const [whenClick, setWhenClick] = useState([]);
  const [dataUser, setDataUser] = useState(null);

  /**
   *
   * @Apollo
   *
   */

  const [updateGrade] = useMutation(UPDATE_GRADE);
  const [loadUpdateGrade,{ data: gradeCourseData, loading }] = useLazyQuery(GRADE_COURSES);
  const [sendGradeCourse] = useMutation(SEND_GRADE_COURSE);
  // query course
  const [loadCourse, { data: courseData }] = useLazyQuery(CALL_COURSE_DATA);

  /**
   *
   * @UseEffect
   *
   */


  useEffect(() => {
    loadUpdateGrade({
      variables: {
        where: {
          // assessmentYear:accessmentRedux,
          course: data?.courseId ,
          numberTest_in: [2, 3, 4],
          gredeStatus_in: ['APROVED'],
        },
      },
    });
    if (gradeCourseData?.grades?.data) {
      setGradeData(gradeCourseData?.grades?.data);
    }
  }, [gradeCourseData]);

  useEffect(() => {
    let grade = [];
    gradeData.map((x) => { grade.push(x.gradeType)});
  }, [gradeData]);


  // use effect
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
    loadCourse();
  }, []);

  /**
   *
   * @Function
   *
   */

  // get depName
  const _renderCourseName = (e) => {
    let result = courseData?.courses?.data.filter((item) => item.id === e);
    if (result?.length > 0) return result[0].title;
    else return "";
  };

  const _saveOneGrade = async (grade) => {
    updateGrade({
      variables: {
        data: {
          gredeStatus: "APROVED",
          numbericGrade: grade?.numbericGrade,
        },
        where: { id: grade.id },
      },
    }).then(() => {
      messageSuccess("ຢືນຢັນແລ້ວ");
    });
  };

  async function _updateAllGrade() {
    sendingLoad("ການດຳເນີນຕ້ອງໃຊ້ເວລາ, ກະລຸນາລໍຖ້າ");
    gradeData.map(async (grade) => { await _saveOneGrade(grade)});
  }
  const _sendGrade = async () => {
    await sendGradeCourse({
      variables: {
        where: { id: data?.courseId },
        data: { sendingGradeStatus: "TEACHER_SUBMITED" },
      },
    })
      .then(() => {
        alertSuccess("ການສົ່ງຄະແນນຂອງທ່ານສຳເລັດແລ້ວ");
      })
      .catch((err) => {
        alertWarning("ການສົ່ງຄະແນນຂອງທ່ານລົ້ມແຫຼວ");
      });
  };


  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => history.push("/grades-index/1", location?.state)}
        >
          <strong>ຄະແນນ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => history.push("/grades-index/1", location?.state)}
        >
          <strong>ເລືອກໝວດໝູ່</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => history.push("/grades-course-list/limit/40/skip/1", location?.state)}
        >
          <strong>ວິຊາທັງໝົດ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() =>
            history.push("/grades-course-detial/1", location?.state)
          }
        >
          ລາຍລະອຽດວິຊາ
        </Breadcrumb.Item>
        <Breadcrumb.Item>ໃຫ້ຄະແນນນັກສຶກສາໃໝ່</Breadcrumb.Item>
      </Breadcrumb>
      <GradeNavAddforStudent data />
      {gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "TEACHER_SCORING" ||
      gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "TEACHER_SUBMITED" ||
      gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "TEACHER_REJECTED" ||
      gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "DEPARTMENT_CHECKING" ||
      gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "DEPARTMENT_SUBMITED" ||
      gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "DEPARTMENT_REJECTED" ||
      gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "FACULTY_CHECKING" ||
      gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "FACULTY_SUBMITED" ||
      gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "FACULTY_REJECTED" ||
      gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "ADMIN_CHECKING" ||
      gradeCourseData?.grades?.data[0]?.sendingGradeStatus ===
        "ADMIN_APPROVED" ? (
        <div>
          {loading ? (
            <Loading />
          ) : _renderCourseName(data?.courseId) === "" ? (
            <CustomContainer style={{ marginTop: 0}}>
              <Empty />
            </CustomContainer>
          ) : (
            <CustomContainer style={{ marginTop: 0}}>
              <div
                className="col-sm-4"
                style={{ display: "block", marginTop: 5 }}
              >
                <span
                  style={{
                    display: "inline-block",
                    fontSize: 24,
                    color: "#057CAE",
                    fontWeight: "bold",
                  }}
                >
                  {_renderCourseName(data?.courseId)}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                ທັງໝົດ {gradeData?.length} ຄົນ
                {dataUser?.role === "ADMIN" ? (
                  <div>
                    <Button
                      type="submit"
                      onClick={() => {
                        setConfirmAllGrade(!confirmAllGrade);
                      }}
                      className="btn btn-info"
                      style={{ width: "200px" }}
                    >
                      ຢືນຢັນທັງໝົດ
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => {
                        setConfirmSentGrade(!confirmSentGrade);
                      }}
                      className="btn btn-success"
                      style={{ width: "200px" }}
                    >
                      ສົ່ງຄະແນນ
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* Table list */}
              {gradeData && (
                <Formik
                  initialValues={{}}
                  onSubmit={(values, { setSubmitting }) => {}}
                >
                  {({ resetForm }) => (
                    <div>
                      <table
                        className="table table-bordered "
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <TableHeader>
                            <th style={{ width: "5%" }}>ລຳດັບ</th>
                            <th style={{ width: "18%" }}>ຊື່ ແລະ ນາມສະກຸນ</th>
                            <th width="8%">ຄະແນນລວມ</th>
                            <th style={{ width: "5%" }}>ເກຣດ</th>
                            <th style={{ width: "19%" }}>ຈັດການ</th>
                          </TableHeader>
                        </thead>
                        <tbody>
                          {loading ? (
                            <Loading />
                          ) : (
                            gradeData?.map((grade, index) => {
                              return (
                                <tr
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      grade?.numbericGrade !== null
                                        ? "#fffff"
                                        : "#f0f0ff",
                                  }}
                                >
                                  <td className="center">
                                    {index + 1}
                                  </td>
                                  <td>
                                    {setGenders(grade?.student?.gender) ?? "-"}{" "}
                                    &nbsp;
                                    {grade?.student?.firstNameL ??
                                      "-"} &nbsp;{" "}
                                    {grade?.student?.lastNameL ?? "-"}
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          ຄະແນນສູງສຸດ 100
                                        </Tooltip>
                                      }
                                    >
                                      <Form.Control
                                        disabled={
                                          dataUser?.role !== "ADMIN"
                                            ? true
                                            : // grade?.numbericGrade !== null ? false :
                                            whenClick[index] === index - 1
                                            ? true
                                            : checkStatus[index] === index
                                            ? false
                                            : true
                                        }
                                        max={100}
                                        min={0}
                                        className="center"
                                        type="number"
                                        placeholder={
                                          gradeData[index].numbericGrade ?? "0"
                                        }
                                        onChange={(e) => {
                                          gradeData[index].numbericGrade =
                                            parseInt(e.target.value);
                                          let _newGradeData = [...gradeData];
                                          setGradeData(_newGradeData);
                                        }}
                                        value={gradeData[index].numbericGrade}
                                      />
                                    </OverlayTrigger>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="tooltip-disabled">
                                          ສູງສຸດເກຣດ A
                                        </Tooltip>
                                      }
                                    >
                                      <input
                                        type="text"
                                        disabled={true}
                                        className="w-100 grade-type"
                                        style={{ textAlign: "center" }}
                                        name={gradeData?.letterGrade}
                                        value={convertLetterGrade(
                                          gradeData[index].numbericGrade
                                        )}
                                      />
                                    </OverlayTrigger>
                                  </td>
                                  {dataUser?.role === "ADMIN" ? (
                                    <td style={{ textAlign: "center" }}>
                                      {whenClick[index] === index ? (
                                        <button
                                          className="btn btn-outline-success"
                                          onClick={async (e) => {
                                            let conn = [...checkStatus];
                                            conn[index] = index - 1;
                                            setCheckStatus(conn);
                                            let setwhen = [...whenClick];
                                            setwhen[index] = index - 1;
                                            setWhenClick(setwhen);
                                            // await _grade(index, grade.id);
                                            await _saveOneGrade(grade);
                                            resetForm({});
                                          }}
                                          style={{ fontWeight: "bold" }}
                                        >
                                          <i className="fa fa-check-circle"></i>
                                          &nbsp;&nbsp; ຢືນຢັນ
                                        </button>
                                      ) : grade?.numbericGrade !== null &&
                                        checkStatus[index] === index ? (
                                        <button
                                          className="btn btn-outline-success"
                                          onClick={async (e) => {
                                            let conn = [...checkStatus];
                                            conn[index] = index - 1;
                                            setCheckStatus(conn);
                                            let setwhen = [...whenClick];
                                            setwhen[index] = index - 1;
                                            setWhenClick(setwhen);
                                            // await _grade(index, grade.id);
                                            await _saveOneGrade();
                                            resetForm({});
                                          }}
                                          style={{ fontWeight: "bold" }}
                                        >
                                          <i className="fa fa-check-circle"></i>
                                          &nbsp;&nbsp; ຢືນຢັນ
                                        </button>
                                      ) : (
                                        ""
                                      )}

                                      {whenClick[index] === index ? (
                                        ""
                                      ) : (
                                        <button
                                          className="btn btn-outline-info"
                                          onClick={() => {
                                            let conn = [...checkStatus];
                                            conn[index] = index;
                                            setCheckStatus(conn);
                                            let setwhen = [...whenClick];
                                            setwhen[index] = index;
                                            setWhenClick(setwhen);
                                          }}
                                        >
                                          ແກ້ໄຂ
                                        </button>
                                      )}
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        color: "#FF8819",
                                        textAlign: "center",
                                        marginTop: "4px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <i className="fa fa-exclamation-triangle"></i>
                                      &nbsp;&nbsp;ບໍ່ມີສິດໃຫ້ ຫຼື ແກ້ໄຂຄະແນນ
                                    </td>
                                  )}
                                </tr>
                              );
                            })
                          )}

                          {/* })} */}
                        </tbody>
                      </table>
                      <div
                        className="col-md-12"
                        style={{ textAlign: "center" }}
                      >
                        {dataUser?.role === "ADMIN" ? (
                          <Button
                            type="submit"
                            onClick={() => {
                              setConfirmAllGrade(!confirmAllGrade);
                            }}
                            className="btn btn-info"
                            style={{ width: "200px" }}
                          >
                            ຢືນຢັນທັງໝົດ
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </Formik>
              )}
            </CustomContainer>
          )}
        </div>
      ) : (
        <CustomContainer style={{ marginTop: 0}}>
          <Empty />
        </CustomContainer>
      )}


      <Modal
        show={confirmAllGrade}
        onHide={() => setConfirmAllGrade(!confirmAllGrade)}
      >
        <Modal.Header closeButton>
          <p>ຢືນຢັນການບັນທືກຄະແນນທັງໝົດ</p>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>ທ່ານແນ່ໃຈແລ້ວບໍ່ ?</p>
          <div className="col-md-12" style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="btn btn-outline-secondary"
              onClick={() => setConfirmAllGrade(!confirmAllGrade)}
            >
              ຍົກເລີກ
            </button>
            &nbsp;&nbsp; &nbsp;
            <button
              type="submit"
              onClick={async () => {
                await _updateAllGrade();
                window.location.reload();
              }}
              className="btn btn-info"
            >
              ຢືນຢັນ
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={confirmSentGrade}
        onHide={() => setConfirmSentGrade(!confirmSentGrade)}
      >
        <Modal.Header closeButton>
          <p>ຢືນຢັນການສົ່ງຄະແນນ</p>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <p>ທ່ານແນ່ໃຈແລ້ວບໍ່ວ່າຕ້ອງການສົ່ງຄະແນນວິຊານີ້ ?</p>
          <div className="col-md-12" style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="btn btn-outline-secondary"
              onClick={() => setConfirmSentGrade(!confirmSentGrade)}
            >
              ຍົກເລີກ
            </button>
            &nbsp;&nbsp; &nbsp;
            <button
              type="submit"
              onClick={async () => {
                await _sendGrade();
                setConfirmSentGrade(!confirmSentGrade);
              }}
              className="btn btn-info"
            >
              ຢືນຢັນ
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddGradeForStudent;
