import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
/**
 *
 * @Component
 *
 */
import { Breadcrumb, Pagination } from "react-bootstrap";
import { CustomContainer, Title, TableHeader } from "../../common";
import HistoryLogDetail from "./updateLog/historyLogDetail";
import Loading from "../../common/Loading";

/**
 *
 * @Constant
 *
 */
/**
 *
 * @Apollo
 *
 */
import { UPDATELOGS } from "../../apollo/grade";

/**
 *
 * @Function
 *
 */
import { formatDate } from "../../common/super";
import "./util/index.css";

function HistoryUpdateGrade() {
  const { history, location, match } = useReactRouter();
  const COURSE_ID = location?.state?.courseId;
  const [logsDataModal, setLogsDataModal] = useState({});
  const [oldData, setOldData] = useState({});
  const [newData, setNewData] = useState({});
  const [isLogShow, setIsLogsShow] = useState(false);
  const pageNambers = match?.params?.page;
  const PAGE_LIMIT = 50;
  const [countPage, setCountPage] = useState();
  const [loadUpdateLog, { data: logsData, loading: loadingLogs }] = useLazyQuery(UPDATELOGS);

  useEffect(() => {
    loadUpdateLog({
      variables: {
        first: PAGE_LIMIT,
        skip: PAGE_LIMIT * (pageNambers - 1),
        where: {
          courseId: COURSE_ID,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var courseCount = logsData?.updateLogs?.total;
    const countPages = [];
    for (var i = 1; i <= Math.ceil(courseCount / PAGE_LIMIT); i++) {
      countPages.push(i);
      setCountPage(countPages);
    }
  }, [logsData]);
  const _diffData = (x) => {
    return JSON.parse(x);
  };

  const _onNext = () => {
    if (parseInt(pageNambers) + 1 < 1) {
      history.push(`/grades-update-detail-history/${1}`);
    } else {
      history.push(
        `/grades-update-detail-history/${parseInt(pageNambers) + 1}`
      );
    }
  };
  const _onPrevius = () => {
    if (parseInt(pageNambers) - 1 < 1) {
      history.push(`/grades-update-detail-history/${1}`);
    } else {
      history.push(
        `/grades-update-detail-history/${parseInt(pageNambers) - 1}`
      );
    }
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
          <strong>ຄະແນນ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
          <strong>ເລືອກໝວດໝູ່</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/grades-course-list/limit/40/skip/1")}>
          <strong>ວິຊາທັງໝົດ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ປະຫວັດການແກ້ໄຂ</Breadcrumb.Item>
      </Breadcrumb>

      {/* <GradeNavCourseDetail data={location?.state}/> */}

      <CustomContainer style={{ marginTop: 0 }}>
        <Title text="ປະຫັວດການແກ້ໄຂທັງໝົດ" />
        <div>
          <p>ທັງໝົດ {logsData?.updateLogs?.total}ລາຍການ</p>
        </div>
        <table
          className="table table-hover table-striped"
          style={{ marginTop: 20 }}
        >
          <thead>
            <TableHeader>
              <th width="100px">ລຳດັບ</th>
              <th>ປະເພດການປ່ຽນແປງ</th>
              <th>ວັນທີອັບເດດ</th>
              <th>ຜູ້ອັບເດດ</th>
              <th>ລາຍລະອຽດ</th>
            </TableHeader>
          </thead>
          <tbody style={{ textAlign: "center" }}>
            {loadingLogs ? (
              <Loading />
            ) : (
              logsData?.updateLogs?.data?.map((item, index) => {
                return (
                  <tr key={index} style={{ color: "#6f6f6f" }}>
                    <td className="center">
                      {(parseInt(pageNambers) - 1) * PAGE_LIMIT + index + 1}
                    </td>
                    <td className="center">{item?.logMethod}</td>
                    {/* <td>{_diffData(item?.diffData)?.status?.__new ?? "-"}</td> */}
                    <td>{formatDate(item?.createdAt) ?? "-"}</td>
                    <td>{item?.createdBy ?? "-"}</td>
                    <td>
                      <button
                        className="btn btn-outline-success"
                        onClick={async () => {
                          setOldData(_diffData(item.oldData));
                          setNewData(_diffData(item.newData));
                          setLogsDataModal(_diffData(item.diffData));
                          setIsLogsShow(!isLogShow);
                        }}
                      >
                        <i className="fa fa-eye"></i> ລາຍລະອຽດ
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <Pagination
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <Pagination.Prev onClick={_onPrevius} />
          {countPage?.slice(0, 5).map((Item, index) => {
            return (
              <Pagination.Item
                key={"page" + index}
                active={Item === pageNambers}
                onClick={() => {
                  return history.push(
                    `/grades-update-detail-history/${index + 1}`
                  );
                }}
              >
                {Item}
              </Pagination.Item>
            );
          })}
          {countPage && countPage?.length > 3 ? (
            <Pagination.Item style={{ pointerEvents: "none" }}>
              {"..."}
            </Pagination.Item>
          ) : (
            " "
          )}
          {countPage && countPage?.length > 3 ? (
            <Pagination.Item
              onClick={() =>
                history.push(
                  `/grades-update-detail-history/${countPage?.length}`
                )
              }
            >
              {countPage?.length}
            </Pagination.Item>
          ) : (
            " "
          )}
          <Pagination.Next onClick={_onNext} />
        </Pagination>
      </CustomContainer>

      {/* <HistoryReceiveGrdaedetail
        onHide={() => setIsLogsShow(!isLogShow)}
        show={isLogShow}
        logsData={logsDataModal}
      /> */}
      <HistoryLogDetail
        onHide={() => setIsLogsShow(!isLogShow)}
        show={isLogShow}
        oldData={oldData}
        newData={newData}
        logsData={logsDataModal}
      />
    </div>
  );
}

export default HistoryUpdateGrade;
