import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { Breadcrumb } from "react-bootstrap";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import { CustomContainer, Title } from "../../common";
import Routers from "../../consts/router";
import CourseAddForm from "./common/CourseAddForm";
import CoursePreview from "./common/CoursePreview";
const CourseAdd = () => {
  const { history, location } = useReactRouter();
  const [onSuccess, setOnSuccess] = useState(false);
  const [subjectType, setSubjecType] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          href={Routers.COURSE_LIST + Routers.PAGE_GINATION}
          onClick={() =>
            history.push(Routers.COURSE_LIST + Routers.PAGE_GINATION)
          }
        >
          ລາຍການຫລັກສູດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ໜ້າສ້າງຫລັກສູດ</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ display: "flex", marginLeft: 10 }}>
        <div
          className="menuItemTap"
          onClick={() => history.push(Routers.COURSE_DETAIL, location.state)}
        >
          ໜ້າສະແດງຫລັກສູດ
        </div>

        <div
          className="menuItemTap active-menuTap"
          // onClick={() =>
          //   history.push(Routers.PRERE_STUDENT_LIST + Routers.PAGE_GINATION)
          // }
        >
          ໜ້າສ້າງຫລັກສູດ
        </div>
      </div>
      <CustomContainer style={{ marginTop: 0 }}>
        <Title text={"ເລືອກວິຊາພາຍໃນຄະນະ/ນອກຄະນະ"} />
        <div
          className="border-dash"
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#efefef",
          }}
        >
          <label
            id="demo-radio-buttons-group-label"
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
          >
            ກະລຸນາເລືອກ
          </label>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={true}
              onChange={(e) => setSubjecType(e.target.value)}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="ວິຊາພາຍໃນຄະນະ"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="ວິຊານອກຄະນະ"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </CustomContainer>
      <Grid container className="d-flex">
        <Grid item xs={6}>
          <CourseAddForm
            location={location.state}
            subjectType={subjectType}
            onSuccess={onSuccess}
            setOnSuccess={setOnSuccess}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </Grid>
        <Grid item xs={6}>
          <CoursePreview
            location={location.state}
            onSuccess={onSuccess}
            setOnSuccess={setOnSuccess}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CourseAdd;
