import { gql } from 'apollo-boost'

export const REGIS_DEPARTMENT_DATE_HISTORIES = gql`
  query regisDepartmentDateHistories(
    $where: RegisDepartmentDateHistoryWhereInput
    $orderBy: RegisDepartmentDateHistoryOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    regisDepartmentDateHistories(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data{
        id
        startDateRegister
        endDateRegister
        createdBy{
            role
            firstname
            lastname
        }
        department{
            name
        }
        createdAt
      }
    }
  }
`;