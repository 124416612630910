import React, { useState, useEffect } from "react";
/**
 *
 * @Library
 *
 */
import useReactRouter from "use-react-router";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import InputBase from "@material-ui/core/InputBase";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Row, Col, Modal, Spinner } from "react-bootstrap";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
/**
 *
 * @Component
 *
 */
import { CustomContainer } from "../../common";
import { formatDate, currency, onConvertStudentType } from "../../common/super";
import Loading from "../../common/Loading";
/**
 *
 * @Appollo
 *
 */
import {
  FACULTIES,
  DEPARTMENTS,
  STUDENTS,
  TRIBES,
  MAJORS,
} from "./apollo/query";
/**
 *
 * @Constants
 *
 */
import Consts from "../../consts";
import Routers from "../../consts/router";
/**
 *
 * @Function
 *
 */
import { formateDateSlash, formatDateYYMMDD } from "../../common/super";
import PaginationHelper from "../../helpers/PaginationHelper";
import {
  convertGenderNam,
  convertGenderNamEN,
  onConvertEducationLevel,
  convertStatus,
  onConvertStudentInfo,
  convertScholarship,
  convertScholarshipEn,
} from "../../helpers/user";

import axios from "axios";
import { NEW_API_URL, API_KEY } from "../../common/contrant";
import { saveAs } from "file-saver";

import * as XLSX from "xlsx";

function PreregistrationStudentRejected() {
  const [excelData, setExcelData] = useState([]);

  const accessmentRedux = useSelector((state) => state.accessment.value);
  const { history, location } = useReactRouter();
  const parsed = location?.state;
  // const parsed = queryString?.parse(location?.state);
  const [dataStudents, setDataStudents] = useState();
  const [dataStudentsExcel, setDataStudentsExcel] = useState();
  const [totalStudents, setTotalStudents] = useState();

  // STATE SEARCH
  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState();
  const [dataMajors, setDataMajors] = useState([]);
  const [facultyId, setFacultyId] = useState(
    !parsed?.faculty ? "" : parsed?.faculty
  );
  const [departmentsId, setDepartmentsId] = useState(
    !parsed?.department ? "" : parsed?.department
  );
  const [majorId, setMajorId] = useState(!parsed?.major ? "" : parsed?.major);
  const [tribeId, setTribeId] = useState(!parsed?.tribe ? "" : parsed?.tribe);
  const [studentNumber, setStudentNumber] = useState();
  const [createdAt_lt, setCreatedAt_lt] = useState(
    !parsed?.createdAt_lt ? "" : parsed?.createdAt_lt
  );
  const [createdAt_glt, setCreatedAt_glt] = useState(
    !parsed?.createdAt_glt ? "" : parsed?.createdAt_glt
  );
  const [stdId, setStdId] = useState("");
  const [isAdmin, setIsAdmin] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [dataUser, setDataUser] = useState(null);
  const [role, setRole] = useState("");
  const [whereExcel, setWhereExcel] = useState({});
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);

  const [loadFaculties, { data: dataFaculties }] = useLazyQuery(FACULTIES);
  const [loadDepartments, { data: dataDepartments }] =
    useLazyQuery(DEPARTMENTS);
  const [loadMajors, { data: majorData }] = useLazyQuery(MAJORS);

  const { data: dataTribe } = useQuery(TRIBES);
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  const [loadStudents, { loading: regStudentLoading, data: regStudentData }] =
    useLazyQuery(STUDENTS, { fetchPolicy: "network-only" });
  const [
    loadStudentsExcel,
    { loading: regStudentExcelLoading, data: regStudentExcelData },
  ] = useLazyQuery(STUDENTS, { fetchPolicy: "cache-and-network" });

  useEffect(() => {
    loadStudents({
      fetchPolicy: "cache-and-network",
      variables: {
        where: {
          assessmentYear: accessmentRedux,
        },
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });
    loadFaculties({
      variables: {
        where: {
          assessmentYear: accessmentRedux,
          isDeleted: false,
        },
      },
    });
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
  }, []);

  const onExport = (data) => {
    let queue = 0;
    const nreStudentExport = data.map((student) => {
      queue++;
      let gender = "ຊາຍ";
      if (student.gender === "FEMALE") gender = "ຍິງ";
      let educationLevel = "";
      switch (student?.educationLevel || "") {
        case "BACHELOR_DEGREE":
          educationLevel = "ປະລິນຍາຕີ";
          break;
      }

      return {
        ລຳດັບ: queue,
        ລະຫັດ: student.studentNumber,
        "ຊື່ ແລະ ນາມສະກຸນ(ພາສາລາວ)": `${student.firstNameL + " "} ${
          student.lastNameL
        } `,
        "ຊື່ ແລະ ນາມສະກຸນ(ພາສາອັງກິດ)": `${student.firstNameE + " "} ${
          student.lastNameE
        } `,
        ເພດ: gender,
        ຄະນະ: student.faculty?.name || "",
        ພາກວິຊາ: student.department?.name || "",
        ສາຂາ: student.major?.name || "",
        ປິຮຽນ: student.yearLevel || "",
        ເບີໂທລະສັບ: student.phoneHome || "",
        ອິເມວ: student.email || "",
        ລະດັບການສຶກສາ: educationLevel,
        ຢູ່ຫ້ອງພັກ: student.dormitory === "YES" ? "ຢູ່" : "ບໍ່ຢູ່",
        ສາມັນຈົບຈາກແຂວງ: student.graduateFromProvince || "",
        ສາມັນຈົບຈາກໂຮງຮຽນ: student.graduateFromSchool || "",
        ວັນເດືອນປີເກີດ: student?.birthday || "",
        ສັນຊາດ: student.nationality || "",
        ສາສະຫນາ: student.religion || "",
        "ຊົນເຜົ່າ​": student.tribe?.trib_name || "",
        ສູກເສີນຕິດຕໍ່:
          student.fatherNameAndSurname + "  " + student.emergencyPhone || "",
        "ຊື່ ແລະ ນາມສະກຸນພໍ່": `${student.fatherNameAndSurname} `,
        "ຊື່ ແລະ ນາມສະກຸນແມ່": `${student.motherNameAndSurname} `,
        ເບີໂທລະສັບພໍ່ແມ່: student.parentTelephone,
        ວັນທີລົງທະບຽນ: student.createdAt,
      };
    });

    const ws = XLSX.utils.json_to_sheet(nreStudentExport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Students"); // 'Students' is the sheet name

    // Write the XLSX file
    const xlsxBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const blob = new Blob([new Uint8Array(xlsxBuffer)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Save the XLSX file using the saveAs function
    saveAs(blob, "students.xlsx");
  };

  const getRegistedStudent = async () => {
    await axios
      .get(NEW_API_URL + `/statistic/rejectedprerestudent`, {
        headers: {
          api_key: API_KEY,
        },
        params: {
          fact: facultyId,
          depId: departmentsId,
          majorId: majorId,
        },
      })
      .then((res) => {
        if (res.data) {
          onExport(res.data);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    loadFaculties({
      variables: {
        where: {
          assessmentYear: accessmentRedux,
          isDeleted: false,
        },
      },
    });
  }, [accessmentRedux]);

  useEffect(() => {
    if (dataFaculties?.faculties) {
      setFaculties(dataFaculties?.faculties);
    }
  }, [dataFaculties]);

  useEffect(() => {
    setDepartments(dataDepartments?.departments);
  }, [dataDepartments]);
  useEffect(() => {
    if (majorData) setDataMajors(majorData?.majors?.data);
  }, [majorData]);

  useEffect(() => {
    if (facultyId) {
      loadDepartments({
        variables: {
          where: {
            faculty: facultyId,
            assessmentYear: accessmentRedux,
            isDeleted: false,
          },
        },
      });
    }
  }, [facultyId]);

  // select faculty
  const _selectFaculty = (e) => {
    setDepartments([]);
    setDepartmentsId("");
    setDataMajors([]);
    setMajorId("");
    setFacultyId(e.target.value);
    if (e.target.value !== "") {
      loadDepartments({
        variables: {
          where: {
            faculty: e.target.value,
            assessmentYear: accessmentRedux,
            isDeleted: false,
          },
        },
      });
    }
  };
  const _onSelectedDepartment = (e) => {
    setDataMajors([]);
    setMajorId("");
    setDepartmentsId(e.target.value);
    if (e.target.value !== "") {
      loadMajors({
        variables: {
          where: {
            department: e.target.value,
            assessmentYear: accessmentRedux,
            isDeleted: false,
          },
        },
      });
    }
  };
  const _onSelectedMajor = (e) => {
    setMajorId(e.target.value);
  };
  const _onSelectedTribes = (e) => {
    setTribeId(e.target.value);
  };

  // ROUTE
  const _registerStudentDetail = (dataStudents) => {
    history.push("/preregistration_student-detail", dataStudents);
  };

  useEffect(() => {
    let userRole = dataUser?.role;
    // check role
    if (userRole !== "ADMIN" && userRole !== "DEAN") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    if (
      dataUser?.role == "FAC_ADMIN" ||
      dataUser?.role == "FAC_ADMIN_TEACHER" ||
      dataUser?.role == "DEP_ADMIN_TEACHER" ||
      dataUser?.role == "DEP_ADMIN"
    ) {
      setFacultyId(dataUser?.faculty?.id);
      loadDepartments({
        variables: {
          where: {
            faculty: dataUser?.faculty?.id,
            assessmentYear: accessmentRedux,
            isDeleted: false,
          },
        },
      });
    }
    setRole(dataUser?.role);
  }, [dataUser]);
  // SEARCH  ----------------->>
  useEffect(() => {
    if (!regStudentData) return;
    setDataStudents(regStudentData?.preregistrationStudents?.data);
    setTotalStudents(regStudentData?.preregistrationStudents?.total);
  }, [regStudentData]);
  useEffect(() => {
    if (!regStudentExcelData) return;
    setDataStudentsExcel(regStudentExcelData?.preregistrationStudents?.data);
  }, [regStudentExcelData]);

  useEffect(() => {
    let qlWhere = { assessmentYear: accessmentRedux, statusStep: "REJECTED" };
    if (studentNumber) qlWhere = { ...qlWhere, studentNumber: studentNumber };
    if (facultyId) qlWhere = { ...qlWhere, faculty: { id: facultyId } };
    if (departmentsId)
      qlWhere = { ...qlWhere, department: { id: departmentsId } };
    if (majorId) qlWhere = { ...qlWhere, major: majorId };
    if (tribeId) qlWhere = { ...qlWhere, tribe: { id: tribeId } };
    if (createdAt_lt || createdAt_glt)
      qlWhere = {
        ...qlWhere,
        createdAt_lt: createdAt_lt,
        createdAt_gte: createdAt_glt,
      };

    setWhereExcel(qlWhere);
    loadStudents({
      variables: {
        where: qlWhere,
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });
  }, [
    facultyId,
    departmentsId,
    majorId,
    tribeId,
    studentNumber,
    createdAt_lt,
    createdAt_glt,
    stdId,
    accessmentRedux,
  ]);

  const _studentNumber = (e) => {
    if (e.keyCode == 32) e.preventDefault();
    if (e.key === "Enter") setStudentNumber(e.target.value);
  };

  const _onSelectedDate = (e) => {
    const date = new Date(e.target.value);
    date.setDate(date.getDate() + 1);
    setCreatedAt_lt(formateDateSlash(date));
    setCreatedAt_glt(e.target.value);
  };
  const reloadData = async () => {
    try {
      setIsLoading(true);
      history.push(
        Routers.PRERE_STUDENT_REJECTED + "/limit/" + _limit + "/skip/" + 1
      );
      loadStudents({
        fetchPolicy: "cache-and-network",
        variables: {
          where: { statusStep: "REJECTED", assessmentYear: accessmentRedux },
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const _loadDataExport = () => {
    loadStudentsExcel({
      variables: {
        where: whereExcel,
        // skip: (_skip - 1) * 1000,
        // first: 1000,
      },
    });
  };
  return (
    <div>
      <div className="h-title">
        <h3>ລົງທະບຽນນັກສຶກສາ</h3>
      </div>
      <div style={{ display: "flex", marginLeft: 10 }}>
        {!isAdmin ? (
          <>
            <div
              className="menuItemTap"
              onClick={() =>
                history.push(
                  Routers.PRERE_STUDENT_LIST_ALL + Routers.PAGE_GINATION
                )
              }
            >
              ຂໍ້ມູນນັກສຶກສາທັງຫມົດທີມາລົງທະບຽນ
            </div>

            <div
              className="menuItemTap"
              onClick={() =>
                history.push(Routers.PRERE_STUDENT_LIST + Routers.PAGE_GINATION)
              }
            >
              ຂໍ້ມູນນັກສຶກສາທີ່ລົງທະບຽນຈາກ ວິຊາການ ມຊ
            </div>
          </>
        ) : dataUser?.role === "FAC_ADMIN" ? (
          <div
            className="menuItemTap "
            onClick={() =>
              history.push(Routers.PRERE_STUDENT_LIST + Routers.PAGE_GINATION)
            }
          >
            ຂໍ້ມູນທີ່ລົງທະບຽນຈາກຄະນະ ມຊ
          </div>
        ) : (
          ""
        )}
        <div
          className="menuItemTap active-menuTap"
          onClick={() =>
            history.push(Routers.PRERE_STUDENT_REJECTED + Routers.PAGE_GINATION)
          }
        >
          ຂໍ້ມູນນັກສຶກສາທີ່ຖືກປະຕິເສດ
        </div>
        <div
          className="menuItemTap"
          onClick={() =>
            history.push(Routers.PRERE_STUDENT_FAC_LIST + Routers.PAGE_GINATION)
          }
        >
          ຂໍ້ມູນນັກສຶກສາທີ່ລົງທະບຽນແລ້ວ
        </div>
        <div
          className="menuItemTap"
          onClick={() => history.push(Routers.PRERE_STUDENT_STATISTISTIC)}
        >
          ຂໍ້ມູນສະຖິຕິ
        </div>
        <div
          className="menuItemTap"
          onClick={() => history.push(Routers.PRERE_STUDENT_STATISTISTIC_TRIBE)}
        >
          ສະຖິຕິຊົນເຜົ່າ
        </div>
      </div>
      {(regStudentLoading || isLoading) && <Loading />}
      {/* <div style={{backgroundColor:"red"}}>ຂໍ້ມູນທີ່ລົງທະບຽນຈາກ ວິຊາການ ມຊ</div> */}
      <CustomContainer style={{ marginTop: 0 }}>
        <div className="form-group row">
          <label className="col-sm-1 col-form-label">ຄົ້ນຫາ</label>
          <div className="col-sm-11">
            <input
              type="text"
              className="form-control boxs"
              // id="searchText"
              placeholder="ປ້ອນລະຫັດນັກສຶກສາແລ້ວກົດ Enter"
              onKeyDown={(e) => _studentNumber(e)}
            />
          </div>
        </div>

        <div
          style={{
            marginTop: 4,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          <Row>
            <Col sm={3}>
              <p>ຄະນະວິຊາ</p>
              {/* {(!isAdmin || facSqlId) && ( */}
              <Form.Control
                as="select"
                className="form-select"
                style={{ cursor: "pointer" }}
                disabled={role !== "ADMIN" && role !== "DEAN" ? true : false}
                value={facultyId}
                defaultValue={!parsed?.faculty ? "" : parsed?.faculty}
                onChange={(e) => {
                  _selectFaculty(e);
                }}
              >
                <option value="">ຄະນະທັງໝົດ</option>
                {faculties?.map((item, index) => {
                  return (
                    <option key={"faculty" + index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
            <Col sm={3}>
              <p>ພາກວິຊາ</p>
              <Form.Control
                as="select"
                className="form-select"
                style={{ cursor: "pointer" }}
                value={departmentsId}
                defaultValue={!parsed?.department ? "" : parsed?.department}
                onChange={(e) => _onSelectedDepartment(e)}
              >
                <option value="">ທັງໝົດ</option>
                {departments &&
                  departments.map((item, index) => {
                    return (
                      <option key={"major" + index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col sm={3}>
              <p>ສາຂາວິຊາ</p>
              <Form.Control
                as="select"
                className="form-select"
                style={{ cursor: "pointer" }}
                value={majorId}
                defaultValue={!parsed?.major ? "" : parsed?.major}
                onChange={(e) => _onSelectedMajor(e)}
              >
                <option value="">ທັງໝົດ</option>
                {dataMajors &&
                  dataMajors.map((item, index) => {
                    return (
                      <option key={"major" + index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Control>
            </Col>
            <Col sm={3}>
              <p>ຊົນເຜົ່າ</p>
              <Form.Control
                as="select"
                className="form-select"
                style={{ cursor: "pointer" }}
                defaultValue={!parsed?.tribe ? "" : parsed?.tribe}
                value={tribeId}
                onChange={(e) => _onSelectedTribes(e)}
              >
                <option value={""}>ທັງໝົດ</option>
                {dataTribe?.tribes?.map((item, index) => {
                  return (
                    <option key={"tribe" + index} value={item.id}>
                      {item.trib_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <p>ວັນທີ່</p>
              <InputBase
                id="date"
                className="form-control"
                type="date"
                defaultValue={
                  parsed?.createdAt_glt
                    ? formatDateYYMMDD(parsed?.createdAt_glt)
                    : ""
                }
                onChange={(e) => _onSelectedDate(e)}
              />
            </Col>
          </Row>
        </div>

        <div>
          <Row>
            <Col sm={6}>
              ທັງໝົດ {currency(totalStudents)} ລາຍການ. ຍິງ{" "}
              {regStudentData?.preregistrationStudents?.female} ຄົນ, ຊາຍ{" "}
              {regStudentData?.preregistrationStudents?.male} ຄົນ, ໃນແຜນ{" "}
              {regStudentData?.preregistrationStudents?.inPlan} ຄົນ, ນອກແຜນ{" "}
              {regStudentData?.preregistrationStudents?.outPlan} ຄົນ
            </Col>
            <Col
              sm={2}
              style={{ marginRight: -20, color: Consts.PRIMARY_COLOR }}
            >
              <button
                className="btn btn-outline-success"
                onClick={() => reloadData()}
                style={{ float: "right", marginRight: -20 }}
              >
                <FontAwesomeIcon icon="redo-alt" size="1x" /> ໂຫຼດຂໍ້ມູນ
              </button>
            </Col>
            <Col sm={2}>
              <button
                className="btn btn-outline-success"
                onClick={() => {
                  getRegistedStudent();
                  //   setShowAddConfirmModal(true);
                  //   _loadDataExport();
                }}
                style={{ float: "right" }}
              >
                <i className="fa fa-file-excel"></i> Export Excel
              </button>
            </Col>
          </Row>
          <table
            id="allData"
            className="table table-hover"
            style={{ width: "100%", fontSize: "14px" }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: Consts.PRIMARY_COLOR,
                  textAlign: "center",
                }}
              >
                <th
                  style={{
                    backgroundColor: Consts.PRIMARY_COLOR,
                    color: "white",
                    width: 70,
                  }}
                >
                  ລຳດັບ
                </th>
                <th
                  style={{
                    backgroundColor: Consts.PRIMARY_COLOR,
                    color: "white",
                    width: 70,
                  }}
                >
                  ລະຫັດນັກສຶກສາ
                </th>
                <th
                  style={{
                    backgroundColor: Consts.PRIMARY_COLOR,
                    color: "white",
                    width: 70,
                  }}
                >
                  ຊື່ ແລະ ນາມສະກຸນ
                </th>
                <th
                  style={{
                    backgroundColor: Consts.PRIMARY_COLOR,
                    color: "white",
                    width: 200,
                  }}
                >
                  ວັນເດືອນປີເກີດ
                </th>
                <th
                  style={{
                    backgroundColor: Consts.PRIMARY_COLOR,
                    color: "white",
                    width: 70,
                  }}
                >
                  ຈົບຈາກໂຮງຮຽນ
                </th>
                <th
                  style={{
                    backgroundColor: Consts.PRIMARY_COLOR,
                    color: "white",
                    width: 70,
                  }}
                >
                  ວັນທີ່ລົງທະບຽນ
                </th>
                <th
                  style={{
                    backgroundColor: Consts.PRIMARY_COLOR,
                    color: "white",
                    width: 70,
                  }}
                >
                  ລົງທະບຽນຈາກ ວຊກ ມຊ
                </th>
                <th
                  style={{
                    backgroundColor: Consts.PRIMARY_COLOR,
                    color: "white",
                    width: 70,
                  }}
                >
                  ລົງທະບຽນຈາກຄະນະ
                </th>
                <th
                  style={{
                    backgroundColor: Consts.PRIMARY_COLOR,
                    color: "white",
                    width: 70,
                  }}
                >
                  ຈັດການ
                </th>
              </tr>
            </thead>
            {dataStudents?.length > 0 &&
              dataStudents?.map((item, index) => (
                <tbody key={index}>
                  <tr style={{ textAlign: "center" }}>
                    <td>{index + 1 + _limit * (_skip - 1)}</td>
                    <td>{item?.studentNumber}</td>
                    <td style={{ textAlign: "left" }}>
                      {!item?.firstNameL || item?.firstNameL === ""
                        ? convertGenderNamEN(item?.gender) +
                          " " +
                          item?.firstNameE +
                          " " +
                          item?.lastNameE
                        : convertGenderNam(item?.gender) +
                          " " +
                          item?.firstNameL +
                          " " +
                          item?.lastNameL}
                    </td>
                    <td>{formatDate(item?.birthday)}</td>
                    <td>{item?.graduateFromSchool}</td>
                    <td>{formatDate(item?.createdAt)}</td>
                    <td>
                      <span style={{ color: "#FF0000" }}>ຖືກປະຕິເສດ</span>
                    </td>
                    <td>
                      <span style={{ color: "#FF0000" }}>ຖືກປະຕິເສດ</span>
                    </td>
                    <td>
                      <div
                        onClick={(e) => _registerStudentDetail(item)}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#FFFFFF",
                          padding: 3,
                          width: 60,
                          borderRadius: 4,
                          margin: "0 auto",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={["fas", "eye"]}
                          color={Consts.BORDER_COLOR}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
          {Pagination_helper(totalStudents, Routers.PRERE_STUDENT_LIST, {
            faculty: facultyId,
            department: departmentsId,
            major: majorId,
            tribe: tribeId,
            createdAt_lt: createdAt_lt,
            createdAt_glt: createdAt_glt,
            status: "REJECTED",
          })}
        </div>
      </CustomContainer>
      <Modal
        show={showAddConfirmModal}
        onHide={_handleShowAddConfirmModalClose}
        size="xl"
      >
        <Modal.Header>ລາຍລະອຽດນັກສຶກສາ</Modal.Header>
        <Modal.Body>
          <div style={{ float: "right", padding: 20 }}>
            <ReactHTMLTableToExcel
              style={{ backgroundColor: "#000" }}
              className="btn btn-outline-success"
              table="ExportData"
              filename={"ລາຍລະອຽດລົງທະບຽນເຂົ້າຮຽນ"}
              sheet="Sheet"
              buttonText="Export excel"
              font={"NotoSansLao"}
              headerPaddingCellOptions={{ background: "#ff0000" }}
            />
          </div>
          {regStudentExcelLoading || !dataStudentsExcel ? (
            <div
              style={{
                width: "100%",
                minHeight: 350,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" style={{ color: "#057CAE" }} />
            </div>
          ) : (
            <table className="table table-hover" id="ExportData">
              <thead>
                <tr className="thead">
                  <th rowSpan={2} className="align-middle">
                    ລຳດັບ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ສົກຮຽນ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ລະຫັດນັກສຶກສາ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ເພດຊື່ ແລະ ນາມສະກຸນ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ຊື່ ແລະ ນາມສະກຸນ (ອັງກິດ)
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ສະຖານະ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ເບີໂທລະສັບ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ວັນເດືອນປີເກີດ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ສະຖານທີເກີດ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ບ່ອນຢູ່ປະຈຸບັນ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ສັນຊາດ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ຊົນເຜົ່າ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ສາສະໜາ
                  </th>
                  <th colSpan={7}>ຂໍ້​ມູນ​ຜູ້​ປົກ​ຄອງ</th>
                  <th colSpan={2}>ກໍລະນີສຸກເສີນຕິດຕໍ່</th>
                  <th rowSpan={2} className="align-middle">
                    ຫໍ​ພັກ​ນັກ​ສຶກ​ສາ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ຄະນະ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ພາກວິຊາ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ສາຂາວິຊາ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ຈົບຈາກແຂວງ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ຈົບຈາກໂຮງຮຽນ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ສົກສຶກສາທີ່ຈົບ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ຂໍ້​ມູນ​ນັກ​ສຶກ​ສາ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ລະດັບການສຶກສາ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ເປັນນັກສຶກສາ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ສຳລັບທືນການສຶກສາ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ວັນທີ່ລົງທະບຽນ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ລົງທະບຽນຈາກ ມຊ
                  </th>
                  <th rowSpan={2} className="align-middle">
                    ລົງທະບຽນຈາກຄະນະ
                  </th>
                </tr>
                <tr>
                  <th>ຊື ແລະ ນາມສະກຸນພໍ່</th>
                  <th>ຊື່ ແລະ ນາມສະກຸນແມ່</th>
                  <th>ເບີໂທລະສັບພໍ່/ແມ່</th>
                  <th>ບ້ານ</th>
                  <th>ເມືອງ</th>
                  <th>ແຂວງ</th>
                  <th>ປະເທດ</th>
                  <th>ພົວພັນທ່ານ</th>
                  <th>ເບີໂທຕິດຕໍ່</th>
                </tr>
              </thead>
              {dataStudentsExcel?.map((item, index) => {
                return (
                  <tbody key={index}>
                    <tr>
                      <td className="center">{index + 1}</td>
                      <td>{item?.semesterYear}</td>
                      <td>{item?.studentNumber}</td>
                      <td>
                        {convertGenderNam(item?.gender)}
                        {item?.firstNameL ? " " + item?.firstNameL : ""}
                        {item?.lastNameL ? " " + item?.lastNameL : ""}
                      </td>
                      <td>
                        {convertGenderNamEN(item?.gender)}
                        {item?.firstNameE ? " " + item?.firstNameE : ""}
                        {item?.lastNameE ? " " + item?.lastNameE : ""}
                      </td>
                      <td>{convertStatus(item?.maritualStatus)}</td>
                      <td>{item?.phoneMobile}</td>
                      <td>{formatDate(item?.birthday)}</td>
                      <td>
                        {item?.birthAddress?.village},{" "}
                        {item?.birthAddress?.district},{" "}
                        {item?.birthAddress?.province}
                      </td>
                      <td>
                        {item?.address?.village}, {item?.address?.district},{" "}
                        {item?.address?.province}
                      </td>
                      <td>{item?.nationality}</td>
                      <td>{item?.tribe?.trib_name}</td>
                      <td>{item?.religion}</td>
                      <td>{item?.fatherNameAndSurname}</td>
                      <td>{item?.motherNameAndSurname}</td>
                      <td>{item?.parentTelephone}</td>
                      <td>{item?.parentAddress?.village}</td>
                      <td>{item?.parentAddress?.district}</td>
                      <td>{item?.parentAddress?.province}</td>
                      <td>{item?.parentAddress?.country}</td>
                      <td>{item?.contactName}</td>
                      <td>{item?.emergencyPhone}</td>
                      <td>
                        {item?.dormitory === "YES"
                          ? "ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"
                          : "ບໍ່ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"}
                      </td>
                      <td>{item?.faculty?.name}</td>
                      <td>{item?.department?.name}</td>
                      <td>{item?.major?.name}</td>
                      <td>{item?.graduateFromProvince}</td>
                      <td>{item?.graduateFromSchool}</td>
                      <td>{item?.semesterYear}</td>
                      <td>{onConvertStudentInfo(item?.studentInfo)}</td>
                      <td>{onConvertEducationLevel(item?.educationLevel)}</td>
                      <td>{onConvertStudentType(item?.studentType)}</td>
                      {item?.studentType === "IN_PLAN_EN" ||
                      item?.studentType === "OUT_PLAN_EN" ? (
                        <>
                          <td>
                            {item?.scholarship
                              ? convertScholarshipEn(item?.scholarship)
                              : item?.otherScholarship}
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            {item?.scholarship
                              ? convertScholarship(item?.scholarship)
                              : item?.otherScholarship}
                          </td>
                        </>
                      )}
                      <td>{formatDate(item?.createdAt)}</td>
                      <td>
                        <span style={{ color: "#FF0000" }}>ຖືກປະຕິເສດ</span>
                      </td>
                      <td>
                        <span style={{ color: "#FF0000" }}>ຖືກປະຕິເສດ</span>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          )}
          <ReactHTMLTableToExcel
            style={{ backgroundColor: "#000" }}
            className="btn btn-outline-success"
            table="ExportData"
            filename={"ລາຍລະອຽດລົງທະບຽນເຂົ້າຮຽນ"}
            sheet="Sheet"
            buttonText="Export excel"
            font={"NotoSansLao"}
            headerPaddingCellOptions={{ background: "#ff0000" }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PreregistrationStudentRejected;
