import React from 'react'
import { CustomButton } from '../../../common'
import { Modal } from 'react-bootstrap'

import {  useMutation } from '@apollo/react-hooks'
import { UPDATE_FACULTY } from '../../../apollo/faculty/index'
import { alertSuccess, alertWarning } from '../../../common/super'

// DeleteFacultyConfirm
function DeleteFacultyConfirm ({
  deleteFacultyConfirmModal,
  _handleDeleteFacultyConfirmModalClose,
  facultyData
}) {
  const [deleteFaculty] = useMutation(UPDATE_FACULTY)
  const _deleteFaculty = async () => {
    deleteFaculty({ variables: { 
      data: {isDeleted: true},
      where: { id: facultyData.id } 
    } })
      .then(() => {
        _handleDeleteFacultyConfirmModalClose()
        alertSuccess('ການລຶບ')
      })
      .catch(err => {
        _handleDeleteFacultyConfirmModalClose()
        alertWarning('ການລຶບລົ້ມແຫຼວ')
      })
  }

  return (
    <Modal
      show={deleteFacultyConfirmModal}
      onHide={_handleDeleteFacultyConfirmModalClose}
      size='md'
    >
      <Modal.Body>
        <h4>ຕ້ອງການລຶບຄະນະ {facultyData?.name} ນີ້ແທ້ບໍ?</h4>
      </Modal.Body>
      <Modal.Footer>
      <CustomButton
          style={{background:"#fff", color: "#c7c7c7",border: "1px solid #c7c7c7"}}
          title='ຍົກເລີກ'
          onClick={_handleDeleteFacultyConfirmModalClose}
        />
        <CustomButton
          style={{background:"#c7c7c7", color: "#fff",border: "none"}}
          onClick={_deleteFaculty}
          title='ຢືນຢັນການລົບ'
        />
      </Modal.Footer>
    </Modal>
  )
}

export default DeleteFacultyConfirm
