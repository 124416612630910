import { gql } from "apollo-boost";

export const QUERY_USER_EDIT = gql`
query User($where: UserWhereUniqueInput!) {
  user(where: $where) {
    id
    userId
    firstname
    lastname
    email
    phone
    role
    description
    note
    birthday
    yearLevel
    gender
    maritualStatus
    religion
    educationBackGround
    highSchool
    college
    passportNo
    idNumber
    introduction
    address {
      country
      province
      district
      village
      postCode
      lat
      lng
      detail
      note
    }
    department {
      id
      name
    }
    faculty {
      id
      name
    }
    createdAt
    updatedAt
  }
}
`;

export const FACULTIES_EDIT_TEACHER = gql`
  query Faculties(
    $where: CustomFacultyWhereInput
  ) {
    faculties(
      where: $where
    ) {
        id
        name
    }
  }
`;

export const DEPARTMENTS_EDIT_TEACHER = gql`
query Departments( $where: CustomDepartmentWhereInput) {
    departments(where: $where) {
        id
        name
        faculty{
          id
          name
        }
    }
  }
`;