import React, { useEffect, useState } from "react";
import { fetStatistichData } from "../Api";
import Loading from "../../../common/Loading";
import { Button, Typography } from "@mui/material";
import { SaveAlt as SaveAltIcon } from "@mui/icons-material";
import * as XLSX from "xlsx";
import "../index.css";
import { useSelector } from "react-redux";

const StudentByAge = ({ faculty, department, major, studentType }) => {
  const [data, setData] = useState(null);
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const getData = async () => {
    setData();
    try {
      const result = await fetStatistichData(
        `allstudentage?faculty=${faculty}&department=${department}&major=${major}&assessmentYear=${accessmentRedux}&studentType=${studentType}`
      );
      console.log("data result => ", result);
      setData(result);
    } catch (error) { }
  };

  useEffect(() => {
    getData();
  }, [faculty, studentType, department, major, accessmentRedux]);

  const cellStyle = {
    width: "300px",
    height: "40px",
    textAlign: "center",
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.aoa_to_sheet(formatDataForExcel());
    const merges = generateMerges();
    ws["!merges"] = merges;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "StudentsByAge");
    XLSX.writeFile(wb, "StudentsByAge.xlsx");
  };

  const formatDataForExcel = () => {
    const headers = [
      [
        "ລຳດັບ",
        "ເກນອາຍຸ",
        "ລະບົບການຮຽນ",
        "ຈຳນວນນັກຮຽນທີ່ຈົບ",
        "",
        "",
        "ຈຳນວນນັກຮຽນທີ່ເຂົ້າໃໝ່ປີທີ 1",
        "",
        "",
        "ລະບົບການຮຽນ",
        "ຈຳນວນນັກຮຽນທີ່ຈົບ",
        "",
        "",
        "ຈຳນວນນັກຮຽນທີ່ເຂົ້າໃໝ່ປີທີ 1",
        "",
        "",
        "ລະບົບການຮຽນ",
        "ຈຳນວນນັກຮຽນທີ່ຈົບ",
        "",
        "",
        "ຈຳນວນນັກຮຽນທີ່ເຂົ້າໃໝ່ປີທີ 1",
        "",
        "",
      ],
      [
        "",
        "",
        "",
        "ລວມ",
        "ຍິງ",
        "ຊາຍ",
        "ລວມ",
        "ຍິງ",
        "ຊາຍ",
        "",
        "ລວມ",
        "ຍິງ",
        "ຊາຍ",
        "ລວມ",
        "ຍິງ",
        "ຊາຍ",
        "",
        "ລວມ",
        "ຍິງ",
        "ຊາຍ",
        "ລວມ",
        "ຍິງ",
        "ຊາຍ",
      ],
    ];

    const rows = Object.entries(data.data).map(([key, value], index) => [
      index + 1,
      key,
      "ປະລິນຍາຕີ ປົກກະຕິ",
      value["ປະລີນຍາຕີ"]?.total || 0,
      value["ປະລີນຍາຕີ"]?.female || 0,
      value["ປະລີນຍາຕີ"]?.male || 0,
      value["ປະລີນຍາຕີ"]?.totalYear1 || 0,
      value["ປະລີນຍາຕີ"]?.femaleYear1 || 0,
      value["ປະລີນຍາຕີ"]?.maleYear1 || 0,
      "ຕໍ່ເນື່ອງປະລິນຍາຕີ",
      value["ຕໍ່ເນື່ອງປະລິນຍາຕີ"]?.total || 0,
      value["ຕໍ່ເນື່ອງປະລິນຍາຕີ"]?.female || 0,
      value["ຕໍ່ເນື່ອງປະລິນຍາຕີ"]?.male || 0,
      value["ຕໍ່ເນື່ອງປະລິນຍາຕີ"]?.totalYear1 || 0,
      value["ຕໍ່ເນື່ອງປະລິນຍາຕີ"]?.femaleYear1 || 0,
      value["ຕໍ່ເນື່ອງປະລິນຍາຕີ"]?.maleYear1 || 0,
      "ອະນຸປະລິນຍາ",
      value["ອະນຸປະລິນຍາ"]?.total || 0,
      value["ອະນຸປະລິນຍາ"]?.female || 0,
      value["ອະນຸປະລິນຍາ"]?.male || 0,
      value["ອະນຸປະລິນຍາ"]?.totalYear1 || 0,
      value["ອະນຸປະລິນຍາ"]?.femaleYear1 || 0,
      value["ອະນຸປະລິນຍາ"]?.maleYear1 || 0,
    ]);

    return [...headers, ...rows];
  };

  const generateMerges = () => {
    return [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 1, c: 1 } },
      { s: { r: 0, c: 2 }, e: { r: 1, c: 2 } },
      { s: { r: 0, c: 3 }, e: { r: 0, c: 5 } },
      { s: { r: 0, c: 6 }, e: { r: 0, c: 8 } },
      { s: { r: 0, c: 9 }, e: { r: 1, c: 9 } },
      { s: { r: 0, c: 10 }, e: { r: 0, c: 12 } },
      { s: { r: 0, c: 13 }, e: { r: 0, c: 15 } },
      { s: { r: 0, c: 16 }, e: { r: 1, c: 16 } },
      { s: { r: 0, c: 17 }, e: { r: 0, c: 19 } },
      { s: { r: 0, c: 20 }, e: { r: 0, c: 22 } },
    ];
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <Typography variant="h5">
            {` ຈຳນວນນັກສຶກສາແຍກຕາມອາຍຸ, ຕາມລະບົບການຮຽນ (${studentType === "IN_PLAN" ? "ໃນແຜນ" : "ນອກເເຜນ"
              }) ປະລິນຍາຕີ`}
          </Typography>
        </div>
        <div>
          <Button
            variant="contained"
            color="success"
            startIcon={<i className="fa fa-file-excel"></i>}
            sx={{ textTransform: 'none' }}
            onClick={exportToExcel}
          >
            Export
          </Button>
        </div>
      </div>

      {data ? (
        <div style={{ overflowX: "auto" }}>
          <div
            style={{
              maxHeight: "80vh",
              overflowY: "auto",
              paddingBottom: "100px",
            }}
          >
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1,
                }}
              >
                <tr>
                  <th
                    rowSpan={2}
                    style={{
                      width: "100px",
                    }}
                  >
                    ລຳດັບ
                  </th>
                  <th
                    rowSpan={2}
                    style={{
                      width: "100px",
                    }}
                  >
                    ເກນອາຍຸ
                  </th>
                  <th rowSpan={2} style={cellStyle}>
                    ລະບົບການຮຽນ
                  </th>
                  <th colSpan={3} style={cellStyle}>
                    ນັກສຶກສາທັງໝົດ
                  </th>
                  <th colSpan={3} style={cellStyle}>
                    ນັກສຶກສາເຂົ້າໃຫມ່ປີທີ 1
                  </th>
                  <th rowSpan={2} style={cellStyle}>
                    ລະບົບການຮຽນ
                  </th>
                  <th colSpan={3} style={cellStyle}>
                    ນັກສຶກສາທັງໝົດ
                  </th>
                  <th colSpan={3} style={cellStyle}>
                    ນັກສຶກສາເຂົ້າໃຫມ່ປີທີ 1
                  </th>
                  <th rowSpan={2} style={cellStyle}>
                    ລະບົບການຮຽນ
                  </th>
                  <th colSpan={3} style={cellStyle}>
                    ນັກສຶກສາທັງໝົດ
                  </th>
                  <th colSpan={3} style={cellStyle}>
                    ນັກສຶກສາເຂົ້າໃຫມ່ປີທີ 1
                  </th>
                </tr>
                <tr>
                  <th style={cellStyle}>ລວມ</th>
                  <th style={cellStyle}>ຍິງ</th>
                  <th style={cellStyle}>ຊາຍ</th>
                  <th style={cellStyle}>ລວມ</th>
                  <th style={cellStyle}>ຍິງ</th>
                  <th style={cellStyle}>ຊາຍ</th>
                  <th style={cellStyle}>ລວມ</th>
                  <th style={cellStyle}>ຍິງ</th>
                  <th style={cellStyle}>ຊາຍ</th>
                  <th style={cellStyle}>ລວມ</th>
                  <th style={cellStyle}>ຍິງ</th>
                  <th style={cellStyle}>ຊາຍ</th>
                  <th style={cellStyle}>ລວມ</th>
                  <th style={cellStyle}>ຍິງ</th>
                  <th style={cellStyle}>ຊາຍ</th>
                  <th style={cellStyle}>ລວມ</th>
                  <th style={cellStyle}>ຍິງ</th>
                  <th style={cellStyle}>ຊາຍ</th>
                </tr>
              </thead>
              <tbody>
                {data.data &&
                  Object.entries(data.data).map(([key, value], index) => {
                    return (
                      <tr key={key}>
                        <td style={cellStyle}>{index + 1}</td>
                        <td style={cellStyle}>{key}</td>
                        <td style={cellStyle}>{"ປະລິນຍາຕີ ປົກກະຕິ"}</td>
                        <td style={cellStyle}>
                          {value["ປະລີນຍາຕີ"]?.total || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ປະລີນຍາຕີ"]?.female || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ປະລີນຍາຕີ"]?.male || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ປະລີນຍາຕີ"]?.totalYear1 || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ປະລີນຍາຕີ"]?.femaleYear1 || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ປະລີນຍາຕີ"]?.maleYear1 || 0}
                        </td>
                        <td style={cellStyle}>{"ຕໍ່ເນື່ອງປະລິນຍາຕີ"}</td>
                        <td style={cellStyle}>
                          {value["ຕໍ່ເນື່ອງປະລິນຍາຕີ"]?.total || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ຕໍ່ເນື່ອງປະລິນຍາຕີ"]?.female || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ຕໍ່ເນື່ອງປະລິນຍາຕີ"]?.male || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ຕໍ່ເນື່ອງປະລິນຍາຕີ"]?.totalYear1 || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ຕໍ່ເນື່ອງປະລິນຍາຕີ"]?.femaleYear1 || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ຕໍ່ເນື່ອງປະລິນຍາຕີ"]?.maleYear1 || 0}
                        </td>
                        <td style={cellStyle}>{"ອະນຸປະລິນຍາ"}</td>
                        <td style={cellStyle}>
                          {value["ອະນຸປະລິນຍາ"]?.total || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ອະນຸປະລິນຍາ"]?.female || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ອະນຸປະລິນຍາ"]?.male || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ອະນຸປະລິນຍາ"]?.totalYear1 || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ອະນຸປະລິນຍາ"]?.femaleYear1 || 0}
                        </td>
                        <td style={cellStyle}>
                          {value["ອະນຸປະລິນຍາ"]?.maleYear1 || 0}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="bg-white">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default StudentByAge;
