import React, { useState, useEffect } from "react";
/**
 *
 * @Library
 *
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
/**
 *
 * @Component
 *
 */
import { Breadcrumb, Form, Row, Col } from "react-bootstrap";
import { CustomContainer, Title, TableHeader, TableCell } from "../../common";
import Loading from "../../common/Loading";
import moment from "moment";
/**
 *
 * @Constants
 *
 */
import Routers from "../../consts/router";
/**
 *
 * @Function
 *
 */
import { toDayDash, nextOneDayParameter, formatDate } from "../../common/super";
import PaginationHelper from "../../helpers/PaginationHelper";
/**
 *
 * @Apollo
 *
 */
import { USER_LOGIN } from "../../apollo/userLogin/index";

export default function Teacheradminhistory() {
  const { history } = useReactRouter();
  /**
   *
   * @state
   *
   */
  const [startDate, setStartDate] = useState();
  const [setEndDate] = useState(moment(Date.now()).format("YYYY-MM-DD"));
  const [endDateAddOneDay, setEndDateAddOneDay] = useState(moment(Date.now()).format("YYYY-MM-DD"));
  // state Data History Login
  const [dataHistoryLogin, setDataHistoryLogin] = useState();
  const [loginhistoryCount, setLoginhistoryCount] = useState();
  const { _limit, _skip, Pagination_helper } = PaginationHelper();
  /**
   *
   * Apollo Query Data
   *
   */
  const [dataUserLogin, { data, loading }] = useLazyQuery(USER_LOGIN, {fetchPolicy: "cache-and-network"});

  /**
   *
   * State
   *
   */

  useEffect(() => {
    dataUserLogin({
      variables: {
        where: {
          role: "TEACHER",
        },
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data) return;
    setDataHistoryLogin(data?.userLogins?.data);
    setLoginhistoryCount(data?.userLogins?.total);
  }, [data]);

  // set state menu item
  const all = (e) => {
    history.push(Routers.LOGIN_HISTORY + Routers.PAGE_GINATION);
  };
  const _admin = (e) => {
    history.push(Routers.ADMIN_HISTORY + Routers.PAGE_GINATION)
  };
  const _dep_admin = (e) => {
    history.push(Routers.DEPARTMENT_HISTORY + Routers.PAGE_GINATION);
  };
  const _fac_admin = (e) => {
    history.push(Routers.FACULTY_HISTORY + Routers.PAGE_GINATION);
  };
  const _teacher = (e) => {
    history.push(Routers.TEACHER_HISTORY + Routers.PAGE_GINATION);
  };
  const _student = (e) => {
    history.push(Routers.STUDENT_HISTORY + Routers.PAGE_GINATION);
  };

  // search DATE
  const _startDate = (e) => {
    let stDate = e.target.value;
    setStartDate(stDate);
  };
  const _endDate = async (e) => {
    let enDate = e.target.value;
    // setEndDate(enDate);
    let addDateToOne = await nextOneDayParameter(enDate);
    setEndDateAddOneDay(addDateToOne);
  };

  const _searchDate = () => {
    dataUserLogin({
      variables: {
        where: {
           role: "TEACHER",
              createdAt_gte: startDate,
              createdAt_lt: endDateAddOneDay
            },
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/setting-list")}>
          <strong>ຕັ້ງຄ່າ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.goBack()}>
          <strong>ເລືອກການຕັ້ງຄ່າ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ປະຫວັດການເຂົ້າໃຊ້ບະບົບ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <div
          style={{
            fontSize: 32,
            color: "#057CAE",
            fontWeight: "bold",
            marginTop: 35,
          }}
        >
          ລາຍຊື່ການເຂົ້າໃຊ້ລະບົບທັງໝົດ
        </div>
        <Title>ປະຫວັດການເຂົ້າໃຊ້ບະບົບທັງໝົດ</Title>
        <Form.Group>
          <Row className="justify-content-md-center">
            <Col sm={4}>
              <Form.Label>ວັນທີເລີ່ມຕົ້ນ</Form.Label>
              <Form.Control
                type="date"
                defaultValue={toDayDash()}
                placeholder="Normal text"
                onChange={(e) => _startDate(e)}
              />
            </Col>
            <Col sm={4}>
              <Form.Label>ວັນທີ່ສຸດທ້າຍ</Form.Label>
              <Form.Control
                type="date"
                defaultValue={toDayDash()}
                onChange={(e) => _endDate(e)}
              />
            </Col>
            <button
              type="button"
              className="btn btn-primary"
              style={{
                backgroundColor: "#057CAE",
                borderRadius: 8,
                marginTop: 29,
                width: 200,
                height: 40,
              }}
              onClick={() => _searchDate()}
            >
              <FontAwesomeIcon icon={faSearch} style={{ marginRight: 30 }} />{" "}
              ຄົ້ນຫາ
            </button>
          </Row>
        </Form.Group>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 30,
            fontWeight: "bold",
          }}
        >
          <div className="menuItem" onClick={() => all("ALL")}> ທັງໝົດ </div>
          <div className="menuItem" onClick={() => _admin("ADMIN")}>ວິຊາການ</div>
          <div className="menuItem" onClick={() => _fac_admin("FAC_ADMIN")}>ຄະນະ</div>
          <div className="menuItem" onClick={() => _dep_admin("DEP_ADMIN")}>ພາກວິຊາ</div>
          <div className="menuItem  active-menu" onClick={() => _teacher("TEACHER")}>ອາຈານ</div>
          <div className="menuItem" onClick={() => _student("STUDENT")}>ນັກສຶກສາ</div>
        </div>

        <div style={{ margin: 10 }}>ທັງໝົດ ({loginhistoryCount} )</div>
        <div>
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader>
                <th style={{ width: 70 }}>ລຳດັບ</th>
                <th>UserId</th>
                <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                <th>ວັນເດືອນປີເຂົ້າລະບົບ</th>
              </TableHeader>
            </thead>
            {loading ?  <Loading/> : dataHistoryLogin?.map((item, index) => {
              return (
                <tbody key={index}>
                  <tr
                    style={{
                      borderBottom: "2px solid #ffff",
                      textAlign: "center",
                    }}
                  >
                    <TableCell>{index + 1 + _limit * (_skip - 1)}</TableCell>
                    <td>{item?.userId}</td>
                    <td>
                      {item?.firstName} {item?.lastName}{" "}
                    </td>
                    <td>{formatDate(item?.createdAt)}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
        {Pagination_helper(loginhistoryCount, Routers.TEACHER_HISTORY)}
      </CustomContainer>
    </div>
  );
}
