import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { Breadcrumb } from "react-bootstrap";
import "./utils/index.css";
import { CustomContainer } from "../../common";
import SettingsTribes from "./settingsTribes/SettingsTribes";
import SettingReligional from "./settingReligional/settingReligional";
import SettingClassRoom from "./settingClassRoom/settingClassRoom";
import SettingFaculty from "./settingFaculty/settingFaculty";

// appllo ==================================
function SettingMasterData() {
  const { history, match } = useReactRouter();
  const content = match?.params?.content;
  const [dataUser, setDataUser] = useState(null);

  // on first load ==================================
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
  }, []);

  const _renderBreadcrumb = () => {
    switch (content) {
      case "tribe":
        return "ລາຍການຊົນເຜົ່າ";
      case "religional":
        return "ລາຍການສາສະໜາ";
      case "class-room":
        return "ລາຍການຫ້ອງຮຽນ";
      case "faculty":
        return "ລາຍການຄະນະ";
      default:
        break;
    }
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ເລືອກການຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {/* {content === "tribe"
            ? "ລາຍການຊົນເຜົ່າ"
            : content === "religional"
            ? "ລາຍການສາສະໜາ"
            : "ລາຍການຄະນະ"} */}
          {_renderBreadcrumb()}
        </Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <div className="row" style={{ marginTop: 0, marginBottom: 0 }}>
          <div
            className="col-md-3"
            style={{ borderRight: "2px solid #F0F0F0" }}
          >
            <div className="list-group">
              <li
                type="button"
                onClick={() =>
                  history.push(
                    `/setting-master-data/${"tribe"}/limit/50/skip/1`
                  )
                }
                className={
                  content === "tribe"
                    ? "list-group-item list-group-item-action on-active"
                    : "list-group-item list-group-item-action"
                }
              >
                <i className="fa fa-caret-right"></i>&nbsp; ເພີ່ມຊົນເຜົ່າ
              </li>
              <li
                type="button"
                onClick={() =>
                  history.push(
                    `/setting-master-data/${"religional"}/limit/50/skip/1`
                  )
                }
                className={
                  content === "religional"
                    ? "list-group-item list-group-item-action on-active"
                    : "list-group-item list-group-item-action"
                }
              >
                <i className="fa fa-caret-right"></i>&nbsp; ເພີ່ມສາສະໜາ
              </li>
              <button
                type="button"
                onClick={() =>
                  history.push(
                    `/setting-master-data/${"class-room"}/limit/50/skip/1`
                  )
                }
                className={
                  content === "class-room"
                    ? "list-group-item list-group-item-action on-active"
                    : "list-group-item list-group-item-action"
                }
              >
                <i className="fa fa-caret-right"></i>&nbsp; ເພີ່ມຫ້ອງຮຽນ
              </button>
              <li
                type="button"
                onClick={() =>
                  history.push(
                    `/setting-master-data/${"faculty"}/limit/50/skip/1`
                  )
                }
                className={
                  content === "faculty"
                    ? "list-group-item list-group-item-action on-active"
                    : "list-group-item list-group-item-action"
                }
              >
                <i className="fa fa-caret-right"></i>&nbsp; ເພີ່ມຄະນະ
              </li>
              {/* <button
                type="button"
                // onClick={() =>
                //   history.push(`/setting-master-data/${"deparment"}`)
                // }
                className={
                  content === "department"
                    ? "list-group-item list-group-item-action on-active"
                    : "list-group-item list-group-item-action"
                }
              >
                <i className="fa fa-caret-right"></i>&nbsp; ເພີ່ມວິຊາ
              </button> */}
            </div>
          </div>
          <div className="col-md-9" style={{ marginTop: "10px" }}>
            {content === "tribe" ? (
              <SettingsTribes />
            ) : content === "religional" ? (
              <SettingReligional />
            ) : content === "class-room" ? (
              <SettingClassRoom />
            ) : content === "faculty" ? (
              <SettingFaculty />
            ) : (
              ""
            )}
          </div>
        </div>
      </CustomContainer>
    </div>
  );
}
export default SettingMasterData;
