import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import { Breadcrumb, Modal, Form } from "react-bootstrap";
import { valiDate } from "../../../common/super";
import { CustomButton, CustomContainer, TableHeader } from "../../../common";
import EditMajor from "./EditMajor";
import { fetchDelete, fetchGet, fetchPost } from "../../../common/fetchAPI";

function SettingMajor() {
  const { history, location } = useReactRouter();

  const accessmentRedux = useSelector((state) => state.accessment.value);
  const getData = location?.state;
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const _closeAdd = () => setShowAdd(false);
  const _closeDelete = () => setShowDelete(false);
  const [assessmentYear, setAssessmentYear] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState();

  // Edit Department
  const [showSearchView, setShowSearchView] = useState(false);
  const [newData, setNewData] = useState();

  const _handleSearchViewClose = () => {
    setShowSearchView(false);
    fetchMajor();
  };

  useEffect(() => {
    let year = [];
    const NOW = new Date().getFullYear();
    for (var i = NOW - 0; i >= 2014; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    setAssessmentYear(year);
  }, [accessmentRedux]);

  const _showDelete = (data) => {
    setShowDelete(true);
    setDeleteConfirm(data);
  };

  const [majors, setMajors] = useState([]);

  const fetchMajor = async () => {
    const data = await fetchGet(
      `major?faculty=${getData?.faculty?._id}&department=${getData?._id}&assessmentYear=${accessmentRedux}`
    );
    setMajors(data);
  };

  useEffect(() => {
    fetchMajor();
  }, [accessmentRedux]);

  const _addMajor = async (data) => {
    await fetchPost("major", {
      ...data,
      faculty: getData?.faculty?._id,
      department: getData?._id,
    });
    setShowAdd(false);
    fetchMajor();
  };

  const _confirmDelete = async (deleteConfirm) => {
    await fetchDelete("major/" + deleteConfirm._id);
    setShowDelete(false);
    fetchMajor();
  };

  const _handleSearchViewShow = (e) => {
    setNewData(e);
    setShowSearchView(true);
  };
  return (
    <div>
      {/* add Department start here */}
      <div
        className="modal fade"
        id="addDeparment"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        style={{ display: "absolute" }}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 style={{ color: "#057CAE", fontWeight: "bold" }}>
                ເພີ່ມສາຂາວິຊາ
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Formik
              initialValues={{
                name: "",
                description: "",
                enName: "",
                enDescription: "",
                note: "",
              }}
              validate={(values) => {
                const errors = {};
                if (!values.name) {
                  errors.name = "Required";
                }
                if (!values.assessmentYear) {
                  errors.assessmentYear = "Required";
                }
                return errors;
              }}
              onSubmit={(values) => {
                let status = values.status;
                if (status == "") {
                  status = "DISABLE";
                } else {
                  status = "ENABLE";
                }
                let data = {
                  name: values.name,
                  description: values.description,
                  enName: values.enName,
                  enDescription: values.enDescription,
                  assessmentYear: values.assessmentYear,
                  note: values.note,
                };
                _addMajor(data);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Modal
                  animation={false}
                  size="lg"
                  centered
                  show={showAdd}
                  onHide={_closeAdd}
                  style={{ padding: 0 }}
                >
                  <Modal.Header style={{ padding: 10, border: 0 }}>
                    <Modal.Title
                      style={{
                        textAlign: "center",
                        width: "100%",
                        padding: 0,
                        marginTop: 20,
                        fontWeight: "bolder",
                      }}
                    >
                      <strong>ເພີ່ມຂໍ້ມູນສາຂາວິຊາ</strong>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "0 100px 20px 100px",
                      justifyContent: "center",
                    }}
                  >
                    <div className="model-contents">
                      <label>ຊື່ສາຂາວິຊາ(ລາວ) {valiDate()}:</label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        placeholder="ກະລຸນາປ້ອນຊື່ສາຂາວິຊາ(ລາວ)"
                        isInvalid={!!errors.name}
                      />
                      <label>ຕົວຫຍໍ້(ລາວ)</label>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="description"
                        onChange={handleChange}
                        value={values.description}
                        placeholder="ກະລຸນາປ້ອນຕົວຫຍໍ້(ຖ້າມີ)"
                      />
                      <label>ຊື່ສາຂາວິຊາ(ອັງກິດ):</label>
                      <input
                        type="text"
                        className="form-control"
                        name="enName"
                        onChange={handleChange}
                        value={values.enName}
                        placeholder="ກະລຸນາປ້ອນຊື່ສາຂາວິຊາ(ອັງກິດ)"
                      />
                      <label>ຕົວຫຍໍ້(ອັງກິດ):</label>
                      <textarea
                        type="text"
                        rows="3"
                        className="form-control"
                        name="enDescription"
                        onChange={handleChange}
                        value={values.enDescription}
                        placeholder="ກະລຸນາປ້ອນຕົວຫຍໍ້ຂອງສາຂາວິຊານີ້(ອັງກິດ)"
                      ></textarea>
                      <label>ສົກສຶກສາ {valiDate()}:</label>
                      <Form.Control
                        as="select"
                        name="assessmentYear"
                        value={values.assessmentYear}
                        className="form-select"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        isInvalid={!!errors.assessmentYear}
                      >
                        <option value="">---ກະລຸນາເລືອກສົກສຶກສາ---</option>
                        {assessmentYear.map((x, assesIndex) => {
                          return (
                            <option key={assesIndex} value={x}>
                              {x}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <label>ໝາຍເຫດ</label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="note"
                        onChange={handleChange}
                        value={values.note}
                        placeholder="ກະລຸນາປ້ອນໝາຍເຫດຖ້າມີ"
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <CustomButton title="ຍົກເລີກ" onClick={_closeAdd} />
                    <CustomButton
                      onClick={handleSubmit}
                      title="ບັນທຶກ"
                      confirm
                    />
                  </Modal.Footer>
                </Modal>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {/* end add department here  */}

      {/* delete modal */}

      <Modal show={showDelete} onHide={_closeDelete} size="md">
        <Modal.Body>
          <h4>ຕ້ອງການລຶບສາຂາວິຊາ {} ແທ້ບໍ?</h4>
        </Modal.Body>
        <Modal.Footer>
          <CustomButton
            style={{
              background: "#fff",
              color: "#c7c7c7",
              border: "1px solid #c7c7c7",
            }}
            title="ຍົກເລີກ"
            onClick={_closeDelete}
          />
          <CustomButton
            style={{ background: "#c7c7c7", color: "#fff", border: "none" }}
            onClick={() => _confirmDelete(deleteConfirm)}
            title="ຢືນຢັນການລົບ"
          />
        </Modal.Footer>
      </Modal>

      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ເລືອກການຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() =>
            history.push("/setting-master-data/faculty/limit/50/skip/1")
          }
        >
          ເພີ່ມຄະນະ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push("/setting-department", getData?.faculty)}
        >
          ເພີ່ມພາກວິຊາ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ເພີ່ມສາຂາວິຊາ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <div className="row">
          <div
            className="col-sm-3"
            style={{ borderRight: "1px solid #f0f0f0" }}
          >
            <p>ຊື່(ລາວ): {getData?.name}</p>
            <p>ຊື່(En): {getData?.enName}</p>
            <p>ຕົວຫຍໍ້(En):{getData?.enDescription}</p>
          </div>
          <div className="col-sm-9">
            <div>
              <b>ລາຍການສາຂາວິຊາ</b>
              <button
                className="btn btn-info right"
                style={{ margin: 0 }}
                onClick={() => setShowAdd(true)}
              >
                <i className="fa fa-plus"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                ເພີ່ມສາຂາວິຊາ
              </button>
            </div>
            <br />
            <div>
              <table className="table-hover tables">
                <thead>
                  <TableHeader>
                    <th style={{ width: "10%" }}>ລຳດັບ</th>
                    <th>ຊື່ພາສາລາວ</th>
                    <th>ຊື່ພາສາອັງກິດ</th>
                    <th style={{ width: "20%" }}>ຈັດການ</th>
                  </TableHeader>
                </thead>
                <tbody>
                  {majors?.map((item, index) => (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{item?.name}</td>
                      <td>{item?.enName}</td>
                      <td className="text-center">
                        <button
                          className="btn btn-control"
                          onClick={(e) => _handleSearchViewShow(item)}
                          data-toggle="modal"
                          data-target="#editDepartment"
                        >
                          <i className="fa fa-pen edit"></i>
                        </button>
                        <button
                          className="btn btn-control"
                          data-toggle="modal"
                          data-target="#delete"
                          onClick={() => _showDelete(item)}
                        >
                          <i className="fa fa-trash trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <EditMajor
              showSearchView={showSearchView}
              _handleSearchViewClose={_handleSearchViewClose}
              allData={newData}
              getData={getData}
            />
          </div>
        </div>
      </CustomContainer>
    </div>
  );
}

export default SettingMajor;
