import { gql } from 'apollo-boost'

export const FACULTIES = gql`
  query Faculties(
    $where: CustomFacultyWhereInput
    $orderBy: OrderDataInput
    $skip: Int
    $first: Int
  ) {
    faculties(
      where: $where
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
        id
        name
        description
        status
        startDateRegister
        endDateRegister
    }
  }
`;

export const FACULTIE = gql`
  query FacultiesQueryShow {
  facultyQueryShow{
    fac_id
    fac_name
  }
  }
`;

export const FACULTY = gql`
  query Faculty(
    $where: FacultyWhereUniqueInput!
  ) {
    faculty(
      where: $where
    ) {
        id
        name
        departments{
          id
          name
          note
        }
        startDateRegister
        endDateRegister
        createdBy{
          role
          firstname
          lastname
        }
        updatedBy{
          role
          firstname
          lastname
        }
        createdAt
        updatedAt
    }
  }
`;

export const FACULTY_NAME = gql`
  query Faculty($where: CustomFacultyWhereInput) {
    faculties(where: $where) {
      id
      name
      description
      departments {
        id
        name
        enName
      }
    }
  }
`;
export const UPDATE_FACULTY = gql`
  mutation UpdateFaculty(
    $data: CustomFacultyUpdateDataInput!
    $where: FacultyWhereUniqueInput!
  ) {
    updateFaculty(data: $data, where: $where) {
      id
    }
  }
`

export const UPDATE_FACULTY_REGISTER = gql`
  mutation UpdateFacultyDateRegister(
    $data: UpdateFacultyDateRegisterInput!
    $where: FacultyWhereUniqueInput!
  ) {
    updateFacultyDateRegister(data: $data, where: $where) {
      id
    }
  }
`
export const FACULTY_MASTER = gql`
  query Faculty (
    $where: CustomFacultyWhereInput
    $orderBy: FacultyOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int){
    faculties (
      where: $where 
      orderBy: $orderBy 
      skip: $skip 
      after: $after 
      before: $before 
      first: $first 
      last: $last){
      id
      name
      enName
      description
      enDescription
      startDateRegister
      endDateRegister
      status
      note
      createdBy {
        role
        firstname
        lastname
      }
      updatedBy {
        role
        firstname
        lastname
      }
      createdAt
      updatedAt
    }
  }
`
export const CREATE_FACULTY = gql`
mutation CreateFaculty($data: CustomFacultyInput!) {
  createFaculty(data: $data) {
    id
  }
}
`
export const DELETE_FACULTY = gql`
mutation DeleteFaculty($where: FacultyWhereUniqueInput!) {
  deleteFaculty(where: $where) {
    id
  }
}
`;