import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Modal, Button, Form, Row, Col, Toast } from "react-bootstrap";
import Consts from "../../consts";
import { Formik } from "formik";

import { UPDATE_REGISTRATION } from "../../apollo/registration";
import { USERS } from "../../apollo/user";
import { REGISTER_COURSES } from "../../apollo/course";

function RegistrationEdit({ showEditView, _handleEditViewClose, registrationData }) {
  const [updateRegistration] = useMutation(UPDATE_REGISTRATION);
  const { loading: usersLoading, data: usersData } = useQuery(USERS, { variables: { where: { role: "STUDENT" } } });
  const { loading: coursesLoading, data: coursesData} = useQuery(REGISTER_COURSES);

  //States
  const [data, setData] = useState({});
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (registrationData) {
      setData(registrationData);
    }
  }, [registrationData]);

  // //functions
  // const _registration = (values) => {
  //   let paramQL = {
  //     data: {
  //       note: values.note,
  //       student: {
  //         connect: {
  //           userId: values.userId,
  //         },
  //       },
  //       course: {
  //         connect: {
  //           courseCode: values.courseCode,
  //         },
  //       },
  //     },
  //     where: { id: data.id },
  //   };

  //   updateRegistration({ variables: paramQL })
  //     .then(async () => {
  //       await _handleEditViewClose();
  //       // history.push("/registration-list")
  //       window.location.reload(true);
  //     })
  //     .catch((err) => {
  //       _handleEditViewClose();
  //       setShowToast(true);
  //     });
  // };

  return (
    <div>
      {/* <Modal show={showEditView} onHide={_handleEditViewClose} size="lg">
        <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
          <b>ແກ້ໄຂການລົງທະບຽນວິຊາ</b>
        </Modal.Title>
        {(usersLoading || coursesLoading) && "Loading..."}
        <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
          {data ? (
            <Formik
              initialValues={{
                userId: data.student
                  ? data.student.userId
                    ? data.student.userId
                    : ""
                  : "",
                courseCode: data.course
                  ? data.course.courseCode
                    ? data.course.courseCode
                    : ""
                  : "",
                note: data.note ? data.note : "",
              }}
              
              onSubmit={(values, { setSubmitting }) => {
                _registration(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <div>
                  <div style={{ border: "#eee solid 1px", padding: 50 }}>
                    {usersData ? (
                      <Form.Group
                        as={Row}
                        controlId="formPlaintextEmail"
                        style={{ margin: 0, marginBottom: 10 }}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ເລືອກນັກສຶກສາ
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            as="select"
                            name="userId"
                            onChange={handleChange}
                            value={values.userId}
                            isInvalid={!!errors.userId}
                          >
                            <option disabled={true} value="">
                              ---ກະລຸນາເລືອກນັກສຶກສາ---
                            </option>
                            {usersData.users &&
                              usersData.users.map((x, index) => (
                                <option key={"userId" + index} value={x.userId}>
                                  {x.firstname +
                                    " " +
                                    (x.lastname ? x.lastname : "")}
                                </option>
                              ))}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    ) : (
                      ""
                    )}

                    {coursesData ? (
                      <Form.Group
                        as={Row}
                        controlId="formPlaintextEmail"
                        style={{ margin: 0, marginBottom: 10 }}
                      >
                        <Form.Label column sm="4" className="text-left">
                          ເລືອກວິຊາ
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            as="select"
                            name="courseCode"
                            onChange={handleChange}
                            value={values.courseCode}
                            isInvalid={!!errors.courseCode}
                          >
                            <option disabled={true} value="">
                              ---ກະລຸນາເລືອກວິຊາ---
                            </option>
                            {coursesData.courses &&
                              coursesData.courses.map((x, index) => (
                                <option
                                  key={"courseCode" + index}
                                  value={x.courseCode}
                                >
                                  {x.title}
                                </option>
                              ))}
                          </Form.Control>
                        </Col>
                      </Form.Group>
                    ) : (
                      ""
                    )}

                    <Form.Group
                      as={Row}
                      controlId="formPlaintextEmail"
                      style={{ margin: 0, marginBottom: 10 }}
                    >
                      <Form.Label column sm="4" className="text-left">
                        ໝາຍເຫດ
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder="ກະລຸນາປ້ອນ"
                          name="note"
                          value={values.note}
                          onChange={handleChange}
                          isInvalid={!!errors.note}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                  <div style={{ height: 20 }} />
                  <div className="row">
                    <div style={{ padding: 15 }} className="col">
                      <Button
                        onClick={_handleEditViewClose}
                        style={{
                          width: "100%",
                          backgroundColor: "#fff",
                          color: "#6f6f6f",
                          borderColor: Consts.SECONDARY_COLOR,
                        }}
                      >
                        ຍົກເລີກ
                      </Button>
                    </div>
                    <div style={{ padding: 15 }} className="col">
                      <Button
                        style={{
                          width: "100%",
                          backgroundColor: Consts.SECONDARY_COLOR,
                          color: "#fff",
                          borderColor: Consts.SECONDARY_COLOR,
                        }}
                        onClick={handleSubmit}
                      >
                        ລົງທະບຽນ
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>

      <div
        style={{
          position: "fixed",
          top: 10,
          right: 10,
          zIndex: 9999,
        }}
      >
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header style={{ backgroundColor: "#c0392b" }}>
            <strong className="mr-auto" style={{ color: "#fff" }}>
              ມີຂໍ້ຜິດພາດ
            </strong>
          </Toast.Header>
          <Toast.Body>ທ່ານຕື່ມຂໍ້ມູນບໍ່ຖືກຕ້ອງຕາມທີ່ລະບົບກໍານົດ</Toast.Body>
        </Toast>
      </div> */}
    </div>
  );
}

export default RegistrationEdit;
