import React from "react";
import useReactRouter from "use-react-router";
import { useMutation } from "@apollo/react-hooks";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Consts from "../../consts";
import Routers from "../../consts/router";
import { UPDATE_USER } from "./../../apollo/user";
import { convertNamNar, convertGender } from "../../helpers/user";
import { messageWarning } from "../../common/super";
import * as _ from "lodash";

function TeacherEditConfirm({
  showAddConfirmModal,
  _handleShowAddConfirmModalClose,
  param,
  faculty,
  department
}) {
  const { history } = useReactRouter();
  const [updateUser] = useMutation(UPDATE_USER);
  const _confirmTeacherEdit = async () => {
    try{
    delete param.data.day;
    delete param.data.month;
    delete param.data.year;
    
    if (!param.data.email) {
      delete param.data.email;
    }
    if(param.data.checkUpdatePhone === "Not change") {
      delete param.data.phone;
      delete param.data.checkUpdatePhone
    }
    if (!param.data.phone) {
      delete param.data.phone;
    }
    if (!param.data.lastname) {
      delete param.data.lastname;
    }
    if (
      !param.data.birthday ||
      param.data.birthday === "undefined-undefined-undefined"
    ) {
      delete param.data.birthday;
    }
    if (!param.data.gender) {
      delete param.data.gender;
    }
    if (!param.data.maritualStatus) {
      delete param.data.maritualStatus;
    }
    delete param.data.userId;
    delete param.data.confirmPassword;
    await updateUser({ variables: param });
    history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION);
    window.location.reload(true);
  } catch (err){
    if(err?.message == "GraphQL error: USER_ALREADY_EXIST")
      messageWarning("ໄອດີນີ້ມີຢູ່ແລ້ວ ກະລຸນາປ້ອນໄອດີໃໝ່!");
  }
  };

  const _renderFaculty = (id) => {
    let facultyIndex = _.find(faculty.faculties, function (o) {
      return o.id === id;
    });
    return facultyIndex.name;
  };

  const _renderDepartment = (departmentId) => {
    let departmentIndex = _.find(department.departments, function (o) {
      return o.id === departmentId;
    });
    return departmentIndex.name;
  };

  return (
    <Modal
      show={showAddConfirmModal}
      onHide={_handleShowAddConfirmModalClose}
      size="lg"
    >
      <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
        ຢືນຢັນການແກ້ໄຂຂໍ້ມູນອາຈານ
      </Modal.Title>
      <Modal.Body
        style={{
          marginLeft: 50,
          marginRight: 5,
          color: Consts.SECONDARY_COLOR0,
          padding: 50,
          paddingTop: 0,
        }}
      >
        <div>
          <div
            style={{
              backgroundColor: "#fff",
              padding: 10,
              marginTop: 20,
            }}
          >
            {/* Form container */}
            <div
              style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
            >
              {/* ---------- ຄະນະແລະຄະນະ --------- */}
              <div style={{ marginBottom: 10 }}>
                <div>
                  <i
                    className="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
                  />
                  ຄະນະແລະພາກວິຊາ
                </div>
                {/* ຄະນະ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ຄະນະ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data &&
                        (param.data.faculty
                          ? _renderFaculty(param.data.faculty)
                          : "")}
                    </span>
                  </Col>
                </Form.Group>

                {/* ຄະນະ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ພາກວິຊາ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data &&
                        // (param.data.faculty && param.data.department
                        (param.data
                          ? _renderDepartment(
                              // param.data.faculty.connect.id,
                              param.data.department
                            )
                          : "")}
                    </span>
                  </Col>
                </Form.Group>
              </div>
              {/* ---------- ຂໍ້ມູນອາຈານ --------- */}
              <div style={{ marginBottom: 10 }}>
                <div>
                  <i
                    className="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
                  />
                  ຂໍ້ມູນອາຈານ
                </div>
                {/* ຊື່ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ຊື່
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{param.data && param.data.firstname} </span>
                  </Col>
                </Form.Group>

                {/* ນາມສະກຸນ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ນາມສະກຸນ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{param.data && param.data.lastname}</span>
                  </Col>
                </Form.Group>

                {/* ວັນເດືອນປີເກີດ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ວັນເດືອນປີເກີດ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param?.data?.birthday}
                    </span>
                  </Col>
                </Form.Group>

                {/* ເພດ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ເພດ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data &&
                        (param.data.gender
                          ? convertNamNar(param.data.gender)
                          : "")}
                    </span>
                  </Col>
                </Form.Group>

                {/* ສະຖານະ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ສະຖານະ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data &&
                        (param.data.maritualStatus
                          ? convertGender(param.data.maritualStatus)
                          : "")}
                    </span>
                  </Col>
                </Form.Group>

                {/* ເບີໂທ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ເບີໂທ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data && param.data.phone && param.data.phone}
                    </span>
                  </Col>
                </Form.Group>

                {/* ອີເມວ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ອີເມວ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{param.data && param.data.email}</span>
                  </Col>
                </Form.Group>
              </div>

              {/* ---------- ໄອດີ ແລະ ລະຫັດຜ່ານ --------- */}
              <div style={{ marginBottom: 10 }}>
                <div>
                  <i
                    className="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
                  />
                  ໄອດີ ແລະ ລະຫັດຜ່ານ
                </div>
                {/* ລະດັບຜູ້ໃຊ້ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ລະດັບຜູ້ໃຊ້
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{param.data && param.data.role}</span>
                  </Col>
                </Form.Group>

                {/* ໄອດີ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ໄອດີ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{param.data && param.data.userId}</span>
                  </Col>
                </Form.Group>

                {/* ລະຫັດຜ່ານ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ລະຫັດຜ່ານ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{param.data && param.data.password}</span>
                  </Col>
                </Form.Group>
              </div>

              {/* ---------- ອື່ນໆ --------- */}
              <div style={{ marginBottom: 10 }}>
                <div>
                  <i
                    className="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
                  />
                  ອື່ນໆ
                </div>
                {/* ລາຍລະອຽດ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ລາຍລະອຽດ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{param.data && param.data.description}</span>
                  </Col>
                </Form.Group>

                {/* ໝາຍເຫດ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ໝາຍເຫດ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{param.data && param.data.note}</span>
                  </Col>
                </Form.Group>
              </div>
            </div>
          </div>

          <div style={{ height: 20 }} />
          <div className="row">
            <div style={{ padding: 15 }} className="col">
              <Button
                onClick={_handleShowAddConfirmModalClose}
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  color: "#6f6f6f",
                  borderColor: Consts.SECONDARY_COLOR,
                }}
              >
                ຍົກເລີກ
              </Button>
            </div>
            <div style={{ padding: 15 }} className="col">
              <Button
                style={{
                  width: "100%",
                  backgroundColor: Consts.SECONDARY_COLOR,
                  color: "#fff",
                  borderColor: Consts.SECONDARY_COLOR,
                }}
                onClick={() => _confirmTeacherEdit()}
              >
                ຕົກລົງ
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TeacherEditConfirm;
