import React, { useEffect, useRef, useState } from 'react'
import { TableHeader } from "../../../common";
import { convertLetterGrade, toDayDash } from "../../../common/super";

export default function ComponentToPrintE({studentDatas, gradeDatas, gradeDatass, totalCourse, length, maxCount}) {
    const ref = useRef()
    let genders;
    let mapCount = 20 - parseInt(length);
    let newMaxCount = 20 - parseInt(maxCount);
    const [totalScore, setTotalScore] = useState()

    useEffect(() => {
        if (studentDatas?.gender === "MALE") {
            genders = "Mr"
        } else if (studentDatas?.gender === "FEMALE") {
            genders = "Miss"
        } else if (studentDatas?.gender === "MONK") {
            genders = "Monk"
        } else {
            genders = "Other"
        }
    }, [])

    useEffect(() => {
        let scoreArr = []
        if(gradeDatas) {
            for (let i = 0; i < gradeDatas.length; i++) {
                scoreArr.push(gradeDatas[i]?.numbericGrade)
            }
        }
        if(gradeDatass) {
            for (let i = 0; i < gradeDatass.length; i++) {
                scoreArr.push(gradeDatass[i]?.numbericGrade)
            }
        }

        const totalScore = scoreArr.reduce(function(acc, val) { return acc + val; }, 0)
        setTotalScore(totalScore / totalCourse)
    }, [gradeDatas, gradeDatass, totalCourse])

  return (
    <div ref={ref}><br />
        <div className="center">
            <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Emblem_of_Laos.svg/1200px-Emblem_of_Laos.svg.png'} className="imgSize" alt="" />
        </div>
        <p className="center">Lao People's Democratic Republic<br />
        Peace Independence Democracy Unity Properity</p>
        <table className="table-score">
            <tr style={{ borderStyle: "hidden" }}>
                <td style={{ borderStyle: "hidden" }}>University of Laos<br />
                Faculty of {studentDatas?.faculty?.enName ? studentDatas?.faculty?.enName : studentDatas?.faculty?.name}</td>
                <td style={{ borderStyle: "hidden", textAlign: "right" }}>Ref.no:................................/.........<br />
                Vientiane Capital, Date:...........................</td>
            </tr>
            <tr>
                <td colSpan="2" style={{ textAlign: "center", fontSize: "26px", fontWeight: "bold" }}>
                    <p>OFFICIAL TRANSCRIPT OF ACADEMIC RECORDS</p></td>
            </tr>
            <tr style={{ borderStyle: "hidden" }}>
                <td style={{ borderStyle: "hidden" }}>
                    Program of Study: Bachelor of {studentDatas?.department?.enName ? studentDatas?.department?.enName : studentDatas?.department?.name}<br />
                    Name and SurName: {genders}{""} {studentDatas?.firstNameE}{" "}
                    {studentDatas?.lastNameE}</td>
                <td style={{ borderStyle: "hidden", textAlign: "right" }}>DATE: {toDayDash()}<br />
                Student ID: {studentDatas?.userId}</td>
            </tr>
        </table>
        <br />
        <div style={{ width: "100%" }}>
            <div style={{ float: "left", width: "50%", marginRight: -1 }}>
                <table style={{ width: "100%", marginRight: -1 }}>
                    <thead>
                        <TableHeader>
                            {/* <tr style={{ backgroundColor: "#057CAE", paddingTop:10, paddingBottom:10}}> */}
                            <th>Course</th>
                            <th>Course Name</th>
                            <th className="center">Credit</th>
                            <th className="center">Grade</th>
                            {/* </tr> */}
                        </TableHeader>
                    </thead>
                    <tbody>
                        {gradeDatas?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="center">{item?.course?.courseCode}</td>
                                    <td>{item?.course?.enTitle}</td>
                                    <td className="center">{item?.course?.unit}</td>
                                    <td className="center">{item?.letterGrade}</td>
                                </tr>
                            );
                        })}
                        {
                           Array.from(Array(mapCount), ((e, i) =>{
                                return(
                                <tr>
                                    <td className="center">-</td>
                                    <td></td>
                                    <td className="center"></td>
                                    <td className="center"></td>
                                </tr> 
                                );
                            })
                           )}
                        <tr>
                            <td rowSpan="2">ReMark</td>
                            <td colSpan="3" className="center">-</td>
                        </tr>
                        <tr>
                        <td colSpan="3" className="center">-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ float: "right", width: "50%", marginLeft: -1 }}>
                <table style={{ width: "100%", marginLeft: -1 }}>
                    <thead>
                        <TableHeader>
                            {/* <tr style={{ backgroundColor: "#057CAE" }}> */}
                            <th>Course</th>
                            <th>Course Name</th>
                            <th className="center">Credit</th>
                            <th className="center">Grade</th>
                            {/* </tr> */}
                        </TableHeader>
                    </thead>
                    <tbody>
                        {gradeDatass?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className="center">{item?.course?.courseCode}</td>
                                    <td>{item?.course?.enTitle}</td>
                                    <td className="center">{item?.course?.unit}</td>
                                    <td className="center">{item?.letterGrade}</td>
                                </tr>
                            );
                        })}
                        {
                           Array.from(Array(newMaxCount), ((e, i) =>{
                                return(
                                <tr>
                                    <td className="center">-</td>
                                    <td></td>
                                    <td className="center"></td>
                                    <td className="center"></td>
                                </tr> 
                                );
                            })
                           )}
                        <tr>
                            <td colSpan="2">Total of Credit</td>
                            <td colSpan="2">{totalScore ? totalScore.toFixed(2) : ""}</td>
                        </tr>
                        <tr>
                            <td colSpan="2">CGPA</td>
                            <td colSpan="2">{totalScore ? convertLetterGrade(totalScore) : ""}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        {/* Asign */}
        <br /><br />
        <table className="table-score">
            <tr>
                <td className="center" style={{ borderStyle: "hidden"}}></td>
                <td className="center" style={{ borderStyle: "hidden", width:"50%"}}>
                A(Excellent)=4; B+(Very Good)=3.5; B(Good)=3; C+(Fairly Good)=2.5;<br />
                C(Fair)=2; D+(Poor)=1.5; D(Very Poor)=1; F(Fall)=0.</td>
                <td className="center" style={{ borderStyle: "hidden"}}></td>
            </tr>
        </table><br />
        <table className="table-score">
            <tr>
                <td className="center" style={{ borderStyle: "hidden", paddingRight: 200 }}>DEAN</td>
                <td style={{ textAlign: "center", borderStyle: "hidden" }}>Head of Department</td>
            </tr>
        </table>
    </div>
  )
}
