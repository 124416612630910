import { gql } from "apollo-boost";

export const PRE_SIGNED_URL = gql`
  query PreSignedUrl($mimeType: String, $fileName: String) {
    preSignedUrl(mimeType: $mimeType, fileName: $fileName) {
      url
    }
  }
`;
export const PRE_SIGNED_MANY_URL = gql`
  query PreSignedManyUrl($data: [getManyPresign]) {
    preSignedManyUrl(data: $data) {
      url
    }
  }
`;

export const FILE_BY_KEYWORD = gql`
  query FileByKeyword(
    $keyword: String
    $createdName: String
    $startDate: String
    $endDate: String
  ) {
    fileByKeyword(
      keyword: $keyword
      createdName: $createdName
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      title
      description
      file
      keyword
      type
      cate
      createdAt
      updatedAt
      createdBy {
        role
        firstname
        lastname
      }
      updatedBy {
        role
        firstname
        lastname
      }
    }
  }
`;

export const FILES = gql`
  query Files(
    $where: FileWhereInput
    $orderBy: FileOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    files_page(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        id
        title
        description
        file
        createdBy {
          role
          firstname
          lastname
        }
        updatedBy {
          role
          firstname
          lastname
        }
        updatedAt
      }
      total
    }
  }
`;

//queryFile

export const QUERY_FILE = gql`
  query Files(
    $where: CustomFileWhereInput
    $orderBy: FileOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ){
    files(
      where: $where,
      orderBy: $orderBy,
      skip: $skip,
      after: $after,
      before: $before,
      first: $first,
      last: $last
    ){
      total
      data{
        id
        title
        description
        enName
        enDescription
        file
        type
        cate
        createdAt
        updatedAt
        createdBy{
          firstname
          lastname
        }
        updatedBy{
          firstname
          lastname
        }
      }
    }
  }
`;


export const FILE = gql`
  query File($where: FileWhereUniqueInput!) {
    file(where: $where) {
      id
      title
      description
      file
      keyword
      type
      cate
      createdBy {
        role
        firstname
        lastname
      }
      updatedBy {
        role
        firstname
        lastname
      }
      createdAt
      updatedAt
    }
  }
`;

export const CREATE_FILE = gql`
  mutation CreateFile($data: CustomFileCreateInput!) {
    createFile(data: $data) {
      id
    }
  }
`;

export const UPDATE_FILE = gql`
  mutation UpdateFile($data: CustomFileUpdateInput!, $where: FileWhereUniqueInput!) {
    updateFile(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($where: FileWhereUniqueInput!) {
    deleteFile(where: $where) {
      id
    }
  }
`;
