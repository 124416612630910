import React, { useState } from "react";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Consts, { USER_KEY } from "../../consts";
import Routers from "../../consts/router";
import { alertSuccess, messageWarning } from "../../common/super";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_STUDENT } from "./../../apollo/student";
import addLogs from "../../helpers/AddLogs";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/contrant";
function StudentEditConfirm({
  showAddConfirmModal,
  _handleShowAddConfirmModalClose,
  param,
  faculty,
  department,
  major,
  studentId,
  classroom,
}) {
  const { history } = useReactRouter();
  const [updateStudent] = useMutation(UPDATE_STUDENT);
  const [show, setShow] = useState(false);
  const _confirmStudentEdit = async () => {
    // _handleShowAddConfirmModalClose()
    try {
      param.where = {
        id: studentId,
      };
      delete param.data.currentCountry;
      delete param.data.currentProvince;
      delete param.data.currentDistrict;
      delete param.data.currentVillage;

      delete param.data.birthCountry;
      delete param.data.birthProvince;
      delete param.data.birthDistrict;
      delete param.data.birthVillage;
      delete param.data.day;
      delete param.data.month;
      delete param.data.year;
      if (!param.data.personalType) {
        delete param.data.personalType;
      }
      if (!param.data.email) {
        delete param.data.email;
      }
      if (!param.data.phoneMobile) {
        delete param.data.phoneMobile;
      }
      if (!param.data.phoneHome) {
        delete param.data.phoneHome;
      }
      if (!param.data.lastname) {
        delete param.data.lastname;
      }
      if (
        !param.data.birthday ||
        param.data.birthday === "undefined-undefined-undefined"
      ) {
        delete param.data.birthday;
      }
      if (!param.data.gender) {
        delete param.data.gender;
      }
      if (!param.data.maritualStatus) {
        delete param.data.maritualStatus;
      }
      if (!param.data.description) {
        delete param.data.description;
      }
      if (param.data.dateScholarshipAgreementNo === "Invalid date") {
        delete param.data.dateScholarshipAgreementNo;
      }
      if (param.data.dateTransferLetterNo === "Invalid date") {
        delete param.data.dateTransferLetterNo;
      }
      if (!param.data.classRoom) {
        delete param.data.classRoom;
      }
      if (!param.data.password) {
        delete param.data.password;
      }
      delete param.data.confirmPassword;
      // delete param.data.userId;
      const updateData = await updateStudent({ variables: param });
      if (updateData) {
        await setShow(false);
        await addLogs({
          action: "UPDATE",
          data: param,
          student: studentId,
          status: "SUCCESS",
          type: "STUDENT",
        });
        const user = localStorage.getItem(USER_KEY);
        const userObject = JSON.parse(user);
        const token = userObject?.accessToken;

        await axios.put(NEW_API_URL + "prestudent", param, {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        });

        await alertSuccess("ແກ້ໄຂຂໍ້ມູນສຳເລັດ");

        setTimeout(() => {
          history.push(Routers.STUDENT_LIST + Routers.PAGE_GINATION);
          window.location.reload();
        }, 1500);
      }
    } catch (err) {
      if (
        err?.message == "GraphQL error: USER_WITH_THIS_USERID_IS_ALREADY_EXIST"
      )
        messageWarning("ໄອດີນີ້ມີຢູ່ແລ້ວ ກະລຸນາປ້ອນໄອດີໃໝ່!");
    }
  };

  const _onConvertGenter = (gender) => {
    let result;
    switch (gender) {
      case "MALE":
        result = "ຊາຍ";
        break;
      case "FEMALE":
        result = "ຍິງ";
        break;
      case "OTHER":
        result = "ອື່ນໆ";
        break;
      default:
        result = "ຊາຍ";
    }
    return result;
  };

  const _onConvertMaritualStatus = (maritualStatus) => {
    let result;
    switch (maritualStatus) {
      case "SINGLE":
        result = "ໂສດ";
        break;
      case "MARRIAGE":
        result = "ແຕ່ງງານແລ້ວ";
        break;
      default:
        result = "ໂສດ";
    }
    return result;
  };

  return (
    <div>
      <Modal
        show={showAddConfirmModal}
        onHide={_handleShowAddConfirmModalClose}
        size="lg"
      >
        <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
          ຢືນຢັນການແກ້ໄຂນັກຮຽນ
        </Modal.Title>
        <Modal.Body
          style={{
            marginLeft: 50,
            marginRight: 5,
            color: Consts.SECONDARY_COLOR0,
            padding: 50,
            paddingTop: 0,
          }}
        >
          <div>
            <div
              style={{
                backgroundColor: "#fff",
                padding: 10,
                marginTop: 20,
              }}
            >
              {/* Form container */}
              <div
                style={{
                  width: "80%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {/* ---------- ພາກວິຊາແລະພາກວິຊາ --------- */}
                <div style={{ marginBottom: 10 }}>
                  <div>
                    <i
                      className="fa fa-caret-down"
                      aria-hidden="true"
                      style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
                    />
                    ຄະນະແລະພາກວິຊາ
                  </div>
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ຄະນະ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>{faculty ? faculty : "-"}</span>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ພາກວິຊາ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>{department ? department : "-"}</span>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ສາຂາວິຊາ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>{major ? major : "-"}</span>
                    </Col>
                  </Form.Group>

                  {/* ປີຮຽນ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ປີຮຽນ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>
                        {param.data &&
                          (param.data.yearLevel ? param.data.yearLevel : "-")}
                      </span>
                    </Col>
                  </Form.Group>

                  {/* ຫ້ອງຮຽນ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ຫ້ອງຮຽນ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>{param.data && (classroom ? classroom : "-")}</span>
                    </Col>
                  </Form.Group>
                </div>

                {/* ---------- ຂໍ້ມູນນັກຮຽນ --------- */}
                <div style={{ marginBottom: 10 }}>
                  <div>
                    <i
                      className="fa fa-caret-down"
                      aria-hidden="true"
                      style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
                    />
                    ຂໍ້ມູນນັກຮຽນ
                  </div>
                  {/* ຊື່ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ຊື່
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>
                        {param.data &&
                          (param.data.firstNameL
                            ? param.data.firstNameL
                            : "-")}{" "}
                      </span>
                    </Col>
                  </Form.Group>

                  {/* ນາມສະກຸນ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ນາມສະກຸນ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>
                        {param.data &&
                          (param.data.lastNameL
                            ? param.data.lastNameL
                            : "-")}{" "}
                      </span>
                    </Col>
                  </Form.Group>

                  {/* ວັນເດືອນປີເກີດ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ວັນເດືອນປີເກີດ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>{param.data?.birthday ?? "-"}</span>
                    </Col>
                  </Form.Group>

                  {/* ເພດ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ເພດ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>
                        {param.data &&
                          (param.data.gender
                            ? _onConvertGenter(param.data.gender)
                            : "-")}
                      </span>
                    </Col>
                  </Form.Group>

                  {/* ສະຖານະ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ສະຖານະ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>
                        {param.data?.maritualStatus
                          ? _onConvertMaritualStatus(param.data.maritualStatus)
                          : "-"}
                      </span>
                    </Col>
                  </Form.Group>

                  {/* ເບີໂທ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ເບີໂທ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>
                        {param.data &&
                          (param.data.phoneMobile
                            ? param.data.phoneMobile
                            : "-")}
                      </span>
                    </Col>
                  </Form.Group>

                  {/* ອີເມວ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ອີເມວ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>
                        {param.data &&
                          (param.data.email ? param.data.email : "-")}
                      </span>
                    </Col>
                  </Form.Group>
                </div>

                {/* ---------- ໄອດີແລະລະຫັດຜ່ານ --------- */}
                <div style={{ marginBottom: 10 }}>
                  <div>
                    <i
                      className="fa fa-caret-down"
                      aria-hidden="true"
                      style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
                    />
                    ໄອດີແລະລະຫັດຜ່ານ
                  </div>
                  {/* ໄອດີ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ໄອດີ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>
                        {param.data &&
                          (param.data.userId ? param.data.userId : "-")}
                      </span>
                    </Col>
                  </Form.Group>

                  {/* ລະຫັດຜ່ານ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ລະຫັດຜ່ານ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>
                        {param.data &&
                          (param.data.password ? param.data.password : "-")}
                      </span>
                    </Col>
                  </Form.Group>
                </div>

                {/* ---------- ອື່ນໆ --------- */}
                <div style={{ marginBottom: 10 }}>
                  <div>
                    <i
                      className="fa fa-caret-down"
                      aria-hidden="true"
                      style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
                    />
                    ອື່ນໆ
                  </div>
                  {/* ລາຍລະອຽດ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ລາຍລະອຽດ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>
                        {param.data &&
                          (param.data.description
                            ? param.data.description
                            : "-")}
                      </span>
                    </Col>
                  </Form.Group>

                  {/* ໝາຍເຫດ */}
                  <Form.Group
                    as={Row}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      paddingLeft: 20,
                      fontSize: 16,
                    }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ໝາຍເຫດ
                    </Form.Label>
                    <Col sm="8" style={{ marginTop: 8 }}>
                      <span>
                        {param.data &&
                          (param.data.note ? param.data.note : "-")}
                      </span>
                    </Col>
                  </Form.Group>
                </div>
              </div>
            </div>

            <div style={{ height: 20 }} />
            <div className="row">
              <div style={{ padding: 15 }} className="col">
                <Button
                  onClick={_handleShowAddConfirmModalClose}
                  style={{
                    width: "100%",
                    backgroundColor: "#fff",
                    color: "#6f6f6f",
                    borderColor: Consts.SECONDARY_COLOR,
                  }}
                >
                  ຍົກເລີກ
                </Button>
              </div>
              <div style={{ padding: 15 }} className="col">
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: Consts.SECONDARY_COLOR,
                    color: "#fff",
                    borderColor: Consts.SECONDARY_COLOR,
                  }}
                  onClick={() => _confirmStudentEdit()}
                >
                  ບັນທຶກ
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Body>
          <Col
            className="text-center"
            style={{ paddingTop: 40, paddingBottom: 50 }}
          >
            <div
              className="textfont-size72 text-bold"
              style={{ color: "#00A1DE" }}
            >
              ສໍາເລັດ
            </div>
            <FontAwesomeIcon
              icon={faCheckCircle}
              size="9x"
              style={{ color: "#00A1DE" }}
            />
            <div
              className="textfont-size19 text-bold"
              style={{ marginTop: 40 }}
            >
              ປັນທືກເປັນທີ່ຮຽນຮ້ອຍ
            </div>
          </Col>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default StudentEditConfirm;
