import { gql } from "apollo-boost";

export const UPDATE_LOGS = gql`
  query UpdateLogs (
      $where: UpdateLogWhereInput
      $orderBy: UpdateLogOrderByInput
      $skip: Int
      $after: String
      $before: String
      $first: Int
      $last: Int) {
    updateLogs(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last) {
      total
      data {
        id
        relatedId
        relatedSchema
        logMethod
        note
        diffData
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`;

export const UPDATE_LOG = gql`
  query UpdateLog($where: UpdateLogWhereUniqueInput!) {
    updateLog(where: $where) {
      id
      relatedId
      relatedSchema
      logMethod
      note
      diffData
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
