import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import * as _ from "lodash";

/**
 *
 * @Component
 *
 */
import { Breadcrumb, Button, Form, Table, Accordion, Card } from "react-bootstrap";
import { useSelector } from 'react-redux'
import { CustomContainer, Title, TableHeader } from "../../../common";
import Loading from "../../../common/Loading";
import EditMajorTimeRegister from "./EditMajorTimeRegister";

/**
 *
 * @Constant
 *
 */
import Consts from "../../../consts";

/**
 *
 * @Apollo
 *
 */
import { MAJOR } from "../../../apollo/major";
import { COURSES, UPDATE_COURSE, UPDATE_MANY_COURSE_STATUS } from "../../../apollo/course";
import { REGIS_MAJOR_DATE_HISTORIES, REGIS_Major_DATE_HISTORIES } from "../../../apollo/regisMajorDateHistory";
/**
 *
 * @Function
 *
 */
import { formatDate, formatDateTime, dateTime, removeDuplicates } from "../../../common/super";
import { set } from "lodash";

export default function SettingMajorDetail() {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  // Route
  const { history, location, match } = useReactRouter();

  // varible
  const facultyId = match.params.facultyId;
  const majorId = match.params.majorId;
  // console.log("majorId::", location)

  // State
  const [showUploadView, setShowUploadView] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [courseSemesterOne, setCourseSemesterOne] = useState([]);
  const [courseSemesterTwo, setCourseSemesterTwo] = useState([]);
  const [courseArr, setCourseArr] = useState([]);
  const [checkedAllRegister, setCheckedAllRegister] = useState(false);
  const [dataRegisMajorDateHistory, SetDataRegisMajorDateHistory] = useState([]);
  const [selectYear, setSelectYear] = useState(1);

  // Apollo
  let [loadMajorData, { loading, data: MajorData }] = useLazyQuery(MAJOR, { variables: { where: { id: majorId } } });
  let [loadRegisMajorDateHistoryData, { loading: regisMajorDateHistoryLoading, data: regisMajorDateHistoryData }] = useLazyQuery(REGIS_MAJOR_DATE_HISTORIES, {
    variables: {
      where: { major: majorId },
      orderBy: "createdAt_DESC",
    },
  });

  let [loadCourse, { loading: loadingCourse, data: dataCourse }] = useLazyQuery(COURSES);

  const [updateCourse] = useMutation(UPDATE_COURSE);
  const [updateManyCourseStatus] = useMutation(UPDATE_MANY_COURSE_STATUS);

  // use
  useEffect(() => {
    loadCourse({
      variables: {
        where: {
          faculty: facultyId,
          major: majorId,
          year: parseInt(selectYear),
          isDeleted: false,
          assessmentYear: accessmentRedux
        },
      },
    });
    loadMajorData();
    loadRegisMajorDateHistoryData();
  }, [selectYear, accessmentRedux]);

  useEffect(() => {
    if (dataCourse) {
      setCourseData(removeDuplicates(dataCourse?.courses?.data))
    }
  }, [dataCourse]);
  
  useEffect(() => {
    if (courseData?.length > 0) {
      setCourseSemesterOne([]);
      setCourseSemesterTwo([]);
      let courseArr = []
      let checkedArr = []
      const groupByCategory = courseData.reduce((group, product) => {
        const { semester } = product;
        group[semester] = group[semester] ?? [];
        group[semester].push(product);
        return group;
      }, {});

      setCourseSemesterOne(groupByCategory?.[1]);
      setCourseSemesterTwo(groupByCategory?.[2]);

      for (let i = 0; i < courseData.length; i++) {
        courseArr.push(courseData[i].id)
        checkedArr.push(courseData[i].isRegistration)
      }
      setCourseArr(courseArr);

      const checkedAll = checkedArr.every(obj => obj === true);
      if (checkedAll === true) {
        setCheckedAllRegister(true)
      } else {
        setCheckedAllRegister(false)
      }

    } else {
      setCourseSemesterOne([]);
      setCourseSemesterTwo([]);
    }
  }, [courseData])


  useEffect(() => {
    SetDataRegisMajorDateHistory(regisMajorDateHistoryData?.regisMajorDateHistories);
  }, [regisMajorDateHistoryData]);

  const _onChangeSwitchAll = async (courseArr) => {
    await updateManyCourseStatus({
      variables: {
        data: {
          isRegistration: checkedAllRegister === false ? true : false,
        },
        where: { id: courseArr },
      },
    });
    setCheckedAllRegister(checkedAllRegister === false ? true : false)
    history.go(0)
  };

  const _onChangeSwitchOne = async (id, isRegistration, index) => {
    await updateCourse({
      variables: {
        data: {
          isRegistration: isRegistration === true ? false : true,
        },
        where: { id },
      },
    });

    let _newData = [...courseSemesterOne];
    _newData[index].isRegistration = !_newData[index].isRegistration;
    setCourseSemesterOne(_newData);
  };

  const _onChangeSwitchTwo = async (id, isRegistration, index) => {
    await updateCourse({
      variables: {
        data: {
          isRegistration: isRegistration === true ? false : true,
        },
        where: { id },
      },
    });

    let _newData = [...courseSemesterTwo];
    _newData[index].isRegistration = !_newData[index].isRegistration;
    setCourseSemesterTwo(_newData);
  };

  const _onChangeSwitchUpgradeOne = async (id, isUpgrade, index) => {
    await updateCourse({
      variables: {
        data: {
          isUpgrade: isUpgrade === true ? false : true,
        },
        where: {
          id: id,
        },
      },
    });

    let _newData = [...courseSemesterOne];
    _newData[index].isUpgrade = !_newData[index].isUpgrade;

    setCourseSemesterOne(_newData);
  };

  const _onChangeSwitchUpgradeTwo = async (id, isUpgrade, index) => {
    await updateCourse({
      variables: {
        data: {
          isUpgrade: isUpgrade === true ? false : true,
        },
        where: {
          id: id,
        },
      },
    });

    let _newData = [...courseSemesterTwo];
    _newData[index].isUpgrade = !_newData[index].isUpgrade;

    setCourseSemesterTwo(_newData);
  };

  // Function
  const _handleUploadViewClose = () => setShowUploadView(false);
  const _handleUploadViewShow = () => setShowUploadView(true);
  const _backToFaculty = () => history.push("/setting-faculty-detail/" + facultyId);

  // Loadding
  if (loading || regisMajorDateHistoryLoading || loadingCourse) return <Loading />;

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ເລືອກການຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push("/setting-faculty")}
        >
          ການລົງທະບຽນ
        </Breadcrumb.Item>
        <Breadcrumb.Item href="" onClick={() => _backToFaculty()}>
          {location?.state?.faculty}
        </Breadcrumb.Item>
        <Breadcrumb.Item href="" onClick={() => _backToFaculty()}>
          {location?.state?.department}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{location?.state?.major}</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        {/* title */}
        <div className="d-flex justify-content-between">
          <Title text={'ສາຂາວິຊາ ' + MajorData?.major?.name} />
          <span style={{ marginLeft: "60%", marginTop: 8, fontSize: 22 }}>ລົງທະບຽນທຸກຊັ້ນຮຽນ</span>
          <Form.Check
            style={{ marginTop: 8 }}
            type="switch"
            // className="custom-control custom-checkbox checkbox-lg"
            id={`custom-switch`}
            checked={checkedAllRegister}
            label=""
            onChange={() => _onChangeSwitchAll(courseArr)
            }
          />
          <span style={{ marginTop: 8, fontSize: 22 }}>ເປີດ</span>
        </div>
        <div className="btn-group" role="group" aria-label="Basic example">
          {[1, 2, 3, 4, 5, 6]?.map((year, index) => (
            <button key={index} type="button" className="btnStyle m-1"
              onClick={() => setSelectYear(year)}
              style={{
                backgroundColor: selectYear === year ? "#057cae" : "",
                color: selectYear === year ? "#fff" : ""
              }}
            >
              ປີ {year}
            </button>
          ))}
        </div>
      </CustomContainer>
      <CustomContainer>
        <div style={{width: "100%"}}>
          <h4 style={{ fontWeight: "bold" }}>ເທີມ 1</h4>
          <h6>ວິຊາທັງໝົດ ({courseSemesterOne ? courseSemesterOne?.length : 0})</h6>

          <table
            className="table-hoer"
            bordercolor="#fff"
            style={{ width: "100%" }}
          >
            <thead>
              <TableHeader>
                <th>ລຳດັບ</th>
                <th width="20%">ຊື່ວິຊາ(ລາວ)</th>
                <th width="20%">ຊື່ວິຊາ(ອັງກິດ)</th>
                <th>ໜ່ວຍກິດ</th>
                <th width="20%">ຊື່ອາຈານສອນ</th>
                <th width="15%">ເປີດລົງທະບຽນຮຽນ</th>
                <th width="15%">ເປີດລົງທະບຽນອັບເກຣດ</th>
              </TableHeader>
            </thead>
            <tbody>
              {courseSemesterOne && courseSemesterOne?.length > 0 &&
                courseSemesterOne?.map((course, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{course?.title}</td>
                    <td>{course?.enTitle}</td>
                    <td>{course?.unit}</td>
                    <td>{course?.teacher.map((teacher, index) => (
                      teacher.firstname + " " + teacher.lastname
                    ))}
                    </td>
                    <td className="center">
                      <Form.Check
                        type="switch"
                        id={`custom-switch ${index}`}
                        checked={course?.isRegistration}
                        label=""
                        onChange={() =>
                          _onChangeSwitchOne(
                            course?.id,
                            course?.isRegistration,
                            index
                          )
                        }
                      />
                    </td>

                    <td className="center">
                      <Form.Check
                        type="switch"
                        id={`custom-switch ${course?.id}`}
                        checked={course?.isUpgrade}
                        label=""
                        onChange={() =>
                          _onChangeSwitchUpgradeOne(
                            course?.id,
                            course?.isUpgrade,
                            index
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {courseSemesterOne?.length === 0 || courseSemesterOne === undefined ? 
            <div
              style={{
                width: "100%",
                minHeight: 100,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                alignItems: "center",
                color: "#BFBFBF",
                fontSize: 32,
                fontWeight: "bold",
              }}
            >
              <p>ຍັງບໍ່ມິວິຊາ</p>
            </div>
            : <></>
          }
        </div>
        <div
          style={{
            width: "100%",
            marginTop: 16
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <h4 style={{ fontWeight: "bold" }}>ເທີມ 2</h4>
          <h6>ວິຊາທັງໝົດ ({courseSemesterTwo ? courseSemesterTwo?.length : 0})</h6>

          <table
            className="table-hoer"
            bordercolor="#fff"
            style={{ width: "100%" }}
          >
            <thead>
              <TableHeader>
                <th>ລຳດັບ</th>
                <th width="20%">ຊື່ວິຊາ(ລາວ)</th>
                <th width="20%">ຊື່ວິຊາ(ອັງກິດ)</th>
                <th>ໜ່ວຍກິດ</th>
                <th width="20%">ຊື່ອາຈານສອນ</th>
                <th width="15%">ເປີດລົງທະບຽນຮຽນ</th>
                <th width="15%">ເປີດລົງທະບຽນອັບເກຣດ</th>
              </TableHeader>
            </thead>
            <tbody>
              {courseSemesterTwo && courseSemesterTwo?.length > 0 &&
                courseSemesterTwo?.map((course, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{course?.title}</td>
                    <td>{course?.enTitle}</td>
                    <td>{course?.unit}</td>
                    <td>{course?.teacher.map((teacher, index) => (
                      teacher.firstname + " " + teacher.lastname
                    ))}
                    </td>
                    <td className="center">
                      <Form.Check
                        type="switch"
                        id={`custom-switch2 ${index}`}
                        checked={course?.isRegistration}
                        label=""
                        onChange={() =>
                          _onChangeSwitchTwo(
                            course?.id,
                            course?.isRegistration,
                            index
                          )
                        }
                      />
                    </td>

                    <td className="center">
                      <Form.Check
                        type="switch"
                        id={`custom-switch2 ${course?.id}`}
                        checked={course?.isUpgrade}
                        label=""
                        onChange={() =>
                          _onChangeSwitchUpgradeTwo(
                            course?.id,
                            course?.isUpgrade,
                            index
                          )
                        }
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {courseSemesterTwo?.length === 0 || courseSemesterTwo === undefined ? 
            <div
              style={{
                width: "100%",
                minHeight: 100,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                alignItems: "center",
                color: "#BFBFBF",
                fontSize: 32,
                fontWeight: "bold",
              }}
            >
              <p>ຍັງບໍ່ມິວິຊາ</p>
            </div>
            : <></>
          }
        </div>
      </CustomContainer>

      <EditMajorTimeRegister
        data={MajorData?.major ?? ""}
        showUploadView={showUploadView}
        _handleUploadViewClose={_handleUploadViewClose}
        loadMajorData={loadMajorData}
      />
    </div>
  );
}