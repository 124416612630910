import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import * as _ from "lodash";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

/**
 *
 * @Component
 *
 */
import { CustomContainer } from "../../common";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import { useSelector } from 'react-redux'
import Loading from "../../common/Loading";
import Empty from "../../common/Empty";

/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";

/**
 *
 * @Apollo
 *
 */
import {
  STATISTICES_ALL,
  STATISTICES,
} from "../../apollo/registration/registerStudent";
import { FACULTY } from "../../common/facultyConstant";

/**
 *
 * @Function
 *
 */
import { toDayDash, currency } from "../../common/super";

export default function RegistrationStudentStatistic({ stdIdFilter }) {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  var stdId = moment(stdIdFilter).format("YY");
  /**
   *
   * @State
   *
   */
  const [isAdmin, setIsAdmin] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [facSqlId, setFacSqlId] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [userName, setUserName] = useState(false);
  const [facName, setFacName] = useState(false);
  /**
   *
   * @Apollo
   *
   */
  const [loadStatisticData, { data: statisticData }] =
    useLazyQuery(STATISTICES_ALL);

  const [
    loadDepartmentstatisticData,
    { loading: depLoading, data: departmentData },
  ] = useLazyQuery(STATISTICES);
  /**
   *
   * @UseEffect
   *
   */
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      if (user.data.role === "ADMIN") {
        let facSqlIndex = _.findIndex(FACULTY, {
          id: user?.data?.faculty?.id,
        });
        setFacSqlId("ALL_FACUTY");
        setIsAdmin(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        setUserName(
          user.data.firstname
            ? user.data.firstname
            : " " + " " + user.data.lastname
            ? user.data.lastname
            : ""
        );
        setFacName(FACULTY[facSqlIndex]?.name);
      } else {
        let facSqlIndex = _.findIndex(FACULTY, {
          id: user?.data?.faculty?.id,
        });
        setUserName(user?.data?.firstname + " " + user?.data?.lastname);
        setFacSqlId(FACULTY[facSqlIndex]?.vId);
        setFacName(FACULTY[facSqlIndex]?.name);
        setIsAdmin(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
    }
  }, []);

  useEffect(() => {
    if (facSqlId)
      if (facSqlId === "ALL_FACUTY") {
        let qlWhere = {};
        if (stdIdFilter) qlWhere = { ...qlWhere, stdid: parseInt(stdId) };
        loadStatisticData({
          variables: { where: qlWhere },
        });
        loadDepartmentstatisticData({
          variables: { where: qlWhere },
        });
      } else {
        let qlWhere = {};
        if (facSqlId) qlWhere = { ...qlWhere, fac_id: parseInt(facSqlId) };
        if (stdId) qlWhere = { ...qlWhere, stdid: parseInt(stdId) };
        loadStatisticData({
          variables: { where: qlWhere },
        });

        loadDepartmentstatisticData({
          variables: { where: qlWhere },
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facSqlId, stdIdFilter]);

  useEffect(() => {
    let _newData = departmentData?.statistics
      ?.filter((item, xindex) => {
        if (!isAdmin) return true;
        else {
          if (xindex >= 14) return false;
          else return true;
        }
      })
      .map((item, index) => {
        return {
          name: item?.major,
          ລົງທະບຽນແລ້ວ: item?.success,
          ຍັງບໍ່ລົງທະບຽນ: parseInt(item?.total - item?.success),
        };
      });
    setGraphData(_newData);
  }, [departmentData]);
  /**
   *
   * @Function
   *
   */
  const _handleShowConfirmModalClose = () => setShowConfirmModal(false);
  const _handleShowConfirmModalShow = () => setShowConfirmModal(true);
  // get faculty id
  const _selectFaculty = (e) => {
    setFacSqlId(e.target.value);
  };

  return (
    <div>
      {depLoading && <Loading />}

      <CustomContainer style={{ marginTop: 0 }}>
        <Row>
          <Col sm={2}>
            <p>ຄະນະວິຊາ</p>
          </Col>
          <Col sm={6}>
            {facSqlId && (
              <Form.Control
                disabled={!isAdmin}
                as="select"
                style={{ border: "none", backgroundColor: "#f1f1f1f1" }}
                onChange={(e) => _selectFaculty(e)}
                defaultValue={facSqlId}
              >
                <option value="ALL_FACUTY">ຄະນະທັງໝົດ </option>
                {FACULTY?.map((item, index) => {
                  return (
                    <option key={"faculty" + index} value={item.vId}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Control>
            )}
          </Col>
          <Col sm={3}>
            <button
              className="btn btn-outline-success"
              onClick={() => _handleShowConfirmModalShow()}
            >
              <i className="fa fa-file-excel"></i> Export Excel
            </button>
          </Col>
        </Row>
        {departmentData?.statistics?.length > 0 ? (
          <div>
            <div
              style={{
                marginTop: 24,
                marginBottom: 8,
                fontSize: 16,
                color: Consts.FONT_COLOR_SECONDARY,
              }}
            >
              <Row style={{ paddingLeft: "10%", paddingLeft: "10%" }}>
                <BarChart
                  width={900}
                  height={300}
                  data={graphData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 20,
                  }}
                >
                  <XAxis dataKey="name" hide={true} />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey={"ລົງທະບຽນແລ້ວ"} stackId="b" fill="#47A6E5" />
                  <Bar dataKey="ຍັງບໍ່ລົງທະບຽນ" stackId="b" fill="#057CAE" />
                </BarChart>
              </Row>
            </div>
            {/* Table list */}
            <div>
              <Row>
                <div className="container" style={{ textAlign: "center" }}>
                  <div className="listItem" style={{ textAlign: "center" }}>
                    <b>
                      {statisticData &&
                      currency(
                        statisticData?.statisticsAll[0]?.total + "\n"
                      ) !== 0
                        ? currency(
                            statisticData?.statisticsAll[0]?.total + "\n"
                          )
                        : 0}
                      <hr /> ສະຖິຕິທັງໝົດ
                    </b>
                  </div>
                  <div className="listItem" style={{ textAlign: "center" }}>
                    <b>
                      {statisticData &&
                      currency(statisticData?.statisticsAll[0]?.success) !== 0
                        ? currency(statisticData?.statisticsAll[0]?.success)
                        : 0}
                      <hr /> ລົງທະບຽນສຳເລັດ
                    </b>
                  </div>
                  <div className="listItem" style={{ textAlign: "center" }}>
                    <b>
                      {statisticData &&
                      currency(statisticData?.statisticsAll[0]?.noSuccess) !== 0
                        ? currency(statisticData?.statisticsAll[0]?.noSuccess)
                        : 0}
                      <hr />
                      ລົງທະບຽນບໍ່ສຳເລັດ
                    </b>
                  </div>
                </div>
              </Row>
              {/* {(loading || called) && ""} */}
              {/* <Row>
                <Col sm={3}>
                  ສະແດງ {departmentData?.statistics.length} ໃນ{" "}
                  {departmentData?.statistics.length}
                </Col>
              </Row> */}
              <table className="table table-hover" style={{ width: "100%" }}>
                <tr
                  style={{
                    textAlign: "center",
                    backgroundColor: Consts.PRIMARY_COLOR,
                  }}
                >
                  <th style={{ width: 70 }}>ລຳດັບ</th>
                  <th>ສາຂາວິຊາ</th>
                  <th>ສະຖິຕິທັງໝົດ</th>
                  <th>ລົງທະບຽນສຳເລັດ</th>
                </tr>
                <tbody>
                  {departmentData?.statistics?.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                      >
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "left" }}>{item?.major}</td>
                        <td>{item?.total}</td>
                        <td>{item?.success}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <Empty />
        )}

        <Modal
          center
          show={showConfirmModal}
          onHide={_handleShowConfirmModalClose}
          size="lg"
        >
          <Modal.Header style={{ textAlign: "center", paddingTop: 20 }}>
            {"ລາຍງານຂໍ້ມູນສະຖິຕິ" +
              " " +
              " ສົກຮຽນ " +
              " " +
              (stdIdFilter - 1) +
              "-" +
              stdIdFilter}
            <div style={{ height: 20 }} />
            <div className="row" style={{ padding: 20 }}>
              <Button
                className="btn"
                variant="outline-dark"
                onClick={_handleShowConfirmModalClose}
              >
                ຍົກເລີກ
              </Button>
              &nbsp;&nbsp;
              <ReactHTMLTableToExcel
                style={{ backgroundColor: "none", border: "1px solid red" }}
                className="btn btn-outline-success"
                table="ExportData"
                filename={
                  "ລາຍງານຂໍ້ມູນສະຖິຕິ" +
                  " " +
                  (stdIdFilter - 1) +
                  "-" +
                  stdIdFilter +
                  " " +
                  toDayDash()
                }
                sheet="Sheet"
                buttonText="Export excel"
                font={"NotoSansLao"}
                headerPaddingCellOptions={{ background: "#ff0000" }}
              />
            </div>
          </Modal.Header>

          <Modal.Body>
            <div>
              <table
                className="table"
                id="ExportData"
                style={{
                  fontSize: 12,
                  fontFamily: "NotoSansLao",
                }}
              >
                <thead>
                  <tr>
                    <td colSpan={4}>
                      {facName}&nbsp;ສົກຮຽນ{" "}
                      {stdIdFilter - 1 + "-" + stdIdFilter}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ width: 70 }}>ລຳດັບ</th>
                    <th>ສາຂາວິຊາ</th>
                    <th>ສະຖິຕິທັງໝົດ</th>
                    <th>ລົງທະບຽນສຳເລັດ</th>
                  </tr>
                </thead>
                <tbody>
                  {departmentData?.statistics?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td style={{ textAlign: "left" }}> {item?.major}</td>
                        <td style={{ textAlign: "right" }}> {item?.total}</td>
                        <td style={{ textAlign: "right" }}>{item?.success}</td>
                      </tr>
                    );
                  })}
                  <tr style={{ width: "100%" }}>
                    <td colSpan={3}>ສະຖິຕິ ທັງໝົດ</td>
                    <td style={{ textAlign: "right" }}>
                      {statisticData?.statisticsAll[0]?.total
                        ? currency(statisticData?.statisticsAll[0]?.total)
                        : 0}{" "}
                      ຄົນ
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>ລົງທະບຽນແລ້ວ</td>
                    <td style={{ textAlign: "right" }}>
                      {statisticData?.statisticsAll[0]?.success
                        ? currency(statisticData?.statisticsAll[0]?.success)
                        : 0}{" "}
                      ຄົນ
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>ຍັງບໍ່ທັນລົງທະບຽນ</td>
                    <td style={{ textAlign: "right" }}>
                      {statisticData?.statisticsAll[0]?.noSuccess
                        ? currency(statisticData?.statisticsAll[0]?.noSuccess)
                        : 0}{" "}
                      ຄົນ
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>ວັນທີ</td>
                    <td style={{ textAlign: "right" }}>{toDayDash()}</td>
                  </tr>
                  <tr>
                    <td colSpan={3}>ໂດຍ</td>
                    <td style={{ textAlign: "right" }}>{userName}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div style={{ height: 20 }} />
            <div className="row" style={{ padding: 20 }}>
              <Button
                className="btn"
                variant="outline-dark"
                onClick={_handleShowConfirmModalClose}
              >
                ຍົກເລີກ
              </Button>
              &nbsp;&nbsp;
              <ReactHTMLTableToExcel
                style={{ backgroundColor: "none", border: "1px solid red" }}
                className="btn btn-outline-success"
                table="ExportData"
                filename={
                  "ລາຍງານຂໍ້ມູນສະຖິຕິ" +
                  " " +
                  (stdIdFilter - 1) +
                  "-" +
                  stdIdFilter +
                  " " +
                  toDayDash()
                }
                sheet="Sheet"
                buttonText="Export excel"
                font={"NotoSansLao"}
                headerPaddingCellOptions={{ background: "#ff0000" }}
              />
            </div>
          </Modal.Footer>
        </Modal>
      </CustomContainer>
    </div>
  );
}
