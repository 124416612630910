import React, { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import useReactRouter from "use-react-router";

/**
 *
 * @Library
 *
 */
 import { useSelector } from 'react-redux'
/**
 *
 * @Component
 *
 */
import { Button, Modal, Breadcrumb, Row, Col, Form } from "react-bootstrap";
import { CustomContainer, Title } from "../../../common";
import Loading from "../../../common/Loading";
import GradeNavCourseDetail from "../common/GradeNavCourseDetail";

/**
 *
 * @Constant
 *
 */
/**
 *
 * @Apollo
 *
 */
import { UPDATELOGS, COURSES, SEND_GRADE_COURSE, COURSE } from "../../../apollo/grade";

/**
 *
 * @Function
 *
 */
import { getUserDataFromLCStorage } from "../../../helpers/user";
import "../util/index.css";
import { Translater } from "../../../common/super";
import HelpModal from "../common/HelpModal";
import { FACULTIES } from "../../../apollo/faculty";
import { DEPARTMENTS } from "../../../apollo/deparment";
import { CLASSROOM_MASTERS } from "../../../apollo/classRoom";
import { MAJORS } from "../../../apollo/major";
import { SENDING_GRADES_STATUS } from "../../studentDetail/apollo/query";
import _ from "lodash";

function HistoryReceiveGrade() {
  const { history, location, match } = useReactRouter();
  const courseID = location?.state?.courseId;
  const accessmentRedux = useSelector((state) => state.accessment.value)

  const pageNambers = match?.params?.page;
  const PAGE_LIMIT = 50;

  const [role, setRole] = useState("")
  const [dataUser, setDataUser] = useState({});
  const [showRejected, setShowRejected] = useState(false);
  const [showSubmited, setShowSubmited] = useState(false);
  const [showChecking, setShowChecking] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [isShowHelp, setIsShowHelp] = useState(false);
  const [gradeStatus, setGradeStatus] = useState("ADMIN_APPROVED");
  const [adminApprove, setAdminApproved] = useState([]);
  const [emptyValues, setEmptyValues] = useState({})

  const [sendingStatus, setSendingStatus] = useState("")
  const [dataFaculties, setDataFaculties] = useState([]);
  const [facultyId, setFacultyId] = useState("");

  const [dataDepartments, setDataDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState("");

  const [dataMajors, setDataMajors] = useState([]);
  const [majorId, setMajorId] = useState("");
  const [dataClassRooms, setDataClassRooms] = useState([]);
  const [classroomId, setClassroomId] = useState("");
  const [year, setYear] = useState("");
  const [semester, setSemester] = useState("");

  const [loadSendingStatus, { data: sendingStatusData }] = useLazyQuery(SENDING_GRADES_STATUS, {fetchPolicy:"network-only"});
  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTIES);
  const [loadDepartments, { data: departmentData }] = useLazyQuery(DEPARTMENTS);
  const [loadMajor, { data: majorData }] = useLazyQuery(MAJORS);
  const [loadClassRooms, { data: classroomData }] = useLazyQuery(CLASSROOM_MASTERS);

  const [loadUpdateLog, { data: logsData }] = useLazyQuery(UPDATELOGS, {
    variables: {
      where: { relatedSchema: "GRADE_COURSE" },
      first: PAGE_LIMIT,
      skip: PAGE_LIMIT * parseInt(pageNambers - 1),
    },
  });

  useEffect(() => {
    if (getUserDataFromLCStorage()?.data) setDataUser(getUserDataFromLCStorage()?.data)
    setRole(JSON.parse(localStorage.getItem("user")).data.role)
    loadUpdateLog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setAdminApproved([
      "ADMIN_CHECKING",
      "ADMIN_APPROVED",
      "FACULTY_CHECKING",
      "FACULTY_SUBMITED",
      "FACULTY_REJECTED",
      "DEPARTMENT_CHECKING",
      "DEPARTMENT_SUBMITED",
      "DEPARTMENT_REJECTED",
      "TEACHER_SCORING",
      "TEACHER_SUBMITED",
      "TEACHER_REJECTED",
    ]);
    loadFaculties({
      variables: {
        where: { isDeleted: false, assessmentYear: accessmentRedux }
      }
    });

    setYear(location?.state?.yearLevel)
    setSemester(location?.state?.semester)
  }, [accessmentRedux]);

  useEffect(() => {
    if (dataUser?.role === "FAC_ADMIN") {
      setFacultyId(dataUser?.faculty?.id)
    }
    else if (dataUser?.role === "DEP_ADMIN") {
      setFacultyId(dataUser?.faculty?.id)
      setDepartmentId(dataUser?.department?.id)
    }
  }, [dataUser])

  useEffect(() => {
    if(sendingStatusData) setSendingStatus(sendingStatusData?.grades?.data[0]?.sendingGradeStatus)
  }, [sendingStatusData])

  useEffect(() => {
    if(facultyData) setDataFaculties(facultyData?.faculties);
  }, [facultyData]);

  useEffect(() => {
    if(departmentData) setDataDepartments(departmentData?.departments);
  }, [departmentData]);

  useEffect(() => {
    if(majorData) setDataMajors(majorData?.majors?.data);
  }, [majorData]);

  useEffect(() => {
    if(classroomData) setDataClassRooms(classroomData?.classRooms?.data);
  }, [classroomData]);

  useEffect(() => {
    if(facultyId !== "") {
      loadDepartments({
        variables: { where: { isDeleted: false, faculty: facultyId } },
      });
    }
  }, [facultyId]);

  useEffect(() => {
    if(departmentId !== "") {
      loadMajor({
        variables: { where: { isDeleted: false, department: departmentId } },
      });
    }
  }, [departmentId]);

  useEffect(() => {
    if(!majorId) return;
    let _where = {}
    if (year !== "") _where = { ..._where, year: year.toString() }
    if(majorId !== "") _where = { ..._where, major: majorId }
    loadClassRooms({
      variables: { where: _where },
    });
  }, [majorId, year]);

  useEffect(() => {
    setSendingStatus("")
    if (facultyId === "" || departmentId === "" || majorId === "" || year === "" || semester === "" || classroomId === "") return;
    loadSendingStatus({
      variables: {
        where: { 
          assessmentYear: accessmentRedux, 
          numberTest: 1, 
          course: courseID,
          faculty: facultyId,
          department: departmentId,
          major: majorId,
          yearLevel: parseInt(year),
          semester: parseInt(semester),
          classRoom: classroomId
        }
      },
    });
  }, [facultyId, departmentId, majorId, year, classroomId])

  useEffect(() => {
    var logCount = logsData?.course_page?.total;
    const countPages = [];
    for (var i = 1; i <= Math.ceil(logCount / PAGE_LIMIT); i++) {
      countPages.push(i);
    }
  }, [logsData]);

  const [sendGradeCourse] = useMutation(SEND_GRADE_COURSE);

  const STYLE = {
    width: 100,
    height: 100,
    borderRadius: 50,
    backgroundColor: "#f0f0f0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    fontWeight: "bold",
  };

  const _selectFaculty = (e) => {
    setFacultyId(e.target.value);
    setDepartmentId("")
    setDataDepartments([])
    setMajorId("")
    setDataMajors([])
    setClassroomId("")
    setDataClassRooms([])
  }
  const _onSelectedDepartment = (e) => {
    setDepartmentId(e.target.value);
    setMajorId("")
    setDataMajors([])
    setClassroomId("")
    setDataClassRooms([])
  }
  const _onSelectedMajor = (e) => {
    setMajorId(e.target.value);
    setClassroomId("")
    setDataClassRooms([])
  }
  const _onSelectedClassroom = (e) => {
    setClassroomId(e.target.value);
  }
  const _onSelectedYear = (e) => {
    setYear(e.target.value);
  }
  const _onSelectedSemester = (e) => {
    setSemester(e.target.value);
  }

  const _teacherStatusColor = (sendingStatus, role) => {
    let COLOR;
    if (
      sendingStatus === "TEACHER_SUBMITED" ||
      sendingStatus === "ADMIN_CHECKING" ||
      sendingStatus === "ADMIN_APPROVED" ||
      sendingStatus === "DEPARTMENT_SUBMITED" ||
      sendingStatus === "DEPARTMENT_CHECKING" ||
      sendingStatus === "DEPARTMENT_REJECTED" ||
      sendingStatus === "FACULTY_CHECKING" ||
      sendingStatus === "FACULTY_SUBMITED" ||
      sendingStatus === "FACULTY_REJECTED"
    ) {
      COLOR = "#18B714";
    } else if (sendingStatus === "TEACHER_REJECTED") {
      COLOR = "#DD1414";
    } else if (sendingStatus === "TEACHER_SCORING") {
      COLOR = "orange";
    } else {
      COLOR = "#f0f0f0";
    }
    return COLOR;
  };

  const _depStatusColor = (sendingStatus, role) => {
    let COLOR;
    if (
      sendingStatus === "ADMIN_CHECKING" ||
      sendingStatus === "ADMIN_APPROVED" ||
      sendingStatus === "DEPARTMENT_SUBMITED" ||
      sendingStatus === "FACULTY_CHECKING" ||
      sendingStatus === "FACULTY_SUBMITED" ||
      sendingStatus === "FACULTY_REJECTED"
    ) {
      COLOR = "#18B714";
    } else if (sendingStatus === "DEPARTMENT_REJECTED") {
      COLOR = "#DD1414";
    } else if (sendingStatus === "TEACHER_SUBMITED") {
      COLOR = "yellow";
    } else if (sendingStatus === "DEPARTMENT_CHECKING") {
      COLOR = "orange";
    } else {
      COLOR = "#f0f0f0";
    }
    return COLOR;
  };

  const _facStatusColor = (sendingStatus, role) => {
    let COLOR;
    if (
      sendingStatus === "ADMIN_CHECKING" ||
      sendingStatus === "ADMIN_APPROVED" ||
      sendingStatus === "FACULTY_SUBMITED"
    ) {
      COLOR = "#18B714";
    } else if (sendingStatus === "FACULTY_REJECTED") {
      COLOR = "#DD1414";
    } else if (sendingStatus === "DEPARTMENT_SUBMITED") {
      COLOR = "yellow";
    } else if (sendingStatus === "FACULTY_CHECKING") {
      COLOR = "orange";
    } else {
      COLOR = "#f0f0f0";
    }
    return COLOR;
  };

  const _adminStatusColor = (sendingStatus, role) => {
    let COLOR;
    if (sendingStatus === "ADMIN_APPROVED") {
      COLOR = "#18B714";
    } else if (sendingStatus === "ADMIN_CHECKING") {
      COLOR = "orange";
    } else if (sendingStatus === "FACULTY_SUBMITED") {
      COLOR = "yellow";
    } else {
      COLOR = "#f0f0f0";
    }
    return COLOR;
  };

  const _lineDepRejected = (sendingStatus) => {
    let COLOR;
    if (
      sendingStatus === "ADMIN_REJECTED" ||
      sendingStatus === "ADMIN_CHECKING" ||
      sendingStatus === "ADMIN_APPROVED" ||
      sendingStatus === "TEACHER_SUBMITED" ||
      sendingStatus === "DEPARTMENT_CHECKING" ||
      sendingStatus === "DEPARTMENT_SUBMITED" ||
      sendingStatus === "FACULTY_CHECKING" ||
      sendingStatus === "FACULTY_SUBMITED" ||
      sendingStatus === "FACULTY_REJECTED"
    ) {
      COLOR = "#18B714";
    } else if (sendingStatus === "DEPARTMENT_REJECTED") {
      COLOR = "#f0f0f0";
    } else {
      COLOR = "#f0f0f0";
    }
    return COLOR;
  };

  const _lineFacRejected = (sendingStatus, role) => {
    let COLOR;
    if (
      sendingStatus === "ADMIN_REJECTED" ||
      sendingStatus === "ADMIN_CHECKING" ||
      sendingStatus === "ADMIN_APPROVED" ||
      sendingStatus === "DEPARTMENT_SUBMITED" ||
      sendingStatus === "FACULTY_CHECKING" ||
      sendingStatus === "FACULTY_SUBMITED"
    ) {
      COLOR = "#18B714";
    } else if (sendingStatus === "FACULTY_REJECTED") {
      if (role !== "TEACHER") {
        COLOR = "#f0f0f0";
      } else {
        COLOR = "#18B714";
      }
    } else {
      COLOR = "#f0f0f0";
    }
    return COLOR;
  };

  const _lineAdminRejected = (sendingStatus, role) => {
    let COLOR;
    if (
      sendingStatus === "ADMIN_CHECKING" ||
      sendingStatus === "ADMIN_APPROVED" ||
      sendingStatus === "FACULTY_SUBMITED"
    ) {
      COLOR = "#18B714";
    } else if (sendingStatus === "ADMIN_REJECTED") {
      if (role === "ADMIN" || role === "FAC_ADMIN") {
        COLOR = "#f0f0f0";
      } else {
        COLOR = "#18B714";
      }
    } else {
      COLOR = "#f0f0f0";
    }
    return COLOR;
  };

  const _showRejected = (sendingStatus, role) => {
    let display = "none";
    if (sendingStatus === "DEPARTMENT_REJECTED") {
      if (role === "DEP_ADMIN") {
        display = "block";
      } else {
        display = "none";
      }
    } else if (sendingStatus === "FACULTY_REJECTED") {
      if (role === "FAC_ADMIN") {
        display = "block";
      } else {
        display = "none";
      }
    } else if (sendingStatus === "ADMIN_REJECTED") {
      if (role === "ADMIN") {
        display = "block";
      } else {
        display = "none";
      }
    }
    return display;
  };

  const _showSubmited = (sendingStatus, role) => {
    let display = "none";
    if (
      (sendingStatus === "" ||
        sendingStatus === "DEPARTMENT_SUBMITED" ||
        sendingStatus === "FACULTY_CHECKING" ||
        sendingStatus === "FACULTY_SUBMITED" ||
        sendingStatus === "FACULTY_REJECTED" ||
        sendingStatus === "ADMIN_CHECKING" ||
        sendingStatus === "ADMIN_APPROVED") &&
      role === "DEP_ADMIN"
    ) {
      display = "block";
    } else if (
      (sendingStatus === "FACULTY_SUBMITED" ||
        sendingStatus === "ADMIN_CHECKING" ||
        sendingStatus === "ADMIN_APPROVED") &&
      role === "FAC_ADMIN"
    ) {
      display = "block";
    } else if (sendingStatus === "ADMIN_APPROVED" && role === "ADMIN") {
      display = "block";
    }
    return display;
  };

  const _checkEmptyValue = () => {
    let _empty = {}
    if(facultyId === "") _empty = { ..._empty, faculty: "ກະລຸນາເລືອກຄະນະ" }
    if(departmentId === "") _empty = { ..._empty, department: "ກະລຸນາເລືອກພາກວິຊາ" }
    if(majorId === "") _empty = { ..._empty, major: "ກະລຸນາເລືອກສາຂາວິຊາ" }
    if(classroomId === "") _empty = { ..._empty, classRoom: "ກະລຸນາເລືອກຫ້ອງນັກສຶກສາ" }
    setEmptyValues(_empty)

    if(!_.isEmpty(_empty)) return;
    setShowApprove(!showApprove)
  }

  const _approve = async (role) => {
    if(facultyId === "") return;
    if(departmentId === "") return;
    if(majorId === "") return;
    if(classroomId === "") return;
    if(year === "") return;
    if(semester === "") return;

    try {
      let _sendGradeCourse = await sendGradeCourse({
        variables: {
          where: {
            course: courseID,
            faculty: facultyId,
            department: departmentId,
            major: majorId,
            classRoom: classroomId,
            assessmentYear: accessmentRedux,
            semester: semester?.toString(),
            yearLevel: parseInt(year)
          },
          data: { sendingGradeStatus: gradeStatus },
        },
      });
  
      if (_sendGradeCourse?.data?.sendGradeCourse?.id) {
        //   window.location.reload();
        setShowRejected(false)
        setShowSubmited(false)
        setShowChecking(false)
        setShowCancel(false)
        setShowApprove(false)
        loadSendingStatus({
          variables: {
            where: { 
              assessmentYear: accessmentRedux, 
              course: courseID,
              faculty: facultyId,
              department: departmentId,
              major: majorId,
              yearLevel: parseInt(year),
              semester: parseInt(semester),
              classRoom: classroomId
            }
          },
        });
      }  
    } catch (error) {
      console.log('error', error)
    }
  };


  const _displayAccept = (sendingStatus) => {
    let display = "none";
    if (
      (sendingStatus === "TEACHER_SUBMITED" || sendingStatus === "DEPARTMENT_REJECTED") &&
      role === "DEP_ADMIN"
    ) {
      display = "block";
    } else if (
      (sendingStatus === "DEPARTMENT_SUBMITED" || sendingStatus === "FACULTY_REJECTED") &&
      role === "FAC_ADMIN"
    ) {
      display = "block";
    } else if (
      (sendingStatus === "FACULTY_SUBMITED" || sendingStatus === "ADMIN_REJECTED") &&
      role === "ADMIN"
    ) {
      display = "block";
    }
    return display;
  };

  const _clickAccept = async (sendingStatus) => {
    let status;
    if (
      (sendingStatus === "TEACHER_SUBMITED" || sendingStatus === "DEPARTMENT_REJECTED") &&
      role === "DEP_ADMIN"
    ) {
      status = "DEPARTMENT_CHECKING";
    } else if (
      (sendingStatus === "DEPARTMENT_SUBMITED" || sendingStatus === "FACULTY_REJECTED") &&
      role === "FAC_ADMIN"
    ) {
      status = "FACULTY_CHECKING";
    } else if (
      (sendingStatus === "FACULTY_SUBMITED" || sendingStatus === "ADMIN_REJECTED") &&
      role === "ADMIN"
    ) {
      status = "ADMIN_CHECKING";
    }

    if(facultyId === "") return;
    if(departmentId === "") return;
    if(majorId === "") return;
    if(classroomId === "") return;
    if(year === "") return;
    if(semester === "") return;

    try {
      const _sendGradeCourse = await sendGradeCourse({
        variables: {
          where: {
            course: courseID,
            faculty: facultyId,
            department: departmentId,
            major: majorId,
            classRoom: classroomId,
            assessmentYear: accessmentRedux,
            semester: semester?.toString(),
            yearLevel: parseInt(year)
          },
          data: { sendingGradeStatus: status },
        },
      });
      
      if (_sendGradeCourse?.data?.sendGradeCourse?.id) {
        //   window.location.reload();
        setShowRejected(false)
        setShowSubmited(false)
        setShowChecking(false)
        setShowCancel(false)
        setShowApprove(false)
        loadSendingStatus({
          variables: {
            where: { 
              assessmentYear: accessmentRedux, 
              course: courseID,
              faculty: facultyId,
              department: departmentId,
              major: majorId,
              yearLevel: parseInt(year),
              semester: parseInt(semester),
              classRoom: classroomId
            }
          },
        });
      }
    } catch (error) {
      console.log('error', error)
    }
  };

  const _clickRejectChecking = async (role) => {
    let status;
    if (role === "DEP_ADMIN") {
      status = "TEACHER_REJECTED";
    } else if (role === "FAC_ADMIN") {
      status = "DEPARTMENT_REJECTED";
    } else if (role === "ADMIN") {
      status = "FACULTY_REJECTED";
    }

    if(facultyId === "") return;
    if(departmentId === "") return;
    if(majorId === "") return;
    if(classroomId === "") return;
    if(year === "") return;
    if(semester === "") return;

    try {
      const _sendGradeCourse = await sendGradeCourse({
        variables: {
          where: {
            course: courseID,
            faculty: facultyId,
            department: departmentId,
            major: majorId,
            classRoom: classroomId,
            assessmentYear: accessmentRedux,
            semester: semester?.toString(),
            yearLevel: parseInt(year)
          },
          data: { sendingGradeStatus: status },
        },
      });
      if (_sendGradeCourse?.data?.sendGradeCourse?.id) {
        //   window.location.reload();
        setShowRejected(false)
        setShowSubmited(false)
        setShowChecking(false)
        setShowCancel(false)
        setShowApprove(false)
        loadSendingStatus({
          variables: {
            where: { 
              assessmentYear: accessmentRedux, 
              course: courseID,
              faculty: facultyId,
              department: departmentId,
              major: majorId,
              yearLevel: parseInt(year),
              semester: parseInt(semester),
              classRoom: classroomId
            }
          },
        });
      }
    } catch (error) {
      console.log('error', error)
    }
    
  };
  const _displayCancel = (sendingStatus) => {
    let display = "none";
    if (
      (sendingStatus === "DEPARTMENT_SUBMITED" || sendingStatus === "TEACHER_REJECTED") &&
      role === "DEP_ADMIN"
    ) {
      display = "block";
    } else if (
      (sendingStatus === "FACULTY_SUBMITED" || sendingStatus === "DEPARTMENT_REJECTED") &&
      role === "FAC_ADMIN"
    ) {
      display = "block";
    } else if (
      (sendingStatus === "ADMIN_APPROVED" || sendingStatus === "FACULTY_REJECTED") &&
      role === "ADMIN"
    ) {
      display = "block";
    }
    return display;
  };

  const _clickCancel = async (role) => {
    let status;
    if (role === "DEP_ADMIN") {
      status = "DEPARTMENT_CHECKING";
    } else if (role === "FAC_ADMIN") {
      status = "FACULTY_CHECKING";
    } else if (role === "ADMIN") {
      status = "ADMIN_CHECKING";
    }
    if(facultyId === "") return;
    if(departmentId === "") return;
    if(majorId === "") return;
    if(classroomId === "") return;
    if(year === "") return;
    if(semester === "") return;

    try {
      const _sendGradeCourse = await sendGradeCourse({
        variables: {
          where: {
            course: courseID,
            faculty: facultyId,
            department: departmentId,
            major: majorId,
            classRoom: classroomId,
            assessmentYear: accessmentRedux,
            semester: semester?.toString(),
            yearLevel: parseInt(year)
          },
          data: { sendingGradeStatus: status },
        },
      });
      if (_sendGradeCourse?.data?.sendGradeCourse?.id) {
        //   window.location.reload();
        setShowRejected(false)
        setShowSubmited(false)
        setShowChecking(false)
        setShowCancel(false)
        setShowApprove(false)
        loadSendingStatus({
          variables: {
            where: { 
              assessmentYear: accessmentRedux, 
              course: courseID,
              faculty: facultyId,
              department: departmentId,
              major: majorId,
              yearLevel: parseInt(year),
              semester: parseInt(semester),
              classRoom: classroomId
            }
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const _displayChecking = (sendingStatus) => {
    let display = "none";
    if (sendingStatus === "DEPARTMENT_CHECKING" && role === "DEP_ADMIN") {
      display = "block";
    } else if (sendingStatus === "FACULTY_CHECKING" && role === "FAC_ADMIN") {
      display = "block";
    } else if (sendingStatus === "ADMIN_CHECKING" && role === "ADMIN") {
      display = "block";
    }
    return display;
  };
  const _clickAcceptChecking = async (role) => {
    let status;
    if (role === "DEP_ADMIN") {
      status = "DEPARTMENT_SUBMITED";
    } else if (role === "FAC_ADMIN") {
      status = "FACULTY_SUBMITED";
    } else if (role === "ADMIN") {
      status = "ADMIN_APPROVED";
    }
    if(facultyId === "") return;
    if(departmentId === "") return;
    if(majorId === "") return;
    if(classroomId === "") return;
    if(year === "") return;
    if(semester === "") return;

    try {
      const _sendGradeCourse = await sendGradeCourse({
        variables: {
          where: {
            course: courseID,
            faculty: facultyId,
            department: departmentId,
            major: majorId,
            classRoom: classroomId,
            assessmentYear: accessmentRedux,
            semester: semester?.toString(),
            yearLevel: parseInt(year)
          },
          data: { sendingGradeStatus: status },
        },
      });
      if (_sendGradeCourse?.data?.sendGradeCourse?.id) {
        //   window.location.reload();
        setShowRejected(false)
        setShowSubmited(false)
        setShowChecking(false)
        setShowCancel(false)
        setShowApprove(false)
        loadSendingStatus({
          variables: {
            where: { 
              assessmentYear: accessmentRedux, 
              course: courseID,
              faculty: facultyId,
              department: departmentId,
              major: majorId,
              yearLevel: parseInt(year),
              semester: parseInt(semester),
              classRoom: classroomId
            }
          },
        });
      }  
    } catch (error) {
      console.log('error', error)
    }
  };

  const goToRoute = async (url, data) => {
    await history.push(url, data);
    window.location.reload();
  };

  return (
    <div>
      {/* <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
          <strong>ຄະແນນ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
          <strong>ເລືອກໝວດໝູ່</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/grades-course-list/limit/40/skip/1")}>
          <strong>ວິຊາທັງໝົດ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ປະຫວັດການຢືນຢັນ</Breadcrumb.Item>
      </Breadcrumb>

      <GradeNavCourseDetail data={location?.state} goToRoute={goToRoute} /> */}

      <CustomContainer style={{ marginTop: -16 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Title text="ປະຫວັດການຢືນຢັນ" />
          <div
            onClick={() => setIsShowHelp(!isShowHelp)}
            style={{
              display: "flex",
              width: 30,
              height: 30,
              borderRadius: 15,
              alignItems: "center",
              // textAlign: "center",
              justifyContent: "center",
              color: "#6f6f6f",
              backgroundColor: "#f0f0f0",
            }}
          >
            <i className="fa fa-question"></i>
          </div>
        </div>
        <Row>
          <Col sm={3}>
            <p style={{padding: 0, margin: 0, marginBottom: 10}}>ຄະນະ</p>
            <Form.Control
              as="select"
              className='form-select'
              style={{ cursor: "pointer" }}
              value={facultyId}
              disabled={role === "FAC_ADMIN" || role === "DEP_ADMIN" ? true : false}
              isInvalid={!!emptyValues?.faculty}
              onChange={(e) => { _selectFaculty(e) }}
            >
              <option value="">ຄະນະທັງໝົດ</option>
              {dataFaculties?.map((item, index) => {
                return (
                  <option key={index} value={item?.id}>
                    {item?.name}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {emptyValues?.faculty}
            </Form.Control.Feedback>
          </Col>
          <Col sm={3}>
            <p style={{padding: 0, margin: 0, marginBottom: 10}}>ພາກວິຊາ</p>
            <Form.Control
              as="select"
              className='form-select'
              style={{ cursor: "pointer" }}
              value={departmentId}
              disabled={role === "DEP_ADMIN" ? true : false}
              isInvalid={!!emptyValues?.department}
              onChange={(e) => _onSelectedDepartment(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataDepartments?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {emptyValues?.department}
            </Form.Control.Feedback>
          </Col>
          <Col sm={3}>
            <p style={{padding: 0, margin: 0, marginBottom: 10}}>ສາຂາ</p>
            <Form.Control
              as="select"
              className='form-select'
              style={{ cursor: "pointer" }}
              value={majorId}
              isInvalid={!!emptyValues?.major}
              onChange={(e) => _onSelectedMajor(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataMajors?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {emptyValues?.major}
            </Form.Control.Feedback>
          </Col>
          <Col sm={3}>
            <p style={{padding: 0, margin: 0, marginBottom: 10}}>ປີຮຽນ</p>
            <Form.Control
              as="select"
              className='form-select'
              style={{ cursor: "pointer" }}
              disabled
              value={year}
              onChange={(e) => _onSelectedYear(e)}
            >
              <option value="">ທັງໝົດ</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </Form.Control>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <p style={{padding: 0, margin: 0, marginBottom: 10}}>ພາກຮຽນ</p>
            <Form.Control
              as="select"
              className='form-select'
              style={{ cursor: "pointer" }}
              disabled
              value={semester}
              onChange={(e) => _onSelectedSemester(e)}
            >
              <option value="">ທັງໝົດ</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </Form.Control>
          </Col>
          <Col sm={3}>
            <p style={{padding: 0, margin: 0, marginBottom: 10}}>ຫ້ອງຮຽນ</p>
            <Form.Control
              as="select"
              className='form-select'
              style={{ cursor: "pointer" }}
              value={classroomId}
              isInvalid={!!emptyValues?.classRoom}
              onChange={(e) => _onSelectedClassroom(e)}
            >
              <option value="">ທັງໝົດ</option>
              {dataClassRooms?.map((item, index) => {
                return (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {emptyValues?.classRoom}
            </Form.Control.Feedback>
          </Col>
        </Row>
        <div className="border-receive">
          <div
            style={{
              width: 600,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  // flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  className={sendingStatus === "TEACHER_SCORING" ? "blink" : ""}
                  style={{
                    ...STYLE,
                    backgroundColor: _teacherStatusColor(sendingStatus, role),
                  }}
                >
                  ອາຈານ
                </div>
                <div
                  style={{
                    width: 20,
                    height: 4,
                    backgroundColor: _lineDepRejected(sendingStatus),
                  }}
                ></div>
              </div>
              <p>ຂັ້ນຕອນທີ1</p>
            </div>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  className={sendingStatus === "DEPARTMENT_CHECKING" ? "blink" : ""}
                  style={{
                    ...STYLE,
                    backgroundColor: _depStatusColor(sendingStatus, role),
                  }}
                >
                  ພາກວິຊາ
                </div>
                <div
                  style={{
                    width: 20,
                    height: 4,
                    backgroundColor: _lineFacRejected(sendingStatus, role),
                  }}
                ></div>
              </div>
              <p>ຂັ້ນຕອນທີ2</p>
            </div>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  // flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  className={sendingStatus === "FACULTY_CHECKING" ? "blink" : ""}
                  style={{
                    ...STYLE,
                    backgroundColor: _facStatusColor(sendingStatus, role),
                  }}
                >
                  ຄະນະ
                </div>
                <div
                  style={{
                    width: 20,
                    height: 4,
                    backgroundColor: _lineAdminRejected(sendingStatus, role),
                  }}
                ></div>
              </div>
              <p>ຂັ້ນຕອນທີ3</p>
            </div>
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  // flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  className={sendingStatus === "ADMIN_CHECKING" ? "blink" : ""}
                  style={{
                    ...STYLE,
                    backgroundColor: _adminStatusColor(sendingStatus, role),
                  }}
                >
                  ວິຊາການ
                </div>
              </div>
              <p>ຂັ້ນຕອນທີ4</p>
            </div>
          </div>

          <div
            style={{
              display: _displayAccept(sendingStatus),
              marginLeft: role === "ADMIN" ? -100 : "",
            }}
          >
            <button
              className="btn btn-outline-success"
              onClick={() => setShowChecking(!showChecking)}
            >
              <i className="fa fa-check"></i> ຮັບກວດ
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <select
              style={{
                width: 300,
                display: role === "ADMIN" ? "block" : "none",
                marginTop: 10,
              }}
              className="form-control"
              onChange={(e) => {
                setGradeStatus(e.target.value);
              }}
            >
              <option value="">ສະຖານະທັງໝົດ</option>
              {adminApprove.map((e, index) => {
                return <option value={e} key={index}>{Translater(e)}</option>;
              })}
            </select>
            <button
              disabled={gradeStatus === "" ? true : false}
              style={{
                display: role === "ADMIN" ? "block" : "none",
                marginLeft: 10,
                height: 38,
              }}
              className="btn btn-success"
              onClick={() => _checkEmptyValue()}
            >
              <i className="fa fa-check-circle"></i> ຢືນຢັນ
            </button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="col-sm-4 col-md-4 col-lg-4">
              ສະຖານະປັດຈຸບັນ: {Translater(sendingStatus)}
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4">
              <div
                style={{
                  display: "flex",
                  justifyContent: role !== "ADMIN" ? "center" : "",
                }}
              >
                <div
                  style={{
                    display: _displayChecking(sendingStatus),
                    marginLeft: role === "ADMIN" ? 5 : "",
                  }}
                >
                  <button
                    className="btn btn-outline-success"
                    onClick={() => setShowSubmited(!showSubmited)}
                  >
                    <i className="fa fa-check-circle"></i> ຍອມຮັບ
                  </button>
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => setShowRejected(!showRejected)}
                  >
                    <i className="fa fa-times"></i> ປະຕິເສດ
                  </button>
                </div>
                <button
                  style={{
                    display: _displayCancel(sendingStatus),
                    marginLeft: role === "ADMIN" ? 75 : "",
                  }}
                  className="btn btn-outline-danger"
                  onClick={() => setShowCancel(!showCancel)}
                >
                  <i className="fa fa-times"></i> ຍົກເລີກ
                </button>
              </div>
            </div>

            <div className="col-sm-4 col-md-4 col-lg-4"></div>
          </div>

          <div
            style={{
              display: "flex",
              marginTop: 10,
              marginLeft: role === "ADMIN" ? -100 : "",
            }}
          >
            <div
              className="btn"
              style={{
                marginRight: 20,
                backgroundColor: "#f0f0f0",
                display: _showRejected(sendingStatus, role),
              }}
            >
              ຄະແນນຖືກປະຕິເສດ{" "}
              <i className="fa fa-times" style={{ color: "#DD1414" }}></i>
            </div>
            <div
              className="btn"
              style={{
                display: _showSubmited(sendingStatus, role),
                backgroundColor: "#f0f0f0",
              }}
            >
              ຍອມຮັບຄະແນນສຳເລັດ{" "}
              <i className="fa fa-check" style={{ color: "#18B714" }}></i>
            </div>
          </div>
        </div>
      </CustomContainer>

      <HelpModal show={isShowHelp} onHide={() => setIsShowHelp(!isShowHelp)} />
      <Modal show={showApprove} onHide={() => setShowApprove(!showApprove)}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#3B8D16",
          }}
        >
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 45,
              }}
            >
              <i className="fa fa-check" style={{ color: "#3B8D16" }}></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", fontWeight: "bolder" }}>
          ທ່ານແນ່ໃຈແລ້ວບໍ່ວ່າຕ້ອງການຢືນຢັນ <br />
          <div className="d-flex justify-content-center">
            <div className="d-flex">
              <Button
                className="btn btn-secondary"
                style={{
                  backgroundColor: "white",
                  color: "gray",
                  marginRight: 10,
                }}
                onClick={() => setShowApprove(!showApprove)}
              >
                ຍົກເລີກ
              </Button>
              <Button
                className="btn btn-success"
                style={{ backgroundColor: "#3B8D16" }}
                onClick={() => {
                  _approve(role);
                }}
              >
                ຢືນຢັນ
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showChecking} onHide={() => setShowChecking(!showChecking)}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#3B8D16",
          }}
        >
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 45,
              }}
            >
              <i className="fa fa-check" style={{ color: "#3B8D16" }}></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", fontWeight: "bolder" }}>
          ທ່ານແນ່ໃຈແລ້ວບໍ່ວ່າຕ້ອງການກວດຄະແນນ <br />
          <div className="d-flex justify-content-center">
            <div className="d-flex">
              <Button
                className="btn btn-secondary"
                style={{
                  backgroundColor: "white",
                  color: "gray",
                  marginRight: 10,
                }}
                onClick={() => setShowChecking(!showChecking)}
              >
                ຍົກເລີກ
              </Button>
              <Button
                className="btn btn-success"
                style={{ backgroundColor: "#3B8D16" }}
                onClick={() => {
                  _clickAccept(sendingStatus);
                }}
              >
                ຍອມຮັບ
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showSubmited} onHide={() => setShowSubmited(!showSubmited)}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#3B8D16",
          }}
        >
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 45,
              }}
            >
              <i className="fa fa-check" style={{ color: "#3B8D16" }}></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", fontWeight: "bolder" }}>
          ທ່ານແນ່ໃຈແລ້ວບໍ່ວ່າຕ້ອງການຍອມຮັບ <br />
          <div className="d-flex justify-content-center">
            <div className="d-flex">
              <Button
                className="btn btn-secondary"
                style={{
                  backgroundColor: "white",
                  color: "gray",
                  marginRight: 10,
                }}
                onClick={() => setShowSubmited(!showSubmited)}
              >
                ຍົກເລີກ
              </Button>
              <Button
                className="btn btn-success"
                style={{ backgroundColor: "#3B8D16" }}
                onClick={() => {
                  _clickAcceptChecking(role);
                }}
              >
                ຍອມຮັບ
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showCancel} onHide={() => setShowCancel(!showCancel)}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#DD1414",
          }}
        >
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 45,
              }}
            >
              <i className="fa fa-times" style={{ color: "#DD1414" }}></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", fontWeight: "bolder" }}>
          ທ່ານແນ່ໃຈແລ້ວບໍ່ວ່າຕ້ອງການຍົກເລີກ
          <div className="d-flex justify-content-center">
            <div className="d-flex">
              <Button
                className="btn btn-secondary"
                style={{
                  backgroundColor: "white",
                  color: "gray",
                  marginRight: 10,
                }}
                onClick={() => setShowCancel(!showCancel)}
              >
                ຍົກເລີກ
              </Button>
              <Button
                className="btn btn-dark"
                onClick={() => {
                  _clickCancel(role);
                }}
              >
                ຍອມຮັບ
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showRejected} onHide={() => setShowRejected(!showRejected)}>
        <Modal.Header
          closeButton
          style={{
            backgroundColor: "#DD1414",
          }}
        >
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <div
              style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 45,
              }}
            >
              <i className="fa fa-times" style={{ color: "#DD1414" }}></i>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", fontWeight: "bolder" }}>
          ທ່ານແນ່ໃຈແລ້ວບໍ່ວ່າຕ້ອງການປະຕິເສດ
          <div className="d-flex justify-content-center">
            <div className="d-flex">
              <Button
                className="btn btn-secondary"
                style={{
                  backgroundColor: "white",
                  color: "gray",
                  marginRight: 10,
                }}
                onClick={() => setShowRejected(!showRejected)}
              >
                ຍົກເລີກ
              </Button>
              <Button
                className="btn btn-dark"
                onClick={() => {
                  _clickRejectChecking(role);
                }}
              >
                ຍອມຮັບ
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default HistoryReceiveGrade;
