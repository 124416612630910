import React, { useState, useEffect } from "react";
import PaginationHelper from "../../../helpers/PaginationHelper";
import { useLazyQuery } from "@apollo/react-hooks";
import { Breadcrumb } from "react-bootstrap";
import useReactRouter from "use-react-router";
import Nav from "react-bootstrap/Nav";
import Const from "../../../consts";
import Routers from "../../../consts/router";
import {
  CustomContainer,Title,
} from "../../../common";
import Loading from "../../../common/Loading";
import { UPDATE_LOGS } from "../../../apollo/updateLog";
import { formatDateTime } from "../../../common/super";
export default function UpdateLogs() {
 const { _limit, _skip, Pagination_helper } = PaginationHelper();
  const { history } = useReactRouter();
  const [logData, setLogData] = useState("");

  const [
    loadUpdatelog,
    { loading: updateLogLoading, data: updateLogData },
  ] = useLazyQuery(UPDATE_LOGS);


  useEffect(() => {
    loadUpdatelog({
      variables: {
        skip: (_skip - 1) * _limit,
        first: _limit,
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setLogData(updateLogData?.updateLogs?.data);
  }, [updateLogData]);

 

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ເລືອກການຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ປະຫວັດການປ່ຽນແປງຂໍ້ມູນ</Breadcrumb.Item>
      </Breadcrumb>

      <Nav variant="tabs" style={{ height: 51 }}>
        <Nav.Item>
          <Nav.Link
            active={"update-log"}
            onClick={() => history.push(`/settings/update-log/limit/40/skip/1`)}
          >
            ປະຫວັດການປ່ຽນແປງຂໍ້ມູນ
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link style={{ cursor:'no-drop' }} active={""}>
            ລາຍລະອຽດປະຫວັດການປ່ຽນແປງຂໍ້ມູນ
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <CustomContainer>
        {updateLogLoading && <Loading />}
        {/* title */}
        <Title text="ປະຫວັດການປ່ຽນແປງຂໍ້ມູນ"></Title>
        {/* body list */}
        <div className="row">
          <table className="table table-hover table-bordered">
              <tr style={{background: Const.PRIMARY_COLOR, color: "white", textAlign: "center"}}>
                <th style={{ width: 70 }}>#</th>
                <th>ປະເພດການປ່ຽນແປງ</th>
                <th>ຕາຕະລາງ</th>
                <th>ວັນທີ</th>
                <th>ປ່ຽນແປງໂດຍ</th>
                <th>ລາຍລະອຽດ</th>
              </tr>
            <tbody>
              {logData &&
                logData?.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      border: "2px solid #ffffff",
                      textAlign: "center",
                      background: "#f0f0f0",
                    }}
                  >
                    <td>{index + 1}</td>
                    <td>{item.logMethod}</td>
                    <td>{item.relatedSchema}</td>
                    <td>
                      {item.createdAt ? formatDateTime(item.createdAt) : "-"}
                    </td>
                    <td>{item.createdBy}</td>
                    <td>
                      <button
                        className="btn btn-outline-success"
                        onClick={() =>
                          history.push(`/settings/update-log-detail/${item.id}`)
                        }
                      >
                        <i className="fa fa-eye"></i> ລາຍລະອຽດ
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {Pagination_helper(updateLogData?.updateLogs?.total, Routers.SETTINGS_UPDATE_LOG)}
      </CustomContainer>
    </div>
  );
}
