import React, { useState, useEffect, useRef } from "react";
import useReactRouter from "use-react-router";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from 'react-redux'

/**
 *
 * @Library
 *
 */
import axios from "axios";
import { Formik } from "formik";
import CKEditor from "ckeditor4-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 *
 * @Component
 *
 */
import { Breadcrumb, Form, Button, Row, Col } from "react-bootstrap";
import { CustomContainer, Title } from "../../common";

/**
 *
 * @Constant
 *
 */
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

/**
 *
 * @Apollo
 *
 */
import { PRE_SIGNED_URL, PRE_SIGNED_MANY_URL, UPDATE_FILE } from "../../apollo/doc";
import { NOTIC, UPDATE_NOTIC, FACULTY } from "../../apollo/notic";
import { FACULTIES } from "../../apollo/faculty";

/**
 *
 * @Function
 *
 */
import moment from "moment";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./notice.css";
import { PAGE_GINATION } from "../../consts/router";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function EditNotice() {
  const { history, location, match } = useReactRouter();
  /**
   *
   * @State
   *
   */
  const classes = useStyles();
  const accessmentRedux = useSelector((state) => state.accessment.value)

  const [loadFile, setLoadFile] = useState([]);
  const [displayFile, setDisplayFile] = useState(location?.state?.files ?? []);
  const [oldFileId, setOldFileId] = useState([]);
  const [oldFileUrl, setOldFileUrl] = useState([]);
  const [coverImage, setCoverImage] = useState(
    location?.state?.coverImage?.url
  );
  const [previewImageURL, setPreviewImageURL] = useState();
  const [setContentState] = useState([location?.state?.content]);
  const [checkAccessable, setCheckAccessable] = useState(
    location?.state?.accessableRole?.length > 2
      ? "ALL"
      : location?.state?.accessableRole?.length > 1
      ? location?.state?.accessableRole[1]
      : location?.state?.accessableRole[0]
  );
  // const [noticData, setNoticData] = useState({});
  const [valueData, setValueData] = useState(location?.state?.content);
  const [setNoticType] = useState();
  let [fileType, setFileType] = useState([]);
  let [fileUrl, setFileUrl] = useState([]);
  let [facultyName, setFacultyName] = useState("");
  let [facultyId, setFacultyId] = useState("");
  let [facultyStateId, setFacultyStateId] = useState([]);
  /**
   *
   * @Apollo
   *
   */
  const [loadNotice, { data: noticData }] = useLazyQuery(NOTIC);
  const [updateNotic] = useMutation(UPDATE_NOTIC);
  const [updateFiLe] = useMutation(UPDATE_FILE);
  const [loadPresignImage, { data: presignData }] =
    useLazyQuery(PRE_SIGNED_URL);

  const [loadFileUrl, { data: dataFile }] = useLazyQuery(PRE_SIGNED_MANY_URL);

  const [loadFaculty, { data: facultyData }] = useLazyQuery(FACULTIES, {fetchPolicy: "network-only"});

  /**
   *
   * @UseEffect
   *
   */
  useEffect(() => {
    loadNotice({
      variables: {
        where: {
          id: match?.params?.id,
        },
      },
    });
    loadFaculty({variables: {where: {isDeleted: false, assessmentYear: accessmentRedux}}})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    let arrayfac =[];
    for (let i = 0; i < location?.state?.accessableFaculty.length; i++) {
      arrayfac.push(location?.state?.accessableFaculty[i]?.facultyId);
      
    }
    setFacultyStateId(arrayfac);
  }, []);
  
  useEffect(() => {
    loadFaculty({variables: {where: {isDeleted: false, assessmentYear: accessmentRedux}}})
  }, [accessmentRedux])
  

  useEffect(() => {
    // setCheckAccessable(noticData?.notic?.accessableRole?.length > 2 ? "ALL" : noticData?.notic?.accessableRole?.length > 1?noticData?.notic?.accessableRole[1]:noticData?.notic?.accessableRole[0]);
    if (noticData?.notic?.files?.length > 0) {
      let newOldFileId = oldFileId;
      let newOldFileUrl = oldFileUrl;
      for (var i = 0; i < noticData.notic.files.length; i++) {
        newOldFileId.push(noticData.notic.files[i].id);
        newOldFileUrl.push({
          // title: noticData.notic.files[i].title,
          file: noticData.notic.files[i].file,
        });
      }
      setOldFileId(newOldFileId);
      setOldFileUrl(newOldFileUrl);
    }
  }, [noticData]);

  useEffect(() => {
    setPreviewImageURL(noticData?.notic?.coverImage?.url);
  }, [noticData?.notic?.coverImage?.url]);

  useEffect(() => {
    if (dataFile?.preSignedManyUrl?.length > 0) {
      let newUrl = fileUrl;
      for (var i = 0; i < dataFile.preSignedManyUrl.length; i++) {
        newUrl.push({ url: dataFile.preSignedManyUrl[i].url });
      }
      setFileUrl(newUrl);
    }
  }, [dataFile]);

  useEffect(() => {
    if (fileType.length > 0) {
      getPresign(fileType);
    }
  }, [fileType]);

  const getPresign = async (fileType) => {
    await loadFileUrl({
      variables: {
        data: fileType,
      },
    });
  };

  useEffect(() => {
    getPresignImage(coverImage);
  }, [coverImage]);

  const getPresignImage = async (file) => {
    await loadPresignImage({
      variables: {
        mimeType: file?.type,
        fileName: file?.name?.split(".")[0],
      },
    });
  };
  /**
   *
   * @Function
   *
   */
  const _bactoNoticeList = () => {
    history.push("/notice-list"+PAGE_GINATION);
    // window.location.reload(true);
  };
  const _clickResets = () => {
    history.push("/notice-list"+PAGE_GINATION);
  };
  function uploadImageCallBack(file) {}

  const onContentStateChange = (contentState) => {
    setContentState(contentState.blocks[0].text);
  };

  // function checkAccessable
  const _onChecked = (e) => {
    setCheckAccessable(e.target.value);
  };

  // function images
  const inputFile = useRef("inputFile");
  const inputImage = useRef("inputImage");
  const _addImage = () => {
    inputImage.current.click();
  };
  const _addFile = () => {
    inputFile.current.click();
  };
  const _handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setPreviewImageURL(URL.createObjectURL(event.target.files[0]));
      setCoverImage(event.target.files[0]);
    }
  };

  const handleChangeFaculty = (e) => {
    setFacultyName(e.target.value.split("|+|")[0]);
    setFacultyId(e.target.value.split("|+|")[1]);
  };

  const _handleChangeFile = async (event) => {
    let type = [];
    let newDisplayFile = displayFile;
    for (var i = 0; i < event?.target?.files?.length; i++) {
      newDisplayFile.push({
        title: event.target.files[i].name,
        url: "",
      });
      let _backType =
        event.target.files[i].name.split(".")[
          event.target.files[i].name.split(".").length - 1
        ];
      let _frontType = event.target.files[i].type.split("/")[0];
      type.push({
        mimeType: `${_frontType}/${_backType}`,
        fileName: event.target.files[i].name.split(".")[0],
      });
    }

    setFileType(type);
    let newFile = [...loadFile, ...event.target.files];
    setDisplayFile(newDisplayFile);

    setLoadFile(newFile);
  };

  const _removeFile = (item, e) => {
    let newFile = [];
    let newDisplayFile = [];
    let newOldFileUrl = [];
    for (var i = 0; i < loadFile.length; i++) {
      if (loadFile[i]?.name !== item?.title) {
        newFile.push(loadFile[i]);
      }
    }
    for (var i = 0; i < displayFile?.length; i++) {
      if (displayFile[i]?.title !== item?.title) {
        newDisplayFile.push(displayFile[i]);
      }
    }
    for (var i = 0; i < oldFileUrl?.length; i++) {
      if (oldFileUrl[i]?.title !== item?.title) {
        newOldFileUrl.push(oldFileUrl[i]);
      }
    }
    setLoadFile(newFile);
    setDisplayFile(newDisplayFile);
    setOldFileUrl(newOldFileUrl);
  };

  const _selectNoticType = (e) => {
    setNoticType(e.target.value);
  };

  const _uploadFile = async () => {
    if (presignData?.preSignedUrl) {
      const response = await axios({
        method: "put",
        url: presignData.preSignedUrl.url,
        data: coverImage,
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      });
      return response;
    }
  };

  const _uploadManyFile = async () => {
    if (fileUrl && fileUrl.length > 0) {
      let newResponse = [];
      for (var i = 0; i < fileUrl.length; i++) {
        const response = await axios({
          method: "put",
          url: fileUrl[i].url,
          data: loadFile[i],
          headers: {
            "Content-Type": " file/*; image/*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        });
        newResponse.push(response);
      }
      return newResponse;
    }
  };

  const _createNotic = async (parameter) => {
    let fileName = null;
    if (coverImage !== previewImageURL) {
      const res = await _uploadFile();
      fileName = res.request.responseURL.split("?")[0];
    }else{
      fileName = previewImageURL
    }
    let newUploadUrl = [];
    if (loadFile.length > 0) {
      const res = await _uploadManyFile();
      for (var i = 0; i < res.length; i++) {
        newUploadUrl.push({
          title: res[i].request.responseURL.split("?")[0].split("/")[4],
          file: res[i].request.responseURL.split("?")[0],
        });
      }
    }
    if (newUploadUrl.length > 0) {
      let fileData = {
        title: newUploadUrl[0]?.title,
        file: newUploadUrl[0]?.file,
      };
      await updateFiLe({
        variables: {
          data: fileData,
          where: {id: location?.state?.files[0].id}
        }
      }).then(async (x) => {
        let allData = {
          ...parameter.data,
          // files: [x.data.updateFiLe.id],
          coverImage: {
            url: fileName,
          },
        };
      // TD: send data 2 server
      await updateNotic({
        variables: { data: allData, where: { id: location?.state?.id } },
      }).then((y) => {
        history.push(`/notice-list`+PAGE_GINATION);
        window.location.reload(true);
      });
    });
    } else {
      let allData = {
        ...parameter.data,
        // files: oldFileId[o].id,
        coverImage: {
            url: fileName
        },
      };
      // TD: send data 2 server
     await updateNotic({
        variables: { data: allData, where: { id: location?.state?.id } }
      }).then((y) => {
        history.push(`/notice-list`+PAGE_GINATION);
        window.location.reload(true);
      });
    }
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "white",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  // const noticAddValidation = Yup.object().shape({
  //   facalty: Yup.string()
  //     .required('Required'),
  // });
  const onEditorChange = (evt) => {
    setValueData(evt.editor.getData());
  };
  // if (loadingData) return <a>Loading ....</a>;

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={_bactoNoticeList} alt>
          ການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          ແກ້ໄຂການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ
        </Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <Title text={"ແກ້ໄຂການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ"} />
        <Formik
          initialValues={{
            noticeType: location?.state?.type,
            accessableRole: checkAccessable,
            startDate: moment(location?.state?.startDate).format("YYYY-MM-DD"),
            endDate: moment(location?.state?.endDate).format("YYYY-MM-DD"),
            title: location?.state?.title,
            content: valueData,
          }}
          // validationSchema={noticAddValidation}
          onSubmit={async (values, { handleSubmit }) => {
            let accessableRole;
            if (values.accessableRole === "ALL") {
              accessableRole =  ["ADMIN",
                "TEACHER",
                "STUDENT",
                "DEP_ADMIN",
                "FAC_ADMIN",
                "DEAN"]
            } else {
              accessableRole = ["ADMIN", values.accessableRole]
            };
            let arrFac = location?.state?.accessableFaculty;
            let accessableFaculty = [];
            if (facultyName !== "") {
              if(arrFac.length === facultyData?.faculties?.length){
                accessableFaculty.push({ facultyId: facultyId, name: facultyName });
              }else{
                 accessableFaculty.push(...arrFac,{ facultyId: facultyId, name: facultyName });
              }
             
            }
            if(facultyName === ""){
              accessableFaculty = facultyData?.faculties?.map((item, index) => {
                return(
                  {facultyId: item.id,name: item.name}
                )
              });
            }
            // delete accessableFaculty[0]?.__typename
            for (let i = 0; i < accessableFaculty.length; i++) {
              delete accessableFaculty[i]?.__typename
              
            }
            let parameter = {
              data: {
                type: values.noticeType,
                startDate: values.startDate,
                endDate: values.endDate,
                accessableRole: accessableRole,
                title: values.title,
                content: valueData,
                accessableFaculty,
                isPublish: true,
              },
            };
            _createNotic(parameter);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <div>
              <Form.Group>
                <Form.Label>ປະເພດແຈ້ງການ</Form.Label>
                <Form.Control
                  as="select"
                  name="noticeType"
                  value={values.noticeType}
                  onChange={((e) => _selectNoticType(e), handleChange)}
                >
                  <option value="NOTIC">ແຈ້ງການ</option>
                  <option value="AGREEMENT">ຂໍ້ຕົກລົງ</option>
                  <option value="REGULATION">ລະບຽບ</option>
                  <option value="NEWS">ໜັງສືພິມ</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>ຄະນະ</Form.Label>
                <Form.Control
                  as="select"
                  value={values.facultyName}
                  name="facultyName"
                  onChange={((e) => handleChangeFaculty(e))}
                  isInvalid={!!errors.facultyName}
                  // required={true}
                >
                  <option value="">ທຸກຄະນະ</option>
                  {facultyData?.faculties?.map((item, index) => {
                    return (
                      <option disabled={facultyData?.faculties?.length === location?.state?.accessableFaculty.length ? false : facultyStateId.indexOf(item.id) !== -1} key={index} value={item.name + "|+|" + item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <Form.Label>ເລືອກການສະແດງ</Form.Label>
              {"\n"}
              <Form.Group
                as={Row}
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Form.Check
                  style={{ marginLeft: 25 }}
                  type="radio"
                  label="ທັງໝົດ"
                  name="accessableRole"
                  id="accessable1"
                  value="ALL"
                  onChange={handleChange}
                  checked={values.accessableRole === "ALL" ? true : false}
                />
                <Form.Check
                  style={{ marginLeft: 30 }}
                  type="radio"
                  label="ສະເພາະອາຈານ"
                  name="accessableRole"
                  id="accessable2"
                  value="TEACHER"
                  onChange={handleChange}
                  checked={values.accessableRole === "TEACHER" ? true : false}
                />
                <Form.Check
                  style={{ marginLeft: 30 }}
                  type="radio"
                  label="ສະເພາະນັກຮຽນ"
                  name="accessableRole"
                  id="accessable3"
                  value="STUDENT"
                  onChange={handleChange}
                  checked={values.accessableRole === "STUDENT" ? true : false}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label style={{ display: "block" }}>
                  ກຳນົດເວລາແຈ້ງການ
                </Form.Label>
                <span>ວັນທີເປີດ</span>
                <TextField
                  name="startDate"
                  type="date"
                  defaultValue={values.startDate}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />{" "}
                <span>ວັນທີປິດ</span>
                <TextField
                  name="endDate"
                  type="date"
                  defaultValue={values.endDate}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group style={{ width: "100%" }} Col={9}>
                <Form.Label>ປ້ອນຫົວຂໍ້ເອກະສານ</Form.Label>
                <Row>
                  <Col
                    md={1}
                    onClick={_addImage}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 12,
                      width: 110,
                      height: 50,
                      cursor: "pointer",
                      marginLeft: 15,
                      border: "1px solid #057CAE",
                      borderStyle: "dashed",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1,
                      }}
                    >
                      <FontAwesomeIcon icon={["fa", "plus"]} />
                      &nbsp;&nbsp; ເພີ່ມຮູບພາບ
                    </div>

                    <img
                      src={previewImageURL}
                      style={{ height: 50, position: "absolute" }}
                    />
                    <input
                      type="file"
                      id="inputImage"
                      multiple
                      name="image"
                      ref={inputImage}
                      style={{ display: "none" }}
                      onChange={_handleChangeImage}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      style={{ height: 50 }}
                      type="text"
                      value={values.title}
                      name="title"
                      onChange={handleChange}
                      placeholder="ກະລຸນາປ້ອນ..."
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Label>ປ້ອນເນື້ອໃນເອກະສານ</Form.Label>
              {/* <Editor
                  name="content"
                  initialContentState={contentState}
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                  onContentStateChange={onContentStateChange}
                  toolbar={{ image: { icon: false } }}
                  onChange={handleChange}
                /> */}
              <div>
                <CKEditor data={valueData} onChange={onEditorChange} />
              </div>
              <Form.Label style={{ marginTop: 10, display: "block" }}>
                ອັບໂຫລດ File ເອກະສານ
              </Form.Label>
              <div className={displayFile?.length > 0 ? "box-dashed" : ""}>
                {displayFile?.map((item, index) => {
                  return (
                    <div className="file-item">
                      <div className="tagfile">{item?.title}</div>
                      <button
                        onClick={() => _removeFile(item, index)}
                        className="btn btn-sm"
                        style={{ float: "right", width: "20%" }}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </div>
                  );
                })}
              </div>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      <b style={{ color: "red" }}>ຄຳແນະນຳ</b>
                    </Typography>
                    <hr />
                    <p>{"ຂະໜາດບໍ່ເກີນ 5MB "}</p>
                    <p style={{ color: "red" }}>{"ອັບໂຫລດໄດ້ສະເພາະໄຟລ໌"}</p>
                    <p>{".PDF, .XLSX, .DOCX, .PPT, .JPEG, .PNG, .JPG"}</p>
                  </React.Fragment>
                }
              >
                <div
                  onClick={_addFile}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: 50,
                    border: "1px solid #057CAE",
                    borderStyle: "dashed",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={["fa", "download"]} />
                  &nbsp;&nbsp;&nbsp;&nbsp; ອັບໂຫຼດ File ເອກະສານ
                  <input
                    type="file"
                    id="inputfile"
                    multiple
                    name="image"
                    ref={inputFile}
                    style={{ display: "none" }}
                    onChange={_handleChangeFile}
                  />
                </div>
              </HtmlTooltip>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: 30,
                }}
              >
                <Button
                  onClick={() => _clickResets()}
                  variant={"none"}
                  style={{
                    border: "1px solid #057CAE",
                    borderRadius: 0,
                    width: 200,
                  }}
                >
                  ຍົກເລີກ
                </Button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  onClick={handleSubmit}
                  style={{
                    border: "1px solid #057CAE",
                    borderRadius: 0,
                    width: 200,
                  }}
                >
                  ແກ້ໄຂ
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </CustomContainer>
    </div>
  );
}
