import React, { useEffect, useState } from 'react'
import { Breadcrumb, Row, Col, Modal, Button } from 'react-bootstrap'
import useReactRouter from 'use-react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMutation } from '@apollo/react-hooks'
import Consts from '../../consts'
import Routers from '../../consts/router'
import { CustomContainer, Title } from '../../common'
import { formatDate, onConvertStudentType } from "../../common/super";
import { DELETE_PRERE_CODE } from '../../apollo/codePreregistration';
import { onConvertEducationLevel } from '../../helpers/user'

function CodePreregistrationDetail() {
  const { history } = useReactRouter()
  const dataDetail = history?.location?.state;
  
  // States
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
  const [userData, setUserData] = useState()
  
  const [deleteCode] = useMutation(DELETE_PRERE_CODE)

  // Set states
  const _handleDeleteConfirmModalClose = () => setShowDeleteConfirmModal(false)
  const _handleDeleteConfirmModalShow = () => setShowDeleteConfirmModal(true)

  useEffect(() => {
    if(!dataDetail) return;
    setUserData(dataDetail)
  }, [dataDetail])
  
  const _edit = () => {
    history.push(Routers.CODE_PRERE_EDIT + "/id/" + dataDetail?.id, dataDetail);
  }
  
  const _deleteConfirm = async () => {
    try {
      const deleteData = await deleteCode({ 
        variables: { where: { id: dataDetail?.id } } 
      });
      if(deleteData && deleteData?.data?.deletePreregistrationCode?.id) {
        _handleDeleteConfirmModalClose()
        history.push(Routers.CODE_PRERE_LIST + Routers.PAGE_GINATION);
        window.location.reload()
      }
    } catch(error) {
      console.log("update error::: ", error.message);
    }
  }

  const _onConvertGender = (gender) => {
    let result;
    switch (gender) {
      case "MALE":
        result = "ຊາຍ";
        break;
      case "FEMALE":
        result = "ຍິງ";
        break;
      case "MONK":
        result = "ພຣະ";
        break;
      case "NOVICE":
        result = "ສຳມະເນນ";
        break;
      default:
        result = "ຊາຍ";
    }
    return result;
  };

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push(Routers.CODE_PRERE_LIST + Routers.PAGE_GINATION)}
        >
          ລະຫັດລົງທະບຽນເຂົ້າຮຽນ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push(Routers.CODE_PRERE_LIST + Routers.PAGE_GINATION)}
        >
          ລະຫັດລົງທະບຽນເຂົ້າຮຽນທັງໝົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍລະອຽດລະຫັດລົງທະບຽນເຂົ້າຮຽນ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title text="ລາຍລະອຽດລະຫັດລົງທະບຽນເຂົ້າຮຽນ" />

          {/* Button group */}
          <div>
            {/* ແກ້ໄຂ */}
            <button
              style={{
                backgroundColor: "#fff",
                color: Consts.BORDER_COLOR,
                width: 100,
                height: 40,
                border: "1px solid " + Consts.BORDER_COLOR,
                outline: "none",
                marginRight: 5,
              }}
              onClick={() => _edit()}
            >
              <FontAwesomeIcon icon="edit" style={{ fontSize: 16 }} /> ແກ້ໄຂ
            </button>

            {/* ລຶບ */}
            <button
              style={{
                backgroundColor: "#fff",
                color: Consts.BORDER_COLOR_DELETE,
                width: 100,
                height: 40,
                border: "1px solid " + Consts.BORDER_COLOR_DELETE,
                outline: "none",
              }}
              onClick={_handleDeleteConfirmModalShow}
            >
              <i className="fa fa-trash" /> ລຶບ
            </button>
          </div>
        </div>

        <div
          style={{
            width: 500,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            paddingBottom: 80,
          }}
        >
          {/* -------- ຂໍ້ມູນລະຫັດລົງທະບຽນເຂົ້າຮຽນ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ຂໍ້ມູນລະຫັດລົງທະບຽນເຂົ້າຮຽນ
            </div>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>ລະຫັດລົງທະບຽນເຂົ້າຮຽນ</Col>
                <Col>
                  {userData &&
                    (userData?.code ? userData?.code : "-")}
                </Col>
              </Row>
              <Row>
                <Col>ຄະນະ</Col>
                <Col>
                  {userData &&
                    userData?.faculty &&
                    (userData?.faculty?.name ? userData?.faculty?.name : "-")}
                </Col>
              </Row>
              <Row>
                <Col>ພາກວິຊາ</Col>
                <Col>
                  {userData &&
                    userData?.department &&
                    (userData?.department?.name ? userData?.department?.name : "-")}
                </Col>
              </Row>
              <Row>
                <Col>ສາຂາວິຊາ</Col>
                <Col>
                  {userData &&
                    userData?.major &&
                    (userData?.major?.name ? userData?.major?.name : "-")}
                </Col>
              </Row>
              <Row>
                <Col>ລະດັບສຶກສາ</Col>
                <Col>
                  {userData &&
                    (userData?.educationLevel ? onConvertEducationLevel(userData?.educationLevel) : "-")}
                </Col>
              </Row>
              <Row>
                <Col>ປະເພດນັກສຶກສາ</Col>
                <Col>
                  {userData &&
                    (userData?.studentType ? onConvertStudentType(userData?.studentType) : "-")}
                </Col>
              </Row>
            </div>
          </div>

          {/* -------- ຂໍ້ມູນທົ່ວໄປ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ຂໍ້ມູນທົ່ວໄປ
            </div>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>ຊື່ (ພາສາລາວ)</Col>
                <Col>
                  {userData && (userData?.firstNameL ? userData?.firstNameL : "-")}
                </Col>
              </Row>
              <Row>
                <Col>ນາມສະກຸນ (ພາສາລາວ)</Col>
                <Col>
                  {userData && (userData?.lastNameL ? userData?.lastNameL : "-")}
                </Col>
              </Row>
              <Row>
                <Col>ຊື່ (ພາສາອັງກິດ)</Col>
                <Col>
                  {userData && (userData?.firstNameE ? userData?.firstNameE : "-")}
                </Col>
              </Row>
              <Row>
                <Col>ນາມສະກຸນ (ພາສາອັງກິດ)</Col>
                <Col>
                  {userData && (userData?.lastNameE ? userData?.lastNameE : "-")}
                </Col>
              </Row>
              <Row>
                <Col>ເພດ</Col>
                <Col>
                  {userData &&
                    (userData?.gender ? _onConvertGender(userData?.gender) : "-")}
                </Col>
              </Row>
              <Row>
                <Col>ວັນເດືອນປີເກີດ</Col>
                <Col>
                  {userData &&
                    (userData?.birthday
                      ? formatDate(userData?.birthday) : "-")}
                </Col>
              </Row>
            </div>
          </div>
        </div>

      </CustomContainer>
      <Modal
        show={showDeleteConfirmModal}
        onHide={_handleDeleteConfirmModalClose}
        size='lg'
      >
        <Modal.Body style={{padding: 50}}>
          <Modal.Title
            style={{
              textAlign: 'center',
              paddingTop: 20,
              color: Consts.BORDER_COLOR_DELETE,
              fontWeight: 'bold'
            }}
          >
            ທ່ານຕ້ອງການລຶບແທ້ບໍ່?
          </Modal.Title>
          <Row className="pt-5">
            <Col>
              <Button
                onClick={_handleDeleteConfirmModalClose}
                style={{
                  width: '100%',
                  backgroundColor: '#fff',
                  color: '#6f6f6f',
                  borderColor: Consts.DELETE_COLOR_BUTTON
                }}
              >
                ຍົກເລີກ
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  width: '100%',
                  backgroundColor: Consts.DELETE_COLOR_BUTTON,
                  color: '#fff',
                  borderColor: Consts.DELETE_COLOR_BUTTON
                }}
                onClick={_deleteConfirm}
              >
                ລຶບ
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CodePreregistrationDetail