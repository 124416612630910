import React, { useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { CustomButton } from '../../../common'
import { valiDate, alertWarning } from '../../../common/super'
import { Modal, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import * as _ from 'lodash'
import { UPDATE_CLASSROOM } from "../../../apollo/classRoom/index";
import { useSelector } from 'react-redux'
import { DEPARTMENTS, FACULTIES } from '../apollo/query'
import { MAJORS } from '../../../apollo/major'



const EditClassRoom = ({ showEditClassRoom, _handleEditClassRoomClose, allData }) => {

  const accessmentRedux = useSelector((state) => state.accessment.value)
  const [updateClassRoom] = useMutation(UPDATE_CLASSROOM)
  const [departmentData, setDepartmentData] = useState([])
  const [majorData, setMajorData] = useState([])

  const [loadFaculty, { data: facultyDatas }] = useLazyQuery(FACULTIES);
  const [loadDepartment, { data: dapartmentApollo }] = useLazyQuery(DEPARTMENTS);
  const [loadMajor, { data: majorApollo }] = useLazyQuery(MAJORS);

  // on first load
  useEffect(() => {
    loadFaculty({
      variables: {
        where: {
          isDeleted: false,
          assessmentYear: accessmentRedux
        }
      }
    });
    loadDepartment({
      variables: {
        where: {
          isDeleted: false,
          faculty: allData?.faculty?.id
        }
      }
    })
    loadMajor({
      variables: {
        where: {
          isDeleted: false,
          department: allData?.department?.id
        }
      }
    })
  }, [accessmentRedux, allData]);
  useEffect(() => {
    if (dapartmentApollo) {
      setDepartmentData(dapartmentApollo?.departments)
    }
    if (majorApollo) {
      setMajorData(majorApollo?.majors?.data)
    }
  }, [dapartmentApollo, majorApollo])

  const _saveUpdateClassRoom = data => {
    // return
    updateClassRoom({
      variables: {
        data: { ...data },
        where: { id: allData?.id }
      }
    })
      .then(async () => {
        // _handleEditClassRoomClose()
        window.location.reload(true)
      })
      .catch(err => {
        _handleEditClassRoomClose()
        alertWarning('ການແກ້ໄຂລົ້ມແຫຼວ')
      })
  }

  const _onSelectFaculty = (e) => {
    if (e.target.value === "") {
      setDepartmentData([])
      setMajorData([])
    } else {
      setMajorData([])

      loadDepartment({
        variables: {
          where: {
            isDeleted: false,
            faculty: e.target.value
          }
        }
      })

    }
  }
  const _onSelectDeparment = (e) => {
    if (e.target.value === "") {
      setMajorData([])
    } else {
      loadMajor({
        variables: {
          where: {
            isDeleted: false,
            department: e.target.value
          }
        }
      })

    }
  }

  return (
    <Modal show={showEditClassRoom} onHide={_handleEditClassRoomClose} size='lg'>
      <Formik
        initialValues={{
          faculty: allData?.faculty?.id ?? '',
          department: allData?.department ? allData?.department?.id : '',
          major: allData?.major?.id ?? '',
          name: allData?.name ?? "",
          year: allData?.year ?? "",
          note: allData?.note ?? "",
        }}
        validate={values => {
          const errors = {}
          if (!values.faculty) {
            errors.faculty = 'ກະລຸນາເລືອກຄະນະ'
          }
          if (!values.department) {
            errors.department = 'ກະລຸນາເລືອກພາກ'
          }
          if (!values.major) {
            errors.major = 'ກະລຸນາເລືອກສາຂາ'
          }
          if (!values.name) {
            errors.name = 'ກະລຸນາປ້ອນຊື່ຫ້ອງນັກສຶກສາ'
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          let data = {
            faculty: values.faculty,
            department: values.department,
            major: values.major,
            name: values.name,
            year: values.year,
            note: values.note,
          }
          _saveUpdateClassRoom(data)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
          /* and other goodies */
        }) => (
          <div>
            <Modal.Title style={{ textAlign: 'center', paddingTop: 20 }}>
              ແກ້ໄຂຫ້ອງຮຽນ
            </Modal.Title>
            <Modal.Body
              style={{ marginLeft: 50, marginRight: 50, padding: 50 }}
            >
              <div className='modal-body'>
                <label>ຄະນະ :{valiDate()}</label>
                <Form.Control
                  as='select'
                  name='faculty'
                  onChange={(e) => {
                    handleChange(e)
                    _onSelectFaculty(e)
                  }}
                  value={values.faculty}
                  isInvalid={!!errors.faculty}
                >
                  <option value={""}>---ກະລຸນາເລືອກຄະນະ---</option>
                  {
                    facultyDatas?.faculties.map((fac, index) => (
                      <option key={"faculty" + index} value={fac?.id}>{fac?.name}</option>
                    )
                    )
                  }
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.faculty}
                </Form.Control.Feedback>
                <label>ພາກວິຊາ :{valiDate()}</label>
                <Form.Control
                  as='select'
                  name='department'
                  onChange={(e) => {
                    handleChange(e)
                    _onSelectDeparment(e)
                  }}
                  value={values.department}
                  isInvalid={!!errors.department}
                >
                  <option value={""}>---ກະລຸນາເລືອກພາກວິຊາ---</option>
                  {departmentData.map((dep, index) => (
                    <option key={"department" + index} value={dep?.id}>{dep?.name}</option>
                  )
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.department}
                </Form.Control.Feedback>
                <label>ສາຂາວິຊາ :{valiDate()}</label>
                <Form.Control
                  as='select'
                  name='major'
                  onChange={(e) => {
                    handleChange(e)
                    // _onSelectMajor(e)
                  }}
                  value={values.major}
                  isInvalid={!!errors.major}
                >
                  <option value={""}>---ກະລຸນາເລືອກສາຂາ---</option>
                  {majorData.map((major, index) => (
                    <option key={"major" + index} value={major?.id}>{major?.name}</option>
                  )
                  )}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.major}
                </Form.Control.Feedback>
                <label>ຊື່ຫ້ອງນັກສຶກສາ :{valiDate()}</label>
                <Form.Control
                  type='text'
                  name='name'
                  onChange={handleChange}
                  value={values.name}
                  isInvalid={!!errors.name}
                  placeholder='ກະລຸນາປ້ອນຫ້ອງນັກສຶກສາ'
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
                <label>ປີ :{valiDate()}</label>
                <Form.Control
                  as='select'
                  name='year'
                  onChange={handleChange}
                  value={values.year}
                  isInvalid={!!errors.year}
                >
                  <option disabled={true} value="">---ກະລຸນາເລືອກປີ---</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </Form.Control>
                <label>ໝາຍເຫດ</label>
                <textarea
                  className='form-control'
                  name='note'
                  onChange={handleChange}
                  value={values.note}
                  placeholder='ກະລຸນາປ້ອນໝາຍເຫດ(ຖ້າມີ)'
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <CustomButton title='ຍົກເລິກ' onClick={_handleEditClassRoomClose} />
              <CustomButton onClick={handleSubmit} title='ບັນທຶກ' confirm />
            </Modal.Footer>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default EditClassRoom
