import { gql } from "apollo-boost";

export const STATISTICES_ALL = gql`
  query StatisticsAll($where: StatisticsAllInput) {
    statisticsAll(where: $where) {
      total
      success
      noSuccess
    }
  }
`;
export const STATISTICES = gql`
  query Statistics($where: StatisticsInput) {
    statistics(where: $where) {
      major
      total
      success
    }
  }
`;
