exports.FACULTY = [
  {
    id: "5f855802027439000710ccc6",
    vId: 21,
    name: "ສະຖາປັດຕະຍະກຳສາດ",
    departments: [
      {
        vId: 26,
        id: "5d8c8bc424aa9a0007208b0e",
        name: "ສະຖາປັດຕະຍະກຳ​ສາດ",
      },
      {
        vId: 27,
        id: "5d8c8bc424aa9a0007208b0f",
        name: "ວາງ​ແຜນ​ສະພາບ​ແວດ​ລ້ອມ-ຜັງ​ເມືອງ",
      },
      {
        vId: 28,
        id: "5d8c8bc424aa9a0007208b10",
        name: "ວິທະຍາ​ການ​ອາຄານ",
      },
      {
        vId: 29,
        id: "5f86dcb1027439000710cd4f",
        name: "ອອກແບບຕົກແຕ່ງ",
      },
      {
        vId: 30,
        id: "5f86dcd7027439000710cd50",
        name: "ອອກແບບຜະລິດຕະພັນ",
      },
      {
        vId: 31,
        id: "5f86dd20027439000710cd51",
        name: "ສິ່ງແວດລ້ອມ-ຜັງເມືອງ",
      },
    ],
  },
  {
    id: "5f855802027439000710ccc7",
    vId: 22,
    name: "ວິສະວະກຳສາດ",
    departments: [
      {
        vId: 115,
        name: "ວິສະວະກຳ ຂົວ-ທາງ ແລະ​ຂົນ​ສົ່ງ",
      },
      {
        vId: 68,
        id: "5d8c8bc424aa9a0007208b12",
        name: "ວິສະວະກຳກົນຈັກ",
      },
      {
        vId: 69,
        id: "5f86de05027439000710cd52",
        name: "ວິສະວະກຳ ອຸດສະຫະກຳ",
      },
      {
        vId: 70,
        id: "5f86de86027439000710cd53",
        name: "ວິສະວະກຳ ເຄມີ",
      },
      {
        vId: 71,
        id: "5d8c8bc424aa9a0007208b11",
        name: "ວິສະວະກຳ ໂຍທາ",
      },
      {
        vId: 72,
        id: "5f86def0027439000710cd54",
        name: "ວິສະວະກຳ ສິ່ງແວດລ້ອມ",
      },
      {
        vId: 73,
        id: "5f86df51027439000710cd55",
        name: "ວິສະວະກຳ ບໍ່ແຮ່",
      },
      {
        vId: 74,
        id: "5d8c8bc424aa9a0007208b13",
        name: "ວິສະວະກຳໄຟຟ້າ",
      },
      {
        vId: 75,
        id: "5f86df7f027439000710cd56",
        name: "ວິສະວະກຳ ໄຟຟ້າພະລັງງານນ້ຳ",
      },
      {
        vId: 76,
        id: "5f86df98027439000710cd57",
        name: "ວິສະວະກຳ ຄອມພິວເຕີ",
      },
      {
        vId: 77,
        id: "5d8c8bc424aa9a0007208b15",
        name: "ເຕັກໂນໂລຊີ ຂໍ້ມູນຂ່າວສານ",
      },
      {
        vId: 78,
        id: "5f86e003027439000710cd58",
        name: "ວິສະວະກຳ ເອເລັກໂຕຣນິກ",
      },
      {
        vId: 79,
        id: "5f86e029027439000710cd59",
        name: "ວິສະວະກຳ ໂທລະຄົມມະນາຄົມ",
      },
      {
        vId: 80,
        id: "5f86e04d027439000710cd5a",
        name: "ວິສະວະກຳ ຂົນສົ່ງ ແລະ ພາລາທິການ",
      },
      {
        vId: 82,
        id: "5f86e07b027439000710cd5b",
        name: "ວິສະວະກຳ ຂົວ-ທາງ",
      },
      {
        vId: 109,
        id: "5f86e090027439000710cd5c",
        name: "ວິສະວະກຳ ຂະບວນການແປຮູບອາຫານ",
      },
      {
        vId: 110,
        id: "5f86e0a4027439000710cd5d",
        name: "ວິສະວະກຳ ວັດສະດຸສາດ",
      },
    ],
  },
  {
    id: "5f855803027439000710ccc8",
    vId: 15,
    name: "ເສດຖະສາດ ແລະ ບໍລິຫານທຸລະກິດ",
    departments: [
      {
        vId: 42,
        id: "5f86e121027439000710cd5e",
        name: "ບໍລິການທຸລະກິດທົ່ວໄປ",
      },
      {
        vId: 43,
        id: "5f86e155027439000710cd5f",
        name: "ການຕະຫຼາດ",
      },
      {
        vId: 44,
        id: "5d8c8bc424aa9a0007208305",
        name: "ການ​ບັນຊີ",
      },
      {
        vId: 45,
        id: "5f86e26e027439000710cd66",
        name: "ການກວດສອບ",
      },
      {
        vId: 46,
        id: "5f86e25a027439000710cd65",
        name: "ພາສີ ແລະ ອາກອນ",
      },
      {
        vId: 47,
        id: "5d8c8bc424aa9a0007208304",
        name: "ການ​ເງິນ-ການ​ທະນາຄານ",
      },
      {
        vId: 48,
        id: "5d8c8bc424aa9a0007208302",
        name: "ການ​ຄ້າ",
      },
      {
        vId: 49,
        id: "5f86e1e2027439000710cd60",
        name: "ເສດຖະສາດພາກລັດ",
      },
      {
        vId: 50,
        id: "5f86e1fe027439000710cd61",
        name: "ເສດຖະສານການພັດທະນາ ແລະ ວາງແຜນ",
      },
      {
        vId: 51,
        id: "5f86e213027439000710cd62",
        name: "ເສດຖະສາດນຳໃຊ້",
      },
      {
        vId: 117,
        id: "5f86e231027439000710cd63",
        name: "ບັນຊີ-ການເງິນ",
      },
      {
        vId: 118,
        id: "5f86e244027439000710cd64",
        name: "ການເງິນພາກລັດ",
      },
    ],
  },
  {
    id: "5f86d8f6027439000710cd46",
    vId: 17,
    name: "ວິທະຍາສາດສິ່ງແວດລ້ອມ",
    departments: [
      {
        vId: 64,
        id: "5f86e2b8027439000710cd67",
        name: "ວິທະຍາສາດສິ່ງແວດລ້ອມ",
      },
      {
        vId: 65,
        id: "5f86e2cd027439000710cd68",
        name: "ວິທະຍາສາດສິ່ງແວດລ້ອມ",
      },
      {
        vId: 66,
        id: "5f86e36b027439000710cd69",
        name: "ການວາງແຜນພັດທະນາ",
      },
      {
        vId: 67,
        id: "5f86e37b027439000710cd6a",
        name: "ວິທະຍາສາດພື້ນຖານ",
      },
    ],
  },
  {
    id: "5f855803027439000710ccca",
    vId: 13,
    name: "ນິຕິສາດ ແລະ ລັດຖະສາດ",
    departments: [
      {
        vId: 1,
        id: "5f86e3d8027439000710cd6b",
        name: "ການຕ່າງປະເທດ",
      },
      {
        vId: 2,
        id: "5d8c8bc424aa9a0007208501",
        name: "ກົດໝາຍແພ່ງ",
      },
      {
        vId: 3,
        id: "5d8c8bc424aa9a0007208502",
        name: "ກົດໝາຍອາຍາ",
      },
      {
        vId: 4,
        id: "5f86e3f6027439000710cd6c",
        name: "ການປົກຄອງ",
      },
      {
        vId: 5,
        id: "5d8c8bc424aa9a0007208505",
        name: "ກົດໝາຍທຸລະກິດ",
      },
    ],
  },
  {
    id: "5f855804027439000710cccb",
    vId: 16,
    name: "ກະເສດສາດ",
    departments: [
      {
        vId: 85,
        id: "5d8c8bc424aa9a0007208601",
        name: "ປູກຝັງ",
      },
      {
        vId: 86,
        id: "5f86e4c3027439000710cd6f",
        name: "ປ້ອງກັນພຶດ",
      },
      {
        vId: 87,
        id: "5f86e4b6027439000710cd6e",
        name: "ການປະມົງ",
      },
      {
        vId: 88,
        id: "5f86e4a9027439000710cd6d",
        name: "ການລ້ຽງສັດ",
      },
      {
        vId: 89,
        id: "5d8c8bc424aa9a0007208604",
        name: "ສັດຕະວະເພດ",
      },
      {
        vId: 90,
        id: "5d8c8bc424aa9a0007208603",
        name: "ເສດຖະກິດ​ກະສິກຳ ​ແລະ ​ເຕັກ​ໂນ​ໂລ​ຊີ​ອາຫານ",
      },
    ],
  },

  {
    id: "5f855804027439000710cccc",
    vId: 14,
    name: "ສຶກສາສາດ",
    departments: [
      { vId: 52, id: "5d8c8bc424aa9a0007208701", name: "ບໍລິຫານການສຶກສາ" },
      { vId: 53, id: "5f86e55f027439000710cd70", name: "ຄະນິດສາດ" },
      { vId: 54, id: "5f86e573027439000710cd71", name: "ເຄມີສາດ" },
      { vId: 55, id: "5f86e593027439000710cd72", name: "ຟີຊິກສາດ" },
      { vId: 56, id: "5f86e5a7027439000710cd73", name: "ຊີວະສາດ" },
      { vId: 57, id: "5f86e5b4027439000710cd74", name: "ການເມືອງ" },
      { vId: 58, id: "5f86e5c5027439000710cd75", name: "ພູມີສາດ" },
      { vId: 59, id: "5f86e5d8027439000710cd76", name: "ປະຫວັດສາດ" },
      { vId: 60, id: "5f86e5e5027439000710cd77", name: "ພາສາອັງກິດ" },
      { vId: 61, id: "5f86e5f3027439000710cd78", name: "ພາສາຝຣັ່ງ" },
      { vId: 62, id: "5f86e601027439000710cd79", name: "ພາສາລາວ-ວັນນະຄະດີ" },
      { vId: 63, id: "5f86e60e027439000710cd7a", name: "ຈິດຕະວິທະຍາການສຶກສາ" },
    ],
  },
  {
    id: "5f855804027439000710cccd",
    vId: 18,
    name: "ວິທະຍາສາດປ່າໄມ້",
    departments: [
      { vId: 113, id: "5f86e6e9027439000710cd7e", name: "ປ່າໄມ້ທົ່ວໄປ" },
      {
        vId: 103,
        id: "5f86e6da027439000710cd7d",
        name: "ການອະນຸລັກປ່າໄມ້ ແລະ ສັດປ່າ",
      },
      { vId: 20, id: "5f86e6c9027439000710cd7c", name: "ປ່າໄມ້" },
      {
        vId: 21,
        id: "5f86e6ba027439000710cd7b",
        name: "ເສດຖະສາດປ່າໄມ້ ແລະ ເຕັກໂນໂລຊີປຸງແຕ່ງໄມ້",
      },
      {
        vId: 22,
        id: "5d8c8bc424aa9a0007208802",
        name: "ຄຸ້ມ​ຄອງ​ຈັດ​ສັນ​ປ່າ​ໄມ້",
      },
      {
        vId: 23,
        id: "5d8c8bc424aa9a0007208803",
        name: "ຄຸ້ມ​ຄອງ​ຈັດ​ສັນ​ແຫ​ລ່ງນ້ຳ ​ແລະ​ ການ​ວາງ​ແຜນ​ນຳ​ໃຊ້​ທີ່​ດິນ",
      },
      { vId: 24, name: "ການທ່ອງທ່ຽວອະນຸຮັກ" },
      { vId: 25, name: "ການປ່າໄມ້ຊຸມຊົນ ແລະ ພັດທະນາຊົນນະບົດ" },
    ],
  },
  {
    id: "5f855805027439000710ccce",
    vId: 12,
    name: "ອັກສອນສາດ",
    departments: [
      {
        vId: 94,
        id: "5d8c8bc424aa9a0007208901",
        name: "ພາສາ​ອັງກິດ",
      },
      {
        vId: 91,
        id: "5f86e795027439000710cd7f",
        name: "ພາສາລາວ-ວັນນະຄະດີ",
      },
      {
        vId: 92,
        id: "5d8c8bc424aa9a0007208909",
        name: "ສື່ສານ​ມວນ​ຊົນ",
      },
      {
        vId: 93,
        id: "5d8c8bc424aa9a0007208903",
        name: "ວິຊາພາສາຝຣັ່ງ",
      },
      {
        vId: 95,
        id: "5d8c8bc424aa9a0007208906",
        name: "ພາສາ​ຫວຽດນາມ",
      },
      {
        vId: 96,
        id: "5d8c8bc424aa9a0007208905",
        name: "ພາສາ​ຍີ່ປຸ່ນ",
      },
      {
        vId: 97,
        id: "5d8c8bc424aa9a0007208904",
        name: "ພາສາ​ຈີນ",
      },
      {
        vId: 98,
        id: "5d8c8bc424aa9a0007208908",
        name: "ພາສາ​ເກົາຫລີ",
      },
      {
        vId: 99,
        id: "5d8c8bc424aa9a0007208907",
        name: "ພາສາເຢຍລະ​ມັນ",
      },
      {
        vId: 100,
        id: "5f86e7bc027439000710cd80",
        name: "ພາສາລັດເຊຍ",
      },
      {
        vId: 101,
        id: "5f86e7ca027439000710cd81",
        name: "ພາສາແອັດສະປາຍໂຍນ",
      },
      {
        vId: 122,
        id: "5f86e7d7027439000710cd82",
        name: "ພາສາຝຣັ່ງສຳລັບການທ່ອງທ່ຽວ",
      },
    ],
  },
  {
    id: "5f855805027439000710cccf",
    vId: 20,
    name: "ວິທະຍາສາດທຳມະຊາດ",
    departments: [
      {
        vId: 6,
        id: "5f86e858027439000710cd83",
        name: "ຄະນິດສາດນຳໃຊ້ ສຳລັບເສດຖະສາດ ແລະ ບໍລິຫານທຸລະກິດ",
      },
      {
        vId: 7,
        id: "5f86e86b027439000710cd84",
        name: "ສະຖິຕິ",
      },
      {
        vId: 8,
        id: "5d8c8bc424aa9a0007201004",
        name: "ຄະນິດສາດ",
      },
      {
        vId: 9,
        id: "5d8c8bc424aa9a0007201003",
        name: "ຟີ​ຊິກ​ສາດ",
      },
      {
        vId: 10,
        id: "5f86e87a027439000710cd85",
        name: "ທໍລະນີຟີຊິກນຳໃຊ້",
      },
      {
        vId: 11,
        id: "5f86e88f027439000710cd86",
        name: "ວັດສະດຸສາດ",
      },
      {
        vId: 12,
        id: "5f86e89d027439000710cd87",
        name: "ຟີຊິກນິວເຄຼຍ",
      },
      {
        vId: 6,
        id: "5d8c8bc424aa9a0007201002",
        name: "ເຄມີ​ສາດ",
      },
      {
        vId: 13,
        id: "5f86e8c8027439000710cd88",
        name: "ເຄມີສິ່ງແວດລ້ອມ",
      },
      {
        vId: 14,
        id: "5f86e8d5027439000710cd89",
        name: "ຊີວະ​ວິທະຍາ",
      },
      {
        vId: 15,
        id: "5f86e8e4027439000710cd8a",
        name: "ເຕັກໂນໂລຊີ ຊີວະພາບ",
      },
      {
        vId: 16,
        id: "5d8c8bc424aa9a0007201001",
        name: "ຊີວະ​ວິທະຍາ",
      },
      {
        vId: 17,
        id: "5d8c8bc424aa9a0007201005",
        name: "ວິທະຍາສາດ​ຄອມ​ພີ​ວ​ເຕີ",
      },
      {
        vId: 18,
        id: "5f86e8f3027439000710cd8b",
        name: "ພັດທະນາເວບໄຊ",
      },
      {
        vId: 19,
        id: "5f86e900027439000710cd8c",
        name: "ການພັດທະນາໂປຣແກຣມຄອມພິວເຕີ",
      },
    ],
  },

  {
    id: "5f855806027439000710ccd0",
    vId: 11,
    name: "ວິທະຍາສາດສັງຄົມ",
    departments: [
      {
        vId: 32,
        id: "5d8c8bc424aa9a0007201104",
        name: "ພູມີສາດ ​ແລະ ​ສາລະ​ສົນ​ເທດ​ສາດ",
      },
      {
        vId: 33,
        id: "5f86e969027439000710cd8d",
        name: "ບັນນາຮັກສາດ ແລະ ສາຣະສົນເທດສາດ",
      },
      {
        vId: 34,
        id: "5f86ea09027439000710cd92",
        name: "ປະຫວັດສາດ ແລະ ມານຸດວິທະຍາ",
      },
      {
        vId: 37,
        id: "5f86e988027439000710cd8f",
        name: "ການ​ເມືອງ ​ແລະ​ ບໍລິຫານ​ລັດຖະກິດ",
      },
      {
        vId: 41,
        id: "5f86e996027439000710cd90",
        name: "ສັງຄົມ​ສົງ​ເຄາະ ​ແລະ ​ການ​ພັດທະນາ",
      },
      {
        vId: 34,
        id: "5f86e9a4027439000710cd91",
        name: "ປະຫວັດສາດ ​ແລະ ​ການ​ຄຸ້ມ​ຄອງ​ມໍລະດົກ",
      },
      {
        vId: 38,
        id: "5d8c8bc424aa9a0007201105",
        name: "ການທ່ອງທ່ຽວ ​ແລະ ​ໂຮງ​ແຮມ",
      },
      {
        vId: 36,
        id: "5f86ea5e027439000710cd93",
        name: "ບູຮານວິທະຍາ ແລະ ການຄຸ້ມຄອງມໍລະດົກ",
      },
      {
        vId: 39,
        id: "5f86ea6b027439000710cd94",
        name: "ການໂຮງແຮມ",
      },
    ],
  },
  {
    id: "5f855806027439000710ccd1",
    vId: 23,
    name: "ຊັບພະຍາກອນນ້ຳ",
    departments: [
      {
        vId: 38,
        id: "5f86f7cb027439000710cd95",
        name: "ວິສະວະກຳ ຊົນລະປະທານ",
      },
      {
        vId: 84,
        id: "5f86f809027439000710cd96",
        name: "ການຄຸ້ມຄອງ ຊັບພະຍາກອນນ",
      },
      {
        vId: 111,
        id: "5f86f81d027439000710cd97",
        name: "ອຸຕຸນິຍົມ ແລະ ອຸທົບກະສາດ",
      },
      {
        vId: 112,
        id: "5f86f852027439000710cd98",
        name: "ເຕັກນິກຊົນລະປະທານ",
      },
    ],
  },
  {
    id: "5f855806027439000710ccd2",
    vId: 19,
    name: "ວິທະຍາສາດການກິລາ - ກາຍະກຳ",
    departments: [
      {
        vId: 102,
        id: "5d8c8bc424aa9a0007201301",
        name: "ສ້າງ​ຄູ​ພະລະສຶກສາ",
      },
      {
        vId: 104,
        id: "5f86fa97027439000710cd9a",
        name: "ຄູເຝີກແລ່ນ-ລານ",
      },
      {
        vId: 105,
        id: "5f86fa87027439000710cd99",
        name: "ຄູເຝີກບານເຕະ",
      },
      {
        vId: 106,
        id: "5d8c8bc424aa9a0007201303",
        name: "ວິທະຍາສາດການກີລາ",
      },
      {
        vId: 107,
        id: "5f86faaf027439000710cd9b",
        name: "ຄູເຝີກບານສົ່ງ",
      },
      {
        vId: 108,
        id: "5d8c8bc424aa9a0007201304",
        name: "ບິ​ລິ​ຫານການກີລາ",
      },
      {
        vId: 114,
        id: "5f86face027439000710cd9c",
        name: "ຄູພະລະສຶກສາ",
      },
    ],
  },
  { id: "5f86da37027439000710cd47", vId: 24, name: "ຫ້ອງການ ວິຊາການ" },
  { id: "5f86da57027439000710cd48", vId: 25, name: "ຫ້ອງການ ສັງລວມ" },
  { id: "5f86da6c027439000710cd49", vId: 26, name: "ຫ້ອງການ ຈັດຕັ້ງ" },
  { id: "5f86da7f027439000710cd4a", vId: 27, name: "ຫ້ອງການຄຸ້ມຄອງ ນສ" },
  { id: "5f86da92027439000710cd4b", vId: 28, name: "ຫ້ອງການພົວພັນຕ່າງປະເທດ" },
  {
    id: "5f86dabb027439000710cd4c",
    vId: 29,
    name: "ທະນະຄານການຄ້າຕ່າງປະເທດລາວ",
  },
];
