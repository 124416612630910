import React, { useState, useEffect } from "react";
/**
 *
 * @Library
 *
 */
 import useReactRouter from "use-react-router";
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 import * as moment from "moment";
 import { useLazyQuery } from '@apollo/react-hooks';
/**
 *
 * @Component
 *
 */
 import { Breadcrumb, OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
 import CourseDocUpload from "./CourseDocUpload";
 import CourseDocEdit from "./CourseDocEdit";
 import CourseDocDeleteConfirm from "./CourseDocDeleteConfirm";
import { CustomContainer, Title, TableHeader, TableCell } from "../../common";

/**
 *
 * @Constant
 *
 */
 import Consts from "../../consts";
 import Routers from "../../consts/router";

 /**
 *
 * @Apollo
 *
 */
import { QUERY_FILE } from "../../apollo/doc";

/**
 *
 * @Function
 *
 */
import "moment/locale/lo";
moment.locale("lo");

function CourseDocList() {
  const { history, location, match } = useReactRouter();
  const courseId = match?.params?.id;

  // State
  const [courseDocUploadModal, setCourseDocUploadModal] = useState(false);
  const [courseDocEditModal, setCourseDocEditModal] = useState(false);
  const [courseDocDeleteConfirmModal, setCourseDocDeleteConfirmModal] =
    useState(false);

  const [courseData, setCourseData] = useState(null);
  const [dataDelete, setDataDelete] = useState({});
  const [dataEdit, setDataEdit] = useState({});
  const [courseFiles, setCourseFiles] = useState([])

  const [loadCourseFiles, { data: courseFiledata }] = useLazyQuery(QUERY_FILE);

  // Set states
  const _handlCourseDocUploadModalClose = () => setCourseDocUploadModal(false);
  const _handlCourseDocUploadModalShow = () => setCourseDocUploadModal(true);
  const _handlCourseDocEditModalClose = () => setCourseDocEditModal(false);
  const _handlCourseDocEditModalShow = () => setCourseDocEditModal(true);
  const _handlCourseDocDeleteConfirmModalClose = () =>
    setCourseDocDeleteConfirmModal(false);
  const _handlCourseDocDeleteConfirmModalShow = () =>
    setCourseDocDeleteConfirmModal(true);

  useEffect(() => {
    loadCourseFiles({
      fetchPolicy: "network-only",
      variables: { where: { course: courseId } },
    });
  }, [courseId])
  
  useEffect(() => {
    setCourseData(location.state);
  }, [location.state]);

  useEffect(() => {
    if(courseFiledata) {
      console.log(courseFiledata)
      setCourseFiles(courseFiledata?.files?.data)
    }
  }, [courseFiledata])

  // useEffect(() => {
  //   console.log("coursefile", courseFiles)
  // }, [courseFiles])

  const _courseDetail = () => {
    history.push(Routers.COURSE_DETAIL+'/id/'+courseId, location.state);
    // window.location.reload(true)
  };
  const _uploadFile = () => {
    _handlCourseDocUploadModalShow();
  };

  const _editFile = (dataEdit) => {
    setDataEdit(dataEdit)
    _handlCourseDocEditModalShow();
  };

  const _removeFile = async (index, courseData, dataDelete) => {
  // const _removeFile = async (index, dataDelete) => {
    // e.stopPropagation();
    await setDataDelete({
      index,
      courseData,
      dataDelete,
    });
    _handlCourseDocDeleteConfirmModalShow();
  };
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href={Routers.COURSE_LIST+Routers.PAGE_GINATION}>ຈັດການວິຊາ</Breadcrumb.Item>
        <Breadcrumb.Item href={Routers.COURSE_LIST+Routers.PAGE_GINATION}>ວິຊາທັງໝົດ</Breadcrumb.Item>
        <Breadcrumb.Item active>ເອກະສານບົດສອນ</Breadcrumb.Item>
      </Breadcrumb>

      {/* Container */}
      <CustomContainer>
        {/* --------- Title and Button groups ----------- */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title text=" dff" />

          {/* Button group */}
          <div>
            {/* ລາຍລະອຽດວິຊາ */}
            <OverlayTrigger
              overlay={<Tooltip id="tooltip-disabled">ເບິ່ງລາຍລະອຽດ</Tooltip>}
            >
              <button
                style={{
                  backgroundColor: "#fff",
                  color: Consts.SECONDARY_COLOR,
                  width: 160,
                  height: 35,
                  border: `1px solid ${Consts.PRIMARY_COLOR}`,
                  outline: "none",
                  marginRight: 20,
                  borderRadius: 5,
                }}
                onClick={() => _courseDetail()}
              >
                ລາຍລະອຽດວິຊາ
              </button>
            </OverlayTrigger>
            {/* ອັບໂຫລດບົດສອນ */}
            {/* <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">
                  ອັບໂຫລດເອກະສານຢູ່ໃນເຄື່ອງຂອງທ່ານ
                </Tooltip>
              }
            >
              <button
                style={{
                  backgroundColor: Consts.SECONDARY_COLOR,
                  color: "#fff",
                  width: 160,
                  height: 35,
                  border: "1px solid #ddd",
                  outline: "none",
                  borderRadius: 0,
                  borderRadius: 5,
                }}
                onClick={() => _uploadFile()}
              >
                <FontAwesomeIcon icon="download" style={{ fontSize: 16 }} />{" "}
                ອັບໂຫລດບົດສອນ
              </button>
            </OverlayTrigger> */}
          </div>
        </div>

        {/* -------- ຂໍ້ມູນວິຊາ ----------- */}
        <div style={{ marginTop: 10 }}>
          <div>ຂໍ້ມູນວິຊາ</div>
          {/* ------ detail box ------ */}
          <div
            style={{
              border: "1px solid #ddd",
              width: "60%",
              padding: 20,
              fontSize: 14,
              marginRight: "auto",
              marginLeft: "auto",
              marginTop: -10,
              paddingLeft: 80,
            }}
          >
            <Row>
              <Col>ຊື່ວິຊາ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {courseData && courseData.title}
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row>
              <Col>ລະຫັດວິຊາ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {courseData && courseData.courseCode}
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row>
              <Col>ຈໍານວນຫນ່ວຍກິດ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {courseData && courseData.unit}
              </Col>
            </Row>
          </div>
        </div>

        {/* ------ ເອກະສານທີ່ຖືກອັບໂຫລດ -------- */}
        <div style={{ marginTop: 20, marginBottom: 10 }}>
          ເອກະສານທີ່ຖືກອັບໂຫລດ
        </div>

        {/* ---------- table --------- */}
        <div>
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader>
                <th style={{ width: 70 }}>ລຳດັບ</th>
                <th style={{ width: 220 }}>ຫົວຂໍ້</th>
                <th>ໄຟລ</th>
                <th>ອັບເດດ</th>
                {/* <th style={{ width: 180 }}>ຈັດການ</th> */}
              </TableHeader>
            </thead>

            <tbody>
              {courseFiles?.map((x, index) => {
                return (
                  <tr
                    style={{
                      borderBottom: "2px solid #ffff",
                      textAlign: "center",
                    }}
                    key={index}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {x.title}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      <a href={x.file}>
                        {x.title} ({" "}
                        {x.file.split(".")[x.file.split(".").length - 1]} )
                      </a>
                    </TableCell>
                    <TableCell>
                      {moment(new Date(x.createdAt)).format("LLL")}
                    </TableCell>
                    {/* <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          onClick={(e) => _editFile(x)}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#FFFFFF",
                            padding: 3,
                            width: 64,
                            borderRadius: 4,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "edit"]}
                            color={Consts.BORDER_COLOR}
                          />{" "}
                        </div>
                        <div
                          // onClick={(e) => _removeFile(e, courseData, x)}
                          onClick={(e) => _removeFile(index, courseData, x)}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "#FFFFFF",
                            padding: 3,
                            width: 64,
                            borderRadius: 4,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["fas", "trash"]}
                            color={Consts.BORDER_COLOR_DELETE}
                          />{" "}
                        </div>
                      </div>
                    </TableCell> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* -------- Course doc upload modal ---------- */}
        {courseData && (
          <CourseDocUpload
            courseDocUploadModal={courseDocUploadModal}
            _handlCourseDocUploadModalClose={_handlCourseDocUploadModalClose}
            courseData={courseData}
            courseId={courseId}
          />
        )}

        {/* -------- Course doc edit modal ---------- */}
        <CourseDocEdit
          courseDocEditModal={courseDocEditModal}
          _handlCourseDocEditModalClose={_handlCourseDocEditModalClose}
          dataEdit={dataEdit}
          courseId={courseId}
        />

        {/* -------- Course delete confirm modal ---------- */}
        <CourseDocDeleteConfirm
          courseDocDeleteConfirmModal={courseDocDeleteConfirmModal}
          _handlCourseDocDeleteConfirmModalClose={
            _handlCourseDocDeleteConfirmModalClose
          }
          dataDelete={dataDelete}
          courseFiles={courseFiles}
        />
      </CustomContainer>
    </div>
  );
};

export default CourseDocList;
