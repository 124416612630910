import { gql } from "apollo-boost";

export const GRADES = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data{
        id
        semester
        assessmentYear
        yearLevel
        numberTest
        letterGrade
        course{
          id
          title
          isUpgrade
          teacher{
            id
            firstname
            lastname
            }
          }
        student{
          id
          studentNumber
          firstNameL
          lastNameL
          yearLevel
          }
        
       }
      total
    }
  }
`;
export const GRADES_FILTER = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data{
        id
        semester
        assessmentYear
        yearLevel
        numberTest
        course{
          id
          title
          isUpgrade
          }
        student{
          id
          studentNumber
          firstNameL
          lastNameL
          }
        teacher{
           id
           firstname
           lastname
          }
       }
      total
    }
  }
`;

export const UPGRADE_GRADES = gql`
    mutation UpgradeGrade(
    $where: GradeWhereUniqueInput!
  ) {
    upgradeGrade(where: $where) {
      id
    }
  }
`;
