import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import FilterListIcon from "@material-ui/icons/FilterList";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import Pagination from "@mui/material/Pagination";

import { SEARCH_BY_ABSTRACT_TITLE } from "./url";

import "./index.css";
import axios from "axios";
import { Typography } from "@material-ui/core";

export const ScopusPage = () => {
  const [selectedFilter, setSelectedFilter] = React.useState("");

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };
  const [keyword, setKeyword] = useState("");

  const onInputChange = (event) => {
    setKeyword(event.target.value);
  };

  const [result, setResult] = useState([]);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const onSearch = async () => {
    try {
      setLoading(true);
      setResult([]);
      const countPerPage = 10; // Number of results per page

      const start = (page - 1) * countPerPage; // Calculate the starting point

      const response = await axios.get(
        `https://api.elsevier.com/content/search/scopus?query=${keyword}&count=${countPerPage}&start=${start}&apiKey=716138752e96bfba2e969d44c25eac3d`
      );

      if (response.data && response.data["search-results"]) {
        // Access the 'search-results' field in the response
        const searchResults = response.data["search-results"];
        // Assuming 'entry' is an array within 'search-results'
        const entries = searchResults.entry;
        // Do something with 'entries'

        setResult(entries);
        setLoading(false);
      } else {
        console.log("No search results found.");
      }
    } catch (error) {
      console.log("Error:");
      console.error(error);
    }
  };
  const goToScopusPage = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("Scopus URL not found in the response.");
    }
  };

  useEffect(() => {
    if (keyword) onSearch();
  }, [page]);

  const handlePageChange = (event, page) => {
    setPage(page);
    // You can now use the "page" value to know the selected page.
    console.log("Selected Page:", page);
    // You can perform any actions you need with the selected page.
  };

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-md-8 col-lg-8">
          <div className="d-flex align-items-center w-100 pl-5 ml-3">
            <img className="scopus-logo" src="/scopus.png" alt="Scopus Logo" />
          </div>
          <div className="d-flex align-items-center bg-white px-2  mt-3 scopus-search">
            <div className="d-flex align-items-center mr-2">
              <IconButton onClick={onSearch} aria-label="search">
                <SearchIcon />
              </IconButton>
            </div>
            <InputBase
              value={keyword}
              // onKeyDown={onSearch}
              onChange={onInputChange}
              placeholder="ຄົ້ນຫາບົດຄວາມ"
              inputProps={{ "aria-label": "search" }}
              style={{ flex: 1 }}
            />
          </div>
        </div>

        {result.length > 0 && (
          <div className="mt-4">
            {/* <TableContainer>
              <Table>
                <TableHead style={{ background: "white", textAlign: "center" }}>
                  <TableRow>
                    <TableCell className="text-center bg-white">
                      ລຳດັບ
                    </TableCell>
                    <TableCell className="text-center bg-white px-3">
                      ຫົວຂໍ້
                    </TableCell>
                    <TableCell className="text-center bg-white">
                      ຜຸ້ຂຽນ
                    </TableCell>
                    <TableCell className="text-center bg-white">
                      ຊື່ສຳນັກພິມ
                    </TableCell>
                    <TableCell className="text-center bg-white">
                      ປະເພດ
                    </TableCell>
                    <TableCell className="text-center bg-white">
                      ລາຍລະອຽດ
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="text-center bg-white">
                        {(page - 1) * 10 + index + 1}
                      </TableCell>
                      <TableCell className="text-center bg-white">
                        {item["dc:title"]}
                      </TableCell>
                      <TableCell className="text-center bg-white">
                        {item["dc:creator"]}
                      </TableCell>
                      <TableCell className="text-center bg-white">
                        {item["prism:publicationName"]}
                      </TableCell>
                      <TableCell className="text-center bg-white">
                        {item["prism:aggregationType"]}
                      </TableCell>
                      <TableCell className="text-center bg-white">
                        <button
                          className="scopus-bt btn"
                          onClick={() => goToScopusPage(item.link[2]["@href"])}
                        >
                          ລາຍລະອຽດ
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> */}

            <div className="mb- mt-2">
              {result.map((item, index) => (
                <div key={index} className="bg-white mb-3 p-2 px-3">
                  <div className="row">
                    <h5
                      onClick={() => goToScopusPage(item.link[2]["@href"])}
                      className="scopus-link"
                    >
                      {"ຫົວຂໍ້: " + item["dc:title"]}
                    </h5>
                    <br></br>
                    <p> {"ຜູ້ຂຽນ: " + item["dc:creator"]}</p>
                    <br></br>
                    <p> {"ສຳນັກພິມ: " + item["prism:publicationName"]}</p>
                    <br></br>
                    <p> {"ຫມວດຫມຸ່: " + item["prism:aggregationType"]}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-3 mb-5 text-center d-flex justify-content-center w-100">
              <Pagination
                count={10}
                variant="outlined"
                page={page}
                onChange={handlePageChange}
              />
            </div>
          </div>
        )}

        {loading && (
          <div className="text-center mt-5 pt-5">
            <div>
              <CircularProgress />
            </div>
            <div className="mt-2">
              <Typography>ກຳລັງຄົ້ນຫາ...</Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
