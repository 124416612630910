export const formatDate = (createdAt) => {
  if (!createdAt) return ""; // Return empty string if createdAt is undefined

  const dateObj = new Date(createdAt);
  const day = dateObj.getDate(); // Get day of the month (1-31)
  const month = dateObj.getMonth() + 1; // Get month (0-11) and add 1 to convert it to (1-12)
  const year = dateObj.getFullYear(); // Get full year (e.g., 2024)

  // Get hours, minutes, and seconds
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();

  // Formatting the time to ensure leading zeroes if needed
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  // Return the formatted date string including the time
  return `${day}/${month}/${year} ${formattedTime}`;
};

export default formatDate;
