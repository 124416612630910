import { gql } from 'apollo-boost'

export const REGIS_FACULTY_DATE_HISTORIES = gql`
  query regisFacultyDateHistories(
    $where: RegisFacultyDateHistoryWhereInput
    $orderBy: RegisFacultyDateHistoryOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    regisFacultyDateHistories(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data{
        id
        startDateRegister
        endDateRegister
        createdBy{
            role
            firstname
            lastname
        }
        faculty{
            name
        }
        createdAt
      }
    }
  }
`;