const SCORE_STATUS = {
  TEACHER_SCORING: "ອາຈານໃຫ້ຄະເເນນ",
  TEACHER_SUBMITED: "ອາຈານຢືນຢັນຄະເເນນ",
  DEPARTMENT_SUBMITED: "ຳພາກວິຊາຢືນຢັນຄະແນນ",
  DEPARTMENT_REJECTED: "ພາກວິຊາປະຕິເສດຄະເເນນ",
  FACULTY_SUBMITED: "ຄະນະຢືນຢັນຄະແນນ",
  FACULTY_REJECTED: "ຄະນະປະຕິເສດຄະເເນນ",
  ADMIN_REJECTED: "ມຊ ປະຕິເສດຄະເເນນ",
};

const getScoreStatusColor = (status) => {
  switch (status) {
    case "TEACHER_SCORING":
      return "#00a8e8";
    case "TEACHER_SUBMITED":
    case "DEPARTMENT_SUBMITED":
    case "FACULTY_SUBMITED":
      return "#007f5f";
    case "DEPARTMENT_REJECTED":
    case "FACULTY_REJECTED":
    case "ADMIN_REJECTED":
      return "#bf4342";
    default:
      return "#CCCCCC"; // Replace with your default color
  }
};

const SCORE_STATUS_LIST = [
  { value: "TEACHER_SCORING", label: "ອາຈານໃຫ້ຄະເເນນ" },
  { value: "TEACHER_SUBMITED", label: "ອາຈານຢືນຢັນຄະເເນນ" },
  { value: "DEPARTMENT_SUBMITED", label: "ພາກວິຊາຢືນຢັນຄະແນນ" },
  { value: "DEPARTMENT_REJECTED", label: "ພາກວິຊາປະຕິເສດຄະເເນນ" },
  { value: "FACULTY_SUBMITED", label: "ຄະນະຢືນຢັນຄະແນນ" },
  { value: "FACULTY_REJECTED", label: "ຄະນະປະຕິເສດຄະເເນນ" },
  { value: "ADMIN_REJECTED", label: "ມຊ ປະຕິເສດຄະເເນນ" },
];

module.exports = {
  SCORE_STATUS,
  SCORE_STATUS_LIST,
  getScoreStatusColor,
};
