import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import * as _ from "lodash";

/**
 *
 * @Component
 *
 */
import { Breadcrumb, Button, Form, Table, Accordion, Card } from "react-bootstrap";
import { useSelector } from 'react-redux'
import { CustomContainer, Title, TableHeader } from "../../../common";
import Loading from "../../../common/Loading";
import EditDepartmentTimeRegister from "./EditDepartmentTimeRegister";

/**
 *
 * @Constant
 *
 */
import Consts from "../../../consts";

/**
 *
 * @Apollo
 *
 */
import { DEPARTMENT } from "../../../apollo/deparment";
import { REGIS_DEPARTMENT_DATE_HISTORIES } from "../../../apollo/regisDepartmentDateHistory";
/**
 *
 * @Function
 *
 */
import { formatDate, formatDateTime, dateTime } from "../../../common/super";
import { MAJORS } from "../../../apollo/major";

export default function SettingDepartmentDetail() {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  // Route
  const { history, location, match } = useReactRouter();

  // varible
  const facultyId = match.params.facultyId;
  const departmentId = match.params.departmentId;

  // State
  const [showUploadView, setShowUploadView] = useState(false);
  const [majorData, setMajorData] = useState([]);
  const [dataRegisDepartmentDateHistory, SetDataRegisDepartmentDateHistory] = useState([]);

  // Apollo
  let [loadDepartmentData, { loading, data }] = useLazyQuery(DEPARTMENT, { variables: { where: { id: departmentId } } });
  let [loadRegisDepartmentDateHistoryData, { loading: regisDepartmentDateHistoryLoading, data: regisDepartmentDateHistoryData }] = useLazyQuery(REGIS_DEPARTMENT_DATE_HISTORIES, {
    variables: {
      where: { department: departmentId },
      orderBy: "createdAt_DESC",
    },
  });

  let [loadMajor, { loading: loadingMajor, data: dataMajor }] = useLazyQuery(MAJORS, {
    variables: {
      where: {
        assessmentYear: accessmentRedux,
        department: departmentId,
        isDeleted: false
      },
    },
  });

  // use
  useEffect(() => {
    loadMajor();
    loadDepartmentData();
    loadRegisDepartmentDateHistoryData();
  }, []);

  useEffect(() => {
    if (dataMajor?.majors?.data) {
      setMajorData(dataMajor?.majors?.data);
    }
  }, [dataMajor]);

  useEffect(() => {
    SetDataRegisDepartmentDateHistory(regisDepartmentDateHistoryData?.regisDepartmentDateHistories);
  }, [regisDepartmentDateHistoryData]);

  // Function
  const _handleUploadViewClose = () => setShowUploadView(false);
  const _handleUploadViewShow = () => setShowUploadView(true);
  const _backToFaculty = () => history.push("/setting-faculty-detail/" + facultyId);

  const _toSettingMajorDetail = (id, name) => {
    history.push(`/setting-faculty-detail/${facultyId}/major/${id}`, {
      faculty: data?.department?.faculty?.name,
      department: data?.department?.name,
      major: name,
    });
  };

  // Loadding
  if (loading || regisDepartmentDateHistoryLoading || loadingMajor) return <Loading />;

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item href="" onClick={() => history.push("/setting-list")}>
          ເລືອກການຕັ້ງຄ່າ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push("/setting-faculty")}
        >
          ການລົງທະບຽນ
        </Breadcrumb.Item>
        <Breadcrumb.Item href="" onClick={() => _backToFaculty()}>
          {location?.state?.faculty?.name}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{data?.department?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        {/* title */}
        <Title text={data?.department?.name} />
        {/* body list */}
        {/* <div
          style={{
            width: "100%",
            minHeight: 300,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: 32,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid " + Consts.SECONDARY_COLOR,
              width: "65%",
              minHeight: 150,
              justifyContent: "center",
              paddingTop: 32,
              paddingBottom: 16,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                paddingLeft: 32,
              }}
            >
              <div style={{ width: "70%" }}>
                <h6>
                  <span style={{ fontWeight: "bold" }}>ຄະນະ: {"\t"}</span>
                  <span>{location?.state?.faculty?.name}</span>
                </h6>
                <h6>
                  <span style={{ fontWeight: "bold" }}>ສາຂາວິຊາ: {"\t"}</span>
                  <span>{data?.department?.name}</span>
                </h6>
                <h6>
                  <span style={{ fontWeight: "bold" }}>
                    ໄລຍະລົງທະບຽນ: {"\t"}
                  </span>
                  <span>
                    {formatDate(data?.department?.startDateRegister) ??
                      "ຍັງບໍ່ທັນຕັ້ງຄ່າ"}
                  </span>
                  {"\t"} ~ {"\t"}
                  <span>
                    {formatDate(data?.department?.endDateRegister) ??
                      "ຍັງບໍ່ທັນຕັ້ງຄ່າ"}
                  </span>
                </h6>
                <h6>
                  <span style={{ fontWeight: "bold" }}>
                    ອັບເດດລ່າສຸດ: {"\t"}
                  </span>
                  <span>
                    {formatDate(data?.department?.updatedAt)}{" "}
                    {dateTime(data?.faculty?.updatedAt) ?? "ຍັງບໍ່ທັນຕັ້ງຄ່າ"}
                    {"\t"} (ໂດຍ: {"\t"}
                    {data?.department?.updatedBy?.firstname
                      ? data?.department?.updatedBy?.firstname +
                      "  " +
                      (data?.department?.updatedBy?.lastname ?? "")
                      : data?.department?.createdBy?.firstname
                        ? data?.department?.createdBy?.firstname +
                        "  " +
                        (data?.department?.createdBy?.lastname ?? "")
                        : "ຍັງບໍ່ທັນຕັ້ງຄ່າ"}
                    )
                  </span>
                </h6>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "30%",
                  justifyContent: "flex-end",
                  paddingRight: 16,
                }}
              >
                <Button
                  style={{
                    border: "1px solid " + Consts.SECONDARY_COLOR,
                    backgroundColor: Consts.SECONDARY_COLOR,
                    color: "white",
                    width: "100%",
                    height: 40,
                  }}
                  onClick={() => _handleUploadViewShow()}
                >
                  ແກ້ໄຂ
                </Button>
              </div>
            </div>
            <div style={{ width: "100%", marginBottom: 0, marginTop: 32 }}>
              <Accordion>
                <Card style={{ border: 0 }}>
                  <Card.Header style={{ backgroundColor: "white" }}>
                    <Accordion.Toggle
                      as={Card}
                      style={{
                        color: Consts.SECONDARY_COLOR,
                        cursor: "pointer",
                        border: 0,
                        textDecoration: "underline",
                      }}
                      eventKey="0"
                    >
                      ເບິ່ງປະຫວັດການອັບເດດ
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {dataRegisDepartmentDateHistory?.data?.length > 0 ? (
                        <Table striped bordered>
                          <tr
                            style={{
                              backgroundColor: Consts.SECONDARY_COLOR,
                              color: "white",
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            <th style={{ width: 70 }}>#</th>
                            <th>ວັນທີເປີດໃຫ້ລົງທະບຽນ</th>
                            <th>ວັນທີສິ້ນສຸດໃຫ້ລົງທະບຽນ</th>
                            <th>ອັບເດດໂດຍ</th>
                            <th>ອັບເດດວັນທີ</th>
                          </tr>
                          <tbody>
                            {dataRegisDepartmentDateHistory?.data?.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td style={{ textAlign: "center" }}>
                                    {index + 1}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {formatDate(item?.startDateRegister)}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {formatDate(item?.endDateRegister)}
                                  </td>
                                  <td>
                                    {item?.createdBy?.firstname
                                      ? item?.createdBy?.firstname +
                                      "  " +
                                      (item?.createdBy?.lastname ?? "")
                                      : "-"}
                                  </td>
                                  <td>
                                    {formatDateTime(item?.createdAt)}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      ) : (
                        <p
                          style={{
                            color: "#bfbfbf",
                            fontSize: 18,
                            fontWeight: "bold",
                          }}
                        >
                          ຍັງບໍ່ເຄີຍມີການຕັ້ງຄ່າ
                        </p>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
        </div> */}
        {/* Major */}
        <h4 style={{ fontWeight: "bold" }}>ສາຂາວິຊາ</h4>
        <h6>ສາຂາວິຊາທັງໝົດ ({majorData?.length})</h6>
        <Table hover>
          <tr
            style={{
              backgroundColor: Consts.SECONDARY_COLOR,
              color: "white",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <th style={{ width: 70, textAlign: "center" }}>#</th>
            <th>ຊື່ສາຂາວິຊາ</th>
            <th>ວັນທີເປີດໃຫ້ລົງທະບຽນ</th>
            <th>ວັນທີສິ້ນສຸດໃຫ້ລົງທະບຽນ</th>
          </tr>
          <tbody>
            {majorData?.map((item, index) => (
              <tr
                key={index}
                onClick={() => _toSettingMajorDetail(item.id, item.name)}
                style={{
                  backgroundColor: "#f1f1f1",
                  borderBottomWidth: 2,
                  borderBottomColor: "white",
                  borderBottomStyle: "solid",
                }}
              >
                <td style={{ textAlign: "center" }}>{index + 1}</td>
                <td>{item?.name}</td>
                <td style={{ textAlign: "center" }}>
                  {formatDate(item?.startDateRegister) ?? "-"}
                </td>
                <td style={{ textAlign: "center" }}>
                  {formatDate(item?.endDateRegister) ?? "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CustomContainer>

      <EditDepartmentTimeRegister
        data={data?.department ?? ""}
        showUploadView={showUploadView}
        _handleUploadViewClose={_handleUploadViewClose}
        loadDepartmentData={loadDepartmentData}
      />
    </div>
  );
}
