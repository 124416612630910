import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { UPDATE_RELIGIONAL } from '../../../apollo/religionalMasters'
import { CustomButton } from '../../../common'
import { Modal, Form} from 'react-bootstrap'
import { valiDate } from '../../../common/super'
import { Formik } from 'formik'
const EditeReligional = ({
  showSearchView,
  _handleSearchViewClose,
  allData
}) => {
  const [updateReligional] = useMutation(UPDATE_RELIGIONAL)
  const _saveReligional = data => {
    updateReligional({
      variables: {
        data: {
          name: data.name,
          note: data.note
        },
        where: { id: allData?.id }
      }
    })
      .then(async () => {
        window.location.reload(true)
      })
      .catch(err => {})
  }
  return (
    <Modal show={showSearchView} onHide={_handleSearchViewClose} size='lg'>
      <Formik
        initialValues={{
          tribeName: allData?.name ?? "",
          note: allData?.note ?? ""
        }}
        validate={(values) => {
          const errors = {}
          if (!values.tribeName) {
            errors.tribeName = "Required";
          }

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          let data = {
            name: values.tribeName,
            note: values.note
          }
          _saveReligional(data)
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <div>
            <Modal.Title style={{ textAlign: 'center', paddingTop: 20 }}>
              ແກ້ໄຂສາສະໜາ
            </Modal.Title>
            <Modal.Body
              style={{ marginLeft: 50, marginRight: 50, padding: 50 }}
            >
              <label>ຊື່ສາສະໜາ {valiDate()}</label>
              <Form.Control
                type="text"
                className="form-control"
                name="tribeName"
                onChange={handleChange}
                value={values.tribeName}
                placeholder="ກະລຸນາປ້ອນຊື່ສາສະໜາ"
                // isInvalid={!!errors.tribeName}

              />

              <label>ໝາຍເຫດ</label>
              <textarea
                className='form-control'
                name='note'
                onChange={handleChange}
                value={values.note}
                placeholder='ກະລຸນາປ້ອນໝາຍເຫດ(ຖ້າມີ)'
              />
            </Modal.Body>
            <Modal.Footer>
              <CustomButton
                title='ຍົກເລີກ'
                onClick={() => _handleSearchViewClose()}
              />
              <CustomButton onClick={handleSubmit} title='ບັນທຶກ' confirm />
            </Modal.Footer>
          </div>
        )}
      </Formik>
    </Modal>
  )
}

export default EditeReligional
