import { NEW_API_URL, API_KEY } from "../../common/contrant";
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CustomMessage from "../../common/CustomStatusMessage";
import DialogContentText from "@mui/material/DialogContentText";
import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "../../redux/filtrSlice";
import Grid from "@mui/material/Grid";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import AddSubbjectDialog from "./AddSubjectDialog";
import { USER_KEY } from "../../consts";

const SubjectLists = () => {
  const dispatch = useDispatch();
  const [subjects, setSubjects] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [showComnfirm, setShowConfirm] = useState(false);

  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;

  const filterData = useSelector((state) => state.filter);

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [onSubmit, setOnSubmit] = useState(false);

  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleEdit = (item) => {
    setOpen(true);
    setSelectedItem(item);
  };

  useEffect(() => {
    if (onSubmit) {
      setOnSubmit(false);
      fetchData();
    }
  }, [onSubmit]);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleDelete = (id) => {
    setSelectedItem({
      id: id,
    });
    setShowConfirm(true);
  };

  const [count, setCount] = useState(0);

  const [formData, setFormData] = useState({
    faculty: filterData.faculty || "",
    department: filterData.department || "",
    major: filterData.major || "",
    educationLevel: filterData.educationLevel || "",
    title: "",
    type: true,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setPage(0);
  };

  // Fetch data from the API
  const fetchData = async () => {
    try {
      // Create a new URLSearchParams object and append parameters
      const apiUrl = `${NEW_API_URL}subject?limit=${rowsPerPage}&faculty=${
        formData.faculty
      }&department=${formData.department}&title=${
        formData.title
      }&educationLevel=${formData.educationLevel}&page=${page + 1}&type=${
        formData.type
      }`;

      const response = await axios.get(apiUrl, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.data;
      setCount(data?.count || 0);
      setSubjects(data?.data);
    } catch (error) {
      setSubjects([]);
    }
  };
  useEffect(() => {
    fetchData();
  }, [formData, rowsPerPage, page]); // Empty dependency array means this effect runs once on component mount

  useEffect(() => {
    let _filterData = {};
    if (formData.faculty !== "") {
      _filterData.faculty = formData.faculty;
    }

    // Check and set department if not null
    if (formData.department !== "") {
      _filterData.department = formData.department;
    }

    // Check and set major if not null
    if (formData.major !== "") {
      _filterData.major = formData.major;
    }
    // Fetch faculties, departments, and education levels from your backend API
    // Update the state variables (faculties, departments, educationLevels) accordingly
    // For simplicity, I'm using placeholder data here

    // Dispatch the setFilter action with the filterData payload
    dispatch(setFilter(_filterData));

    // Fetch faculties, departments, and education levels from your backend API
    // Update the state variables (faculties, departments, educationLevels) accordingly
    // For simplicity, I'm using placeholder data here

    // Fetch faculties
    axios
      .get(NEW_API_URL + "faculty", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setFaculties(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch departments
    axios
      .get(NEW_API_URL + "department/" + formData.faculty, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDepartments(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch education levels
    axios
      .get(NEW_API_URL + "educationlevel", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setEducationLevels(response.data))
      .catch((error) => console.error(error));
  }, [formData]);

  useEffect(() => {
    if (snackbarOpen) {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }
  }, [snackbarOpen]);

  const deleteSubject = async () => {
    try {
      if (selectedItem?.id) {
        await axios.delete(`${NEW_API_URL}subject?id=${selectedItem?.id}`, {
          headers: {
            api_key: API_KEY,
          },
        });
        showSnackbar("ລົບລາຍວິຊາສຳເລັດ", "success");
        setShowConfirm(false);
        fetchData();
      }
    } catch (err) {
      setShowConfirm(false);
      showSnackbar("ມີບັນຫາໃນການລົບວິຊາ", "error");
    }
  };
  return (
    <div className="row">
      <CustomMessage
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <Paper className="mt-3">
        <Dialog open={showComnfirm} onClose={() => setShowConfirm(false)}>
          <DialogTitle>ລົບລາຍວິຊາ</DialogTitle>
          <DialogContent>
            <DialogContentText>ຕ້ອງການລົບລາຍວິຊານີ້ແທ້ບໍ່?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowConfirm(false)} color="primary">
              ຍົກເລີກ
            </Button>
            <Button
              onClick={() => {
                deleteSubject();
              }}
              color="primary"
              autoFocus
            >
              ຢືນຢັນ
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>ເພິ່ມລາຍວິຊາໃຫມ່</DialogTitle>
          <DialogContent>
            <AddSubbjectDialog
              selectedItem={selectedItem || ""}
              onError={() => {
                showSnackbar("ມີບັນຫາໃນການເພີ່ມລາຍວິຊາ", "error");
              }}
              onSuccess={() => {
                setOpen(false);
                showSnackbar("ເພີ່ມລາຍວິຊາສຳເລັດ", "success");
              }}
              onSubmit={onSubmit}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              ຍົກເລີກ
            </Button>
            <Button
              onClick={() => {
                setOnSubmit(true);
              }}
              color="primary"
              autoFocus
            >
              ບັນທຶກ
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>

      <Paper elevation={0} className="bg-white mt-1 pb-5">
        <div className="px-4 text-start w-100 mt-4">
          <h3>ລາຍການວິຊາ</h3>
        </div>
        <Grid
          container
          className="d-flex justify-content-center px-4"
          spacing={2}
        >
          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="department">ພາກ</InputLabel>
              <Select
                label="ພາກ"
                name="department"
                value={formData?.department || ""}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ທັງຫມົດ
                </MenuItem>
                {departments.map((department) => (
                  <MenuItem key={department._id} value={department._id}>
                    {department?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="educationLevel">ລະດັບການສຶກສາ</InputLabel>
              <Select
                label="ລະດັບການສຶກສາ"
                name="educationLevel"
                value={formData?.educationLevel}
                onChange={handleChange}
              >
                <MenuItem key={""} value={""}>
                  ທັງຫມົດ
                </MenuItem>
                {educationLevels.map((educationLevel) => (
                  <MenuItem
                    key={educationLevel?._id}
                    value={educationLevel?._id}
                  >
                    {educationLevel?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="type">ປະເພດວິຊາ</InputLabel>
              <Select
                label="ປະເພດວິຊາ"
                name="type"
                value={formData?.type}
                onChange={handleChange}
              >
                <MenuItem key={false} value={false}>
                  ວິຊານອກ
                </MenuItem>
                <MenuItem key={true} value={true}>
                  ວິຊາໃນ
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth size="small" margin="normal">
              <TextField
                fullWidth
                name="title"
                onChange={handleChange}
                type="text"
                className="mt-2"
                variant="standard"
                placeholder="ຄົ້ນຫາ"
                InputProps={{
                  style: {
                    height: "33px",
                  },
                  startAdornment: <SearchIcon />,
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <Button
              onClick={() => {
                setOpen(true);
                setSelectedItem({});
              }}
              className="mt-3"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              ເພິ່ມໃຫມ່
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <div className="col-12 mb-5">
        <Paper elevation={0} className="bg-white pb-3 w-100">
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "#e9ecef" }}>
                <TableRow style={{ backgroundColor: "#e9ecef" }}>
                  <TableCell align="center">ລຳດັບ</TableCell>
                  <TableCell align="center">ຊື່ວິຊາ</TableCell>
                  <TableCell align="center">ລະຫັດວິຊາ</TableCell>
                  <TableCell align="center">ປະເພດວິຊາ</TableCell>
                  <TableCell align="center">ຫນ່ວຍກິດ</TableCell>
                  <TableCell align="center">ພາກ</TableCell>
                  <TableCell align="center">ລະດັບການສຶກສາ</TableCell>
                  <TableCell align="center">ຈັດການ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subjects.map((subject, index) => (
                  <TableRow key={subject._id}>
                    <TableCell align="center">
                      {index + rowsPerPage * (page - 1) + 1}
                    </TableCell>
                    <TableCell align="center">{subject?.title}</TableCell>
                    <TableCell align="center">{subject?.code}</TableCell>
                    <TableCell align="center">
                      {subject?.type ? (
                        <Typography
                          sx={{
                            color: "blue",
                          }}
                        >
                          ວິຊາໃນ
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "red",
                          }}
                        >
                          ວິຊານອກ
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="center">{subject?.credit}</TableCell>
                    <TableCell align="center">
                      {subject.department?.name}
                    </TableCell>
                    <TableCell align="center">
                      {subject.educationLevel?.name}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        aria-label="Edit"
                        onClick={() => handleEdit(subject)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        aria-label="Delete"
                        onClick={() => handleDelete(subject._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              <TablePagination
                rowsPerPageOptions={[10, 20, 50, 200]}
                colSpan={8} // Adjusted colSpan to include the Actions column
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

export default SubjectLists;
