import { USER_KEY } from "../../consts";
import React, { useEffect, useState, useMemo } from "react";
import DataFilter from "../../common/Filter";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/contrant";
import { useSelector } from "react-redux";
import "./index.css";
import { IconButton, Typography } from "@mui/material";
import CustomMessage from "../../common/CustomStatusMessage";
import LoadingDialog from "../../common/LoadingDialog";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CourseDetail from "./courseDetail";
import AddCourseModal from "./addCourseDialog";
import ConfirmModal from "../../common/ConfirmModal";
import Box from "@mui/material/Box";

const user = localStorage.getItem(USER_KEY);
const userObject = JSON.parse(user);
const token = userObject?.accessToken;

export const TimeTable = () => {
  const accessmentRedux = useSelector((state) => state.accessment.value);

  const onSelected = (data) => {
    setFilter(data);
  };

  const user = localStorage.getItem(USER_KEY);

  const [facId, setFacId] = useState("");
  const [depId, setDepId] = useState("");
  const [disableDep, setDisableDep] = useState(false);
  const [disableFact, setDisableFact] = useState(false);

  const [showDeleteCourse, setShowDeleteCourse] = useState();

  const [showAddCourse, setShowAddCourse] = useState(false);
  const [selectedTimeAndDay, setSelectedTimeAndDay] = useState();

  useEffect(() => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);

    if (userObject) {
      if (userObject.data.role === "DEP_ADMIN") {
        setDisableDep(true);
        setDepId(userObject.data.department.id);
        setFacId(userObject.data.faculty.id);
        setDisableFact(true);
      }
      if (userObject.data.role === "FAC_ADMIN") {
        setDisableDep(false);
        setDepId("");
        setFacId(userObject.data.faculty.id);
        setDisableFact(true);
      }
      if (userObject.data.role === "ADMIN") {
        setDisableDep(false);
        setDisableFact(false);
        setDepId("");
        setFacId("");
      }
    }
  }, [user]);

  const [filter, setFilter] = useState({});
  const [courses, setCourses] = useState([]);
  const [times, setTimes] = useState([]);

  const [addTableStatus, setAddTableStatus] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (addTableStatus.open) {
      setTimeout(() => {
        setAddTableStatus((prev) => ({ ...prev, open: false }));
      }, 2000);
    }
  }, [addTableStatus]);

  const fetchTimeTable = async () => {
    setIsLoading(true);
    if (filter?.class && filter?.semester) {
      try {
        const res = await axios.get(
          `${NEW_API_URL}timetable/${filter?.faculty}/${filter.department}/${filter.major}/${filter.class}/${filter.year}/${accessmentRedux}/${filter?.semester}`,
          {
            headers: {
              api_key: API_KEY,
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCourses(res.data.courses);

        // Step 1: Sort and remove duplicates
        const times = res.data?.times
          .sort((a, b) => parseInt(a.hour) - parseInt(b.hour))
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.hour === item.hour)
          );

        setTimes(times);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const addTimeTable = async (body) => {
    if (body) {
      setIsLoading(true);
      try {
        await axios.post(`${NEW_API_URL}timetable/`, body, {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        });
        await fetchTimeTable();
        setAddTableStatus({
          severity: "success",
          message: "ເພິ່ມຕາຕະລາງສຳເລັດ",
          open: true,
        });
        setShowAddCourse(false);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        setAddTableStatus({
          severity: "error",
          message: "ເກິດບັນຫາໃນການເພີ່ມຕາຕະລາງ",
          open: true,
        });
        setShowAddCourse(false);
      }
    }
  };

  const removeCourse = async (course) => {
    try {
      await axios.delete(`${NEW_API_URL}timetable/${course}`, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      await fetchTimeTable();
      setAddTableStatus({
        severity: "success",
        message: "ລົບຕາຕະລາງສຳເລັດ",
        open: true,
      });
      setShowDeleteCourse(false);
    } catch (err) {
      await fetchTimeTable();
      setAddTableStatus({
        severity: "error",
        message: "ເກີດຂໍ້ຜິດພາດໃນການລົບຕາຕະລາງ",
        open: true,
      });
      setShowDeleteCourse(false);
    }
  };

  useEffect(() => {
    fetchTimeTable();
  }, [filter]);

  const [showDetail, setShowDetail] = useState(false);

  // Memoized computation of time-slot to courses map
  const timeToCoursesMap = useMemo(() => {
    const map = {};
    if (courses) {
      Object.keys(courses).forEach((day) => {
        courses[day].forEach((course) => {
          course.timesStudy.forEach((timeSlot) => {
            const hour = timeSlot.times[0].hour;
            if (!map[hour]) {
              map[hour] = {};
            }
            if (!map[hour][day]) {
              map[hour][day] = [];
            }
            map[hour][day].push(course);
          });
        });
      });
    }
    return map;
  }, [courses]);

  const CourseContents = ({ courses, time, day }) => {
    const [hover, setHover] = useState(false);
    const [showAddIcon, setShowAddIcon] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);

    const handleRightClick = (event, course) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setSelectedCourse(course);
    };

    const handleClose = (type) => {
      setAnchorEl(null);
      if (type === "detail") {
        setShowDetail(true);
        setSelectedTimeAndDay({
          time: time,
          day: day,
          course: selectedCourse,
        });
      }
      if (type === "delete") {
        setShowDeleteCourse(true);
        setSelectedTimeAndDay({
          time: time,
          day: day,
          course: selectedCourse,
        });
      }
    };

    const courseItems = courses.map((course, courseIndex) => (
      <div
        onContextMenu={(e) => handleRightClick(e, course)}
        key={courseIndex}
        className="center"
        style={{ cursor: "pointer", textAlign: "center" }}
      >
        {course.title || course?.course?.subject?.title}
      </div>
    ));

    if (courseItems.length === 0) {
      return (
        <div
          onClick={() => {
            setShowAddCourse(true);
            setSelectedTimeAndDay({
              time: time,
              day: day,
            });
          }}
          onMouseEnter={() => setShowAddIcon(true)}
          onMouseLeave={() => setShowAddIcon(false)}
          className={`my-column-row p-0 nothing ${
            showAddIcon ? "show-add-icon" : ""
          }`}
        >
          <AddIcon
            color="primary"
            sx={{
              width: "20%",
              height: "20%",
            }}
          />
        </div>
      );
    }

    return (
      <div
        onMouseEnter={() => setShowAddIcon(true)}
        onMouseLeave={() => setShowAddIcon(false)}
        className="my-column-row p-0"
        style={{ position: "relative" }}
      >
        {courseItems}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleClose("")}
        >
          <MenuItem onClick={() => handleClose("detail")}>ລາຍລະອຽດ</MenuItem>
          <MenuItem onClick={() => handleClose("delete")}>ລົບ</MenuItem>
        </Menu>
        <div
          className="add"
          style={{
            visibility: showAddIcon ? "visible" : "hidden",
          }}
        >
          <IconButton
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
              setShowAddCourse(true);
              setSelectedTimeAndDay({
                time: time,
                day: day,
              });
            }}
            sx={{
              width: "50px",
              height: "50px",
            }}
          >
            <AddIcon
              sx={{
                color: hover && showAddIcon ? "white" : "primary.main",
                transition: "color 0.3s",
                width: "40px",
                height: "40px",
              }}
            />
          </IconButton>
        </div>
      </div>
    );
  };

  const EmptyComponent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
      }}
    >
      <Box sx={{ marginBottom: "1rem" }}>
        <img
          width="80"
          height="80"
          src="https://img.icons8.com/ultraviolet/40/classroom.png"
          alt="classroom"
        />
      </Box>
      <Typography variant="h5">ກະລຸນາເລືອກຫ້ອງຮຽນ</Typography>
    </Box>
  );

  return (
    <div className="">
      <LoadingDialog open={isLoading} message={"ກຳລັງໂຫລດ..."} />
      <ConfirmModal
        onCancel={() => setShowDeleteCourse(false)}
        onSubmit={() => {
          setShowDeleteCourse(false);
          removeCourse(selectedTimeAndDay?.course?._id);
        }}
        open={showDeleteCourse}
        title={"ທ່ານຕ້ອງການລົບແທ້ບໍ່?"}
        message={"ເມື່ອທ່ານກົດລົບແລ້ວຕາຕະລາງທີ່ມີວິຊານີ້ຈະຫາຍໄປນຳ"}
      />
      <CustomMessage
        open={addTableStatus.open}
        message={addTableStatus.message}
        severity={addTableStatus.severity}
      />
      <CourseDetail
        open={showDetail}
        onCancel={() => {
          setShowDetail(false);
          fetchTimeTable();
        }}
        data={selectedTimeAndDay}
      />
      <AddCourseModal
        open={showAddCourse}
        data={selectedTimeAndDay}
        depId={filter?.department}
        factId={filter?.faculty}
        onCancel={() => setShowAddCourse(false)}
        major={filter?.major}
        year={filter?.year}
        semester={filter?.semester}
        onSubmit={(data) => {
          const _data = {
            ...data,
            assessmentYear: accessmentRedux,
            class: filter?.class,
            faculty: filter?.faculty,
            department: filter?.department,
            major: filter?.major,
            year: filter?.year,
            semester: filter?.semester,
          };
          addTimeTable(_data);
        }}
      />
      <div className="row pt-2 px-5">
        <DataFilter
          depId={depId}
          disableDep={disableDep}
          factId={facId}
          disableFact={disableFact}
          onSelected={onSelected}
          semester
        />
      </div>
      {!filter?.semester || !filter?.class || times.length === 0 ? (
        <EmptyComponent />
      ) : (
        times && (
          <div className="row">
            <div
              className="col-1 w-100"
              style={{
                marginTop: "86px",
              }}
            >
              {times.map((time, index) => (
                <div className="hour" key={index}>
                  <div>{"ຊົ່ວໂມງ " + time?.hour}</div>
                  <div>{"( " + time?.time + " )"}</div>
                </div>
              ))}
            </div>

            <div className="col-11" style={{ overflowX: "auto" }}>
              <table className="my-table">
                <thead>
                  <tr>
                    <th className="my-column-header">ຈັນ</th>
                    <th className="my-column-header">ອັງຄານ</th>
                    <th className="my-column-header">ພຸດ</th>
                    <th className="my-column-header">ພະຫັດ</th>
                    <th className="my-column-header">ສຸກ</th>
                    <th className="my-column-header">ເສົາ</th>
                    <th className="my-column-header">ອາທິດ</th>
                  </tr>
                </thead>
                <tbody>
                  {times.map((time, index) => (
                    <tr key={index}>
                      {[
                        "MONDAY",
                        "TUESDAY",
                        "WEDNESDAY",
                        "THURSDAY",
                        "FRIDAY",
                        "SATURDAY",
                        "SUNDAY",
                      ].map((day) => (
                        <td className="text-center p-0 m-0" key={day}>
                          <CourseContents
                            day={day}
                            courses={timeToCoursesMap[time.hour]?.[day] || []}
                            time={time}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )
      )}
    </div>
  );
};
