import React from 'react'
import { Col, Row } from 'reactstrap'
import useReactRouter from "use-react-router"
import Consts from "../../../consts";

const StepperProgress = () => {
    const { history, match } = useReactRouter()
    const url = match.url
    const matchUrl = url.split('/')[1]
    return (
        <div>
            <Row sm={12} style={{ textAlign: 'center' }} >
                <Col className='d-flex justify-content-center align-items-center'>
                    <div className='rounded-circle' style={{
                        backgroundColor: matchUrl === "teacher-add" || matchUrl === "teacher-form-statistic"
                            || matchUrl === "teacher-form-place-of-birth" ? Consts.PRIMARY_COLOR : 'gray',
                        color: "white",
                        width: 25,
                        height: 25
                    }}>
                        01
                    </div>
                    ຂໍ້ມູນທົ່ວໄປ
                </Col>
                <Col className='d-flex justify-content-center align-items-center'>
                    <div className='rounded-circle' style={{
                        backgroundColor: matchUrl === "teacher-form-statistic" || matchUrl === "teacher-form-place-of-birth" ? Consts.PRIMARY_COLOR : 'gray',
                        color: "white",
                        width: 25,
                        height: 25
                    }}>

                        02
                    </div>
                    ຂໍ້ມູນການສຶກສາ
                </Col>
                <Col className='d-flex justify-content-center align-items-center'>
                    <div className='rounded-circle' style={{
                        backgroundColor: matchUrl === 'teacher-form-place-of-birth' ? Consts.PRIMARY_COLOR : 'gray',
                        color: "white",
                        width: 25,
                        height: 25
                    }}>
                        03
                    </div>
                    ສະຖານທີ່ເກີດ
                </Col>
            </Row>
            <Row sm={12} style={{ paddingLeft: "18px", paddingRight: "18px" }}>
                <Col style={{
                    backgroundColor:  matchUrl === 'teacher-add' || matchUrl === 'teacher-form-statistic'
                    || matchUrl === 'teacher-form-place-of-birth' ? Consts.PRIMARY_COLOR : 'gray',
                    height: "4px"
                }}>

                </Col>
                <Col style={{
                    backgroundColor: matchUrl === 'teacher-form-statistic' || matchUrl === 'teacher-form-place-of-birth' ? Consts.PRIMARY_COLOR : 'gray',
                    height: "4px"
                }}>

                </Col>
                <Col style={{
                    backgroundColor: matchUrl === 'teacher-form-place-of-birth' ? Consts.PRIMARY_COLOR : 'gray',
                    height: "4px"
                }}>

                </Col>
            </Row>
        </div>
    )
}

export default StepperProgress