import React, { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
/**
 *
 * @Library
 *
 */
import * as moment from "moment";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 *
 * @Component
 *
 */
import { Breadcrumb, Row, Col, OverlayTrigger, Tooltip, Tab, Tabs } from "react-bootstrap";
import { CustomContainer, Title, TableHeader, TableCell } from "../../../common";
/**
 *
 * @Constant
 *
 */
import Consts from "../../../consts";
import Routers from "../../../consts/router";
/**
 *
 * @Apollo
 *
 */
import { COURSE, COURSE_FACULTY_DEPARTMENT } from "../../../apollo/course";
// import GradeNavCourseDetail from "../common/GradeNavCourseDetail";
// import sentGradeDetail from "../sendGrades/sentGradedetail";
// import GradeCourseList from "./GradeCourseList";
// import AddGradeForStudent from "./AddGradeForStudent";
import {
  UPDATE_GRADE_COURSE,
  GRADE_COURSES,
} from "../../../apollo/grade/index";
/**
 *
 * @Function
 *
 */
import { reconvertDay } from "../../../common/super";
import { alertSuccess, alertWarning } from "../../../common/super";
import Loading from "../../../common/Loading"
import "../util/index.css";
import "moment/locale/lo";
import UserNav from "../common/GradeNavCourseDetail";
moment.locale("lo");

function GradeSendingCourseDetail() {
  const { history, match,location } = useReactRouter();
  const courseID = location?.state?.data?.courseId;

  /**
   *
   * @State
   *
   */
  const [gradeCourseId, setGradeCourseId] = useState();
  // const [gradeCourseStatus, setGradeCourseStatus] = useState();
  const [dataUser, setDataUser] = useState(null);
  const [courseData, setCourseData] = useState({})
  const [courseFaculties, setCourseFaculties] = useState([])
  const [courseDepartment, setCourseDepartment] = useState([])
  const [courseMajor, setCourseMajor] = useState([])
  const [courseClassroom, setCourseClassroom] = useState([])
  /**
   *
   * @Apollo
   *
   */
  /**
   *
   * @UseEffect
   *
   */
  const [updateGradeCourse] = useMutation(UPDATE_GRADE_COURSE);

  const [loadDataCourse, { data, loading }] = useLazyQuery(COURSE_FACULTY_DEPARTMENT);
  // const [loadCourses, { data: apolloData, loading: apolloLoading }] =
  //   useLazyQuery(COURSE);

  const [loadGradeCourse, { data: gradeCourseData }] = useLazyQuery(GRADE_COURSES);

  /**
   *
   * @Function
   *
   */

  const _viewDoc = (data) => {
    history.push(Routers.COURSE_DETAIL_DOC_LIST + "/id/" + data.id, data);
  };
  const _setScore = () => {
    let _data = location?.state;
    history.push("/add-grade-for-student/1", _data);
  };

  //  check user role
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
  }, []);

  // load gradeCourse here
  useEffect(() => {
    loadGradeCourse({
      variables: {
        where: {
          course: courseID,
        },
      },
    });
    loadDataCourse({
      variables: { where: { id: courseID } },
    });
  }, [courseID]);

  useEffect(() => {
    if (data) {
      setCourseData(data?.courseAndFacultyAndDepartmentAndMajor?.dataCourse);
      setCourseFaculties(data?.courseAndFacultyAndDepartmentAndMajor?.dataFaculty)
      setCourseDepartment(data?.courseAndFacultyAndDepartmentAndMajor?.dataDepartment)
      setCourseMajor(data?.courseAndFacultyAndDepartmentAndMajor?.dataMajor)
      setCourseClassroom(data?.courseAndFacultyAndDepartmentAndMajor?.dataClassroom)
    }
  }, [data])

  useEffect(() => {
    setGradeCourseId(gradeCourseData?.gradeCourses?.data[0]?.id);
  }, [gradeCourseData]);

  // send Grades (update grade course)
  const _sentGrade = () => {
    updateGradeCourse({
      variables: {
        data: {
          status: "TEACHER_SUBMITED",
        },
        where: {
          id: gradeCourseId,
        },
      },
    })
      .then(async () => {
        alertSuccess("ການສົ່ງຄະແນນສຳເລັດແລ້ວ");
      })
      .catch((err) => {
        alertWarning("ການສົ່ງຄະແນນລົ້ມແຫຼວ");
      });
  };

  if (loading) return <Loading />;
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() => history.push("/grades-index/1", location?.state)}
        >
          <strong>ຄະແນນ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => history.push("/grades-index/1", location?.state)}
        >
          <strong>ເລືອກໝວດໝູ່</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() => history.push(Routers.GRADE_SENDING_COURSE_LIST + "/limit/40/skip/1", location?.state)}
        >
          <strong>ວິຊາທັງໝົດ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ລາຍລະອຽດວິຊາ</Breadcrumb.Item>
      </Breadcrumb>
      <UserNav data={location?.state} />
      {/* tab bar */}
      {/* <GradeNavCourseDetail data={location?.state} goToRoute={goToRoute} /> */}
      
      <CustomContainer style={{ marginTop: -16 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // marginTop: 20
          }}
        >
          <Title text="ລາຍລະອຽດວິຊາ" />

          {/* Button group */}
          <div>
            {/* {dataUser?.role === "ADMIN" ? (
              <button
                className="btn btn-outline-info"
                onClick={() => {
                  _setScore();
                }}
              >
                <i className="fa fa-bolt" /> &nbsp;&nbsp;ແກ້ໄຂຄະແນນ
              </button>
            ) : (
              ""
            )} */}

            {/* {gradeCourseStatus =="TEACHER_SCORING" ? 
              <button
              className="btn btn-outline-success"
              data-toggle="modal"
              data-target="#confirmSent"
              // onClick={() => _sentGrade()}
              >
              <i className="fa fa-check-circle" />&nbsp; ສົ່ງຄະແນນ
            </button> : <button
              className="btn btn-success mr-2"
              disabled={true}
              >
              <i className="fa fa-check-circle" /> &nbsp;
              ຄະແນນຖືກສົ່ງແລ້ວ
            </button>
            } */}
            {/* <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">ເບິ່ງເອກະສານບົດສອນ</Tooltip>
              }
            >
              <button
                className="btn btn-outline-info mr-2"
                onClick={() => _viewDoc(courseData)}
              >
                <i className="fa fa-file-alt" /> ເອກະສານບົດສອນ
              </button>
            </OverlayTrigger> */}

            {/* set score */}
          </div>
        </div>
        <div style={{ height: 50 }}></div>
        <div
          style={{
            width: 500,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            paddingBottom: 80,
          }}
        >
          {/* ------ detail box ------ */}
          <div
            style={{
              border: "1px solid #ddd",
              width: 500,
              padding: 20,
              fontSize: 14,
              paddingLeft: 80,
            }}
          >
            <Row>
              <Col>ຊື່ວິຊາ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {courseData && courseData.title}
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row>
              <Col>ລະຫັດວິຊາ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {courseData && courseData.courseCode}
              </Col>
            </Row>
            <div style={{ height: 10 }} />
            <Row>
              <Col>ຈໍານວນຫນ່ວຍກິດ</Col>
              <Col
                style={{ color: Consts.FONT_COLOR_PRIMARY, fontWeight: "bold" }}
              >
                {courseData && courseData.unit}
              </Col>
            </Row>
          </div>

          {/* -------- ຄະນະແລະພາກວິຊາ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }} >
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ຄະນະ ແລະ ພາກວິຊາ
            </div>
            <div style={{ fontSize: 14 }}>
              <Row>
                <strong># ຄະນະ</strong>
                <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                  <thead>
                    <TableHeader>
                      <th style={{ width: 60 }}>ລຳດັບ</th>
                      <th>ຊື່ຄະນະ</th>
                    </TableHeader>
                  </thead>
                  <tbody>
                    {courseFaculties?.length > 0 &&
                      courseFaculties?.map((item, index) => (
                        <tr
                          style={{
                            borderBottom: "2px solid #ffff",
                            textAlign: "center",
                          }}
                          key={index}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <p>
                              {item.faculty?.name ?? "-"}
                            </p>
                          </TableCell>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Row>

              <Row>
                <strong># ພາກວິຊາ</strong>
                <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                  <thead>
                    <TableHeader>
                      <th style={{ width: 60 }}>ລຳດັບ</th>
                      <th>ຊື່ພາກວິຊາ</th>
                    </TableHeader>
                  </thead>
                  <tbody>
                    {courseDepartment?.length > 0 &&
                      courseDepartment?.map((item, index) => (
                        <tr
                          style={{
                            borderBottom: "2px solid #ffff",
                            textAlign: "center",
                          }}
                          key={index}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <p>
                              {item.department?.name ?? "-"}
                            </p>
                          </TableCell>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Row>

              <Row>
                <strong># ສາຂາວິຊາ</strong>
                <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                  <thead>
                    <TableHeader>
                      <th style={{ width: 60 }}>ລຳດັບ</th>
                      <th>ສາຂາວິຊາ</th>
                    </TableHeader>
                  </thead>
                  <tbody>
                    {courseMajor?.length > 0 &&
                      courseMajor?.map((item, index) => (
                        <tr
                          style={{
                            borderBottom: "2px solid #ffff",
                            textAlign: "center",
                          }}
                          key={index}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            <p>
                              {item.major?.name ?? "-"}
                            </p>
                          </TableCell>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Row>
            </div>
          </div>

          {/* -------- ປີຮຽນແລະພາກຮຽນ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ປີຮຽນ ແລະ ພາກຮຽນ
            </div>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>ປີຮຽນ</Col>
                <Col>{courseData && courseData.year}</Col>
              </Row>
              <Row>
                <Col>ພາກຮຽນ</Col>
                <Col>{courseData && courseData.semester}</Col>
              </Row>
            </div>
          </div>

          {/* -------- ຕາຕະລາງອາຈານສອນ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ອາຈານສອນ
            </div>
            <div style={{ paddingLeft: 20, fontSize: 14, paddingTop: 10 }}>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 60 }}>ລຳດັບ</th>
                    <th>ຊື່ອາຈານສອນ</th>
                  </TableHeader>
                </thead>
                <tbody>
                  {courseData?.teacher?.length > 0 &&
                    courseData?.teacher?.map((item, index) => (
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {
                            // courseData?.teacher?.length > 0 &&
                            // courseData?.teacher?.map((item) => (
                            <p>
                              {item.firstname +
                                " " +
                                (item.lastname ? item.lastname : "")}
                            </p>
                            // ))
                          }
                        </TableCell>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ຫ້ອງນັກສຶກສາ
            </div>
            <div style={{ paddingLeft: 20, fontSize: 14, paddingTop: 10 }}>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 60 }}>ລຳດັບ</th>
                    <th>ຫ້ອງນັກສຶກສາ</th>
                  </TableHeader>
                </thead>
                <tbody>
                  {courseClassroom?.length > 0 &&
                    courseClassroom?.map((item, index) => (
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {item?.classRoom?.name ?? "-"}
                        </TableCell>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* -------- ຕາຕະລາງມື້ສອນ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ຕາຕະລາງມື້ສອນ
            </div>
            <div style={{ paddingLeft: 20, fontSize: 14, paddingTop: 10 }}>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 60 }}>ລຳດັບ</th>
                    <th>ວັນ</th>
                    <th>ຊົ່ວໂມງ</th>
                  </TableHeader>
                </thead>
                <tbody>
                  {courseData &&
                    courseData.dayTimeIndexes &&
                    courseData.dayTimeIndexes.length > 0 &&
                    courseData.dayTimeIndexes.map((item, index) => (
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {item.day ? reconvertDay(item.day) : "-"}
                        </TableCell>
                        <TableCell>
                          {item.timeIndexes &&
                            item.timeIndexes.length > 0 &&
                            item.timeIndexes.map(
                              (t, ti) =>
                                parseInt(t) +
                                1 +
                                (ti + 1 >= item.timeIndexes.length ? "" : " - ")
                            )}
                        </TableCell>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* -------- ຕາຕະລາງມື້ສອນ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ໝວດການໃຫ້ຄະແນນ
            </div>
            <div style={{ paddingLeft: 20, fontSize: 14, paddingTop: 10 }}>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 60 }}>ລຳດັບ</th>
                    <th>ໝວດຄະແນນ</th>
                    <th>ຄະແນນ</th>
                  </TableHeader>
                </thead>
                <tbody>
                  {courseData &&
                    courseData.gradeType &&
                    courseData.gradeType.length > 0 &&
                    courseData.gradeType.map((item, index) => (
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                        key={index}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.maxScore}</TableCell>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* -------- ຄໍາອະທິບາຍ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ຄໍາອະທິບາຍ
            </div>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>ເນື້ອໃນຂອງວິຊາ</Col>
                <Col>{courseData && courseData.description}</Col>
              </Row>
            </div>
          </div>

          {/* -------- ອັບໂຫລດ Syllabus -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ອັບໂຫລດ Syllabus
            </div>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>ອັບໂຫລດໄຟລ (PDF)</Col>
                <Col>
                  <a
                    href={
                      courseData &&
                      courseData.syllabusFile &&
                      courseData.syllabusFile.file
                    }
                  >
                    {courseData &&
                      courseData.syllabusFile &&
                      courseData.syllabusFile.title}
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </CustomContainer>
      <div
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="mydelete"
        id="confirmSent"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                <b>ສົ່ງຄະແນນ</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ textAlign: "center" }}>
              <h4>
                ຕ້ອງການສົ່ງຄະແນນວິຊາ {courseData && courseData.title} ແທ້ບໍ?
              </h4>
              <br />
              <center>
                <button
                  type="button"
                  className="cancelconf"
                  data-dismiss="modal"
                >
                  ຍົກເລີກ
                </button>
                <button
                  className="btn btn-info"
                  onClick={() => _sentGrade()}
                  data-dismiss="modal"
                >
                  &nbsp;&nbsp;ສົ່ງຄະແນນ&nbsp;&nbsp;
                </button>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GradeSendingCourseDetail;

