import { gql } from "apollo-boost";

export const TRIBE_MASTER = gql`
  query TribeMasters {
    tribeMasters {
      data {
        id
        tribeNumber
        name
        note
      }
    }
  }
`;
export const DELETE_TRIBEMASTER = gql`
  mutation DeleteTribeMaster($where: TribeMasterWhereUniqueInput!) {
    deleteTribeMaster(where: $where) {
      id
    }
  }
`;
export const CREATE_TRIBEMASTER = gql`
  mutation CreateTribeMaster($data: TribeMasterCreateInput!) {
    createTribeMaster(data: $data) {
      id
      name
      note
    }
  }
`;

export const UPDATE_TRIBEMASTER = gql`
  mutation UpdateTribeMaster(
    $data: TribeMasterUpdateInput!
    $where: TribeMasterWhereUniqueInput!
  ) {
    updateTribeMaster(data: $data, where: $where) {
      id
    }
  }
`;