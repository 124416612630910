import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Checkbox from "@material-ui/core/Checkbox";
import InputBase from "@material-ui/core/InputBase";
import queryString from "query-string";
import { toast } from "react-toastify";
import moment from "moment";
import * as _ from "lodash";
/**
 *
 * @Component
 *
 */
import { Modal, Button, Form, Row, Col, Pagination } from "react-bootstrap";

import { CustomContainer, TableHeader } from "../../common";
import Loading from "../../common/Loading";
import PopUpLoading from "../../common/PopUpLoading";
import Empty from "../../common/Empty";

/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";
import Routers from "../../consts/router";
/**
 *
 * @Apollo
 *
 */
import {
  REGISTER_STUDENT,
  STD_TRIBES,
  COURSE_FAC,
  STATISTICS_COUNT,
  UPDATE_REGISTRATION_STUDENT_BULK,
} from "../../apollo/registrationStudent/index";
import { FACULTY } from "../../common/facultyConstant";

/**
 *
 * @Function
 *
 */

import { toDayDash, formatDate, currency } from "../../common/super";

function RegistrationStudentList({ stdIdFilter }) {
  const { history, location, match } = useReactRouter();
  const pageNambers = match?.params?.page;
  const urlParam = queryString.parse(location.search);
  const PAGE_LIMIT = 20;
  var stdId = moment(stdIdFilter).format("YY");
  /**
   *
   * @State
   *
   */
  const [isAdmin, setIsAdmin] = useState(true);
  const [dataUser, setDataUser] = useState(null);
  const [facSqlId, setFacSqlId] = useState("");
  const [department, setDepartment] = useState([]);
  const [checkedItemsArray, setCheckedItemsArray] = useState([]);
  const [tribeId, setTribeId] = useState("");
  const [facEntre, setFacEntre] = useState("");
  const [userDate, setUserDate] = useState("");
  const [selectdDepartment, setSelectdDepartment] = useState("");
  const [allChecked, setAllChecked] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [dummy, setDummy] = useState(false);
  const [userName, setUserName] = useState(false);
  const [stdNameE, setStdNameE] = useState("");
  /**
   *
   * @Apollo
   *
   */
  const [updateRegistrationStudentBulk] = useMutation(
    UPDATE_REGISTRATION_STUDENT_BULK
  );
  const [loadStatisticsCount] = useLazyQuery(STATISTICS_COUNT, {
    variables: {
      where: { fac_id: parseInt(facSqlId) },
    },
  });
  const [loadStudentData, { loading: studentLoading, data: studentData }] =
    useLazyQuery(REGISTER_STUDENT);

  const [
    loadStudentDataAll,
    { loading: studentAllLoading, data: studentAllData },
  ] = useLazyQuery(REGISTER_STUDENT);
  const [loadDepartmentData, { data: departmentData }] = useLazyQuery(
    COURSE_FAC,
    {
      variables: {
        where: { fac_id: parseInt(facSqlId) },
      },
    }
  );

  const [loadTribesData, { data: tribesData }] = useLazyQuery(STD_TRIBES);

  /**
   *
   * @UseEffect
   *
   */
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
  }, []);

  //Detect if urlparam has query parameters
  useEffect(() => {
    if (
      urlParam.fac_id ||
      urlParam.stdmajor ||
      urlParam.std_tribes ||
      urlParam.facEntre ||
      urlParam.user_date ||
      urlParam.stdId ||
      urlParam.stdNameE
    ) {
      if (urlParam.fac_id) setFacSqlId(urlParam.fac_id);
      if (urlParam.stdmajor) setSelectdDepartment(urlParam.stdmajor);
      if (urlParam.std_tribes) setTribeId(urlParam.std_tribes);
      if (urlParam.facEntre) setFacEntre(urlParam.facEntre);
      if (urlParam.user_date) setUserDate(urlParam.user_date);
      if (urlParam.stdNameE) setStdNameE(urlParam.stdNameE);
    }
  }, []);

  // filter data by users role
  useEffect(() => {
    // if(!dataUser)return ;
    const user = JSON.parse(localStorage.getItem("user"));
    let userRole = dataUser?.role;
    // check role
    if (userRole === "ADMIN") {
      setIsAdmin(false);
      // loadStudentData();
      setUserName(
        user.data.firstname
          ? user.data.firstname
          : " " + " " + user.data.lastname
          ? user.data.lastname
          : ""
      );
      loadStatisticsCount({
        variables: {
          where: { fac_id: parseInt(facSqlId) },
        },
      });
    } else {
      setIsAdmin(true);
      setUserName(user?.data?.firstname + " " + user?.data?.lastname);
      let facSqlIndex = _.findIndex(FACULTY, {
        id: dataUser?.faculty?.id,
      });
      loadTribesData();
      setFacSqlId(FACULTY[facSqlIndex]?.vId);
      setDepartment(FACULTY[facSqlIndex]?.departments);
    }
  }, [dataUser]);

  useEffect(() => {
    // loadStudentData();
    loadStatisticsCount();
  }, [facSqlId]);

  useEffect(() => {
    if (facSqlId) loadDepartmentData();
  }, [departmentData]);
  useEffect(() => {
    let qlWhere = {};
    if (facSqlId) qlWhere = { ...qlWhere, fac_id: parseInt(facSqlId) };
    if (selectdDepartment)
      qlWhere = { ...qlWhere, stdmajor: parseInt(selectdDepartment) };
    if (tribeId) qlWhere = { ...qlWhere, std_tribes: parseInt(tribeId) };
    if (facEntre) qlWhere = { ...qlWhere, facEntre: parseInt(facEntre) };
    if (userDate) qlWhere = { ...qlWhere, user_date: userDate };
    if (stdId) qlWhere = { ...qlWhere, stdid: stdId };
    if (stdNameE) qlWhere = { ...qlWhere, stdnameE: stdNameE };

    loadStudentData({
      variables: {
        where: qlWhere,
        limit: PAGE_LIMIT,
        skip: (parseInt(pageNambers) - 1) * PAGE_LIMIT,
      },
    });

    loadStatisticsCount({
      variables: {
        where: { fac_id: parseInt(facSqlId) },
      },
    });
  }, [
    facSqlId,
    selectdDepartment,
    tribeId,
    facEntre,
    userDate,
    stdIdFilter,
    stdNameE,
  ]);

  useEffect(() => {
    setCheckedItemsArray(checkedItemsArray);
  }, [checkedItemsArray]);

  /**
   *
   * @Function
   *
   */

  const _handleShowConfirmModalClose = () => setShowConfirmModal(false);
  const _handleShowConfirmModalShow = () => setShowConfirmModal(true);
  const _handleShowConfirm = () => setShowConfirm(true);
  const _handleCloseConfirm = () => setShowConfirm(false);

  // get faculty id
  const _selectFaculty = (e) => {
    setFacSqlId(e.target.value);
    let _facultyIndex = _.findIndex(FACULTY, { vId: parseInt(e.target.value) });
    setDepartment(FACULTY[_facultyIndex]?.departments);
  };

  // for filter data setudent

  const _getStudentAll = () => {
    let qlWhere = {};
    if (facSqlId) qlWhere = { ...qlWhere, fac_id: parseInt(facSqlId) };
    if (selectdDepartment)
      qlWhere = { ...qlWhere, stdmajor: parseInt(selectdDepartment) };
    if (tribeId) qlWhere = { ...qlWhere, std_tribes: parseInt(tribeId) };
    if (facEntre) qlWhere = { ...qlWhere, facEntre: parseInt(facEntre) };
    if (userDate) qlWhere = { ...qlWhere, user_date: userDate };
    if (stdId) qlWhere = { ...qlWhere, stdid: stdId };
    if (stdNameE) qlWhere = { ...qlWhere, stdnameE: stdNameE };

    loadStudentDataAll({
      variables: {
        where: qlWhere,
        limit: studentData.registrationStudents.total,
        skip: 0,
      },
    });
  };

  // pagination

  const registrationCount = studentData?.registrationStudents.total;
  const countPage = [];
  for (var i = 1; i <= Math.ceil(registrationCount / PAGE_LIMIT); i++) {
    countPage.push(i);
  }

  const _paginationRender = () => {
    let _paginationUrl = "?";
    if (facSqlId) _paginationUrl = _paginationUrl + "fac_id=" + facSqlId + "&&";
    if (selectdDepartment)
      _paginationUrl = _paginationUrl + "stdmajor=" + selectdDepartment + "&&";
    if (tribeId)
      _paginationUrl = _paginationUrl + "std_tribes=" + tribeId + "&&";
    if (facEntre)
      _paginationUrl = _paginationUrl + "facEntre=" + facEntre + "&&";
    if (userDate)
      _paginationUrl = _paginationUrl + "user_date=" + userDate + "&&";
    if (stdId) _paginationUrl = _paginationUrl + "stdid=" + stdId + "&&";
    if (stdNameE)
      _paginationUrl = _paginationUrl + "stdnameE=" + stdNameE + "&&";
    return _paginationUrl;
  };

  const _onNext = () => {
    if (parseInt(pageNambers) + 1 < 1) {
      history.push(`/registration_student/${1}${_paginationRender()}`);
    } else {
      history.push(
        `/registration_student/${
          parseInt(pageNambers) + 1
        }${_paginationRender()}`
      );
    }
  };
  const _onPrevius = () => {
    if (parseInt(pageNambers) - 1 < 1) {
      history.push(`/registration_student/${1}${_paginationRender()}`);
    } else {
      history.push(
        `/registration_student/${
          parseInt(pageNambers) - 1
        }${_paginationRender()}`
      );
    }
  };

  // get trib_name
  const _renderTribe = (tribe) => {
    let result = tribesData?.tbtribesQueryShow?.filter(
      (item, index) => item.tribNo === tribe
    );
    if (result?.length > 0) return result[0].trib_name;
    else return "";
  };
  const _renderStatus = (status) => {
    if (status === null) return "ຍັງບໍ່ລົງ";
    else return "ລົງແລ້ວ";
  };

  //    setAllChecked
  const _checkAll = (e) => {
    setAllChecked(!allChecked);
    let filterChecked = [];
    if (!allChecked)
      filterChecked = studentData.registrationStudents.data.filter(
        (item, index) => item.facEntre !== 1
      );
    setCheckedItemsArray(filterChecked);
    setDummy(!dummy);
  };

  const handleChange = (e, item) => {
    // to find out if it's checked or not; returns true or false
    const checked = e.target.checked;
    let data = checkedItemsArray;
    let isIncludedIndex = _.findIndex(data, item);

    if (isIncludedIndex < 0) data.push(item);
    else _.remove(data, (x) => x.stdid === item.stdid);

    setCheckedItemsArray(data);
    setDummy(!dummy);
  };

  const _onApply = () => {
    if (checkedItemsArray.length === 0) {
      toast.error("ກະລຸນາເລືອກນັກຮຽນທີ່ຈະອັບເດດ!", { autoClose: 5000 });
      // _handleShowConfirmModalClose();
      return;
    }
    let _qlParam = [];
    checkedItemsArray.map((x, index) => {
      _qlParam.push({
        stdregno: x.stdregno,
      });
    });
    updateRegistrationStudentBulk({ variables: { where: _qlParam } })
      .then(async () => {
        window.location.reload();
      })
      .catch((err) => {
        alert("fail!");
      });
  };

  const _onSelectedTribes = (e) => {
    setTribeId(e.target.value);
  };
  const _onSelectedStdNameE = (e) => {
    // setFacEntre(e.target.value);
    setStdNameE(e.target.value);
  };
  const _onSelectedUserDate = (e) => {
    setUserDate(e.target.value);
  };
  const _onSelectdDepartment = (e) => {
    setSelectdDepartment(e.target.value);
  };

  const _verifyChecked = (item) => {
    let _isChecked = _.findIndex(checkedItemsArray, { stdid: item.stdid });
    if (_isChecked >= 0) return true;
    else return false;
  };

  const _onClickDetail = (e) => {
    history.push(`/registration_student-detail/${pageNambers}`, e);
  };
  const _handlePreregistration = () => {
    history.push(Routers.PRERE_STUDENT_LIST + "/limit/20/skip/1");
  };

  const _renderFac = (fac_id) => {
    let result = FACULTY?.filter((item, index) => item.vId === fac_id);
    if (result.length > 0) return result[0].name;
    else return "";
  };

  return (
    <div>
      {studentLoading && <Loading />}

      <CustomContainer style={{ marginTop: 0 }}>
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          <Row>
            <Col sm={3}>
              <p>ຄະນະວິຊາ</p>
              {(!isAdmin || facSqlId) && (
                <Form.Control
                  as="select"
                  style={
                    isAdmin
                      ? {
                          border: "none",
                          backgroundColor: "#ccc",
                          cursor: "no-drop",
                        }
                      : {
                          border: "none",
                          backgroundColor: "#f1f1f1f1",
                          cursor: "pointer",
                        }
                  }
                  disabled={isAdmin}
                  defaultValue={facSqlId}
                  onChange={(e) => _selectFaculty(e)}
                >
                  {/*  */}
                  <option value="">ຄະນະທັງໝົດ</option>
                  {FACULTY?.map((item, index) => {
                    return (
                      <option key={"faculty" + index} value={item.vId}>
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Control>
              )}
            </Col>
            <Col sm={2}>
              <p>ສາຂາວິຊາ</p>
              <Form.Control
                as="select"
                style={{
                  border: "none",
                  backgroundColor: "#f1f1f1f1",
                  cursor: "pointer",
                }}
                defaultValue={26}
                onChange={(e) => _onSelectdDepartment(e)}
              >
                {facSqlId === "" ? (
                  "ສາຂາທັງໝົດ"
                ) : (
                  <option value="">ທັງໝົດ</option>
                )}
                {department?.map((item, index) => {
                  return (
                    <option key={"major" + index} value={item?.vId}>
                      {item?.name}
                    </option>
                  );
                })}
              </Form.Control>
              {/* )} */}
            </Col>
            <Col sm={2}>
              <p>ຊົນເຜົ່າ</p>
              <Form.Control
                as="select"
                style={{
                  border: "none",
                  backgroundColor: "#f1f1f1f1",
                  cursor: "pointer",
                }}
                defaultValue={tribeId}
                onChange={(e) => _onSelectedTribes(e)}
              >
                <option value={""}>ທັງໝົດ</option>
                {tribesData?.tbtribesQueryShow?.map((item, index) => {
                  return (
                    <option key={"tribe" + index} value={item.tribNo}>
                      {item.trib_name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>

            <Col sm={2}>
              <p>ລົງທະບຽນຈາກ ວກທຊ</p>
              <Form.Control
                as="select"
                style={{
                  border: "none",
                  backgroundColor: "#f1f1f1f1",
                  cursor: "pointer",
                }}
                onChange={(e) => _onSelectedStdNameE(e)}
              >
                <option value={""}>ເລືອກ</option>
                <option value={"IS_NULL"}>ຍັງບໍ່ທັນລົງ</option>
                <option value={"IS_NOT_NULL"}>ລົງແລ້ວ</option>
              </Form.Control>
            </Col>
            <Col sm={2}>
              <p>ວັນທີ່</p>
              <InputBase
                id="date"
                style={{
                  backgroundColor: "#f1f1f1f1",
                  paddingTop: 4,
                  paddingBottom: 3,
                  paddingLeft: 5,
                  paddingRight: 5,
                  borderRadius: 4,
                  cursor: "pointer",
                }}
                type="date"
                defaultValue={toDayDash()}
                onChange={(e) => _onSelectedUserDate(e)}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <button
              hidden={isAdmin}
              onClick={_handlePreregistration}
              className="btn btn-outline-success"
              style={{ float: "right", marginRight: 10 }}
            >
              <i className="fa fa-random"></i> &nbsp;&nbsp;ຂໍ້ມູນໃໝ່ 2021
            </button>
          </Row>
        </div>
        {studentData?.registrationStudents?.data.length > 0 ? (
          <div>
            <Row>
              <Col sm={6}>
                ໜ້າທີ່ {pageNambers} ສະແດງ {pageNambers * PAGE_LIMIT} ໃນ{" "}
                {currency(studentData?.registrationStudents.total)} ລາຍການ
              </Col>
              <Col sm={6}>
                <button
                  className="btn btn-outline-success"
                  onClick={() => {
                    _getStudentAll();
                    _handleShowConfirmModalShow();
                  }}
                  style={{ float: "right" }}
                >
                  <i className="fa fa-file-excel"></i> Export Excel
                </button>{" "}
                &nbsp;&nbsp;
                <button
                  hidden={isAdmin}
                  onClick={_handleShowConfirm}
                  className="btn btn-outline-success"
                  style={{ float: "right", marginRight: 10 }}
                >
                  <i className="fa fa-check-circle"></i> &nbsp;&nbsp;ບັນທຶກ
                </button>
                <button
                  hidden={isAdmin}
                  onClick={_handlePreregistration}
                  className="btn btn-outline-success"
                  style={{ float: "right", marginRight: 10 }}
                >
                  <i className="fa fa-random"></i> &nbsp;&nbsp;ຂໍ້ມູນໃໝ່ 2021
                </button>
              </Col>
              <Col sm={2} hidden={isAdmin} style={{ paddingLeft: 20 }}>
                <Checkbox
                  hidden={isAdmin}
                  color="primary"
                  name="selectAll"
                  onChange={(e) => _checkAll(e)}
                />
                <font>ເລືອກທັງໝົດ</font>
              </Col>
              <Col sm={2} hidden={isAdmin} style={{ paddingTop: 6 }}>
                <font>ຖືກເລືອກ {checkedItemsArray?.length}</font>
              </Col>
            </Row>
            <table
              id="allData"
              className="table table-hover"
              style={{ width: "100%" }}
            >
              <thead>
                <TableHeader>
                  <th hidden={isAdmin} style={{ width: 60 }}>
                    ເລືອກ
                  </th>
                  <th style={{ width: 70 }}>ລຳດັບ</th>
                  <th>ລະຫັດນັກຮຽນ</th>
                  <th>ເພດ</th>
                  <th>ຊື່</th>
                  <th>ນາມສະກຸນ</th>
                  <th>ຊົນເຜົ່າ</th>
                  <th>ວັນເດືອນປີເກີດ</th>
                  <th>ຈົບຈາກໂຮງຮຽນ</th>
                  <th>ລົງທະບຽນຈາກ ວກທຊ</th>
                  {/* <th>ສົກຮຽນ</th> */}
                  <th style={{ width: 180 }}>ວັນທີ່ລົງທະບຽນ</th>
                </TableHeader>
              </thead>
              <tbody>
                {studentData?.registrationStudents?.data.map((item, index) => {
                  return (
                    <tr
                      key={"student" + index}
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                    >
                      <td hidden={isAdmin}>
                        <Checkbox
                          disabled={item?.facEntre === 1 ? true : isAdmin}
                          color="primary"
                          value={item?.stdid}
                          checked={_verifyChecked(item)}
                          onChange={(e) => handleChange(e, item)}
                        />
                      </td>
                      <td onClick={(e) => _onClickDetail(item)}>
                        {(parseInt(pageNambers) - 1) * PAGE_LIMIT + index + 1}
                      </td>
                      <td onClick={(e) => _onClickDetail(item)}>
                        {item.stdid}
                      </td>
                      <td onClick={(e) => _onClickDetail(item)}>
                        {item.stdgd}
                      </td>
                      <td
                        className="left"
                        onClick={(e) => _onClickDetail(item)}
                      >
                        {item.stdnameL}
                      </td>
                      <td
                        className="left"
                        onClick={(e) => _onClickDetail(item)}
                      >
                        {item.stdsurL}
                      </td>
                      <td onClick={(e) => _onClickDetail(item)}>
                        {_renderTribe(item.std_tribes)}
                      </td>
                      <td onClick={(e) => _onClickDetail(item)}>
                        {item.user_date ? formatDate(item.stddate) : "ວ່າງ"}
                      </td>
                      <td
                        className="left"
                        onClick={(e) => _onClickDetail(item)}
                      >
                        {item.stdschool}
                      </td>
                      <td onClick={(e) => _onClickDetail(item)}>
                        {_renderStatus(item?.stdnameE)}
                      </td>
                      {/* <td onClick={(e) => _onClickDetail(item)}>
                        {item.std_seem}
                      </td> */}
                      <td onClick={(e) => _onClickDetail(item)}>
                        {item.user_date ? formatDate(item.user_date) : "ວ່າງ"}
                      </td>
                    </tr>
                  );
                })}
                {/* })} */}
              </tbody>
            </table>

            <Pagination
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <Pagination.Prev onClick={_onPrevius} />
              {countPage.slice(0, 5).map((Item, index) => {
                return (
                  <Pagination.Item
                    key={"page" + index}
                    active={Item === pageNambers}
                    onClick={() =>
                      history.push(
                        `/registration_student/${Item}${_paginationRender()}`
                      )
                    }
                  >
                    {Item}
                  </Pagination.Item>
                );
              })}
              {countPage && countPage?.length > 3 ? (
                <Pagination.Item style={{ pointerEvents: "none" }}>
                  {"..."}
                </Pagination.Item>
              ) : (
                " "
              )}
              {countPage && countPage?.length > 3 ? (
                <Pagination.Item
                  onClick={() =>
                    history.push(
                      `/registration_student/${
                        countPage?.length
                      }${_paginationRender()}`
                    )
                  }
                >
                  {countPage?.length}
                </Pagination.Item>
              ) : (
                " "
              )}
              <Pagination.Next onClick={_onNext} />
            </Pagination>
          </div>
        ) : (
          <Empty />
        )}
      </CustomContainer>

      {/* Export Excel */}
      <Modal
        center
        show={showConfirmModal}
        onHide={_handleShowConfirmModalClose}
        size="lg"
      >
        {studentAllLoading && <PopUpLoading />}
        <Modal.Header style={{ textAlign: "center", paddingTop: 40 }}>
          {"ລາຍງານຂໍ້ມູນສະຖິຕິ" +
            " " +
            " ສົກຮຽນ " +
            " " +
            (stdIdFilter - 1) +
            "-" +
            stdIdFilter}
          <div style={{ height: 20 }} />
          <div className="row" style={{ padding: 20 }}>
            <Button
              className="btn"
              variant="outline-dark"
              onClick={_handleShowConfirmModalClose}
            >
              ຍົກເລີກ
            </Button>
            &nbsp;&nbsp;
            <ReactHTMLTableToExcel
              style={{ backgroundColor: "#000" }}
              className="btn btn-outline-success"
              table="ExportData"
              filename={
                "ລາຍງານຂໍ້ມູນສະຖິຕິ" +
                " " +
                (stdIdFilter - 1) +
                "-" +
                stdIdFilter +
                " " +
                toDayDash()
              }
              sheet="Sheet"
              buttonText="Export excel"
              font={"NotoSansLao"}
              headerPaddingCellOptions={{ background: "#ff0000" }}
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <table
              className="table"
              id="ExportData"
              style={{
                fontSize: 12,
                fontFamily: "NotoSansLao",
              }}
            >
              <thead>
                <tr>
                  <td colSpan={13}>
                    ຄະນະ {_renderFac(facSqlId)} {"      "}
                    ສົກຮຽນ {stdIdFilter - 1 + " - " + stdIdFilter}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: 70 }}>ລຳດັບ</th>
                  <th>ລະຫັດນັກຮຽນ</th>
                  <th>ເພດ</th>
                  <th>ຊື່ (ລາວ)</th>
                  <th>ນາມສະກຸນ(ລາວ)</th>
                  <th>ຊື່(ອັງກິດ)</th>
                  <th>ນາມສະກຸນ(ອັງກິດ)</th>
                  <th>ຊົນເຜົ່າ</th>
                  <th>ວັນເດືອນປີເກີດ</th>
                  <th>ຈົບຈາກໂຮງຮຽນ</th>
                  <th>ສະຖານະ</th>
                  <th>ສົກຮຽນ</th>
                  <th style={{ width: 180 }}>ວັນທີ່ລົງທະບຽນ</th>
                </tr>
              </thead>
              <tbody>
                {studentAllData &&
                  studentAllData?.registrationStudents?.data
                    .filter((studentData, index) => index <= 100)
                    .map((item, index) => {
                      return (
                        <tr>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {(parseInt(pageNambers) - 1) * PAGE_LIMIT +
                              index +
                              1}
                          </td>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {item.stdid}
                          </td>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {item.stdgd}
                          </td>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {item.stdnameL}
                          </td>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {item.stdsurL}
                          </td>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {item.stdnameE}
                          </td>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {item.stdsurE}
                          </td>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {_renderTribe(item.std_tribes)}
                          </td>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {item.user_date ? formatDate(item.stddate) : "ວ່າງ"}
                          </td>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {item.stdschool}
                          </td>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {_renderStatus(item?.stdnameE)}
                          </td>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {item.std_seem}
                          </td>
                          <td onClick={(e) => _onClickDetail(item)}>
                            {item.user_date
                              ? formatDate(item.user_date)
                              : "ວ່າງ"}
                          </td>
                        </tr>
                      );
                    })}
                <tr style={{ width: "100%" }}>
                  <td colSpan={11}>ສະຖິຕິ ທັງໝົດ</td>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    {studentData
                      ? currency(
                          studentData && studentData.registrationStudents.total
                        )
                      : 0}{" "}
                    ຄົນ
                  </td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td colSpan={11}>ລົງທະບຽນແລ້ວ</td>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    {studentData
                      ? currency(studentData?.registrationStudents?.success)
                      : 0}{" "}
                    ຄົນ
                  </td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td colSpan={11}>ຍັງບໍ່ທັນລົງທະບຽນ</td>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    {studentData
                      ? currency(
                          (studentData?.registrationStudents?.total ?? "0") -
                            (studentData?.registrationStudents?.success ?? "0")
                        )
                      : 0}{" "}
                    ຄົນ
                  </td>
                </tr>
                <tr style={{ width: "100%" }}>
                  <td colSpan={11}>ວັນທີ</td>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    {toDayDash()}
                  </td>
                </tr>
                <tr>
                  <td colSpan={11}>ໂດຍ</td>
                  <td colSpan={2} style={{ textAlign: "right" }}>
                    {userName}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showConfirm} onHide={_handleCloseConfirm} size="lg">
        <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
          ຢືນຢັນການອັບເດດສະຖານະນັກຮຽນ
        </Modal.Title>
        <Modal.Body
          style={{
            marginLeft: 50,
            marginRight: 50,
            padding: 50,
            paddingTop: 0,
          }}
        >
          <div style={{ height: 20 }} />
          <div className="row">
            <div style={{ padding: 15 }} className="col">
              <Button
                onClick={_handleCloseConfirm}
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  color: "#6f6f6f",
                  borderColor: "#6f6f6f",
                }}
              >
                ຍົກເລີກ
              </Button>
            </div>
            <div style={{ padding: 15 }} className="col">
              <Button
                style={{
                  width: "100%",
                  backgroundColor: Consts.SECONDARY_COLOR,
                  color: "#fff",
                  borderColor: Consts.SECONDARY_COLOR,
                }}
                onClick={_onApply}
              >
                ຕົກລົງ
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RegistrationStudentList;
