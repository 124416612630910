import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";

const AddGradeTypeDialog = ({ open, onClose, onSubmit }) => {
  const [gradeName, setGradeName] = useState("");
  const [maxScore, setMaxScore] = useState("");

  const resetForm = () => {
    setGradeName("");
    setMaxScore("");
  };

  const handleGradeNameChange = (e) => {
    setGradeName(e.target.value);
  };

  const handleMaxScoreChange = (e) => {
    setMaxScore(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit({ name: gradeName, maxScore });
    onClose();
    resetForm(); // Reset form after submitting
  };

  const handleClose = () => {
    onClose();
    resetForm(); // Reset form when closing the dialog
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>ເພິ່ມເກນການໃຫ້ຄະເເນນ</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="ຫົວຂໍ້"
          type="text"
          fullWidth
          value={gradeName}
          onChange={handleGradeNameChange}
        />
        <TextField
          margin="dense"
          label="ຄະເເນນສູງສຸດ"
          type="number"
          fullWidth
          value={maxScore}
          onChange={handleMaxScoreChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>ຍົກເລີກ</Button>
        <Button onClick={handleSubmit} color="primary">
          ເພີ່ມ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddGradeTypeDialog;
