import React, { useState, useEffect } from "react";
import { Breadcrumb, Row, Col } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Consts from "../../consts";
import Routers from "../../consts/router";
import Loading from "../../common/Loading";
import CourseDeleteConfirm from "./StudentDeleteConfirm";
import { CustomContainer, Title } from "../../common";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { STUDENT, DELETE_STUDENT } from "./../../apollo/student";
import {
  formatDate,
  MaritualStatus,
  convertStudyStatus,
} from "../../common/super";
import { TRIBE_MASTER } from "../../apollo/tribeMaster";
import { RELIGIONAL_MASTERS } from "../../apollo/religionalMasters";
import addLogs from "../../helpers/AddLogs";

function StudentDetail() {
  const { history, match } = useReactRouter();
  const studentId = match?.params?.id;
  /**
   *
   * @State
   *
   */
  const [showDeleteConfirmView, setShowDeleteConfirmView] = useState(false);
  const [Gender, setGender] = useState();
  const [newData, setNewData] = useState();
  const [dataTribes, setDataTribes] = useState([]);
  const [dataReligions, setDataReligions] = useState([]);

  /**
   *
   * @Apollo
   *
   */
  const [dataStudentLoader, { loading: studentLoading, data: apolloData }] =
    useLazyQuery(STUDENT);
  const [loadTribes, { data: tribesData }] = useLazyQuery(TRIBE_MASTER);
  const [loadReligions, { data: religionData }] =
    useLazyQuery(RELIGIONAL_MASTERS);

  const [deleteStudent] = useMutation(DELETE_STUDENT, {
    variables: { where: { id: newData?.id } },
  });
  /**
   *
   * @UseEffect
   *
   */
  useEffect(() => {
    loadTribes();
    loadReligions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dataStudentLoader({
      fetchPolicy: "network-only",
      variables: {
        where: { id: studentId },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId]);

  useEffect(() => {
    if (apolloData?.student) setNewData(apolloData?.student);
  }, [apolloData]);
  useEffect(() => {
    if (tribesData) setDataTribes(tribesData?.tribeMasters?.data);
  }, [tribesData]);
  useEffect(() => {
    if (religionData) setDataReligions(religionData?.religionalMasters?.data);
  }, [religionData]);

  // check status and gender
  useEffect(() => {
    if (newData?.gender === "FEMALE") {
      setGender("ຍິງ");
    } else {
      setGender("ຊາຍ");
    }
  }, [newData]);
  /**
   *
   * @Function
   *
   */

  const _handleDeleteConfirmViewClose = () => setShowDeleteConfirmView(false);
  const _handleDeleteConfirmViewShow = () => setShowDeleteConfirmView(true);

  const _edit = () => {
    history.push(Routers.STUDENT_EDIT + "/id/" + newData?.id, newData);
  };

  const _delete = () => {
    _handleDeleteConfirmViewShow();
  };

  const _deleteConfirm = async () => {
    await deleteStudent();
    await addLogs({
      action: "DELETE",
      type: "STUDENT",
      student: newData?.id,
      data: {
        data: newData,
      },
      status: "SUCCESS",
    });
    history.push(Routers.STUDENT_LIST + Routers.PAGE_GINATION);
    window.location.reload(true);
  };

  if (studentLoading) return <Loading />;

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item
          onClick={() =>
            history.push(Routers.STUDENT_LIST + Routers.PAGE_GINATION)
          }
        >
          ຈັດການນັກສຶກສາ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          onClick={() =>
            history.push(Routers.STUDENT_LIST + Routers.PAGE_GINATION)
          }
        >
          ລາຍຊື່ນັກສຶກສາທັງໝົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍລະອຽດນັກສຶກສາ</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          marginLeft: 10,
        }}
      >
        <div
          className="menuItemTap active-menuTap"
          onClick={() =>
            history.push(Routers.STUDENT_DETAIL + "/id/" + studentId)
          }
        >
          ຂໍ້ມູນນັກສຶກສາ
        </div>
        <div
          className="menuItemTap"
          onClick={() =>
            history.push(Routers.STUDENT_YEAR_ONE + "/id/" + studentId)
          }
        >
          ຄະແນນປີ 1
        </div>
        <div
          className="menuItemTap"
          onClick={() =>
            history.push(Routers.STUDENT_YEAR_TWO + "/id/" + studentId)
          }
        >
          ຄະແນນປີ 2
        </div>
        <div
          className="menuItemTap"
          onClick={() =>
            history.push(Routers.STUDENT_YEAR_THREE + "/id/" + studentId)
          }
        >
          ຄະແນນປີ 3
        </div>
        <div
          className="menuItemTap"
          onClick={() =>
            history.push(Routers.STUDENT_YEAR_FOUR + "/id/" + studentId)
          }
        >
          ຄະແນນປີ 4
        </div>
      </div>
      <CustomContainer style={{ marginTop: 0 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title text="ລາຍລະອຽດນັກສຶກສາ" />

          <div>
            <button
              style={{
                backgroundColor: "#fff",
                color: Consts.BORDER_COLOR,
                height: 40,
                paddingLeft: 12,
                paddingRight: 12,
                border: "1px solid " + Consts.BORDER_COLOR,
                outline: "none",
                marginRight: 5,
              }}
              onClick={() => _edit()}
            >
              <FontAwesomeIcon icon="edit" style={{ fontSize: 16 }} />{" "}
              ແກ້ໄຂຂໍ້ມູນ
            </button>
            <button
              style={{
                backgroundColor: "#fff",
                color: Consts.BORDER_COLOR_DELETE,
                height: 40,
                paddingLeft: 12,
                paddingRight: 12,
                border: "1px solid " + Consts.BORDER_COLOR_DELETE,
                outline: "none",
              }}
              onClick={() => _delete()}
            >
              <i className="fa fa-trash" /> ລຶບຂໍ້ມູນ
            </button>
          </div>
        </div>
        <div className="row">
          <div
            className="col-md-4"
            style={{ textAlign: "center", padding: 10 }}
          >
            <img
              src={
                newData?.image?.url
                  ? Consts.URL_IMAGE_STUDENT + newData?.image?.url
                  : "https://www.seekpng.com/png/detail/414-4140251_you-profile-picture-question-mark.png"
              }
              alt=""
              style={{ width: 300, borderRadius: 4 }}
            />
          </div>
          <div
            className="col-md-6"
            style={{
              marginTop: 20,
              paddingBottom: 80,
            }}
          >
            <div style={{ padding: 20, paddingBottom: 0 }}>
              <div style={{ fontWeight: "bold" }}>
                <FontAwesomeIcon
                  className="icon-caret-down-detail"
                  icon="caret-down"
                />
                ຄະນະ, ພາກວິຊາ ແລະ ສາຂາວິຊາ
              </div>
              <div style={{ paddingLeft: 20, fontSize: 14 }}>
                <Row>
                  <Col>ຄະນະ</Col>
                  <Col>{newData?.faculty?.name ?? "-"}</Col>
                </Row>
                <Row>
                  <Col>ພາກວິຊາ</Col>
                  <Col>{newData?.department?.name ?? "-"}</Col>
                </Row>
                <Row>
                  <Col>ສາຂາວິຊາ</Col>
                  <Col>{newData?.major?.name ?? "-"}</Col>
                </Row>
              </div>
            </div>

            <div style={{ padding: 20, paddingBottom: 0 }}>
              <div style={{ fontWeight: "bold" }}>
                <FontAwesomeIcon
                  className="icon-caret-down-detail"
                  icon="caret-down"
                />
                ຂໍ້ມູນນັກສຶກສາ
              </div>
              <div style={{ paddingLeft: 20, fontSize: 14 }}>
                <Row>
                  <Col>ລະຫັດນັກສຶກສາ</Col>
                  <Col>{newData?.studentNumber}</Col>
                </Row>
                <Row>
                  <Col>ຂໍ້ມູນນັກສຶກສາ</Col>
                  <Col>{newData?.personalType?.name}</Col>
                </Row>
                <Row>
                  <Col>ລະດັບສຶກສາ</Col>
                  <Col>{newData?.educationLevel?.name}</Col>
                </Row>
                <Row>
                  <Col>ຫ້ອງຮຽນ</Col>
                  <Col>{newData?.classRoom?.name}</Col>
                </Row>
                <Row>
                  <Col>ສະຖານະການຮຽນ</Col>
                  <Col>{convertStudyStatus(newData?.studyStatus)}</Col>
                </Row>
                <Row>
                  <Col>ປີຮຽນ</Col>
                  <Col>{newData?.yearLevel}</Col>
                </Row>
              </div>
            </div>

            <div style={{ padding: 20, paddingBottom: 0 }}>
              <div style={{ fontWeight: "bold" }}>
                <FontAwesomeIcon
                  className="icon-caret-down-detail"
                  icon="caret-down"
                />
                ຂໍ້ມູນທົ່ວໄປ
              </div>
              <div style={{ paddingLeft: 20, fontSize: 14 }}>
                <Row>
                  <Col>ຊື່ (ພາສາລາວ)</Col>
                  <Col>{newData?.firstNameL}</Col>
                </Row>
                <Row>
                  <Col>ນາມສະກຸນ (ພາສາລາວ)</Col>
                  <Col>{newData?.lastNameL}</Col>
                </Row>
                <Row>
                  <Col>ຊື່ (ພາສາອັງກິດ)</Col>
                  <Col>{newData?.firstNameE}</Col>
                </Row>
                <Row>
                  <Col>ນາມສະກຸນ (ພາສາອັງກິດ)</Col>
                  <Col>{newData?.lastNameE}</Col>
                </Row>
                <Row>
                  <Col>ວັນເດືອນປີເກີດ</Col>
                  <Col>{formatDate(newData?.birthday)}</Col>
                </Row>
                <Row>
                  <Col>ເພດ</Col>
                  <Col>{Gender ?? "-"}</Col>
                </Row>
                <Row>
                  <Col>ສະຖານະ</Col>
                  <Col>
                    {newData &&
                      (newData?.maritualStatus
                        ? MaritualStatus(newData?.maritualStatus)
                        : "-")}
                  </Col>
                </Row>
                <Row>
                  <Col>ສັນຊາດ</Col>
                  <Col>{newData?.nationality ?? "-"}</Col>
                </Row>
                <Row>
                  <Col>ຊົນເຜົ່າ</Col>
                  <Col>
                    {newData?.tribe
                      ? dataTribes?.filter(
                          (data) => data.id === newData?.tribe
                        )[0]?.name
                      : "-"}
                  </Col>
                </Row>
                <Row>
                  <Col>ສາສະໜາ</Col>
                  <Col>
                    {newData?.religion
                      ? dataReligions?.filter(
                          (data) => data.id === newData?.religion
                        )[0]?.name
                      : "-"}
                  </Col>
                </Row>
                <Row>
                  <Col>ເບີໂທເຮືອນ</Col>
                  <Col>{newData?.phoneHome ?? "-"}</Col>
                </Row>
                <Row>
                  <Col>ເບີໂທລະສັບມືຖື</Col>
                  <Col>{newData?.phoneMobile ?? "-"}</Col>
                </Row>
                <Row>
                  <Col>ອີເມວ</Col>
                  <Col>{newData?.email ?? "-"}</Col>
                </Row>
              </div>
            </div>
            <div style={{ padding: 20, paddingBottom: 0 }}>
              <div style={{ fontWeight: "bold" }}>
                <FontAwesomeIcon
                  className="icon-caret-down-detail"
                  icon="caret-down"
                />
                ທີ່ຢູ່ປັດຈຸບັນ
              </div>
              <div style={{ paddingLeft: 20, fontSize: 14 }}>
                {newData?.studentType === "IN_PLAN_EN" ||
                newData?.studentType === "OUT_PLAN_EN" ? (
                  <Row>
                    <Col>ປະເທດ</Col>
                    <Col>{newData?.address?.country ?? "-"}</Col>
                  </Row>
                ) : (
                  <></>
                )}
                <Row>
                  <Col>ແຂວງ</Col>
                  <Col>{newData?.address?.province ?? "-"}</Col>
                </Row>
                <Row>
                  <Col>ເມືອງ</Col>
                  <Col>{newData?.address?.district ?? "-"}</Col>
                </Row>
                <Row>
                  <Col>ບ້ານ</Col>
                  <Col>{newData?.address?.village ?? "-"}</Col>
                </Row>
              </div>
            </div>
            <div style={{ padding: 20, paddingBottom: 0 }}>
              <div style={{ fontWeight: "bold" }}>
                <FontAwesomeIcon
                  className="icon-caret-down-detail"
                  icon="caret-down"
                />
                ສະຖານທີ່ເກີດ
              </div>
              <div style={{ paddingLeft: 20, fontSize: 14 }}>
                {newData?.bornAbroad === "" ? (
                  <>
                    {newData?.studentType === "IN_PLAN_EN" ||
                    newData?.studentType === "OUT_PLAN_EN" ? (
                      <Row>
                        <Col>ປະເທດ</Col>
                        <Col>{newData?.birthAddress?.country ?? "-"}</Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                    <Row>
                      <Col>ແຂວງ</Col>
                      <Col>{newData?.birthAddress?.province ?? "-"}</Col>
                    </Row>
                    <Row>
                      <Col>ເມືອງ</Col>
                      <Col>{newData?.birthAddress?.district ?? "-"}</Col>
                    </Row>
                    <Row>
                      <Col>ບ້ານ</Col>
                      <Col>{newData?.birthAddress?.village ?? "-"}</Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col>ແຂວງ, ເມືອງ, ບ້ານ</Col>
                    <Col>{newData?.bornAbroad ?? "-"}</Col>
                  </Row>
                )}
              </div>
            </div>
            <div style={{ padding: 20, paddingBottom: 0 }}>
              {newData?.studentType === "IN_PLAN" ||
              newData?.studentType === "IN_PLAN_EN" ? (
                <>
                  <div style={{ fontWeight: "bold" }}>
                    <FontAwesomeIcon
                      className="icon-caret-down-detail"
                      icon="caret-down"
                    />
                    ສຳຫຼັບນັກ​ສຶກ​ສາ/ພະ​ນັກ​ງານທຶນ​ແບ່ງ​ປັນ
                  </div>
                  <div style={{ paddingLeft: 20, fontSize: 14 }}>
                    <Row>
                      <Col>ທຶນແບ່ງປັນ</Col>
                      <Col>{newData?.scholarship ?? "-"}</Col>
                    </Row>
                    {newData?.studentType === "IN_PLAN_EN" ? (
                      <Row>
                        <Col>ຈົບຈາກປະເທດ</Col>
                        <Col>
                          {newData?.comingFrom?.graduatedCountry ?? "-"}
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                    <Row>
                      <Col>ຈົບຈາກແຂວງ</Col>
                      <Col>{newData?.comingFrom?.graduatedProvince ?? "-"}</Col>
                    </Row>
                    <Row>
                      <Col>ຈົບຈາກໂຮງຮຽນ</Col>
                      <Col>{newData?.comingFrom?.graduatedSchool ?? "-"}</Col>
                    </Row>
                    <Row>
                      <Col>ສົກສຶກສາ</Col>
                      <Col>
                        {newData?.comingFrom?.graduatedAccessMentYear ?? "-"}
                      </Col>
                    </Row>
                    <Row>
                      <Col>ເລກທີ່ຂໍ້ຕົກລົງເສັງໄດ້ທຶນ ມຊ</Col>
                      <Col>{newData?.scholarshipAgreementNo ?? "-"}</Col>
                    </Row>
                    <Row>
                      <Col>ເລກທີໃບນຳສົ່ງ</Col>
                      <Col>{newData?.transferLetterNo ?? "-"}</Col>
                    </Row>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ fontWeight: "bold" }}>
                    <FontAwesomeIcon
                      className="icon-caret-down-detail"
                      icon="caret-down"
                    />
                    ສຳລັບນັກສຶກສາຈ່າຍຄ່າຮຽນ
                  </div>
                  <div style={{ paddingLeft: 20, fontSize: 14 }}>
                    {newData?.studentType === "OUT_PLAN_EN" ? (
                      <>
                        <Row>
                          <Col>ທຶນແບ່ງປັນ</Col>
                          <Col>{newData?.scholarship ?? "-"}</Col>
                        </Row>
                        <Row>
                          <Col>ຈົບຈາກປະເທດ</Col>
                          <Col>
                            {newData?.payTuition?.graduatedCountry ?? "-"}
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <></>
                    )}
                    <Row>
                      <Col>ຈົບຈາກແຂວງ</Col>
                      <Col>{newData?.payTuition?.graduatedProvince ?? "-"}</Col>
                    </Row>
                    <Row>
                      <Col>ຈົບຈາກໂຮງຮຽນ</Col>
                      <Col>{newData?.payTuition?.graduatedSchool ?? "-"}</Col>
                    </Row>
                    <Row>
                      <Col>ສົກສຶກສາ</Col>
                      <Col>
                        {newData?.payTuition?.graduatedAccessMentYear ?? "-"}
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </div>
            <div style={{ padding: 20, paddingBottom: 0 }}>
              <div style={{ fontWeight: "bold" }}>
                <FontAwesomeIcon
                  className="icon-caret-down-detail"
                  icon="caret-down"
                />
                ໄອດີ ແລະ ລະຫັດຜ່ານ
              </div>
              <div style={{ paddingLeft: 20, fontSize: 14 }}>
                <Row>
                  <Col>ໄອດີ</Col>
                  <Col>{newData?.userId ?? "-"}</Col>
                </Row>
                <Row>
                  <Col>ລະຫັດຜ່ານ</Col>
                  <Col>{newData?.userId ?? "-"}</Col>
                </Row>
              </div>
            </div>
            <div style={{ padding: 20, paddingBottom: 0 }}>
              <div style={{ fontWeight: "bold" }}>
                <FontAwesomeIcon
                  className="icon-caret-down-detail"
                  icon="caret-down"
                />
                ຕິດຕໍ່ສຸກເສີນ
              </div>
              <div style={{ paddingLeft: 20, fontSize: 14 }}>
                <Row>
                  <Col>ພົວພັນທ່ານ</Col>
                  <Col>{newData?.emergencyContact?.relationship ?? "-"}</Col>
                </Row>
                <Row>
                  <Col>ເບີຕິດຕໍ່</Col>
                  <Col>{newData?.parentPhone ?? "-"}</Col>
                </Row>
              </div>
            </div>

            <div style={{ padding: 20, paddingBottom: 0 }}>
              <div style={{ fontWeight: "bold" }}>
                <FontAwesomeIcon
                  className="icon-caret-down-detail"
                  icon="caret-down"
                />
                ອື່ນໆ
              </div>
              <div style={{ paddingLeft: 20, fontSize: 14 }}>
                <Row>
                  <Col>ໝາຍເຫດ</Col>
                  <Col>{newData?.note ?? "-"}</Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        {/* ------- Delete Modal ------ */}
        <CourseDeleteConfirm
          showDeleteConfirmView={showDeleteConfirmView}
          _handleDeleteConfirmViewClose={_handleDeleteConfirmViewClose}
          _deleteConfirm={_deleteConfirm}
          newData={newData}
        />
      </CustomContainer>
    </div>
  );
}

export default StudentDetail;
