import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";
/**
 *
 * @Library
 *
 */
import * as _ from "lodash";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { Breadcrumb, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
/**
 *
 * @Component
 *
 */
import { CustomContainer, Title } from "../../common";
import CodePreregistrationEditConfirm from "./CodePreregistrationEditConfirm";
/**
 *
 * @Constant
 *
 */
import Routers from "../../consts/router";
/**
 *
 * @Apollo
 *
 */
import { FACULTIES } from "../../apollo/faculty";
import { DEPARTMENTS } from "../../apollo/deparment";
import { CODES } from "./../../apollo/codePreregistration";
/**
 *
 * @Function
 *s
 */
import { valiDate } from "../../common/super";
import Loading from "../../common/Loading";
import { MAJORS } from "../../apollo/major";
import getStudentNumber from "../../helpers/getStudentNumber";

function CodePreregistrationEdit() {
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const { history, match } = useReactRouter();
  const codeId = match?.params?.id;

  // state
  const [formParam, setFormParam] = useState({})
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false)
  const [dataCode, setDataCode] = useState({})
  const [dataFaculties, setDataFaculties] = useState([]);
  const [facultyId, setFacultyId] = useState("");
  const [facultyName, setFacultyName] = useState("")

  const [dataDepartments, setDataDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState("")
  const [departmentName, setDepartmentName] = useState("")

  const [dataMajors, setDataMajors] = useState([])
  const [majorName, setMajorName] = useState("")
  const [preStudentNumber, setPreStudentNumber] = useState("");
  const [studentType, setStudentType] = useState("");
  const [educationLevel, setEducationLevel] = useState("");
  const [studentCode, setStudentCode] = useState("");

  const currentYear = new Date().getFullYear();
  /**
   *
   * @Apollo
   *
   */
  const [loadDataCode, { loading, data: codeData }] = useLazyQuery(CODES);
  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTIES);
  const [loadDepartments, { data: departmentData }] = useLazyQuery(DEPARTMENTS);
  const [loadMajors, { data: majorData }] = useLazyQuery(MAJORS)
  /**
   *
   * @UseEffect
   *
   */

  useEffect(() => {
    loadDataCode({
      variables: {
        where: {
          id: codeId
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeId])

  useEffect(() => {
    if (!codeData) return;

    setDataCode(codeData?.codes?.data[0])
    setFacultyId(codeData?.codes?.data[0]?.faculty?.id)
    setFacultyName(codeData?.codes?.data[0]?.faculty?.name)

    loadDepartments({
      variables: { where: { isDeleted: false, faculty: codeData?.codes?.data[0]?.faculty?.id, assessmentYear: accessmentRedux } },
    });
    setDepartmentName(codeData?.codes?.data[0]?.department?.name)

    loadMajors({
      variables: { where: { isDeleted: false, department: codeData?.codes?.data[0]?.department?.id, assessmentYear: accessmentRedux } },
    });
  
    setMajorName(codeData?.codes?.data[0]?.major?.name)
    const studentCode = codeData?.codes?.data[0]?.code
    if (codeData?.codes?.data[0]?.studentType === "IN_PLAN" || codeData?.codes?.data[0]?.studentType === "OUT_PLAN") {
      const firstFourDigits = studentCode.substring(4);
      setStudentCode(firstFourDigits)
    } else {
      const firstFourDigits = studentCode.substring(5);
      setStudentCode(firstFourDigits)
    }

    setStudentType(codeData?.codes?.data[0]?.studentType)
    setEducationLevel(codeData?.codes?.data[0]?.educationLevel)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeData])

  useEffect(() => {
    setPreStudentNumber(
      getStudentNumber({
        faculty: facultyId,
        degree: educationLevel,
        studentType: studentType,
      })
    );
  }, [studentType, facultyId, educationLevel]);
  useEffect(() => {
    loadFaculties({ variables: { where: { isDeleted: false, assessmentYear: accessmentRedux } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessmentRedux]);

  useEffect(() => {
    if (!facultyData) return;
    setDataFaculties(facultyData?.faculties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facultyData]);

  useEffect(() => {
    if (!departmentData) return;
    setDataDepartments(departmentData?.departments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentData]);

  useEffect(() => {
    if (!majorData) setDataMajors([]);
    setDataMajors(majorData?.majors?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [majorData]);

  useEffect(() => {
    if (facultyId !== "") {
      loadDepartments({
        variables: { where: { isDeleted: false, faculty: facultyId, assessmentYear: accessmentRedux } },
      });
      // loadMajors({
      //   variables: {
      //     where: {
      //       isDeleted: false,
      //       department: departmentId,
      //       assessmentYear: accessmentRedux,
      //     },
      //   },
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facultyId]);

  useEffect(() => {
    if (departmentId !== "") {
      loadMajors({
        variables: { where: { isDeleted: false, department: departmentId, assessmentYear: accessmentRedux } },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);
  /**
   *
   * @Function
   *
   */
  const _handleAddConfirmModalClose = () => setShowAddConfirmModal(false);
  const _handleAddConfirmModalShow = () => setShowAddConfirmModal(true);

  const _selectFaculty = (e) => {
    setFacultyId(e.target.value);
    setDepartmentId("")
    const facIndex = _.findIndex(dataFaculties, { id: e.target.value });
    setFacultyName(dataFaculties[facIndex]?.name);
  }
  const _onSelectedDepartment = (e) => {
    setDepartmentId(e.target.value)
    const depIndex = _.findIndex(dataDepartments, { id: e.target.value })
    setDepartmentName(dataDepartments[depIndex]?.name);
  }
  const _onSelectedMajor = (e) => {
    const majIndex = _.findIndex(dataMajors, { id: e.target.value })
    setMajorName(dataMajors[majIndex]?.name);
  }
  if (loading) return (<Loading />)
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push(Routers.CODE_PRERE_LIST + Routers.PAGE_GINATION)}
        >
          ລະຫັດລົງທະບຽນເຂົ້າຮຽນ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() => history.push(Routers.CODE_PRERE_LIST + Routers.PAGE_GINATION)}
        >
          ລະຫັດລົງທະບຽນເຂົ້າຮຽນທັງໝົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ແກ້ໄຂລະຫັດລົງທະບຽນເຂົ້າຮຽນ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer className="pl-5 pr-5">
        <Title text={"ແກ້ໄຂລະຫັດລົງທະບຽນເຂົ້າຮຽນ"} />
        {dataCode &&
          <Formik
            enableReinitialize
            initialValues={{
              firstNameL: dataCode?.firstNameL ?? "",
              lastNameL: dataCode?.lastNameL ?? "",
              firstNameE: dataCode?.firstNameE ?? "",
              lastNameE: dataCode?.lastNameE ?? "",
              gender: dataCode?.gender ?? "MALE",
              // birthday: dataCode?.birthday ?? "",
              faculty: dataCode?.faculty?.id ?? "",
              department: dataCode?.department?.id ?? "",
              major: dataCode?.major?.id ?? "",
              educationLevel: dataCode?.educationLevel ?? "",
              day: dataCode?.birthday ? dataCode.birthday.split('-')[2] : "",
              month: dataCode?.birthday ? dataCode.birthday.split('-')[1] : "",
              year: dataCode?.birthday ? dataCode.birthday.split('-')[0] : "",
              code: studentCode ?? "",
              studentType: dataCode?.studentType ?? "",
              assessmentYear: accessmentRedux,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.code) {
                errors.code = "ກະລຸນາປ້ອນລະຫັດລົງທະບຽນເຂົ້າຮຽນ"
              }
              if (!values.faculty) {
                errors.faculty = "ກະລຸນາເລືອກຄະນະ"
              }
              if (!values.department) {
                errors.department = "ກະລຸນາເລືອກພາກວິຊາ"
              }
              if (!values.major) {
                errors.major = "ກະລຸນາເລືອກສາຂາວິຊາ"
              }
              if (!values.educationLevel) {
                errors.educationLevel = "ກະລຸນາເລືອກລະດັບສຶກສາ"
              }
              if (!values.studentType) {
                errors.studentType = "ກະລຸນາເລືອກປະເພດນັກສຶກສາ"
              }
              if (!values.day) {
                errors.day = "ກະລຸນາເລືອກວັນ"
              }
              if (!values.month) {
                errors.month = "ກະລຸນາເລືອກເດີືອນ"
              }
              if (!values.year) {
                errors.year = "ກະລຸນາເລືອກປີ"
              }
              return errors;
            }}
            onSubmit={async (values) => {
              if (!values.firstNameE) {
                delete values.firstNameE
              }
              if (!values.lastNameE) {
                delete values.lastNameE
              }
              const birthday = `${values.year}-${values.month}-${values.day}`;
              let data = { ...values, birthday, preStudentNumber: preStudentNumber }
              let paramQL = { data }
              setFormParam(paramQL);
              _handleAddConfirmModalShow();
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
            }) => (
              <Form>
                <Row>
                  <Col sm={12}>
                    <div className="heading">
                      <b>ຂໍ້ມູນລະຫັດລົງທະບຽນເຂົ້າຮຽນ</b>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {/* <Col sm={12}>
                    <Form.Label>ລະຫັດລົງທະບຽນເຂົ້າຮຽນ {valiDate()}</Form.Label>
                    <Form.Control
                      type="text"
                      name="code"
                      value={values.code}
                      isInvalid={!!errors.code}
                      placeholder="ກະລຸນາປ້ອນລະຫັດລົງທະບຽນເຂົ້າຮຽນ"
                      onChange={handleChange}
                    />
                  </Col> */}
                  <Col sm={4}>
                    <Form.Label>ຄະນະ {valiDate()}</Form.Label>
                    <Form.Control
                      as="select"
                      name="faculty"
                      value={values.faculty}
                      isInvalid={!!errors.faculty}
                      onChange={(e) => {
                        _selectFaculty(e);
                        handleChange(e);
                        values.faculty = ""
                        values.department = ""
                        values.major = ""
                        // values.studentType = ""
                        // values.educationLevel = ""
                      }}
                    >
                      <option value="">---ກະລຸນາເລືອກຄະນະ---</option>
                      {dataFaculties?.map((x, index) => (
                        <option key={index} value={x?.id}>{x.name}</option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col sm={4}>
                    <Form.Label>ພາກວິຊາ {valiDate()}</Form.Label>
                    <Form.Control
                      as="select"
                      name="department"
                      value={values.department}
                      isInvalid={!!errors.department}
                      onChange={(e) => {
                        _onSelectedDepartment(e);
                        handleChange(e);
                        values.major = ""
                      }}
                    >
                      <option value="">---ກະລຸນາເລືອກສາຂາວິຊາ---</option>
                      {dataDepartments?.map((x, index) => (
                        <option key={index} value={x?.id}>{x.name}</option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col sm={4}>
                    <Form.Label>ສາຂາວິຊາ {valiDate()}</Form.Label>
                    <Form.Control
                      as="select"
                      name="major"
                      value={values.major}
                      isInvalid={!!errors.major}
                      onChange={(e) => {
                        _onSelectedMajor(e);
                        handleChange(e);
                      }}
                    >
                      <option value="">---ກະລຸນາເລືອກສາຂາວິຊາ---</option>
                      {dataMajors?.map((x, index) => (
                        <option key={index} value={x?.id}>{x.name}</option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col sm={4}>
                    <Form.Label>ລະດັບສຶກສາ {valiDate()}</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        handleChange(e);
                        setEducationLevel(e.target.value)
                      }}
                      name="educationLevel"
                      value={values.educationLevel}
                      isInvalid={!!errors.educationLevel}
                    >
                      <option value="">---ເລືອກລະດັບສຶກສາ---</option>
                      <option value="DEPLOMA">ອະນຸປະລິນຍາ</option>
                      <option value="BACHELOR_DEGREE">ປະລິນຍາຕີ</option>
                      <option value="BACHELOR_CONTINUING">
                        ຕໍ່ເນື່ອງປະລິນຍາຕີ
                      </option>
                    </Form.Control>
                  </Col>
                  <Col sm={4}>
                    <Form.Label>ປະເພດນັກສຶກສາ {valiDate()}</Form.Label>
                    <Form.Control
                      as="select"
                      name="studentType"
                      value={values.studentType}
                      isInvalid={!!errors.studentType}
                      onChange={(e) => {
                        handleChange(e)
                        setStudentType(e.target.value)
                      }}
                    >
                      <option value="">---ກະລຸນາເລືອກປະເພດນັກສຶກສາ---</option>
                      <option value="IN_PLAN">ນັກສຶກສາລາວ (ໃນແຜນ)</option>
                      <option value="OUT_PLAN">ນັກສຶກສາລາວ (ນອກແຜນ)</option>
                      <option value="IN_PLAN_EN">ນັກສຶກສາຕ່າງປະເທດ (ໃນແຜນ)</option>
                      <option value="OUT_PLAN_EN">ນັກສຶກສາຕ່າງປະເທດ (ນອກແຜນ)</option>
                    </Form.Control>
                  </Col>
                  <Col sm={4}>
                    <Form.Label>ລະຫັດລົງທະບຽນເຂົ້າຮຽນ {valiDate()}</Form.Label>
                    <InputGroup>
                      {preStudentNumber && (
                        <InputGroup.Text
                          style={{ height: "40px", lineHeight: "40px" }}
                        >
                          {preStudentNumber}
                        </InputGroup.Text>
                      )}
                      <Form.Control
                        type="text"
                        className="form-control"
                        name="code"
                        value={values.code}
                        isInvalid={!!errors.code}
                        placeholder="ກະລຸນາປ້ອນລະຫັດລົງທະບຽນເຂົ້າຮຽນ"
                        style={{ height: "40px" }} // Ensuring height is the same
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <br />
                    <div className="heading">
                      <b>ຂໍ້ມູນທົ່ວໄປ</b>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Label>ຊື່ (ພາສາລາວ)</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstNameL"
                      value={values.firstNameL}
                      // isInvalid={!!errors.firstNameL}
                      placeholder="ກະລຸນາປ້ອນຊື່"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Label>ນາມສະກຸນ (ພາສາລາວ)</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastNameL"
                      value={values.lastNameL}
                      // isInvalid={!!errors.lastNameL}
                      placeholder="ກະລຸນາປ້ອນນາມສະກຸນ"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Label>ຊື່ (ພາສາອັງກິດ)</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstNameE"
                      value={values.firstNameE}
                      isInvalid={!!errors.firstNameE}
                      placeholder="ກະລຸນາປ້ອນຊື່"
                      onChange={handleChange}
                    />
                  </Col>
                  <Col sm={6}>
                    <Form.Label>ນາມສະກຸນ (ພາສາອັງກິດ)</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastNameE"
                      value={values.lastNameE}
                      isInvalid={!!errors.lastNameE}
                      placeholder="ກະລຸນາປ້ອນນາມສະກຸນ"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}>
                    <Form.Label>ເລືອກເພດ {valiDate()}</Form.Label>
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      inline
                      label="ຊາຍ"
                      type="radio"
                      value="MALE"
                      id="gender-radio"
                      name="gender"
                      onChange={handleChange}
                      checked={values.gender === "MALE" ? true : false}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      inline
                      label="ຍິງ"
                      type="radio"
                      value="FEMALE"
                      id="gender-radio"
                      name="gender"
                      onChange={handleChange}
                      checked={values.gender === "FEMALE" ? true : false}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      inline
                      label="ພຣະ"
                      type="radio"
                      value="MONK"
                      id="gender-radio"
                      name="gender"
                      onChange={handleChange}
                      checked={values.gender === "MONK" ? true : false}
                    />
                  </Col>
                  <Col sm={2}>
                    <Form.Check
                      inline
                      label="ສຳມະເນນ"
                      type="radio"
                      value="NOVICE"
                      id="gender-radio"
                      name="gender"
                      onChange={handleChange}
                      checked={values.gender === "NOVICE" ? true : false}
                    />
                  </Col>
                </Row>
                <Row>
                  <Form.Label>ວັນເດືອນປີເກີດ {valiDate()}</Form.Label>
                  <Col sm={2}>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="day"
                      value={values.day}
                      isInvalid={!!errors.day}
                    >
                      <option value="">ວັນ</option>
                      {Array.from({ length: 31 }, (_, i) => {
                        const value = String(i + 1).padStart(2, '0');
                        return (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Col sm={2}>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="month"
                      value={values.month}
                      isInvalid={!!errors.month}
                    >
                      <option value="">ເດືອນ</option>
                      {Array.from({ length: 12 }, (_, i) => {
                        const value = String(i + 1).padStart(2, '0');
                        return (
                          <option key={value} value={value}>
                            {value}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Col sm={2}>
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="year"
                      value={values.year}
                      isInvalid={!!errors.year}
                    >
                      <option value="">ປີ</option>
                      {Array.from({ length: 100 }, (_, i) => (
                        <option key={currentYear - i} value={currentYear - i}>
                          {currentYear - i}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col sm={6}>
                    <Button
                      variant="outline-secondary"
                      className="btn secondary float-right"
                      onClick={() => history.push(Routers.CODE_PRERE_LIST + Routers.PAGE_GINATION)}
                    >
                      ຍົກເລີກ
                    </Button>
                  </Col>
                  <Col sm={6}>
                    <Button className="btn btn-info" onClick={handleSubmit}>
                      ແກ້ໄຂຂໍ້ມູນ
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        }
      </CustomContainer>
      <CodePreregistrationEditConfirm
        showAddConfirmModal={showAddConfirmModal}
        _handleAddConfirmModalClose={_handleAddConfirmModalClose}
        param={formParam}
        faculty={facultyName}
        department={departmentName}
        major={majorName}
        codeId={codeId}
      />
    </div>
  );
}
export default CodePreregistrationEdit;