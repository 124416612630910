import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import useReactRouter from 'use-react-router'
import { useMutation } from '@apollo/react-hooks';
import Consts from '../../consts'
import { UPDATE_COURSE } from '../../apollo/course'

const CourseDocDeleteConfirm = ({
  courseDocDeleteConfirmModal,
  _handlCourseDocDeleteConfirmModalClose,
  dataDelete,
  courseFiles
}) => {
  const [updateCourse] = useMutation(UPDATE_COURSE);
  const [newData, setNewData] = useState([])

  useEffect(() => {
    let _files = []
    for (var i = 0; i < newData.length; i++) {
      _files.push(newData[i].id)
    }
    let paramQL = {
      data: {
        files: _files,
        isUpgrade: true,
        isRegistration: true
      },
      where: {
        id: dataDelete?.courseData?.id
      }
    };
    // console.log("paramQL delete-->", paramQL)
    updateCourse({ variables: paramQL }).then((x) => {
      // history.push(Routers.COURSE_DETAIL+'/id/'+dataDelete.courseData.id, dataDelete.courseData)
      window.location.reload(true)
    }).catch((err) => {
      _handlCourseDocDeleteConfirmModalClose()
    });
  }, [newData])

  const _confirmDelete = () => {
    const temp = [...courseFiles];
    temp.splice(dataDelete?.index, 1);
    setNewData(temp);
  }

  return (
    <div>
      <Modal
        show={courseDocDeleteConfirmModal}
        onHide={_handlCourseDocDeleteConfirmModalClose}
        size='lg'
      >
        <Modal.Title style={{ textAlign: 'center', paddingTop: 20, color: Consts.BORDER_COLOR_DELETE, fontWeight: 'bold' }}>
          ຕ້ອງການລຶບເອກະສານ?
        </Modal.Title>
        <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
          <p className='text-center'>{dataDelete && dataDelete.dataDelete && dataDelete.dataDelete.title}</p>

          <div style={{ height: 20 }} />
          <div className='row' style={{ textAlign: 'center' }}>
            <div style={{ padding: 15 }} className='col'>
              <Button
                onClick={_handlCourseDocDeleteConfirmModalClose}
                style={{
                  width: '60%',
                  backgroundColor: '#fff',
                  color: '#6f6f6f',
                  borderColor: Consts.DELETE_COLOR_BUTTON,
                  borderRadius: 5
                }}
              >
                ຍົກເລີກ
              </Button>
            </div>
            <div style={{ padding: 15 }} className='col'>
              <Button
                style={{
                  width: '60%',
                  backgroundColor: Consts.DELETE_COLOR_BUTTON,
                  color: '#fff',
                  borderColor: Consts.DELETE_COLOR_BUTTON,
                  borderRadius: 5
                }}
                onClick={() => _confirmDelete()}
              >
                ລົບ
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CourseDocDeleteConfirm
