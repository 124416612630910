// utils.js

export const getStudentTypeDisplay = (type) => {
  return type === "OUT_PLAN" || type === "OUT_PLAN_EN"
    ? "ນອກເຜນ"
    : type === "IN_PLAN" || type === "IN_PLAN_EN"
    ? "ໃນເເຜນ"
    : type;
};

export const getEducationLevel = (type) => {
  switch (type.trim()) {
    case "BACHELOR_DEGREE":
      return "ປະລິນຍາຕີ";
    case "BACHELOR_CONTINUING":
      return "ຕໍ່ເນື່ອງປະລິນຍາຕີ";
    case "DEPLOMA":
      return "ຊັ້ນສູງ";
    default:
      return type;
  }
};
