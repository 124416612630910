import React from 'react'
import Consts from '../consts'

const Title = ({text}) => {
  return (
    <div style={{fontSize: 22, color: Consts.PRIMARY_COLOR, fontWeight: 'bold', marginTop: 8, marginBottom: 20}}>
      {text}
    </div>
  )
}

export default Title;
