import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import { toast } from 'react-toastify';
import Consts, { USER_KEY } from '../../../consts';
import { API_KEY, NEW_API_URL } from '../../../common/contrant';
import axios from 'axios';
import { Close } from '@mui/icons-material';

const ConfirmPaymentDialog = ({ setOnSuccess, setShowConfirm, showConfirm, paymentID }) => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);
    const token = userObject?.accessToken
    const handlePayment = async (id) => {
        try {
            const res = await axios.post(
                NEW_API_URL + 'register/payment/' + id,
                {
                    headers: {
                        api_key: API_KEY,
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            if (res.status === 200) {
                setOnSuccess(true)
                setShowConfirm(false)
                toast.success("ຈ່າຍເງິນສຳເລັດ");
            }
        } catch (error) {
            toast.error("ຈ່າຍເງິນບໍ່ສຳເລັດ");
        }

    };

    return (
        <div>
            <Dialog
                PaperProps={{
                    sx: {
                        height: 200,
                    }
                }}
                open={showConfirm}
                onClose={() => setShowConfirm(false)}
            >
               <IconButton
                    aria-label="close"
                    onClick={() => setShowConfirm(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                    <h4>ທ່ານຕ້ອງການຊຳລະເງິນແທ້ບໍ່?</h4>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center', alignItems: "center", marginBottom: "20px" }}>
                    <Button
                        sx={{ width: "150px" }}
                        variant='contained'
                        color='primary'
                        onClick={() => handlePayment(paymentID)}
                    >
                        ຢືນຢັນການຊຳລະ
                    </Button>
                    <Button
                        sx={{ width: "150px" }}
                        variant='contained'
                        color='warning'
                        onClick={() => setShowConfirm(false)}
                    >
                        ຍົກເລີກ
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ConfirmPaymentDialog