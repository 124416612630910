import { NEW_API_URL, API_KEY } from "../../common/contrant";
import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { Paper } from "@mui/material";
import { useSelector } from "react-redux";
import { USER_KEY } from "../../consts";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const AddSubbjectDialog = ({ onSubmit, onError, onSuccess, selectedItem }) => {
  const filterData = useSelector((state) => state.filter);
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;

  useEffect(() => {
    if (onSubmit) {
      handleSubmit();
    }
  }, [onSubmit]);

  const [errors, setErrors] = useState({
    faculty: "",
    department: "",
    title: "",
    educationLevel: "",
    credit: "",
    code: "",
    type: "",
  });

  const [formData, setFormData] = useState({
    faculty: filterData.faculty || "",
    department: filterData.department || "",
    educationLevel: "",
    credit: "",
    code: "",
    titleEn: "",
    // false when is insource subject
    type: true,
  });

  useEffect(() => {
    // check if is on update then set initial data is selected item
    // and else then set intitial data is from filter data in redux state
    if (selectedItem) {
      setFormData({
        faculty: selectedItem?.faculty?._id,
        department: selectedItem?.department?._id,
        major: selectedItem?.major?._id,
        title: selectedItem?.title,
        titleEn: selectedItem?.titleEn,
        educationLevel: selectedItem?.educationLevel?._id,
        credit: selectedItem?.credit,
        unit: formData.credit?.substring(0, 1),
        type: selectedItem?.type === true || false,
        code: selectedItem?.code || "",
      });
    } else {
      setFormData({
        faculty: filterData.faculty || "",
        department: filterData.department || "",
        educationLevel: "",
        credit: "",
        titleEn: "",
        code: "",
        // false when is insource subject
        type: true,
      });
    }
  }, []);

  // useEffect(() => {
  //   if (formData.type === false) {
  //     setFormData({
  //       ...formData,
  //       faculty: undefined,
  //       department: undefined,
  //     });
  //   } else {
  //     setFormData({
  //       ...formData,
  //       faculty: filterData?.faculty,
  //     });
  //   }
  // }, [formData.type, filterData]);

  function validateFormat(input) {
    // Define regular expressions for the required formats
    const patterns = [
      /^\d\(\d-\d-\d\)$/, // X(X-X-X)
      /^\d\(\d{2}-\d{2}-\d{2}\)$/, // X(XX-XX-XX)
      /^\d\(\d{2}-\d-\d\)$/, // X(XX-X-X)
      /^\d\(\d-\d{2}-\d\)$/, // X(X-XX-X)
      /^\d\(\d-\d-\d{2}\)$/, // X(X-X-XX)
      /^\d\(\d-\d{2}-\d{2}\)$/, // X(X-XX-XX)
    ];

    // Test the input against each regular expression
    for (const pattern of patterns) {
      if (pattern.test(input)) {
        return true;
      }
    }
    return false;
  }

  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    const pattern = /^\d\(\d-\d-\d\)$/;
    if (formData.type === undefined) {
      newErrors.type = "ກະລຸນາເລືອກປະເພດວິຊາ";
      valid = false;
    }

    if (!validateFormat(formData.credit)) {
      newErrors.credit = "ຮູບເເບບຫນ່ວຍກິດບໍ່ຖືກຕ້ອງ";
      valid = false;
    }

    if (!formData.code) {
      newErrors.code = "ກະລຸນາປ້ອນລະຫັດວິຊາ";
      valid = false;
    }

    if (!formData.credit) {
      newErrors.credit = "ກະລຸນາປ້ອນຫນ່ວຍກິດ";
      valid = false;
    }

    if (!formData.department && formData.type === true) {
      newErrors.department = "ກະລຸນາເລືອກພາກ";
      valid = false;
    }

    if (!formData.title) {
      newErrors.title = "ກະລຸນາປ້ອນຊື່ວິຊາ";
      valid = false;
    }

    if (!formData.educationLevel) {
      newErrors.educationLevel = "ກະລຸນາເລືອກລະດັບການສຶກສາ";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        let _formData = formData;
        _formData.unit = formData.credit?.substring(0, 1);
        if (formData.type === true) {
          _formData.faculty = userObject?.data?.faculty?.id;
        } else {
          _formData.faculty = undefined;
          _formData.faculty = undefined;
        }
        if (selectedItem?._id) {
          await axios.patch(
            `${NEW_API_URL}subject?id=${selectedItem._id}`,
            _formData,
            {
              headers: {
                api_key: API_KEY,
                Authorization: `Bearer ${token}`,
              },
            }
          );
        } else {
          await axios.post(`${NEW_API_URL}subject`, _formData, {
            headers: {
              api_key: API_KEY,
              Authorization: `Bearer ${token}`,
            },
          });
        }
        onSuccess();
      } catch (err) {
        onError();
        setErrors(true);
      }
    }
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });

    // Clear the error message when the user starts typing
    setErrors({
      ...errors,
      [event.target.name]: "",
    });
  };

  // const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);

  useEffect(() => {
    // Fetch departments
    axios
      .get(NEW_API_URL + "department/" + formData.faculty, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDepartments(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch education levels
    axios
      .get(NEW_API_URL + "educationlevel", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setEducationLevels(response.data))
      .catch((error) => console.error(error));
  }, []);

  // const handleOnBlur = () => {
  //   const credit = formData.credit || "";
  //   if (credit.length === 4) {
  //     const _credit = `${credit.substring(0, 1)}(${credit.substring(
  //       1,
  //       2
  //     )}-${credit.substring(2, 3)}-${credit.substring(3, 4)})`;
  //     setFormData({
  //       ...formData,
  //       credit: _credit,
  //     });
  //   }
  // };

  return (
    <div className="">
      <Paper elevation={0} className="px-3 mx-3">
        <form>
          <FormControl size="small" fullWidth margin="normal">
            <InputLabel htmlFor="type">
              ປະເພດວິຊາ
              <Tooltip
                title="ວິຊານອກເປັນວິຊາທີຄະນະອຶ່ນສາມາດນຳໃຊ້ໄປຮຽນໄດ້ ວິຊາໃນເປັນວິຊາທີຮຽນສະເພາະພາຍໃນຄະນະເທົ່ານັ້ນ"
                arrow
              >
                <HelpOutlineIcon
                  color="primary"
                  style={{
                    marginLeft: "5px",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </InputLabel>
            <Select
              size="small"
              label="ປະເພດວິຊາ"
              name="type"
              value={formData.type}
              onChange={handleChange}
            >
              {[
                { value: true, label: "ວິຊາໃນ" },
                { value: false, label: "ວິຊານອກ" },
              ].map((type) => (
                <MenuItem key={type?.value} value={type.value}>
                  {type?.label}
                </MenuItem>
              ))}
            </Select>
            <div style={{ color: "red" }}>{errors.type}</div>
          </FormControl>

          {formData.type === true && (
            <FormControl size="small" fullWidth margin="normal">
              <InputLabel htmlFor="department">ພາກ</InputLabel>
              <Select
                size="small"
                label="ພາກ"
                name="department"
                value={formData?.department}
                onChange={handleChange}
              >
                {departments.map((department) => (
                  <MenuItem key={department._id} value={department._id}>
                    {department?.name}
                  </MenuItem>
                ))}
              </Select>
              <div style={{ color: "red" }}>{errors.department}</div>
            </FormControl>
          )}

          <FormControl size="small" fullWidth>
            <TextField
              size="small"
              fullWidth
              label="ຊື່ວິຊາ"
              name="title"
              value={formData?.title}
              onChange={handleChange}
              margin="normal"
            />
            <div style={{ color: "red" }}>{errors.title}</div>
          </FormControl>
          <FormControl size="small" fullWidth>
            <TextField
              size="small"
              fullWidth
              label="ຊື່ວິຊາ ພາສາອັງກິດ"
              name="titleEn"
              value={formData?.titleEn}
              onChange={handleChange}
              margin="normal"
            />
          </FormControl>
          <FormControl size="small" fullWidth>
            <TextField
              size="small"
              fullWidth
              label="ລະຫັດວິຊາ"
              name="code"
              value={formData?.code}
              onChange={handleChange}
              margin="normal"
            />
            <div style={{ color: "red" }}>{errors.code}</div>
          </FormControl>
          <FormControl size="small" fullWidth margin="normal">
            <TextField
              // onBlur={handleOnBlur}
              size="small"
              placeholder="X(X-X-X)"
              fullWidth
              label="ຫນ່ວຍກິດ"
              name="credit"
              value={formData?.credit}
              onChange={handleChange}
            />
            <div style={{ color: "red" }}>{errors.credit}</div>
          </FormControl>

          <FormControl size="small" fullWidth margin="normal">
            <InputLabel htmlFor="educationLevel">ລະດັບການສຶກສາ</InputLabel>
            <Select
              size="small"
              label="ລະດັບການສຶກສາ"
              name="educationLevel"
              value={formData?.educationLevel || ""}
              onChange={handleChange}
            >
              {educationLevels.map((level) => (
                <MenuItem key={level?._id} value={level?._id}>
                  {level?.name}
                </MenuItem>
              ))}
            </Select>
            <div style={{ color: "red" }}>{errors.educationLevel}</div>
          </FormControl>
        </form>
      </Paper>
    </div>
  );
};

export default AddSubbjectDialog;
