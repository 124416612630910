import React from "react";
import { Modal, Button, Typography, Box } from "@mui/material";

const modalStyle = {
  position: "absolute",
  width: 400,
  backgroundColor: "#fff",
  boxShadow: 24,
  padding: 3,
  borderRadius: 2,
  outline: "none",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
};

const buttonContainerStyle = {
  marginTop: 2,
  display: "flex",
  justifyContent: "center",
  gap: 2,
};

const ConfirmModal = ({ open, message, title, onSubmit, onCancel }) => {
  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Typography variant="h5" id="modal-title">
          {title}
        </Typography>
        <Typography variant="body1" id="modal-description" mt={2}>
          {message}
        </Typography>
        <Box sx={buttonContainerStyle}>
          <Button
            sx={{ px: 4 }}
            onClick={onSubmit}
            variant="contained"
            color="error"
          >
            ຢືນຢັນ
          </Button>
          <Button
            sx={{ px: 4 }}
            onClick={onCancel}
            variant="contained"
            color="primary"
          >
            ຍົກເລີກ
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
