import React from "react";
import useReactRouter from "use-react-router";
import { useMutation } from "@apollo/react-hooks";

/**
 *
 * @Component
 *
 */
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";
import Routers from "../../consts/router";

/**
 *
 * @Apollo
 *
 */
import { CREATE_STUDENT } from "./../../apollo/student";
/**
 *
 * @Function
 *
 */

import { alertSuccess, messageWarning } from "../../common/super";
import addLogs from "../../helpers/AddLogs";
import axios from "axios";
import { NEW_API_URL, API_KEY } from "../../common/contrant";
import { USER_KEY } from "../../consts";

const user = localStorage.getItem(USER_KEY);
const userObject = JSON.parse(user);
const token = userObject?.accessToken;

function StudentAddConfirm({
  showAddConfirmModal,
  _handleShowAddConfirmModalClose,
  param,
  facalty,
  department,
  major,
  classroom,
}) {
  const { history } = useReactRouter();
  const [createStudent] = useMutation(CREATE_STUDENT);
  const _confirmStudentAdd = async () => {
    try {
      delete param.data.currentCountry;
      delete param.data.currentProvince;
      delete param.data.currentDistrict;
      delete param.data.currentVillage;

      delete param.data.birthCountry;
      delete param.data.birthProvince;
      delete param.data.birthDistrict;
      delete param.data.birthVillage;
      delete param.data.district;
      delete param.data.day;
      delete param.data.month;
      delete param.data.year;

      if (!param.data.email) {
        delete param.data.email;
      }
      if (!param.data.phoneMobile) {
        delete param.data.phoneMobile;
      }
      if (!param.data.phoneHome) {
        delete param.data.phoneHome;
      }
      if (!param.data.lastNameL) {
        delete param.data.lastNameL;
      }
      if (!param.data.firstNameE) {
        delete param.data.firstNameE;
      }
      if (!param.data.lastNameE) {
        delete param.data.lastNameE;
      }
      if (
        !param.data.birthday ||
        param.data.birthday === "undefined-undefined-undefined"
      ) {
        delete param.data.birthday;
      }
      if (!param.data.gender) {
        delete param.data.gender;
      }
      if (!param.data.maritualStatus) {
        delete param.data.maritualStatus;
      }
      if (!param.data.description) {
        delete param.data.description;
      }
      if (!param.data.note) {
        delete param.data.note;
      }
      if (
        !param.data.emergencyContact?.isLiveTogether ||
        param.data.emergencyContact?.isLiveTogether === "undefined"
      ) {
        delete param.data.emergencyContact?.isLiveTogether;
      }
      if (!param.data.graduatedAccessMentYear) {
        delete param.data.graduatedAccessMentYear;
      }
      if (!param.data.dateScholarshipAgreementNo) {
        delete param.data.dateScholarshipAgreementNo;
      }
      if (!param.data.dateTransferLetterNo) {
        delete param.data.dateTransferLetterNo;
      }
      if (!param.data.classRoom) {
        delete param.data.classRoom;
      }
      if (!param.data.transferLetterNo) {
        delete param.data.transferLetterNo;
      }
      if (!param.data.scholarshipAgreementNo) {
        delete param.data.scholarshipAgreementNo;
      }
      delete param.data.confirmPassword;
      // const createNew = await createStudent({ variables: param });

     const  studentAdd =  await axios.post(NEW_API_URL + "student", param, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      await addLogs({
        type: "STUDENT",
        status: "SUCCESS",
        action: "CREATE",
        data: param,
        student: studentAdd?.id,
      });
      await _handleShowAddConfirmModalClose();
      alertSuccess("ເພີ່ມນັກສຶກສາສຳເລັດ");
      setTimeout(() => {
        history.push(Routers.STUDENT_LIST + Routers.PAGE_GINATION);
        window.location.reload();
      }, 1500);
    } catch (err) {
      console.log("err: ", err);
      if (
        err?.message == "GraphQL error: USER_WITH_THIS_USERID_IS_ALREADY_EXIST"
      )
        messageWarning("ໄອດີນີ້ມີຢູ່ແລ້ວ ກະລຸນາປ້ອນໄອດີໃໝ່!");
    }
  };

  const _onConvertGenter = (gender) => {
    let result;
    switch (gender) {
      case "MALE":
        result = "ຊາຍ";
        break;
      case "FEMALE":
        result = "ຍິງ";
        break;
      case "OTHER":
        result = "ອື່ນໆ";
        break;
      default:
        result = "ຊາຍ";
    }
    return result;
  };

  const _onConvertMaritualStatus = (maritualStatus) => {
    let result;
    switch (maritualStatus) {
      case "SINGLE":
        result = "ໂສດ";
        break;
      case "MARRIAGE":
        result = "ແຕ່ງງານແລ້ວ";
        break;
      default:
        result = "ໂສດ";
    }
    return result;
  };

  return (
    <Modal
      show={showAddConfirmModal}
      onHide={_handleShowAddConfirmModalClose}
      size="lg"
    >
      <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
        ຢືນຢັນການເພີ່ມນັກຮຽນ
      </Modal.Title>
      <Modal.Body
        style={{
          marginLeft: 50,
          marginRight: 5,
          color: Consts.SECONDARY_COLOR0,
          padding: 50,
          paddingTop: 0,
        }}
      >
        <div>
          <div
            style={{
              backgroundColor: "#fff",
              padding: 10,
              marginTop: 20,
            }}
          >
            {/* Form container */}
            <div
              style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
            >
              {/* ---------- ຄະນະແລະພາກວິຊາ --------- */}
              <div style={{ marginBottom: 10 }}>
                <div>
                  <i
                    className="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
                  />
                  ຄະນະແລະພາກວິຊາ
                </div>
                {/* ຄະນະ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ຄະນະ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{facalty ? facalty : "-"}</span>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ພາກວິຊາ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{department ? department : "-"}</span>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ສາຂາວິຊາ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{major ? major : "-"}</span>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ປີຮຽນ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data &&
                        (param.data.yearLevel ? param.data.yearLevel : "-")}
                    </span>
                  </Col>
                </Form.Group>

                {/* ຫ້ອງຮຽນ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ຫ້ອງຮຽນ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{param.data && (classroom ? classroom : "-")}</span>
                  </Col>
                </Form.Group>
              </div>

              {/* ---------- ຂໍ້ມູນນັກຮຽນ --------- */}
              <div style={{ marginBottom: 10 }}>
                <div>
                  <i
                    className="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
                  />
                  ຂໍ້ມູນນັກຮຽນ
                </div>
                {/* ຊື່ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ຊື່
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data &&
                        (param.data.firstNameL
                          ? param.data.firstNameL
                          : "-")}{" "}
                    </span>
                  </Col>
                </Form.Group>

                {/* ນາມສະກຸນ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ນາມສະກຸນ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data &&
                        (param.data.lastNameL
                          ? param.data.lastNameL
                          : "-")}{" "}
                    </span>
                  </Col>
                </Form.Group>

                {/* ວັນເດືອນປີເກີດ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ວັນເດືອນປີເກີດ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>{param?.data?.birthday}</span>
                  </Col>
                </Form.Group>

                {/* ເພດ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ເພດ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data &&
                        (param.data.gender
                          ? _onConvertGenter(param.data.gender)
                          : "-")}
                    </span>
                  </Col>
                </Form.Group>

                {/* ສະຖານະ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ສະຖານະ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data &&
                        (param.data.maritualStatus
                          ? _onConvertMaritualStatus(param.data.maritualStatus)
                          : "-")}
                    </span>
                  </Col>
                </Form.Group>

                {/* ເບີໂທ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ເບີໂທ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param?.data?.phoneMobile
                        ? param?.data?.phoneMobile
                        : "-"}
                    </span>
                  </Col>
                </Form.Group>

                {/* ອີເມວ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ອີເມວ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data &&
                        (param.data.email ? param.data.email : "-")}
                    </span>
                  </Col>
                </Form.Group>
              </div>

              {/* ---------- ໄອດີແລະລະຫັດຜ່ານ --------- */}
              <div style={{ marginBottom: 10 }}>
                <div>
                  <i
                    className="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
                  />
                  ໄອດີແລະລະຫັດຜ່ານ
                </div>
                {/* ໄອດີ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ໄອດີ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data &&
                        (param.data.userId ? param.data.userId : "-")}
                    </span>
                  </Col>
                </Form.Group>

                {/* ລະຫັດຜ່ານ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ລະຫັດຜ່ານ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data &&
                        (param.data.password ? param.data.password : "-")}
                    </span>
                  </Col>
                </Form.Group>
              </div>

              {/* ---------- ອື່ນໆ --------- */}
              <div style={{ marginBottom: 10 }}>
                <div>
                  <i
                    className="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ marginRight: 5, color: Consts.SECONDARY_COLOR }}
                  />
                  ອື່ນໆ
                </div>
                {/* ລາຍລະອຽດ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                ></Form.Group>
                {/* ໝາຍເຫດ */}
                <Form.Group
                  as={Row}
                  style={{
                    margin: 0,
                    marginBottom: 10,
                    paddingLeft: 20,
                    fontSize: 16,
                  }}
                >
                  <Form.Label column sm="4" className="text-left">
                    ໝາຍເຫດ
                  </Form.Label>
                  <Col sm="8" style={{ marginTop: 8 }}>
                    <span>
                      {param.data && (param.data.note ? param.data.note : "-")}
                    </span>
                  </Col>
                </Form.Group>
              </div>
            </div>
          </div>

          <div style={{ height: 20 }} />
          <div className="row">
            <div style={{ padding: 15 }} className="col">
              <Button
                onClick={_handleShowAddConfirmModalClose}
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  color: "#6f6f6f",
                  borderColor: Consts.SECONDARY_COLOR,
                }}
              >
                ຍົກເລີກ
              </Button>
            </div>
            <div style={{ padding: 15 }} className="col">
              <Button
                style={{
                  width: "100%",
                  backgroundColor: Consts.SECONDARY_COLOR,
                  color: "#fff",
                  borderColor: Consts.SECONDARY_COLOR,
                }}
                onClick={() => _confirmStudentAdd()}
              >
                ເພີ່ມ
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default StudentAddConfirm;
