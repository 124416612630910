import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from "@mui/material";
import { CustomContainer, Title } from "../../../common";
import CoursePreviewFilter from "./CoursePreviewFilter";
import { Delete } from "@mui/icons-material";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";
import axios from "axios";
import { USER_KEY } from "../../../consts";
import CustomMessage from "../../../common/CustomStatusMessage";
import { translateSubjectGroup } from "../../../common/super";
import { useSelector } from "react-redux";

const CoursePreview = ({
  location,
  onSuccess,
  setOnSuccess,
  isLoading,
  setIsLoading,
}) => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const [subjects, setSubjects] = useState([]);
  const [formData, setFormData] = useState({
    subjectGroup: "",
    year: "",
    semester: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const accessmentRedux = useSelector((state) => state.accessment.value);

  useEffect(() => {
    setIsLoading(true);
    const apiUrl = `${NEW_API_URL}subject/course?limit=${rowsPerPage}
        &faculty=${location.faculty}&department=${location.department}
        &major=${location._id}&page=${page + 1}&subjectGroup=${
      formData.subjectGroup
    }&year=${formData.year}&semester=${
      formData.semester
    }&assessmentYear=${accessmentRedux}`;

    axios
      .get(apiUrl, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setSubjects(response.data || []);
        setOnSuccess(false);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [formData, location, rowsPerPage, page, onSuccess, accessmentRedux]);

  useEffect(() => {
    if (snackbarOpen) {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }
  }, [snackbarOpen]);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.delete(
        `${NEW_API_URL}subject/course?subjectId=${id}`,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        showSnackbar("ລົບຫລັກສູດສຳເລັດ", "success");
        setOnSuccess(true);
      }
    } catch (error) {
      console.error("Error deleting subject/course:", error);
      showSnackbar("ບໍ່ສາມາດລົບຫລັກສູດ", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <CustomContainer>
        <div className="d-flex align-items-center">
          <Title text={"ຕາຕະລາງສະແດງລາຍການວິຊາໃນຫຼັກສູດ "} />
          <b
            style={{
              marginTop: 8,
              marginBottom: 20,
              marginLeft: 10,
              color: "red",
            }}
          >
            {location?.name}
          </b>
        </div>
        <CoursePreviewFilter
          formData={formData}
          setFormData={setFormData}
          subjectData={subjects?.data}
        />
        <div className="d-flex align-items-center">
          <Title text={"ຫຼັກສູດທັງໝົດ "} />
          <span style={{ marginTop: 8, marginBottom: 20, marginLeft: 10 }}>
            {" "}
            ({subjects?.count} ລາຍການ)
          </span>
        </div>
        {/* <Paper sx={{ width: '100%', mb: 2 }}> */}
        <TableContainer>
          <Table sx={{ whiteSpace: "break-spaces" }}>
            <TableHead>
              <TableRow color="info">
                <TableCell
                  width={150}
                  align="center"
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    whiteSpace: "wrap",
                    wordWrap: "break-word",
                  }}
                >
                  ຊື່ວິຊາຮຽນ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ໜ່ວຍກິດ
                </TableCell>
                <TableCell
                  minWidth={105}
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ໝວດວິຊາ
                </TableCell>
                <TableCell
                  width={55}
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ປີຮຽນ
                </TableCell>
                <TableCell
                  width={55}
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ພາກຮຽນ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ຈັດການ
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subjects?.data?.map((subject, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ whiteSpace: "wrap" }}>
                    {subject?.subject?.title}
                  </TableCell>
                  <TableCell align="center">
                    {subject?.subject?.credit}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "wrap" }}>
                    {translateSubjectGroup(subject?.subjectGroup)}
                  </TableCell>
                  <TableCell align="center">{subject?.year}</TableCell>

                  <TableCell align="center">{subject?.semester}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      sx={{ color: "red" }}
                      size="small"
                      aria-label="delete"
                      onClick={(e) => handleDelete(e, subject?._id)}
                      disabled={isLoading}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            <TablePagination
              rowsPerPageOptions={[
                10,
                20,
                50,
                { label: "All", value: subjects?.count },
              ]}
              colSpan={6} // Adjusted colSpan to include the Actions column
              count={subjects?.count}
              rowsPerPage={rowsPerPage}
              page={page}
              elevation={0}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
        {/* </Paper> */}
      </CustomContainer>
      <CustomMessage
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default CoursePreview;
