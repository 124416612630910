import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Grid,
  Chip,
  Typography,
} from "@mui/material";
import { USER_KEY } from "../../consts";
import { API_KEY, NEW_API_URL } from "../../common/contrant";
import { useSelector } from "react-redux";
import axios from "axios";

const user = localStorage.getItem(USER_KEY);
const userObject = JSON.parse(user);
const token = userObject?.accessToken;

const daysOfWeek = ["ຈັນ", "ອັງຄານ", "ພຸດ", "ພະຫັດ", "ສຸກ", "ເສົາ", "ອາທິດ"];

const studyTypes = ["LAB", "LECTURE"];

const AddTimeDialog = ({ onSubmit, onCancel, open, factId }) => {
  const [formState, setFormState] = useState({});

  const accessmentRedux = useSelector((state) => state.accessment.value);
  const [timeList, setTimeList] = useState([]);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    if (e.target.value === "oddWeek") {
      setFormState({
        ...formState,
        ["oddWeek"]: true,
        ["allWeek"]: false,
        ["evenWeek"]: false,
      });
    } else if (e.target.value === "evenWeek") {
      setFormState({
        ...formState,
        ["oddWeek"]: false,
        ["allWeek"]: false,
        ["evenWeek"]: true,
      });
    } else if (e.target.value === "allWeek") {
      setFormState({
        ...formState,
        ["oddWeek"]: false,
        ["allWeek"]: true,
        ["evenWeek"]: false,
      });
    } else {
      const { name, value } = e.target;
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  const handleTimeChange = (e) => {
    const timeIds = e.target.value;
    const foundTimes = timeList.filter((time) => timeIds.includes(time._id));

    setFormState({
      ...formState,
      times: [...formState.times, ...e.target.value],
      timeStudy: [...formState.timeStudy, ...foundTimes],
    });
  };

  const fetchTimeList = async () => {
    try {
      if (factId) {
        const response = await axios.get(
          `${NEW_API_URL}time/${factId}/${accessmentRedux}`,
          {
            headers: {
              api_key: API_KEY,
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTimeList(response.data?.data || []);
      }
    } catch (err) {}
  };

  useEffect(() => {
    setFormState({
      oddWeek: false,
      evenWeek: false,
      allWeek: true,
      day: "",
      studyType: "",
      address: "",
      // use for show  only
      times: [],
      // use to send to API
      timeStudy: [],
    });
    fetchTimeList();
  }, [open]);

  const validate = () => {
    let tempErrors = {};
    if (!formState.day) tempErrors.day = "Day is required";
    if (formState.times.length === 0)
      tempErrors.times = "At least one time is required";
    if (!formState.address) tempErrors.address = "Address is required";
    if (!formState.studyType) tempErrors.studyType = "Study Type is required";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      onSubmit(formState);
      onCancel();
    }
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>ເພິ່ມເວລາສອນ</DialogTitle>
      <DialogContent sx={{ width: 350 }}>
        <Box component="form" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>ມື້ສອນ</InputLabel>
                <Select
                  size="medium"
                  label="ມື້ສອນ"
                  name="day"
                  value={formState.day}
                  onChange={handleChange}
                >
                  {daysOfWeek.map((day) => (
                    <MenuItem key={day} value={day}>
                      {day}
                    </MenuItem>
                  ))}
                </Select>
                {errors.day && (
                  <Typography color="error" variant="caption">
                    {errors.day}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>ອາທິດສອນ</InputLabel>
                <Select
                  size="medium"
                  label="ອາທິດສອນ"
                  name="week"
                  value={
                    formState.oddWeek
                      ? "oddWeek"
                      : formState.evenWeek
                      ? "evenWeek"
                      : formState.allWeek
                      ? "allWeek"
                      : ""
                  }
                  onChange={handleChange}
                >
                  <MenuItem key={"allWeek"} value={"allWeek"}>
                    ທຸກອາທິດ
                  </MenuItem>
                  <MenuItem key={"oddWeek"} value={"oddWeek"}>
                    ອາທິດຄີກ
                  </MenuItem>
                  <MenuItem key={"evenWeek"} value={"evenWeek"}>
                    ອາທິດຄູ່
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>ປະເພດການສອນ</InputLabel>
                <Select
                  size="medium"
                  label="ປະເພດການສອນ"
                  name="studyType"
                  value={formState.studyType}
                  onChange={handleChange}
                >
                  {studyTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
                {errors.studyType && (
                  <Typography color="error" variant="caption">
                    {errors.studyType}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={"ສະຖານທິ່ສອນ"}
                  value={formState.address}
                  onChange={handleChange}
                  name="address"
                  variant="outlined"
                />
                {errors.address && (
                  <Typography color="error" variant="caption">
                    {errors.address}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>ເວລາສອນ</InputLabel>
                <Select
                  size="medium"
                  label="ເວລາສອນ"
                  name="times"
                  value={formState.times}
                  onChange={handleTimeChange}
                  multiple
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      {selected.map((selectedTime, index) => (
                        <Chip
                          key={index}
                          label={
                            timeList.find((time) => time._id === selectedTime)
                              ?.time
                          }
                          style={{ margin: 2 }}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {timeList.map((time, index) => (
                    <MenuItem key={index} value={time._id}>
                      {time.time}
                    </MenuItem>
                  ))}
                </Select>
                {errors.times && (
                  <Typography color="error" variant="caption">
                    {errors.times}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>ຍົກເລີກ</Button>
        <Button onClick={handleSubmit} variant="contained">
          ຕົກລົງ
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTimeDialog;
