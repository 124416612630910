import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as _ from "lodash";
import queryString from "query-string";
/**
 *
 * @Component
 *
 */
import { Breadcrumb, OverlayTrigger, Tooltip, Row, Col, } from "react-bootstrap";
import { useSelector } from 'react-redux'
import { CustomContainer, Title, CustomButton, TableHeader, TableCell } from "../../common";
import Loading from "../../common/Loading";
import RegistrationAdd from "./RegistrationAdd";
import RegistrationEdit from "./RegistrationEdit";

import RoleAdmin from "./filter/RoleAdmin";
import RoleFaculty from "./filter/RoleFaculty";
import RoleDepartment from "./filter/RoleDepartment";
/**
 *
 * @Constant
 *
 */
import Consts, { MOODLE_URL } from "../../consts";
import Routers from "../../consts/router";

/**
 *
 * @Apollo
 *
 */
import { GRADES } from "../../apollo/registration";
import { FACULTIES } from "../../apollo/faculty";
/**
 *
 * @Function
 *
 */
import { currency, formatDateTime, setGenders } from "../../common/super";
import PaginationHelper from "../../helpers/PaginationHelper";
import { convertGenderNamEN, getUserDataFromLCStorage } from "../../helpers/user";

function RegistrationList() {
  const { history, location } = useReactRouter();

  const accessmentRedux = useSelector((state) => state.accessment.value)
  const parsed = queryString?.parse(location?.state);
  const { _limit, _skip, Pagination_helper } = PaginationHelper();

  /**
   *
   * @State
   *
   */

  /* Faculty  */
  const [dataFaculty, setDataFaculty] = useState([]);
  const [facutyId, setFacutyId] = useState(!parsed?.faculty ? "" : parsed?.faculty);

  /* Department */
  const [departmentId, setDepartmentId] = useState(!parsed?.department ? "" : parsed?.department);

  const [majorId, setMajorId] = useState(!parsed?.major ? "" : parsed?.major);

  /* grade */
  const [dataRegistrations, setDataRegistrations] = useState([]);
  const [totals, setTotals] = useState("");
  const [yearLevel, setYearLevel] = useState(!parsed?.yearLevel ? "" : parsed?.yearLevel);
  const [semester, setSemester] = useState(!parsed?.semester ? "" : parsed?.semester);
  const [classRoom, setClassRoom] = useState(!parsed?.classRoom ? "" : parsed?.classRoom);

  const [firstNameL, setFirstNameL] = useState("");
  const [dataUser, setDataUser] = useState();
  const [ROLE, setROLE] = useState();
  const [showEditView, setShowEditView] = useState(false);

  /**
   *
   * @Apollo
   *
   */
  const [loadDataFaculties, { loading: facultyLoading, data: facultyData }] = useLazyQuery(FACULTIES);
  const [loaddataGrade, { loading: gradeLoading, data: registrationData }] = useLazyQuery(GRADES, { fetchPolicy: "network-only" });
  /**
   *
   * @UseEffect
   *
   */
  useEffect(() => {
    loadDataFaculties({
      variables: {
        where: { isDeleted: false, assessmentYear: accessmentRedux },
      },
    });
    loaddataGrade({
      variables: {
        where: { assessmentYear: accessmentRedux.replaceAll(" ", "") },
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (getUserDataFromLCStorage()?.data) setDataUser(getUserDataFromLCStorage()?.data)
    setROLE(getUserDataFromLCStorage()?.data?.role);

    if (getUserDataFromLCStorage()?.data?.role === "FAC_ADMIN") {
      setFacutyId(getUserDataFromLCStorage()?.data?.faculty?.id)
    }
    if (getUserDataFromLCStorage()?.data?.role === "DEP_ADMIN") {
      setFacutyId(getUserDataFromLCStorage()?.data?.faculty?.id)
      setDepartmentId(getUserDataFromLCStorage()?.data?.department?.id)
    }
  }, [accessmentRedux]);

  useEffect(() => {
    let qlWhere = { assessmentYear: accessmentRedux };
    if (facutyId !== "" && facutyId !== "ທັງໝົດ") qlWhere = { ...qlWhere, faculty: facutyId ?? "" };
    if (departmentId !== "" && departmentId !== undefined) qlWhere = { ...qlWhere, department: departmentId };
    if (majorId !== "" && majorId !== undefined) qlWhere = { ...qlWhere, major: majorId };
    if (classRoom !== "" && classRoom !== undefined) qlWhere = { ...qlWhere, classRoom: classRoom };
    if (yearLevel !== "" && yearLevel !== "ທັງໝົດ") qlWhere = { ...qlWhere, yearLevel: parseInt(yearLevel) ?? "" };
    if (firstNameL !== "") qlWhere = { ...qlWhere, student: firstNameL };
    if (semester !== "") qlWhere = { ...qlWhere, semester: semester };

    loaddataGrade({
      variables: {
        where: { ...qlWhere },
        skip: (_skip - 1) * _limit,
        first: _limit,
      },
    });
  }, [facutyId, departmentId, majorId, classRoom, yearLevel, accessmentRedux, firstNameL, semester]);

  const _handleSearchFromStudent = () => {
    try {
      let qlWhere = { assessmentYear: accessmentRedux };
      if (facutyId !== "" && facutyId !== "ທັງໝົດ") qlWhere = { ...qlWhere, faculty: facutyId ?? "" };
      if (departmentId !== "") qlWhere = { ...qlWhere, department: departmentId ?? "" };
      if (yearLevel !== "" && yearLevel !== "ທັງໝົດ") qlWhere = { ...qlWhere, yearLevel: parseInt(yearLevel) ?? "" };
      if (firstNameL !== "") qlWhere = { ...qlWhere, student: firstNameL };

      loaddataGrade({
        variables: {
          where: { ...qlWhere },
          skip: (_skip - 1) * _limit,
          first: _limit,
        },
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }

  useEffect(() => {
    setDataRegistrations(registrationData?.grades?.data)
    setTotals(registrationData?.grades?.total);
  }, [registrationData])

  useEffect(() => {
    if (facultyData) setDataFaculty(facultyData?.faculties);
  }, [facultyData, dataUser, ROLE]);


  /**
   *
   * @Function
   *
   */

  const [showAddModal, setShowAddModal] = useState(false);

  const _handleShowAddModalClose = () => setShowAddModal(false);
  const _handleShowAddModalShow = () => setShowAddModal(true);

  const _handleEditViewClose = () => setShowEditView(false);

  const _subjectAddMore = (event) => {
    history.push(Routers.REGISTRATIONS_ADD + "/limit/100/skip/1", event);
  };
  const _studentDetail = (event) => {
    history.push(`/registration-detail/${1}`, event);
  };

  if (facultyLoading || gradeLoading) return <Loading />;

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item active>
          ລົງທະບຽນນັກສຶກສາປີ 1
        </Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <Title text={"ລາຍການລົງທະບຽນທັງໝົດ"} />
        <div style={{ paddingTop: 0 }}>
          {ROLE === "ADMIN" ?
            <RoleAdmin
              setFacutyId={setFacutyId}
              setDepartmentId={setDepartmentId}
              setYearLevel={setYearLevel}
              yearLevel={yearLevel}
              semester={semester}
              setSemester={setSemester}
              classRoom={classRoom}
              setClassRoom={setClassRoom}
              facutyId={facutyId}
              dataFaculty={dataFaculty}
              firstNameL={firstNameL}
              setFirstNameL={setFirstNameL}
              departmentId={departmentId}
              register="REGISTER"
              _handleSearchFromStudent={_handleSearchFromStudent}
              setMajorId={setMajorId}
              majorId={majorId}
            /> : ROLE === "FAC_ADMIN" ?
              <RoleFaculty
                setFacutyId={setFacutyId}
                setDepartmentId={setDepartmentId}
                setYearLevel={setYearLevel}
                yearLevel={yearLevel}
                semester={semester}
                setSemester={setSemester}
                classRoom={classRoom}
                setClassRoom={setClassRoom}
                facutyId={facutyId}
                dataFaculty={dataFaculty}
                firstNameL={firstNameL}
                setFirstNameL={setFirstNameL}
                departmentId={departmentId}
                register="REGISTER"
                _handleSearchFromStudent={_handleSearchFromStudent}
                setMajorId={setMajorId}
                majorId={majorId}
              /> : ROLE === "DEP_ADMIN" ?
                <RoleDepartment
                  setFacutyId={setFacutyId}
                  setDepartmentId={setDepartmentId}
                  setYearLevel={setYearLevel}
                  yearLevel={yearLevel}
                  semester={semester}
                  setSemester={setSemester}
                  classRoom={classRoom}
                  setClassRoom={setClassRoom}
                  facutyId={facutyId}
                  dataFaculty={dataFaculty}
                  firstNameL={firstNameL}
                  setFirstNameL={setFirstNameL}
                  departmentId={departmentId}
                  register="REGISTER"
                  _handleSearchFromStudent={_handleSearchFromStudent}
                  setMajorId={setMajorId}
                  majorId={majorId}
                /> : ""
          }
        </div>
      </CustomContainer>
      <CustomContainer>
        <Row style={{}}>
          <Col
            sm={9}
            className="justify-content-center"
            style={{ paddingLeft: 0, textAlign: "right" }}
          ></Col>
          <Col sm={3} style={{ paddingRight: 10 }}>
            <Row xs={1} md={2} className="d-flex justify-content-end">
              {/* <Col style={{ paddingLeft: 36 }}>
                <CustomButton
                  confirm
                  addIcon
                  title="ລົງທະບຽນ"
                  onClick={() => _handleShowAddModalShow()}
                />
              </Col> */}
              <Col>
                <CustomButton
                  confirm
                  addIcon
                  title="ລົງທະບຽນຫຼາຍຄົນ"
                  onClick={() => _subjectAddMore()}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          ທັງໝົດ {currency(totals)} ລາຍການ
        </div>

        <div style={{ marginBottom: 20 }}>
          <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
            <thead>
              <TableHeader>
                <th style={{ width: 70 }}>ລຳດັບ</th>
                <th>ລະຫັດນັກສຶກສາ</th>
                <th>ຊື່ນັກຮຽນ</th>
                <th>ລະຫັດວິຊາ</th>
                <th>ຊື່ວິຊາ</th>
                <th>ວັນທີລົງທະບຽນ</th>
                <th style={{ width: 180 }}>ຈັດການ</th>
              </TableHeader>
            </thead>
            <tbody>
              {dataRegistrations?.map((x, index) => {
                return (
                  <tr
                    style={{
                      borderBottom: "2px solid #ffff",
                      textAlign: "center",
                    }}
                    key={index}
                  >
                    <TableCell>{index + 1 + _limit * (_skip - 1)}</TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {x?.student?.userId ?? "-"}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {!x?.student?.firstNameL || x?.student?.firstNameL === "" ?
                        convertGenderNamEN(x?.student?.gender) + " " + x?.student?.firstNameE + " " + x?.student?.lastNameE
                        :
                        setGenders(x?.student?.gender) + " " + x?.student?.firstNameL + " " + x?.student?.lastNameL
                      }
                      {/* {setGenders(x?.student?.gender) + " " + x?.student?.firstNameL + " " + x?.student?.lastNameL} */}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {x.course && x.course
                        ? x.course.courseCode
                          ? x.course.courseCode
                          : ""
                        : "ວິຊາຖືກລົບແລ້ວ"}
                      {x?.course?.isDeleted === true ? " (ລົບອອກແລ້ວ)" : ""}
                    </TableCell>
                    <TableCell style={{ textAlign: "left" }}>
                      {x.course && x.course
                        ? x.course.title
                          ? x.course.title
                          : ""
                        : "ວິຊາຖືກລົບແລ້ວ"}
                    </TableCell>
                    <TableCell>
                      {x.createdAt &&
                        formatDateTime(x.createdAt).toLocaleString("la-LA", {
                          hour12: false,
                        })}
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              ເບິ່ງລາຍລະອຽດ
                            </Tooltip>
                          }
                        >
                          <div
                            onClick={() => _studentDetail(x)}
                            style={{
                              cursor: "pointer",
                              backgroundColor: "#FFFFFF",
                              padding: 3,
                              width: 64,
                              borderRadius: 4,
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fas", "eye"]}
                              color={Consts.BORDER_COLOR}
                            />{" "}
                          </div>
                        </OverlayTrigger>
                      </div>
                    </TableCell>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {Pagination_helper(
          totals,
          Routers.REGISTRATIONS_LIST,
          `faculty=${facutyId}&&department=${departmentId}&&major=${majorId}&&classRoom=${classRoom}&&yearLevel=${yearLevel}&&semester=${semester}`
        )}
      </CustomContainer>

      <RegistrationAdd
        showAddModal={showAddModal}
        _handleShowAddModalClose={_handleShowAddModalClose}
      />

      <RegistrationEdit
        showEditView={showEditView}
        _handleEditViewClose={_handleEditViewClose}
      />

    </div>
  );
}

export default RegistrationList;
