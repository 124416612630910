import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
/**
 *
 * @Library
 *
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useLazyQuery, useQuery } from "@apollo/react-hooks";
/**
 *
 * @Component
 *
 */
import { Modal, Col, Row, Spinner } from "react-bootstrap";
import { CustomContainer, CustomButton } from "../../common";
import Loading from "../../common/Loading";
/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";
import Routers from "../../consts/router";
/**
 *
 * @Apollo
 *
 */
import { CODE, STUDENTS, USER } from "./apollo/query";
import { UPDATE_STUDENT } from "./apollo/mutation";
/**
 *
 * @Function
 *
 */
import PreregistrationConfirm from "./PreregistrationConfirm";
import { formatDate, convertHealthStatus } from "../../common/super";
import {
  onConvertStudentInfo,
  convertGenderNam,
  onConvertEducationLevel,
  convertGender,
  convertGenderNamEN,
  convertStatus,
  convertScholarship,
  convertScholarshipEn,
  getUserDataFromLCStorage,
} from "../../helpers/user";
import axios from "axios";
import { API_KEY, NEW_API_URL } from "../../common/contrant";

import { Button } from "@mui/material";

import { USER_KEY } from "../../consts";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import addLogs from "../../helpers/AddLogs";

export default function RegistrationStudentDetailV1() {
  const { history } = useReactRouter();
  // const dataStudent = location.state
  const datas = history.location.state;
  const [dataStudent, setDataStudent] = useState();
  const [dataUser, setDataUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [displayStyle, setDisplayStyle] = useState(false);
  const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
  const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
  const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);
  const [dataStudentConfirm, setDataStudentConfirm] = useState();
  const [studentNumber, setStudentNumber] = useState(
    datas?.studentNumber ?? ""
  );
  const [show, setShow] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [rotation, setRotation] = useState(0);
  const [showIncorrectStudentNumber, setShowIncorrectStudentNumber] = useState(false);
  const [incorrectStudentNumber, setIncorrectStudentNumber] = useState("");

  const _handleRejectPreregistration = () => setShow(true);
  const _closeRejectPrere = () => setShow(false);
  // const [updatePreregistrationStudent] = useMutation(UPDATE_STUDENT);

  const [loadStudentData, { data: studentData }] = useLazyQuery(STUDENTS);
  const { data: adminApprovedData } = useQuery(USER, {
    variables: {
      where: {
        id: datas?.adminApprovedBy,
      },
    },
  });
  const { data: facultyApprovedData } = useQuery(USER, {
    variables: {
      where: {
        id: datas?.facultyApprovedBy,
      },
    },
  });

  const [canReject, setCanReject] = useState(false);
  const [canApprove, setCanApprove] = useState(false);

  useEffect(() => {
    const userObject = JSON.parse(user);
    // enum: ["SAVED","SEND_FORM","REQUEST","REJECTED", "ADMIN_APPROVED", "APPROVED"],
    const role = userObject.data?.role;

    if (dataStudent) {
      if (role === "FAC_ADMIN") {
        if (dataStudent.statusStep === "ADMIN_APPROVED") {
          setCanReject(true);
          setCanApprove(true);
        } else {
          setCanReject(false);
          setCanApprove(false);
        }
      } else if (role === "ADMIN") {
        if (
          dataStudent.statusStep === "ADMIN_APPROVED" ||
          dataStudent.statusStep === "APPROVED" ||
          dataStudent.statusStep === "REJECTED"
        ) {
          setCanReject(false);
          setCanApprove(false);
        } else {
          setCanReject(true);
          setCanApprove(true);
        }
      }
    }
  }, [dataStudent]);

  useEffect(() => {
    loadStudentData({
      variables: {
        where: {
          studentNumber: datas?.studentNumber,
        },
      },
    });
  }, [datas]);

  useEffect(() => {
    if (!studentData) return;
    setDataStudent(studentData?.preregistrationStudents?.data[0]);
  }, [studentData]);
  const user = localStorage.getItem(USER_KEY);

  const _handleSubmitReject = async () => {
    try {
      const userObject = JSON.parse(user);

      const role = userObject.data?.role;

      setIsLoading(true);

      let rejectFrom = "ປະຕິເສດຈາກວິຊາການ ມຊ";
      if (role === "FAC_ADMIN" || role === "FAC_ADMIN_TEACHER") {
        rejectFrom = "ປະຕິເສດຈາກຄະນະ";
      }

      const requestBody = {
        id: dataStudent.id,
        message: reason,
        rejectfFrom: rejectFrom,
      };
      const token = userObject?.accessToken;
      const response = await axios.post(NEW_API_URL + "reject/", requestBody, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      await addLogs({
        status: "SUCCESS",
        data: response,
        type: "REJECT_STUDENT",
        action: "UPDATE",
        student: dataStudent?.id,
      });

      setIsLoading(false);
      setShow(false);
      setTimeout(() => {
        history.push(`/students-prere`);
        // window.location.reload();
      }, 400);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const [loadCode, { loading: codeLoading, data: datatCode }] =
    useLazyQuery(CODE);

  useEffect(() => {
    if (getUserDataFromLCStorage()?.data) {
      setDataUser(getUserDataFromLCStorage()?.data);
    }
  }, []);

  useEffect(() => {
    if (studentNumber) {
      loadCode({
        variables: {
          where: {
            code: studentNumber,
          },
        },
      });
    }
  }, [studentNumber]);

  const [reason, setReason] = useState("");

  const _handleClickConfirm = (dataStudent) => {
    setDataStudentConfirm(dataStudent);
  };
  const _searchStudentNumber = (e) => {
    if (e.key === "Enter") {
      setStudentNumber(e.target.value);
    }
  };
  const _handlePreregistrationEdit = (dataStudent) => {
    if (
      dataStudent?.studentType === "OUT_PLAN" ||
      dataStudent?.studentType === "IN_PLAN"
    ) {
      history.push("/preregistration_edit", dataStudent);
    } else {
      history.push("/preregistration_edit_en", dataStudent);
    }
  };

  const rotateImage = () => {
    setRotation(rotation + 90); // Rotate by 90 degrees each time
  };

  const styleHide = {
    overflowX: "hidden",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  };

  return (
    <div>
      {/* {codeLoading && <Loading />} */}
      <Col sm={12} style={{ paddingBottom: 20, paddingTop: 20 }}>
        <a
          href="#"
          onClick={() => {
            history.push("/students-prere");
          }}
          rel="noreferrer"
        >
          ລົງທະບຽນນັກສຶກສາ
        </a>
        /{" "}
        {dataStudent && dataStudent?.firstNameL
          ? dataStudent?.firstNameL + " " + dataStudent?.lastNameL
          : dataStudent?.firstNameE + " " + dataStudent?.lastNameE}
        {dataUser?.role === "ADMIN" || dataUser?.role === "FAC_ADMIN" ? (
          <>
            <button
              disabled={!canReject}
              onClick={_handleRejectPreregistration}
              className="btn"
              style={{
                float: "right",
                marginRight: 10,
                backgroundColor: "#f50f0f",
                color: "#fff",
                height: 48,
                marginLeft: 20,
              }}
            >
              ປ​ະ​ຕິ​ເສດ​ການ​ລົງ​ທະ​ບຽນ
            </button>
            <button
              disabled={!canApprove}
              onClick={(e) => {
                if (dataStudent?.studentNumber.length < 11 || dataStudent?.studentNumber.length > 12) {
                  setIncorrectStudentNumber("ຈຳນວນຂອງລະຫັດນັກສຶກສາບໍ່ຖືກ");
                  setShowIncorrectStudentNumber(true);
                  return;
                } else {
                  _handleShowAddConfirmModalShow();
                  _handleClickConfirm(dataStudent);
                }

              }}
              className="btn"
              style={{
                float: "right",
                marginRight: 10,
                backgroundColor: "#7BB500",
                color: "#fff",
                height: 48,
              }}
            >
              ຢືນ​ຢັນ​ການ​ລົງ​ທະ​ບຽນ
            </button>
            <button
              disabled={
                dataUser?.role !== "ADMIN"
                  ? true
                  : false
              }
              onClick={() => _handlePreregistrationEdit(dataStudent)}
              className="btn  btn-primary"
              style={{
                float: "right",
                marginRight: 50,
                height: 48,
              }}
            >
              <i className="fa fa-user-edit" /> ແກ້ໄຂ
            </button>
          </>
        ) : (
          <>
            {/* <button
              disabled={
                // dataStudent?.statusStep === "APPROVED" ||
                // dataStudent?.statusStep !== "ADMIN_APPROVED" ||
                dataStudent?.statusStep === "REJECTED" ? true : false
              }
              onClick={_handleRejectPreregistration}
              className="btn"
              style={{
                float: "right",
                marginRight: 10,
                backgroundColor: "#f50f0f",
                color: "#fff",
                height: 48,
                marginLeft: 20,
              }}
            >
              ປ​ະ​ຕິ​ເສດ​ການ​ລົງ​ທະ​ບຽນ
            </button>
            <button
              disabled={
                dataStudent?.statusStep === "APPROVED" ||
                  dataStudent?.statusStep !== "ADMIN_APPROVED" ||
                  dataStudent?.statusStep === "REJECTED"
                  ? true
                  : false
              }
              onClick={(e) => {
                _handleShowAddConfirmModalShow();
                _handleClickConfirm(dataStudent);
              }}
              className="btn"
              style={{
                float: "right",
                marginRight: 10,
                backgroundColor: "#7BB500",
                color: "#fff",
                height: 48,
              }}
            >
              ຢືນ​ຢັນ​ການ​ລົງ​ທະ​ບຽນ
            </button> */}
          </>
        )}
      </Col>
      <Row style={{ paddingLeft: "10px" }}>
        {/* START LAYOUT 1 */}
        <Col sm={8}>
          <CustomContainer>
            <Row>
              <Col style={{ borderRight: "1px solid #DFDFDF" }}>
                <p style={{ fontSize: "18px", color: "#057CAE" }}>
                  ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ
                </p>
                <img
                  style={{ margin: "0 auto" }}
                  src={
                    "https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png"
                  }
                  className="browserImage"
                  alt="user icon"
                />
                <Row className="p-0" style={{ fontSize: 14, paddingTop: 20 }}>
                  <Col sm="6">ລະຫັດນັກສຶກສາ</Col>
                  <Col sm="6">{dataStudent?.studentNumber ?? "-"}</Col>

                  {/* <Col sm='6'>ລະຫັດສອບເສັງນັກສຶກສາ</Col>
                  <Col sm='6'>{dataStudent?.examCode ?? '-'}</Col> */}

                  <Col sm="6">ຄະນະ</Col>
                  <Col sm="6">{dataStudent?.faculty?.name ?? "-"}</Col>
                  <Col sm="6">ພາກວິຊາ</Col>
                  <Col sm="6">{dataStudent?.department?.name ?? "-"}</Col>
                  <Col sm="6">ສາຂາວິຊາ</Col>
                  <Col sm="6">{dataStudent?.major?.name ?? "-"}</Col>

                  <Col sm="6">ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ</Col>
                  <Col sm="6">
                    {onConvertStudentInfo(dataStudent?.studentInfo) ?? "-"}
                  </Col>

                  <Col sm="6">ລະດັບ</Col>
                  <Col sm="6">
                    {onConvertEducationLevel(dataStudent?.educationLevel) ??
                      "-"}
                  </Col>
                </Row>
              </Col>

              {/*--------------------------------------  */}
              <Col>
                <div style={{ fontSize: "18px", color: "#057CAE" }}>
                  ຂໍ້ມູນ​ທົ່ວໄປ
                </div>

                <Row style={{ fontSize: 14 }}>
                  <Col sm="6">ຊື່</Col>
                  <Col sm="6">
                    {convertGenderNam(dataStudent?.gender)}{" "}
                    {dataStudent?.firstNameL ?? "-"}
                  </Col>

                  <Col sm="6">ນາມສະກຸນ</Col>
                  <Col sm="6">{dataStudent?.lastNameL ?? "-"}</Col>

                  <Col sm="6">ຊື່ (ພາສາອັງກິດ)</Col>
                  <Col sm="6">
                    {convertGenderNamEN(dataStudent?.gender)}{" "}
                    {dataStudent?.firstNameE ?? "-"}
                  </Col>

                  <Col sm="6">ນາມສະກຸນ (ພາສາອັງກິດ)</Col>
                  <Col sm="6">{dataStudent?.lastNameE ?? "-"}</Col>

                  <Col sm="6">ວັນເດືອນປີເກີດ</Col>
                  <Col sm="6">{formatDate(dataStudent?.birthday) ?? "-"}</Col>

                  <Col sm="6">ເພດ</Col>
                  <Col sm="6">{convertGender(dataStudent?.gender) ?? "-"}</Col>

                  <Col sm="6">ສຸຂະພາບ</Col>
                  <Col sm="6">
                    {convertHealthStatus(dataStudent?.healthStatus) ?? "-"}
                  </Col>
                  {dataStudent?.healthStatus === "BAD" ? (
                    <>
                      <Col sm="6"></Col>
                      <Col sm="6">{dataStudent?.health}</Col>
                    </>
                  ) : (
                    ""
                  )}

                  <Col sm="6">ສະຖານະ</Col>
                  <Col sm="6">
                    {convertStatus(dataStudent?.maritualStatus) ?? "-"}
                  </Col>
                  <Col sm="6">ສັນຊາດ</Col>
                  <Col sm="6">{dataStudent?.nationality ?? "-"}</Col>
                  {dataStudent?.studentType === "OUT_PLAN" ||
                    dataStudent?.studentType === "IN_PLAN" ? (
                    <>
                      <Col sm="6">ຊົນເຜົ່າ</Col>
                      <Col sm="6">{dataStudent?.tribe?.trib_name ?? "-"}</Col>
                    </>
                  ) : (
                    <></>
                  )}
                  <Col sm="6">ສາສະໜາ</Col>
                  <Col sm="6">{dataStudent?.religion ?? "-"}</Col>
                  <Col sm="6">ເບີໂທເຮືອນ</Col>
                  <Col sm="6">{dataStudent?.phoneHome ?? "-"}</Col>
                  <Col sm="6">ເບີໂທລະສັບມືຖື</Col>
                  <Col sm="6">{dataStudent?.phoneMobile ?? "-"}</Col>
                </Row>
                <div
                  style={{ fontSize: "18px", color: "#057CAE", marginTop: 20 }}
                >
                  ທີ່​ຢູ່​ປະ​ຈຸ​ບັນ
                </div>
                <Row style={{ fontSize: 14 }}>
                  {dataStudent?.studentType === "OUT_PLAN_EN" ||
                    dataStudent?.studentType === "IN_PLAN_EN" ? (
                    <>
                      <Col sm="6">ປະເທດ</Col>
                      <Col sm="6">{dataStudent?.address?.country ?? "-"}</Col>
                    </>
                  ) : (
                    <></>
                  )}
                  <Col sm="6">ແຂວງ</Col>
                  <Col sm="6">{dataStudent?.address?.province ?? "-"}</Col>

                  <Col sm="6">ເມືອງ</Col>
                  <Col sm="6">{dataStudent?.address?.district ?? "-"}</Col>

                  <Col sm="6">ບ້ານ</Col>
                  <Col sm="6">{dataStudent?.address?.village ?? "-"}</Col>
                </Row>
              </Col>
              {/* --------------------------------------------------- */}
              <Col style={{ borderLeft: "1px solid #DFDFDF" }}>
                <div style={{ fontSize: "18px", color: "#057CAE" }}>
                  ສະຖານທີ່ເກີດ
                </div>

                <Row style={{ fontSize: 14 }}>
                  {dataStudent?.studentType === "OUT_PLAN_EN" ||
                    dataStudent?.studentType === "IN_PLAN_EN" ? (
                    <>
                      <Col sm="6">ປະເທດ</Col>
                      <Col sm="6">
                        {dataStudent?.birthAddress?.country ?? "-"}
                      </Col>
                      <Col sm="6">ແຂວງ</Col>
                      <Col sm="6">
                        {dataStudent?.birthAddress?.province ?? "-"}
                      </Col>
                      <Col sm="6">ເມືອງ</Col>
                      <Col sm="6">
                        {dataStudent?.birthAddress?.district ?? "-"}
                      </Col>
                      <Col sm="6">ບ້ານ</Col>
                      <Col sm="6">
                        {dataStudent?.birthAddress?.village ?? "-"}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col sm="6">ນັກສຶກສາເກີດໃນຕ່າງປະເທດ?</Col>
                      <Col sm="6">
                        {dataStudent?.bornAbroad === "" ||
                          dataStudent?.bornAbroad === null
                          ? "ບໍ່ແມ່ນ"
                          : "ແມ່ນ"}
                      </Col>
                      {dataStudent?.bornAbroad !== "" &&
                        dataStudent?.bornAbroad !== null ? (
                        <>
                          <Col sm="6">ປະເທດ</Col>
                          <Col sm="6">
                            {dataStudent?.birthAddress?.country ?? "-"}
                          </Col>
                          <Col sm="6">ແຂວງ</Col>
                          <Col sm="6">
                            {dataStudent?.birthAddress?.province ?? "-"}
                          </Col>
                          <Col sm="6">ເມືອງ</Col>
                          <Col sm="6">
                            {dataStudent?.birthAddress?.district ?? "-"}
                          </Col>
                          <Col sm="6">ບ້ານ</Col>
                          <Col sm="6">
                            {dataStudent?.birthAddress?.village ?? "-"}
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col sm="6">ແຂວງ</Col>
                          <Col sm="6">
                            {dataStudent?.birthAddress?.province ?? "-"}
                          </Col>
                          <Col sm="6">ເມືອງ</Col>
                          <Col sm="6">
                            {dataStudent?.birthAddress?.district ?? "-"}
                          </Col>
                          <Col sm="6">ບ້ານ</Col>
                          <Col sm="6">
                            {dataStudent?.birthAddress?.village ?? "-"}
                          </Col>
                        </>
                      )}
                    </>
                  )}
                </Row>

                <div
                  style={{ fontSize: "18px", color: "#057CAE", marginTop: 20 }}
                >
                  ກໍລະນີສຸກເສີນຕິດຕໍ່
                </div>

                <Row style={{ fontSize: 14 }}>
                  <Col sm="6">ພົວພັນທ່ານ</Col>
                  <Col sm="6">{dataStudent?.contactName ?? "-"}</Col>

                  <Col sm="6">ເບີໂທຕິດຕໍ່</Col>
                  <Col sm="6">{dataStudent?.emergencyPhone ?? "-"}</Col>

                  <Col sm="6">ອາໄສຢູ່ຮ່ວມກັນ</Col>
                  <Col sm="6">
                    {dataStudent?.stayTogether === true ? "ແມ່ນ" : "ບໍ່ແມ່ນ"}
                  </Col>
                  {dataStudent?.stayTogether !== true ? (
                    <>
                      {dataStudent?.studentType === "OUT_PLAN_EN" ||
                        dataStudent?.studentType === "IN_PLAN_EN" ? (
                        <>
                          <Col sm="6">ປະເທດ</Col>
                          <Col sm="6">
                            {dataStudent?.emergencyAddress?.country}
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}

                      <Col sm="6">ແຂວງ</Col>
                      <Col sm="6">
                        {dataStudent?.emergencyAddress?.province}
                      </Col>
                      <Col sm="6">ເມືອງ</Col>
                      <Col sm="6">
                        {dataStudent?.emergencyAddress?.district}
                      </Col>
                      <Col sm="6">ບ້ານ</Col>
                      <Col sm="6">{dataStudent?.emergencyAddress?.village}</Col>
                    </>
                  ) : (
                    <></>
                  )}
                  <Col sm="6">ຫໍ​ພັກ​ນັກ​ສຶກ​ສາ</Col>
                  <Col sm="6">
                    {dataStudent?.dormitory === "YES"
                      ? "ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"
                      : "ບໍ່ຂໍເຂົ້ານອ​ນ​ຫໍ​ພັກ"}
                  </Col>
                </Row>

                <div
                  style={{ fontSize: "18px", color: "#057CAE", marginTop: 20 }}
                >
                  ຂໍ້​ມູນ​ຜູ້​ປົກ​ຄອງ
                </div>

                <Row style={{ fontSize: 14 }}>
                  <Col sm="6">ຊື ແລະ ນາມສະກຸນພໍ່</Col>
                  <Col sm="6">{dataStudent?.fatherNameAndSurname ?? "-"}</Col>

                  <Col sm="6">ຊື່ ແລະ ນາມສະກຸນແມ່</Col>
                  <Col sm="6">{dataStudent?.motherNameAndSurname ?? "-"}</Col>

                  <Col sm="6">ເບີໂທລະສັບພໍ່/ແມ່</Col>
                  <Col sm="6">{dataStudent?.parentTelephone ?? "-"}</Col>

                  {dataStudent?.studentType === "OUT_PLAN_EN" ||
                    dataStudent?.studentType === "IN_PLAN_EN" ? (
                    <>
                      <Col sm="6">ປະເທດ</Col>
                      <Col sm="6">
                        {dataStudent?.parentAddress?.country ?? "-"}
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                  <Col sm="6">ແຂວງ</Col>
                  <Col sm="6">
                    {dataStudent?.parentAddress?.province ?? "-"}
                  </Col>

                  <Col sm="6">ເມືອງ</Col>
                  <Col sm="6">
                    {dataStudent?.parentAddress?.district ?? "-"}
                  </Col>

                  <Col sm="6">ບ້ານ</Col>
                  <Col sm="6">{dataStudent?.parentAddress?.village ?? "-"}</Col>
                </Row>
                {dataStudent?.approveNote === true ? (
                  <>
                    <div
                      style={{ fontSize: "18px", color: "#057CAE", marginTop: 20 }}
                    >
                      ໝາຍເຫດຂໍ້ມູນ​ນັກ​ສຶກ​ສາບໍ່ຖືກຕ້ອງ
                    </div>
                    <Row style={{ fontSize: 14 }}>
                      <Col sm="12">{dataStudent?.approveNoteDetails?.incorrectBirthday}</Col>
                      <Col sm="12">{dataStudent?.approveNoteDetails?.incorrectName}</Col>
                      <Col sm="12">{dataStudent?.approveNoteDetails?.incorrectFile}</Col>
                      <Col sm="12">{dataStudent?.approveNoteDetails?.remark}</Col>
                    </Row>
                  </>
                ) : null}
              </Col>
            </Row>

            {/* ---------------------------------------- */}
            <Row
              style={{
                borderTop: "3px solid #DFDFDF",
                paddingTop: "10px",
              }}
            >
              {" "}
              {dataStudent?.studentType === "IN_PLAN" ||
                dataStudent?.studentType === "IN_PLAN_EN" ? (
                <Col style={{ borderRight: "1px solid #DFDFDF" }}>
                  <div style={{ fontSize: "18px", color: "#057CAE" }}>
                    ສຳຫຼັບນັກ​ສຶກ​ສາ/ພະ​ນັກ​ງານທຶນ​ແບ່ງ​ປັນ
                  </div>

                  <Row className="p-0" style={{ fontSize: 14 }}>
                    {dataStudent?.studentType === "IN_PLAN_EN" ? (
                      <>
                        <Col sm="6">ທຶນແບ່ງປັນ</Col>
                        <Col sm="6">
                          {convertScholarshipEn(dataStudent?.scholarship) ??
                            "-"}
                        </Col>
                        <Col sm="6">ຈົບຈາກປະເທດ</Col>
                        <Col sm="6">
                          {dataStudent?.graduateFromCountry ?? "-"}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col sm="6">ທຶນແບ່ງປັນ</Col>
                        <Col sm="6">
                          {convertScholarship(dataStudent?.scholarship) ?? "-"}
                        </Col>
                      </>
                    )}
                    <Col sm="6">ຈົບຈາກແຂວງ</Col>
                    <Col sm="6">{dataStudent?.graduateFromProvince ?? "-"}</Col>

                    <Col sm="6">ຈົບຈາກໂຮງຮຽນ</Col>
                    <Col sm="6">{dataStudent?.graduateFromSchool ?? "-"}</Col>

                    <Col sm="6">ສົກສຶກສາ</Col>
                    <Col sm="6">{dataStudent?.semesterYear ?? "-"}</Col>

                    <Col sm="6">ເລກທີ່ຂໍ້ຕົກລົງເສັງໄດ້ທຶນ ມຊ</Col>
                    <Col sm="6">
                      {dataStudent?.scholarshipAgreementNo ?? "-"}
                    </Col>

                    <Col sm="6">ເລກທີໃບນຳສົ່ງ</Col>
                    <Col sm="6">{dataStudent?.transferLetterNo ?? "-"}</Col>
                  </Row>
                </Col>
              ) : (
                <Col style={{ borderRight: "1px solid #DFDFDF" }}>
                  <p style={{ fontSize: "18px", color: "#057CAE" }}>
                    ສຳຫຼັບນັກ​ສຶກ​ສາ​ຈ່າຍ​ຄ່າ​ຮຽນ
                  </p>

                  <Row style={{ fontSize: 14 }}>
                    {dataStudent?.studentType === "OUT_PLAN_EN" ? (
                      <>
                        <Col sm="6">ທຶນ</Col>
                        <Col sm="6">
                          {convertScholarshipEn(dataStudent?.scholarship) ??
                            "-"}
                        </Col>
                        {dataStudent?.scholarship ===
                          "ORGANIZATION_IN_LAOPDR_MINISTRIES" ||
                          dataStudent?.scholarship ===
                          "ORGANIZATION_IN_LAOPDR_PROVINCIAL_EDUCATION_DIVISION" ? (
                          <>
                            <Col sm="6">ທຶນຈາກ</Col>
                            <Col sm="6">
                              {dataStudent?.scholarshipFrom ?? "-"}
                            </Col>
                          </>
                        ) : (
                          <></>
                        )}
                        <Col sm="6">ຈົບຈາກປະເທດ</Col>
                        <Col sm="6">
                          {dataStudent?.graduateFromCountry ?? "-"}
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                    <Col sm="6">ຈົບຈາກແຂວງ</Col>
                    <Col sm="6">{dataStudent?.graduateFromProvince ?? "-"}</Col>

                    <Col sm="6">ຈົບຈາກໂຮງຮຽນ</Col>
                    <Col sm="6"> {dataStudent?.graduateFromSchool ?? "-"}</Col>

                    <Col sm="6">ສົກສຶກສາ</Col>
                    <Col sm="6">{dataStudent?.semesterYear ?? "-"}</Col>
                  </Row>
                </Col>
              )}
              {dataStudent?.educationLevel === "BACHELOR_CONTINUING" ? (
                <Row>
                  <Col>ຈົບສາຂາວິຊາ</Col>
                  <Col>{dataStudent?.graduatedMajor}</Col>
                </Row>
              ) : (
                <></>
              )}
              {/*--------------------------------------  */}
              <Col>
                <div style={{ fontSize: "18px", color: "#057CAE" }}>
                  ເອກະສານຄັດຕິດ
                </div>
                {dataStudent?.studentType === "OUT_PLAN_EN" ||
                  dataStudent?.studentType === "IN_PLAN_EN" ? (
                  <Row style={{ fontSize: 14 }}>
                    <Col sm="6">National ID or Passport</Col>
                    {dataStudent?.passportUrl &&
                      dataStudent?.passportUrl?.url?.split(".").pop() ===
                      "pdf" ? (
                      <Col sm="6" style={styleHide}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            Consts.URL_IMAGE + dataStudent?.passportUrl?.url
                          }
                        >
                          {" "}
                          ເປີດໄຟລ PDF
                        </a>
                      </Col>
                    ) : dataStudent?.passportUrl?.url ? (
                      <Col sm="6" style={styleHide}>
                        <img
                          alt=""
                          onClick={() => {
                            setImgShow(true);
                            setImgUrl(
                              Consts.URL_IMAGE + dataStudent?.passportUrl?.url
                            );
                          }}
                          src={Consts.URL_IMAGE + dataStudent?.passportUrl?.url}
                          width="40"
                          height="40"
                        />
                      </Col>
                    ) : (
                      <Col sm="6" style={styleHide}>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </Col>
                    )}
                    <div style={{ height: 8 }} />
                    <Col sm="6">ໄຟລຮູບ ໃບ​ປະ​ກາດ</Col>
                    {dataStudent?.qualificationUrl &&
                      dataStudent?.qualificationUrl?.url?.split(".").pop() ===
                      "pdf" ? (
                      <Col sm="6" style={styleHide}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            Consts.URL_IMAGE +
                            dataStudent?.qualificationUrl?.url
                          }
                        >
                          {" "}
                          ເປີດໄຟລ PDF
                        </a>
                      </Col>
                    ) : dataStudent?.qualificationUrl?.url ? (
                      <Col sm="6" style={styleHide}>
                        <img
                          alt=""
                          onClick={() => {
                            setImgShow(true);
                            setImgUrl(
                              Consts.URL_IMAGE +
                              dataStudent?.qualificationUrl?.url
                            );
                          }}
                          src={
                            Consts.URL_IMAGE +
                            dataStudent?.qualificationUrl?.url
                          }
                          width="40"
                          height="40"
                        />
                      </Col>
                    ) : (
                      <Col sm="6" style={styleHide}>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </Col>
                    )}
                    <div style={{ height: 8 }} />

                    <Col sm="6">ອື່ນໆ</Col>

                    {dataStudent?.otherUrl &&
                      dataStudent?.otherUrl?.url?.split(".").pop() === "pdf" ? (
                      <Col sm="6" style={styleHide}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={Consts.URL_IMAGE + dataStudent?.otherUrl?.url}
                        >
                          {" "}
                          ເປີດໄຟລ PDF
                        </a>
                      </Col>
                    ) : dataStudent?.otherUrl?.url ? (
                      <Col sm="6" style={styleHide}>
                        <img
                          alt=""
                          onClick={() => {
                            setImgShow(true);
                            setImgUrl(
                              Consts.URL_IMAGE + dataStudent?.otherUrl?.url
                            );
                          }}
                          src={Consts.URL_IMAGE + dataStudent?.otherUrl?.url}
                          width="40"
                          height="40"
                        />
                      </Col>
                    ) : (
                      <Col sm="6" style={styleHide}>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </Col>
                    )}
                  </Row>
                ) : (
                  <Row style={{ fontSize: 14 }}>
                    <Col sm="6">
                      ບັດ​ປະ​ຈຳ​ຕົວ ຫຼື ສຳ​ມ​ະ​ໂນ​ຄົວ (ພຣະ: ໜັງສືສຸທິ){" "}
                    </Col>
                    {dataStudent?.idCardOrFamilyBookUrl &&
                      dataStudent?.idCardOrFamilyBookUrl?.url
                        ?.split(".")
                        .pop() === "pdf" ? (
                      <Col sm="6" style={styleHide}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            Consts.URL_IMAGE +
                            dataStudent?.idCardOrFamilyBookUrl?.url
                          }
                        >
                          {" "}
                          ເປີດໄຟລ PDF
                        </a>
                      </Col>
                    ) : dataStudent?.idCardOrFamilyBookUrl?.url ? (
                      <Col sm="6" style={styleHide}>
                        <img
                          alt=""
                          onClick={() => {
                            setImgShow(true);
                            setImgUrl(
                              Consts.URL_IMAGE +
                              dataStudent?.idCardOrFamilyBookUrl?.url
                            );
                          }}
                          src={
                            Consts.URL_IMAGE +
                            dataStudent?.idCardOrFamilyBookUrl?.url
                          }
                          width="40"
                          height="40"
                        />
                      </Col>
                    ) : (
                      <Col sm="6" style={styleHide}>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </Col>
                    )}
                    <div style={{ height: 8 }} />
                    <Col sm="6">ໄຟລຮູບ ໃບ​ປະ​ກາດ</Col>
                    {dataStudent?.certificateUrl &&
                      dataStudent?.certificateUrl?.url?.split(".").pop() ===
                      "pdf" ? (
                      <Col sm="6" style={styleHide}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            Consts.URL_IMAGE + dataStudent?.certificateUrl?.url
                          }
                        >
                          {" "}
                          ເປີດໄຟລ PDF
                        </a>
                      </Col>
                    ) : dataStudent?.certificateUrl?.url ? (
                      <Col sm="6" style={styleHide}>
                        <img
                          alt=""
                          onClick={() => {
                            setImgShow(true);
                            setImgUrl(
                              Consts.URL_IMAGE +
                              dataStudent?.certificateUrl?.url
                            );
                          }}
                          src={
                            Consts.URL_IMAGE + dataStudent?.certificateUrl?.url
                          }
                          width="40"
                          height="40"
                        />
                      </Col>
                    ) : (
                      <Col sm="6" style={styleHide}>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </Col>
                    )}
                    <div style={{ height: 8 }} />

                    <Col sm="6">ໄຟລຮູບ ບັດ​ເຂົ້າ​ຫ້ອງ​ເສັງ</Col>

                    {dataStudent?.testCardUrl &&
                      dataStudent?.testCardUrl?.url?.split(".").pop() ===
                      "pdf" ? (
                      <Col sm="6" style={styleHide}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            Consts.URL_IMAGE + dataStudent?.testCardUrl?.url
                          }
                        >
                          {" "}
                          ເປີດໄຟລ PDF
                        </a>
                      </Col>
                    ) : dataStudent?.testCardUrl?.url ? (
                      <Col sm="6" style={styleHide}>
                        <img
                          alt=""
                          onClick={() => {
                            setImgShow(true);
                            setImgUrl(
                              Consts.URL_IMAGE + dataStudent?.testCardUrl?.url
                            );
                          }}
                          src={Consts.URL_IMAGE + dataStudent?.testCardUrl?.url}
                          width="40"
                          height="40"
                        />
                      </Col>
                    ) : (
                      <Col sm="6" style={styleHide}>
                        <span> ຍັງບໍ່ທັນອັບໂຫຼດຮູບ</span>
                      </Col>
                    )}
                  </Row>
                )}
              </Col>
            </Row>
            <Row style={{ fontSize: 10, marginTop: 50 }}>
              <Col>
                <Row>
                  <Col sm="6">ຢືນຢັນ/ປະຕິເສດໂດຍວິຊາການ:</Col>
                  <Col sm="6">
                    {adminApprovedData && adminApprovedData?.user
                      ? `${adminApprovedData?.user?.firstname} ${adminApprovedData?.user?.lastname}`
                      : "-"}
                  </Col>
                  <Col sm="6">ຢືນຢັນ/ປະຕິເສດໂດຍຄະນະ:</Col>
                  <Col sm="6">
                    {facultyApprovedData && facultyApprovedData?.user
                      ? `${facultyApprovedData?.user?.firstname} ${facultyApprovedData?.user?.lastname}`
                      : "-"}
                  </Col>
                </Row>
              </Col>
              <Col></Col>
            </Row>
          </CustomContainer>
        </Col>
        {/* END LAYOUT 1 */}

        {/* START LAYOUT 2 */}
        <Col sm={4} style={{ marginLeft: -5 }}>
          <CustomContainer>
            <p
              className="text-center"
              style={{ fontSize: "18px", color: "#057CAE" }}
            >
              ຂໍ້​ມູນກວດ​ສອບ​ລາຍ​ຊື່​ນັກ​ສຶກ​ສາ
            </p>
            <div className="form-group row">
              <label
                style={{ fontSize: "14px" }}
                htmlFor="searchtext"
                className="col-sm-1 col-form-label"
              >
                ຄົ້ນຫາ
              </label>
              <div className="col-sm-11">
                <input
                  style={{
                    height: "30px",
                    fontSize: "14px",
                  }}
                  type="text"
                  className="form-control boxs"
                  id="searchtext"
                  defaultValue={studentNumber}
                  placeholder="ປ້ອນລະຫັດນັກສຶກສາແລ້ວກົດ Enter "
                  onKeyDown={(e) => _searchStudentNumber(e)}
                />
              </div>
            </div>

            {/* ----------- */}
            <Col sm={6} style={{ fontSize: "12px" }}></Col>
            {codeLoading && <Loading />}
            <table
              style={{ width: "100%", fontSize: "12px" }}
            >
              <thead>
                <tr>
                  <th>ລະຫັດນັກສຶກສາ</th>
                  <th>ຊື່</th>
                  <th>ນາມສະກຸນ</th>
                  <th>ເປັນນັກສຶກສາ</th>
                </tr>
              </thead>
              {datatCode &&
                datatCode?.code.map((item, index) => (
                  <tr
                    style={{
                      borderBottom: "2px solid #ffff",
                      textAlign: "center",
                    }}
                  >
                    <td>{item?.code}</td>
                    {item?.firstNameL ? (
                      <td>{item?.firstNameL}</td>
                    ) : (
                      <td>{item?.firstNameE}</td>
                    )}
                    { }
                    {item?.lastNameL ? (
                      <td>{item?.lastNameL}</td>
                    ) : (
                      <td>{item?.lastNameE}</td>
                    )}
                    <td>
                      {item?.studentType === "IN_PLAN"
                        ? "ນັກ​ສຶກ​ສາ/ພະ​ນັກ​ງານທຶນ​ແບ່ງ​ປັນ​"
                        : item?.studentType === "OUT_PLAN"
                          ? "ນັກ​ສຶກ​ສາ​ຈ່າຍ​ຄ່າ​ຮຽນ"
                          : item?.studentType === "IN_PLAN_EN"
                            ? "ນັກ​ສຶກ​ສາຕ່າງປະເທດທຶນ​ແບ່ງ​ປັນ​"
                            : "ນັກ​ສຶກ​ສາ​ຕ່າງປະເທດຈ່າຍ​ຄ່າ​ຮຽນ"}
                    </td>
                  </tr>
                ))}
            </table>
            <Col className="text-center" style={{ fontSize: "12px" }}>
              <u
                onClick={() => {
                  setDisplayStyle(!displayStyle);
                }}
                style={{ cursor: "pointer" }}
              >
                {displayStyle ? "ເບິ່ງໜ້ອຍລົງ" : "ເບິ່ງເພີ່ມເຕີມ"}
              </u>
              <FontAwesomeIcon
                onClick={() => {
                  setDisplayStyle(!displayStyle);
                }}
                icon={displayStyle ? "caret-up" : "caret-down"}
                style={{
                  cursor: "pointer",
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
            </Col>

            {/* ------------------ */}
            {datatCode &&
              datatCode?.code.map((item, index) => (
                <div
                  style={{
                    display: displayStyle ? "flex" : "none",
                    marginTop: "20px",
                  }}
                >
                  <Col>
                    <div style={{ fontSize: "18px" }}>ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ</div>
                    <Row style={{ fontSize: 14, paddingLeft: 30 }}>
                      <Col sm="6">ລະຫັດນັກສຶກສາ</Col>
                      <Col sm="6">{item?.code}</Col>

                      <Col sm="6">ຄະນະ</Col>
                      <Col sm="6">{item?.faculty?.name}</Col>
                      <Col sm="6">ພາກວິຊາ</Col>
                      <Col sm="6">{item?.department?.name}</Col>
                      <Col sm="6">ສາຂາວິຊາ</Col>
                      <Col sm="6">{item?.major?.name}</Col>

                      <Col sm="6">ຂໍ້ມູນ​ນັກ​ສຶກ​ສາ</Col>
                      <Col sm="6">
                        {item?.studentType === "IN_PLAN"
                          ? "ນັກ​ສຶກ​ສາ/ພະ​ນັກ​ງານທຶນ​ແບ່ງ​ປັນ​"
                          : item?.studentType === "OUT_PLAN"
                            ? "ນັກ​ສຶກ​ສາ​ຈ່າຍ​ຄ່າ​ຮຽນ"
                            : item?.studentType === "IN_PLAN_EN"
                              ? "ນັກ​ສຶກ​ສາຕ່າງປະເທດທຶນ​ແບ່ງ​ປັນ​"
                              : "ນັກ​ສຶກ​ສາ​ຕ່າງປະເທດຈ່າຍ​ຄ່າ​ຮຽນ"}
                      </Col>
                    </Row>
                    <div style={{ fontSize: "18px" }}>ຂໍ້ມູນ​ທົ່ວໄປ</div>
                    <Row style={{ fontSize: 14, paddingLeft: 30 }}>
                      {item?.firstNameL ? (
                        <Row>
                          <Col sm="6"> ຊື່</Col>
                          <Col sm="6">
                            {convertGenderNam(item?.gender)} {item?.firstNameL}
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      {item?.lastNameL ? (
                        <Row>
                          <Col sm="6">ນາມສະກຸນ</Col>
                          <Col sm="6">{item?.lastNameL}</Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      {item?.firstNameE ? (
                        <Row>
                          <Col sm="6">ຊື່(ພາສາອັງກິດ)</Col>
                          <Col sm="6">
                            {convertGenderNamEN(item?.gender)}{" "}
                            {item?.firstNameE}
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      {item?.lastNameE ? (
                        <Row>
                          <Col sm="6">ນາມສະກຸນ(ພາສາອັງກິດ)</Col>
                          <Col sm="6">{item?.lastNameE}</Col>
                        </Row>
                      ) : (
                        ""
                      )}
                      <Col sm="6">ວັນເດືອນປີເກີດ</Col>
                      <Col sm="6">{formatDate(item?.birthday)}</Col>
                    </Row>
                  </Col>
                </div>
              ))}
          </CustomContainer>
        </Col>
        {/* END LAYOUT 2 */}
      </Row>

      <Dialog
        open={show}
        onClose={() => { }}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            width: "30%",
            minWidth: "300px", // You can adjust the minimum width as needed
          },
        }}
      >
        <DialogTitle>ຢືນຢັນການປະຕິເສດ</DialogTitle>
        <DialogContent>
          <DialogContentText>ປ້ອນເຫດຜົນຂອງການປະຕິເສດ</DialogContentText>
          <TextField
            onChange={(e) => setReason(e.target.value)}
            autoFocus
            margin="dense"
            id="name"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => _handleSubmitReject()}>ຢືນຢັນ</Button>
          <Button onClick={() => _closeRejectPrere()}>ຍົກເລີກ</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showIncorrectStudentNumber}
        onClose={() => setShowIncorrectStudentNumber(false)}
        fullWidth
        align="center"
        maxWidth="md"
        PaperProps={{
          sx: {
            width: "30%",
            minWidth: "300px", // You can adjust the minimum width as needed
          },
        }}
      >
        <DialogTitle fontSize={24} color={"red"}>{incorrectStudentNumber} !</DialogTitle>
        
        <DialogActions>
          <Button onClick={() => setShowIncorrectStudentNumber(false)}>ປິດ</Button>
        </DialogActions>
      </Dialog>

      {/* <Modal
        style={{ marginTop: 50 }}
        show={show}
        onHide={() => isLoading ? console.log("waiting") : setShow(false)}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>
            <span style={{fontWeight: "bold" }}>
              ຢືນ​ຢັນ​ການປະ​ຕິ​
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
              <Col
                className="text-center"
                style={{ paddingTop: 40, paddingBottom: 50 }}
              >
                <textarea onChange={(e) => setReason(e.target.value)} classNamex="form-control" id="exampleFormControlInput1" placeholder="ປ້ອນເຫດຜົນຂອງການປະຕິເສດ"></textarea>
              </Col>

              <Col className="d-flex justify-content-between">
                <div />
                <CustomButton
                  onClick={() => _closeRejectPrere()}
                  type="submit"
                  title="ຍົກເລີກ"
                />

                <CustomButton
                  confirm
                  onClick={() => _handleSubmitReject()}
                  type="submit"
                  title="ຢືນຢັນ"
                />
                <div />
              </Col>
            </div>
        </Modal.Body>
      </Modal>
 */}

      {/**
       *
       * show image
       *
       */}
      <Modal
        show={imgShow}
        onHide={() => setImgShow(false)}
        centered
        keyboard={false}
        className="no-underline-header"
      >
        <Modal.Header
          closeButton
          style={{ borderBottom: "none", marginLeft: "auto" }}
        ></Modal.Header>
        <button className="btn" onClick={rotateImage}>
          <i class="fas fa-undo"></i>
        </button>
        <Modal.Body>
          <img
            alt=""
            src={imgUrl}
            width={450}
            height={450}
            style={{ transform: `rotate(${rotation}deg)` }}
          />
        </Modal.Body>
      </Modal>

      <PreregistrationConfirm
        showAddConfirmModal={showAddConfirmModal}
        data={dataStudentConfirm}
        _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
      />
    </div>
  );
}
