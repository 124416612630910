import React, { useEffect, useState } from "react";

import { API_KEY, NEW_API_URL } from "../../common/contrant";
import axios from "axios";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import StudentByYear from "./Components/StudentByYear";
import StudentPere from "./Components/StudentPrere";
import StudentByAge from "./Components/StudentByAge";
import StudentByTribe from "./Components/StudentByTribe";
import StudentByCountry from "./Components/StudentByCountry";
import { CustomContainer } from "../../common";
import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import { Autorenew } from "@mui/icons-material";

const statisticType = [
  { value: 1, label: "ຈຳນວນນັກສຶກສາ ຕາມພາກວິຊາ/ ສາຂາວິຊາ, ຕາມປີຮຽນ (ໃນແຜນ)" },
  {
    value: 2,
    label: "ຈຳນວນນັກຮຽນສາມັນທີ່ຈົບໃນສົກຮຽນປີກາຍ ມາຮຽນຕໍ່ສາຍວິຊາຊີບ",
  },
  { value: 3, label: "ຈຳນວນນັກສຶກສາ ຕາມພາກວິຊາ/ສາຂາວິຊາ ຕາມປີຮຽນ (ນອກແຜນ)" },
  {
    value: 4,
    label: "ຈຳນວນນັກສຶກສາ ຕາມພາກວິຊາ/ສາຂາວິຊາ, ຕາມປີຮຽນ (ລວມທັງ 2 ລະບົບ)",
  },
  {
    value: 5,
    label: "ຈຳນວນນັກສຶກສາແຍກຕາມອາຍຸ, ຕາມລະບົບການຮຽນ (ໃນແຜນ) ປະລິນຍາຕີ",
  },
  {
    value: 6,
    label: "ຈຳນວນນັກສຶກສາແຍກຕາມອາຍຸ, ຕາມລະບົບການຮຽນ (ນອກແຜນ) ປະລິນຍາຕີ",
  },
  {
    value: 7,
    label: "ຈຳນວນນັກສຶກສາຄົນຕ່າງປະເທດ ຕາມປີຮຽນ ແລະ ຕາມປະເທດເກີດ (ນອກແຜນ)",
  },
  {
    value: 8,
    label: "ຈຳນວນນັກສຶກສາຄົນຕ່າງປະເທດ ຕາມປີຮຽນ ແລະ ຕາມປະເທດເກີດ (ໃນແຜນ)",
  },
  { value: 9, label: "ຈຳນວນນັກສຶກສາແຍກຕາມຊົນເຜົ່າ​" },
];

const StudentTab = () => {
  const [facultyList, setFacultyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [selectedMajor, setSelectedMajor] = useState("");
  const [majorList, setMajorList] = useState([]);
  const [selectedTypeValue, setSelectedTypeValue] = useState("");
  const [content, setContent] = useState(null);
  const [triggerExport, setTriggerExport] = useState(false);

  const fetchData = async (url, setData) => {
    try {
      const response = await axios.get(url, {
        headers: { api_key: API_KEY },
      });
      if (response.data.success) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
    }
  };

  useEffect(() => {
    fetchData(`${NEW_API_URL}/faculty`, setFacultyList);
  }, []);

  useEffect(() => {
    setSelectedMajor("");
    if (selectedFaculty) {
      fetchData(
        `${NEW_API_URL}/department/${selectedFaculty}`,
        setDepartmentList
      );
      fetchData(`${NEW_API_URL}/getmajo/${selectedDepartment}`, setMajorList);
    }
  }, [selectedFaculty, selectedDepartment]);

  useEffect(() => {
    if (triggerExport) {
      setTimeout(() => setTriggerExport(false), 3000);
    }
  }, [triggerExport]);

  useEffect(() => {
    const contentMapping = {
      1: (
        <StudentByYear
          studentType={"IN_PLAN"}
          faculty={selectedFaculty}
          department={selectedDepartment}
          major={selectedMajor}
        />
      ),
      2: (
        <StudentPere
          faculty={selectedFaculty}
          department={selectedDepartment}
          major={selectedMajor}
        />
      ),
      3: (
        <StudentByYear
          studentType={"OUT_PLAN"}
          faculty={selectedFaculty}
          department={selectedDepartment}
          major={selectedMajor}
        />
      ),
      4: (
        <StudentByYear
          studentType={""}
          faculty={selectedFaculty}
          department={selectedDepartment}
          major={selectedMajor}
        />
      ),
      5: (
        <StudentByAge
          studentType={"IN_PLAN"}
          faculty={selectedFaculty}
          department={selectedDepartment}
          major={selectedMajor}
        />
      ),
      6: (
        <StudentByAge
          studentType={"OUT_PLAN"}
          faculty={selectedFaculty}
          department={selectedDepartment}
          major={selectedMajor}
        />
      ),
      7: (
        <StudentByCountry
          studentType={"OUT_PLAN"}
          faculty={selectedFaculty}
          department={selectedDepartment}
        />
      ),
      8: (
        <StudentByCountry
          studentType={"IN_PLAN"}
          faculty={selectedFaculty}
          department={selectedDepartment}
        />
      ),
      9: (
        <StudentByTribe
          studentType={"OUT_PLAN"}
          faculty={selectedFaculty}
          department={selectedDepartment}
          major={selectedMajor}
        />
      ),
    };
    setContent(contentMapping[selectedTypeValue] || <></>);
  }, [
    selectedTypeValue,
    triggerExport,
    selectedFaculty,
    selectedDepartment,
    selectedMajor,
  ]);

  const clearFilter = () => {
    setSelectedDepartment("");
    setSelectedMajor("");
    setSelectedFaculty("");
  };

  return (
    <div>
      <CustomContainer className="fix-margin-top">
        <Grid container gap={1} xs={12}>
          <Grid item xs={6}>
            <label>ເລຶອກຫົວຂໍ້ສະຖິຕິ</label>
            <FormControl fullWidth size="small">
              <Select
                value={selectedTypeValue}
                displayEmpty
                onChange={(e) => {
                  setSelectedTypeValue(e.target.value);
                }}
              >
                <MenuItem value="">ເລຶອກຫົວຂໍ້ສະຖິຕິ</MenuItem>
                {statisticType.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <label>ຄະນະວິຊາ</label>
            <FormControl fullWidth size="small" >
              <Select
                value={selectedFaculty}
                displayEmpty
                onChange={(e) => setSelectedFaculty(e.target.value)}
              >
                <MenuItem value="">ເລືອກຄະນະວິຊາ</MenuItem>
                {facultyList.map((faculty) => (
                  <MenuItem key={faculty._id} value={faculty._id}>
                    {faculty.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <label>ພາກວິຊາ</label>
            <FormControl fullWidth size="small">
              <Select
                value={selectedDepartment}
                displayEmpty
                onChange={(e) => setSelectedDepartment(e.target.value)}
              >
                <MenuItem value="">ເລືອກພາກວິຊາ</MenuItem>
                {departmentList.map((department) => (
                  <MenuItem key={department._id} value={department._id}>
                    {department.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} display={'flex'} alignItems={'end'}>
            <FormControl fullWidth size='small'>
              <Button
                variant="contained"
                size='large'
                sx={{
                  backgroundColor: 'darkgrey', '&:hover': {
                    backgroundColor: 'grey',
                  },
                }}
                style={{ textTransform: 'none' }}
                onClick={() => {
                  clearFilter();
                }}
              >
                <Autorenew />
              </Button>
            </FormControl>
          </Grid>
        </Grid>
      </CustomContainer>
      <CustomContainer>
        <div>{content}</div>
      </CustomContainer>
    </div >
  );
};

export default StudentTab;
