import React, { forwardRef, useState } from "react";

export const BillTermComponent = forwardRef((props, ref) => {
    const { studentDetail: data } = props;
    const Top = () => (
        <>
            <div className="c-row">
                <div className="col-8 "></div>
                <div className="col-4 text-start">
                    <p>ທບ8</p>
                </div>
            </div>
            <div className="c-row mb-0">
                <div className="col-8 text-start">
                    <p>ມະຫາວິທະຍາໄລເເຫ່ງຊາດ</p>
                </div>
                <div className="col-4 text-start">
                    <p>ເລກທີ.......</p>
                </div>
            </div>
            <div className="c-row">
                <div className="col-8 text-start">
                    <p>ຊື່ຄະນະວິຊາ: {data?.student?.faculty.name}</p>
                </div>
                <div className="col-4 text-start">
                    <p>ວັນທີ......../......../........</p>
                </div>
            </div>
        </>
    );

    const Header = () => (
        <>
            <div className="c-row">
                <div className="col-12 text-center mb-2">
                    <h5>ໃບລົງທະບຽນຮຽນສົກ {data?.student?._assessmentYear}</h5>
                </div>
            </div>
            <div className="c-row">
                <div className="col-12 text-start">
                    <p>
                        ລະຫັດນັກສຶກສາ: {data?.student?.userId} ຊື່:{" "}
                        {data?.student?.firstNameL + " " + data?.student?.lastNameL}
                    </p>
                </div>
            </div>
            <div className="c-row">
                <div className="col-12 text-start">
                    <p>
                        ຫ້ອງເບີ: {data?.student?.classRoom?.name} ເບີໂທ:{" "}
                        {data?.student?.phoneMobile}
                    </p>
                </div>
            </div>
        </>
    );

    const CourseList = () => (
        <>
            <div className="c-row">
                <div className="col-12 text-center">
                    <h5>
                        ລາຍວິຊາຮຽນ ປີ{" "}
                        {data?.student?.yearLevel + " ສາຂາ: " + data?.student?.major?.name}
                    </h5>
                </div>
            </div>

            <div className="row">
                <table>
                    <thead>
                        <tr>
                            <th colSpan={1} className="py-2 text-center bg-white text-dark">
                                ລຳດັບ
                            </th>
                            <th colSpan={2} className="text-start bg-white text-dark">
                                ລະຫັດວິຊາ
                            </th>
                            <th colSpan={3} className="text-start bg-white text-dark">
                                ຊື່ວິຊາ
                            </th>
                            <th colSpan={1} className="text-cemter bg-white text-dark">
                                ຫນ່ວຍກິດ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.courses?.map((course, index) => (
                            <tr key={course?.courseCode}>
                                <td colSpan={1} className="text-center py-2">
                                    {index + 1}
                                </td>
                                <td className="text-start py-2" colSpan={2}>
                                    {" "}
                                    {course?.courseCode}
                                </td>
                                <td className="text-start py-2" colSpan={3}>
                                    {course?.subject?.title}
                                </td>
                                <td className="text-center py-2" colSpan={1}>
                                    {" "}
                                    {course?.credit}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );

    const Footter = () => (
        <>
            <div className="row">
                <div className="col-4">
                    <p>ພະເເນກການເງິນ</p>
                </div>
                <div className="col-4">
                    <p>ຄະນະວິຊາການ</p>
                </div>
                <div className="col-4">
                    <p>ລາຍເຊັນນັກສຶກສາ</p>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12 text-start ps-5">
                    <p className="ps-3">ຫມາຍເຫດ: ນັກສຶກສາເກັບໄວ້ ຫ້າມເຮັດເສຍ</p>
                </div>
            </div>
        </>
    );

    const PayList = () => (
        <div className="c-row">
            <div className="col text-end">
                <p>ລວມຫນ່ວກິດ {" " + totalUnit + " "} ຫນ່ວຍກິດ</p>
                <p>ຄ່າຫນ່ວຍກິດ {data?.creditPrice?.toLocaleString()} ກີບ/ຫນ່ວຍກິດ</p>
                {data?.otherPaidList?.map((pay) => (
                    <div key={pay?.title_la}>
                        <p>
                            {pay?.title_la} {pay?.price.toLocaleString()} ກີບ
                        </p>
                    </div>
                ))}
                <p>ລວມເງິນ {" " + data?.totalPaid.toLocaleString() + " "} ກີບ</p>
            </div>
        </div>
    );

    const totalUnit = data?.courses.reduce(
        (total, course) => total + (course.unit || 0),
        0
    );

    return (
        <div className="d-none">
            <div ref={ref} className="c-row ml-3 mr-3">
                <div className="col-6  mt-4">
                    <div className="mr-3">
                        <Top />
                        <Header />
                        <CourseList />
                        <PayList />
                        <Footter />
                    </div>
                </div>
                <div className="col-6 mt-4">
                    <div className="">
                        <Top />
                        <Header />
                        <CourseList />
                        <PayList />
                        <Footter />
                    </div>
                </div>
            </div>
        </div>
    );
});