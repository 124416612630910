import React from "react";
import moment from "moment";
import Notiflix from "notiflix";
import { result } from "lodash";
// import 'moment/locale/lo'


export const convertStudyStatus = (status) => {
  let res = "";
  switch (status) {
    case "STUDYING":
      res = "ກຳລັງສຶກສາ";
      break;
    case "DROP":
      res = "ໂຈະການຮຽນ";
      break;
    case "DONT_STUDY":
      res = "ປະລະການຮຽນ";
      break;
    case "GRADUATED":
      res = "ຈົບການສຶກສາ";
      break;
    default: // Do nothing

  }
  return res;
};
// ກຳນົດ ເພດ
export const setGender = (SetGender) => {
  let res = "";
  switch (SetGender) {
    case "MALE":
      res = "ຊາຍ";
      break;
    case "FEMALE":
      res = "ຍິງ";
      break;
    case "OTHER":
      res = "ອື່ນໆ";
      break;
    default: // Do nothing

  }
  return res;
};
export const setGenders = (SetGender) => {
  let res = "";
  switch (SetGender) {
    case "FEMALE":
      res = "ນາງ";
      break;
    case "MALE":
      res = "ທ້າວ";
      break;
    case "OTHER":
      res = "ອື່ນໆ";
      break;
    default: // Do nothing
  }
  return res;
};
// ກຳນົດ ສະຖານະປິດເປີດ
export const setSwich = (string) => {
  let res = "";
  switch (string) {
    case true:
      res = "ເປີດ";
      break;
    case false:
      res = "ປິດ";
      break;
    default: // Do nothing
  }
  return res;
};

// ກຳນົດ ຄ່າເລີ່ມຕົ້ນ(ເພດ)
export const checkgender = (gender) => {
  let gd = "";
  switch (gender) {
    case "FEMALE":
      gd = "checked";
      break;
    case "MALE":
      gd = "checked";
      break;
    case "OTHER":
      gd = "checked";
      break;
    default: // Do nothing
  }
  return gd;
};

// ກຳນົດ ສະຖານະພາບ
export const noticeStatus = (notice) => {
  let status = "";
  switch (notice) {
    case "IMPORTANT":
      status = "ເອກະສານທີ່ສຳຄັນ";
      break;
    case "NORMAL":
      status = "ທົ່ວໄປ";
      break;
    case "NOTIC":
      status = "ແຈ້ງການ";
      break;
    case "AGREEMENT":
      status = "ຂໍ້ຕົກລົງ";
      break;
    case "REGULATION":
      status = "ລະບຽບການ";
      break
    case "NEWS":
      status = "ໜັງສືພິມ";
      break;
    default: // Do nothing
  }
  return status;
};
// ກຳນົດ ສະຖານະພາບ
export const MaritualStatus = (maritualStatus) => {
  let status = "";
  switch (maritualStatus) {
    case "SINGLE":
      status = "ໂສດ";
      break;
    case "MARRIAGE":
      status = "ເເຕ່ງງານ";
      break;
    default: // Do nothing
  }
  return status;
};

// ກຳນົດ ສະຖານະພາບ
export const gradeTypeName = (name) => {
  let status = "";
  switch (name) {
    case "ຄະແນນຂື້ນຫ້ອງ":
      status = "scoreOnclass";
      break;
    case "ຄະແນນກິດຈະກຳ":
      status = "scoreOnPaticipate";
      break;
    case "ຄະແນນເສັງກາງພາກ":
      status = "scoreOnMidTerm";
      break;
    case "ຄະແນນເສັງທ້າຍພາກ":
      status = "scoreOnFinal";
      break;
    default: // Do nothing
  }
  return status;
};
// ກຳນົດ ສະຖານະການລົງທະບຽນ
export const RegStatus = (reStatus) => {
  let status = "ຍັງ";
  switch (reStatus) {
    case 1:
      status = "ແລ້ວ";
      break;
    default: // Do nothing
  }
  return status;
};

export const bracket = (tag) => {
  let st = "(";
  let en = ")";
  let i = st + tag + en;
  return i;
};

//  ກຳນົດ ອາຍຸ
export const age = (age) => {
  let today = new Date();
  let y = today.getFullYear();
  let dob = moment(age).format("YYYY");
  return y - dob;
};

// ກຳນົດ ຟໍແມັດເງິນ
export const currency = (value) => {
  let currencys = new Intl.NumberFormat("en-CA").format(value);
  if (value !== 0) return currencys;
  else if (value === 0) return "0";
  else return "";
};

// ກຳນົດ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const formatDateTime = (dateTime) => {
  moment.locale("lo");
  let resp = moment(dateTime).format("DD MMMM YYYY, HH:mm");
  return resp;
};
// ກຳນົດເວລາ 
export const dateTime = (dateTime) => {
  moment.locale("lo");
  let resp = moment(dateTime).format("HH:mm:ss");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ພາສາລາວ)
export const formatDate = (dateTime) => {
  moment.locale("lo");
  let resp = moment(dateTime).format("DD MMMM YYYY");
  if (dateTime) return resp;
  else return "";
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(/)
export const formateDateSlash = (slashData) => {
  let resp = moment(slashData).format("YYYY/MM/DD");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const formatDateDash = (dashDate) => {
  let resp = moment(dashDate).format("YYYY-MM-DD");
  return resp;
};
export const formatDateDashDMY = (dashDate) => {
  let resp = moment(dashDate).format("DD-MM-YYYY");
  return resp;
};

export const formatDateYYMMDD = (dashDate) => {
  let resp = moment(dashDate).format("YYYY-MM-DD");
  return resp;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ອາທິດ)
export const nextSevenDay = () => {
  var nextSenvenDay = moment().add(7, "days");
  var nextSevenDays = moment(nextSenvenDay).format("YYYY-MM-DD");
  return nextSevenDays;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(ຖັດໄປ 1 ມື້ ໂດຍມີ parameter)
export const nextOneDay = () => {
  var nextOneDay = moment().add(1, "days");
  var nextOneDays = moment(nextOneDay).format("YYYY-MM-DD");
  return nextOneDays;
};
// ກໍານົດ ວັນທີປະຈຸບັນ(ຖັດໄປ 1 ມື້)
export const nextOneDayParameter = (e) => {
  var nextOneDay = moment(e).add(1, "days");
  var nextOneDays = moment(nextOneDay).format("YYYY-MM-DD");
  return nextOneDays;
};

// ກຳນົດ ວັນທີ ແລະ ເວລາປັດຈຸບັນ(ພາສາລາວ)
export const toDay = () => {
  moment.locale("lo");
  var today = new Date();
  var todays = moment(today).format("DD/MM/YY, HH:mm");
  return todays;
};

// ກຳນົດ ວັນທີປັດຈຸບັນ(-)
export const toDayDash = () => {
  var today = new Date();
  var todays = moment(today).format("YYYY-MM-DD");
  return todays;
};
export const reconvertDay = (day) => {
  let result = "";
  switch (day) {
    case "MONDAY":
      result = "ຈັນ";
      break;
    case "TUESDAY":
      result = "ອັງຄານ";
      break;
    case "WEDNESDAY":
      result = "ພຸດ";
      break;
    case "THURSDAY":
      result = "ພະຫັດ";
      break;
    case "FRIDAY":
      result = "ສຸກ";
      break;
    case "SATURDAY":
      result = "ເສົາ";
      break;
    case "SUNDAY":
      result = "ອາທິດ";
      break;
    default:
      result = "ຈັນ";

  }
  return result;
};

export const convertDay = (day) => {
  let result = ''
  switch (day) {
    case 'ຈັນ':
      result = 'MONDAY';
      break;
    case 'ອັງຄານ':
      result = 'TUESDAY';
      break;
    case 'ພຸດ':
      result = 'WEDNESDAY';
      break;
    case 'ພະຫັດ':
      result = 'THURSDAY';
      break;
    case 'ສຸກ':
      result = 'FRIDAY';
      break;
    case 'ເສົາ':
      result = 'SATURDAY';
      break;
    case 'ອາທິດ':
      result = 'SUNDAY';
      break;
    default:
      result = 'MONDAY';
  }
  return result;
}
export const onConvertStudentType = (studentType) => {
  let result;
  switch (studentType) {
    case "IN_PLAN":
      result = "ນັກສຶກສາລາວ (ໃນແຜນ)";
      break;
    case "OUT_PLAN":
      result = "ນັກສຶກສາລາວ (ນອກແຜນ)";
      break;
    case "IN_PLAN_EN":
      result = "ນັກສຶກສາຕ່າງປະເທດ (ໃນແຜນ)";
      break;
    case "OUT_PLAN_EN":
      result = "ນັກສຶກສາຕ່າງປະເທດ (ນອກແຜນ)";
      break;
    default:
      result = "";
  }
  return result;
};
export const convertLetterGrade = (numericGrade) => {
  if (numericGrade >= 91) return "A";
  else if (numericGrade >= 81) return "B+";
  else if (numericGrade >= 70) return "B";
  else if (numericGrade >= 65) return "C+";
  else if (numericGrade >= 60) return "C";
  else if (numericGrade >= 55) return "D+";
  else if (numericGrade >= 50) return "D";
  else if (numericGrade >= 0) return "F";
  else if (numericGrade?.toString() === "") return "I";
  else return "";
};

// ກຳນົດ ເຄື່ອງໝາຍ ບັງຄັບໃຫ້ປ້ອນຂໍ້ມູນ
export const valiDate = () => {
  return <font style={{ color: "red" }}> * </font>;
};

// ກຳນົດ ການຈັດລຽງຕາມຄ່າຕ່າງໆ
export const SortBy = ({ sort }) => {
  return (
    <div
      onClick={() => {
        sort();
      }}
      style={{
        float: "right",
        marginRight: 5,
        cursor: "pointer",
      }}
    >
      <i className="fa fa-sort"></i>
    </div>
  );
};
export const alertSuccess = (e) => {
  Notiflix.Notify.init({
    width: '280px',
    position: 'right-bottom',
    zindex: 4000,
    distance: '10px',
    opacity: 1,
  });

  Notiflix.Report.success(
    'ສຳເລັດ', e, 'ອອກ',
    function () {
      window.location.reload();
    },
    {
      width: '360px',
      svgSize: '120px',
    },
  );
};

export const alertWarning = (e) => {
  Notiflix.Notify.init({
    width: '280px',
    position: 'right-bottom',
    zindex: 4000,
    distance: '10px',
    opacity: 1,
  });
  return Notiflix.Notify.warning(
    "ບໍ່ສຳເລັດ",
    e + " ບໍ່ສຳເລັດ ",
    "ອອກ",
    function () {
      window.location.reload();
    }
  );
};
export const alertWarningClassRoom = (text) => {
  Notiflix.Notify.init({
    width: '320px', position: 'right-bottom', zindex: 4000, distance: '10px', opacity: 1,
  });
  return Notiflix.Notify.warning(text);
};
export const alertWarnings = (e) => {
  Notiflix.Notify.init({
    width: '280px',
    position: 'right-bottom',
    zindex: 4000,
    distance: '10px',
    opacity: 1,
  });
  return Notiflix.Notify.warning(
    "ຂໍ້ມູນຜິດພາດ",
    " " + e + " ",
    "ອອກ",
    function () { }
  );
};

export const messageWarning = (e) => {
  Notiflix.Notify.init({
    width: '280px',
    position: 'right-bottom',
    zindex: 4000,
    distance: '10px',
    opacity: 1,
  });
  Notiflix.Notify.warning(e);
};

export const alertConfirm = (action) => {
  return Notiflix.Confirm.Show(
    "Confirm",
    "ທ່າຕ້ອງການລຶບຂໍ້ມູນນີ້ແທ້ ຫຼື ບໍ່ ?",
    "ຕ້ອງການ",
    "ບໍ່ຕ້ອງການ",
    function () {
      action();
    },
    function () {
      window.location.reload();
    }
  );
};

export const startLoading = () => {
  return Notiflix.Loading.circle();
};

export const messageSuccess = (e) => {
  Notiflix.Notify.init({
    width: '280px',
    position: 'right-bottom',
    zindex: 4000,
    distance: '10px',
    opacity: 1,
  });
  Notiflix.Notify.success(e);
};

export const gradeSending = () => {
  Notiflix.Loading.arrows("ຄະແນນຂອງວິຊານີ້ຖືກສົ່ງແລ້ວ");
};

export const sendingLoad = (e) => {
  Notiflix.Loading.standard(e);
};

// Conver status send grade
export const Translater = (gradeStatus) => {
  let checkStatus = "";
  switch (gradeStatus) {
    case "TEACHER_SCORING":
      checkStatus = "ອຈ ໃຫ້ຄະແນນ";
      break;
    case "TEACHER_SUBMITED":
      checkStatus = "ອຈ ສົ່ງຄະແນນແລ້ວ";
      break;
    case "TEACHER_REJECTED":
      checkStatus = "ອຈ ຖືກປະຕິເສດ";
      break;
    // case "TEACHER_REJECTED":
    //   checkStatus = "ອາຈານ ປະຕິເສດຄະແນນແລ້ວ";
    //   break;
    case "DEPARTMENT_CHECKING":
      checkStatus = "ພຊ ກວດຄະແນນ";
      break;
    case "DEPARTMENT_SUBMITED":
      checkStatus = "ພຊ ສົ່ງຄະແນນແລ້ວ";
      break;
    case "DEPARTMENT_REJECTED":
      checkStatus = "ພຊ ຖຶກປະຕິເສດ";
      break;
    case "FACULTY_CHECKING":
      checkStatus = "ຄນ ກວດຄະແນນ";
      break;
    case "FACULTY_SUBMITED":
      checkStatus = "ຄນ ສົ່ງຄະແນນແລ້ວ";
      break;
    case "FACULTY_REJECTED":
      checkStatus = "ຄະນະ ໄດ້ປະຕິເສດຄະແນນແລ້ວ";
      break;
    case "ADMIN_CHECKING":
      checkStatus = "ວທຊ ກຳລັງກວດຄະແນນ";
      break;
    case "ADMIN_APPROVED":
      checkStatus = "ການຢືນຢັນຄະແນນສຳເລັດ";
      break;
    default: // Do nothing
  }
  return checkStatus;
};

export const translateSubjectGroup = (subject) => {
  let result = '';
  switch (subject) {
    case 'GENERAL':
      result = 'ໝວດວິຊາທົ່ວໄປ';
      break;
    case 'BASIC':
      result = 'ໝວດວິຊາພື້ນຖານ';
      break;
    case 'SPECIAL':
      result = 'ໝວດວິຊາສະເພາະ';
      break;
    case 'OPPTION':
      result = 'ໝວດວິຊາເລືອກ';
      break;
  }
  return result;
}

export const convertWeek = (week) => {
  let result = ''
  switch (week) {
    case 'ALL':
      result = 'ທຸກອາທິດ';
      break;
    case 'SINGLE':
      result = 'ອາທິດຄີກ';
      break;
    case 'DOUBLE':
      result = 'ອາທິດຄູ່';
      break;
    default:
      result = 'ທຸກອາທິດ';
  }
  return result;
}

export const removeDuplicates = (arr) => {
  return arr?.filter((item,
    index) => arr?.indexOf(item) === index);
}

export const convertHealthStatus = (status) => {
  switch (status) {
    case "GOOD":
      return "ສົມບຸນ";
    case "BAD":
      return "ພິການ";
    default:
      return "ສົມບຸນ"
  }
}

export const convertHealthStatusEn = (status) => {
  switch (status) {
    case "GOOD":
      return "Normal";
    case "BAD":
      return "Disability";
    default:
      return "Normal"
  }
}