import { gql } from "apollo-boost";

export const DASHBOARD_DATA = gql`
  query DashboardData(
    $studentWhere: CustomStudentWhereInput
    $userWhere: UserCustomWhereInput
    $prereStudentWhere: WhereStudentStatisticsInput
    $fileWhere: CustomFileWhereInput
    $courseWhere: CustomCourseWhereInput
    $teacherFirst: Int
    $whereCountStudentGender: CountStudentGenderWhereInput
  ) {
    students(where: $studentWhere) {
      total
    }

    countStudentGender(where: $whereCountStudentGender) {
      gender
      amount
    }
    
    course_page (where: $courseWhere) {
      total
    }

    files(where: $fileWhere){
      total
    }
    user_page(where: $userWhere, first: $teacherFirst) {
      total
      femaleCountTeacher
      maleCountTeacher
    }
    preregistrationStudentStatistics(where: $prereStudentWhere) {
      total
      approvedCounts
      requestCounts
      data{
        name
        count
        request
        approved
      }
    }
  }
`;

export const DASHBOARD_FACULTIES = gql`
  query Faculties(
    $where: CustomFacultyWhereInput
  ) {
    faculties(
      where: $where
    ) {
        id
        name
    }
  }
`;

export const DASHBOARD_STUDENTS = gql`
  query Students(
    $where: CustomStudentWhereInput
    $orderBy: StudentOrderByInput
    $skip: Int
    $after: String
    $first: Int
  ) {
    students(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      first: $first
    ) {
      total
      totalMale
      totalFemale
      data {
        id
        gender
        firstNameL
        lastNameL
        firstNameE
        lastNameE
        faculty {
          id
          name
        }
      }
    }
  }
`;

export const DASHBOARD_TEACHER = gql`
query Users(
    $where: UserCustomWhereInput
    $orderBy: UserOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    user_page(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        id
        role
        gender
        firstname
        lastname
        enFirstname
        enLastname
        faculty {
          id
          name
        }
      }
      total
      femaleCountTeacher
      maleCountTeacher
      countTeacher
    }
  }
`;
