import { gql } from 'apollo-boost'

export const QUERY_DATA_REGISTRATION = gql`
query QueryRegistration(
    $studentWhere: CustomStudentWhereInput
    $studentSkip: Int
    $studentFirst: Int

    $FacultyWhere: CustomFacultyWhereInput
    $departmentWhere: CustomDepartmentWhereInput

    $courseWhere: CustomCourseWhereInput
  ) {
    students(
      where: $studentWhere
      skip: $studentSkip
      first: $studentFirst
      ) {
      data {
        id
        gender
        firstNameL
        lastNameL
        firstNameE
        lastNameE
        studentNumber
        yearLevel
        faculty {
          id
          name
        }
        department{
          id
          name
        }
      }
      total
    }

    faculties(where: $FacultyWhere) {
        id
        name
        departments{
          id
          name
      }
    }

    course_page(where: $courseWhere) {
      data{
        id
        title
      }
    }

    departments(where: $departmentWhere) {
        id
        name
        faculty{
          id
          name
        }
    }
  }
`;

export const REGISTER_DEPARTMENT = gql`
query Departments( $where: CustomDepartmentWhereInput) {
    departments(where: $where) {
        id
        name
        faculty{
          id
          name
        }
    }
  }
`;

export const FACULTIES = gql`
  query Faculty (
    $where: CustomFacultyWhereInput
    $orderBy: OrderDataInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int){
    faculties (
      where: $where 
      orderBy: $orderBy 
      skip: $skip 
      after: $after 
      before: $before 
      first: $first 
      last: $last){
      id
      name
      enName
      description
      enDescription
      startDateRegister
      endDateRegister
      status
      note
      assessmentYear
      createdBy {
        role
        firstname
        lastname
      }
      
    }
  }
`

export const FACULTY = gql`
  query Faculty ( $where: CustomFacultyWhereInputOne!){
    faculty (where: $where){
      id
      name
      enName
      description
      enDescription
      startDateRegister
      endDateRegister
      status
      note
      createdBy {
        role
        firstname
        lastname
      }
      updatedBy {
        role
        firstname
        lastname
      }
      createdAt
      updatedAt
    }
  }
`

export const DEPARTMENTS = gql`
  query Departments(
    $where: CustomDepartmentWhereInput
    $orderBy: DepartmentOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    departments(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
        id
        name
        enName
        description
        status
        enDescription
        startDateRegister
        endDateRegister
        note
        faculty{
          id
          name
          note
        }
        startDateRegister
        endDateRegister
    }
  }
`
