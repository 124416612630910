import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as _ from "lodash";
import AsyncSelect from "react-select/async";
import chroma from "chroma-js";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { alertSuccess, alertWarning } from "../../common/super";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Consts from "../../consts";
import {
  CREATE_REGISTRATION,
  STUDENT_TO_CHECK,
} from "../../apollo/registration";
import { QUERY_STUDENTS, QUERY_STUDENT } from "../../apollo/user";
import { COURSE_TO_CHECK, COURSE_ADD } from "../../apollo/course";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "1px solid #ced4da",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma("rgb(5, 124, 174)");
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "rgb(5, 124, 174)"
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : "rgb(5, 124, 174)",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled &&
          (isSelected ? "rgb(5, 124, 174)" : color.alpha(0.3).css()),
      },
    };
  },
};

function RegistrationAdd({ showAddModal, _handleShowAddModalClose }) {
  //States
  const [coursesDataQuery, setCoursesDataQuery] = useState();
  const [getCourseCode, setGetCourseCode] = useState("");
  const [getCourseName, setGetCourseName] = useState("");
  const [getCourseCode2, setGetCourseCode2] = useState("");
  const [getStudentCode, setGetStudentCode] = useState("");
  const [getStudentCode2, setGetStudentCode2] = useState("");
  const [courseNull, setCourseNull] = useState("");
  const [studentNull, setStudentNull] = useState("");
  const [createStatus, setCreateStatus] = useState("");
  const [studentOptions, setStudentOptions] = useState([]);
  
  const LIMIT = 100;
  // Apollo
  const [createRegistration] = useMutation(CREATE_REGISTRATION);
  const [loadUsersData, { data: usersData }] = useLazyQuery(QUERY_STUDENTS);
  const [loadUserData, { data: userData }] = useLazyQuery(QUERY_STUDENT);

  const [loadStudentCheck, { data: studentData }] = useLazyQuery(STUDENT_TO_CHECK);
  const [loadCourseCheck, { data: checkData }] = useLazyQuery(COURSE_TO_CHECK, {
    variables: { where: { id: getCourseCode2 } },
  });
  const [loadCoursesData, { data: coursesData }] = useLazyQuery(COURSE_ADD);
  // Use
  useEffect(() => {
    loadUsersData({
      variables: {
        first: LIMIT,
      },
    });
    if(getStudentCode2){
      loadStudentCheck({
        variables: { where: { student: { studentNumber: getStudentCode2 } } },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getCourseCode) {
      setGetCourseCode2(getCourseCode);
      loadCourseCheck()
      setCourseNull("");
      setCreateStatus("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCourseCode]);

  useEffect(() => {
    if (getStudentCode) {
      setGetStudentCode2(getStudentCode);
      setStudentNull("");
      setCreateStatus("");
    }
  }, [getStudentCode]);

  useEffect(() => {
    if (userData?.student) {
      loadCoursesData({
        variables: {
          where: {
            isDeleted: false,
            faculty: userData.student?.faculty?.id.toString(),
            department: userData.student?.department?.id.toString(),
          },
          first: LIMIT,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  // get studentOptions
  useEffect(() => {
    if (usersData?.students?.data?.length > 0) {
      let newOptions = [];
      for (var i = 0; i < usersData?.students?.data?.length; i++) {
        newOptions.push({
          value: usersData?.students?.data[i]?.id,
          label:
            usersData?.students?.data[i]?.firstNameL +
            " " +
            (usersData?.students?.data[i]?.lastNameL ?? ""),
        });
      }
      setStudentOptions(newOptions);
    }
  }, [usersData]);

  // get courseOptions
  useEffect(() => {
    if (coursesData?.courses?.data.length > 0) {
      setCoursesDataQuery(coursesData?.courses?.data);
    }
  }, [coursesData]);

  //functions

  // start on search student data from AsyncSelect
  const filterUserData = (inputValue) => {
    return studentOptions.filter(
      (i) =>
        i.value.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const studentPromiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterUserData(inputValue));
      }, 1000);
    });
  };

  const _handleInputStudentChange = (e) => {
    setGetStudentCode(e);
    if (e.split(" ").length > 1) {
      loadUsersData({
        variables: {
          where: {
            firstNameL: e.split(" ")[0],
            // lastNameL: e.split(" ")[1],
          },
          first: LIMIT,
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } else {
      loadUsersData({
        variables: {
          where: {
            firstNameL: e,
          },
          first: LIMIT,
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  };
  const _handleStudentChange = (e) => {
    setGetStudentCode(e.value);
    loadUserData({
      variables: {
        where: {
          id: e.value,
        },
      },
    });
  };

 
  const _handleCourseChange = (e) => {
    setGetCourseCode(e.target.value.split("|")[1]);
    setGetCourseName(e.target.value.split("|")[0]);
  };

  // end on search course data from AsyncSelect
  const _registration = (values) => {
    let paramQL = {
      data: {
        studentId: getStudentCode2,
        courseId: getCourseCode2,
      },
    };
    createRegistration({ variables: paramQL })
      .then(async () => {
        await _handleShowAddModalClose();
        alertSuccess("ລົງທະບຽນ");
      })
      .catch((err) => {
        _handleShowAddModalClose();
        alertWarning("ລົງທະບຽນລົ້ມແຫຼວ");
      });
  };
  const _checkStudentSchedule = async (values) => {
    _checkNull();
    if (getCourseCode2 && getStudentCode2) {
      if (!_registerCheck(getCourseCode2)) {
        if (_checkSchedule()) {
          _registration(values);
        } else {
          setCreateStatus("ມີຊົ່ວໂມງຮຽນທີ່ຕຳກັນ");
        }
      } else {
        setCreateStatus("ວິຊານີ້ໄດ້ລົງທະບຽນແລ້ວ");
      }
    }
  };

  const _checkSchedule = () => {
    if (
      studentData &&
      studentData.registrations &&
      studentData.registrations.length > 0
    ) {
      let count = 0;
      for (var i = 0; i < studentData.registrations.length; i++) {
        if (
          checkData &&
          checkData.course &&
          checkData.course.year &&
          checkData.course.year === studentData.registrations[i].course.year
        ) {
          if (
            checkData.course.semester ===
            studentData.registrations[i].course.semester
          ) {
            let timeDuplicated = studentData.registrations.filter(
              (_registration) => {
                let _check = false;
                let _dayTimeIndexes = _registration.course.dayTimeIndexes;
                _dayTimeIndexes.filter((_schedule) => {
                  _check = checkDayAndTimeDuplicate(_schedule);
                });
                return _check;
              }
            );
            if (!timeDuplicated.length > 0) {
              count++;
            }
          }
        }
      }
      return count === 0;
    } else {
      return true;
    }
  };

  const checkDayAndTimeDuplicate = (dayTime) => {
    let isDayDuplicate = false;
    let isTimeDuplicate = false;
    if (
      checkData.course &&
      checkData.course.dayTimeIndexes &&
      checkData.course.dayTimeIndexes.length > 0
    ) {
      checkData.course.dayTimeIndexes.filter((_checkDayTimeData) => {
        //Check if Day is duplicated
        if (dayTime.day === _checkDayTimeData.day) isDayDuplicate = true;
        //check if times is duplicated
        _checkDayTimeData.timeIndexes.filter(times => {
          isTimeDuplicate = dayTime.timeIndexes.includes(times);
        });
      });
    }
    return isDayDuplicate || isTimeDuplicate;
  };

  const _checkNull = () => {
    if (!getCourseCode2) {
      setCourseNull("ກະລຸນາເລືອກ");
    } else {
      setCourseNull("");
    }
    if (!getStudentCode2) {
      setStudentNull("ກະລຸນາເລືອກ");
    } else {
      setStudentNull("");
    }
  };

  const _registerCheck = (courseId) => {
    let status = false;
    if (studentData && studentData.registrations.length > 0) {
      for (var i = 0; i < studentData.registrations.length; i++) {
        if (
          studentData.registrations[i].course &&
          studentData.registrations[i].course.courseCode === courseId
        ) {
          status = true;
        }
      }
    }
    return status;
  };

  return (
    <div>
      <Modal show={showAddModal} onHide={_handleShowAddModalClose} size="lg">
        <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
          <b>ລົງທະບຽນວິຊາ</b>
        </Modal.Title>
        <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
          {/* {(coursesLoading) && "Loading..."} */}
          <Formik
            initialValues={{
              note: "",
            }}
            validationSchema={{}}
            onSubmit={(values, { setSubmitting }) => {
              _checkStudentSchedule(values);
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <div>
                <div
                  style={{
                    border: "#eee solid 1px",
                    padding: 50,
                    paddingBottom: createStatus ? 10 : 50,
                  }}
                >
                  <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                    style={{ margin: 0, marginBottom: 10 }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ເລືອກນັກສຶກສາ
                    </Form.Label>
                    <Col sm="8">
                      <AsyncSelect
                        styles={colourStyles}
                        // cacheOptions
                        defaultOptions
                        loadOptions={studentPromiseOptions}
                        onInputChange={_.debounce(
                          _handleInputStudentChange,
                          500
                        )}
                        onChange={_handleStudentChange}
                        placeholder="ກະລຸນາເລືອກ..."
                      />
                      {studentNull && (
                        <p style={{ color: "red", fontSize: 12 }}>
                          {studentNull}
                        </p>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                    style={{ margin: 0, marginBottom: 10 }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ເລືອກວິຊາ
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        as="select"
                        value={getCourseName + "|" + getCourseCode}
                        onChange={(e) => _handleCourseChange(e)}
                      >
                        <option disabled value={"|"}>
                          ກະລຸນາເລືອກ...
                        </option>
                        {coursesDataQuery?.map((item, index) => (
                          <option
                            key={index}
                            value={item.title + "|" + item.id}
                          >
                            {item.title}
                          </option>
                        ))}
                      </Form.Control>
                      {/* <AsyncSelect
                        styles={colourStyles}
                        // cacheOptions
                        defaultOptions
                        loadOptions={coursePromiseOptions}
                        onInputChange={_.debounce(_handleInputCourseChange, 500)}
                        onChange={_handleCourseChange}
                        placeholder="ກະລຸນາເລືອກ..."
                      /> */}
                      {courseNull && (
                        <p style={{ color: "red", fontSize: 12 }}>
                          {courseNull}
                        </p>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                    style={{ margin: 0, marginBottom: 10 }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ໝາຍເຫດ
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="ກະລຸນາປ້ອນ"
                        name="note"
                        value={values.note}
                        onChange={handleChange}
                        isInvalid={!!errors.note}
                      />
                    </Col>
                  </Form.Group>

                  {createStatus && (
                    <p
                      style={{
                        color: "red",
                        fontSize: 16,
                        textAlign: "center",
                      }}
                    >
                      {createStatus}
                    </p>
                  )}
                </div>
                <div style={{ height: 20 }} />
                <div className="row">
                  <div style={{ padding: 15 }} className="col">
                    <Button
                      onClick={_handleShowAddModalClose}
                      style={{
                        width: "100%",
                        backgroundColor: "#fff",
                        color: "#6f6f6f",
                        borderColor: Consts.SECONDARY_COLOR,
                      }}
                    >
                      ຍົກເລີກ
                    </Button>
                  </div>
                  <div style={{ padding: 15 }} className="col">
                    <Button
                      style={{
                        width: "100%",
                        backgroundColor: Consts.SECONDARY_COLOR,
                        color: "#fff",
                        borderColor: Consts.SECONDARY_COLOR,
                      }}
                      onClick={handleSubmit}
                    >
                      ລົງທະບຽນ
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RegistrationAdd;
