import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import Switch from "@material-ui/core/Switch";
import { Form, Modal } from "react-bootstrap";
import { valiDate, alertSuccess, alertWarning } from "../../../common/super";
import { Formik } from "formik";
import moment from "moment";
import { CustomButton } from "../../../common";
import { UPDATE_DEPARTMENT } from "../../../apollo/deparment/index";
import { fetchPut } from "../../../common/fetchAPI";

const EditDepartment = ({
  showSearchView,
  _handleSearchViewClose,
  allData,
  getData,
}) => {
  const [updateFaculty] = useMutation(UPDATE_DEPARTMENT);
  const [checkStatus, setCheckStatus] = useState();
  const [assessmentYear, setAssessmentYear] = useState([]);

  useEffect(() => {
    let year = [];
    const NOW = new Date().getFullYear();
    for (var i = NOW - 0; i >= 2014; i--) {
      year.push(i + " - " + (parseInt(i) + 1));
    }
    setAssessmentYear(year);

    if (allData?.status === "ENABLE") {
      setCheckStatus(true);
    } else {
      setCheckStatus(false);
    }
  }, [allData]);

  const handleChangeStatus = async () => {
    setCheckStatus(!checkStatus);
  };

  const _saveUpdateDepartment = async (data) => {
    try {
      await fetchPut("department/" + allData._id, data);
      _handleSearchViewClose();
    } catch (err) {
      _handleSearchViewClose();
    }
  };

  
  return (
    <div>
      {allData && (
        <Formik
          initialValues={{
            name: allData?.name,
            description: allData?.description,
            enName: allData?.enName,
            enDescription: allData?.enDescription,
            assessmentYear: allData?.assessmentYear,
            note: allData?.note,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "ກະລຸນາປ້ອນຊື່ພາກວິຊາ";
            }
            // let arr = allData?.assessmentYear
            // if (arr.indexOf(values.assessmentYear) !== -1) {
            //   errors.assessmentYear = 'ສົກສຶກສານີ້ມີຢູ່ແລ້ວ'
            // }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            let aa = allData?.status;
            if (aa === "ENABLE" && !checkStatus) {
              aa = "DISABLE";
            } else if ((aa = "ENABLE" && checkStatus)) {
              aa = "ENABLE";
            } else if ((aa = "DISABLE" && checkStatus)) {
              aa = "DISABLE";
            } else {
              aa = "DISABLE";
            }
            let arr = allData?.assessmentYear;
            let newArr = [];
            if (values.assessmentYear === arr) {
              newArr.push(...arr);
            } else {
              // check year is exist
              let checkAssessmentYear = arr.filter(
                (item, index) => item == values.assessmentYear
              );
              if (checkAssessmentYear.length >= 1) {
                newArr.push(...arr);
              } else {
                newArr.push(...arr, values.assessmentYear);
              }
            }
            let data = {
              name: values.name,
              description: values.description,
              enName: values.enName,
              enDescription: values.enDescription,
              assessmentYear: newArr,
              note: values.note,
            };
            _saveUpdateDepartment(data);
          }}
          enableReinitialize
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Modal
              show={showSearchView}
              onHide={_handleSearchViewClose}
              size="lg"
            >
              <Modal.Title
                style={{ textAlign: "center", paddingTop: 20, fontWeight: 700 }}
              >
                ແກ້ໄຂພາກວິຊາ
              </Modal.Title>
              <Modal.Body
                style={{ marginLeft: 50, marginRight: 50, padding: 50 }}
              >
                <div className="model-contents">
                  <label>ຊື່ພາກວິຊາ(ລາວ) {valiDate()}:</label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    placeholder="ກະລຸນາປ້ອນຊື່ພາກວິຊາ(ລາວ)"
                  />
                  <label>ຕົວຫຍໍ້(ລາວ)</label>
                  <Form.Control
                    type="text"
                    name="description"
                    onChange={handleChange}
                    value={values.description}
                    placeholder="ກະລຸນາປ້ອນຕົວຫຍໍ້(ຖ້າມີ)"
                  />
                  <label>ຊື່ພາກວິຊາ(ອັງກິດ):</label>
                  <Form.Control
                    type="text"
                    name="enName"
                    onChange={handleChange}
                    value={values.enName}
                    placeholder="ກະລຸນາປ້ອນຊື່ພາກວິຊາ(ອັງກິດ)"
                  />
                  <label>ຕົວຫຍໍ້(ອັງກິດ):</label>
                  <Form.Control
                    type="text"
                    name="enDescription"
                    onChange={handleChange}
                    value={values.enDescription}
                    placeholder="ກະລຸນາປ້ອນຕົວຫຍໍ້ຂອງພາກວິຊານີ້(ອັງກິດ)"
                  ></Form.Control>
                  <label>ສົກສຶກສາ {valiDate()}:</label>
                  <Form.Control
                    as="select"
                    name="assessmentYear"
                    value={values.assessmentYear}
                    className="form-select"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    // isInvalid={!!errors.assessmentYear}
                  >
                    <option disabled={true} value="">
                      ---ກະລຸນາເລືອກສົກສຶກສາ---
                    </option>
                    {assessmentYear.map((x, assesIndex) => {
                      return (
                        <option key={assesIndex} value={x}>
                          {x}
                        </option>
                      );
                    })}
                  </Form.Control>
                  {/* <Form.Control.Feedback type="invalid">
                    {errors.assessmentYear}
                  </Form.Control.Feedback> */}
                  <label>ໝາຍເຫດ</label>
                  <textarea
                    type="text"
                    className="form-control"
                    name="note"
                    onChange={handleChange}
                    value={values.note}
                    placeholder="ກູລຸນາປ້ອນໝາຍເຫດຖ້າມີ"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <CustomButton
                  title="ຍົກເລີກ"
                  onClick={_handleSearchViewClose}
                />
                <CustomButton onClick={handleSubmit} title="ບັນທຶກ" confirm />
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditDepartment;
