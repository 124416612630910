import React from "react";
import ReactPaginate from "react-paginate";

const PaginationDashboard = ({handlePageClick, total, limit, skip}) => {
    return (
        <ReactPaginate
            previousLabel="ກັບຄືນ"
            onPageChange={handlePageClick}
            breakLabel="..."
            pageRangeDisplayed={3}
            pageCount={Math.ceil(total / limit)}
            marginPagesDisplayed={1}
            renderOnZeroPageCount={null}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            forcePage={skip - 1}
            nextLabel="ຕໍ່ໄປ"
        />
    );
};
export default PaginationDashboard;
