import React from "react";
import { Modal } from "react-bootstrap";

const ImageZoom = ({ showImageView, _handleImageViewClose, imageData }) => {
  return (
    <Modal
      show={showImageView}
      onHide={_handleImageViewClose}
      size="lg"
      style={{ zIndex: 10000 }}
      centered
    >
      {/* <Modal.Title style={{textAlign: 'center', paddingTop: 20}}>
        ຮູບພາບ
      </Modal.Title> */}

      {/* Body */}
      <div
        style={{ flex: 1, position: "relative", backgroundColor: "#f0f0f1" }}
      >
        <Modal.Body
          style={{
            padding: "5px",
          }}
        >
          <img
            alt=""
            src={imageData}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ImageZoom;
