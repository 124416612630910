import { gql } from "apollo-boost";

export const CODE = gql`
query Code($where: LoginInput! ){
    code(where: $where){
      id
      code
      firstNameL
      lastNameL
      firstNameE
      lastNameE
      faculty{
        id
        name
      }
      department{
        id
        name
      }
      gender
      birthday
      phoneMobile
      contactName
      educationLevel
      emergencyPhone
      studentType
    }
  }
`;

export const CODES = gql`
query Codes(
  $where: CustomCodeWhereInput
  $skip: Int
  $limit: Int
){
    codes(
      where: $where
      skip: $skip
      limit: $limit
    ){
      total
      data {
        id
        code
        firstNameL
        lastNameL
        firstNameE
        lastNameE
        faculty{
          id
          name
        }
        department{
          id
          name
        }
        major{
          id
          name
        }
        gender
        birthday
        phoneMobile
        contactName
        educationLevel
        emergencyPhone
        studentType
      }
    }
  }
`;

export const CREATE_PRERE_CODE = gql`
  mutation CreatePreregistrationCode($data: PreregistrationCodeCreateInput) {
    createPreregistrationCode(data: $data) {
      id
    }
  }
`;

export const UPDATE_PRERE_CODE = gql`
  mutation UpdatePreregistrationCode(
    $data: PreregistrationCodeUpdateInput
    $where: PreregistrationCodeWhereUniqueInput!
  ) {
    updatePreregistrationCode(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_PRERE_CODE = gql`
  mutation DeletePreregistrationCode($where: PreregistrationCodeWhereUniqueInput!) {
    deletePreregistrationCode(where: $where) {
      id
    }
  }
`;