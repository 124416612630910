import React, { useState, useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Breadcrumb} from 'react-bootstrap'

import Consts from '../../consts'
import {
  CustomContainer,
  CustomButton,
  TableHeader,
  TableCell,
  Title
} from '../../common'
import Loading from "../../common/Loading";
import { useLazyQuery } from '@apollo/react-hooks'

import { REGISTRATIONS } from '../../apollo/registration'
import { FACULTIES } from '../../apollo/faculty'
import FacultyAdd from './FacultyAdd'
// import FacultySearch from './FacultySearch'
import FacultyEdit from './FacultyEdit'


function FacultyList() {
  const { history} = useReactRouter()

  // Query faculties
  const [
    loadRegistrations,
    {
      data: registrationData
    }
  ] = useLazyQuery(REGISTRATIONS)

  // Query faculties
  const [
    loadFaculties,
    { loading: facultyLoading }
  ] = useLazyQuery(FACULTIES)

  // States
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditView, setShowEditView] = useState(false)
  const [dataEdit, setDataEdit] = useState({})

  // on first load
  useEffect(() => {
    loadRegistrations()
    loadFaculties({
      variables:{
        where: {isDeleted: false}
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const _handleShowAddModalClose = () => setShowAddModal(false)
  const _handleShowAddModalShow = () => setShowAddModal(true)

  const _handleEditViewClose = () => setShowEditView(false)
  const _handleEditViewShow = () => setShowEditView(true)

  const _studentDetail = event => {
    history.push('/registration-detail', event)
  }
  const _edit = async (data) => {
    await setDataEdit(data)
    _handleEditViewShow()
  }

  if (facultyLoading) return <Loading />

  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item href='' onClick={() => history.push('/faculty-list')}>
          ຄະນະ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ຄະນະທັງໝົດ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <Title text={'ຄະນະທັງໝົດ '} />
        <div style={{ textAlign: 'right' }}>
          <CustomButton
            confirm
            addIcon
            title='ເພີ່ມຄະນະ'
            onClick={() => {
              _handleShowAddModalShow()
            }}
          />
        </div>

        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY
          }}
        >
          ທັງໝົດ{' '}
          {registrationData &&
            registrationData.registrations &&
            registrationData.registrations.length}{' '}
          ລາຍການ
        </div>

        {/* Table list */}
        <div>
          <table border='1' bordercolor='#fff' style={{ width: '100%' }}>
            <thead>
              <TableHeader>
                <th>ລຳດັບ</th>
                <th>ລະຫັດນັກຮຽນ</th>
                <th>ຊື່ນັກຮຽນ</th>
                <th>ລະຫັດວິຊາ</th>
                <th>ຊື່ວິຊາ</th>
                <th>ວັນທີລົງທະບຽນ</th>
                <th style={{ width: 180 }}>ຈັດການ</th>
              </TableHeader>
            </thead>
            <tbody>
              {registrationData &&
                registrationData.registrations &&
                registrationData.registrations.map((x, index) => {
                  return (
                    <tr
                      style={{
                        borderBottom: '2px solid #ffff',
                        textAlign: 'center'
                      }}
                      key={index}
                    >
                      <TableCell>
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {x.student && x.student.userId}
                      </TableCell>
                      <TableCell>
                        {x.student && x.student.firstname}  {x.student && x.student.lastname}
                      </TableCell>
                      <TableCell>
                        {x.course && x.course ? (x.course.courseCode ? x.course.courseCode : '') : 'ວິຊາຖືກລົບແລ້ວ'}
                      </TableCell>
                      <TableCell>
                        {x.course && x.course ? (x.course.title ? x.course.title : '') : 'ວິຊາຖືກລົບແລ້ວ'}
                      </TableCell>
                      <TableCell>
                        {x.createdAt && new Date(x.createdAt).toLocaleString('la-LA', { hour12: false })}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around'
                          }}
                        >
                          <div
                            onClick={() => _edit(x)}
                            style={{ cursor: 'pointer', backgroundColor: '#FFFFFF', padding: 3, width: 64, borderRadius: 4 }}
                          >
                            <FontAwesomeIcon
                              icon={['fas', 'edit']}
                              color={Consts.BORDER_COLOR}
                            />{' '}
                          </div>
                          <div
                            onClick={() => _studentDetail(x)}
                            style={{ cursor: 'pointer', backgroundColor: '#FFFFFF', padding: 3, width: 64, borderRadius: 4 }}
                          >
                            <FontAwesomeIcon
                              icon={['fas', 'eye']}
                              color={Consts.BORDER_COLOR}
                            />{' '}
                          </div>
                        </div>
                      </TableCell>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </CustomContainer>

      {/* ------- Add Modal ------ */}
      <FacultyAdd
        showAddModal={showAddModal}
        _handleShowAddModalClose={_handleShowAddModalClose}
      />

      {/* ------- Edit Modal ------ */}
      <FacultyEdit
        showEditView={showEditView}
        _handleEditViewClose={_handleEditViewClose}
        facultyData={dataEdit}
      />
    </div>
  )
}

export default FacultyList
