import React from "react";
import useReactRouter from "use-react-router";
import Nav from "react-bootstrap/Nav";
import {  NAV } from "../../../consts/index";


export default function UserNav({ data }) {
  const {match,history } = useReactRouter();
  console.log("data::", data)
  const url = match.url;
  const defaultURL = url.split("/")[1];

  return (
    <div>
      <Nav variant="tabs" style={{marginLeft: 10, marginRight: 10}}>
        <Nav.Item>
          <Nav.Link
            onClick={() => history.push(`/grade-sending-detail`, data) }
            active={defaultURL === "grade-sending-detail" ? true : false}
          >
            ລາຍລະອຽດວິຊາ
          </Nav.Link>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            onClick={() => history.push(`/grade-sending-course-grade`, data)
            }
            active={defaultURL === "grade-sending-course-grade" ? true : false}
          >
            ຄະແນນ
          </Nav.Link>
        </Nav.Item>

        {/* <Nav.Item>
          <Nav.Link
            onClick={() =>
              goToRoute(`/send-grades-detail/${ pageNambers }`, data)
            }
            active={defaultURL === "send-grades-detail" ? true : false}
          >
            ປະຫວັດການຢືນຢັນ
          </Nav.Link>
        </Nav.Item> */}

        {/* <Nav.Item>
          <Nav.Link
            onClick={() =>
              history.push(`/grades-update-history/${ pageNambers }`, data)
            }
            active={defaultURL === "grades-update-history" ? true : false}
          >
            ປະຫວັດການແກ້ໄຂ
          </Nav.Link>
        </Nav.Item> */}
      </Nav>
    </div>
  );
}
