import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */

/**
 *
 * @Component
 *
 */
import { Breadcrumb, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./notice.css";
import DeleteModal from "./DeleteModal";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { CustomContainer } from "../../common";
import ListAccessableFaculty from "./ListAccessableFaculty";
import ImageZoom from "./ImageZoom";
/**
 *
 * @Constant
 *
 */
// import Consts from "../../consts";

/**
 *
 * @Apollo
 *
 */
import { NOTIC, NOTICS } from "../../apollo/notic";

/**
 *
 * @Function
 *
 */

import {
  formatDateTime,
  formatDate,
  noticeStatus,
  setSwich,
} from "../../common/super";
import { PAGE_GINATION } from "../../consts/router";

export default function DetailNotice() {
  const { history, match } = useReactRouter();
  let noticTypes = match?.params?.type;
  /**
   *
   * @State
   *
   */
  const [showDelete, setShowDelete] = useState(false);
  const [noticDatas, setNoticDatas] = useState();
  const [noticId, setNoticId] = useState(match?.params?.id);
  const [noticType, setNoticType] = useState(noticTypes);
  const [showListFile, setShowListFile] = useState(false);
  const [imageData, setImageData] = useState("");
  const [showImageView, setImageView] = useState(false);
  /**
   *
   * @Apollo
   *
   */
  // graphql
  const { data: noticData } = useQuery(NOTIC, {
    variables: {
      where: {
        id: noticId,
      },
    },
  });
  const [loadSubNotice,{ data: subNotice }] = useLazyQuery(NOTICS,{ fetchPolicy: "network-only" });
  /**
   *
   * @UseEffect
   *
   */
  useEffect(() => {
    loadSubNotice({
        variables: {
          where: {type: noticType },
          orderBy: "createdAt_DESC",
          first: 5,
        },
    })
  },[]);

  useEffect(() => {
    if (noticData) {
      setNoticDatas(noticData?.notic);
    }
   
  },[noticData]);
  /**
   *
   * @Function
   *
   */
  const _handleImageViewClose = () => setImageView(false);
  const _handleImageViewShow = () => setImageView(true);
  const _showImage = (value) => {
    _handleImageViewShow();
    setImageData(value);
  };

  const _handleDeleteShow = (e) => {
    setShowDelete(true);
  };
  const _handleDeleteClose = () => {
    setShowDelete(false);
  };

  const _showListFiles = (noticDatas) => {
    setShowListFile(true);
  };

  const closeList = () => {
    setShowListFile(false);
  };
  const _return = () => {
    history.push("/notice-list"+PAGE_GINATION);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item onClick={_return}>
          ການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ລາຍລະອຽດ {noticDatas?.title}</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <div style={{ display: "flex", justifyContent: "space-between" }}></div>
        <div className="content-top">
          <h3 className="title">ລາຍລະອຽດ</h3>
          <div className="row">
            <div className="col-md-3">
              <img
                alt=""
                src={
                  noticDatas?.coverImage?.url ||
                  "https://www.cakemandu.com.np/public/uploads/items/thumbs/default.png"
                }
                onClick={() => {
                  _showImage(
                    noticDatas?.coverImage?.url ||
                      "https://www.cakemandu.com.np/public/uploads/items/thumbs/default.png"
                  );
                }}
                className="box-view-image"
              />
            </div>
            <div className="col-md-5" style={{ padding: 10 }}>
              <div className="row">
                <div className="col-md-3">
                  <h4>
                    <b>ຫົວຂໍ້: </b>
                  </h4>
                  <p>
                    <b>ປະເພດ:</b>{" "}
                  </p>
                  <p>
                    <b>ຄະນະ:</b>{" "}
                  </p>
                  <p>
                    <b>ມື້ເປິດ-ປິດ: </b>
                  </p>
                  <p>
                    <b style={{ marginTop: 5 }}>ສະຖານະ: </b>
                  </p>

                  <p>
                    <b>ສ້າງ: </b>
                  </p>
                </div>
                <div className="col-md-8">
                  <h4 className="wrap-text">{noticDatas?.title} </h4>
                  <p>{noticeStatus(noticDatas?.type)}</p>
                  <p>
                    <div
                      onClick={() =>
                        noticDatas?.accessableFaculty?.length > 0
                          ? _showListFiles()
                          : ""
                      }
                      className={
                        noticDatas?.accessableFaculty.length > 0
                          ? "bage-full"
                          : "bage-empty"
                      }
                    >
                      {noticDatas?.accessableFaculty.length > 0
                        ? noticDatas?.accessableFaculty.length + " ຄະນະ "
                        : "ທຸກຄະນະ"}{" "}
                    </div>
                  </p>
                  <p>
                    {formatDate(noticDatas?.startDate)} ~{" "}
                    {formatDate(noticDatas?.endDate)}
                  </p>
                  <p>{setSwich(noticDatas?.isPublish)}</p>
                  <p>
                    {formatDateTime(noticDatas?.createdAt)} ( ໂດຍ:{" "}
                    {noticDatas?.createdBy?.firstname} )
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4" style={{ textAlign: "left" }}>
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">ແກ້ໄຂຂໍ້ມູນ</Tooltip>}
              >
                <Button
                  className="btn-action"
                  onClick={() => {
                    history.push(`/notice-edit/${noticId}`, noticDatas);
                  }}
                  variant="outline-success"
                >
                  <i className="fa fa-edit" /> ແກ້ໄຂ
                </Button>
              </OverlayTrigger>
              {/* ລຶບ */}
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">ລົບຂໍ້ມູນ</Tooltip>}
              >
                <Button
                  className="btn-action"
                  variant="outline-danger"
                  onClick={() => _handleDeleteShow(noticDatas?.id)}
                >
                  <i className="fa fa-trash" /> ລົບ
                </Button>
                {/* {" "} */}
              </OverlayTrigger>

              <div
                style={{
                  display: "block",
                  marginTop: 30,
                }}
              >
                <p>
                  <b>ໄຟລ໌ເອກະສານ</b> ( {noticDatas?.files?.length || 0} )
                </p>
                <div
                  style={{
                    overflowY: "scroll",
                    maxHeight: 250,
                  }}
                >
                  {noticDatas?.files.map((item, index) => {

                    console.log("item")
                    console.log(item)
                    return (
                      <ListItem button key={index}>
                        <ListItemIcon>
                          <i className="fa fa-file"></i>
                        </ListItemIcon>
                        <div
                          className="listFile"
                          style={{ width: "80%", marginRight: 10 }}
                        >
                          <ListItemText
                            className="wrap-text"
                            style={{ color: "rgb(5, 124, 174)" }}
                            primary={item?.title}
                          />
                        </div>
                        <ListItemIcon style={{ width: "20%" }}>
                          <a
                            href={item?.file}
                            style={{ color: "rgb(5, 124, 174)" }}
                          >
                            <i className="fa fa-download"></i>
                          </a>
                        </ListItemIcon>
                      </ListItem>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ padding: 10 }}>
          <div className="col-md-8">
            <div className="tab-title">
              <h4>
                <b>ເນື້ອໃນ</b>
              </h4>
            </div>
            <div
              style={{ textAlign: "justify", textIndent: 50 }}
              dangerouslySetInnerHTML={{ __html: noticDatas?.content }}
            ></div>
          </div>
          <div
            className="col-md-4"
            style={{ borderLeft: "solid 0.1px #f1f1f1" }}
          >
            <div
              style={{
                padding: 10,
                marginBottom: 15,
              }}
            >
              <h4
                style={{
                  color: "rgb(5, 124, 174)",
                  fontWeight: "bold",
                }}
              >
                <b>ຫົວຂໍ້ທີ່ກ່ຽວຂ້ອງ</b>
              </h4>
            </div>
            <div style={{ overflowY: "scroll", maxHeight: 500 }}>
              {subNotice?.notics?.data?.map((item, index) => {
                return (
                  <div
                    className="list-item"
                    key={index}
                    onClick={() => {
                      setNoticId(item?.id);
                      setNoticType(item?.type);
                    }}
                  >
                    <table border="0" style={{ width: "100%", border: "none" }}>
                      <thead>
                        <tr>
                          <th style={{ width: 100, border: "none" }}>
                            <img
                              src={
                                item?.coverImage?.url ||
                                "https://www.cakemandu.com.np/public/uploads/items/thumbs/default.png"
                              }
                              alt=""
                              className="box-image"
                            />
                          </th>
                          <th
                            style={{
                              border: "none",
                              width: "25%",
                              paddingLeft: 20,
                            }}
                          >
                            <p>ຫົວຂໍ້: </p>
                            <p>ສ້າງ: </p>
                          </th>
                          <td style={{ width: "80%", border: "none" }}>
                            <p className="wrap-text"> {item?.title || "-"}</p>
                            <p className="wrap-text">
                              {" "}
                              {formatDateTime(item?.createdAt) || "-"}
                            </p>
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </CustomContainer>
      <DeleteModal
        data={noticDatas}
        showDelete={showDelete}
        _handleDeleteClose={_handleDeleteClose}
      />
      <ListAccessableFaculty
        data={noticDatas}
        showList={showListFile}
        closeList={closeList}
      />
      <ImageZoom
        showImageView={showImageView}
        _handleImageViewClose={_handleImageViewClose}
        imageData={imageData}
      />
    </div>
  );
}
