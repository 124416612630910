import React from "react";
import useReactRouter from "use-react-router";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import GradeIcon from "@mui/icons-material/Grade";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RestoreIcon from "@mui/icons-material/Restore";
import { styled } from "@mui/system";
import Routes from "../../consts/router";
// import { rgb } from "chroma-js";

const StyledPaper = styled(Paper)({
  padding: "20px",
  cursor: "pointer",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.05)",
    backgroundColor: "#f8f9fa",
  },
});

const IconWrapper = styled("div")({
  marginRight: "20px",
  color: "#057CAE",
});

const ListOfGrades = () => {
  const { history } = useReactRouter();

  const handleGradeClick = () => {
    history.push(Routes.GRADE_SENDING_COURSE_LIST + Routes.PAGE_GINATION);
  };

  const handleViewClick = () => {
    history.push("/grades-list/limit/20/skip/1");
  };

  const handleUpgradeClick = () => {
    history.push("/grades-upgrade-list/1");
  };

  return (
    <Container
      sx={{
        padding: 0,
        margin: 0,
        width: "100%",
      }}
    >
      <Grid className="pt-5" container spacing={2}>
        <Grid item xs={12} md={4}>
          <StyledPaper onClick={handleGradeClick} tabIndex="0">
            <div className="row">
              <div className="col-2">
                <i
                  style={{
                    // color: rgb(5, 124, 174),
                    width: "35px",
                    height: "35px",
                  }}
                  class="fa-solid fa-star"
                ></i>
              </div>
              <div className="col-10 mt-1">
                <Typography variant="h6">ການໃຫ້ ແລະ ສົ່ງຄະແນນ</Typography>
              </div>
            </div>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledPaper onClick={handleViewClick} tabIndex="0">
            <div className="row">
              <div className="col-2">
                <i
                  style={{
                    // color: rgb(5, 124, 174),
                    width: "35px",
                    height: "35px",
                  }}
                  class="fa-sharp fa-solid fa-eye"
                ></i>
              </div>
              <div className="col-10 mt-1">
                <Typography variant="h6">ເບີ່ງຄະແນນ</Typography>
              </div>
            </div>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledPaper onClick={handleUpgradeClick} tabIndex="0">
            <div className="row">
              <div className="col-2">
                <i
                  style={{
                    // color: rgb(5, 124, 174),
                    width: "35px",
                    height: "35px",
                  }}
                  class="fa-solid fa-list-ol"
                ></i>
              </div>
              <div className="col-10 mt-1">
                <Typography variant="h6">ຄະແນນເທີມ 3</Typography>
              </div>
            </div>
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ListOfGrades;
