import axios from "axios";
import { NEW_API_URL, API_KEY } from "../../common/contrant";
import { USER_KEY } from "../../consts";
const user = localStorage.getItem(USER_KEY);
const userObject = JSON.parse(user);
const token = userObject?.accessToken;

export const getAllStudentRegister = async (
  filter,
  page,
  rowsPerPage,
  status,
  date
) => {
  try {
    const response = await axios.get(
      NEW_API_URL +
        `prerestudents?assessmentYear=${filter?.assessmentYear}&title=${filter?.title}&faculty=${filter?.faculty}&department=${filter?.department}&major=${filter?.major}&page=${page}&limit=${rowsPerPage}&status=${status}&date=${filter?.date}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          api_key: API_KEY,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.log("error get student");
    console.log(err);
    return [];
  }
};
