import React, { useEffect } from "react";
import UpgradeNav from "../common/UpgradeNav";
import moment from "moment";

import { CustomContainer, TableHeader } from "../../../common";
import "./style.css";

import { useLazyQuery } from "@apollo/react-hooks";
import { GRADEIMPROVESHISTORY } from "../../../apollo/upgradestudenthistory";
import { GRADES } from "../../../apollo/registrations";

export default function StudenRegisterUpgrade() {

  const [loadGradeImproves, { data: gradeImprovesData }] =
    useLazyQuery(GRADEIMPROVESHISTORY);

  // Query Grade
  const [loadGrade, { data: gradeData }] = useLazyQuery(GRADES);

  useEffect(() => {
    loadGradeImproves();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    loadGrade();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradeData]);

  return (
    <div>
      <UpgradeNav />
      <CustomContainer>
        <div
          style={{
            color: "#057CAE",
            fontSize: 32,
            fontWeight: "bold",
            padding: 30,
          }}
        >
          ລາຍການຄະແນນທັງໝົດ
        </div>
        <table className="table table-striped">
          <thead>
            <TableHeader>
              <th style={{ width: 60 }}>ລຳດັບ</th>
              <th>ລະຫັດນັກສຶກສາ</th>
              <th>ຊື່ນັກສຶກສາ</th>
              <th>ນັກສຶກສາປີ</th>
              <th style={{ width: 80 }}>ພາກຮຽນ</th>
              <th>ສົກຮຽນ</th>
              <th>ຊື່ວິຊາທີ່ແກ້ເກຣດ</th>
              <th>ຊື່ອາຈານປະຈຳວິຊາ</th>
              <th>ເກຣດເກົ່າ</th>
              <th>ວັນທີກົດຮັບ</th>
            </TableHeader>
          </thead>
          <tbody>
            {gradeImprovesData?.gradeImproves?.data?.map(
              (dataGradeImprove, index) => {
                return (
                  <tr>
                    <td className="center"> {index + 1}</td>
                    <td>{dataGradeImprove?.student?.studentNumber}</td>
                    <td>
                      {dataGradeImprove?.student?.firstNameL}{" "}
                      {dataGradeImprove?.student?.lastNameL}
                    </td>
                    <td className="center">
                      {dataGradeImprove?.student?.yearLevel}
                    </td>
                    <td className="center">
                      {dataGradeImprove?.course?.semester}
                    </td>
                    <td className="center">
                      {dataGradeImprove?.assessmentYear}
                    </td>
                    <td>{dataGradeImprove?.course?.title}</td>
                    <td>{dataGradeImprove?.teacher?.firstname}</td>
                    <td>{dataGradeImprove?.newGrade?.letterGrade}</td>
                    <td>{moment(dataGradeImprove?.createdAt).format("L")}</td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>

        {/* <Pagination
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 20,
                    }}
                >
                    <Pagination.Prev onClick={_onPrevius} />
                    {countPage?.slice(0, 5).map((Item, index) => {
                        return (
                            <Pagination.Item
                                key={"page" + index}
                                active={Item == pageNambers}
                                onClick={() => {
                                    return history.push(`/send-grades/${index + 1}`);
                                }}
                            >
                                {Item}
                            </Pagination.Item>
                        );
                    })}
                    {countPage && countPage?.length > 3 ? (
                        <Pagination.Item style={{ pointerEvents: "none" }}>
                            {"..."}
                        </Pagination.Item>
                    ) : (
                            " "
                        )}
                    {countPage && countPage?.length > 3 ? (
                        <Pagination.Item
                            onClick={() => history.push(`/send-grades/${countPage?.length}`)}
                        >
                            {countPage?.length}
                        </Pagination.Item>
                    ) : (
                            " "
                        )}
                    <Pagination.Next onClick={_onNext} />
                </Pagination> */}
      </CustomContainer>
    </div>
  );
}
