import React, { useState, useEffect } from "react";
/**
 *
 * Ribrary
 *
 */
import useReactRouter from "use-react-router";
import { useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from 'react-redux'
/**
 *
 * @Component
 *
 */
import { Breadcrumb } from "react-bootstrap";
import { Bar, Pie } from "react-chartjs-2";
import { Form, Spinner } from "react-bootstrap";
import { TableHeader, TableCell } from "../../common";
import LoadingFullScreen from "../../common/LoadingFullScreen";
/**
 *
 * @Constants
 *
 */
import Consts from "../../consts/index";
import Routers from "../../consts/router";
/**
 *
 * @Apollo
 *
 */
import { DASHBOARD_DATA, DASHBOARD_STUDENTS, DASHBOARD_TEACHER, DASHBOARD_FACULTIES } from "./apollo/query";
import { PREREGISTRATION_TRIBE } from "../preregistrationStudent/apollo/query";
/**
 *
 * css
 *
 */
import "./index.css";
import { convertGender } from "../../helpers/user";
/**
 *
 * Fucntion
 *
 */

import { currency } from "../../common/super";
import _ from "lodash";
import PaginationDashboard from "../../helpers/PaginationDashboard";
export default function Dashboard() {
  const _limit = 10
  const accessmentRedux = useSelector((state) => state.accessment.value)
  const { history } = useReactRouter();
  
  const [studentTotal, setStudentTotal] = useState(0)
  const [teacherCount, setTeacherCount] = useState({})
  const [preregistrationStudentCount, setPreregistrationStudentCount] = useState({})
  const [courseTotal, setCourseTotal] = useState(0)
  const [fileTotal, setFileTotal] = useState(0)
  const [studentGenderCount, setStudentGenderCount] = useState([])
  const [genderArray, setGenderArray] = useState([])
  const [amountArray, setAmountArray] = useState([])
  const [facultyArray, setFacultyArray] = useState([])

  const [dataStudentsInFaculty, setDataStudentsInFaculty] = useState([])
  const [studentsInFacultyTotal, setStudentsInFacultyTotal] = useState(0)
  const [studentsInFacultyFemale, setStudentsInFacultyFemale] = useState(0)
  
  const [dataTeacherInFaculty, setDataTeacherInFaculty] = useState([])
  const [teacherInFacultyTotal, setTeacherInFacultyTotal] = useState(0)
  const [teacherInFacultyMale, setTeacherInFacultyMale] = useState(0)
  const [teacherInFacultyFemale, setTeacherInFacultyFemale] = useState(0)

  const [gradeInFaculties, setGradeInFaculties] = useState([])
  const [studentInFaculties, setStudentInFaculties] = useState([])
  const [teacherInFaculties, setTeacherInFaculties] = useState([])

  const [dataPrereStudentTribes, setDataPrereStudentTribes] = useState([])
  const [prereStudentTribesTotal, setPrereStudentTribesTotal] = useState([])

  const [studentPageSkip, setStudentPageSkip] = useState(1)
  const [teacherPageSkip, setTeacherPageSkip] = useState(1)

  const [studentFacultySelectedId, setStudentFacultySelectedId] = useState("")
  const [teacherFacultySelectedId, setTeacherFacultySelectedId] = useState("")

  const [loadDashboardData, {data: dashboardData, loading: dashboardDataLoading}] = useLazyQuery(DASHBOARD_DATA, {
    fetchPolicy: "cache-and-network"
  });
  const [loadStudentData, { data: studentData, loading: studentDataLoading }] = useLazyQuery(DASHBOARD_STUDENTS, {
    fetchPolicy: "network-only"
  })
  const [loadTeacherData, { data: teacherData, loading: teacherDataLOading }] = useLazyQuery(DASHBOARD_TEACHER, {
    fetchPolicy: "network-only"
  })
  const [loadGradeInFaculty, { data: gradeInFacultyData }] = useLazyQuery(DASHBOARD_FACULTIES)
  const [loadStudentInFaculty, { data: studentInFacultyData }] = useLazyQuery(DASHBOARD_FACULTIES)
  const [loadTeacherInFaculty, { data: teacherInFacultyData }] = useLazyQuery(DASHBOARD_FACULTIES)
  const [loadPreregistrationTribe, { data: preregistrationTribeData }] = useLazyQuery(PREREGISTRATION_TRIBE)

  const data = {
    labels: facultyArray,
    datasets: [
      {
        label: "A",
        data: [54, 45, 40, 5, 10, 12, 20, 60, 40, 45, 69, 67, 50, 58],
        backgroundColor: "rgba(4, 104, 153, 1)",
      },
      {
        label: "B",
        data: [50, 40, 40, 20, 10, 12, 50, 45, 60, 65, 55, 64, 53, 68],
        backgroundColor: "rgba(13, 149, 188, 1)",
      },
      {
        label: "C",
        data: [40, 3, 23, 21, 15, 12, 20, 70, 43, 25, 39, 70, 50, 58],
        backgroundColor: "rgba(162, 185, 105, 1)",
      },
      {
        label: "D",
        data: [55, 10, 13, 15, 10, 12, 20, 12, 10, 5, 9, 13, 15, 18],
        backgroundColor: "rgba(235, 203, 56, 1)",
      },
      {
        label: "F",
        data: [40, 10, 17, 15, 9, 23, 26, 20, 10, 2, 10, 16, 11],
        backgroundColor: "rgba(255, 35, 102, 1)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          beginAtZero: true,
        },
      },
      x: {
        stacked: true,
      },
    },
    legend: {
      display: true,
      position: "bottom",
    },
  };
  const teacherPie = {
    datasets: [
      {
        label: "# of Votes",
        data: [teacherInFacultyMale, teacherInFacultyFemale],
        backgroundColor: ["#046899", "#7BB500"],
        borderColor: ["#046899", "#7BB500"],
        borderWidth: 1,
      },
    ],
    labels: ["ຊາຍ", "ຍິງ"],
  };
  const studentPie = {
    datasets: [
      {
        label: "# of Votes",
        data: amountArray,
        backgroundColor: ["#046899", "#2e7d32", "#ef9b0f", "#ffdf00", "#808080"],
        borderColor: ["#046899", "#2e7d32", "#ef9b0f", "#ffdf00", "#808080"],
        borderWidth: 1,
      },
    ],
    labels: genderArray,
  };
  
  useEffect(() => {
    let qlWhere = { assessmentYear: accessmentRedux }
    let allPrere = { ...qlWhere, statusStep_in: ["ADMIN_APPROVED", "APPROVED", "REQUEST", "REJECTED", "SEND_FORM"] }
    let allCourse = { ...qlWhere, isDeleted: false }
    let qlTeacher = { isDeleted: false, role_not_in: ["STUDENT"] }
    loadDashboardData({
      variables: {
        studentWhere: allCourse,
        courseWhere: allCourse,
        prereStudentWhere: allPrere,
        userWhere: qlTeacher,
        fileWhere: { isDeleted: false, type: "PUBLIC_FILE" },
        whereCountStudentGender: qlWhere,
      }
    })

    loadPreregistrationTribe({
      variables: {
        where: allPrere
      }
    })
    loadStudentData({
      variables: {
        where: allCourse,
        skip: (studentPageSkip - 1) * _limit,
        first: _limit,
      }
    })
    loadTeacherData({
      variables: {
        where: qlTeacher,
        skip: (studentPageSkip - 1) * _limit,
        first: _limit,
      }
    })
    loadGradeInFaculty({
      variables: {
        where: allCourse
      }
    })
    loadStudentInFaculty({
      variables: {
        where: allCourse
      }
    })
    loadTeacherInFaculty({
      variables: {
        where: allCourse
      }
    })
  }, [accessmentRedux])

  useEffect(() => {
    if(dashboardData) {
      setStudentTotal(dashboardData?.students?.total)
      setPreregistrationStudentCount(dashboardData?.preregistrationStudentStatistics)
      setTeacherCount(dashboardData?.user_page)
      setFileTotal(dashboardData?.files?.total)
      setCourseTotal(dashboardData?.course_page?.total)
      setStudentGenderCount(dashboardData?.countStudentGender)

      let genderArr = [];
      let amountArr = [];
      for(let i = 0;i < dashboardData?.countStudentGender?.length; i++){
        genderArr.push(convertGender(dashboardData?.countStudentGender[i]?.gender))
        amountArr.push(dashboardData?.countStudentGender[i]?.amount)
      }
      setGenderArray(genderArr)
      setAmountArray(amountArr)
    }
  }, [dashboardData])

  useEffect(() => {
    if(studentData) {
      setDataStudentsInFaculty(studentData?.students?.data)
      setStudentsInFacultyTotal(studentData?.students?.total)
      setStudentsInFacultyFemale(studentData?.students?.totalFemale)
    }
  }, [studentData])

  useEffect(() => {
    if(teacherData) {
      setDataTeacherInFaculty(teacherData?.user_page?.data)
      setTeacherInFacultyTotal(teacherData?.user_page?.total)
      setTeacherInFacultyMale(teacherData?.user_page?.maleCountTeacher)
      setTeacherInFacultyFemale(teacherData?.user_page?.femaleCountTeacher)
    }
  }, [teacherData])

  useEffect(() => {
    if(preregistrationTribeData) {
      setDataPrereStudentTribes(preregistrationTribeData?.preregistrationStudentTribes?.data)
      setPrereStudentTribesTotal(preregistrationTribeData?.preregistrationStudentTribes?.total)
    }
  }, [preregistrationTribeData])

  useEffect(() => {
    if(gradeInFacultyData) {
      setGradeInFaculties(gradeInFacultyData?.faculties)
      let facultyArr = []
      for(let i = 0; i < gradeInFacultyData?.faculties?.length; i++) {
        facultyArr.push(gradeInFacultyData?.faculties[i]?.name)
      }
      setFacultyArray(facultyArr)
    }
  }, [gradeInFacultyData])

  useEffect(() => {
    if(studentInFacultyData) setStudentInFaculties(studentInFacultyData?.faculties)
  }, [studentInFacultyData])

  useEffect(() => {
    if(teacherInFacultyData) setTeacherInFaculties(teacherInFacultyData?.faculties)
  }, [teacherInFacultyData])

  useEffect(() => {
    let qlWhere = { assessmentYear: accessmentRedux, isDeleted: false }
    if(studentFacultySelectedId !== "") qlWhere = { ...qlWhere, faculty: studentFacultySelectedId }
    loadStudentData({
      variables: {
        where: qlWhere,
        skip: (studentPageSkip - 1) * _limit,
        first: _limit,
      }
    })
  }, [studentPageSkip, studentFacultySelectedId])

  useEffect(() => {
    let qlWhere = { isDeleted: false, role_not_in: ["STUDENT"] }
    if(teacherFacultySelectedId !== "") qlWhere = { ...qlWhere, faculty: teacherFacultySelectedId }
    loadTeacherData({
      variables: {
        where: qlWhere,
        skip: (teacherPageSkip - 1) * _limit,
        first: _limit,
      }
    })
  }, [teacherPageSkip, teacherFacultySelectedId])

  const handleStudentPageClick = (envent) => {
    let currentPage = envent.selected + 1;
    setStudentPageSkip(currentPage)
  };
  const handleTeacherPageClick = (envent) => {
    let currentPage = envent.selected + 1;
    setTeacherPageSkip(currentPage)
  };
  const handleStudentSelectedFaculty = (e) => {
    setStudentPageSkip(1)
    setStudentFacultySelectedId(e?.target?.value)
  }
  const handleTeacherSelectedFaculty = (e) => {
    setTeacherPageSkip(1)
    setTeacherFacultySelectedId(e?.target?.value)
  }
  return (
    <div style={{backgroundColor: "#fff"}}>
      { dashboardDataLoading && <LoadingFullScreen /> }
      <Breadcrumb>
        <Breadcrumb.Item>ພາບລວມ</Breadcrumb.Item>
      </Breadcrumb>
      <div className="body">
        <div className="container-custom">
          <div className="row-custom">
            <div className="card-detail" onClick={() => history.push(Routers.STUDENT_LIST+ Routers.PAGE_GINATION)}>
              <div className="row">
                <div className="col-md-7">
                  <h5 className="text-bold">ຈຳນວນ</h5>
                  <h5 className="text-bold">ນັກສຶກສາທັງໝົດ</h5>
                </div>
                <div className="col-md-5 center-card">
                  <i className="fa fa-chart-line" />
                </div>
                <h5
                  className="text-bold"
                  style={{ color: "#606060", margin: 0 }}
                >
                  {currency(studentTotal)} ຄົນ
                </h5>
              </div>
            </div>
            {/* --------- Student Aprove ---------- */}
            <div
              className="card-detail-not-teacher"
              onClick={() =>history.push(Routers.PRERE_STUDENT_LIST + Routers.PAGE_GINATION)}
            >
              <div className="row">
                <div className="col-md-8">
                  <h5 className="text-bold-not-teacher">
                    ລົງທະບຽນຮຽນທັງໝົດ <br />
                    Preregistration
                  </h5>
                </div>
                <div className="col-md-4 center-card-not-teacher">
                  <i className="fa fa-users" />
                </div>
                <h5
                  className="text-bold"
                  style={{ color: "#606060", margin: 0 }}
                >
                  {_.isEmpty(preregistrationStudentCount) ? "0 ຄົນ" : currency(preregistrationStudentCount?.total) + " ຄົນ" }
                </h5>
              </div>
            </div>
            <div
              className="card-detail-registration"
              onClick={() => history.push(Routers.PRERE_STUDENT_FAC_LIST + Routers.PAGE_GINATION)}
            >
              <div className="row">
                <div className="col-md-8">
                  <h5 className="text-bold-registration">
                    ລົງທະບຽນຮຽນສຳເລັດ <br />
                    Preregistration
                  </h5>
                </div>
                <div className="col-md-4 center-card-registration">
                  <i className="fa fa-users" />
                </div>
                <h5
                  className="text-bold"
                  style={{ color: "#606060", margin: 0 }}
                >
                  {_.isEmpty(preregistrationStudentCount) ? "0 ຄົນ" : currency(preregistrationStudentCount?.approvedCounts)  + " ຄົນ" }
                </h5>
              </div>
            </div>
            <div
              className="card-detail-not-registration"
              onClick={() =>history.push(Routers.PRERE_STUDENT_LIST + Routers.PAGE_GINATION)}
            >
              <div className="row">
                <div className="col-md-7">
                  <h5 className="text-bold-not-registration">
                    ລໍຖ້າລົງ​ທະ​ບຽນ <br/>
                    Preregistration
                  </h5>
                </div>
                <div className="col-md-5 center-card-not-registration">
                  <i className="fa fa-times-circle" />
                </div>
                <h5
                  className="text-bold"
                  style={{ color: "#606060", margin: 0 }}
                >
                  {_.isEmpty(preregistrationStudentCount) ?
                    "0 ຄົນ" :
                    currency(preregistrationStudentCount?.requestCounts) + " ຄົນ"
                  }
                </h5>
              </div>
            </div>
          </div>
          <div className="row-custom margin-top">
            {/* --------- Teacher ---------- */}
            <div 
              className="card-detail-not-teacher"
              onClick={() =>history.push(Routers.TEACHER_LIST + Routers.PAGE_GINATION)}
            >
              <div className="row">
                <div className="col-md-7">
                  <h5 className="text-bold-not-teacher">ຈຳນວນ</h5>
                  <h5 className="text-bold-not-teacher">ອາຈານທັງໝົດ</h5>
                </div>
                <div className="col-md-5 center-card-not-teacher">
                  <i className="fa fa-chalkboard-teacher" />
                </div>
                <h5
                  className="text-bold"
                  style={{ color: "#606060", margin: 0 }}
                >
                  {_.isEmpty(teacherCount) ? 0 : currency(teacherCount?.total)} ຄົນ
                </h5>
              </div>
            </div>
            {/* --------- Document ---------- */}
            <div 
              className="card-detail-not-teacher"
              onClick={() =>history.push(Routers.DOCUMENT_LIST + Routers.PAGE_GINATION)}
            >
              <div className="row">
                <div className="col-md-7">
                  <h5 className="text-bold-not-teacher">ຈຳນວນ</h5>
                  <h5 className="text-bold-not-teacher">ເອກະສານທັງໝົດ</h5>
                </div>
                <div className="col-md-5 center-card-not-teacher">
                  <i className="fa fa-file" />
                </div>
                <h5
                  className="text-bold"
                  style={{ color: "#606060", margin: 0 }}
                >
                  {currency(fileTotal)} ເອກະສານ
                </h5>
              </div>
            </div>
            {/* --------- Courses ---------- */}
            <div 
              className="card-detail-not-teacher"
              onClick={() =>history.push(Routers.COURSE_LIST + Routers.PAGE_GINATION)}
            >
              <div className="row">
                <div className="col-md-7">
                  <h5 className="text-bold-not-teacher">ຈຳນວນ</h5>
                  <h5 className="text-bold-not-teacher">ວິຊາທັງໝົດ</h5>
                </div>
                <div className="col-md-5 center-card-not-teacher">
                  <i className="fa fa-book" />
                </div>
                <h5
                  className="text-bold"
                  style={{ color: "#606060", margin: 0 }}
                >
                  {currency(courseTotal)} ວິຊາ
                </h5>
              </div>
            </div>
            <div className="card-detail-none" />
          </div>
        </div>

        <div className="container-custom">
          <div className="row">
            <div className="card-left">
              <h4>
                ຈຳນວນເພດ <br /> ຂອງອາຈານ
              </h4>
              <div>
                <Pie data={teacherPie} width={600} height={600} />
              </div>
              <div className="row">
                <div className="col-sm-1 parent" style={{ marginTop: 40 }}>
                  <div className="child-male child-1" />
                  <div className="child-male child-2" />
                </div>
                <div className="col-sm-3" style={{ marginTop: 40 }}>
                  ຊາຍ
                </div>
                <div
                  className="col-sm-3"
                  style={{ marginLeft: 10, marginTop: 40 }}
                >
                  {_.isEmpty(teacherCount) ? 0 : currency(teacherCount?.maleCountTeacher)}
                </div>
              </div>
              <div className="row">
                <div
                  className="col-sm-1 parent-female"
                  style={{ marginTop: 10 }}
                >
                  <div className="child-female child-female-1" />
                  <div className="child-female child-female-2" />
                </div>
                <div className="col-sm-3" style={{ marginTop: 10 }}>
                  ຍິງ
                </div>
                <div
                  className="col-sm-3"
                  style={{ marginLeft: 10, marginTop: 10 }}
                >
                  {_.isEmpty(teacherCount) ? 0 : currency(teacherCount?.femaleCountTeacher)}
                </div>
              </div>
              <div className="row" style={{ marginLeft: 10, marginTop: 20 }}>
                <div className="col-sm-6">ທັງໝົດ</div>
                <div className="col-sm-6">{_.isEmpty(teacherCount) ? 0 : currency(teacherCount?.total)}</div>
              </div>
            </div>
            <div className="card-right">
              <div className="header d-flex align-items-center">
                <h4>ເປີເຊັນເກຣດຮຽນ</h4>
                <div className="d-flex align-items-center">
                  <span>ຄະນະ: </span>
                  <select className="form-control ml-2 mt-2" style={{width: 160}}>
                    <option value={""}>ທັງໝົດ</option>
                    {gradeInFaculties?.map((faculty, index) => (
                      <option key={index} value={faculty?.id}>{faculty?.name}</option>
                    ))}
                  </select>
                </div>
                <div className="d-flex align-items-center">
                  <span>ປີຮຽນ: </span>
                  <select className="form-control ml-2 mt-2" style={{width: 100}}>
                    <option value="">ທັງໝົດ</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </div>
                <div className="d-flex align-items-center">
                  <span>ພາກຮຽນ: </span>
                  <select className="form-control ml-2 mt-2" style={{width: 100}}>
                    <option value="">ທັງໝົດ</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </select>
                </div>
              </div>
              <div style={{ overflowX: "scroll" }}>
                <Bar data={data} options={options} />
              </div>
            </div>
          </div>
        </div>
        <div className="container-custom">
          <div className="row">
            <div className="card-left">
              <h4>
                ຈຳນວນເພດ <br /> ຂອງນັກສຶກສາ
              </h4>
              <div>
                <Pie data={studentPie} width={600} height={600} />
              </div>
              {studentGenderCount?.map((item, index) => {
                return (
                  <div className="row" key={index}>
                    <div 
                      className={
                        index === 0 || index === 2 || index === 3 || index === 4 ? "col-sm-1 parent"
                        : "col-sm-1 parent-female-teacher"
                      }
                    >
                      {index === 0 ?
                      <>
                        <div className="child-male child-1" />
                        <div className="child-male child-2" />
                      </>
                      : index === 1 ? 
                      <>
                        <div className="child-female child-female-teacher-1" />
                        <div className="child-female child-female-teacher-2" />
                      </>
                      : index === 2 ?
                      <>
                        <div className="child-monk child-monk-1" />
                        <div className="child-monk child-monk-2" />
                      </>
                      : index === 3 ?
                      <>
                        <div className="child-novice child-novice-1" />
                        <div className="child-novice child-novice-2" />
                      </>
                      :
                      <>
                        <div className="child-other child-other-1" />
                        <div className="child-other child-other-2" />
                      </>
                      }
                    </div>
                    <div className="col-sm-3">{convertGender(item?.gender)}</div>
                    <div className="col-sm-3 ml-5">
                      {currency(item?.amount) ?? "0"}
                    </div>
                  </div>
                );
              })}
              <div className="row" style={{ marginLeft: 10, marginTop: 20 }}>
                <div className="col-sm-6">ທັງໝົດ</div>
                <div className="col-sm-6">{currency(studentTotal)}</div>
              </div>
            </div>
            <div className="card-right">
              <div className="row">
                <div className="col-md-6">
                  <h2>ຈຳນວນນັກຮຽນແຕ່ລະຄະນະ</h2>
                  <Form.Control
                    as="select"
                    onChange={(e) => handleStudentSelectedFaculty(e)}
                    value={studentFacultySelectedId}
                  >
                    <option value="">ທຸກຄະນະ</option>
                    {studentInFaculties?.map((faculty, index) => (
                      <option key={index} value={faculty?.id}>{faculty?.name}</option>
                    ))}
                  </Form.Control>
                  <div>
                    ທັງໝົດ {currency(studentsInFacultyTotal) ?? 0} ຄົນ, ຍິງ {currency(studentsInFacultyFemale) ?? 0} ຄົນ
                  </div>
                    { studentDataLoading && <LoadingFullScreen /> }
                    <table border="1" style={{ width: "100%" }}>
                      <thead>
                        <TableHeader>
                          <th style={{ width: 60 }}>ລຳ​ດັບ</th>
                          <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                          <th style={{ width: 50 }}>ເພດ</th>
                          <th>ຄະນະ</th>
                        </TableHeader>
                      </thead>
                      {dataStudentsInFaculty?.map((item, index) => (
                        <tbody key={index}>
                          <tr>
                            <TableCell style={{ width: 60 }}>
                              {index + 1 + _limit * (studentPageSkip - 1)}
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                              {!item?.firstNameL || item?.firstNameL === "" ? 
                                item?.firstNameE + " " + item?.lastNameE
                                :
                                item?.firstNameL + " " + item?.lastNameL
                              }
                            </TableCell>
                            <TableCell style={{ width: 50 }}>
                              {item?.gender ? convertGender(item?.gender) : "-"}
                            </TableCell>
                            <TableCell>{item?.faculty?.name ?? "-"}</TableCell>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <div className="mt-3">
                      <PaginationDashboard 
                        handlePageClick={handleStudentPageClick}
                        total={studentsInFacultyTotal}
                        limit={_limit}
                        skip={studentPageSkip}
                      />
                    </div>
                </div>

                <div className="col-md-6">
                  <h2>ຈຳນວນອາຈານແຕ່ລະຄະນະ</h2>
                  <Form.Control
                    as="select"
                    onChange={(e) => handleTeacherSelectedFaculty(e)}
                    value={teacherFacultySelectedId}
                  >
                    <option value="">ທຸກຄະນະ</option>
                    {teacherInFaculties?.map((faculty, index) => (
                      <option key={index} value={faculty?.id}>{faculty?.name}</option>
                    ))}
                  </Form.Control>
                  <div>
                    ທັງໝົດ {currency(teacherInFacultyTotal) ?? 0} ຄົນ, 
                    ຍິງ {currency(teacherInFacultyFemale) ?? 0} ຄົນ
                  </div>
                  
                  { teacherDataLOading && <LoadingFullScreen /> }
                  <table border="1" style={{ width: "100%" }}>
                    <thead>
                      <TableHeader>
                        <th style={{ width: 60 }}>ລຳ​ດັບ</th>
                        <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                        <th style={{ width: 50 }}>ເພດ</th>
                        <th>ຄະນະ</th>
                      </TableHeader>
                    </thead>
                    <tbody>
                    {dataTeacherInFaculty?.map((item, index) => (
                      <tr key={index}>
                        <TableCell style={{ width: 60 }}>
                          {index + 1 + _limit * (teacherPageSkip - 1)}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {item?.firstname ?? ""} {item?.lastname ?? ""}
                        </TableCell>
                        <TableCell style={{ width: 50 }}>
                          {item?.gender ? convertGender(item?.gender) : "-"}
                        </TableCell>
                        <TableCell>{item?.faculty?.name ?? "-"}</TableCell>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                  <div className="mt-3">
                    <PaginationDashboard 
                      handlePageClick={handleTeacherPageClick}
                      total={teacherInFacultyTotal}
                      limit={_limit}
                      skip={teacherPageSkip}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-custom"
          style={{ boxShadow: "0px 10px 20px #00000029" }}
        >
          <div className="card">
            <div className="card-body">
              <h4>ສະຖິຕິຊົນເຜົ່າ</h4>
              <div>ທັງໝົດ {currency(prereStudentTribesTotal)} ຄົນ</div>
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th>ລຳດັບ</th>
                    <th>ຊົນເຜົ່າ</th>
                    <th>ຍິງ</th>
                    <th>ທັງໝົດ</th>
                  </TableHeader>
                </thead>
                <tbody>
                {dataPrereStudentTribes?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item?.name}</TableCell>
                      <TableCell>{item?.female}</TableCell>
                      <TableCell>{item?.count}</TableCell>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
