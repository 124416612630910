import { NEW_API_URL, API_KEY } from "../../common/contrant";
import Routers from "../../consts/router";
import Const from "../../consts";
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CustomMessage from "../../common/CustomStatusMessage";
import DialogContentText from "@mui/material/DialogContentText";
import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "../../redux/filtrSlice";
import { useHistory } from "react-router-dom";
import { USER_KEY } from "../../consts";
import getRoleDescription from "../../common/GetRoleDescription";
import { ROLE_LIST } from "../../common/GetRoleDescription";
import KeyIcon from "@mui/icons-material/Key";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import GetAppIcon from "@mui/icons-material/GetApp";
import { Visibility } from "@material-ui/icons";
import { CustomContainer } from "../../common";
import { Breadcrumb } from "react-bootstrap";
import Loading from "../../common/Loading";

const CourseList = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;
  const filterData = useSelector((state) => state.filter);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState("");
  const [showComnfirm, setShowConfirm] = useState(false);

  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [count, setCount] = useState(0);

  const [showReset, setShowReset] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [password, setPassword] = useState("");

  const [resetStatus, setResetStatus] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const [formData, setFormData] = useState({
    faculty: filterData.faculty || "",
    department: filterData.department || "",
    role: "",
    title: "",
  });

  const onExport = async () => {
    let queue = 0;
    const userToExport = users.map((user, index) => ({
      ລຳດັບ: index + 1,
      ລະຫັດ: user.userId,
      "ຊື່ ແລະ ນາມສະກຸນ": `${user?.firstname || ""} ${user?.lastname || ""}`,
      ເພດ: user.gender === "MALE" ? "ຊາຍ" : "ຍິງ",
      ຄະນະ: user?.faculty?.name || "",
      ພາກວິຊາ: user?.department?.name || "",
      ລະດັບຜຸ້ໃຊ້: getRoleDescription(user?.role),
      ເບີໂທລະສັບ: user?.phone || "",
      ວັນເດືອນປີເກີດ: user?.birthday || "",
    }));

    const ws = XLSX.utils.json_to_sheet(userToExport);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Users"); // 'Students' is the sheet name

    // Write the XLSX file
    const xlsxBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const blob = new Blob([new Uint8Array(xlsxBuffer)], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "users.xlsx");
  };

  const handleResetPassword = async () => {
    try {
      await axios.put(
        NEW_API_URL + "users/password?userId=" + selectedUserId,
        {
          newPassword: password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            api_key: API_KEY,
          },
        }
      );
      setResetStatus({
        open: true,
        message: "ປ່ຽນລະຫັດຜ່ານສຳເລັດ",
        severity: "info",
      });
      setShowReset(false);
    } catch (err) {
      setResetStatus({
        open: true,
        message: "ປ່ຽນລະຫັດຜ່າບໍ່ນສຳເລັດ",
        severity: "ำพพนพ",
      });
      console.log("reset password error");
      console.log(err);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    fetchData();
  };

  const handleDetail = (user) => {
    history.push(Routers.TEACHER_DETAIL, user._id);
  };
  const handleEdit = (teacher) => {
    localStorage.removeItem("generalInfo");
    localStorage.removeItem("placeOfBirthInfo");
    localStorage.removeItem("statisticInfo");
    history.push(Routers.TEACHER_ADD, teacher);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleDelete = (id) => {
    setSelectedItem({
      id: id,
    });
    setShowConfirm(true);
  };

  const handleChange = (event) => {
    if (event.target.value === "faculty")
      setFormData((predata) => ({
        ...predata,
        department: "",
      }));
    else {
      setFormData({
        ...formData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const fetchData = async () => {
    setUsers([]);
    setIsLoading(true);
    try {
      const _formData = formData;
      if (formData.faculty === "ທັງຫມົດ") _formData.faculty = "";
      if (formData.department === "ທັງຫມົດ") _formData.department = "";
      if (formData.role === "ທັງຫມົດ") _formData.role = "";

      // Create a new URLSearchParams object and append parameters
      const apiUrl = `${NEW_API_URL}users?limit=${rowsPerPage}&faculty=${_formData.faculty}&department=${_formData.department}&page=${page}&keyword=${_formData.title}&role=${_formData?.role}`;
      const response = await axios.get(apiUrl, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data;
      setCount(data?.count || 0);
      setUsers(data?.data);
      setIsLoading(false);
    } catch (error) {
      setUsers([]);
      setIsLoading(false);
    }
  };

  const deleteUser = async () => {
    try {
      if (selectedItem?.id) {
        const rs = await axios.delete(
          `${NEW_API_URL}users?id=${selectedItem?.id}`,
          {
            headers: {
              api_key: API_KEY,
              Authorization: `Bearer ${token}`,
            },
          }
        );
        showSnackbar("ລົບຜູ້ໃຊ້ສຳເລັດ", "success");
        fetchData();

        setShowConfirm(false);
      }
    } catch (err) {
      setShowConfirm(false);
      showSnackbar("ມີບັນຫາໃນການລົບຜຸ້ໃຊ້", "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, [formData, rowsPerPage, page]); // Empty dependency array means this effect runs once on component mount

  useEffect(() => {
    let _filterData = {};
    if (formData.faculty !== "") {
      _filterData.faculty = formData.faculty;
    }

    // Check and set department if not null
    if (formData.department !== "") {
      _filterData.department = formData.department;
    }

    // Check and set major if not null
    if (formData.major !== "") {
      _filterData.major = formData.major;
    }
    // Fetch faculties, departments, and education levels from your backend API
    // Update the state variables (faculties, departments, educationLevels) accordingly
    // For simplicity, I'm using placeholder data here

    // Dispatch the setFilter action with the filterData payload
    dispatch(setFilter(_filterData));
    // Fetch faculties
    axios
      .get(NEW_API_URL + "faculty", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setFaculties(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch departments
    axios
      .get(NEW_API_URL + "department/" + formData.faculty, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDepartments(response.data?.data || []))
      .catch((error) => console.error(error));
  }, [formData]);

  useEffect(() => {
    if (snackbarOpen) {
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 5000);
    }
    if (resetStatus.open) {
      setTimeout(() => {
        setResetStatus({
          open: false,
          message: "",
          severity: "info",
        });
      }, 3000);
    }
  }, [snackbarOpen, resetStatus]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item active>ຈັດການຂໍ້ມູນອາຈານ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <Grid
          container
          className="d-flex justify-content-center px-4"
          spacing={2}
        >
          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="faculty">ຄະນະ</InputLabel>
              <Select
                disabled={
                  userObject?.data?.role === "FAC_ADMIN" ||
                  userObject?.data?.role === "DEP_ADMIN"
                }
                label="ຄະນະ"
                name="faculty"
                value={formData.faculty || "ທັງຫມົດ"}
                onChange={handleChange}
              >
                <MenuItem key={"ທັງຫມົດ"} value={"ທັງຫມົດ"}>
                  ທັງຫມົດ
                </MenuItem>
                {faculties.map((faculty) => (
                  <MenuItem key={faculty._id} value={faculty._id}>
                    {faculty.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="department">ພາກ</InputLabel>
              <Select
                disabled={userObject?.data?.role === "DEP_ADMIN"}
                label="ພາກ"
                name="department"
                value={formData.department || "ທັງຫມົດ"}
                onChange={handleChange}
              >
                <MenuItem key={"ທັງຫມົດ"} value={"ທັງຫມົດ"}>
                  ທັງຫມົດ
                </MenuItem>
                {departments.map((department) => (
                  <MenuItem key={department._id} value={department._id}>
                    {department.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <InputLabel htmlFor="educationLevel">ລະດັບຜູ້ໃຊ້</InputLabel>
              <Select
                label="ລະດັບຜູ້ໃຊ້"
                name="role"
                value={formData.role || "ທັງຫມົດ"}
                onChange={handleChange}
              >
                <MenuItem key={"ທັງຫມົດ"} value={"ທັງຫມົດ"}>
                  ທັງຫມົດ
                </MenuItem>
                {ROLE_LIST.map((role) => (
                  <MenuItem key={role} value={role}>
                    {getRoleDescription(role)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={3}>
            <FormControl fullWidth size="small" margin="normal">
              <TextField
                fullWidth
                name="title"
                onChange={handleChange}
                type="text"
                className="mt-2"
                variant="standard"
                placeholder="ຄົ້ນຫາຜູ້ໃຊ້"
                InputProps={{
                  style: {
                    height: "33px",
                  },
                  startAdornment: <SearchIcon />,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CustomContainer>
      <CustomContainer>
        <Grid
          display={"flex"}
          justifyContent={"space-between"}
          container
          marginBottom={2}
        >
          <Grid item>ລາຍການອາຈານທັງໝົດ ( {count.toLocaleString()} ) ຄົນ</Grid>
          <Grid display={"flex"} alignItems="flex-end" item gap={2}>
            <Grid>
              <Button
                onClick={() => {
                  onExport();
                }}
                variant="contained"
                color="success"
                startIcon={<GetAppIcon />}
              >
                Export
              </Button>
            </Grid>
            <Grid>
              <Button
                onClick={() => {
                  localStorage.removeItem("generalInfo");
                  localStorage.removeItem("placeOfBirthInfo");
                  localStorage.removeItem("statisticInfo");
                  history.push(Routers.TEACHER_ADD);
                }}
                variant="contained"
                sx={{ backgroundColor: Const.PRIMARY_COLOR }}
                startIcon={<AddIcon />}
              >
                ເພິ່ມຜູ້ໃຊ້
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer>
          {isLoading ? (
            <Loading />
          ) : (
            <Table>
              <TableHead>
                <TableRow className="thead-primary">
                  <TableCell className="tablecell-head" width={70}>
                    ລຳດັບ
                  </TableCell>
                  <TableCell className="tablecell-head">ໄອດີ</TableCell>
                  <TableCell className="tablecell-head">
                    ຊື່ ແລະ ນາມສະກຸນ
                  </TableCell>
                  <TableCell className="tablecell-head" width={300}>
                    ອີເມວ
                  </TableCell>
                  <TableCell className="tablecell-head">ເບີໂທລະສັບ</TableCell>
                  <TableCell className="tablecell-head" width={100}>
                    ລະດັບຜູ້ໃຊ້
                  </TableCell>
                  <TableCell className="tablecell-head">ຈັດການ</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {users.map((user, index) => (
                  <TableRow key={user._id}>
                    <TableCell align="center">
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell align="start">{user?.userId}</TableCell>
                    <TableCell align="start">
                      {user.gender === "FEMALE"
                        ? `ທ່ານ ນາງ ${user?.firstname ?? "---"} ${
                            user?.lastname ?? "---"
                          }`
                        : `ທ່ານ ${user?.firstname ?? "---"} ${
                            user?.lastname ?? "---"
                          }`}
                    </TableCell>
                    <TableCell align="start">{user?.email || ""}</TableCell>
                    <TableCell align="center">{user?.phone || ""}</TableCell>
                    <TableCell align="center">
                      {getRoleDescription(user?.role)}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="success"
                        size="small"
                        aria-label="Detail"
                        onClick={() => handleDetail(user)}
                      >
                        <Visibility />
                      </IconButton>
                      <IconButton
                        color="info"
                        size="small"
                        aria-label="Edit"
                        onClick={() => handleEdit(user)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        size="small"
                        aria-label="Delete"
                        onClick={() => {
                          setShowReset(true);
                          setSelectedUserId(user?.userId);
                        }}
                      >
                        <KeyIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        size="small"
                        aria-label="Delete"
                        onClick={() => handleDelete(user._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              <TablePagination
                rowsPerPageOptions={[
                  10,
                  20,
                  50,
                  { label: "All", value: count },
                ]}
                colSpan={7} // Adjusted colSpan to include the Actions column
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                elevation={0}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Table>
          )}
        </TableContainer>
      </CustomContainer>
      <CustomMessage
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <CustomMessage
        open={resetStatus.open}
        message={resetStatus.message}
        severity={resetStatus.severity}
      />
      <Dialog open={showComnfirm} onClose={() => setShowConfirm(false)}>
        <DialogTitle>ລົບຜູ້ໃຊ້</DialogTitle>
        <DialogContent>
          <DialogContentText>ຕ້ອງການລົບຜູ້ໃຊ້ນີ້ແທ້ບໍ່?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirm(false)} color="primary">
            ຍົກເລີກ
          </Button>
          <Button
            onClick={() => {
              deleteUser();
            }}
            color="primary"
            autoFocus
          >
            ຢືນຢັນ
          </Button>
        </DialogActions>
      </Dialog>
      {/* reset password Dialog */}
      <Dialog open={showReset} onClose={() => setShowReset(false)}>
        <DialogTitle>ປ່ຽນລະຫັດຜ່ານ</DialogTitle>
        <DialogContent>
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            placeholder="ລະຫັດຜ່ານໃຫມ່"
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowReset(false)} color="primary">
            ຍົກເລີກ
          </Button>
          <Button
            onClick={() => {
              handleResetPassword();
            }}
            color="primary"
            autoFocus
          >
            ຢືນຢັນ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CourseList;
