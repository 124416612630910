import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { USER_KEY } from "../../consts";
import { NEW_API_URL, API_KEY } from "../../common/contrant";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import AddTeacherModal from "./addTeacherDialog";
import axios from "axios";
import CustomMessage from "../../common/CustomStatusMessage";
import AddGradeTypeDialog from "./addGradeTypeDialog";
import AddTimeDialog from "./addTimeDialog";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const user = localStorage.getItem(USER_KEY);
const userObject = JSON.parse(user);
const token = userObject?.accessToken;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "95vh", // Set a maximum height for the container
  overflowY: "auto", // Enable vertical scrolling
};

const groupStyle = {
  marginBottom: "1rem",
};

export default function CourseDetail({ open, onCancel, data }) {
  const [timesStudy, setTimeStudy] = useState([]);
  const [gradeType, setGradeType] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const [department, setDepartment] = useState();
  const [faculty, setFaculty] = useState();

  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [showAddTeacher, setShowAddTeacher] = useState(false);
  const [showAddGradeType, setShowAddGradetype] = useState(false);
  const [showAddTimeDialog, setShowAddTimeDialog] = useState(false);

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        const data = {
          ...actionStatus,
          open: false,
        };
        setActionStatus(data);
      }, 2000);
    }
  }, [actionStatus]);

  const fetchCourseData = async (id) => {
    try {
      const response = await axios.get(NEW_API_URL + `coursedetail/${id}`, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("response => ", response.data);
      setTimeStudy(response.data?.timesStudy || []);
      setGradeType(response.data?.gradeType || []);
      setTeachers(response.data?.teacher || []);
      setFaculty(response.data?.course?.faculty._id || "");
      setDepartment(response.data?.course?.department._id || "");
    } catch (err) {}
  };

  useEffect(() => {
    if (data) {
      fetchCourseData(data?.course?._id);
    }
  }, [data, open]);

  const removeTime = async (id) => {
    try {
      await axios.delete(
        NEW_API_URL + `course/time/${data?.course?._id}/${id}`,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await fetchCourseData(data?.course?._id);
      setActionStatus({
        severity: "success",
        message: "ລົບເກນຄະເເນນສຳເລັດ",
        open: true,
      });
    } catch (err) {
      setActionStatus({
        severity: "error",
        message: "ເກີດຂໍ້ຜີດພາດໃນການລົບເກນຄະເເນນ",
        open: true,
      });
    }
  };

  const removeTeacher = async (id) => {
    try {
      await axios.delete(
        NEW_API_URL + `course/teacher/${data?.course?._id}/${id}`,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await fetchCourseData(data?.course?._id);
      setActionStatus({
        severity: "success",
        message: "ລົບອາຈານສຳເລັດ",
        open: true,
      });
    } catch (err) {
      setActionStatus({
        severity: "error",
        message: "ເກີດຂໍ້ຜີດພາດໃນການລົບອາຈານ",
        open: true,
      });
    }
  };

  const addTeacher = async (ids) => {
    try {
      await axios.put(
        NEW_API_URL + `course/teacher/${data?.course?._id}`,
        { teacherIds: ids },
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchCourseData(data?.course?._id);
      setActionStatus({
        severity: "success",
        message: "ເພີ່ມອາຈານສຳເລັດ",
        open: true,
      });
    } catch (err) {
      setActionStatus({
        severity: "error",
        message: "ເກີດຂໍ້ຜີດພາດໃນການເພີ່ມອາຈານ",
        open: true,
      });
    }
  };

  const addGradeType = async (grade) => {
    try {
      await axios.put(
        NEW_API_URL + `course/gradetype/${data?.course?._id}`,
        { grade: grade },
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchCourseData(data?.course?._id);
      setActionStatus({
        severity: "success",
        message: "ເພີ່ມເກນຄະເເນນສຳເລັດ",
        open: true,
      });
    } catch (err) {
      console.log("err status => ", err.message);
      let errorMessage;
      if (err.message === "Request failed with status code 400") {
        errorMessage = "ຄະເເນນລວມທັງຫມົດຂອງເກນຄະເເນນຕ້ອງບໍ່ເກີນ 100";
      } else {
        errorMessage = "ມີຂໍ້ຜິດພາດໃນການເພີ່ມເກນການໃຫ້ຄະເເນນ​";
      }
      setActionStatus({
        severity: "error",
        message: errorMessage,
        open: true,
      });
    }
  };

  const removeGradeType = async (title) => {
    try {
      await axios.delete(
        NEW_API_URL + `course/gradetype/${data?.course?._id}/${title}`,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await fetchCourseData(data?.course?._id);
      setActionStatus({
        severity: "success",
        message: "ລົບເກນຄະເເນນສຳເລັດ",
        open: true,
      });
    } catch (err) {
      setActionStatus({
        severity: "error",
        message: "ເກີດຂໍ້ຜີດພາດໃນການລົບເກນຄະເເນນ",
        open: true,
      });
    }
  };

  const addTimesTudy = async (timeData) => {
    try {
      await axios.put(
        NEW_API_URL + `course/time/${data?.course?._id}`,
        { data: timeData },
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      await fetchCourseData(data?.course?._id);
      setActionStatus({
        severity: "success",
        message: "ເພີ່ມເກນຄະເເນນສຳເລັດ",
        open: true,
      });
    } catch (err) {
      setActionStatus({
        severity: "error",
        message: "ມີຂໍ້ຜິດພາດໃນການເພີ່ມເກນການເພີ່ມຊົ່ວໂມງສອນ​",
        open: true,
      });
    }
  };

  return (
    <div>
      <AddTimeDialog
        factId={faculty}
        open={showAddTimeDialog}
        onCancel={() => setShowAddTimeDialog(false)}
        onSubmit={async (data) => {
          if (data) {
            await addTimesTudy(data);
          }
        }}
      />
      <AddGradeTypeDialog
        open={showAddGradeType}
        onClose={() => setShowAddGradetype(false)}
        onSubmit={async (data) => {
          if (data) {
            console.log("data grade type => ", data);
            await addGradeType(data);
          }
        }}
      />
      <CustomMessage
        open={actionStatus.open}
        message={actionStatus.message}
        severity={actionStatus.severity}
      />
      <AddTeacherModal
        faculty={faculty}
        department={department}
        handleSubmit={async (selectedTeachers) => {
          // Extracting _id values of teachers
          const teacherIdsFromTeachers = teachers.map((teacher) => teacher._id);

          // Combining selected teachers' IDs with IDs from the list of all teachers
          const combinedTeacherIds = [
            ...selectedTeachers,
            ...teacherIdsFromTeachers,
          ];
          await addTeacher(combinedTeacherIds);
          setShowAddTeacher(false);
        }}
        open={showAddTeacher}
        onClose={() => setShowAddTeacher(false)}
      />
      <Modal open={open} onClose={onCancel}>
        <Box sx={style}>
          <div className="w-100 text-center mt-2">
            <Typography variant="h5">ລາຍລະອຽດຕາຕະລາງສອນ</Typography>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="d-flex gap-5 justify-content-start">
                <Typography variant="body1">ຄະນະ:</Typography>
                <Typography variant="body1">
                  {data?.course?.course?.faculty?.name}
                </Typography>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex gap-5 justify-content-start">
                <Typography variant="body1">ພາກ:</Typography>
                <Typography variant="body1" className="value">
                  {data?.course?.course?.department?.name}
                </Typography>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex mt-2 gap-3 justify-content-start">
                <Typography variant="body1">ສາຂາ:</Typography>
                <Typography variant="body1">
                  {data?.course?.course?.major?.name}
                </Typography>
              </div>
            </div>

            <div className="col-6">
              <div className="d-flex mt-2 gap-1 justify-content-start">
                <Typography variant="body1">ຫ້ອງຮຽນ:</Typography>
                <Typography variant="body1">
                  {data?.course?.classRoom?.name}
                </Typography>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex mt-2 justify-content-start">
                <Typography variant="body1">ປິຮຽນ:</Typography>
                <Typography variant="body1">{data?.course?.year}</Typography>
              </div>
            </div>

            <div className="col-6">
              <div className="d-flex mt-2 gap-1 justify-content-start">
                <Typography variant="body1">ພາກຮຽນ:</Typography>
                <Typography variant="body1">
                  {data?.course?.semester}
                </Typography>
              </div>
            </div>

            <div className="mt-2">
              <Typography variant="body1">ອາຈານສອນ:</Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                  gap: 2, // Adjust gap between items
                }}
              >
                {teachers.map((teacher) => (
                  <Box
                    key={teacher._id}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        textAlign: "start",
                        paddingRight: "16px", // Ensure there's space for the icon
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {`${teacher.gender === "MALE" ? "ທ່ານ " : "ທ່ານ ນາງ "}${
                          teacher.firstname || ""
                        } ${teacher.lastname || ""}`}
                        <IconButton
                          edge="end"
                          color="error"
                          sx={{ ml: "auto" }}
                          onClick={() => removeTeacher(teacher._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Button>
                  </Box>
                ))}
              </Box>

              <Button
                startIcon={<AddIcon />}
                size="large"
                onClick={() => setShowAddTeacher(true)}
                sx={{
                  width: "100%",
                  textAlign: "start",
                  marginTop: "15px",
                }}
              >
                ເພິ່ມອາຈານສອນ
              </Button>
            </div>
            <div className="mt-2" style={groupStyle}>
              <Typography variant="body1">ວັນເວລາສອນ:</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow style={{ backgroundColor: "#edf2f4" }}>
                      <TableCell style={{ textAlign: "center" }}>
                        ອາທິດສອນ
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        ຊົ່ວໂມງ
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        ປະເພດການສອນ
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        ສະຖານທິ່ສອນ
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>ລົບ</TableCell>{" "}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {timesStudy?.map((timeStudy, index) => (
                      <TableRow key={timeStudy._id}>
                        <TableCell style={{ textAlign: "center" }}>
                          {timeStudy.day}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {timeStudy.times[0]?.time}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {timeStudy.studyType === "LAB"
                            ? "ປະຕິບັດ"
                            : "ທິດສະດີ"}
                        </TableCell>
                        <TableCell
                          style={{ textAlign: "center" }}
                        >{`${timeStudy.address}`}</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <IconButton
                            color="error"
                            onClick={() => removeTime(timeStudy._id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Button
                onClick={() => setShowAddTimeDialog(true)}
                size="large"
                startIcon={<AddIcon />}
                sx={{ width: "100%", textAlign: "start", marginTop: "15px" }}
              >
                ເພິ່ມເວລາສອນ
              </Button>
            </div>
            <div className="mt-2" style={groupStyle}>
              <Typography variant="body1">ເກນການໃຫ້ຄະເເນນ:</Typography>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, minmax(280px, 1fr))",
                  gap: "16px", // Adjust gap between items
                  width: "100%", // Ensure the grid container takes the full width
                }}
              >
                {gradeType?.map((grade, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        textAlign: "start",
                        paddingRight: "16px", // Ensure there's space for the icon
                      }}
                      variant="outlined"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {`${grade?.name} (${grade?.maxScore || ""})`}
                        <IconButton
                          color="error"
                          sx={{ ml: "auto" }}
                          onClick={() => removeGradeType(grade.name)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Button>
                  </Box>
                ))}
              </div>
              <Button
                onClick={() => setShowAddGradetype(true)}
                size="large"
                startIcon={<AddIcon />}
                sx={{ width: "100%", textAlign: "start", marginTop: "15px" }}
              >
                ເພິ່ມເກນການໃຫ້ຄະເເນນ
              </Button>
            </div>
          </div>
          <Box
            size="large"
            sx={{ display: "flex", justifyContent: "center", mt: 3 }}
          >
            <Button variant="contained" onClick={onCancel} sx={{ ml: 2 }}>
              ປິດ
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
