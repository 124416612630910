import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { NEW_API_URL } from "../../common/contrant";

import { useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { FACULTIES } from "../preregistrationStudent/apollo/query";
import { Box, Checkbox, FormControlLabel } from "@mui/material";

function Docnotice() {
  const [docnotices, setDocnotices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAllModal, setShowAllModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState("all"); // New state to track the selected tab
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    type: "NOTICE",
    for: "STUDENT",
    faculty: "",
    showDate: "",
    endDate: "",
    image: null, // Reset image field
    file: null, // Reset file field
  });
  const [imagePreview, setImagePreview] = useState("");
  const [selectedRole, setSelectedRole] = useState("ALL");
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(16); // Show 4 items per page

  const [loadFaculty, { data: facultyData }] = useLazyQuery(FACULTIES, {
    fetchPolicy: "network-only",
  });

  // State for checkboxes
  const [checkboxState, setCheckboxState] = useState({
    isCheckedTrue: false,
    isCheckedFalse: false,
  });

  // Checkbox change handlers
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    if (name === "checkboxtrue") {
      setCheckboxState({ isCheckedTrue: checked, isCheckedFalse: false }); // Uncheck the false checkbox
    } else if (name === "checkboxfalse") {
      setCheckboxState({ isCheckedTrue: false, isCheckedFalse: checked }); // Uncheck the true checkbox
    }
  };

  useEffect(() => {
    loadFaculty({
      variables: {
        where: { isDeleted: false },
      },
    });
  }, []);

  const handleChangeFaculty = (e) => {
    const selectedFaculty = e.target.value.split("|+|");
    setFormData({ ...formData, faculty: selectedFaculty[1] }); // Assuming the second part is the faculty ID
  };

  useEffect(() => {
    axios
      .get(NEW_API_URL + "docnotice")
      .then((response) => {
        setDocnotices(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    setFormData({ ...formData, image: file });

    if (file) {
      const previewUrl = URL.createObjectURL(file); // Generate preview URL
      setImagePreview(previewUrl);
    } else {
      setImagePreview(""); // Clear preview if no file is selected
    }
  };

  // Function to handle file selection (PDF, DOC, etc.)
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setFormData({ ...formData, file });
  };

  // Handle form submission for both adding and updating
  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validate form data
    if (
      !formData.file ||
      !formData.image ||
      !formData.title ||
      !formData.description ||
      !formData.endDate ||
      !formData.faculty
    ) {
      alert("ກະລຸນາປ້ອນໃຫ້ຄົບ.");
      return;
    }

    // Log form data for debugging
    console.log("Form Data Before Submission:", formData);

    // Create form data
    const data = new FormData();
    data.append("file", formData.file || selectedDoc.file);
    data.append("image", formData.image || selectedDoc.image);
    data.append("description", formData.description);
    data.append("type", formData.type);
    data.append("for", formData.for);
    data.append("faculty", formData.faculty);
    data.append("showDate", formData.showDate);
    data.append("endDate", formData.endDate);
    data.append("title", formData.title);

    // Check if it's an update or new submission
    if (selectedDoc) {
      // Update existing document
      handleUpdateSubmit(data);
    } else {
      // Add new document
      handleAddSubmit(data);
    }
  };

  const handleAddSubmit = async (data) => {
    try {
      if (!checkboxState.isCheckedTrue && !checkboxState.isCheckedFalse) {
        alert("ກະລຸນາເລືອກໃຫ້ແຈ້ງເຕືອນ ຫຼື ບໍ່!");
        return;
      }
      const response = await axios.post(NEW_API_URL + "docnotice", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("ເພີ່ມຂໍ້ມູນສຳເລັດແລ້ວ!");
      setDocnotices([...docnotices, response.data]);

      if (checkboxState.isCheckedTrue) {
        await sendNotification();
      }

      // Reset form fields
      setImagePreview("");
      setCheckboxState({ isCheckedTrue: false, isCheckedFalse: false });
      resetForm();
      setShowAddModal(false); // Close the modal
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };

  // Submit form for updating existing document
  const handleUpdateSubmit = (data) => {
    axios
      .put(`${NEW_API_URL}docnotice/${selectedDoc._id}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        alert("ອັບເດດເອກະສານສຳເລັດແລ້ວ!");
        const updatedDocnotices = docnotices.map((doc) =>
          doc._id === selectedDoc._id ? response.data : doc
        );
        setDocnotices(updatedDocnotices); // Update the state
        setShowAddModal(false); // Close the modal
      })
      .catch((error) => {
        console.error("Error updating document:", error);
      });
  };

  // Function to open the update modal with selected document data
  const handleUpdate = (docnotice) => {
    setIsEditMode(true); // Set to edit mode
    setFormData({
      title: docnotice.title,
      description: docnotice.description,
      type: docnotice.type,
      for: docnotice.for,
      faculty: docnotice.faculty,
      showDate: docnotice.showDate,
      endDate: docnotice.endDate,
      image: docnotice.image, // Keep the existing image
      file: docnotice.file, // Keep the existing file
    });
    setSelectedDoc(docnotice);
    setShowAddModal(true); // Reuse the add modal for updates
  };

  // Function to reset form fields after submission
  const resetForm = () => {
    setFormData({
      title: "",
      description: "",
      type: "NOTICE",
      for: "STUDENT",
      faculty: "",
      showDate: "",
      endDate: "",
      image: null, // Reset image field
      file: null, // Reset file field
    });
    setSelectedDoc(null); // Reset selected document
  };

  // Function to delete a document
  const handleDelete = (docnotice) => {
    if (window.confirm(`ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບ "${docnotice.title}"?`)) {
      axios
        .delete(`${NEW_API_URL}docnotice/${docnotice._id}`)
        .then(() => {
          // alert("ລຶບເອກະສານສຳເລັດແລ້ວ!");
          setDocnotices(docnotices.filter((doc) => doc._id !== docnotice._id));
        })
        .catch((error) => {
          console.error("Error deleting document:", error);
        });
    }
  };

  // Function to filter docnotices based on selected tab and role
  const filteredNotices = docnotices.filter((docnotice) => {
    const isTabMatch = selectedTab === "all" || docnotice.type === selectedTab;
    const isRoleMatch =
      selectedRole === "ALL" || docnotice.for === selectedRole; // Assuming 'for' indicates the target audience

    return isTabMatch && isRoleMatch;
  });

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginatedNotices = filteredNotices.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const sendNotification = async () => {
    await axios.post(NEW_API_URL + "notification/send", {
      title:
        formData.type === "docnotice"
          ? "ແຈ້ງການອອກໃຫມ່"
          : formData.type === "CONFIRM"
          ? "ຂໍ້ຕົກລົງອອກໃຫມ່"
          : formData.type === "RULE"
          ? "ລະບຽບອອກໃຫມ່"
          : "ໜັງສືພິມອອກໃຫມ່",
      body: "",
      path:
        formData.type === "docnotice"
          ? "NOTICE"
          : formData.type === "CONFIRM"
          ? "CONFIRM"
          : formData.type === "RULE"
          ? "RULE"
          : "NEWS",
    });
  };

  const totalPages = Math.ceil(docnotices.length / itemsPerPage);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleViewAll = (docnotice) => {
    setSelectedDoc(docnotice);
    setShowAllModal(true);
  };

  return (
    <div className=" p-3 ">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>ການແຈ້ງການ ແລະ ເຜີຍແຜ່ລະບຽບການ</h1>
        <Button
          variant="success"
          className="d-flex align-items-center"
          onClick={() => setShowAddModal(true)}
        >
          <i className="fas fa-plus-circle me-2"></i> ເພີ່ມໃຫມ່
        </Button>
      </div>
      <div className="nav nav-tabs justify-content-center my-4">
        <div className="nav-item">
          <span className="nav-link disabled">ຫມວດ: </span>
          {/* Display category title */}
        </div>
        <button
          className={`nav-link ${selectedTab === "all" ? "active" : ""}`}
          onClick={() => setSelectedTab("all")}
        >
          ທາງໝົດ
        </button>
        <button
          className={`nav-link ${selectedTab === "NOTICE" ? "active" : ""}`}
          onClick={() => setSelectedTab("NOTICE")}
        >
          ແຈ້ງການ
        </button>
        <button
          className={`nav-link ${selectedTab === "CONFIRM" ? "active" : ""}`}
          onClick={() => setSelectedTab("CONFIRM")}
        >
          ຂໍ້ຕົກລົງ
        </button>
        <button
          className={`nav-link ${selectedTab === "RULE" ? "active" : ""}`}
          onClick={() => setSelectedTab("RULE")}
        >
          ລະບຽບ
        </button>
        <button
          className={`nav-link ${selectedTab === "NEWS" ? "active" : ""}`}
          onClick={() => setSelectedTab("NEWS")}
        >
          ໜັງສືພິມ
        </button>
      </div>

      {/* New selection for Student, Teacher, and All */}
      <div className="my-4 text-center">
        <span className="me-2 fs-5">ຜູ້ທີ່ສາມາດເຫັນ: </span>
        <div className="btn-group">
          <button
            className={`btn btn-outline-primary me-2 ${
              selectedRole === "ALL" ? "active" : ""
            }`}
            onClick={() => setSelectedRole("ALL")}
          >
            ທາງໝົດ
          </button>
          <button
            className={`btn btn-outline-primary me-2 ${
              selectedRole === "STUDENT" ? "active" : ""
            }`}
            onClick={() => setSelectedRole("STUDENT")}
          >
            ສະເພາະນັກຮຽນ
          </button>
          <button
            className={`btn btn-outline-primary ${
              selectedRole === "TEACHER" ? "active" : ""
            }`}
            onClick={() => setSelectedRole("TEACHER")}
          >
            ສະເພາະອາຈານ
          </button>
        </div>
      </div>

      {/* Render notices in a grid */}
      <div className="row">
        {paginatedNotices.length > 0 ? (
          paginatedNotices.map((docnotice) => (
            <div
              className="col-lg-3 col-md-4 col-sm-6 col-12 mb-4"
              key={docnotice._id}
            >
              <div className="card">
                <div className="card-body">
                  {docnotice.image && (
                    <img
                      src={docnotice.image}
                      alt={docnotice.title}
                      style={{
                        height: "auto", 
                        maxHeight: "200px",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  )}
                  <div className="card-title">
                    <p className="text-truncate">{docnotice.title}</p>
                  </div>
                  <div className="card-text">
                    <p className="text-truncate">{docnotice.description}</p>
                  </div>

                  <Button
                    variant="info"
                    className="me-2"
                    onClick={() => handleViewAll(docnotice)}
                  >
                    <i className="fas fa-eye"></i>
                  </Button>

                  <Button
                    variant="warning"
                    className="me-2"
                    onClick={() => handleUpdate(docnotice)}
                  >
                    <i className="fas fa-edit"></i> ແກ້ໄຂ
                  </Button>

                  <Button
                    variant="danger"
                    onClick={() => handleDelete(docnotice)}
                  >
                    <i className="fas fa-trash-alt"></i> ລຶບ
                  </Button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>ບໍ່ພົບເອກະສານແຈ້ງການ.</p>
        )}
      </div>
      <div className="d-flex justify-content-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index}
            variant={currentPage === index + 1 ? "primary" : "secondary"}
            onClick={() => setCurrentPage(index + 1)}
            className="mx-1"
          >
            {index + 1}
          </Button>
        ))}
      </div>

      <Modal
        show={showAddModal}
        onHide={() => {
          resetForm();
          setIsEditMode(false); // Reset edit mode
          setShowAddModal(false);
        }}
        dialogClassName="modal-lg"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? "ແກ້ໄຂ" : "ເພີ່ມໃຫມ່"}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="container">
          <form>
            <div className="form-group">
              <label>ຫົວຂໍ້:</label>
              <input
                type="text"
                name="title"
                placeholder="ປ້ອນຫົວຂໍ້"
                className="form-control"
                value={formData.title}
                onChange={(e) =>
                  setFormData({ ...formData, title: e.target.value })
                }
                required
              />
            </div>

            <div className="form-group">
              <label>ລາຍລະອຽດ:</label>
              <textarea
                name="description"
                className="form-control"
                placeholder="ລາຍລະອຽດ"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                required
                spellCheck={true}
                autoCapitalize="sentences"
                style={{
                  width: "700px",
                  height: "200px",
                  resize: "none",
                  overflowY: "auto",
                  lineHeight: "1.6",
                  padding: "10px",
                  boxSizing: "border-box",
                }}
              />
            </div>

            <>
              <div className="form-group">
                <label>ປະເພດ:</label>
                <select
                  name="type"
                  className="form-control"
                  value={formData.type}
                  onChange={(e) =>
                    setFormData({ ...formData, type: e.target.value })
                  }
                >
                  <option value="NOTICE">ແຈ້ງການ</option>
                  <option value="CONFIRM">ຂໍ້ຕົກລົງ</option>
                  <option value="RULE">ລະບຽບ</option>
                  <option value="NEWS">ໜັງສືພິມ</option>
                </select>
              </div>

              <div className="form-group">
                <label>ສະເພາະ:</label>
                <select
                  name="for"
                  className="form-control"
                  value={formData.for}
                  onChange={(e) =>
                    setFormData({ ...formData, for: e.target.value })
                  }
                >
                  <option value="STUDENT">ສະເພາະນັກຮຽນ</option>
                  <option value="TEACHER">ສະເພາະອາຈານ</option>
                  <option value="ALL">ທາງໝົດ</option>
                </select>
              </div>

              <div className="form-group">
                <label>ຄະນະ:</label>
                <select
                  name="faculty"
                  className="form-control"
                  onChange={handleChangeFaculty}
                  required
                >
                  <option value="">ທຸກຄະນະ</option>
                  {facultyData?.faculties?.map((item, index) => (
                    <option key={index} value={`${item.name}|+|${item.id}`}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group">
                <label>ຮູບພາບ:</label>
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleImageChange}
                  required
                />
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Image Preview"
                    style={{ width: "100px", marginTop: "10px" }}
                  />
                )}
              </div>

              <div className="form-group">
                <label>ໄຟເອກະສານ:</label>
                <input
                  type="file"
                  name="file"
                  className="form-control"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>ວັນທີເຜີຍແຜ່:</label>
                <input
                  type="date"
                  name="showDate"
                  className="form-control"
                  value={formData.showDate}
                  onChange={(e) =>
                    setFormData({ ...formData, showDate: e.target.value })
                  }
                  required
                />
              </div>
            </>

            <div className="form-group">
              <label>ວັນທີສຸດການເຜີຍແຜ່:</label>
              <input
                type="date"
                name="endDate"
                className="form-control"
                value={formData.endDate}
                onChange={(e) =>
                  setFormData({ ...formData, endDate: e.target.value })
                }
                required
              />
            </div>

            {!isEditMode && (
              <>
                <Box className="p-3 border border-danger m-3">
                  <Box className="py-2">ໃຫ້ແຈ້ງເຕືອນ ຫຼື ບໍ່*</Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkboxtrue"
                          checked={checkboxState.isCheckedTrue}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="ແຈ້ງເຕືອນ"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkboxfalse"
                          checked={checkboxState.isCheckedFalse}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="ບໍ່ແຈ້ງເຕືອນ"
                    />
                  </Box>
                </Box>
              </>
            )}

            <Button
              variant="secondary"
              onClick={() => setShowAddModal(false)}
              className="me-2" // Bootstrap class to add margin between buttons
            >
              ປິດ {/* Lao for 'Close' */}
            </Button>
            <Button variant="primary" onClick={handleFormSubmit}>
              {isEditMode ? "ບັນທຶກການແກ້ໄຂ" : "ບັນທຶກ"}{" "}
              {/* Lao for 'Save' or 'Save Changes' */}
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      {/* Modal to display full details */}
      <Modal
        show={showAllModal}
        onHide={() => setShowAllModal(false)}
        dialogClassName="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedDoc?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="from-control">
          {selectedDoc && (
            <>
              {selectedDoc.image && (
                <img
                  src={selectedDoc.image}
                  alt={selectedDoc.title}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    marginBottom: "15px",
                  }}
                />
              )}
              <p>
                <strong>ຫົວຂໍ້:</strong> {selectedDoc.title}
              </p>

              <div className="form-group">
                <p>
                  <strong>ລາຍລະອຽດ:</strong>
                </p>
                <textarea
                  name="description"
                  className="form-control"
                  value={selectedDoc.description}
                  readOnly
                  style={{
                    width: "700px",
                    height: "200px",
                    resize: "none",
                    overflowY: "auto",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    lineHeight: "1.6",
                    padding: "10px",
                    fontSize: "1rem",
                  }}
                />
              </div>

              <p>
                <strong>ປະເພດ:</strong> {selectedDoc.type}
              </p>
              <p>
                <strong>ສະເພາະ:</strong> {selectedDoc.for}
              </p>
              <p>
                <strong>ວັນທີເຜີຍແຜ່:</strong>{" "}
                {new Date(selectedDoc.showDate).toLocaleDateString()}
              </p>
              <p>
                <strong>ວັນທີສຸດການເຜີຍແຜ່:</strong>{" "}
                {new Date(selectedDoc.endDate).toLocaleDateString()}
              </p>
              {/* {selectedDoc.file && (
                <p>
                  <a
                    href={selectedDoc.file}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Document
                  </a>
                </p>
              )} */}
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Docnotice;
