import { gql } from "apollo-boost";
export const CREATE_MANY_REGISTRATION = gql`
  mutation CreateManyGrade($data: CreateManyGradeInput) {
    createManyGrade(data: $data) {
      message
      all
      success
      cannotInsert{
        userId
        firstNameL
        lastNameL
      }
    }
  }
`;

export const CREATE_MANY_PROMOTE_TO_NEXT_GRADE = gql`
  mutation CreateManyPromoteToNextGrade($data: [StudentCreateInputForPromoteNextGrade]) {
    createManyPromoteToNextGrade(data: $data) {
      all
      success
      message
      }
    }
`;