import React from 'react';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
    Button
} from '@mui/material'
import { convertGenderNam } from '../../../helpers/user';

const ExportUpdatedRegister = ({ data }) => {
    const onExport = (data) => {
        let queue = 0;
        const StudentList = data.map((item) => {
            queue++;
            return {
                ລຳດັບ: queue,
                ລະຫັດນັກສຶກສາ: item.studentNumber,
                "ຊື່ ແລະ ນາມສະກຸນ": convertGenderNam(item?.gender)
                    + " " + item?.firstNameL + " " + item?.lastNameL,
                ຄະນະ: item?.faculty?.name,
                ພາກ: item?.department?.name,
                ສາຂາ: item?.major?.name,
                ລະດັບການສຶກສາ: item?.educationLevel?.name,
                ປີຮຽນ: item.yearLevel,
                ສະຖານະ: item?.isRegister?.firstSemester === false ? "ຍັງບໍ່ໄດ້ລົງທະບຽນ" : "ລົງທະບຽນແລ້ວ",
            };
        });

        const ws = XLSX.utils.json_to_sheet(StudentList);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "UpdatedRegisterList");

        // Write the XLSX file
        const xlsxBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
            mimeType:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const blob = new Blob([new Uint8Array(xlsxBuffer)], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "updatedRegisterStudent.xlsx");
    };
    return (
        <div>
            <Button
                color="success"
                variant='contained'
                sx={{ textTransform: "none", marginBottom: 3 }}
                onClick={() => onExport(data)}
            >
                Export
            </Button>
        </div>
    )
}

export default ExportUpdatedRegister