//export const NEW_API_URL = "http://localhost:8000/api/v1/"
//export const NEW_API_URL = "https://nuol-api.daohoung.com/api/v1/";
//export const NEW_API_URL = "http://47.128.3.173:8000/api/v1/";
// export const NEW_API_URL = "http://13.229.141.85:8004/api/v1/";
// export const NEW_API_URL = "http://13.251.5.178:2002/api/v1/"
// EducationLevel api
export const NEW_API_URL = "https://nuol-api.laodevhub.com/api/v1/";

//export const NEW_API_URL = "http://13.229.141.85:8000/api/v1/";

export const API_KEY = "ScHZ14SyWp0189ftmKzU$PM%%hORJjX2KNVd*Hjzw^0&CnnPHo";
// 135N0116/21
