import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import styled from "styled-components";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Routers from "../consts/router";
import Login from "../pages/login/Login";

import TabComponenttest from "../pages/scholarshipAndJob/TabComponent";
// CustomSideNav
import { CustomSideNav, CustomNavbar } from "../common";

// Course
import CourseList from "../pages/courseList/CourseList";
import CourseAdd from "../pages/courseAdd/CourseAdd";
import CourseDetail from "../pages/courseDetail/CourseDetail";
import CourseDocList from "../pages/courseDocList/CourseDocList";

// Teacher
import TeacherList from "../pages/teacherList/TeacherList";
import TeacherDetail from "../pages/teacherDetail/TeacherDetail";
import TeacherAdd from "../pages/teacherAdd/TeacherAdd";
import TeacherEdit from "../pages/teacherEdit/TeacherEdit";
import TeacherFormStatistic from "../pages/teacherAdd/TeacherFormStatistic";
import TeacherFormPlaceOfBirth from "../pages/teacherAdd/TeacherFormPlaceOfBirth";

// Student
import StudentList from "../pages/studentList/StudentList";
import StudentDetail from "../pages/studentDetail/StudentDetail";
import StudentAdd from "../pages/studentAdd/StudentAdd";
import StudentEdit from "../pages/studentEdit/StudentEdit";
import StudentYearOne from "../pages/studentDetail/StudentYearOne";
import StudentYearTwo from "../pages/studentDetail/StudentYearTwo";
import StudentYearThree from "../pages/studentDetail/StudentYearThree";
import StudentYearFour from "../pages/studentDetail/StudentYearFour";

//assign student
import AssignStudentList from "../pages/assignList/AssignStudentList";
import AssignAdd from "../pages/assignAdd/AssignAdd";
import AssignDetail from "../pages/assignDetail/AssignDetail";
import AssignEdit from "../pages/assignEdit/AssignEdit";

// restration
import RegistrationList from "../pages/registrationList/RegistrationList";
import RegistrationDetail from "../pages/registrationDetail/RegistrationDetail";

// Profile
import ProfileDetail from "../pages/profile/ProfileDetail";
import ProfileEdit from "../pages/profile/ProfileEdit";

// Document
import DocumentList from "../pages/documentList/DocumentList";
import DocumentDetail from "../pages/documentDetail/DocumentDetail";

// Setting
import SettingList from "../pages/setting/SettingList";
import SettingFaculty from "../pages/setting/settingRegistration/SettingFaculty";
import SettingFacultyDetail from "../pages/setting/settingRegistration/SettingFacultyDetail";
import SettingDepartmentDetail from "../pages/setting/settingRegistration/SettingDepartmentDetail";
import SettingDepartment from "../pages/setting/settingDepartment/SettingDepartment";
import PromoteToNextGrade from "../pages/setting/promoteToNextGrade/PromoteToNextGrade";

import FacultyList from "../pages/faculty/FacultyList";
import LoginHistory from "../pages/setting/LoginHistory";

// Notice
import NoticeList from "../pages/notice/NoticeList";
import AddNotice from "../pages/notice/AddNotice";
import EditNotice from "../pages/notice/EditNotice";
import DetailNotice from "../pages/notice/DetailNotice";

// Preregistration
import PreregistrationStudentList from "../pages/preregistrationStudent/PreregistrationStudentList";
import PreregistrationStudentListAll from "../pages/preregistrationStudent/PreregistrationStudentListAll";

import PreregistrationStudentRejected from "../pages/preregistrationStudent/PreregistrationStudentRejected";
import PreregistrationStudentDetail from "../pages/preregistrationStudent/PreregistrationStudentDetail";
import PreregistrationStudentFacultyList from "../pages/preregistrationStudent/PreregistrationStudentFacultyList";
import PreregistrationStudentDetailFacultyList from "../pages/preregistrationStudent/PreregistrationStudentDetailFacultyList";
import PreregistrationStudentStatistic from "../pages/preregistrationStudent/PreregistrationStudentStatistic";
import PreregistrationStudentStatisticTribe from "../pages/preregistrationStudent/PreregistrationStudentStatisticTribe";
import PreregistrationEdit from "../pages/preregistrationStudent/PreregistrationEdit";
import PreregistrationEditEn from "../pages/preregistrationStudent/PreregistrationEditEn";

// Code preregistration
import CodePreregistrationList from "../pages/codePreregistration/CodePreregistrationList";
import CodePreregistrationAdd from "../pages/codePreregistration/CodePreregistrationAdd";
import CodePreregistrationEdit from "../pages/codePreregistration/CodePreregistrationEdit";
import CodePreregistrationDetail from "../pages/codePreregistration/CodePreregistrationDetail";

import RegistrationStudent from "../pages/registrationStudent/RegistrationStudent";
import RegistrationAddMore from "../pages/registrationList/RegistrationAddMore";
import RegistrationStudentDetail from "../pages/registrationStudent/RegistrationStudentDetail";
import RegistrationStudentAdd from "../pages/registrationStudent/RigistrationStudentAdd";
import SettingsTribes from "../pages/setting/settingsTribes/SettingsTribes";
import SettingMasterData from "../pages/setting/SettingMasterData";
import UpdateLogs from "../pages/setting/updateLog/";
import UpdateLogsDetail from "../pages/setting/updateLog/updateLogDetail";

// grade
import GradesIndex from "../pages/grades/index";
import AddGradeForStudent from "../pages/grades/saveGrades/AddGradeForStudent";
import UpGradeforStudent from "../pages/grades/saveGrades/UpGradeforStudent";
import AllCourseDetial from "../pages/grades/sendGrades/AllCourse";
import StudentListForGrade from "../pages/grades/gradeList/StudentListForGrade";
import GradesList from "../pages/grades/gradeList/GradesList";
import GradesToPrint from "../pages/grades/gradeList/GradesToPrint";
import GradesCourseDetial from "../pages/grades/saveGrades/GradesCourseDetial";
import TapGrade from "../pages/grades/saveGrades/TapGrade";
import HistoryReceiveGrade from "../pages/grades/sendGrades/historyReceiveGrade";
import SentGradesDetail from "../pages/grades/sendGrades/sentGradedetail";
import HistoryUpdateGrade from "../pages/grades/historyUpdateGrade";
import StudentRegisterUpgrade from "../pages/grades/Upgrade/StudentRegisterUpgrade";
import HistoryReceiveGrdaedetail from "../pages/grades/historyUpdateGradeDetail";
import RegisterGradeCorrection from "../pages/grades/Upgrade/RegisterGradeCorrection";
import UpgradeStudentHistory from "../pages/grades/Upgrade/UpgradeStudentHistory";

import Adminhistory from "../pages/setting/Adminhistory";
import Facadminhistory from "../pages/setting/Facadminhistory";
import Depadminhistory from "../pages/setting/Depadminhistory";
import Studentdminhistory from "../pages/setting/Studentdminhistory";
import Teacheradminhistory from "../pages/setting/Teacheradminhistory";
import Policy from "../pages/setting/Policy";
import GradeCourseList from "../pages/grades/saveGrades/GradeCourseList";

/**
 *
 * Dashboard
 *
 */

import Dashboard from "../pages/dashboard/Dashboard";
import SettingMajor from "../pages/setting/settingMajor/SettingMajor";
import SettingMajorDetail from "../pages/setting/settingRegistration/SettingMajorDetail";

import Price from "../pages/setting/priceSetting/prices";
import AssessmentYearSetting from "../pages/setting/assessmentYear/assessmentYear";

import TimeStudy from "../pages/setting/timeStudy/TimeStudy";

import NewStudentList from "../pages/studentList/newStudentList";
import UpdatedRegister from "../pages/updatedRegister/updatedRegister";
import { TimeTable } from "../pages/timeTable/timeTable";
import { ScopusPage } from "../pages/scopus";
import StatisticPage from "../pages/statistic/Index";
import GradeClassList from "../pages/grades/gradeclass/GradeClassLists";
import GradeSendingCourseList from "../pages/grades/gradeSending/GradeSendingCourseLists";
import GradeSendingCourseDetail from "../pages/grades/gradeSending/GradeSendingCourseDetail";
import SubjectLists from "../pages/subjects/SubjectList";
import PaymentList from "../pages/payment/PaymentList";

import GradeClassDetail from "../pages/grades/gradeclass/GradeClassDetail";
import GradeSendingCourseGrade from "../pages/grades/gradeSending/GradeSendingCourseGrade";
import PrereStudentList from "../pages/prereStudent";
import LogList from "../pages/logList";
import Docnotice from "../pages/scholarshipAndJob/Docnotice";

const Main = styled.main`
  /* position: relative; */
  overflow: hidden;
  transition: all 0.15s;
  padding: 0 8px;
  margin-left: ${(props) => (props.expanded ? 240 : 64)}px;
`;
function Routes() {
  return (
    <Router>
      <Switch>
        {/* Before login routes */}
        <PublicRoute exact path="/" component={Login} />

        <PublicRoute
          exact
          path="/admindata"
          component={TabComponenttest}
        />

        {/* After login routes (has SideNav and NavBar) */}
        <Route
          render={({ location, history }) => (
            <React.Fragment>
              {/* sidenav */}
              <CustomSideNav location={location} history={history} />

              <Main>
                {/* navbar */}
                <CustomNavbar />

                {/* Contents */}
                <div
                  style={{
                    marginTop: 60,
                    backgroundColor: "#eee",
                    minHeight: "100vh",
                  }}
                >
                  {/* private routes */}
                  <PrivateRoute
                    path={Routers.COURSE_LIST + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <CourseList />}
                  />
                  <PrivateRoute
                    path={Routers.COURSE_ADD}
                    component={(props) => <CourseAdd />}
                  />
                  <PrivateRoute
                    path={Routers.COURSE_DETAIL}
                    component={(props) => <CourseDetail />}
                  />
                  <PrivateRoute
                    path={Routers.COURSE_DETAIL_DOC_LIST + "/id/:id"}
                    component={(props) => <CourseDocList />}
                  />
                  {/* Assign student */}
                  <PrivateRoute
                    path={
                      Routers.ASSIGN_STUDENT_LIST + "/limit/:limit/skip/:skip"
                    }
                    exact
                    component={(props) => <AssignStudentList />}
                  />
                  <PrivateRoute
                    path={"/policies"}
                    exact
                    component={(props) => <Policy />}
                  />
                  <PrivateRoute
                    path={
                      Routers.ASSIGN_STUDENT_ADD + "/limit/:limit/skip/:skip"
                    }
                    component={(props) => <AssignAdd />}
                  />
                  <PrivateRoute
                    path={Routers.ASSIGN_STUDENT_DETAIL + "/id/:id"}
                    component={(props) => <AssignDetail />}
                  />
                  <PrivateRoute
                    path={Routers.ASSIGN_STUDENT_EDIT + "/id/:id"}
                    component={(props) => <AssignEdit />}
                  />
                  {/* Teacher PrivateRoutes */}
                  <PrivateRoute
                    path={Routers.TEACHER_LIST}
                    component={(props) => <TeacherList />}
                  />
                  <PrivateRoute
                    path={Routers.TEACHER_ADD}
                    component={(props) => <TeacherAdd />}
                  />
                  <PrivateRoute
                    path={Routers.TEACHER_FORM_STATISTIC}
                    component={(props) => <TeacherFormStatistic />}
                  />
                  <PrivateRoute
                    path={Routers.TEACHER_FORM_PLACE_OF_BIRTH}
                    component={(props) => <TeacherFormPlaceOfBirth />}
                  />
                  <PrivateRoute
                    path={Routers.TEACHER_EDIT + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <TeacherEdit />}
                  />
                  <PrivateRoute
                    path={Routers.TEACHER_DETAIL}
                    component={(props) => <TeacherDetail />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_LIST + "/limit/:limit/skip/:skip"}
                    component={(props) => <NewStudentList />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_DETAIL + "/id/:id"}
                    component={(props) => <StudentDetail />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_ADD}
                    component={(props) => <StudentAdd />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_EDIT + "/id/:id"}
                    component={(props) => <StudentEdit />}
                  />
                  {/* New code =========================================== */}
                  <PrivateRoute
                    path={Routers.SUBJECT_LIST}
                    component={(props) => <SubjectLists />}
                  />

                  <PrivateRoute
                    path={Routers.PAYMENT_LIST}
                    component={(props) => <PaymentList />}
                  />
                  {/* <PrivateRoute
                    path="/statistic/prerestudent"
                    component={(props) => <PrereStudent />}
                  />

                  <PrivateRoute
                    path="/statistic/prerestudent"
                    component={(props) => <PrereStudent />}
                  />
                  <PrivateRoute
                    path="/statistic/inplanstudent"
                    component={(props) => <InplanStudent />}
                  />
                  <PrivateRoute
                    path="/statistic/outplanstudent"
                    component={(props) => <AllOutplanStudent />}
                  />
                  <PrivateRoute
                    path="/statistic/allstudent"
                    component={(props) => <AllStudent />}
                  />
                  <PrivateRoute
                    path="/statistic/studentbyageinplan"
                    component={(props) => <StudentByAgeInplan />}
                  />
                  <PrivateRoute
                    path="/statistic/studentbyageoutplan"
                    component={(props) => <StudentByAgeOutplan />}
                  />
                  <PrivateRoute
                    path="/statistic/studentbyageoutplancontunue"
                    component={(props) => <StudentByAgeOutplanContunue />}
                  />
                  <PrivateRoute
                    path="/statistic/studentbyageinplancontinue"
                    component={(props) => <StudentByAgeInplanContunue />}
                  /> */}
                  <PrivateRoute
                    path="/statistic"
                    component={(props) => <StatisticPage />}
                  />
                  <PrivateRoute
                    path="/pricessetting"
                    component={(props) => <Price />}
                  />
                  <PrivateRoute
                    path="/newregister"
                    exact
                    component={(props) => <UpdatedRegister />}
                  />
                  <PrivateRoute
                    path="/scopus"
                    exact
                    component={(props) => <ScopusPage />}
                  />
                  <PrivateRoute
                    path="/timetable"
                    exact
                    component={(props) => <TimeTable />}
                  />
                  {/* <PrivateRoute
                    path="/timetable/edit/:id"
                    exact
                    component={(props) => <TimeTableEdit />}
                  /> */}

                  <PrivateRoute
                    path="/timestudy"
                    exact
                    component={(props) => <TimeStudy />}
                  />
                  <PrivateRoute
                    path={Routers.ASSEMENT_YEAR_SETTING}
                    exact
                    component={(props) => <AssessmentYearSetting />}
                  />
                  {/* New code =========================================== */}
                  <PrivateRoute
                    path={Routers.STUDENT_YEAR_ONE + "/id/:id"}
                    component={(props) => <StudentYearOne />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_YEAR_TWO + "/id/:id"}
                    component={(props) => <StudentYearTwo />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_YEAR_THREE + "/id/:id"}
                    component={(props) => <StudentYearThree />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_YEAR_FOUR + "/id/:id"}
                    component={(props) => <StudentYearFour />}
                  />
                  <PrivateRoute
                    path={
                      Routers.REGISTRATIONS_LIST + "/limit/:limit/skip/:skip"
                    }
                    component={(props) => <RegistrationList />}
                  />
                  <PrivateRoute
                    path="/registration-detail"
                    component={(props) => <RegistrationDetail />}
                  />
                  {/* Document PrivateRoutes */}
                  <PrivateRoute
                    path={Routers.DOCUMENT_LIST + "/limit/:limit/skip/:skip"}
                    component={(props) => <DocumentList />}
                  />
                  <PrivateRoute
                    path={Routers.DOCUMENT_DETAIL + "/id/:id"}
                    component={(props) => <DocumentDetail />}
                  />
                  <PrivateRoute
                    path="/profile-detail"
                    component={(props) => <ProfileDetail />}
                  />
                  <PrivateRoute
                    path="/profile-edit"
                    component={(props) => <ProfileEdit />}
                  />
                  {/* setting */}
                  <PrivateRoute
                    path="/setting-list"
                    exact
                    component={(props) => <SettingList />}
                  />
                  <PrivateRoute
                    path={
                      Routers.PROMOTE_TO_NEXT_GRADE + "/limit/:limit/skip/:skip"
                    }
                    exact
                    component={(props) => <PromoteToNextGrade />}
                  />
                  {/* registration path */}
                  <PrivateRoute
                    path="/setting-faculty"
                    exact
                    component={(props) => <SettingFaculty />}
                  />
                  <PrivateRoute
                    path="/setting-faculty-detail/:id"
                    exact
                    component={(props) => <SettingFacultyDetail />}
                  />
                  <PrivateRoute
                    path="/setting-faculty-detail/:facultyId/department/:departmentId"
                    exact
                    component={(props) => <SettingDepartmentDetail />}
                  />
                  <PrivateRoute
                    path="/setting-faculty-detail/:facultyId/major/:majorId"
                    exact
                    component={(props) => <SettingMajorDetail />}
                  />
                  <PrivateRoute
                    path="/setting-major"
                    exact
                    component={(props) => <SettingMajor />}
                  />
                  {/* end registration path */}
                  <PrivateRoute
                    path={Routers.LOGIN_HISTORY + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <LogList />}
                  />
                  <PrivateRoute
                    path="/faculty-list"
                    exact
                    component={(props) => <FacultyList />}
                  />
                  <PrivateRoute
                    path={Routers.Notice_List + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <Docnotice />}
                  />
                  {/* <PrivateRoute
                    path={Routers.Notice_List + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <NoticeList />}
                  /> */}
                  <PrivateRoute
                    path="/notice-add"
                    exact
                    component={(props) => <AddNotice />}
                  />
                  <PrivateRoute
                    path="/notice-edit/:id"
                    exact
                    component={(props) => <EditNotice />}
                  />
                  <PrivateRoute
                    path="/notice-detail/:id/:type"
                    exact
                    component={(props) => <DetailNotice />}
                  />
                  {/* grades */}
                  <PrivateRoute
                    path={Routers.GRADE_INDEX + "/:page"}
                    exact
                    component={(props) => <GradesIndex />}
                  />
                  <PrivateRoute
                    path="/grades-list/limit/:limit/skip/:skip"
                    exact
                    component={(props) => <GradesList />}
                  />
                  <PrivateRoute
                    path={
                      Routers.GRADE_COURSE_LIST + "/limit/:limit/skip/:skip"
                    }
                    exact
                    component={(props) => <GradeCourseList />}
                  />
                  <PrivateRoute
                    path="/add-grade-for-student/:page"
                    component={(props) => <AddGradeForStudent />}
                  />
                  <PrivateRoute
                    path="/up-grade-for-student/:page"
                    component={(props) => <UpGradeforStudent />}
                  />
                  <PrivateRoute
                    path={Routers.GRADE_SENDING_COURSE_DETAIL}
                    component={(props) => <GradeSendingCourseDetail />}
                  />
                  <PrivateRoute
                    path={
                      Routers.GRADE_SENDING_COURSE_LIST +
                      "/limit/:limit/skip/:skip"
                    }
                    component={(props) => <GradeSendingCourseList />}
                  />
                  <PrivateRoute
                    path={Routers.GRADE_CLASS_LIST + "/limit/:limit/skip/:skip"}
                    component={(props) => <GradeClassList />}
                  />
                  <PrivateRoute
                    path={Routers.GRADE_CLASS_DETAIL}
                    component={(props) => <GradeClassDetail />}
                  />
                  <PrivateRoute
                    path={Routers.GRADE_SENDING_COURSE_GRADE}
                    component={(props) => <GradeSendingCourseGrade />}
                  />
                  {/* <PrivateRoute
                    path="/sent-grades-list/:page"
                    component={(props) => <SentGradesList />}
                  /> */}
                  <PrivateRoute
                    path={Routers.GRADE_TAPS + "/:id/"}
                    exact
                    component={(props) => <TapGrade />}
                  />
                  <PrivateRoute
                    path={Routers.GRADE_COURSE_DETAIL + ":id/"}
                    component={(props) => <GradesCourseDetial />}
                  />
                  <PrivateRoute
                    path="/send-grades/:page"
                    component={(props) => <AllCourseDetial />}
                  />
                  <PrivateRoute
                    path="/send-grades-detail/:page"
                    component={(props) => <HistoryReceiveGrade />}
                  />
                  <PrivateRoute
                    path="/grades-student-list/:page"
                    component={(props) => <StudentListForGrade />}
                  />
                  <PrivateRoute
                    path="/grades-to-report"
                    component={(props) => <GradesToPrint />}
                  />
                  <PrivateRoute
                    path="/grades-sent-detail/:id"
                    component={(props) => <SentGradesDetail />}
                  />
                  {/* <PrivateRoute
                    path="/grades-course-history/:page"
                    component={(props) => <AllCourseForHistory/>}
                  /> */}
                  <PrivateRoute
                    path="/grades-update-history/:page"
                    component={(props) => <HistoryUpdateGrade />}
                  />
                  <PrivateRoute
                    path="/grades-update-detail-history/:page"
                    component={(props) => <HistoryReceiveGrdaedetail />}
                  />
                  <PrivateRoute
                    path="/grades-upgrade-list/:page"
                    component={(props) => <StudentRegisterUpgrade />}
                  />
                  <PrivateRoute
                    path="/register_grade_correction/:page"
                    component={(props) => <RegisterGradeCorrection />}
                  />
                  <PrivateRoute
                    path="/upgrade-student-history/:page"
                    component={(props) => <UpgradeStudentHistory />}
                  />
                  <PrivateRoute
                    path="/registration_student/:page"
                    exact
                    component={(props) => <RegistrationStudent />}
                  />
                  <PrivateRoute
                    path={"/students-prere"}
                    exact
                    component={(props) => <PrereStudentList />}
                  />
                  <PrivateRoute
                    path={"/loglist"}
                    exact
                    component={(props) => <LogList />}
                  />

                  <PrivateRoute
                    path="/preregistration_student-detail"
                    exact
                    component={(props) => <PreregistrationStudentDetail />}
                  />
                  <PrivateRoute
                    path="/preregistration-student-detail-faculty-list"
                    exact
                    component={(props) => (
                      <PreregistrationStudentDetailFacultyList />
                    )}
                  />
                  <PrivateRoute
                    path="/preregistration_edit"
                    exact
                    component={(props) => <PreregistrationEdit />}
                  />
                  <PrivateRoute
                    path="/preregistration_edit_en"
                    exact
                    component={(props) => <PreregistrationEditEn />}
                  />
                  {/*Close V1 */}
                  {/* code preregistration */}
                  <PrivateRoute
                    path={Routers.CODE_PRERE_LIST + "/limit/:limit/skip/:skip"}
                    component={(props) => <CodePreregistrationList />}
                  />
                  <PrivateRoute
                    path={Routers.CODE_PRERE_ADD}
                    component={(props) => <CodePreregistrationAdd />}
                  />
                  <PrivateRoute
                    path={Routers.CODE_PRERE_EDIT + "/id/:id"}
                    component={(props) => <CodePreregistrationEdit />}
                  />
                  <PrivateRoute
                    path={Routers.CODE_PRERE_DETAIL + "/id/:id"}
                    component={(props) => <CodePreregistrationDetail />}
                  />
                  <PrivateRoute
                    path="/registration_student-detail/:page"
                    exact
                    component={(props) => <RegistrationStudentDetail />}
                  />
                  <PrivateRoute
                    path="/registration-student-add"
                    exact
                    component={(props) => <RegistrationStudentAdd />}
                  />
                  <PrivateRoute
                    path="/setting-tribes"
                    exact
                    component={(props) => <SettingsTribes />}
                  />
                  <PrivateRoute
                    path="/setting-master-data/:content/limit/:limit/skip/:skip"
                    exact
                    component={(props) => <SettingMasterData />}
                  />
                  <PrivateRoute
                    path="/setting-department"
                    exact
                    component={(props) => <SettingDepartment />}
                  />
                  <PrivateRoute
                    path={
                      Routers.SETTINGS_UPDATE_LOG + "/limit/:limit/skip/:skip"
                    }
                    exact
                    component={(props) => <UpdateLogs />}
                  />
                  <PrivateRoute
                    path="/settings/update-log-detail/:id"
                    exact
                    component={(props) => <UpdateLogsDetail />}
                  />
                  <PrivateRoute
                    path={Routers.ADMIN_HISTORY + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <Adminhistory />}
                  />
                  <PrivateRoute
                    path={Routers.FACULTY_HISTORY + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <Facadminhistory />}
                  />
                  <PrivateRoute
                    path={
                      Routers.DEPARTMENT_HISTORY + "/limit/:limit/skip/:skip"
                    }
                    exact
                    component={(props) => <Depadminhistory />}
                  />
                  <PrivateRoute
                    path={Routers.TEACHER_HISTORY + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <Teacheradminhistory />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_HISTORY + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <Studentdminhistory />}
                  />
                  {/**
                   *
                   * Dashboard
                   *
                   */}
                  <PrivateRoute
                    path="/dashboard-page"
                    exact
                    component={(props) => <Dashboard />}
                  />
                  <PrivateRoute
                    path={
                      Routers.REGISTRATIONS_ADD + "/limit/:limit/skip/:skip"
                    }
                    exact
                    component={(props) => <RegistrationAddMore />}
                  />
                </div>
              </Main>
            </React.Fragment>
          )}
        />
      </Switch>
    </Router>
  );
}

export default Routes;
