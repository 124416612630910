import { gql } from "apollo-boost";

export const RELIGIONAL_MASTERS = gql`
  query ReligionalMasters {
    religionalMasters {
      data {
        id
        name
        note
      }
    }
  }
`;
export const DELETE_RELIGINAL = gql`
  mutation DeleteReligionalMaster($where: ReligionalMasterWhereUniqueInput!) {
    deleteReligionalMaster(where: $where) {
      id
    }
  }
`;
export const CREATE_RELIGINAL = gql`
  mutation CreateReligionalMaster($data: ReligionalMasterCreateInput!) {
    createReligionalMaster(data: $data) {
      id
    }
  }
`;

export const UPDATE_RELIGIONAL = gql`
  mutation UpdateReligionalMaster(
    $data: ReligionalMasterUpdateInput!
    $where: ReligionalMasterWhereUniqueInput!
  ) {
    updateReligionalMaster(data: $data, where: $where) {
      id
    }
  }
`;
