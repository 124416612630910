import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";

/**
 *
 * @Library
 *
 */
import { useLazyQuery } from "@apollo/react-hooks";
import * as _ from "lodash";
import { useSelector } from "react-redux";
/**
 *
 * @Component
 *
 */
import { Row, Col } from "react-bootstrap";
import { CustomContainer } from "../../common";
import Empty from "../../common/Empty";
import Loading from "../../common/Loading";

/**
 *
 * @Constants
 *
 */
import Consts from "../../consts";
import Routers from "../../consts/router";
/**
 *
 * @Function
 *
 */
import { currency } from "../../common/super";

/**
 *
 * @Apollo
 *
 */
import { PREREGISTRATION_TRIBE } from "./apollo/query";

function RegistrationStudentStatisticTribe({ stdIdFilter }) {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const { history } = useReactRouter();
  var stdId = stdIdFilter === "ທັງໝົດ" ? "" : stdIdFilter;

  const [isAdmin, setIsAdmin] = useState();
  const [dataUser, setDataUser] = useState(null);
  const [dataTribes, setDataTribes] = useState([]);
  const [tibeTotals, setTibeTotals] = useState(0);

  const [loadTribesData, { loading: tribesLoading, data: tribesData }] =
    useLazyQuery(PREREGISTRATION_TRIBE);

  useEffect(() => {
    let qlWhere = {
      assessmentYear: accessmentRedux,
      statusStep_in: [
        "APPROVED",
        "ADMIN_APPROVED",
        "REQUEST",
        "REJECTED",
        "SEND_FORM",
      ],
    };
    if (stdId !== "") qlWhere = { ...qlWhere, semesterYear: stdId };
    loadTribesData({ variables: { where: qlWhere } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setDataUser(user.data);
    } else {
      history.push(`/`);
    }
  }, []);
  // console.log('tribesData: ', tribesData)

  useEffect(() => {
    if (!tribesData) return;
    setDataTribes(tribesData?.preregistrationStudentTribes?.data);
    setTibeTotals(tribesData?.preregistrationStudentTribes?.total);
  }, [tribesData]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      if (user.data.role === "ADMIN") {
        let qlWhere = {
          assessmentYear: accessmentRedux,
          statusStep_in: [
            "APPROVED",
            "ADMIN_APPROVED",
            "REQUEST",
            "REJECTED",
            "SEND_FORM",
          ],
        };
        if (stdId !== "") qlWhere = { ...qlWhere };
        loadTribesData({ variables: { where: qlWhere } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      } else {
        let qlWhere = {
          assessmentYear: accessmentRedux,
          statusStep_in: [
            "APPROVED",
            "ADMIN_APPROVED",
            "REQUEST",
            "REJECTED",
            "SEND_FORM",
          ],
        };
        if (stdId !== "") qlWhere = { ...qlWhere };
        loadTribesData({ variables: { where: qlWhere } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
    }
  }, [stdIdFilter, accessmentRedux]);

  useEffect(() => {
    let userRole = dataUser?.role;
    // check role
    if (userRole !== "ADMIN" && userRole !== "DEAN") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [dataUser]);

  return (
    <div>
      {tribesLoading && <Loading />}
      <CustomContainer style={{ marginTop: 0 }}>
        <div
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontSize: 16,
            color: Consts.FONT_COLOR_SECONDARY,
          }}
        >
          <Row>
            <Col ms={2}></Col>
            {/* <Col ms={2}>
              {chart && (
                <Chart
                  options={chart.options}
                  series={chart.series}
                  legendAlignment="center"
                  type="donut"
                  width="500"
                  height="300"
                />
              )}
            </Col> */}
            <Col sm={6}></Col>
          </Row>
        </div>

        {tribesData?.preregistrationStudentTribes?.data?.length > 0 ? (
          <div>
            {/* {(loading || called) && ""} */}
            <Row>
              <Col sm={3}>ສະແດງລາຍການທັງໜົດ {tibeTotals ?? "0"}</Col>
            </Row>
            <table className="table table-hover" style={{ width: "100%" }}>
              <tr
                style={{
                  color: "white",
                  backgroundColor: Consts.PRIMARY_COLOR,
                  textAlign: "center",
                }}
              >
                <th style={{ width: 70 }}>ລຳດັບ</th>
                <th>ຊົນເຜົ່າ</th>
                <th>ຍິງ</th>
                <th>ສະຖິຕິທັງໝົດ</th>
              </tr>
              {dataTribes?.map((item, index) => {
                if (item?.name == null) {
                  return;
                }
                return (
                  <tbody key={index}>
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                    >
                      <td>{index + 1} </td>
                      <td>{item?.name}</td>
                      <td>{currency(item?.male)}</td>
                      <td>{currency(item?.count)}</td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        ) : (
          <Empty />
        )}
        <div></div>
      </CustomContainer>
    </div>
  );
}

export default RegistrationStudentStatisticTribe;
