import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
/**
 *
 * @Libraries
 *
 */
import { Row, Col, FormCheck, Modal, Breadcrumb } from "react-bootstrap";
import * as _ from "lodash";
import queryString from "query-string";
import { useSelector } from "react-redux";
/**
 *
 * @Component
 *
 */
import RoleAdmin from "./filter/RoleAdmin";
import RoleFaculty from "./filter/RoleFaculty";
import RoleDepartment from "./filter/RoleDepartment";

/**
 *
 * @Constants
 *
 */
import {
  CustomButton,
  TableHeader,
  TableCell,
  CustomContainer,
} from "../../common";
// import consts from "../../consts";
import Routers from "../../consts/router";
import Loading from "../../common/Loading";
/**
 *
 * @Apollo
 *
 */
import { FACULTIES } from "../../apollo/faculty";
import { COURSES } from "../../apollo/course";
import { STUDENTS } from "./../../apollo/student";
import { CREATE_MANY_REGISTRATION, GRADES } from "../../apollo/registration";
/**
 *
 * @Function
 *
 */
import { messageSuccess, messageWarning } from "../../common/super";
import PaginationHelper from "../../helpers/PaginationHelper";
import {
  convertGenderNamEN,
  getUserDataFromLCStorage,
} from "../../helpers/user";
import { setGenders, currency } from "../../common/super";
import { MAJORS } from "../../apollo/major";
import { REGISTER_DEPARTMENT } from "./apollo/query";
import { CLASSROOM_MASTERS } from "../../apollo/classRoom";
import Empty from "../../common/Empty";
import addLogs from "../../helpers/AddLogs";

function RegistrationAddMore() {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const { history, location } = useReactRouter();
  const parsed = queryString?.parse(location?.state);
  const { _limit, _skip, Pagination_helper } = PaginationHelper();
  /**
   *
   * @State
   *
   */

  const [students, setStudents] = useState([]);
  /* Faculty  */
  const [dataFaculty, setDataFaculty] = useState([]);
  const [facutyId, setFacutyId] = useState(
    !parsed?.faculty ? "" : parsed?.faculty
  );
  /* Department */
  const [departmentId, setDepartmentId] = useState(
    !parsed?.department ? "" : parsed?.department
  );

  const [majorId, setMajorId] = useState(!parsed?.major ? "" : parsed?.major);
  const [dataClassroom, setDataClassroom] = useState([]);
  const [classroomId, setclassroomId] = useState(
    !parsed?.classroom ? "" : parsed?.classroom
  );
  /* Courses */
  const [dataCourses, setDataCourses] = useState([]);
  const [courseId, setCourseId] = useState("");
  /* Students */
  const [dataStudents, setDataStudents] = useState([]);
  const [totals, setTotals] = useState("");
  const [yearLevel, setYearLevel] = useState(
    !parsed?.yearLevel ? "" : parsed?.yearLevel
  );
  const [semester, setSemester] = useState(
    !parsed?.semester ? "" : parsed?.semester
  );
  const [firstNameL, setFirstNameL] = useState("");
  const [dataUser, setDataUser] = useState();
  const [ROLE, setROLE] = useState();

  const [show, setShow] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [dataGrades, setDataGrades] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /**
   *
   * @Apollo
   *
   */
  const [loadDataFaculties, { data: facultiesData }] = useLazyQuery(FACULTIES);
  const [loadCourses, { data: courseData }] = useLazyQuery(COURSES);
  const [loadStudents, { loading: studentLoading, data: studentData }] =
    useLazyQuery(STUDENTS, { fetchPolicy: "network-only" });
  const [createManyRegistration, { loading: createManyRegistrationLoading }] =
    useMutation(CREATE_MANY_REGISTRATION);

  const [loadDataDeparments, { data: departmentData }] =
    useLazyQuery(REGISTER_DEPARTMENT);
  const [loadDataMajors, { data: majorData }] = useLazyQuery(MAJORS);
  const [loadClassRooms, { data: classroomData }] =
    useLazyQuery(CLASSROOM_MASTERS);
  const [loaddataGrade, { data: registrationData }] = useLazyQuery(GRADES, {
    fetchPolicy: "network-only",
  });
  /**
   *
   * @useEffect
   *
   */
  useEffect(() => {
    loadDataFaculties({
      variables: {
        where: { isDeleted: false, assessmentYear: accessmentRedux },
      },
    });
    // loadStudents({
    //   variables: {
    //     where: { assessmentYear: accessmentRedux, isDeleted: false },
    //     skip: (_skip - 1) * _limit,
    //     first: _limit,
    //     orderBy: "createdAt_DESC"
    //   },
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (getUserDataFromLCStorage()?.data)
      setDataUser(getUserDataFromLCStorage()?.data);
    setROLE(getUserDataFromLCStorage()?.data?.role);

    if (getUserDataFromLCStorage()?.data?.role === "FAC_ADMIN")
      setFacutyId(getUserDataFromLCStorage()?.data?.faculty?.id);
    if (getUserDataFromLCStorage()?.data?.role === "DEP_ADMIN") {
      setFacutyId(getUserDataFromLCStorage()?.data?.faculty?.id);
      setDepartmentId(getUserDataFromLCStorage()?.data?.department?.id);
    }
  }, [accessmentRedux]);

  useEffect(() => {
    if (facultiesData) setDataFaculty(facultiesData?.faculties);
  }, [facultiesData, dataUser, ROLE]);

  /* set data course */
  useEffect(() => {
    if (courseData) setDataCourses(courseData?.courses?.data);
  }, [courseData]);

  /* set data student */
  useEffect(() => {
    if (studentData?.students) {
      setDataStudents(studentData?.students?.data);
      setTotals(studentData?.students?.total);
    }
  }, [studentData]);

  useEffect(() => {
    if (classroomData) setDataClassroom(classroomData?.classRooms?.data);
  }, [classroomData]);

  useEffect(() => {
    if (registrationData) setDataGrades(registrationData?.grades?.data);
  }, [registrationData]);

  useEffect(() => {
    if (!majorId) return;
    let _where = {};
    if (yearLevel !== "" && yearLevel !== "ທັງໝົດ")
      _where = { ..._where, year: yearLevel };
    if (majorId !== "") _where = { ..._where, major: majorId };
    loadClassRooms({
      variables: { where: _where },
    });
  }, [majorId, yearLevel]);

  useEffect(() => {
    if (facutyId && departmentId && majorId && classroomId && yearLevel) {
      let _where = {
        isDeleted: false,
        faculty: facutyId,
        department: departmentId,
        major: majorId,
        classRoom: classroomId,
        year: parseInt(yearLevel),
        assessmentYear: accessmentRedux,
      };
      if (semester !== "") _where = { ..._where, semester: parseInt(semester) };

      loadCourses({ variables: { where: _where } });
    }

    let qlWhere = { assessmentYear: accessmentRedux };
    if (facutyId !== "") qlWhere = { ...qlWhere, faculty: facutyId };
    if (departmentId !== "") qlWhere = { ...qlWhere, department: departmentId };
    if (majorId !== "") qlWhere = { ...qlWhere, major: majorId };
    if (yearLevel !== "" && yearLevel !== "ທັງໝົດ")
      qlWhere = { ...qlWhere, yearLevel: yearLevel };
    if (firstNameL !== "") qlWhere = { ...qlWhere, firstNameL: firstNameL };
    if (classroomId !== "") qlWhere = { ...qlWhere, classRoom: classroomId };

    if (classroomId !== "") {
      loadStudents({
        variables: {
          where: { ...qlWhere, isDeleted: false },
          skip: (_skip - 1) * _limit,
          first: _limit,
          orderBy: "createdAt_DESC",
        },
      });
    }
  }, [
    facutyId,
    departmentId,
    majorId,
    yearLevel,
    firstNameL,
    accessmentRedux,
    classroomId,
    semester,
  ]);

  useEffect(() => {
    let qlWhere = { assessmentYear: accessmentRedux };
    if (classroomId !== "") qlWhere = { ...qlWhere, classRoom: classroomId };
    if (courseId !== "") qlWhere = { ...qlWhere, course: courseId };

    loaddataGrade({ variables: { where: qlWhere } });
  }, [courseId, classroomId, accessmentRedux]);

  useEffect(() => {
    if (facutyId) {
      loadDataDeparments({
        variables: {
          where: {
            isDeleted: false,
            faculty: facutyId,
            assessmentYear: accessmentRedux,
          },
        },
      });
    }
  }, [facutyId]);

  useEffect(() => {
    if (departmentId) {
      loadDataMajors({
        variables: {
          where: {
            isDeleted: false,
            department: departmentId,
            assessmentYear: accessmentRedux,
          },
        },
      });
    }
  }, [departmentId]);

  /**
   *
   * @Function
   *
   */

  /* Function ເລືອກນັກສຶກສາ */
  const _selectCheckBox = (e) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempStudent = dataStudents?.map((student) => {
        return { ...student, isChecked: checked };
      });
      setDataStudents(tempStudent);
      if (checked === true) {
        let arr1 = [];
        for (var b = 0; b < tempStudent?.length; b++) {
          arr1.push(tempStudent[b]?.id);
        }
        setStudents(arr1);
      } else {
        setStudents([]);
      }
    } else {
      let tempStudent = dataStudents?.map((student) =>
        student?.id === name ? { ...student, isChecked: checked } : student
      );
      let arr = [...students];
      if (checked === true) {
        arr.push(name);
        setStudents(arr);
      } else {
        const arrayId = students.filter((id) => id !== name);
        setStudents(arrayId);
      }
      setDataStudents(tempStudent);
    }
  };

  // ລົງທະຽນວິຊາຮຽນຈຳນວນຫຼາຍ
  const _registration = async (values) => {
    if (courseId === "") messageWarning("ກາລຸນາເລືອວິຊາກ່ອນ!");
    if (students.length === 0) messageWarning("ຍັງບໍ່ໄດ້ເລືອກນັກຮຽນ!");
    if (students.length > 0) {
      let paramQL = {
        data: {
          students: students,
          course: courseId,
          faculty: facutyId,
          department: departmentId,
          major: majorId,
          classRoom: classroomId,
        },
      };

      try {
        await createManyRegistration({ variables: paramQL })
          .then(async (save) => {
            if (save?.data?.createManyGrade?.cannotInsert?.length === 0) {
              messageSuccess("ລົງທະບຽນສຳເລັດ");

              // add logs here

              await addLogs({
                action: "REGISTER_MANY",
                type: "STUDENT",
                status: "SUCCESS",
                students: students,
                data: students,
              });

              ///////////
              // history.push(Routers.REGISTRATIONS_LIST + "/limit/40/skip/1")
            } else {
              setStudentList(save?.data?.createManyGrade?.cannotInsert);
              handleShow(true);
            }
          })
          .catch((err) => {
            messageWarning("ລົງທະບຽນລົ້ມແຫຼວ! ນັກສຶກສາໄດ້ລົງທະບຽນວິຊານີ້ແລ້ວ");
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const _selectFaculty = (e) => {
    setFacutyId(e.target.value);
  };

  /* Select Department */
  const _selectDepartment = (e) => {
    setDepartmentId(e.target.value);
  };

  const _selectMajor = (e) => {
    setMajorId(e.target.value);
  };
  const _selectClassroom = (e) => {
    setclassroomId(e.target.value);
  };

  const _selectCourse = (e) => {
    setCourseId(e.target.value);
  };

  const _selectYear = (e) => {
    setYearLevel(e.target.value);
    // setStudents([])
  };
  const _selectSemester = (e) => {
    setSemester(e.target.value);
  };
  const _inputFirstName = (e) => {
    if (e.key === "Enter") setFirstNameL(e.target.value);
  };

  const _convertStatusRegister = (studentId) => {
    const checkData = dataGrades.filter(
      (item) => item?.student?.id === studentId
    );
    if (checkData?.length > 0)
      return <span className="text-success">ລົງທະບຽນແລ້ວ</span>;
    else return <span className="text-danger">ຍັງບໍ່ໄດ້ລົງທະບຽນ</span>;
  };

  if (createManyRegistrationLoading) return <Loading />;
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item
          href=""
          onClick={() => {
            history.push("/registration_list/limit/40/skip/1");
          }}
        >
          ລົງທະບຽນວິຊາ
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href=""
          onClick={() => {
            history.push("/registration_list/limit/40/skip/1");
          }}
        >
          ລາຍການລົງທະບຽນທັງໝົດ
        </Breadcrumb.Item>
        <Breadcrumb.Item active> ລົງທະບຽນວິຊາຮຽນຈຳນວນຫຼາຍ </Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <div>
          <Row>
            <Col sm={3}>
              <label>ຄະນະ</label>
              <select
                className="form-select"
                style={{ color: "#8D8D8D" }}
                onChange={(e) => {
                  _selectFaculty(e);
                  setStudents([]);
                }}
                value={facutyId}
                disabled={dataUser?.role !== "ADMIN" ? true : false}
              >
                <option>ທັງໝົດ</option>
                {dataFaculty &&
                  dataFaculty.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </Col>
            <Col sm={3}>
              <label>ພາກວິຊາ</label>
              <select
                className="form-select"
                style={{ color: "#8D8D8D" }}
                onChange={(e) => {
                  _selectDepartment(e);
                  setStudents([]);
                }}
                value={departmentId}
                disabled={dataUser?.role !== "DEP_ADMIN" ? false : true}
              >
                <option value="">ທັງໝົດ</option>
                {departmentData?.departments &&
                  departmentData?.departments.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
              </select>
            </Col>
            <Col sm={3}>
              <label>ສາຂາວິຊາ</label>
              <select
                className="form-select"
                style={{ color: "#8D8D8D" }}
                onChange={(e) => {
                  _selectMajor(e);
                  setStudents([]);
                }}
                value={majorId}
              >
                <option value="">ທັງໝົດ</option>
                {majorData?.majors &&
                  majorData?.majors?.data.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
              </select>
            </Col>
            <Col sm={3}>
              <label>ປີຮຽນ</label>
              <select
                className="form-select"
                style={{ color: "#8D8D8D" }}
                value={yearLevel}
                onChange={(e) => {
                  _selectYear(e);
                  setStudents([]);
                }}
              >
                <option value="ທັງໝົດ">ທັງໝົດ</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <label>ພາກຮຽນ</label>
              <select
                className="form-select"
                style={{ color: "#8D8D8D" }}
                value={semester}
                onChange={(e) => _selectSemester(e)}
              >
                <option value="">ທັງໝົດ</option>
                <option value="1">1</option>
                <option value="2">2</option>
              </select>
            </Col>
            <Col sm={3}>
              <label>ຫ້ອງນັກສຶກສາ</label>
              <select
                className="form-select"
                style={{ color: "#8D8D8D" }}
                onChange={(e) => {
                  _selectClassroom(e);
                  setStudents([]);
                }}
                value={classroomId}
              >
                <option value="">ທັງໝົດ</option>
                {dataClassroom &&
                  dataClassroom?.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
              </select>
            </Col>
            <Col sm={3}>
              <label>ວິຊາ</label>
              <select
                className="form-select"
                style={{ color: "#8D8D8D" }}
                onChange={(e) => _selectCourse(e)}
              >
                <option hidden value="">
                  ເລືອກວິຊາ...
                </option>
                <option value="">ທັງໝົດ</option>
                {dataCourses?.length > 0 &&
                  dataCourses?.map((item, index) => (
                    <option key={index} value={item?.id}>
                      {item?.title +
                        " / ອາຈານ " +
                        item?.teacher[0]?.firstname +
                        " " +
                        item?.teacher[0]?.lastname}
                    </option>
                  ))}
              </select>
            </Col>
          </Row>
        </div>
      </CustomContainer>
      {dataStudents?.length > 0 ? (
        <CustomContainer>
          <Col sm={8} className="mt-1 ms-2">
            <Row>
              <div className="custom-control custom-checkbox">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    style={{ color: "orange", bordercolor: "orange" }}
                    className="form-check-input"
                    onChange={(e) => _selectCheckBox(e)}
                    name="allSelect"
                    checked={
                      dataStudents?.filter(
                        (student) => student?.isChecked !== true
                      ).length < 1
                    }
                  />
                  ເລືອກທັງໝົດ {students?.length} ຄົນ
                </label>
              </div>
              {/* <p>ຖືກເລືອກ </p> */}
            </Row>
          </Col>
          <div>
            <Row>
              <p
                className="mt-1"
                style={{ color: "#8D8D8D", marginBottom: -50 }}
              >
                ທັງໝົດ {currency(totals)} ຄົນ
              </p>
              <div style={{ textAlign: "right" }}>
                <CustomButton
                  confirm
                  addIcon
                  title="ລົງທະບຽນ"
                  onClick={(e) => _registration(e)}
                />
              </div>
            </Row>
          </div>
          <div>
            {studentLoading ? (
              <Loading />
            ) : (
              <table
                border="1"
                bordercolor="#fff"
                style={{ width: "100%", marginBottom: 20 }}
              >
                <thead>
                  <TableHeader>
                    <th style={{ width: 70 }}>ເລືອກ</th>
                    <th style={{ width: 70 }}>ລຳດັບ</th>
                    <th>ລະຫັດນັກສຶກສາ</th>
                    <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                    <th>ຄະນະ</th>
                    <th>ພາກວິຊາ</th>
                    <th>ສາຂາວິຊາ</th>
                    <th>ປີຮຽນ</th>
                    <th>ສະຖານະລົງທະບຽນ</th>
                    {/* <th style={{ width: 150 }}>ຈັດການ</th> */}
                  </TableHeader>
                </thead>
                {dataStudents?.map((x, index) => {
                  return (
                    <tbody key={index}>
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                      >
                        <TableCell>
                          <FormCheck
                            className="ms-4"
                            name={x?.id}
                            checked={x?.isChecked || false}
                            onChange={(e) => _selectCheckBox(e)}
                          />
                        </TableCell>
                        <TableCell>
                          {index + 1 + _limit * (_skip - 1)}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x?.studentNumber}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {!x?.firstNameL || x?.firstNameL === ""
                            ? convertGenderNamEN(x?.gender) +
                              " " +
                              x?.firstNameE +
                              " " +
                              x?.lastNameE
                            : setGenders(x?.gender) +
                              " " +
                              x?.firstNameL +
                              " " +
                              x?.lastNameL}
                          {/* {setGenders(x?.gender) + " " + x?.firstNameL + " " + x?.lastNameL} */}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x?.faculty?.name}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x?.department?.name}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x?.major?.name}
                        </TableCell>
                        <TableCell>{x?.yearLevel}</TableCell>
                        <TableCell>
                          {courseId === "" ? (
                            <span className="text-warning">ເລືອກວິຊາກ່ອນ</span>
                          ) : (
                            _convertStatusRegister(x?.id)
                          )}
                        </TableCell>
                        {/* <TableCell>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            > */}
                        {/* <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    ເບິ່ງລາຍລະອຽດ
                                  </Tooltip>
                                }
                              > */}
                        {/* <div
                                  onClick={() => _studentDetail(x)}
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor: "#FFFFFF",
                                    padding: 3,
                                    width: 64,
                                    borderRadius: 4,
                                  }}
                                > */}
                        {/* <FontAwesomeIcon
                                    icon={["fas", "eye"]}
                                    color={consts.BORDER_COLOR}
                                  />{" "}
                                </div> */}
                        {/* </OverlayTrigger>
                            </div>
                          </TableCell> */}
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            )}

            {Pagination_helper(
              totals,
              Routers.REGISTRATIONS_ADD,
              `faculty=${facutyId}&&department=${departmentId}&&major=${majorId}&&classroom=${classroomId}&&yearLevel=${yearLevel}`
            )}
          </div>
        </CustomContainer>
      ) : (
        <CustomContainer className="text-center">
          <h4>
            <strong style={{ color: "#666" }}>
              ເລືອກໂຕກອງເພື່ອສະແດງຂໍ້ມູນນັກສຶກສາ
            </strong>
          </h4>
          <Empty />
        </CustomContainer>
      )}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>ລາຍຊື່ນັກສຶກສາທີ່ລົງທະບຽນແລ້ວ</Modal.Header>
        <Modal.Body>
          <table className="table table-hover" id="ExportData">
            <thead>
              <tr>
                <th width="10%" className="center" style={{ color: "#ffffff" }}>
                  ລຳດັບ
                </th>
                <th style={{ color: "#ffffff" }}>ຊື່</th>
                <th style={{ color: "#ffffff" }}>ນາມສະກຸນ</th>
                <th style={{ color: "#ffffff" }}>ລະຫັດນັກສຶກສາ</th>
              </tr>
            </thead>
            <tbody>
              {studentList?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="center">{index + 1}</td>
                    <td> {item?.firstNameL} </td>
                    <td> {item?.lastNameL} </td>
                    <td className="center">{item?.userId}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RegistrationAddMore;
