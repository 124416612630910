import { Typography } from "@mui/material";
import React from "react";

export const getActionTypeTitle = (actionType) => {
  if (actionType === "CREATE") {
    return (
      <Typography
        sx={{
          color: "#4361ee",
        }}
      >
        ເພິ່ມໃຫມ່
      </Typography>
    );
  } else if (actionType === "UPDATE") {
    return (
      <Typography
        sx={{
          color: "#43aa8b",
        }}
      >
        ເເກ້ໄຂ
      </Typography>
    );
  } else if (actionType === "DELETE") {
    return (
      <Typography
        sx={{
          color: "#bc4749",
        }}
      >
        ລົບ
      </Typography>
    );
  }
};

export default getActionTypeTitle