import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const PasswordModal = () => {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [visibilityTimeout, setVisibilityTimeout] = useState(null);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = () => {
    if (password === "slms@2024") {
      setError("");
      setOpen(false);
      // Place your login success logic here (e.g., redirecting to a dashboard)
    } else {
      setError("Incorrect password. Please try again.");
    }
  };

  const togglePasswordVisibility = () => {
    if (!showPassword) {
      setShowPassword(true);
      // Set timeout to hide password after 5 seconds
      const timeout = setTimeout(() => {
        setShowPassword(false);
      }, 5000);
      setVisibilityTimeout(timeout);
    } else {
      setShowPassword(false);
      if (visibilityTimeout) {
        clearTimeout(visibilityTimeout);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (visibilityTimeout) {
        clearTimeout(visibilityTimeout);
      }
    };
  }, [visibilityTimeout]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Enter Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the password to proceed.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          error={!!error}
          helperText={error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePasswordVisibility}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordModal;
