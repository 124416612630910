import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import "./index.css";
import { NEW_API_URL } from "../../common/contrant";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
const LaoDoc = () => {
  const [show, setShow] = useState(false);
  const [laodoc, setLaodoc] = useState([]); // Initialize as an empty array
  const [confirmDeleteShow, setConfirmDeleteShow] = useState(false); // State for confirmation modal
  const [itemToDelete, setItemToDelete] = useState(null); // State to hold the ID of the item to delete

  const [newTitle, setNewTitle] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [newType, setNewType] = useState("Research");
  const [newDescription, setNewDescription] = useState("");
  const [newAuthor, setNewAuthor] = useState("");
  const [newFile, setNewFile] = useState(null);
  const [newPublisher, setNewPublisher] = useState("");

  const [type, setType] = useState("");
  const [currentId, setCurrentId] = useState(null); // To track the ID of the document being updated

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(16); // Show 4 items per page

  const [imagePreview, setImagePreview] = useState("");

  // State for checkboxes
const [checkboxState, setCheckboxState] = useState({ isCheckedTrue: false, isCheckedFalse: false });

// Checkbox change handlers
const handleCheckboxChange = (e) => {
  const { name, checked } = e.target;
  
  if (name === "checkboxtrue") {
    setCheckboxState({ isCheckedTrue: checked, isCheckedFalse: false }); // Uncheck the false checkbox
  } else if (name === "checkboxfalse") {
    setCheckboxState({ isCheckedTrue: false, isCheckedFalse: checked }); // Uncheck the true checkbox
  }
};

  useEffect(() => {
    fetchLaodoc();
  }, [type]);

  const fetchLaodoc = async () => {
    try {
      const response = await axios.get(
        `${NEW_API_URL}laodoc?limit=200&type=${type}` // Use selected limit
      );
      setLaodoc(response.data.researchDocs || []); // Ensure laodoc is set to the researchDocs array
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAddNew = () => {
    setShow(true);
    setCurrentId(null); // Reset currentId when adding new
    clearForm();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setNewImage(file);
    // Create a preview URL for the selected image
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    } else {
      setImagePreview(""); // Clear the preview if no file is selected
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setNewFile(file);
  };

   

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("title", newTitle);
    formData.append("type", newType);
    formData.append("description", newDescription);
    formData.append("author", newAuthor);
    formData.append("publisher", newPublisher);
    formData.append("file", newFile);
    formData.append("image", newImage);

    const data = {
      title: newTitle,
      description: newDescription,
      author: newAuthor,
      publisher: newPublisher,
    };

    try {
      let response;
      if (currentId) {
        if (!newTitle || !newDescription || !newPublisher) {
          alert("ກະລຸນາປ້ອນໃຫ້ຄົບ!!");
          return;
        }
        response = await axios.put(
          NEW_API_URL + "laodoc?id=" + currentId,
          data
        );

        if (response.status === 200) {
          setLaodoc(
            laodoc.map((item) =>
              item._id === currentId ? response.data : item
            )
          );
        } else {
          console.error("Failed to update document: ", response.statusText);
        }
      } else {
        if (
          !newTitle ||
          !newDescription ||
          !newPublisher ||
          !newType ||
          !newImage ||
          !newFile
        ) {
          alert("ກະລຸນາປ້ອນໃຫ້ຄົບ!!");
          return;
        }

        if (!checkboxState.isCheckedTrue && !checkboxState.isCheckedFalse) {
          alert("ກະລຸນາເລືອກໃຫ້ແຈ້ງເຕືອນ ຫຼື ບໍ່!");
          return;
        }

        response = await axios.post(NEW_API_URL + "laodoc", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setLaodoc([...laodoc, response.data]);
      }

      if (checkboxState.isCheckedTrue) {
        await sendNotification();
      }
    } catch (error) {
      console.error(
        "Error during save operation: ",
        error.response || error.message
      );
    } finally {
      // Cleanup logic is executed whether the operation was successful or failed
     
      setImagePreview("");
      clearForm();
      setCheckboxState({ isCheckedTrue: false, isCheckedFalse: false });
      setShow(false);
    }
  };

  const handleUpdateClick = (item) => {
    setCurrentId(item._id); // Set the current ID for updating
    setNewTitle(item.title);
    setNewType(item.type);
    setNewDescription(item.description);
    setNewAuthor(item.author);
    setNewPublisher(item.publisher);
    setShow(true); // Show the modal
  };

  const handleDelete = (id) => {
    setItemToDelete(id); // Set the item to delete
    setConfirmDeleteShow(true); // Show the confirmation modal
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(
        NEW_API_URL + "laodoc?id=" + itemToDelete
      );
      if (response.status === 200) {
        setLaodoc(laodoc.filter((item) => item._id !== itemToDelete));
        setConfirmDeleteShow(false); // Hide the confirmation modal
        setItemToDelete(null); // Reset itemToDelete
      } else {
        console.error("Failed to delete document: ", response.statusText);
      }
    } catch (error) {
      console.error(
        "Error during delete operation: ",
        error.response || error.message
      );
    }
  };

  const clearForm = () => {
    setNewTitle("");
    setNewImage("");
    setNewType("research");
    setNewDescription("");
    setNewAuthor(null);
    setNewFile("");
    setNewPublisher("");
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = laodoc.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(laodoc.length / itemsPerPage);

  const sendNotification = async () => {
    await axios.post(NEW_API_URL + "notification/send", {
      title:
        newType === "research"
          ? "ບົດຄົ້ນຄວ້າອອກໃຫມ່"
          : newType === "general"
          ? "ຄວາມຮູ້ທົ່ວໄປອອກໃຫມ່"
          : newType === "special"
          ? "ວິຊາສະເພາະອອກໃຫມ່"
          : newType === "government"
          ? "ຂະແໜ່ງການພັກລັດອອກໃຫມ່"
          : "ນິຕິກຳອອກໃຫມ່",
      body: "",
      path:
        newType === "research"
          ? "research"
          : newType === "general"
          ? "general"
          : newType === "special"
          ? "special"
          : newType === "government"
          ? "government"
          : "legislation",
    });
  };

  return (
    <div className="body px-5 py-3">
      <div className="d-flex justify-content-end">
        <Button
          variant="success"
          className="d-flex align-items-center"
          onClick={handleAddNew}
        >
          <i className="fas fa-plus-circle me-2"></i> ເພີ່ມໃຫມ່
        </Button>
      </div>
      <select
        className="form-control"
        value={type}
        onChange={(e) => setType(e.target.value)}
      >
        <option value="research">ບົດຄົ້ນຄວ້າ</option>
        <option value="general">ຄວາມຮູ້ທົ່ວໄປ</option>
        <option value="special">ວິຊາສະເພາະ</option>
        <option value="government">ຂະແໜ່ງການພັກລັດ</option>
        <option value="legislation">ນິຕິກຳ</option>
      </select>

      <div className="mt-3">
        <Row>
          {currentItems.length > 0 ? (
            currentItems.map((item) => (
              <Col key={item._id} xs={12} md={6} lg={3} xl={3}>
                <div
                  className="card p-3 mt-3 position-relative custom-card "
                  style={{ height: "360px" }}
                >
                  <div className="rounded border">
                    <img
                      src={item.image}
                      alt={item.title}
                      className="card-img-top custom-img"
                    />
                  </div>
                  <div className="card-body p-2">
                    <div className="text-center mb-2">
                      <h5 className="card-title">{item.title}</h5>
                      <p className="card-text card-description">
                        {item.description}
                      </p>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                      <Button
                        type="button"
                        className="btn btn-warning me-2"
                        onClick={() => handleUpdateClick(item)}
                      >
                        <i className="fas fa-edit me-1"></i> {/* Edit icon */}
                        ແກ້ໄຂ
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDelete(item._id)}
                      >
                        <i className="fas fa-trash me-1"></i> {/* Trash icon */}
                        ລຶບ
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <p>ບໍ່ມີລາຍການ.</p>
          )}
        </Row>
      </div>
      <div className="d-flex justify-content-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index}
            variant={currentPage === index + 1 ? "primary" : "secondary"}
            onClick={() => setCurrentPage(index + 1)}
            className="mx-1"
          >
            {index + 1}
          </Button>
        ))}
      </div>

      {/* Add/Edit Modal */}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{currentId ? "ແກ້ໄຂ" : "ເພີ່ມ"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Only show the Select Type dropdown if adding a new document */}
            {!currentId && (
              <Form.Group controlId="formCategory">
                <Form.Label>ປະເພດບົດ</Form.Label>
                <Form.Control
                  as="select"
                  value={newType}
                  onChange={(e) => setNewType(e.target.value)}
                  required
                >
                  <option value="research">ບົດຄົ້ນຄວ້າ</option>
                  <option value="general">ຄວາມຮູ້ທົ່ວໄປ</option>
                  <option value="special">ວິຊາສະເພາະ</option>
                  <option value="government">ຂະແໜ່ງການພັກລັດ</option>
                  <option value="legislation">ນິຕິກຳ</option>
                </Form.Control>
              </Form.Group>
            )}

            {/* Always show these fields when updating or adding a new document */}
            <Form.Group controlId="formTitle">
              <Form.Label>ຫົວຂໍ້</Form.Label>
              <Form.Control
                type="text"
                placeholder="ປ້ອນຫົວຂໍ້"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label>ລາຍລະອຽດ</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="ປ້ອນລາຍລະອຽດ"
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAuthor">
              <Form.Label>ຜູ້ຂຽນ</Form.Label>
              <Form.Control
                type="text"
                placeholder="ປ້ອນຜູ້ຂຽນ"
                value={newAuthor}
                onChange={(e) => setNewAuthor(e.target.value)}
                // required
              />
            </Form.Group>

            {/* Only show these fields when adding a new document */}
            {!currentId && (
              <>
                <Form.Group controlId="formImage">
                  <Form.Label>ຮູບ</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={handleImageChange}
                    required
                  />
                  {imagePreview && ( // Display image preview
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        width: "100%",
                        marginTop: "10px",
                        borderRadius: "5px",
                      }}
                    />
                  )}
                </Form.Group>
                <Form.Group controlId="formFile">
                  <Form.Label>ໄຟເອກະສານ</Form.Label>
                  <Form.Control
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    required
                  />
                </Form.Group>
              </>
            )}

            <Form.Group controlId="formPublisher">
              <Form.Label>ຜູ້ເຜີຍແຜ່</Form.Label>
              <Form.Control
                type="text"
                placeholder="ປ້ອນຜູ້ເຜີຍແຜ່"
                value={newPublisher}
                onChange={(e) => setNewPublisher(e.target.value)}
                required
              />
            </Form.Group>

            {!currentId && (
              <>
                <Box className="p-3 border border-danger m-3">
                  <Box className="py-2">ໃຫ້ແຈ້ງເຕືອນ ຫຼື ບໍ່*</Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkboxtrue"
                          checked={checkboxState.isCheckedTrue}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="ແຈ້ງເຕືອນ"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkboxfalse"
                          checked={checkboxState.isCheckedFalse}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="ບໍ່ແຈ້ງເຕືອນ"
                    />
                  </Box>
                </Box>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            ປິດ
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {currentId ? "ບັນທຶກ" : "ບັນທຶກ"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        show={confirmDeleteShow}
        onHide={() => setConfirmDeleteShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>ຢືນຢັນການລົບ</Modal.Title>
        </Modal.Header>
        <Modal.Body>ທ່ານແນ່ໃຈບໍ່ວ່າຢາກລົບ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setConfirmDeleteShow(false)}
          >
            ຍົກເລີກ
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            ລຶບ
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LaoDoc;
