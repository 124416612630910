import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_KEY, NEW_API_URL } from '../../../common/contrant';
import { useSelector } from 'react-redux'



const Price = () => {

  const userData = JSON.parse(localStorage.getItem('user'));
  const accessmentRedux = useSelector((state) => state.accessment.value)

  // const url = "http://18.218.102.83:8000/api/v1/pricecategory";
  const url = NEW_API_URL + "pricecategory";

  const [titleLa, setTitleLa] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [price, setPrice] = useState('');
  const [credit, setCredit] = useState(false);
  const [data, setData] = useState([]);
  const [faculty, setFaculty] = useState(null);
  const [titleLaError, setTitleLaError] = useState('');
  const [titleEnError, setTitleEnError] = useState('');
  const [priceError, setPriceError] = useState('');

  const fetchData = async () => {
    try {
      setData([]);
      const facId = userData?.data?.faculty?.id || "";

      const response = await axios.get(`${url}/${facId}`, {
        headers: {
          api_key: API_KEY
        }
      });
      setData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Get user data from local storage

    console.log(userData.data)
    // Access the user data
    if (userData) {
      setFaculty(userData?.data?.faculty?.id);
    }
  }, []);


  const addData = async (data) => {
    try {

      const response = await axios.post(url, data, {
        headers: {
          api_key: API_KEY
        }
      });
      if (response.data.success) {
        setShowSuccess(true)

      }
      else {
        alert('Add data failed');
      }
    } catch (error) {
      console.error(error);
      alert('Add data failed');
    }
  };

  useEffect(() => {
    fetchData();
  }, [faculty]);

  const handleSubmit = async () => {
    setTitleLaError('');
    setTitleEnError('');
    setPriceError('');

    let isValid = true;

    if (titleLa.trim() === '') {
      setTitleLaError('ກະລຸນາປ້ອນຂໍ້ມຸນ');
      isValid = false;
    }

    if (titleEn.trim() === '') {
      setTitleEnError('ກະລຸນາປ້ອນຂໍ້ມຸນ');
      isValid = false;
    }

    if (price === '') {
      setPriceError('ກະລຸນາປ້ອນຂໍ້ມຸນ');
      isValid = false;
    }

    if (isValid) {
      const newData = {
        title_la: titleLa,
        title_en: titleEn,
        price: price,
        isCredit: credit,
        facultyId: faculty,
        assementYear: accessmentRedux,
      };


      await addData(newData);
      await fetchData();
      setTitleLa('');
      setPrice('');
      setTitleEn('');
      setCredit(false);
    }
  };




  const [showDelete, setShowDelete] = useState(false)
  const [selectID, setSelectID] = useState("")
  const [showSuccess, setShowSuccess] = useState(false)


  const ConfirmDelete = () => (
    <div className='my-modal'>
      <div className='modal-content w-25 p-5'>
        <p > ທ່ານຕ້ອງການລົບແທ້ບໍ່</p>
        <div className='row'>
          <div className='col-6'>
            <button onClick={() => setShowDelete(false)} className='newbt-sm'>ຍົກເລີກ</button>
          </div>
          <div className='col-6'>
            <button onClick={() => {
              deleteRow(selectID)
              setShowDelete(false)
            }} className='newbt-sm bg-danger'>ຕົກລົງ</button>
          </div>
        </div>
      </div>
    </div>
  )


  const SuccessDialog = () => (
    <div className='my-modal'>
      <div className='modal-content w-25 p-5'>
        <p>ສຳເລັດ </p>
        <img className='w-25 mb-3' src='/success.png' />
        <button onClick={() => setShowSuccess(false)} className='newbt-sm'>ຕົກລົງ</button>
      </div>
    </div>
  )


  const deleteRow = async (id) => {

    try {

      const fullUrl = url + "/" + id
      await axios.delete(fullUrl, {
        headers: {
          api_key: API_KEY
        }
      });
      await fetchData();
      setShowSuccess(true)
    } catch (error) {


      console.error(error);


    }
  };

  const PermissionDenine = () => (
    <div class="pn">
      <h1>ຂໍອະໄພ</h1>
      <p>ທ່ານບໍ່ມິສິດເຂົ້າຈັດການຫນ້ານີ້</p>
    </div>
  )


  return (
    <div className="w-100 mt-5 pt-5 bg-white fh">
      <div className="row justify-content-center">

        {showDelete && (

          <ConfirmDelete />

        )}


        {showSuccess && (
          <SuccessDialog />
        )}


        {faculty === undefined && (
          <PermissionDenine />
        )}
        {faculty !== undefined && (
          <div className='container text-center'>
            <h3>ຈັດການລາຍການຈ່າຍຄ່າຮຽນ</h3>
            <div className='row text-start'>
              <div className="col-3 mt-3 ms-3">
                <label htmlFor="titleLa" className="form-label">ຫົວຂໍ້ (ພາສາລາວ)</label>
                <input
                  type="text"
                  id="titleLa"
                  className="form-control"
                  value={titleLa}
                  onChange={(e) => setTitleLa(e.target.value)}
                />
                {titleLaError && <div className="text-danger">{titleLaError}</div>}

                <label htmlFor="titleEn" className="form-label">ຫົວຂໍ້ (ພາສາອັງກິດ)</label>
                <input
                  type="text"
                  id="titleEn"
                  className="form-control"
                  value={titleEn}
                  onChange={(e) => setTitleEn(e.target.value)}
                />
                {titleEnError && <div className="text-danger">{titleEnError}</div>}

                <label htmlFor="price" className="form-label">ລາຄາ</label>
                <input
                  type="number"
                  id="price"
                  className="form-control"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                {priceError && <div className="text-danger">{priceError}</div>}

                <div className="ms-5 mt-3">
                  <input
                    type="checkbox"
                    id="credit"
                    className="form-check-input mr-3"
                    checked={credit}
                    onChange={(e) => setCredit(e.target.checked)}
                  />
                  <label htmlFor="credit" className="form-label ms-2">ຫນ່ວຍກິດ</label>
                </div>

                <div className="text-center mt-">
                  <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary m-3 px-4 py-2">ເພີ່ມ</button>
                </div>
              </div>

              <div className="col-8">
                <table className="table mt-4 table-striped">
                  <thead>
                    <tr>
                      <th className="text-center" colSpan={1}>ລຳດັບ</th>
                      <th className="text-center" colSpan={2}>ຫົວຂໍ້ (LA)</th>
                      <th className="text-center" colSpan={2}>ຫົວຂໍ້ (EN)</th>
                      <th className="text-center" colSpan={2}>ລາຄາ</th>
                      <th className="text-center" colSpan={2}>ສົກຣຽນ</th>
                      <th className="text-center" colSpan={2}>ປະເພດ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 && (
                      data.map((item, index) => (
                        <tr key={index}>
                          <td colSpan={1} className="text-center">{index + 1}</td>
                          <td colSpan={2} className="text-center">{item.title_la}</td>
                          <td colSpan={2} className="text-center">{item.title_en}</td>
                          <td colSpan={2} className="text-center">{item.price}</td>
                          <td colSpan={2} className="text-center">{item.assementYear}</td>
                          <td colSpan={2} className="text-center ps-3">    {item.isCredit ? "ຫນ່ວຍກິດ" : "ອຶ່ນໆ"}</td>
                          <td className="hv text-center"
                            onClick={() => {
                              setSelectID(item._id)
                              deleteRow(item._id);
                            }}
                          >
                            <img
                              className="icon"
                              src="https://icon-library.com/images/delete-icon-png/delete-icon-png-19.jpg"
                              alt="Delete"
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>


            </div>
          </div>


        )}

      </div>
    </div>
  );
};

export default Price;
