import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import axios from "axios";
import { Alert } from "@mui/material";

/**
 *
 * @Libraries
 *
 */
import {
  Row,
  Col,
  FormCheck,
  Modal,
  Breadcrumb,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import * as _ from "lodash";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
/**
 *
 * @Component
 *
 */

/**
 *
 * @Constants
 *
 */
import { CustomButton, TableHeader, TableCell } from "../../../common";
// import consts from "../../consts";
import Routers from "../../../consts/router";
import Consts from "../../../consts";
import Loading from "../../../common/Loading";
/**
 *
 * @Apollo
 *
 */
import { FACULTIES } from "../../../apollo/faculty";
import { STUDENTS_PROMOTE_TO_NEXT_GRADE } from "../../../apollo/student";
import { CREATE_MANY_PROMOTE_TO_NEXT_GRADE } from "../apollo/mutation";
/**
 *
 * @Function
 *
 */
import {
  messageSuccess,
  messageWarning,
  setGenders,
} from "../../../common/super";
import PaginationHelper from "../../../helpers/PaginationHelper";
import {
  convertGenderNamEN,
  getUserDataFromLCStorage,
} from "../../../helpers/user";
import { currency } from "../../../common/super";
import { DEPARTMENTS } from "../apollo/query";
import { MAJORS } from "../../../apollo/major";
import { CLASSROOM_MASTERS } from "../../../apollo/classRoom";
import NewPromoteToNextGrade from "./newPromoteToNextGrade";
import { API_KEY, NEW_API_URL } from "../../../common/contrant";
import { USER_KEY } from "../../../consts";

function PromoteToNextGrade() {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;

  const accessmentRedux = useSelector((state) => state.accessment.value);
  const { history, location } = useReactRouter();
  const parsed = queryString?.parse(location?.state);
  const { _limit, _skip, Pagination_helper } = PaginationHelper();
  /**
   *
   * @State
   *
   */

  const [students, setStudents] = useState([]);

  /* Faculty  */
  const [dataFaculties, setDataFaculties] = useState([]);
  const [facultyId, setFacultyId] = useState(
    !parsed?.faculty ? "" : parsed?.faculty
  );

  const [dataDepartments, setDataDepartments] = useState([]);
  const [departmentId, setDepartmentId] = useState(
    !parsed?.department ? "" : parsed?.department
  );

  const [dataMajors, setDataMajors] = useState([]);
  const [majorId, setMajorId] = useState(!parsed?.major ? "" : parsed?.major);

  const [dataClassRooms, setDataClassRooms] = useState([]);
  const [classRoomId, setClassRoomId] = useState(
    !parsed?.classRoom ? "" : parsed?.classRoom
  );

  const [yearLevel, setYearLevel] = useState(
    !parsed?.yearLevel ? "" : parsed?.yearLevel
  );

  const [studentNumber, setStudentNumber] = useState(
    !parsed?.studentNumber ? "" : parsed?.studentNumber
  );
  const [studentName, setStudentName] = useState(
    !parsed?.studentName ? "" : parsed?.studentName
  );
  /* Students */
  const [dataStudents, setDataStudents] = useState([]);
  const [totals, setTotals] = useState("");
  const [ROLE, setROLE] = useState();
  const [confirmPromoteToNextGrade, setConfirmPromoteToNextGrade] =
    useState(false);

  const showConfirm = () => setConfirmPromoteToNextGrade(true);
  const closeConfirm = () => setConfirmPromoteToNextGrade(false);

  /**
   *
   * @Apollo
   *
   */
  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTIES);
  const [loadDepartments, { data: departmentData }] = useLazyQuery(DEPARTMENTS);
  const [loadMajors, { data: majorData }] = useLazyQuery(MAJORS);
  const [loadClassRooms, { data: classroomData }] =
    useLazyQuery(CLASSROOM_MASTERS);
  const [loadStudents, { loading: studentLoading, data: studentData }] =
    useLazyQuery(STUDENTS_PROMOTE_TO_NEXT_GRADE, {
      fetchPolicy: "network-only",
    });
  const [
    createManyPromoteToNextGrade,
    { loading: createManyPromoteToNextGradeLoading },
  ] = useMutation(CREATE_MANY_PROMOTE_TO_NEXT_GRADE);

  /**
   *
   * @useEffect
   *
   */
  useEffect(() => {
    loadStudents({
      variables: {
        where: {
          assessmentYear: accessmentRedux,
          isDeleted: false,
          isPromoteToNextGrade: false,
        },
        skip: (_skip - 1) * _limit,
        first: _limit,
        orderBy: "createdAt_DESC",
      },
    });
    setROLE(getUserDataFromLCStorage()?.data?.role);
    if (getUserDataFromLCStorage()?.data?.role === "FAC_ADMIN") {
      setFacultyId(getUserDataFromLCStorage()?.data?.faculty?.id);
    } else if (getUserDataFromLCStorage()?.data?.role === "DEP_ADMIN") {
      setFacultyId(getUserDataFromLCStorage()?.data?.faculty?.id);
      setDepartmentId(getUserDataFromLCStorage()?.data?.department?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accessmentRedux) {
      loadFaculties({
        variables: {
          where: {
            assessmentYear: accessmentRedux,
            isDeleted: false,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessmentRedux]);

  useEffect(() => {
    if (!facultyData) return;
    setDataFaculties(facultyData?.faculties);
  }, [facultyData]);

  useEffect(() => {
    setDataDepartments(departmentData?.departments);
  }, [departmentData]);

  useEffect(() => {
    if (classroomData) setDataClassRooms(classroomData?.classRooms?.data);
  }, [classroomData]);

  useEffect(() => {
    setDataMajors(majorData?.majors?.data);
  }, [majorData]);

  useEffect(() => {
    if (facultyId !== "") {
      loadDepartments({
        variables: {
          where: {
            isDeleted: false,
            faculty: facultyId,
            assessmentYear: accessmentRedux,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facultyId]);
  useEffect(() => {
    if (departmentId !== "") {
      loadMajors({
        variables: {
          where: {
            isDeleted: false,
            department: departmentId,
            assessmentYear: accessmentRedux,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);
  useEffect(() => {
    if (majorId !== "") {
      let _where = { major: majorId };
      if (yearLevel !== "") _where = { ..._where, year: yearLevel };
      loadClassRooms({
        variables: { where: _where },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [majorId, yearLevel]);

  /* set data student */
  useEffect(() => {
    if (studentData?.students) {
      setDataStudents(studentData?.students?.data);
      setTotals(studentData?.students?.total);
    }
  }, [studentData]);

  const loaddata = async () => {
    let qlWhere = {
      isDeleted: false,
      isPromoteToNextGrade: false,
      assessmentYear: accessmentRedux,
    };
    if (facultyId !== "") qlWhere = { ...qlWhere, faculty: facultyId };
    if (departmentId !== "") qlWhere = { ...qlWhere, department: departmentId };
    if (majorId !== "") qlWhere = { ...qlWhere, major: majorId };
    if (classRoomId !== "") qlWhere = { ...qlWhere, classRoom: classRoomId };
    if (yearLevel !== "" && yearLevel !== "ທັງໝົດ")
      qlWhere = { ...qlWhere, yearLevel: yearLevel ?? "" };

    if (studentNumber) {
      loadStudents({
        variables: {
          where: { ...qlWhere, studentNumber: studentNumber },
          skip: (_skip - 1) * _limit,
          first: _limit,
          orderBy: "createdAt_DESC",
        },
      });
    } else if (studentName) {
      loadStudents({
        variables: {
          where: { ...qlWhere, firstNameL: studentName },
          skip: (_skip - 1) * _limit,
          first: _limit,
          orderBy: "createdAt_DESC",
        },
      });
    } else {
      loadStudents({
        variables: {
          where: qlWhere,
          skip: (_skip - 1) * _limit,
          first: _limit,
          orderBy: "createdAt_DESC",
        },
      });
    }
  };

  useEffect(() => {
    loaddata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    facultyId,
    departmentId,
    majorId,
    classRoomId,
    yearLevel,
    studentNumber,
    studentName,
    accessmentRedux,
  ]);

  /**
   *
   * @Function
   *
   */
  const _selectFaculty = (e) => {
    setFacultyId(e.target.value);
    setDepartmentId("");
    setDataDepartments([]);
    setMajorId("");
    setDataMajors([]);
    setClassRoomId("");
    setDataClassRooms([]);
  };
  const _onSelectedDepartment = (e) => {
    setDepartmentId(e.target.value);
    setMajorId("");
    setDataMajors([]);
    setClassRoomId("");
    setDataClassRooms([]);
  };
  const _onSelectedMajor = (e) => {
    setMajorId(e.target.value);
    setClassRoomId("");
    setDataClassRooms([]);
  };
  const _onSelectedClassRoom = (e) => {
    setClassRoomId(e.target.value);
  };

  const _onSelectedYear = (e) => {
    setYearLevel(e.target.value);
  };
  const _keyStudentNumber = (e) => {
    if (e.key === "Enter") {
      setFacultyId("");
      setDepartmentId("");
      setYearLevel("");
      setClassRoomId("");
      setStudentName("");
      setStudentNumber(e.target.value);
    }
  };
  const _keyStudentName = (e) => {
    if (e.key === "Enter") {
      setFacultyId("");
      setDepartmentId("");
      setYearLevel("");
      setClassRoomId("");
      setStudentNumber("");
      setStudentName(e.target.value);
    }
  };

  /* Function ເລືອກນັກສຶກສາ */
  const _selectCheckBox = (e) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempStudent = dataStudents?.map((student) => {
        return { ...student, isChecked: checked };
      });
      let tempStudent2 = JSON.parse(JSON.stringify(tempStudent));
      setDataStudents(tempStudent);
      if (checked === true) {
        let arr1 = [];
        for (var b = 0; b < tempStudent2?.length; b++) {
          delete tempStudent2[b]?.__typename;
          delete tempStudent2[b]?.isChecked;
          arr1.push({
            ...tempStudent2[b],
            faculty: tempStudent2[b]?.faculty?.id,
            department: tempStudent2[b]?.department?.id,
            major: tempStudent2[b]?.major?.id,
            classRoom: tempStudent2[b]?.classRoom?.id,
          });
        }
        setStudents(arr1);
      } else {
        setStudents([]);
      }
    } else {
      let dataArray = [...students];
      for (var i = 0; i < dataStudents?.length; i++) {
        if (dataStudents[i]?.id === name) {
          delete dataStudents[i]?.__typename;
          dataArray.push({
            ...dataStudents[i],
            faculty: dataStudents[i]?.faculty?.id,
            department: dataStudents[i]?.department?.id,
            major: dataStudents[i]?.major?.id,
            classRoom: dataStudents[i]?.classRoom?.id,
          });
          setStudents(dataArray);
        }
      }
      if (checked === true) {
        setStudents(dataArray);
      } else {
        const arrayId = students.filter((student) => student?.id !== name);
        setStudents(arrayId);
      }
      let tempStudent = dataStudents?.map((student) =>
        student?.id === name ? { ...student, isChecked: checked } : student
      );
      setDataStudents(tempStudent);
    }
  };

  function getNextAcademicYear(currentAcademicYear) {
    const [startYear, endYear] = currentAcademicYear.split(" - ").map(Number);

    if (Number.isNaN(startYear) || Number.isNaN(endYear)) {
      throw new Error("Invalid academic year format");
    }

    const nextStartYear = endYear;
    const nextEndYear = endYear + 1;
    const nextAcademicYear = `${nextStartYear} - ${nextEndYear}`;

    return nextAcademicYear;
  }

  const [promoteLoading, setPromoteLoading] = useState(false);
  const [showSuccess, setSHowSuccess] = useState(false);

  const [showFailed, setShowFailed] = useState(false);

  const [showValidatError, setShowValidateError] = useState();
  const [validateMessage, setValidateMessage] = useState("");

  const [classroomList, setClassroomList] = useState([]);

  useEffect(() => {
    if (showValidatError) {
      setTimeout(() => {
        setShowValidateError(false);
      }, 4000);
    }
  }, [showValidatError]);

  const [selectedClassroom, setSelectedClassroom] = useState({});

  const [selectedAssesmentYear, setSelectedAssesmentYaer] = useState("");

  const handleAssesmentYearChange = (event) => {
    setSelectedAssesmentYaer(event.target.value);
  };

  useEffect(() => {
    console.log("selectedAssesmentYear");
    console.log(selectedAssesmentYear);
  }, [selectedAssesmentYear]);

  const [selectedYear, setSelectedYear] = useState();

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const _registration = async () => {
    closeConfirm();
    setPromoteLoading(true);

    if (students.length === 0) {
      messageWarning("ຍັງບໍ່ໄດ້ເລືອກນັກຮຽນ!");
      setPromoteLoading(false); // Stop loading
      return; // Exit early if no students are selected
    }

    if (!selectedClassroom._id) {
      console.error("No selected classroom!");
      setPromoteLoading(false); // Stop loading
      return; // Exit early if no classroom is selected
    }

    try {
      const user = localStorage.getItem(USER_KEY);
      const userObject = JSON.parse(user);
      const token = userObject?.accessToken;

      const requestBody = {
        assessmentYear: selectedAssesmentYear,
        studentIdList: students.map((s) => s.id),
        year: selectedYear,
        classRoom: selectedClassroom._id,
        user: userObject?.data?.id,
      };

      await axios.post(NEW_API_URL + "register/students", requestBody, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      setPromoteLoading(false);
      setShowFailed(false);
      setSHowSuccess(true);
    } catch (error) {
      console.error("Registration failed:", error);
      setShowFailed(true);
      setPromoteLoading(false);
    }
  };

  const handleClassChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      const selectedObject = classroomList.find(
        (classroom) => classroom._id === selectedValue
      );
      setSelectedClassroom(selectedObject);
    }
  };

  const [canConfirm, setCanConfirm] = useState(false);

  useEffect(() => {
    if (selectedAssesmentYear && selectedYear && selectedClassroom) {
      setCanConfirm(true);
    } else {
      setCanConfirm(false);
    }
  }, [selectedAssesmentYear, selectedClassroom, selectedYear]);

  useEffect(() => {
    if (selectedYear && selectedAssesmentYear) {
      const postData = {
        major: majorId,
        year: selectedYear,
      };
      axios
        .post(NEW_API_URL + "classroom", postData, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((response) => {
          if (response.data.success) {
            setClassroomList(response.data.data);
            setSelectedClassroom(response.data.data[0]);
          } else if (response.status === 404) {
            setClassroomList([]);
            setSelectedClassroom();
          }
        })
        .catch((error) => {
          setSelectedClassroom();
          // setShowValidateError(true);
          setValidateMessage(
            "ກະລຸນາກວດສອບຂໍ້ມຸນນັກສຶກສາຄຶນວ່າເປັນນັກສຶກສາປິສຸດທ້າຍ ຫລື ມີຫ້ອງຮຽນນັກສຳລັບສຶກສາປີຕໍ່ໄປບໍ່?"
          );
          setClassroomList([]);
        });
    }
  }, [selectedAssesmentYear, selectedYear]);

  if (createManyPromoteToNextGradeLoading || studentLoading) return <Loading />;
  return (
    // <div>
    //   <NewPromoteToNextGrade />
    // </div>
    <div style={{ padding: 20 }}>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/setting-list")}>
          <strong>ຕັ້ງຄ່າ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.goBack()}>
          <strong>ເລືອກການຕັ້ງຄ່າ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ເລື່ອນຊັ້ນນັກສຶກສາ</Breadcrumb.Item>
      </Breadcrumb>
      <div>
        {promoteLoading && (
          <div className="my-modal">
            <div className="modal-content w-25">
              <p>ກຳລັງປະມວນຜົນ, ກະລຸນາລໍຖ້າ</p>
              <img className="w-25" src="/loading.gif" />
            </div>
          </div>
        )}

        {showSuccess && (
          <div className="my-modal">
            <div className="modal-content w-25">
              <p>ສຳເລັດການເລື່ອນຊັ້ນ</p>
              <img className="w-25 p-2 mb-3" src="/success.png" />
              <button
                onClick={() => {
                  setSHowSuccess(false);
                  setStudents([]);
                  loaddata();
                }}
                type="button"
                className="btn-primary rounded py-2 px-5"
              >
                ສຳເລັດ
              </button>
            </div>
          </div>
        )}

        {showFailed && (
          <div className="my-modal">
            <div className="modal-content w-25">
              <p>ຂໍອະໄພ! ມີບັນຫາໃນການເລື່ອນຊັ້ນ</p>
              <img className="w-25 p-2 mb-3" src="/fail.png" />
              <button
                onClick={() => setShowFailed(false)}
                type="button"
                className="btn-primary rounded py-2 px-5"
              >
                ປິດ
              </button>
            </div>
          </div>
        )}

        {confirmPromoteToNextGrade && (
          <div className="my-modal">
            <div className="modal-content w-50">
              <h3>ທ່ານຕ້ອງການເລື່ອນຊັ້ນນັກສຶກສາແທ້ບໍ່?</h3>
              <p>(ຫລັງຈາກນັກສຶກສາຖືກເລຶ່ອນແລ້ວຈະບໍ່ສາມາດຍ້ອນກັບໄດ້)</p>
              <div className="row w-100">
                <div className="col-5 text-center">
                  <h4>ຈາກ</h4>
                  <div className="text-start ps-3 pt-2">
                    <p>
                      ນັກສຶກສາຈຳນວນ <strong>{students.length}</strong> ຄົນ{" "}
                    </p>
                    <p>ສົກຣຽນ : {accessmentRedux}</p>
                    <p>ປີຮຽນ : {students[0]?.yearLevel}</p>
                    <p>
                      ສາຂາວິຊາ :{" "}
                      {majorData?.majors?.data.map((item) => {
                        if (item.id === majorId) return item.name;
                      })}
                    </p>

                    {/* <p>ສາຂາວິຊາ : {majorData?.majors?.data[0].name}</p> */}
                  </div>
                </div>
                <div className="col-2 text-start">
                  <h4>-----------&gt;</h4>
                </div>
                <div className="col-5 text-center">
                  <h4>ເປັນ</h4>
                  <div className="text-start ps-3 pt-2">
                    <p>
                      ນັກສຶກສາຈຳນວນ <strong>{students.length}</strong> ຄົນ{" "}
                    </p>
                    <div className="mb-2">
                      <select
                        className="p-1 px-3"
                        value={selectedAssesmentYear}
                        onChange={handleAssesmentYearChange}
                      >
                        <option value={""}>ເລືອກສົກຮຽນ</option>
                        <option value="2019 - 2020">2019 - 2020</option>
                        <option value="2020 - 2021">2020 - 2021</option>
                        <option value="2021 - 2022">2021 - 2022</option>
                        <option value="2022 - 2023">2022 - 2023</option>
                        <option value="2023 - 2024">2023 - 2024</option>
                        <option value="2024 - 2025">2024 - 2025</option>
                      </select>
                    </div>
                    <div className="mb-2">
                      <select
                        className="p-1 px-4"
                        value={selectedYear}
                        onChange={handleYearChange}
                      >
                        <option value={""}>ເລືອກປີຮຽນ</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                      </select>
                    </div>
                    <div className="mb-2">
                      <select
                        className="p-1 px-3"
                        value={selectedClassroom ? selectedClassroom._id : ""}
                        onChange={handleClassChange}
                      >
                        <option value={""}>ເລືອກຫ້ອງຮຽນ</option>
                        {classroomList &&
                          classroomList.map((classroom, index) => (
                            <option
                              key={classroom?._id}
                              value={classroom?._id}
                              selected={index === 0}
                            >
                              {classroom?.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col">
                      <p>
                        ສາຂາວິຊາ :{" "}
                        {majorData?.majors?.data.map((item) => {
                          if (item.id === majorId) return item.name;
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <button
                    onClick={() => closeConfirm()}
                    type="button"
                    className="btn btn-danger rounded py-2 px-5"
                  >
                    ຍົກເລີກ
                  </button>
                </div>
                <div className="col-6 w-50">
                  <button
                    disabled={!canConfirm}
                    onClick={() => _registration()}
                    type="button"
                    className="btn btn-primary rounded py-2 px-5"
                  >
                    ຢືນຢັນ
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <div
          style={{
            paddingRight: "30px",
            paddingLeft: "30px",
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              fontSize: 32,
              color: "#057CAE",
              fontWeight: "bold",
              paddingTop: 28,
            }}
          >
            ເລື່ອນຊັ້ນນັກສຶກສາ
          </div>

          <div style={{ paddingTop: 0 }}>
            <Row>
              <Col>
                <p style={{ padding: 0, marginBottom: 10 }}>ຄະນະ</p>
                <Form.Control
                  as="select"
                  className="form-select"
                  style={{ cursor: "pointer" }}
                  disabled={ROLE !== "ADMIN" && ROLE !== "DEAN" ? true : false}
                  value={facultyId}
                  onChange={(e) => _selectFaculty(e)}
                >
                  <option value="">ທັງໝົດ</option>
                  {dataFaculties?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {" "}
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              <Col>
                <p style={{ padding: 0, marginBottom: 10 }}>ພາກວິຊາ</p>
                <Form.Control
                  as="select"
                  className="form-select"
                  style={{ cursor: "pointer" }}
                  disabled={ROLE === "DEP_ADMIN" ? true : false}
                  value={departmentId}
                  onChange={(e) => _onSelectedDepartment(e)}
                >
                  <option value="">ທັງໝົດ</option>
                  {dataDepartments?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              <Col>
                <p style={{ padding: 0, marginBottom: 10 }}>ສາຂາວິຊາ</p>
                <Form.Control
                  as="select"
                  className="form-select"
                  style={{ cursor: "pointer" }}
                  value={majorId}
                  onChange={(e) => _onSelectedMajor(e)}
                >
                  <option value="">ທັງໝົດ</option>
                  {dataMajors?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              <Col>
                <p style={{ padding: 0, marginBottom: 10 }}>ປີຮຽນ</p>
                <Form.Control
                  as="select"
                  className="form-select"
                  style={{ cursor: "pointer" }}
                  value={yearLevel}
                  onChange={(e) => _onSelectedYear(e)}
                >
                  <option value={""}>ທັງໝົດ</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </Form.Control>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <p style={{ padding: 0, marginBottom: 10 }}>ຫ້ອງຮຽນ</p>
                <Form.Control
                  as="select"
                  className="form-select"
                  style={{ cursor: "pointer" }}
                  value={classRoomId}
                  onChange={(e) => _onSelectedClassRoom(e)}
                >
                  <option value="">ທັງໝົດ</option>
                  {dataClassRooms?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              <Col sm={3}>
                <p style={{ padding: 0, marginBottom: 10 }}>
                  ລະຫັດນັກສຶກສາ{" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        ປ້ອນລະຫັດນັກສຶກສາ Enter ເພື່ອຄົ້ນຫາ
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color={Consts.PRIMARY_COLOR}
                    />
                  </OverlayTrigger>
                </p>
                <Form.Control
                  type="text"
                  placeholder="ປ້ອນລະຫັດນັກສຶກສາ"
                  style={{ cursor: "pointer" }}
                  name="studentNumber"
                  defaultValue={studentNumber}
                  onKeyDown={(e) => _keyStudentNumber(e)}
                />
              </Col>
              <Col sm={3}>
                <p style={{ padding: 0, marginBottom: 10 }}>
                  ຊື່ນັກສຶກສາ{" "}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        ປ້ອນຊື່ນັກສຶກສາ Enter ເພື່ອຄົ້ນຫາ
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color={Consts.PRIMARY_COLOR}
                    />
                  </OverlayTrigger>
                </p>
                <Form.Control
                  type="text"
                  placeholder="ປ້ອນຊື່ນັກສຶກສາ"
                  style={{ cursor: "pointer" }}
                  name="studentName"
                  defaultValue={studentName}
                  onKeyDown={(e) => _keyStudentName(e)}
                />
              </Col>
            </Row>
          </div>
          <Col sm={8} className="mt-1 ms-2">
            <Row>
              <div className="custom-control custom-checkbox">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    style={{ color: "orange", bordercolor: "orange" }}
                    className="form-check-input"
                    onChange={(e) => _selectCheckBox(e)}
                    name="allSelect"
                    checked={
                      dataStudents?.filter(
                        (student) => student?.isChecked !== true
                      ).length < 1
                    }
                  />
                  ເລືອກທັງໝົດ {students?.length} ຄົນ
                </label>
              </div>
              {/* <p>ຖືກເລືອກ </p> */}
            </Row>
          </Col>
          <div>
            <Row>
              <p
                className="mt-1"
                style={{ color: "#8D8D8D", marginBottom: -40 }}
              >
                ທັງໝົດ {currency(totals)} ຄົນ
              </p>

              {showValidatError && (
                <Alert severity="error">{validateMessage}</Alert>
              )}

              <div style={{ textAlign: "right" }}>
                <CustomButton
                  confirm
                  angleDoubleUp
                  title="ເລື່ອນຊັ້ນ"
                  onClick={(e) => {
                    setSelectedAssesmentYaer("");
                    setSelectedYear();
                    // setSelectedClassroom()
                    if (!departmentId) {
                      setShowValidateError(true);
                      setValidateMessage("ກະລຸນາເລືອກພາກວິຊາ");
                    } else if (!majorId) {
                      setShowValidateError(true);
                      setValidateMessage("ກະລຸນາເລືອກສາຂາ");
                    } else if (!yearLevel) {
                      setShowValidateError(true);
                      setValidateMessage("ກະລຸນາເລືອກປີຮຽນ");
                    } else if (!classRoomId) {
                      setShowValidateError(true);
                      setValidateMessage("ກະລຸນາເລືອກຫ້ອງ");
                    } else if (students.length === 0) {
                      setShowValidateError(true);
                      setValidateMessage("ກະລຸນາເລືອກນັກສຶກສາ");
                    } else {
                      showConfirm();

                      // check if user selected year will fetch classroom
                      // if (selectedYear && selectedAssesmentYear) {
                      //   const postData = {
                      //     major: majorId,
                      //     year: selectedYear,
                      //   };
                      //   axios
                      //     .post(NEW_API_URL + "classroom", postData, {
                      //       headers: {
                      //         api_key: API_KEY,
                      //       },
                      //     })
                      //     .then((response) => {
                      //       if (response.data.success) {
                      //         setClassroomList(response.data.data);
                      //         setSelectedClassroom(response.data.data[0]);
                      //         showConfirm();
                      //       } else if (response.status === 404) {
                      //         setClassroomList([]);
                      //         setShowValidateError(true);
                      //         setValidateMessage("ບໍ່ພົບຫ້ອງຮຽນນັກສຶກສາ");
                      //       }
                      //     })
                      //     .catch((error) => {
                      //       setShowValidateError(true);
                      //       setValidateMessage(
                      //         "ກະລຸນາກວດສອບຂໍ້ມຸນນັກສຶກສາຄຶນວ່າເປັນນັກສຶກສາປິສຸດທ້າຍ ຫລື ມີຫ້ອງຮຽນນັກສຳລັບສຶກສາປີຕໍ່ໄປບໍ່?"
                      //       );
                      //       setClassroomList([]);
                      //     });
                      // }
                    }
                  }}
                />
              </div>
            </Row>
          </div>
          <div>
            {studentLoading ? (
              <Loading />
            ) : (
              <table border="1" bordercolor="#fff" style={{ width: "100%" }}>
                <thead>
                  <TableHeader>
                    <th style={{ width: 70 }}>ເລືອກ</th>
                    <th style={{ width: 70 }}>ລຳດັບ</th>
                    <th>ລະຫັດນັກຮຽນ</th>
                    <th>ຊື່ ແລະ ນາມສະກຸນ</th>
                    <th>ຄະນະ</th>
                    <th>ພາກວິຊາ</th>
                    <th>ສາຂາວິຊາ</th>
                    <th style={{ width: 100 }}>ປີຮຽນ</th>
                    {/* <th style={{ width: 150 }}>ຈັດການ</th> */}
                  </TableHeader>
                </thead>
                {dataStudents?.map((x, index) => {
                  return (
                    <tbody key={index}>
                      <tr
                        style={{
                          borderBottom: "2px solid #ffff",
                          textAlign: "center",
                        }}
                      >
                        <TableCell>
                          <FormCheck
                            className="ms-4"
                            name={x?.id}
                            checked={x?.isChecked || false}
                            onChange={(e) => _selectCheckBox(e)}
                          />
                        </TableCell>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x?.studentNumber}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x?.firstNameL
                            ? setGenders(x?.gender) +
                              " " +
                              x?.firstNameL +
                              " " +
                              x?.lastNameL
                            : convertGenderNamEN(x?.gender) +
                              " " +
                              x?.firstNameE +
                              " " +
                              x?.lastNameE}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x?.faculty?.name}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x?.department?.name}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }}>
                          {x?.major?.name}
                        </TableCell>
                        <TableCell>{x.yearLevel}</TableCell>
                        {/* <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          > */}
                        {/* <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  ເບິ່ງລາຍລະອຽດ
                                </Tooltip>
                              }
                            > */}
                        {/* <div
                                onClick={() => _studentDetail(x)}
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: "#FFFFFF",
                                  padding: 3,
                                  width: 64,
                                  borderRadius: 4,
                                }}
                              > */}
                        {/* <FontAwesomeIcon
                                  icon={["fas", "eye"]}
                                  color={consts.BORDER_COLOR}
                                />{" "}
                              </div> */}
                        {/* </OverlayTrigger>
                          </div>
                        </TableCell> */}
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            )}

            {Pagination_helper(
              totals,
              Routers.PROMOTE_TO_NEXT_GRADE,
              `faculty=${facultyId}&&department=${departmentId}&&major=${majorId}&&yearLevel=${yearLevel}&&classRoom=${classRoomId}&&studentNumber=${studentNumber}&&studentName=${studentName}`
            )}
          </div>
        </div>
      </div>
      {/* <Modal
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header>ລາຍຊື່ນັກສຶກສາທີ່ລົງທະບຽນແລ້ວ</Modal.Header>
        <Modal.Body>
          <table className="table table-hover" id="ExportData">
            <thead>
              <tr>
                <th width="10%" className="center" style={{ color: "#ffffff" }}>ລຳດັບ</th>
                <th style={{ color: "#ffffff" }}>ຊື່</th>
                <th style={{ color: "#ffffff" }}>ນາມສະກຸນ</th>
                <th style={{ color: "#ffffff" }}>ລະຫັດນັກສຶກສາ</th>
              </tr>
            </thead>
            <tbody>
              {studentList?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="center">{index + 1}</td>
                    <td> {item?.firstNameL} </td>
                    <td> {item?.lastNameL} </td>
                    <td className="center">{item?.userId}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal> */}
      {/* confirm promote to next grade */}
      {/* <Modal
        show={confirmPromoteToNextGrade}
        onHide={closeConfirm}
      // size="sm"
      >
        <Modal.Header><h3>ຢືນຢັນເລື່ອນຊັ້ນ</h3></Modal.Header>
        <Modal.Body>

          <h4 style={{ marginTop: 30, marginBottom: 20 }}>ທ່ານຕ້ອງການເລື່ອນຊັ້ນນັກສຶກສາແທ້ບໍ່?</h4>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirm}>
            ຍົກເລີກ
          </Button>
          <Button style={{ backgroundColor: Consts.PRIMARY_COLOR }} onClick={() => _registration()}>
            ຢືນຢັນ
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

export default PromoteToNextGrade;
