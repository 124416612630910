import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { convertGender, convertGenderNam, convertGenderNamEN, onConvertEducationLevel } from "../../helpers/user";
import { formatDateDashDMY } from "../../common/super";
export const onExport = (data) => {
  let queue = 0;
  const nreStudentExport = data.map((student) => {
    queue++;
    console.log("student::",student)
    let gender = "ຊາຍ";
    if (student.gender === "FEMALE") gender = "ຍິງ";
    // let educationLevel = "";
    // switch (student?.educationLevel || "") {
    //   case "BACHELOR_DEGREE":
    //     educationLevel = "ປະລິນຍາຕີ";
    //     break;
    // }

    return {
      ລຳດັບ: queue,
      ລະຫັດ: student.studentNumber,
      "ຊື່ ແລະ ນາມສະກຸນ(ພາສາລາວ)": `${
        convertGenderNam(student?.gender) +
        " " +
        (student?.firstNameL ?? "") +
        " "
      }
      ${student?.lastNameL ?? ""} `,
      "ຊື່ ແລະ ນາມສະກຸນ(ພາສາອັງກິດ)": `${
        convertGenderNamEN(student?.gender) +
        " " +
        (student?.firstNameE ?? "") +
        " "
      }
      ${student?.lastNameE ?? ""} `,
      ເພດ: convertGender(student?.gender),
      ສຸຂະພາບ: student?.health || "" ,
      ຄະນະ: student?.faculty?.name || "",
      ພາກວິຊາ: student?.department?.name || "",
      ສາຂາ: student?.major?.name || "",
      ປິຮຽນ: student?.yearLevel || "",
      ເບີໂທລະສັບ: student?.phoneMobile || "",
      ອິເມວ: student?.email || "",
      ລະດັບການສຶກສາ: onConvertEducationLevel(student?.educationLevel),
      ຢູ່ຫ້ອງພັກ: student?.dormitory === "YES" ? "ຢູ່" : "ບໍ່ຢູ່",
      ສາມັນຈົບຈາກແຂວງ: student?.graduateFromProvince || "",
      ສາມັນຈົບຈາກໂຮງຮຽນ: student?.graduateFromSchool || "",
      ວັນເດືອນປີເກີດ: formatDateDashDMY(student?.birthday) || "",
      ສັນຊາດ: student?.nationality || "",
      ສາສະຫນາ: student?.religion || "",
      "ຊົນເຜົ່າ​": student?.tribe?.trib_name || "",
      ສູກເສີນຕິດຕໍ່:
        student?.fatherNameAndSurname + "  " + student?.emergencyPhone || "",
      "ຊື່ ແລະ ນາມສະກຸນພໍ່": `${student?.fatherNameAndSurname} `,
      "ຊື່ ແລະ ນາມສະກຸນແມ່": `${student?.motherNameAndSurname} `,
      ເບີໂທລະສັບພໍ່ແມ່: student?.parentTelephone,
      ແຂວງຢູ່ປັດຈຸບັນ: student?.address?.province,
      ເມືອງຢູ່ປັດຈຸບັນ: student?.address?.district,
      ບ້ານຢູ່ປັດຈຸບັນ: student?.address?.village,
      ...(student?.bornAbroad !== ""
        ? { ເກີດຢູ່ປະເທດ: student?.birthAddress?.country }
        : { ເກີດຢູ່ປະເທດ: "ລາວ" }),
      ແຂວງເກີດ: student?.birthAddress?.province,
      ເມືອງເກີດ: student?.birthAddress?.district,
      ບ້ານເກີດ: student?.birthAddress?.village,
      ວັນທີລົງທະບຽນ: student?.createdAt,
    };
  });

  const ws = XLSX.utils.json_to_sheet(nreStudentExport);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Students"); // 'Students' is the sheet name

  // Write the XLSX file
  const xlsxBuffer = XLSX.write(wb, {
    bookType: "xlsx",
    type: "array",
    mimeType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const blob = new Blob([new Uint8Array(xlsxBuffer)], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Save the XLSX file using the saveAs function
  saveAs(blob, "students.xlsx");

  // jsonexport(nreStudentExport, function (err, csv) {
  //   if (err) return console.error(err);

  //   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //   saveAs(blob, "students.csv");
  // });
};
