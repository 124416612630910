import React, { useEffect, useRef, useState } from 'react'
import { TableHeader } from "../../../common";
import { convertLetterGrade, toDayDash } from "../../../common/super";

export default function ComponentToPrint({ studentDatas, gradeDatas, gradeDatass, totalCourse, length, maxCount }) {
    const ref = useRef()
    let genders;
    let mapCount = 20 - parseInt(length);
    let newMaxCount = 20 - parseInt(maxCount);
    const [totalScore, setTotalScore] = useState()

    useEffect(() => {
        if (studentDatas?.gender === "MALE") {
            genders = "ທ້າວ"
        } else if (studentDatas?.gender === "FEMALE") {
            genders = "ນາງ"
        } else if (studentDatas?.gender === "MONK") {
            genders = "ພຣະ"
        } else {
            genders = "ອື່ນໆ"
        }
    }, [])

    useEffect(() => {
        let scoreArr = []
        if (gradeDatas) {
            for (let i = 0; i < gradeDatas.length; i++) {
                scoreArr.push(gradeDatas[i]?.numbericGrade)
            }
        }
        if (gradeDatass) {
            for (let i = 0; i < gradeDatass.length; i++) {
                scoreArr.push(gradeDatass[i]?.numbericGrade)
            }
        }

        const totalScore = scoreArr.reduce(function (acc, val) { return acc + val; }, 0)
        setTotalScore(totalScore / totalCourse)
    }, [gradeDatas, gradeDatass, totalCourse])

    return (
        <div ref={ref}><br />
            <div className="center">
                <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Emblem_of_Laos.svg/1200px-Emblem_of_Laos.svg.png'} className="imgSize" alt="" />
            </div>
            <p className="center">ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ<br />
                ສັນຕິພາບ ເອກະລາດ ປະຊາທິປະໄຕ ເອກະພາບ ວັດທະນາຖາວອນ</p>
            <table className="table-score">
                <tr style={{ borderStyle: "hidden" }}>
                    <td style={{ borderStyle: "hidden" }}>ມະຫາວິທະຍາໄລແຫ່ງຊາດ<br />
                        ຄະນະ {studentDatas?.faculty?.name}</td>
                    <td style={{ borderStyle: "hidden", textAlign: "right" }}>ເລກທີ:................................/.........<br />
                        ນະຄອນຫຼວງວຽງຈັນ, ວັນທີ:...........................</td>
                </tr>
                <tr>
                    <td colSpan="2" style={{ textAlign: "center", fontSize: "26px", fontWeight: "bold" }}>
                        <p>ໃບຄະແນນ</p></td>
                </tr>
                <tr style={{ borderStyle: "hidden" }}>
                    <td style={{ borderStyle: "hidden" }}>ຫຼັກສູດການສຶກສາ: ປະລີນຍາຕີ {studentDatas?.department?.name}<br />
                        ຊື່ ແລະ ນາມສະກຸນ: {genders}{""} {studentDatas?.firstNameL}{" "}
                        {studentDatas?.lastNameL}</td>
                    <td style={{ borderStyle: "hidden", textAlign: "right" }}>ວັນທີ: {toDayDash()}<br />
                        ລະຫັດນັກສຶກສາ: {studentDatas?.userId}</td>
                </tr>
            </table>
            <br />
            <div style={{ width: "100%" }}>
                <div style={{ float: "left", width: "50%", marginRight: -1 }}>
                    <table style={{ width: "100%", marginRight: -1 }}>
                        <thead>
                            <TableHeader>
                                {/* <tr style={{ backgroundColor: "#057CAE", paddingTop:10, paddingBottom:10}}> */}
                                <th>ລະຫັດວິຊາ</th>
                                <th>ຊື່ວິຊາ</th>
                                <th className="center">ໜ່ວຍກິດ</th>
                                <th className="center">ລະດັບ</th>
                                {/* </tr> */}
                            </TableHeader>
                        </thead>
                        <tbody>
                            {gradeDatas?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="center">{item?.course?.courseCode}</td>
                                        <td>{item?.course?.title}</td>
                                        <td className="center">{item?.course?.unit}</td>
                                        <td className="center">{item?.letterGrade}</td>
                                    </tr>
                                );
                            })}
                            {
                                Array.from(Array(mapCount), ((e, i) => {
                                    return (
                                        <tr>
                                            <td className="center">-</td>
                                            <td></td>
                                            <td className="center"></td>
                                            <td className="center"></td>
                                        </tr>
                                    );
                                })
                                )}
                            <tr>
                                <td rowSpan="2">ReMark</td>
                                <td colSpan="3" className="center">-</td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="center">-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ float: "right", width: "50%", marginLeft: -1 }}>
                    <table style={{ width: "100%", marginLeft: -1 }}>
                        <thead>
                            <TableHeader>
                                {/* <tr style={{ backgroundColor: "#057CAE" }}> */}
                                <th>ລະຫັດວິຊາ</th>
                                <th>ຊື່ວິຊາ</th>
                                <th className="center">ໜ່ວຍກິດ</th>
                                <th className="center">ລະດັບ</th>
                                {/* </tr> */}
                            </TableHeader>
                        </thead>
                        <tbody>
                            {gradeDatass?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="center">{item?.course?.courseCode}</td>
                                        <td>{item?.course?.title}</td>
                                        <td className="center">{item?.course?.unit}</td>
                                        <td className="center">{item?.letterGrade}</td>
                                    </tr>
                                );
                            })}
                            {
                                Array.from(Array(newMaxCount), ((e, i) => {
                                    return (
                                        <tr>
                                            <td className="center">-</td>
                                            <td></td>
                                            <td className="center"></td>
                                            <td className="center"></td>
                                        </tr>
                                    );
                                })
                                )}
                            <tr>
                                <td colSpan="2">Total of Credit</td>
                                <td colSpan="2">{totalScore ? totalScore.toFixed(2) : ""}</td>
                            </tr>
                            <tr>
                                <td colSpan="2">CGPA</td>
                                <td colSpan="2">{totalScore ? convertLetterGrade(totalScore) : ""}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            {/* Asign */}
            <br /><br />
            <table className="table-score">
                <tr>
                    <td className="center" style={{ borderStyle: "hidden" }}></td>
                    <td className="center" style={{ borderStyle: "hidden", width: "50%" }}>
                        A(ດີເລີດ)=4; B+(ດີຫລາຍ)=3.5; B(ດີ)=3; C+(ດີພໍໃຊ້)=2.5;<br />
                        C(ພໍໃຊ້ໄດ້)=2; D+(ອ່ອນ)=1.5; D(ອ່ອນຫລາຍ)=1; F(ຕົກ)=0.</td>
                    <td className="center" style={{ borderStyle: "hidden" }}></td>
                </tr>
            </table><br />
            <table className="table-score">
                <tr>
                    <td className="center" style={{ borderStyle: "hidden", paddingRight: 200 }}>ຄະນະບໍດີ</td>
                    <td style={{ textAlign: "center", borderStyle: "hidden" }}>ຫົວໜ້າພາກວິຊາ</td>
                </tr>
            </table>
        </div>
    )
}
