import { gql } from "apollo-boost";

export const PRE_SIGNED_URL = gql`
  query PreSignedUrl($mimeType: String, $fileName: String) {
    preSignedUrl(mimeType: $mimeType, fileName: $fileName) {
      url
    }
  }
`;

export const CREATE_STUDENT = gql`
  mutation CreateStudent($data: StudentCreateInputWithPassword!) {
    createStudent(data: $data) {
      id
    }
  }
`;

export const UPDATE_STUDENT = gql`
  mutation UpdateStudent(
    $data: StudentWhereDataInputWithPassword!
    $where: StudentWhereUniqueInput!
  ) {
    updateStudent(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_STUDENT = gql`
  mutation DeleteStudent($where: StudentWhereUniqueInput!) {
    deleteStudent(where: $where) {
      id
    }
  }
`;

export const STUDENTS = gql`
  query Students(
    $where: CustomStudentWhereInput
    $orderBy: StudentOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
  ){
    students(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
    ){
      total
      data{
        id
        userId
        assessmentYear
        studentNumber
        gender
        firstNameL
        lastNameL
        firstNameE
        lastNameE
        maritualStatus
        studyStatus
        phoneHome
        phoneMobile
        emergencyContact{
          relationship
        }
        email
        birthday
        yearLevel
        role
        birthAddress{
          village
          district
          province
        }
        address{
          village
          district
          province
        }
        nationality
        tribe
        religion
        parentName
        parentPhone
        note
        faculty{
          id
          name
        }
        department{
          id
          name
        }
        major {
          id
          name
        }
        classRoom{
          id
          name
        }
        studentType
        comingFrom{
          graduatedProvince
          graduatedSchool
          graduatedAccessMentYear
        }
        payTuition{
          graduatedProvince
          graduatedSchool
          graduatedAccessMentYear
        }
        educationLevel{
          id
          name
        }
        personalType{
          id
          name
        }
        scholarship
        transferLetterNo
        scholarshipAgreementNo
      }
    }
  }
`;

export const STUDENT = gql`
  query Student($where: StudentWhereUniqueInput!) {
    student(where: $where) {
      id
      userAuthId
      userId
      enroll
      studentNumber
      gender
      firstNameL
      lastNameL
      firstNameE
      lastNameE
      transferLetterNo
    	scholarshipAgreementNo
       image {
        url
        width
        height
      }
      birthday
      graduatedSchool
      bornAbroad
      birthAddress {
        country
        province
        district
        village
        postCode
        lat
        lng
        detail
        note
      }
       nationality
      tribe
      yearLevel
      maritualStatus
      scholarship
      scholarshipFrom
      religion
      phoneHome
      phoneMobile
      email
      status
      parentName
      parentPhone
      studyStatus
      studentType
      comingFrom {
        graduatedCountry
        graduatedSchool
        graduatedProvince
        graduatedAccessMentYear
      }
      payTuition {
        graduatedCountry
        graduatedSchool
        graduatedProvince
        graduatedAccessMentYear
      }
       parentAddress {
        country
        province
        district
        village
        postCode
        lat
        lng
        detail
        note
      }
      personalType {
        id
        name
      }
       personalTypeWorkplace
      role
      classRoom {
        id
        name
      }
      faculty {
        id
        name
      }
       department {
        id
        name
      }
      major {
        id
        name
      }
      educationLevel {
        id
        name
      }
      emergencyContact {
        relationship
        isLiveTogether
      }
       address {
        country
        province
        district
        village
        postCode
        lat
        lng
        detail
        note
      }
      assessmentYear
      note
      createdAt
      createdBy{
        id
        userId
        firstname
        lastname
      }
      updatedAt
      updatedBy{
        id
        userId
        firstname
        lastname
      }
    }
  }
`;

export const STUDENTS_PROMOTE_TO_NEXT_GRADE = gql`
  query Students(
    $where: CustomStudentWhereInput
    $orderBy: StudentOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
  ){
    students(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
    ){
      total
      data{
        id
        userId
        assessmentYear
        studentNumber
        gender
        firstNameL
        lastNameL
        firstNameE
        lastNameE
        maritualStatus
        studyStatus
        phoneHome
        phoneMobile
        email
        birthday
        yearLevel
        note
        faculty{
          id
          name
        }
        department{
          id
          name
        }
        major {
          id
          name
        }
        classRoom{
          id
          name
        }
      }
    }
  }
`;