import { gql } from "apollo-boost";

export const GRADES = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
  ) {
    grades(
      where: $where
      orderBy: $orderBy
    ) {
      data {
        id
        gredeStatus
        numberTest
        numbericGrade
        sendingGradeStatus
        semester
        yearLevel
        letterGrade
        numbericGrade
        letterGrade
        student {
          firstNameL
          lastNameL
          assessmentYear
        }
        course {
          courseCode
          title
        teacher{
          firstname
          lastname
        }
        }
        createdAt
        createdBy
        updatedBy

      }
    }
  }
`;

export const SENDING_GRADES_STATUS = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
  ) {
    grades(
      where: $where
      orderBy: $orderBy
    ) {
      data {
        id
        sendingGradeStatus
      }
    }
  }
`;