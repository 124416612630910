import React, { useState, useEffect } from "react"
import { Breadcrumb, Nav } from "react-bootstrap";
import useReactRouter from "use-react-router"

export default function UserNav() {
  const { history, match } = useReactRouter()
  const url = match.url
  const defaultURL = url.split('/')[1]
  const [categoryTitleItem, setCategoryTitleItem] = useState("ໃຫ້ຄະແນນ")
  const [subCategoryTitleItem, setSubCategoryTitleItem] = useState("ວິຊາທັງໝົດ")

  useEffect(() => {
    
    if (defaultURL === "grades-list") {
      console.log("test")
      setCategoryTitleItem("ຄະແນນທັງໝົດ");
    } else if (defaultURL === "grades-student-list") {
      setCategoryTitleItem("ຄະແນນລາຍບຸກຄົນ")
    } else {
      setSubCategoryTitleItem("ວິຊາທັງໝົດ")
    }
  }, [defaultURL])
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
          <strong>ຄະແນນ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
        <strong>ເລືອກໝວດໝູ່</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
        <strong>{categoryTitleItem}</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{subCategoryTitleItem}</Breadcrumb.Item>
      </Breadcrumb>

      {defaultURL === 'grade-sending-courses-list' | defaultURL === 'grades-class-list' ? (<Nav variant="tabs" style={{marginLeft: 10, marginRight: 10}} defaultActiveKey="grade-sending-courses-list">
        <Nav.Item>
          <Nav.Link
            onClick={() => history.push(`/grade-sending-courses-list/limit/40/skip/1`)}
            active={defaultURL === "grade-sending-courses-list" ? true : false}
          >
            ວິຊາທັງໝົດ
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            onClick={() => history.push(`/grades-class-list/limit/40/skip/1`)}
            active={defaultURL === "grades-class-list" ? true : false}
          >
            ຫ້ອງຮຽນທັງໝົດ
          </Nav.Link>
        </Nav.Item>
      </Nav>):<></>
      }
      {defaultURL === 'grades-list' | defaultURL === 'grades-student-list' ? (<Nav variant="tabs" style={{marginLeft: 10, marginRight: 10}} defaultActiveKey="grades-list">
        <Nav.Item>
          <Nav.Link
            onClick={() => history.push(`/grades-list/limit/20/skip/1`)}
            active={defaultURL === "grades-list" ? true : false}
          >
            ຄະແນນທັງໝົດ
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            onClick={() => history.push(`/grades-student-list/1`)}
            active={defaultURL === "grades-student-list" ? true : false}
          >
            ຄະແນນລາຍບຸກຄົນ
          </Nav.Link>
        </Nav.Item>
      </Nav>):<></>
      }
    </div>
  );
}