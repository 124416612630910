import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const CustomMessage = ({ open, message, severity }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={{ marginTop: "40px" }} // Add margin-top style here
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

export default CustomMessage;
