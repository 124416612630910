import React, { useState,useEffect } from "react";
import { Breadcrumb, Row, Col } from "react-bootstrap";
import useReactRouter from "use-react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Consts from "../../consts";
import { CustomContainer, Title } from "../../common";
import Loading from "../../common/Loading";
import { useLazyQuery } from "@apollo/react-hooks";
import { USER } from "./../../apollo/user";
import { formatDate } from "../../common/super";
import {convertRole} from "../../helpers/user"


function ProfileDetail() {
  const { history, location } = useReactRouter();
  const [userData, setUserData] = useState({});


  const [loadUser, { data: userApollo, loading }] = useLazyQuery(USER);

useEffect(() => {
  const user = JSON.parse(localStorage.getItem("user"));
  if(user) {
    loadUser({variables: { where: { id: user?.data?._id } }})
  }
}, [])

useEffect(() => {
  setUserData(userApollo?.user);
}, [userApollo])

  const _edit = () => {
    history.push("/profile-edit", userData);
  };
if(loading) return <Loading/>
  return (
    <div>
      {/* Breadcrumb */}
      <Breadcrumb>
        <Breadcrumb.Item active>ຂໍ້ມູນສ່ວນຕົວ</Breadcrumb.Item>
      </Breadcrumb>

      <CustomContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title text={"ລາຍລະອຽດຂໍ້ມູນສ່ວນຕົວ"} />

          {/* Button group */}
          <div>
            {/* ແກ້ໄຂ */}
            <button
              style={{
                backgroundColor: "#fff",
                color: Consts.PRIMARY_COLOR,
                width: 100,
                height: 40,
                border: "1px solid " + Consts.PRIMARY_COLOR,
                outline: "none",
                marginRight: 5,
              }}
              onClick={() => _edit()}
            >
              <FontAwesomeIcon icon="edit" style={{ fontSize: 16 }} /> ແກ້ໄຂ
            </button>
          </div>
        </div>

        <div
          style={{
            width: 500,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            paddingBottom: 80,
          }}
        >
          {/* -------- ຂໍ້ມູນອາຈານ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ຂໍ້ມູນ {convertRole(userData?.role)}
            </div>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>ຊື່</Col>
                <Col>{userData?.firstname ? userData?.firstname : "-"}</Col>
              </Row>
              <Row>
                <Col>ນາມສະກຸນ</Col>
                <Col>{userData?.lastname ? userData?.lastname : "-"}</Col>
              </Row>
              <Row>
                <Col>ວັນເດືອນປີເກີດ</Col>
                <Col>
                  {userData?.birthday ? formatDate(userData?.birthday) : "-"}
                </Col>
              </Row>
              <Row>
                <Col>ເບີໂທ</Col>
                <Col>{userData?.phone ? userData?.phone : "-"}</Col>
              </Row>
              <Row>
                <Col>ອີເມວ</Col>
                <Col>{userData?.email ? userData?.email : "-"}</Col>
              </Row>
              <Row>
                <Col>ລະດັບຜູ້ໃຊ້</Col>
                <Col>{convertRole(userData?.role ? userData?.role : "-")}</Col>
              </Row>
            </div>
          </div>

          {/* -------- ໄອດີ ແລະ ລະຫັດຜ່ານ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ໄອດີ ແລະ ລະຫັດຜ່ານ
            </div>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>ໄອດີ</Col>
                <Col>{userData?.userId ? userData?.userId : "-"}</Col>
              </Row>
              <Row>
                <Col>ລະຫັດຜ່ານ</Col>
                <Col>********</Col>
              </Row>
            </div>
          </div>
          {/* -------- ຄໍາອະທິບາຍ -------- */}
          <div style={{ padding: 20, paddingBottom: 0 }}>
            <div style={{ fontWeight: "bold" }}>
              <FontAwesomeIcon
                icon="caret-down"
                style={{
                  marginRight: 16,
                  marginLeft: -24,
                  fontSize: 24,
                  color: Consts.PRIMARY_COLOR,
                }}
              />
              ອື່ນໆ
            </div>
            <div style={{ paddingLeft: 20, fontSize: 14 }}>
              <Row>
                <Col>ລາຍລະອຽດ</Col>
                <Col>{userData?.description ? userData?.description : "-"}</Col>
              </Row>
              <Row>
                <Col>ໝາຍເຫດ</Col>
                <Col>{userData?.note ? userData?.note : "-"}</Col>
              </Row>
              <Row>
                <Col>ວັນສ້າງ</Col>
                <Col>
                  {userData?.createdAt
                    ? new Date(userData?.createdAt).toLocaleString("la-LA", {
                        hour12: false,
                      })
                    : "-"}
                </Col>
              </Row>
              <Row>
                <Col>ວັນແກ້ໄຂ</Col>
                <Col>
                  {userData?.updatedAt
                    ? new Date(userData?.updatedAt).toLocaleString("la-LA", {
                        hour12: false,
                      })
                    : "-"}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </CustomContainer>
    </div>
  );
}

export default ProfileDetail;
