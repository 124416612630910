import { gql } from 'apollo-boost'

export const REGIS_MAJOR_DATE_HISTORIES = gql`
  query regisMajorDateHistories(
    $where: RegisMajorDateHistoryWhereInput
    $orderBy: RegisMajorDateHistoryOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    regisMajorDateHistories(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data{
        id
        startDateRegister
        endDateRegister
        createdBy{
            role
            firstname
            lastname
        }
        major{
            name
        }
        createdAt
      }
    }
  }
`;