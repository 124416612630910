const VERSION = "SLMS Admin v1.0.67"
const VERSION_LOGIN_PAGE = "v1.0.67"

//Moodle Staging
const MOODLE_URL = "http://54.254.4.191:888"
//Moodle Production
// const MOODLE_URL = "http://54.255.147.171:888"

const URL_IMAGE = "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/pregistration-image/"
const URL_IMAGE_SMALL = "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/resized/small/"
const URL_IMAGE_MEDIUM = "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/resized/medium/"
const URL_IMAGE_STUDENT = "https://slms-nuol-file-bucket.s3.ap-southeast-1.amazonaws.com/student-image/"

module.exports = {
  PRIMARY_COLOR: '#057CAE',
  SECONDARY_COLOR: '#057CAE',
  FONT_COLOR_PRIMARY: '#383838',
  FONT_COLOR_SECONDARY: '#6f6f6f',
  CONTAINER_PADDING: 24,
  CONTAINER_MARGIN_TOP: -10,
  USER_KEY: 'user',
  BORDER_COLOR: '#7BB500',
  BORDER_COLOR_DELETE:'#B80000',
  DELETE_COLOR_BUTTON: '#C7C7C7',
  MOODLE_URL,
  URL_IMAGE,
  URL_IMAGE_SMALL,
  URL_IMAGE_MEDIUM,
  URL_IMAGE_STUDENT,
  VERSION,
  VERSION_LOGIN_PAGE
}