export const getRoleDescription = (role) => {
  switch (role) {
    case "TEACHER":
      return "ອາຈານສອນ";
    case "DEP_ADMIN":
      return "ວິຊາການພາກ";
    case "FAC_ADMIN":
      return "ວິຊາການຄະນະ";
    case "ADMIN":
      return "ວິຊາການ ມຊ";
    case "DEP_ADMIN_TEACHER":
      return "ວິຊາການພາກ ແລະ ອາຈານ";
    case "FAC_ADMIN_TEACHER":
      return "ວິຊາການຄະນະ ແລະ ອາຈານ";
    case "ADMIN_TEACHER":
      return "ວິຊາການ ມຊ ແລະ ອາຈານ";
    case "DEAN":
      return "ອະທິການບໍດີ";
    default:
      return "";
  }
};
export const ROLE_LIST = [
  "TEACHER",
  "DEP_ADMIN",
  "FAC_ADMIN",
  "ADMIN",
  "DEP_ADMIN_TEACHER",
  "FAC_ADMIN_TEACHER",
  "ADMIN_TEACHER",
  "DEAN"
];

export default getRoleDescription;
