import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import * as _ from "lodash";
/**
 *
 * @Component
 *
 */
import { Breadcrumb, Pagination } from "react-bootstrap";
import { CustomContainer, TableHeader } from "../../../common";
/**
 *
 * @Constant
 *
 */
/**
 *
 * @Apollo
 *
 */
import { FACULTY_NAME } from "../../../apollo/faculty";
import { DEPARTMENT_NAME } from "../../../apollo/deparment/index";
import {
  UPDATE_GRADE_COURSE,
  GRADE_COURSES,
} from "../../../apollo/grade/index";
import { COURSES } from "../../../apollo/course/index";
import { TEACHERS } from "../../../apollo/user/index";
/**
 *
 * @Function
 *
 */
import { alertSuccess, alertWarning } from "../../../common/super";
import "../util/index.css";

function AllCourse() {
  const { history, match } = useReactRouter();
  const pageNambers = match?.params?.page;
  const PAGE_LIMIT = 50;
  const [countPage, setCountPage] = useState([]);
  /**
   *
   * @State
   *
   */
  // filter data
  const [courseTitle, setCourseTitle] = useState();
  const [factId, setFactId] = useState();
  const [departmentId, setDepartmentId] = useState();
  const [courseId, setCourseId] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectSemester, setSelectSemester] = useState();
  const [selectAccessMent, setSelectAccessMent] = useState("");
  const [selectTeacher, setSelectTeacher] = useState();
  const [callAccessMent, setCallAccessMent] = useState();
  const [courseIdToSend, setCourseIdToSend] = useState();
  const [gradeCourseId, setGradeCourseId] = useState();
  const [gradeCourseStatus, setGradeCourseStatus] = useState();
  /**
   *
   * @Apollo
   *
   */
  // Query faculties
  const [loadFaculties, { data: facultyData }] = useLazyQuery(FACULTY_NAME);

  // Query department
  const [loadDepartment, { data: departmentData }] = useLazyQuery(
    DEPARTMENT_NAME,
    {
      variables: {
        where: { faculty: { id: factId } },
      },
    }
  );
  // Qeury teacher
  const [loadTeacher, { data: teacherData }] = useLazyQuery(TEACHERS, {
    variables: {
      where: { faculty: { id: factId }, department: { id: departmentId } },
    },
  });

  // Qeury course
  const [loadCourse, { loading: courseLoading, data: courseData }] =
    useLazyQuery(COURSES, {
      variables: {
        first: PAGE_LIMIT,
        skip: PAGE_LIMIT * (pageNambers - 1),
      },
    });

      // load gradeCourse here
  const [loadGradeCourse, { data: gradeCourseData }] = useLazyQuery(
    GRADE_COURSES,
    {
      variables: {
        where: {
          course: { id: courseIdToSend },
        },
      },
    }
  );

  const [updateGradeCourse] = useMutation(UPDATE_GRADE_COURSE);

  /**
   *
   * @UseEffect
   *
   */
  useEffect(() => {
    loadCourse();
    loadFaculties();
    loadTeacher();
    loadDepartment();
  }, []);

  // load course
  useEffect(() => {
    let allWhere = {};
    if (courseTitle) allWhere = { ...allWhere, title_starts_with: courseTitle };
    if (factId) allWhere = { ...allWhere, faculty: { id: factId } };
    if (departmentId)
      allWhere = { ...allWhere, department: { id: departmentId } };
    if (selectedYear) allWhere = { ...allWhere, year: parseInt(selectedYear) };
    if (selectSemester)
      allWhere = { ...allWhere, semester: parseInt(selectSemester) };
    if (selectAccessMent)
      allWhere = { ...allWhere, assessmentYear: selectAccessMent };
    if (selectTeacher)
      allWhere = { ...allWhere, teacher_some: { id: selectTeacher } };

    loadCourse({
      variables: {
        where: allWhere,
        first: PAGE_LIMIT,
        skip: PAGE_LIMIT * (pageNambers - 1),
      },
    });
  }, [
    courseTitle,
    factId,
    departmentId,
    selectedYear,
    selectSemester,
    selectAccessMent,
    selectTeacher,
  ]);

  // load assessmentyear
  useEffect(() => {
    let today = new Date();
    let y1 = today.getFullYear() - 1;
    let y2 = today.getFullYear();
    var accessment = [];
    for (var i = y2; i >= 2010; i--) {
      accessment.push(i);
    }
    setCallAccessMent(accessment);
  }, []);

  useEffect(() => {
    var courseCount = courseData?.course_page?.total;
    const countPages = [];
    for (var i = 1; i <= Math.ceil(courseCount / PAGE_LIMIT); i++) {
      countPages.push(i);
      setCountPage(countPages);
    }
  }, [courseData]);

  useEffect(() => {
    loadGradeCourse();
  }, [courseIdToSend]);

  useEffect(() => {
    setGradeCourseId(gradeCourseData?.gradeCourses?.data[0]?.id);
    if (gradeCourseData?.gradeCourses?.data[0]?.status === "TEACHER_SCORING") {
      setGradeCourseStatus("TEACHER_SCORING");
    }
  }, [gradeCourseData]);

  /**
   *
   * @Function
   *
   */

  // select faculty ad get value
  const _selectFaculty = (e) => {
    setFactId(e.target.value);
  };
  // select department
  const _onSelectedDepartment = (e) => {
    setDepartmentId(e.target.value);
  };
  // select yaer
  const _onSelectedYear = (e) => {
    setSelectedYear(e.target.value);
  };
  // select unit
  const _onSelectedSemester = (e) => {
    setSelectSemester(e.target.value);
  };
  // select accessMent
  const _onChangeAccessMent = (e) => {
    setSelectAccessMent(e.target.value);
  };
  // select Teacher
  const _onSelectedTeacher = (e) => {
    setSelectTeacher(e.target.value);
  };

  const _onNext = () => {
    if (parseInt(pageNambers) + 1 < 1) {
      history.push(`/send-grades/${1}`);
    } else {
      history.push(`/send-grades/${parseInt(pageNambers) + 1}`);
    }
  };
  const _onPrevius = () => {
    if (parseInt(pageNambers) - 1 < 1) {
      history.push(`/send-grades/${1}`);
    } else {
      history.push(`/send-grades/${parseInt(pageNambers) - 1}`);
    }
  };

  // course Detial
  const _courseDetial = (dataCourse) => {
    const _sendData = {
      factId: factId,
      departmentId: departmentId,
      yearLevel: selectedYear,
      semester: selectSemester,
      courseId: dataCourse,
      accessMent: selectAccessMent,
      teacherId: selectTeacher,
    };
    history.push(`/send-grades-detial/${parseInt(pageNambers)}`, _sendData);
  };



  // send grades
  const _sentGrade = () => {
    updateGradeCourse({
      variables: {
        data: {
          status: "TEACHER_SUBMITED",
        },
        where: {
          id: gradeCourseId,
        },
      },
    })
      .then(async () => {
        alertSuccess("ສົ່ງຄະແນນສຳເລັດແລ້ວ");
      })
      .catch((err) => {
        alertWarning("ສົ່ງຄະແນນລົ້ມແຫຼວ");
      });
  };

  return (
    <div>
      {/* <GradeNav /> */}
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
          <strong>ຄະແນນ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/grades-index/1")}>
          <strong>ເລືອກໝວດໝູ່</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push("/grades-course-list/limit/40/skip/1")}>
          <strong>ວິຊາທັງໝົດ</strong>
        </Breadcrumb.Item>
        <Breadcrumb.Item>ວິຊາທັງໝົດ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer style={{ marginTop: 0 }}>
        <span
          style={{
            display: "inline-block",
            fontSize: 24,
            marginTop: 20,
            color: "#057CAE",
            fontWeight: "bold",
          }}
        >
          ວິຊາທັງໝົດ
        </span>

        <div className="form-group row">
          <label for="searchtext" className="col-sm-1 col-form-label">
            ຄົ້ນຫາ
          </label>
          <div className="col-sm-11">
            <input
              type="text"
              className="form-control boxs"
              id="searchtext"
              placeholder="ປ້ອນຊື່ວິຊາ"
              onChange={(e) => setCourseTitle(e.target.value)}
            />
          </div>
        </div>

        {/* choices filter data */}
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ຄະນະ</label>
            <select
              className="form-control"
              onChange={(e) => _selectFaculty(e)}
            >
              <option value="">ທັງໝົດ</option>
              {facultyData?.faculties?.map((item, index) => {
                return (
                  <option key={"faculty" + index} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ພາກວິຊາ</label>
            <select
              className="form-control"
              onChange={(e) => _onSelectedDepartment(e)}
            >
              <option value="">ທັງໝົດ</option>
              {departmentData?.departments?.map((x, index) => (
                <option key={"department" + index} value={x.id}>
                  {x.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ປີ</label>
            <select
              className="form-control"
              onChange={(e) => _onSelectedYear(e)}
            >
              <option value="">ເລືອກປີ</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ພາກຮຽນ</label>
            <select
              className="form-control"
              onChange={(e) => _onSelectedSemester(e)}
            >
              <option value="">ເລືອກພາກ</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ສົກຮຽນ</label>
            <select
              className="form-control"
              onChange={(e) => _onChangeAccessMent(e)}
            >
              <option value="">ເລືອກສົກຮຽນ</option>
              {callAccessMent?.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <label>ອາຈານ</label>
            <select
              className="form-control"
              onChange={(e) => _onSelectedTeacher(e)}
            >
              <option value="">ເລືອກອາຈານ</option>
              {teacherData?.user_page?.data.map((item, index) => (
                <option key={"teacher" + index} value={item?.id}>
                  {item?.firstname}
                </option>
              ))}
            </select>
          </div>
        </div>
        {courseLoading ? (
          // <Loading />
          <div>...ກຳລັງໂຫຼດ......</div>
        ) : (
          <div>
            <table className="table table-striped">
              <thead>
                <TableHeader>
                  <th style={{ width: 70 }}>ລຳດັບ</th>
                  <th>ວິຊາທັງໝົດຂອງອາຈານ</th>
                  <th style={{ width: 300 }}>ຈັດການ</th>
                </TableHeader>
              </thead>
              <tbody>
                {courseData?.course_page?.data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="center">
                        {(parseInt(pageNambers) - 1) * PAGE_LIMIT + index + 1}
                      </td>
                      <td>{item?.title}</td>
                      <td className="center">
                        {/* <button className="btn btn-outline-success"
                                                        style={{ marginRight: 10 }}
                                                        data-toggle="modal"
                                                        data-target="#confirmSent"
                                                        onClick={() => setCourseIdToSend(item?.id)}
                                                    >
                                                        <i className="fa fa-check-circle"></i> {' '}
                                                        ສົ່ງຄະແນນ
                                                    </button> */}
                        <button
                          className="btn btn-outline-success"
                          onClick={(e) => _courseDetial(item?.id)}
                          // data-toggle="modal"
                          // data-target="#DetialList"
                        >
                          <i className="fa fa-external-link-alt"></i>{" "}
                          ລາຍລະອຽດການສົ່ງ
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <Pagination
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <Pagination.Prev onClick={_onPrevius} />
              {countPage?.slice(0, 5).map((Item, index) => {
                return (
                  <Pagination.Item
                    key={"page" + index}
                    active={Item === pageNambers}
                    onClick={() => {
                      return history.push(`/send-grades/${index + 1}`);
                    }}
                  >
                    {Item}
                  </Pagination.Item>
                );
              })}
              {countPage && countPage?.length > 3 ? (
                <Pagination.Item style={{ pointerEvents: "none" }}>
                  {"..."}
                </Pagination.Item>
              ) : (
                " "
              )}
              {countPage && countPage?.length > 3 ? (
                <Pagination.Item
                  onClick={() =>
                    history.push(`/send-grades/${countPage?.length}`)
                  }
                >
                  {countPage?.length}
                </Pagination.Item>
              ) : (
                " "
              )}
              <Pagination.Next onClick={_onNext} />
            </Pagination>
          </div>
        )}
      </CustomContainer>
      <div
        className="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="mydelete"
        id="confirmSent"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                <b>ສົ່ງຄະແນນ</b>
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ textAlign: "center" }}>
              <h4>ຕ້ອງການສົ່ງຄະແນນວິຊາ {} ແທ້ບໍ?</h4>
              <br />
              <center>
                <button
                  type="button"
                  className="cancelconf"
                  data-dismiss="modal"
                >
                  ຍົກເລີກ
                </button>
                <button
                  className="btn btn-info"
                  onClick={() => _sentGrade()}
                  data-dismiss="modal"
                >
                  &nbsp;&nbsp;ສົ່ງຄະແນນ&nbsp;&nbsp;
                </button>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllCourse;
