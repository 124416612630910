import React, { useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

/**
 *
 * @Library
 *
 */
import { Formik } from "formik";
import * as _ from "lodash";
import AsyncSelect from "react-select/async";
import chroma from "chroma-js";
/**
 *
 * @Component
 *
 */
import { Modal, Button, Form, Row, Col, Toast } from "react-bootstrap";
/**
 *
 * @Constant
 *
 */
import Consts from "../../consts";

/**
 *
 * @Apollo
 *
 */
import {
  CREATE_REGISTRATION,
  STUDENT_TO_CHECK,
} from "../../apollo/registration";
import { FINE_USERS } from "../../apollo/user";
import { FINE_COURSES, COURSE_TO_CHECK } from "../../apollo/course";
/**
 *
 * @Function
 *
 */

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "1px solid #ced4da",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const color = chroma("rgb(5, 124, 174)");
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "rgb(5, 124, 174)"
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : "rgb(5, 124, 174)",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled &&
          (isSelected ? "rgb(5, 124, 174)" : color.alpha(0.3).css()),
      },
    };
  },
};

function FacultyAdd({ showAddModal, _handleShowAddModalClose }) {
  //States
  const [showToast, setShowToast] = useState(false);
  const [getCourseCode, setGetCourseCode] = useState("");
  const [getStudentCode, setGetStudentCode] = useState("");
  let [courseNull, setCourseNull] = useState("");
  let [studentNull, setStudentNull] = useState("");
  let [createStatus, setCreateStatus] = useState("");
  let [studentOptions, setStudentOptions] = useState([]);
  let [courseOptions, setCourseOptions] = useState([]);

  /**
   *
   * @Apollo
   *
   */
  const [createRegistration] = useMutation(CREATE_REGISTRATION);
  const [loadUsersData, { data: usersData }] = useLazyQuery(FINE_USERS);
  const [loadCourseCheck, { data: checkData }] = useLazyQuery(COURSE_TO_CHECK);
  const [loadStudentCheck, { data: studentData }] =
    useLazyQuery(STUDENT_TO_CHECK);
  const [loadCoursesData, { data: coursesData }] = useLazyQuery(FINE_COURSES);

  /**
   * 
   * @UesEffect
   * 
   */
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    loadUsersData({
      variables: {
        keyword: getStudentCode,
        limit: 20,
        role: "STUDENT",
      },
    });
    loadCoursesData({
      variables: {
        keyword: getCourseCode,
        limit: 20,
      },
    });
  }, []);

  useEffect(() => {
    loadCourseCheck({
      variables: { where: { courseCode: getCourseCode } },
    });
    setCourseNull("");
    setCreateStatus("");
  }, [getCourseCode]);

  useEffect(() => {
    if (getStudentCode) {
      loadStudentCheck({
        variables: { where: { student: { userId: getStudentCode } } },
      });
      setStudentNull("");
      setCreateStatus("");
    }
  }, [getStudentCode]);

  // get studentOptions
  useEffect(() => {
    if (usersData?.fineUser?.length > 0) {
      let newOptions = [];
      for (var i = 0; i < usersData.fineUser.length; i++) {
        newOptions.push({
          value: usersData.fineUser[i].userId,
          label:
            usersData.fineUser[i].firstname +
            " " +
            (usersData?.fineUser[i]?.lastname ?? ""),
        });
      }
      setStudentOptions(newOptions);
    }
  }, [usersData]);

  // get courseOptions
  useEffect(() => {
    if (coursesData?.fineCourse?.length > 0) {
      let newOptions = [];
      for (var i = 0; i < coursesData.fineCourse.length; i++) {
        newOptions.push({
          value: coursesData.fineCourse[i].courseCode,
          label: coursesData.fineCourse[i].title,
        });
      }
      setCourseOptions(newOptions);
    }
    // }
  }, [coursesData]);

  // start on search student data from AsyncSelect
  const filterUserData = (inputValue) => {
    return studentOptions.filter(
      (i) =>
        i.value.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const studentPromiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterUserData(inputValue));
      }, 1000);
    });
  };
  const _handleInputStudentChange = (e) => {
    setGetStudentCode(e);
    loadUsersData();
  };
  const _handleStudentChange = (e) => {
    setGetStudentCode(e.value);
  };
  // end on search student data from AsyncSelect

  // start on search course data from AsyncSelect
  const filterCoursesData = (inputValue) => {
    return courseOptions.filter(
      (i) =>
        i.value.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const coursePromiseOptions = (inputValue) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterCoursesData(inputValue));
      }, 1000);
    });
  };
  const _handleInputCourseChange = (e) => {
    setGetCourseCode(e);
    loadCoursesData();
  };
  const _handleCourseChange = (e) => {
    setGetCourseCode(e.value);
  };
  // end on search course data from AsyncSelect

  const _registration = (values) => {
    let paramQL = {
      data: {
        note: values.note,
        student: {
          connect: {
            userId: getStudentCode,
          },
        },
        course: {
          connect: {
            courseCode: getCourseCode,
          },
        },
      },
    };
    createRegistration({ variables: paramQL })
      .then(async () => {
        await _handleShowAddModalClose();
        // history.push("/registration-list")
        window.location.reload(true);
      })
      .catch((err) => {
        _handleShowAddModalClose();
        setShowToast(true);
      });
  };

  const _checkStudentSchedule = async (values) => {
    _checkNull();
    if (getCourseCode && getStudentCode) {
      if (_checkSchedule()) {
        _registration(values);
      } else {
        setCreateStatus("ມີຊົ່ວໂມງຮຽນທີ່ຕຳກັນ");
      }
    }
  };

  const _checkSchedule = () => {
    if (
      studentData &&
      studentData.registrations &&
      studentData.registrations.length > 0
    ) {
      let count = 0;
      for (var i = 0; i < studentData.registrations.length; i++) {
        if (
          checkData &&
          checkData.course &&
          checkData.course.year &&
          checkData.course.year === studentData.registrations[i].course.year
        ) {
          if (
            checkData.course.semester ===
            studentData.registrations[i].course.semester
          ) {
            let timeDuplicated = studentData.registrations.filter(
              (_registration, index) => {
                let _check = false;
                let _dayTimeIndexes = _registration.course.dayTimeIndexes;
                _dayTimeIndexes.filter((_schedule, index) => {
                  _check = checkDayAndTimeDuplicate(_schedule);
                });
                return _check;
              }
            );
            if (!timeDuplicated.length > 0) {
              count++;
            }
          }
        }
      }
      return count === 0;
    } else {
      return true;
    }
  };

  const checkDayAndTimeDuplicate = (dayTime) => {
    let isDayDuplicate = false;
    let isTimeDuplicate = false;
    if (
      checkData.course &&
      checkData.course.dayTimeIndexes &&
      checkData.course.dayTimeIndexes.length > 0
    ) {
      checkData.course.dayTimeIndexes.filter((_checkDayTimeData, index) => {
        //Check if Day is duplicated
        if (dayTime.day === _checkDayTimeData.day) isDayDuplicate = true;

        //check if times is duplicated
        _checkDayTimeData.timeIndexes.filter((times, index) => {
          isTimeDuplicate = dayTime.timeIndexes.includes(times);
        });
      });
    }
    return isDayDuplicate || isTimeDuplicate;
  };

  const _checkNull = () => {
    if (!getCourseCode) {
      setCourseNull("ກະລຸນາເລືອກ");
    } else {
      setCourseNull("");
    }
    if (!getStudentCode) {
      setStudentNull("ກະລຸນາເລືອກ");
    } else {
      setStudentNull("");
    }
  };

  return (
    <div>
      <Modal show={showAddModal} onHide={_handleShowAddModalClose} size="lg">
        <Modal.Title style={{ textAlign: "center", paddingTop: 20 }}>
          <b>ລົງທະບຽນວິຊາ</b>
        </Modal.Title>
        <Modal.Body style={{ marginLeft: 50, marginRight: 50, padding: 50 }}>
          {/* {(coursesLoading) && "Loading..."} */}
          <Formik
            initialValues={{
              note: "",
            }}
            validationSchema={{}}
            onSubmit={(values, { setSubmitting }) => {
              _checkStudentSchedule(values);
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
            }) => (
              <div>
                <div
                  style={{
                    border: "#eee solid 1px",
                    padding: 50,
                    paddingBottom: createStatus ? 10 : 50,
                  }}
                >
                  <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                    style={{ margin: 0, marginBottom: 10 }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ເລືອກນັກສຶກສາ
                    </Form.Label>
                    <Col sm="8">
                      <AsyncSelect
                        styles={colourStyles}
                        // cacheOptions
                        defaultOptions
                        loadOptions={studentPromiseOptions}
                        onInputChange={_.debounce(
                          _handleInputStudentChange,
                          500
                        )}
                        onChange={_handleStudentChange}
                        placeholder="ກະລຸນາເລືອກ..."
                      />
                      {studentNull && (
                        <p style={{ color: "red", fontSize: 12 }}>
                          {studentNull}
                        </p>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                    style={{ margin: 0, marginBottom: 10 }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ເລືອກວິຊາ
                    </Form.Label>
                    <Col sm="8">
                      <AsyncSelect
                        styles={colourStyles}
                        // cacheOptions
                        defaultOptions
                        loadOptions={coursePromiseOptions}
                        onInputChange={_.debounce(
                          _handleInputCourseChange,
                          500
                        )}
                        onChange={_handleCourseChange}
                        placeholder="ກະລຸນາເລືອກ..."
                      />
                      {courseNull && (
                        <p style={{ color: "red", fontSize: 12 }}>
                          {courseNull}
                        </p>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    controlId="formPlaintextEmail"
                    style={{ margin: 0, marginBottom: 10 }}
                  >
                    <Form.Label column sm="4" className="text-left">
                      ໝາຍເຫດ
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        placeholder="ກະລຸນາປ້ອນ"
                        name="note"
                        value={values.note}
                        onChange={handleChange}
                        isInvalid={!!errors.note}
                      />
                    </Col>
                  </Form.Group>

                  {createStatus && (
                    <p
                      style={{
                        color: "red",
                        fontSize: 16,
                        textAlign: "center",
                      }}
                    >
                      {createStatus}
                    </p>
                  )}
                </div>
                <div style={{ height: 20 }} />
                <div className="row">
                  <div style={{ padding: 15 }} className="col">
                    <Button
                      onClick={_handleShowAddModalClose}
                      style={{
                        width: "100%",
                        backgroundColor: "#fff",
                        color: "#6f6f6f",
                        borderColor: Consts.SECONDARY_COLOR,
                      }}
                    >
                      ຍົກເລີກ
                    </Button>
                  </div>
                  <div style={{ padding: 15 }} className="col">
                    <Button
                      style={{
                        width: "100%",
                        backgroundColor: Consts.SECONDARY_COLOR,
                        color: "#fff",
                        borderColor: Consts.SECONDARY_COLOR,
                      }}
                      onClick={handleSubmit}
                    >
                      ລົງທະບຽນ
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <div
        style={{
          position: "fixed",
          top: 10,
          right: 10,
          zIndex: 9999,
        }}
      >
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header style={{ backgroundColor: "#c0392b" }}>
            <strong className="mr-auto" style={{ color: "#fff" }}>
              ມີຂໍ້ຜິດພາດ
            </strong>
          </Toast.Header>
          <Toast.Body>ທ່ານຕື່ມຂໍ້ມູນບໍ່ຖືກຕ້ອງຕາມທີ່ລະບົບກໍານົດ</Toast.Body>
        </Toast>
      </div>
    </div>
  );
}

export default FacultyAdd;
