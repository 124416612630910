import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
/**
 *
 * @Library
 *
 */
import { Formik } from "formik";

/**
 *
 * @Component
 *
 */
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import GradeNav from "../common/GradeNav";
import { CustomContainer, TableHeader, TableCell } from "../../../common";

/**
 *
 * @Constant
 *
 */
import Consts from "../../../consts";

/**
 *
 * @Apollo
 *
 */
import { GRADES, UPDATE_GRADE } from "../../../apollo/grade";
import { STUDENTS } from "../../../apollo/student";

import { COURSES } from "../../../apollo/course";
/**
 *
 * @Function
 *
 */
import {
  formateDateSlash,
  setGenders,
  convertLetterGrade,
  alertSuccess,
  alertWarning,
} from "../../../common/super";
import "../util/index.css";
import { convertGenderNamEN } from "../../../helpers/user";

import PDFGenerator from "./Print";
import { renderToStaticMarkup } from "react-dom/server";
// import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

function StudentListForGrade() {
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const { history } = useReactRouter();
  const [studentNumber, setStudentNumber] = useState("");
  const [numberTest, setNumberTest] = useState(1);
  const [isStudent, setIsStudent] = useState(false);
  const [displayButton, setDisplayButton] = useState("none");

  // States
  const [isYearClicked, setIsYearClicked] = useState(1);
  const [isTermClicked, setIsTermClicked] = useState(1);
  const [isCheckNumberTest, setIsCheckNumberTest] = useState(1);
  const [grade, setGrade] = useState();

  const [lastNameL, setLastNameL] = useState();
  const [studentId, setStudentId] = useState("");
  const [selectYear, setSelectYear] = useState(1);
  const [selectSemeter, setSelectSemeter] = useState(1);
  const [selectCourse, setSelectCourse] = useState("");
  const [selectAssessmentYear, setSelectAssessmentYear] = useState("");
  const [callAccessMent, setCallAccessMent] = useState();

  const [getScoreOnClass, setGetScoreOnClass] = useState(0);
  const [getScoreOnPaticipate, setGetScoreOnPaticipate] = useState(0);
  const [getScoreOnMidTerm, setGetScoreOnMidTerm] = useState(0);
  const [getScoreOnFinal, setGetScoreOnFinal] = useState(0);
  const [getTotalScore, setGetTotalScore] = useState(0);
  const [getLetterGrade, setGetLetterGrade] = useState();
  const [gradeId, setGradeId] = useState();
  const [facId, setFacId] = useState();
  const [depId, setDepId] = useState();
  const [length, setLength] = useState(0);
  const [maxCount, setMaxCount] = useState(0);
  const [studentNumberInvalid, setStudentNumberInvalid] = useState(false);

  const [loadStudent, { data: studentData }] = useLazyQuery(STUDENTS);

  // load grade to edit
  const [loadGradeToEdit, { data: gradeToEdit }] = useLazyQuery(GRADES);
  useEffect(() => {
    let allwhere = {};
    // let whereGrade = { numberTest: numberTest === 1 ? 1 : 2 };
    let whereGrade = {
      numberTest: numberTest,
      sendingGradeStatus_not: "TEACHER_SCORING",
    };
    if (selectCourse !== "") allwhere = { ...allwhere, course: selectCourse };
    if (studentId !== "") allwhere = { ...allwhere, student: studentId };
    if (selectAssessmentYear !== 0)
      allwhere = { ...allwhere, assessmentYear: selectAssessmentYear };
    if (isTermClicked) whereGrade = { ...whereGrade, semester: isTermClicked };
    if (isYearClicked) whereGrade = { ...whereGrade, yearLevel: isYearClicked };

    loadGradeToEdit({
      variables: {
        where: allwhere,
      },
    });

    if (studentNumber != "")
      whereGrade = { ...whereGrade, student: studentNumber };
    loadGrade({
      variables: {
        where: whereGrade,
      },
    });

    setGetScoreOnClass(gradeToEdit?.grades?.data[0]?.gradeType[0]?.score);
    setGetScoreOnPaticipate(gradeToEdit?.grades?.data[0]?.gradeType[1]?.score);
    setGetScoreOnMidTerm(gradeToEdit?.grades?.data[0]?.gradeType[2]?.score);
    setGetScoreOnFinal(gradeToEdit?.grades?.data[0]?.gradeType[3]?.score);
    setGetTotalScore(gradeToEdit?.grades?.data[0]?.numericGrade);
    setGetLetterGrade(gradeToEdit?.grades?.data[0]?.letterGrade);
    setGradeId(gradeToEdit?.grades?.data[0]?.id);
  }, [
    selectCourse,
    selectAssessmentYear,
    isYearClicked,
    isTermClicked,
    numberTest,
    studentNumber,
  ]);

  useEffect(() => {
    setGetTotalScore(
      parseInt(getScoreOnClass) +
        parseInt(getScoreOnPaticipate) +
        parseInt(getScoreOnMidTerm) +
        parseInt(getScoreOnFinal)
    );
  }, [
    getScoreOnClass,
    getScoreOnPaticipate,
    getScoreOnMidTerm,
    getScoreOnFinal,
  ]);

  useEffect(() => {
    if (studentNumber !== "") {
      setStudentNumberInvalid(false);
    }
    let _letterGrade = convertLetterGrade(parseInt(getTotalScore));
    setGetLetterGrade(_letterGrade);
  }, [getTotalScore, studentNumber]);

  // loadGrade for set length
  const [loadGradeLength, { data: gradeLengthData }] = useLazyQuery(GRADES);
  const [loadGradeLengths, { data: gradeLengthDataa }] = useLazyQuery(GRADES);

  // loadGrade to show
  const [loadGrade, { data }] = useLazyQuery(GRADES, {
    fetchPolicy: "network-only",
  });

  const _ClickButtonSearch = (e) => {
    if (studentNumber === "") {
      setStudentNumberInvalid(true);
      return;
    }
    let _whereGrade = {
      assessmentYear: accessmentRedux,
      sendingGradeStatus_not: "TEACHER_SCORING",
    };
    if (studentNumber != "")
      _whereGrade = { ..._whereGrade, student: studentNumber.toString() };
    let _whereStudent = { assessmentYear: accessmentRedux };
    if (studentNumber != "")
      _whereStudent = {
        ..._whereStudent,
        studentNumber: studentNumber.toString(),
      };
    loadGradeLength({
      variables: {
        first: 20,
        where: _whereGrade,
      },
    });
    loadGradeLengths({
      variables: {
        skip: 20,
        where: _whereGrade,
      },
    });

    loadGrade({
      variables: {
        where: {
          numberTest: 1,
          ..._whereGrade,
        },
      },
    });

    loadStudent({
      variables: {
        where: _whereStudent,
      },
    });
    setIsStudent(true);
    setDisplayButton("block");
  };
  const _year = (y) => {
    setIsYearClicked(y);
  };

  const _term = (t) => {
    setIsTermClicked(t);
  };

  const _numberTest = (e) => {
    setIsCheckNumberTest(e);
  };

  useEffect(() => {
    setGrade(data?.grades?.data[0]);
  }, [data]);

  useEffect(() => {
    setLength(gradeLengthData?.grades?.data?.length);
    setMaxCount(gradeLengthDataa?.grades?.data?.length);
    setLastNameL(gradeLengthData?.grades?.data[0]?.student?.lastNameL);
  }, [gradeLengthData, gradeLengthDataa]);

  // load course
  const [loadCourses, { loading: courseLoad, data: courseData }] =
    useLazyQuery(COURSES);

  useEffect(() => {
    loadCourses({
      variables: {
        where: { assessmentYear: accessmentRedux },
        skip: 0,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setDepId(studentData?.students?.data[0]?.department?.id);
    setFacId(studentData?.students?.data[0]?.faculty?.id);
    setStudentId(studentData?.students?.data[0]?.id);
  }, [studentData]);

  // const styles = StyleSheet.create({
  //   page: {
  //     flexDirection: "row",
  //     backgroundColor: "#E4E4E4",
  //   },
  //   section: {
  //     margin: 10,
  //     padding: 10,
  //     flexGrow: 1,
  //   },
  //   header: {
  //     fontSize: 20,
  //     marginBottom: 10,
  //   },
  //   content: {
  //     fontSize: 14,
  //   },
  // });

  // const generatePDF = () => {
  //   const pdfData = (
  //     <Document>
  //       <Page size="A4" style={styles.page}>
  //         <View style={styles.section}>
  //           <Text style={styles.header}>Sample PDF Document</Text>
  //           <Text style={styles.content}>
  //             This is a sample PDF document generated using react-pdf in a
  //             React.js application.
  //           </Text>
  //         </View>
  //       </Page>
  //     </Document>
  //   );

  //   return pdfData;
  // };

  const handleGeneratePDF = () => {
    const input = document.getElementById("pdf-content");

    // Increase the DPI for higher quality (e.g., 300)
    const dpi = 300;
    const scale = dpi / 96; // 96 DPI is the standard screen resolution

    html2canvas(input, { scale: scale }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0); // Set quality to 1.0 (highest)
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Calculate the width and height of the PDF to match the content
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("grade.pdf");
    });
  };

  const _printEn = (e) => {
    handleGeneratePDF();
    let _data = {
      maxCount: maxCount,
      Length: length,
      students: studentData,
      version: "lao",
    };

    //  history.push("/grades-to-report", _data);
  };
  const _printLa = (e) => {
    let _data = {
      maxCount: maxCount,
      Length: length,
      students: studentData,
      version: "eng",
    };
    history.push("/grades-to-report", _data);
  };

  const [updateGrade] = useMutation(UPDATE_GRADE);
  const _saveEditGrade = () => {
    updateGrade({
      variables: {
        data: {
          gradeType: {
            deleteMany: {},
          },
        },
        where: { id: gradeId },
      },
    })
      .then(async () => {
        let Done = updateGrade({
          variables: {
            data: {
              gradeType: {
                create: [
                  {
                    name: "ຄະແນນຂື້ນຫ້ອງ",
                    score: parseInt(getScoreOnClass),
                  },
                  {
                    name: "ຄະແນນກິດຈະກຳ",
                    score: parseInt(getScoreOnPaticipate),
                  },
                  {
                    name: "ຄະແນນເສັງກາງພາກ",
                    score: parseInt(getScoreOnMidTerm),
                  },
                  {
                    name: "ຄະແນນເສັງທ້າຍພາກ",
                    score: parseInt(getScoreOnFinal),
                  },
                ],
              },
              numericGrade: getTotalScore,
              letterGrade: getLetterGrade,
            },
            where: { id: gradeId },
          },
        });
        if (Done) {
          alertSuccess("ການແກ້ໄຂຄະແນນສຳເລັດແລ້ວ");
        }
      })
      .catch((err) => {
        alertWarning("ການແກ້ໄຂຄະແນນລົ້ມແຫຼວ");
      });
  };

  return (
    <div>
      <GradeNav />
      <CustomContainer style={{ marginTop: 0 }}>
        <div className="row">
          <div className="col-sm-6" style={{ display: "block" }}>
            <span
              style={{
                display: "inline-block",
                fontSize: 24,
                marginTop: 20,
                color: "#057CAE",
                fontWeight: "bold",
              }}
            >
              ຄະແນນນັກສຶກສາ
            </span>
          </div>
          <div
            className="col-sm-6"
            style={{ textAlign: "right", display: displayButton }}
          >
            <PDFGenerator student={studentNumber} />

            {/* <button className="editBtn" onClick={(e) => _printEn(e)}>
              <img
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg"
                }
                className="la-en"
                alt=""
              />
              &nbsp;&nbsp; ອອກໃບຄະແນນ
            </button> */}

            {/* 
            <button className="editBtn" onClick={(e) => _printLa(e)}>
              <img
                src={
                  "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Great_Britain_%28English_version%29.png"
                }
                className="la-en"
                alt=""
              />
              &nbsp;&nbsp; ອອກໃບຄະແນນ
            </button> */}
          </div>
        </div>

        <div style={{ display: isStudent === true ? "none" : "block" }}>
          <div className="row">
            <div className="col-md-2">
              <a
                style={{
                  margin: 0,
                  color: Consts.PRIMARY_COLOR,
                  fontWeight: "bold",
                  marginTop: 20,
                }}
              >
                ປ້ອນລະຫັດນັກສຶກສາ
              </a>
            </div>

            <div className="col-md-8">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="ລະຫັດນັກສຶກສາ"
                  onChange={(e) => setStudentNumber(e.target.value)}
                />
                <span className="input-group-btn">
                  <button
                    type="button"
                    onClick={(e) => {
                      _ClickButtonSearch();
                      // setIsStudent(true);
                      // setDisplayButton("block");
                    }}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    }}
                    className="btn btn-info"
                  >
                    <i className="fa fa-search" /> ຄົ້ນຫາ
                  </button>
                </span>
              </div>
              <div>
                {studentNumberInvalid && (
                  <span style={{ color: "red" }}>ກະລຸນາປ້ອນລະຫັດນັກສຶກສາ</span>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <i
              className="fa fa-search"
              style={{
                marginTop: 100,
                fontSize: 100,
                color: "#8B8B8B",
                opacity: 0.5,
              }}
            />
            <p
              style={{
                fontSize: 30,
                fontWeight: "bolder",
                marginTop: 30,
                color: "#8B8B8B",
                opacity: 0.5,
              }}
            >
              ~ ປ້ອນເພື່ອຄົ້ນຫາ ~
            </p>
          </div>
        </div>
        <div style={{ display: isStudent ? "block" : "none" }}>
          <div style={{ marginTop: 15 }}>
            <span
              style={{
                borderBottom: `2px solid ${Consts.PRIMARY_COLOR}`,
                color: "#212529",
                fontWeight: "bolder",
                fontSize: 20,
              }}
            >
              {" "}
              ຜົນການຄົ້ນຫາ:{" "}
            </span>
            <span>
              <strong>{studentNumber} </strong>
            </span>
          </div>
          <div style={{ marginTop: 20, marginLeft: 15 }}>
            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-3 styleLeft">ຊື່ ແລະ ນາມສະກຸນ</div>
              <div className="col-3 styleRight">
                {!grade?.student?.firstNameL ||
                grade?.student?.firstNameL === ""
                  ? convertGenderNamEN(grade?.student?.gender) ??
                    "-" + " " + grade?.student?.firstNameE ??
                    "-" + " " + grade?.student?.lastNameE ??
                    "-"
                  : setGenders(grade?.student?.gender) +
                      " " +
                      grade?.student?.firstNameL ??
                    "-" + " " + grade?.student?.lastNameL ??
                    "-"}
                {/* {setGenders(grade?.student?.gender)}&nbsp;
                {grade?.student?.firstNameL ?? "-" + " " ?? "-"} {grade?.student?.lastNameL ?? "-"} */}
              </div>
            </div>
            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-3 styleLeft">ອັບເດດລ່າສຸດໂດຍ</div>
              <div className="col-3 styleRight">
                {grade?.updatedBy?.firstname ?? "-" + " " ?? "-"}
                {grade?.updatedBy?.lastname ?? "-"}
              </div>
            </div>
            <div className="row" style={{ marginBottom: 10 }}>
              <div className="col-3 styleLeft">ເວລາອັບເດດລ່າສຸດ</div>
              <div className="col-3 styleRight">
                {formateDateSlash(grade?.updatetedAt)}
              </div>
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <div className="row">
              <div className="col-1 head">ປີຮຽນ</div>
              <div
                onClick={() => {
                  _year(1);
                  setSelectYear(1);
                }}
                className={`col-1 year-term ${
                  isYearClicked === 1 ? "active-menu" : "menu-Item"
                }`}
              >
                ປີ1
              </div>
              <div
                onClick={() => {
                  _year(2);
                  setSelectYear(2);
                }}
                className={`col-1 year-term ${
                  isYearClicked === 2 ? "active-menu" : "menu-Item"
                }`}
              >
                ປີ2
              </div>
              <div
                onClick={() => {
                  _year(3);
                  setSelectYear(3);
                }}
                className={`col-1 year-term ${
                  isYearClicked === 3 ? "active-menu" : "menu-Item"
                }`}
              >
                ປີ3
              </div>
              <div
                onClick={() => {
                  _year(4);
                  setSelectYear(4);
                }}
                className={`col-1 year-term ${
                  isYearClicked === 4 ? "active-menu" : "menu-Item"
                }`}
              >
                ປີ4
              </div>
              <div
                onClick={() => {
                  _year(5);
                  setSelectYear(5);
                }}
                className={`col-1 year-term ${
                  isYearClicked === 5 ? "active-menu" : "menu-Item"
                }`}
              >
                ປີ5
              </div>
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <div className="row">
              <div className="col-1 head">ເທີມ</div>
              <div
                onClick={() => {
                  _term(1);
                  setSelectSemeter(1);
                }}
                className={`col-1 year-term ${
                  isTermClicked === 1 ? "active-menu" : "menu-Item"
                }`}
              >
                ເທີມ1
              </div>
              <div
                onClick={() => {
                  _term(2);
                  setSelectSemeter(2);
                }}
                className={`col-1 year-term ${
                  isTermClicked === 2 ? "active-menu" : "menu-Item"
                }`}
              >
                ເທີມ2
              </div>
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <div className="row">
              <div className="col-1 head">ເລືອກເບິ່ງ</div>
              <div
                onClick={() => {
                  _numberTest(1);
                  setNumberTest(1);
                }}
                className={`col-2 year-term ${
                  isCheckNumberTest === 1 ? "active-menu" : "menu-Item"
                }`}
              >
                ຄະແນນທົ່ວໄປ
              </div>
              <div
                onClick={() => {
                  _numberTest(2);
                  setNumberTest(2);
                }}
                className={`col-2 year-term ${
                  isCheckNumberTest === 2 ? "active-menu" : "menu-Item"
                }`}
              >
                ຄະແນນແກ້ເກຣດ
              </div>
            </div>
          </div>
          <div>
            <table
              border="1"
              bordercolor="#fff"
              style={{ width: "100%", marginTop: 20 }}
            >
              <thead>
                <TableHeader>
                  <th width="25%">ຊື່ວິຊາ</th>
                  {data?.grades?.data[0]?.gradeType.map((_item, index) => {
                    return <th key={index}>{_item?.name}</th>;
                  })}
                  <th width="100">ຄະແນນລວມ</th>
                  <th width="100">Grade</th>
                </TableHeader>
              </thead>
              <tbody>
                {data?.grades?.data?.map((x, index) => {
                  return (
                    <tr
                      style={{
                        borderBottom: "2px solid #ffff",
                        textAlign: "center",
                      }}
                      key={index}
                    >
                      <TableCell>{x?.course?.title ?? "-"}</TableCell>
                      {x?.gradeType?.map((newItem, index2) => {
                        return (
                          <TableCell key={index2}>
                            {newItem?.score ? newItem?.score : "-"}
                          </TableCell>
                        );
                      })}
                      <TableCell>{x?.numbericGrade ?? "-"}</TableCell>
                      <TableCell>{x?.letterGrade ?? "-"}</TableCell>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </CustomContainer>

      <div
        className="modal fade bd-example-modal-lg"
        tabindex="-1"
        role="dialog"
        id="editGrade"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <p>
                ແກ້ໄຂຄະແນນຂອງ: {setGenders(grade?.student?.gender)}&nbsp;
                {grade?.student?.firstNameL ?? "-" + " " ?? "-"}
                {grade?.student?.lastNameL ?? "-"}
              </p>
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row">
                  <div className="col-sm-4">
                    <select className="form-control" disabled>
                      <option>
                        {studentData?.students?.data[0]?.faculty?.name}
                      </option>
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <select className="form-control" disabled>
                      <option>
                        {studentData?.students?.data[0]?.department?.name}
                      </option>
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <select className="form-control center" disabled>
                      <option>{selectYear}</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <select className="form-control" disabled>
                      <option>{selectSemeter}</option>
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <select
                      className="form-control"
                      onChange={(e) => setSelectAssessmentYear(e.target.value)}
                    >
                      <option value="">ເລືອກສົກຮຽນ</option>
                      {callAccessMent?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-sm-4">
                    {courseLoad ? (
                      <div>...ກຳລັງໂຫຼດ...</div>
                    ) : (
                      <select
                        className="form-control"
                        onChange={(e) => {
                          setSelectCourse(e.target.value);
                        }}
                      >
                        <option value="">ເລືອກວິຊາ</option>
                        {courseData?.course_page?.data
                          ?.filter(
                            (courseFilter) =>
                              courseFilter?.faculty?.id === facId &&
                              courseFilter?.department?.id === depId &&
                              courseFilter?.semester === selectSemeter &&
                              courseFilter?.year === selectYear
                          )
                          .map((item, index2) => {
                            return (
                              <option key={index2} value={item?.id}>
                                {item?.title}
                              </option>
                            );
                          })}
                      </select>
                    )}
                  </div>
                </div>
                <Formik
                  initialValues={{
                    scoreOnClass:
                      gradeToEdit?.grades?.data[0]?.gradeType[0]?.score,
                    scoreOnPaticipate:
                      gradeToEdit?.grades?.data[0]?.gradeType[1]?.score,
                    scoreOnMidTerm:
                      gradeToEdit?.grades?.data[0]?.gradeType[2]?.score,
                    scoreOnFinal:
                      gradeToEdit?.grades?.data[0]?.gradeType[3]?.score,
                    toTalScore: gradeToEdit?.grades?.data[0]?.numericGrade,
                    grade: gradeToEdit?.grades?.data[0]?.letterGrade,
                  }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.scoreOnClass) {
                      errors.scoreOnClass = "Required";
                    } else if (!values.scoreOnPaticipate) {
                      errors.scoreOnPaticipate = "Required";
                    }
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    let data = {
                      scoreOnClass: values.scoreOnClass,
                      scoreOnPaticipate: values.scoreOnPaticipate,
                      scoreOnMidTerm: values.scoreOnMidTerm,
                      scoreOnFinal: values.scoreOnFinal,
                      toTalScore: values.toTalScore,
                      grade: values.grade,
                    };
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="form-group row">
                        <label className="col-sm-4 col-form-label">
                          ຄະແນນຂື້ນຫ້ອງ:
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="number"
                            name="scoreOnClass"
                            className="form-control"
                            id="scoreOnClass"
                            value={getScoreOnClass}
                            onChange={(e) => {
                              if (e.target.value > 10) return;
                              else if (e.target.value < 0) return;
                              handleChange(e.target.value);
                              setGetScoreOnClass(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="scoreOnPaticipate"
                          className="col-sm-4 col-form-label"
                        >
                          ຄະແນນກິດຈະກຳ:
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="Number"
                            name="scoreOnPaticipate"
                            className="form-control"
                            id="scoreOnPaticipate"
                            value={getScoreOnPaticipate}
                            onChange={(e) => {
                              if (e.target.value > 10) return;
                              else if (e.target.value < 0) return;
                              handleChange(e.target.value);
                              setGetScoreOnPaticipate(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="scoreOnMidTerm"
                          className="col-sm-4 col-form-label"
                        >
                          ຄະແນນເສັງກາງພາກ:
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="Number"
                            name="scoreOnMidTerm"
                            className="form-control"
                            id="scoreOnMidTerm"
                            value={getScoreOnMidTerm}
                            onChange={(e) => {
                              if (e.target.value > 30) return;
                              else if (e.target.value < 0) return;
                              handleChange(e.target.value);
                              setGetScoreOnMidTerm(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="scoreOnFinal"
                          className="col-sm-4 col-form-label"
                        >
                          ຄະແນນເສັງທ້າຍພາກ:
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="Number"
                            name="scoreOnFinal"
                            className="form-control"
                            id="scoreOnFinal"
                            value={getScoreOnFinal}
                            onChange={(e) => {
                              if (e.target.value > 50) return;
                              else if (e.target.value < 0) return;
                              handleChange(e.target.value);
                              setGetScoreOnFinal(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="TotalScore"
                          className="col-sm-4 col-form-label"
                        >
                          ຄະແນນລວມ:
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="Number"
                            name="TotalScore"
                            className="form-control"
                            id="TotalScore"
                            disabled
                            value={getTotalScore}
                            onChange={(e) => {
                              handleChange(e.target.value);
                              setGetTotalScore(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="grade"
                          className="col-sm-4 col-form-label"
                        >
                          ເກຣດ:
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            name="grade"
                            className="form-control"
                            id="grade"
                            disabled
                            value={getLetterGrade}
                            onChange={(e) => {
                              handleChange(e.target.value);
                              setGetLetterGrade(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-info btncancel" data-dismiss="modal">
                ຍົກເລີກ
              </button>
              <button
                className="btn btn-info"
                style={{ marginRight: 20 }}
                data-dismiss="modal"
                onClick={() => _saveEditGrade()}
              >
                &nbsp;ບັນທຶກ&nbsp;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentListForGrade;
