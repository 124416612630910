import { NEW_API_URL, API_KEY } from "../../common/contrant";
import React, { useState, useEffect } from "react";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setFilter } from "../../redux/filtrSlice";
import { useHistory } from "react-router-dom";
import { USER_KEY } from "../../consts";
import Routers from "../../consts/router";
import { CustomContainer, Title } from "../../common";
import { Breadcrumb } from "react-bootstrap";
import FilterCourse from "./common/FilterCourse";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import { formatDate, formateDateSlash } from "../../common/super";

const CourseList = () => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const filterData = useSelector((state) => state.filter);
  const accessmentRedux = useSelector((state) => state.accessment.value);
  const dispatch = useDispatch();
  const history = useHistory();
  const [faculties, setFaculties] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [majors, setMajors] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [formData, setFormData] = useState({
    faculty: filterData.faculty || "",
    department: filterData.department || "",
    title: "",
    year: "",
    assementYear: accessmentRedux,
    educationLevel: filterData.educationLevel || "",
  });
  const handleEdit = (subject) => {
    history.push(Routers.COURSE_ADD, subject);
  };

  const handleCourseDetail = (major) => {
    history.push(Routers.COURSE_DETAIL, major);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  useEffect(() => {
    let _filterData = {};
    if (formData.faculty !== "") {
      _filterData.faculty = formData.faculty;
    }

    // Check and set department if not null
    if (formData.department !== "") {
      _filterData.department = formData.department;
    }

    // Check and set major if not null
    if (formData.major !== "") {
      _filterData.major = formData.major;
    }
    // Dispatch the setFilter action with the filterData payload
    dispatch(setFilter(_filterData));
    // Fetch faculties
    axios
      .get(NEW_API_URL + "faculty", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setFaculties(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch departments
    axios
      .get(NEW_API_URL + "department/" + formData.faculty, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDepartments(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch education levels
    axios
      .get(NEW_API_URL + "educationlevel", {
        headers: {
          api_key: API_KEY,
        },
      })

      .then((response) => setEducationLevels(response.data))
      .catch((error) => console.error(error));

    // Fetch majors
    if (formData.department) {
      axios
        .get(NEW_API_URL + "getmajo/" + formData.department, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((response) => setMajors(response.data?.data || []))
        .catch((error) => console.error(error));
    }
  }, [formData]);

  // useEffect(() => {
  //   fetchData();
  // }, [formData, rowsPerPage, page, accessmentRedux]); // Empty dependency array means this effect runs once on component mount

  useEffect(() => {
    let _filterData = {};
    if (formData.faculty !== "") {
      _filterData.faculty = formData.faculty;
    }

    // Check and set department if not null
    if (formData.department !== "") {
      _filterData.department = formData.department;
    }

    // Check and set major if not null
    if (formData.major !== "") {
      _filterData.major = formData.major;
    }
    // Fetch faculties, departments, and education levels from your backend API
    // Update the state variables (faculties, departments, educationLevels) accordingly
    // For simplicity, I'm using placeholder data here

    // Dispatch the setFilter action with the filterData payload
    dispatch(setFilter(_filterData));
    // Fetch faculties
    axios
      .get(NEW_API_URL + "faculty", {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setFaculties(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch departments
    axios
      .get(NEW_API_URL + "department/" + formData.faculty, {
        headers: {
          api_key: API_KEY,
        },
      })
      .then((response) => setDepartments(response.data?.data || []))
      .catch((error) => console.error(error));

    // Fetch majors
    if (formData.department) {
      axios
        .get(NEW_API_URL + "getmajo/" + formData.department, {
          headers: {
            api_key: API_KEY,
          },
        })
        .then((response) => setMajors(response.data?.data || []))
        .catch((error) => console.error(error));
    }
    // Fetch majors
    // if (formData.major && formData.year) {
    //   axios
    //     .get(NEW_API_URL + "class/" + formData.major + "/" + formData.year, {
    //       headers: {
    //         api_key: API_KEY,
    //       },
    //     })
    //     .then((response) => setClassRoom(response.data?.data || []))
    //     .catch((error) => console.error(error));
    // }
  }, [formData]);

  // // on seaarch teacher
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${NEW_API_URL}search/teacher/${searchValue}`
  //       );
  //       setTeacherList(response.data); // Assuming the API returns an array of options
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   if (searchValue !== "") {
  //     fetchData();
  //   } else {
  //     setTeacherList([]); // Clear options if searchValue is empty
  //   }
  // }, [searchValue]);

  // useEffect(() => {
  //   if (snackbarOpen) {
  //     setTimeout(() => {
  //       setSnackbarOpen(false);
  //     }, 5000);
  //   }
  // }, [snackbarOpen]);

  // const handleSelect = (event, newValue) => {
  //   setSelectedItem(newValue);
  //   if (newValue) {
  //     setFormData((preData) => ({
  //       ...preData,
  //       teacher: newValue?._id,
  //     }));
  //   }
  // };
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item active>ລາຍການຫລັກສູດ</Breadcrumb.Item>
      </Breadcrumb>
      <CustomContainer>
        <Title text={"ຄົ້ນຫາຫລັກສູດ"} />
        <FilterCourse
          formData={formData}
          educationLevels={educationLevels}
          faculties={faculties}
          departments={departments}
          userObject={userObject}
          handleChange={handleChange}
        />
      </CustomContainer>
      <CustomContainer>
        <Title text={"ຫລັກສູດທັງໝົດ"} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow color="info">
                <TableCell
                  align="center"
                  width={80}
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ລຳດັບ
                </TableCell>
                {/* <TableCell align="center" style={{ color: "white", fontWeight: "bold" }}>ຫລັກສູດ</TableCell> */}
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ຊື່ສາຂາວິຊາ ພາສາລາວ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ຊື່ສາຂາວິຊາ ພາສາອັງກິດ
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  ວັນທີ່ສ້າງ
                </TableCell>
                {userObject.data.role === "ADMIN" ? (
                  <></>
                ) : (
                  <TableCell
                    align="center"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    ຄຸ້ມຄອງ
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {majors.map((major, index) => (
                <TableRow key={major._id}>
                  <TableCell align="center">{index + 1}</TableCell>
                  {/* <TableCell align="center">{major?.courseCode}</TableCell> */}
                  <TableCell align="center" sx={{ whiteSpace: "wrap" }}>
                    {major?.name}
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: "wrap" }}>
                    {major?.enName}
                  </TableCell>

                  <TableCell align="center">
                    {formatDate(major?.createdAt)}
                  </TableCell>
                  {userObject.data.role === "ADMIN" ? (
                    <></>
                  ) : (
                    <TableCell align="center">
                      <IconButton
                        color="info"
                        size="small"
                        aria-label="Edit"
                        onClick={() => handleEdit(major)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="success"
                        size="small"
                        aria-label="Eye"
                        onClick={() => handleCourseDetail(major)}
                      >
                        <RemoveRedEyeOutlined />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CustomContainer>
    </div>
  );
};

export default CourseList;
