import { gql } from "apollo-boost";

export const GRADES = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      total
      data {
        id
        gredeStatus
        numberTest
        sendingGradeStatus
        letterGrade
        numbericGrade
        gradeType {
          name
          score
          maxScore
        }
        student {
          id
          userId
          studentNumber
          birthday
          firstNameL
          lastNameL
          firstNameE
          lastNameE
          gender
        }
        course {
          id
          moodleCourseId
          courseCode
          title
          unit
          year
        }
        createdAt
        createdBy
        updatedBy
        note
      }
    }
  }
`;

export const GRADES_TO_PRINT = gql`
  query GradesToPrint(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    gradesToPrint(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      total
      data {
        id
        gredeStatus
        numberTest
        sendingGradeStatus
        letterGrade
        numbericGrade
        gradeType {
          name
          score
          maxScore
        }
        student {
          id
          userId
          studentNumber
          birthday
          firstNameL
          lastNameL
          gender
        }
        course {
          id
          moodleCourseId
          courseCode
          title
          enTitle
          unit
          year
        }
        createdAt
        # createdBy {
        #   id
        #   firstname
        #   lastname
        # }
        # updatedBy {
        #   id
        #   firstname
        #   lastname
        # }
        note
      }
    }
  }
`;

export const COURSES = gql`
  query Courses(
    $where: CustomCourseWhereInput
    $orderBy: CourseOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    courses(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data{
      sendingGradeStatus
      id
      }
    }
  }
`;


export const COURSE = gql`
  query Course(
    $where: CourseWhereUniqueInput!
  ) {
    course(where:$where){
      sendingGradeStatus
      id
    }
  }
`;




export const GRADE_LIST = gql`
  query Grades {
    grades {
      data {
        id
        registration
        student {
          id
          gender
          studentNumber
          firstNameL
          lastNameL
          firstNameE
          lastNameE
        }
        teacher {
          id
        }
        course {
          id
          title
          detail
          year
          description
          department {
            id
            name
            enName
            status
            faculty {
              id
              name
              enName
              description
            }
          }
          gradeType {
            name
            detail
            score
            maxScore
          }
        }
        semester
        year
        numericGrade
        letterGrade
        gradeType {
          name
          detail
          note
          score
          maxScore
        }
        note
        isFailed
        createdBy
        createdAt
        updatedAt
        updatedBy
      }
      total
    }
  }
`;
export const UPDATE_GRADE = gql`
  mutation UpdateGrade(
    $data: CustomGradeWhereInput!
    $where: GradeWhereUniqueInput!
  ) {
    updateGrade(data: $data, where: $where) {
      id
    }
  }
`;

export const UPDATE_MANY_GRADE = gql`
  mutation UpdateManyGrades(
    $data: GradeUpdateManyMutationInput!
    $where: GradeWhereInput
  ) {
    updateManyGrades(data: $data, where: $where) {
      count
    }
  }
`;

export const GRADE_COURSES = gql`
  query gradeCourses(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        id
        gradeType {
          name
          score
          maxScore
        }
        student {
          id
          userId
          firstNameL
          lastNameL
          role
          yearLevel
          assessmentYear
          email
          phoneMobile
        }
        sendingGradeStatus
        numbericGrade
        numberTest
      }
      total
    }
  }
`;

export const GRADE_All_STUDENT_COURSE = gql`
  query Grades(
    $where: CustomGradeWhereInput
    $orderBy: GradeOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    grades(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        course {
          title
          gradeType {
            name
            maxScore
            score
          }
        }
        student {
          gender
          firstNameL
          lastNameL
        }
        #gradeCourse {
        #  midtermScorePeriod
        #  finalScorePeriod
        #}
      }
      total
    }
  }
`;

export const UPDATE_GRADE_COURSE = gql`
  mutation UpdateGradeCourse(
    $data: GradeCourseUpdateInput!
    $where: GradeCourseWhereUniqueInput!
  ) {
    updateGradeCourse(data: $data, where: $where) {
      id
    }
  }
`;

export const SEND_GRADE_COURSE = gql`
  mutation SendGradeCourse(
    $data: sendGradeCourseInput!
    $where: customSendGradeCourseWhereInput!
  ) {
    sendGradeCourse(data: $data, where: $where) {
      id
    }
  }
`;



export const UPDATE_GRADE_STATUS = gql`
  mutation UpdateGradeStatus(
    $data: TeacherSubmitGradeWhereInput!
    $where: GradeWhereUniqueInput!
  ) {
    updateGradeStatus(data: $data, where: $where) {
      id
    }
  }
`;

export const UPDATELOGS = gql`
  query UpdateLogs(
    $where: UpdateLogWhereInput
    $orderBy: UpdateLogOrderByInput
    $skip: Int
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    updateLogs(
      where: $where
      orderBy: $orderBy
      skip: $skip
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      data {
        id
        relatedId
        studentId
        courseId
        relatedSchema
        logMethod
        note
        diffData
        oldData
        newData
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
      total
    }
  }
`;
