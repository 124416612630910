import React from "react";
import Dialog from "@material-ui/core/Dialog";
import "../../index.css";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
function ListAccessableFaculty({ data, showList, closeList }) {
  return (
    <div>
      <Dialog
        onClose={showList}
        aria-labelledby="customized-dialog-title"
        open={showList}
        fullWidth="md"
      >
        <MuiDialogTitle id="customized-dialog-title" 
        style={{height:50}}
        onClose={closeList}>
          <h4 style={{ color: "rgb(5, 124, 174)", fontWeight: "bold",marginTop:5}}>
            ຄະນະທີ່ກ່ຽວຂ້ອງ
          </h4>
        </MuiDialogTitle>
        <MuiDialogContent dividers>
          {data?.accessableFaculty?.map((item, index) => {
            return (
              <ListItem button style={{height:40,paddingTop:20,pointerEvents:'none'}}>
                <ListItemText>
                  <p>{index +1}&nbsp;&nbsp;{item?.name || "-"}</p>
                  </ListItemText>
              </ListItem>
            );
          })}
        </MuiDialogContent>
        <MuiDialogActions>
          <div style={{ textAlign: "center", width: "100%" }}>
            <Button
              className="btn-exit"
              variant="outline-info"
              onClick={closeList}
            >
              ອອກ
            </Button>
          </div>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
}
export default ListAccessableFaculty;
