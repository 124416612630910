import React, { Component } from "react";
import Routes from "./routes/Routes";
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import { ToastContainer, toast } from "react-toastify";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import store from "./redux/store";
import { Provider } from "react-redux";
import { USER_KEY } from "./consts";
import "react-toastify/dist/ReactToastify.css";
import theme from "./common/theme";
import { ThemeProvider } from "@mui/material/styles";
import "./App.css";
library.add(fab, fas);
dom.watch();

toast.configure({
  autoClose: 8000,
  draggable: false,
  // etc., you get the idea
});

const client = new ApolloClient({
//  uri: "http://localhost:7071",
//   uri: "http://13.229.141.85:7071",
  uri: "https://graphql-api.laodevhub.com/",
//  uri: "http://47.128.3.173:7071",
  request: (operation) => {
    const user = localStorage.getItem(USER_KEY);

    if (user) {
      const token = JSON.parse(user)["accessToken"];
      operation.setContext({
        headers: {
          Authorization: token ? "Nuol " + token : "",
          Platform: "ADMIN",
        },
      });
      return;
    }
    operation.setContext({
      headers: {
        Platform: "ADMIN",
      },
    });
  },
  onError: (error) => {
    if (error?.response?.errors?.length > 0) {
      if (
        error?.response?.errors[0]?.message === "TokenExpiredError: jwt expired"
      ) {
        localStorage.clear();
      }
      if (
        error.response.errors[0].message ===
        "USER_WITH_THIS_PHONE_NUMBER_IS_ALREADY_EXIST"
      ) {
        toast.error("ເບີໂທນີ້ມີຢູ່ແລ້ວ ກະລຸນາປ້ອນເບີໂທໃໝ່!", {
          autoClose: 5000,
        });
      }
      if (
        error.response.errors[0].message ===
        "USER_WITH_THIS_EMAIL_IS_ALREADY_EXIST"
      ) {
        toast.error("ອີເມວນີ້ມີຢູ່ແລ້ວ ກະລຸນາປ້ອນອີເມວໃໝ່!", {
          autoClose: 5000,
        });
      }
    } else {
      console.log("ERR:", error?.response?.errors);
      console.log(error);
    }
  },
});

class App extends Component {
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={client}>
            <Routes />
            <ToastContainer style={{ zIndex: 10005 }} autoClose={5000} />
          </ApolloProvider>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
