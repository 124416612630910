import React from 'react'
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
} from "@mui/material";

const FilterCourse = ({ formData, educationLevels, faculties, departments, userObject, handleChange }) => {

    return (
        <Grid
            container
            className="d-flex"
            spacing={2}
        >
            <Grid item xs={4}>
                <FormControl fullWidth size="small" margin="normal">
                    <InputLabel htmlFor="educationLevel">ເລືອກລະດັບ</InputLabel>
                    <Select
                        label="ເລືອກລະດັບ"
                        name="educationLevel"
                        value={formData.educationLevel || ""}
                        onChange={handleChange}
                    >
                        <MenuItem key={""} value={""}>
                            ທັງຫມົດ
                        </MenuItem>
                        {educationLevels.map((educationLevel) => (
                            <MenuItem key={educationLevel._id} value={educationLevel._id}>
                                {educationLevel.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth size="small" margin="normal">
                    <InputLabel htmlFor="faculty">ຄະນະ</InputLabel>
                    <Select
                        disabled={
                            userObject?.data?.role === "FAC_ADMIN" ||
                            userObject?.data?.role === "DEP_ADMIN"
                        }
                        label="ຄະນະ"
                        name="faculty"
                        value={formData.faculty || ""}
                        onChange={handleChange}
                    >
                        <MenuItem key={""} value={""}>
                            ທັງຫມົດ
                        </MenuItem>
                        {faculties.map((faculty) => (
                            <MenuItem key={faculty._id} value={faculty._id}>
                                {faculty.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth size="small" margin="normal">
                    <InputLabel htmlFor="department">ພາກວິຊາ</InputLabel>
                    <Select
                        disabled={userObject?.data?.role === "DEP_ADMIN"}
                        label="ພາກວິຊາ"
                        name="department"
                        value={formData.department || ""}
                        onChange={handleChange}
                    >
                        <MenuItem key={""} value={""}>
                            ທັງຫມົດ
                        </MenuItem>
                        {departments.map((department) => (
                            <MenuItem key={department._id} value={department._id}>
                                {department.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {/* <Grid item xs={3}>
                <FormControl fullWidth size="small" margin="normal">
                    <InputLabel htmlFor="major">ສາຂາວິຊາ</InputLabel>
                    <Select
                        label="ສາຂາວິຊາ"
                        name="major"
                        value={formData.major || ""}
                        onChange={handleChange}
                    >
                        <MenuItem key={""} value={""}>
                            ທັງຫມົດ
                        </MenuItem>
                        {majors.map((major) => (
                            <MenuItem key={major._id} value={major._id}>
                                {major.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid> */}
        </Grid>
    )
}

export default FilterCourse